import React from "react";
// Customizable Area Start
import {
    styled,
    Box,
    Modal,
    Card,
    IconButton,
    Typography,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    TextField,
    InputAdornment,    
    RadioGroup,
    Radio,
    Checkbox,
    Input,    
    CircularProgress
} from "@material-ui/core";
import NutrientManagementController, { Props, configJSON } from "./NutrientManagementActivityController";
import { closeIconSvg, deleteIcon, nutrientManagementSvg, rupeeIconSVG } from "./assets";
import { DateRange, Add, KeyboardArrowDown } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { NoMenuDataFound, renderErrorMessage } from "../../../components/src/AdminConsoleHelper";
import { getLabelFromId, keyDown } from "../../../components/src/helper";
import { INutrientManagementForms, ISelectFarm } from "../../../components/src/interface.web";
import ErrorModal from "../../../components/src/ErrorModal.web";

const inputpropLaborCost = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 0,
        max: 50000,
        step: 100, // Allow Integer values
    },
}
const inputpropFertilizerCost = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 0,
        max: 100000,
        step: 100, // Allow Integer values
    },
}
const inputNutrientQuanity = {
    min: 0,
    step: 10, // Allow decimal values
}
// Customizable Area End

export default class NutrientManagement extends NutrientManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderNutrientForm = () => {
        return (
            <Modal
                open={this.openNutrienActivityModal()}
                style={webStyle.secondaryModalBox}
                BackdropProps={{
                    style: webStyle.modalBackdropProps,
                }}
                data-testid="nutrient_activity_modal"
            >
                <Card variant="outlined" style={webStyle.AddCardBoxSuccess}>
                    <Box style={webStyle.boxContainer}>
                        <ModalHeader>
                            <Box style={webStyle.headerLeft}>
                                <img
                                    src={nutrientManagementSvg}
                                    alt="Crop image"
                                    style={webStyle.headerImage}
                                />
                                <Typography style={webStyle.headerTitle}>Nutrient Management</Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    style={{ padding: 0 }}
                                    onClick={this.handleCloseNutrienActivityModal}
                                >
                                    <img src={closeIconSvg} style={{ width: "24px", height: "24px" }} />
                                </IconButton>
                            </Box>
                        </ModalHeader>
                        {this.state.getActivityDetailsLoading ?
                            <Box style={webStyle.cropFormLoaderBox}>
                                <CircularProgress size={40} color="secondary" />
                            </Box>
                            :
                        <form
                            data-testid="from-submit-testid"
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                        <CustomBoxHeader>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6} className="text-field-container">
                                    <CustomFormLabel>Select Farm</CustomFormLabel>
                                    <FormControl fullWidth>
                                        <CustomSelectDD
                                            disabled={this.props.isEditNutrientModalOpen}
                                            IconComponent={CustomArrowIcon}                            
                                            style={webStyle.inputFieldStyle}                                            
                                            data-testid="select_farm_testid"
                                            variant="outlined"
                                            color="secondary"
                                            inputProps={{ "aria-label": "Without label" }}
                                            displayEmpty
                                            name="select_farm"
                                            renderValue={(value) => {                                               
                                                if (this.state.selectedFarmId) {
                                                    return this.state.farmNamesList.find((item) => item.attributes.id == this.state.selectedFarmId)?.attributes?.name
                                                } else if (!this.state.selectedFarmId) {
                                                    return (
                                                        <span className="opcity-48">Select Farm</span>
                                                    );
                                                }
                                            }}                                            
                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleChangeSelectFarm(event.target.value)}}
                                            error={Boolean(this.state.selectedFarmIdError)}
                                            value={this.state.selectedFarmId}
                                        >
                                            <MenuItem value={""} disabled>
                                                <span className="opcity-48">Select Farm</span>
                                            </MenuItem>
                                            {this.state.farmNamesList.map((item: ISelectFarm) => {
                                                return (
                                                    <MenuItem
                                                        style={{ paddingBlock: "0px" }}
                                                        value={item.attributes.id}
                                                        key={item.id}
                                                    >
                                                        <RadioGroup
                                                            value={this.state.selectedFarmId}
                                                            style={{
                                                                width: "100%",
                                                                borderBottom: "1px solid #F0EBE0",
                                                            }}
                                                        >
                                                            <CustomFormControlLabel
                                                                value={item.attributes.id}
                                                                control={<Radio />}
                                                                style={{ width: "100%" }}
                                                                label={
                                                                    <Box
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <Typography style={webStyle.cropDropDownLable}>
                                                                                {item.attributes.name}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography style={webStyle.cropUnitText}>
                                                                            {item.attributes.farm_area}{" "}{item.attributes?.unit_farm_area_id?.name}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            />
                                                        </RadioGroup>
                                                    </MenuItem>
                                                );
                                            })}
                                            <CustomButton
                                                onClick={this.openAddFarm}
                                                startIcon={<Add style={{ width: "18px", height: "18px" }} />}
                                            >
                                                Add Farm
                                            </CustomButton>
                                        </CustomSelectDD>
                                    {renderErrorMessage(this.state.selectedFarmIdError)}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} className="text-field-container">
                                    <CustomFormLabel>Select Crop</CustomFormLabel>
                                    <CustomSelectDD
                                        style={webStyle.inputFieldStyle}
                                        IconComponent={CustomArrowIcon}  
                                        data-testid="select_crop_testid"
                                        variant="outlined"
                                        color="secondary"
                                        inputProps={{ "aria-label": "Without label" }}
                                        displayEmpty
                                        name="select_crop"
                                        multiple
                                        disabled={!this.state.selectedFarmId || this.props.isEditNutrientModalOpen}
                                        value={this.state.selectedCropIdArr}
                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => { 
                                            this.handleChangeSelectCrop(event.target.value as number[])                                            
                                        }}
                                        error={Boolean(this.state.selectedCropIdError)}
                                        renderValue={(selected: any) => {
                                            if (!this.state.selectedCropIdArr.length) {
                                                return (
                                                    <span className="opcity-48">Select Crop</span>
                                                );
                                            } else {
                                                let selectedCropsLabel: string[] = []
                                                selected.forEach((value: string | number) => {
                                                    const result = this.state.cropNamesList.find((option) => String(option.attributes.id) == String(value))
                                                    if (result) {
                                                        selectedCropsLabel.push(result.attributes.crop_name_id.name)
                                                    }
                                                });
                                                const labels = selectedCropsLabel.join(", ")
                                                return (
                                                    <Typography noWrap>{labels}</Typography>
                                                )
                                            }
                                        }}
                                    >
                                        <MenuItem value={""} disabled>
                                            <span className="opcity-48">Select Crop</span>
                                        </MenuItem>
                                        {this.state.cropNamesList.map((option) => (                                   
                                            <MenuItem key={option.attributes.id} value={option.attributes.id} style={{paddingBottom:0,flexDirection:"column"}}>
                                                <Box style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    marginLeft: "-22px",                                                    
                                                }}>
                                                    <Checkbox checked={this.state.selectedCropIdArr.indexOf(option.attributes.id) !== -1} />
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography style={webStyle.cropDropDownLable}>
                                                                {option.attributes.crop_name_id.name}{" | "}<span style={{ color: "#108B88" }}>{option.attributes?.crop_type_id?.name}</span>
                                                            </Typography>
                                                        </Box>
                                                        <Typography style={webStyle.cropUnitText}>
                                                            {option.attributes.total_crop_area}{" "}{option.attributes?.unit_of_area_id?.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box style={{padding:"0",width:"100%"}}><Divider style={{background:"#F0EBE0"}}/></Box>
                                            </MenuItem>                                    
                                        ))}
                                            <CustomButton
                                                startIcon={<Add style={{ width: "18px", height: "18px" }} />}
                                                onClick={this.openAddCrop}
                                            >
                                                Add Crop
                                            </CustomButton>
                                    </CustomSelectDD>
                                    {renderErrorMessage(this.state.selectedCropIdError)}
                                </Grid>
                            </Grid>
                        </CustomBoxHeader>
                        <CustomHR />
                        {this.state.nutrientManagementForms.map((form: INutrientManagementForms, formIndex: number) => {
                            return (
                                <>
                                    <CustomBox key={formIndex}>                            
                                        <Grid container spacing={2} style={{ position: "relative" }}>
                                            <Grid item xs={12} lg={6} className="text-field-container">
                                                <CustomFormLabel>Nutrient Name</CustomFormLabel>
                                                <FormControl fullWidth>
                                                    <CustomSelectDD                                                        
                                                        data-testid="nutrient_management_name_testid"
                                                        variant="outlined"
                                                        color="secondary"
                                                        style={webStyle.inputFieldStyle}
                                                        inputProps={{ "aria-label": "Without label" }}
                                                        displayEmpty
                                                        name="nutrient_name_id"
                                                        IconComponent={CustomArrowIcon}                                                        
                                                        renderValue={(value) => {
                                                            if (form.nutrient_name_id) {
                                                                return this.state.NutrientNamesList.find((item) => item.id == form.nutrient_name_id)?.name
                                                            } else if (!form.nutrient_name_id) {
                                                                return (
                                                                    <span className="opcity-48">Select Nutrient Name</span>
                                                                );
                                                            }
                                                        }}                                                        
                                                        onClose={this.resetSearchNuturientData}
                                                        error={Boolean(form.errors.nutrient_name_id)}
                                                    >
                                                        <MenuItem value={""} disabled>
                                                            <span className="opcity-48">Select Nutrient Name</span>
                                                        </MenuItem>

                                                        <SearchBoxWrapper>
                                                            <Input
                                                                fullWidth
                                                                disableUnderline
                                                                // autoComplete="off"
                                                                autoFocus
                                                                className="custom_search_input"
                                                                data-testid="search_nutrient_name_testid"
                                                                type="text"
                                                                placeholder="Search by name"
                                                                onClick={(e) => e.stopPropagation()}
                                                                onKeyDown={(e) => e.stopPropagation()}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon
                                                                            style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                                                        />
                                                                    </InputAdornment>
                                                                }
                                                                name="search_nutrient_name"
                                                                value={this.state.searchNutrientName}
                                                                onChange={(event: React.ChangeEvent<{ value: string }>) => { this.handleFilteredNutrientNamesList(event.target.value) }}
                                                            />
                                                        </SearchBoxWrapper>
                                                        <Box>
                                                            {this.state.filteredNutrientNamesList.length > 0 ?
                                                                this.state.filteredNutrientNamesList.map((each,index) => (
                                                                    <MenuItem
                                                                        key={each.id}
                                                                        value={each.id}
                                                                        data-testid={`nutrient_name_menu_${index}`}
                                                                        // MAIN HANDLE FUNCTION 
                                                                        onClick={() => this.handleChange("nutrient_name_id", each.id, formIndex)}
                                                                        selected={each.id == form.nutrient_name_id}>
                                                                        {each.name}
                                                                    </MenuItem>
                                                                ))
                                                                : NoMenuDataFound()
                                                            }
                                                        </Box>
                                                    </CustomSelectDD>
                                                    {renderErrorMessage(form.errors.nutrient_name_id)}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} lg={6} className="text-field-container">
                                                <CustomFormLabel>Nutrient Management Type</CustomFormLabel>
                                                <FormControl fullWidth>
                                                    <CustomSelectDD
                                                        IconComponent={CustomArrowIcon}     
                                                        data-testid="nutrient_management_type_testid"
                                                        variant="outlined"
                                                        color="secondary"
                                                        style={webStyle.inputFieldStyle}
                                                        inputProps={{ "aria-label": "Without label" }}
                                                        displayEmpty
                                                        name="nutrient_type_id"
                                                        value={form.nutrient_type_id}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("nutrient_type_id", event.target.value, formIndex)}
                                                        error={Boolean(form.errors.nutrient_type_id)}
                                                    >
                                                        <MenuItem value={""} disabled>
                                                            <span className="opcity-48">Select Type</span>
                                                        </MenuItem>
                                                        {this.state.NutrientTypesList.length > 0 ?
                                                            this.state.NutrientTypesList.map((each) => (
                                                                <MenuItem key={each.id} value={each.id}>
                                                                    {each.name}
                                                                </MenuItem>
                                                            ))
                                                            : NoMenuDataFound()
                                                        }
                                                    </CustomSelectDD>
                                                    {renderErrorMessage(form.errors.nutrient_type_id)}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} lg={6} className="text-field-container addCropDate">
                                                <CustomFormLabel>Date of Application</CustomFormLabel>
                                                <FormControl fullWidth>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            required
                                                            data-testid="sowing_date_testid"
                                                            className="customDatePicker custom-textbox"
                                                            placeholder="DD/MM/YY"
                                                            format="DD/MM/yyyy"
                                                            inputVariant="outlined"
                                                            color="secondary"
                                                            keyboardIcon={<DateRange style={webStyle.customDateIcon} />}
                                                            minDate={this.state.minimumDate}
                                                            minDateMessage={"Please select a valid date"}
                                                            maxDate={moment()}
                                                            maxDateMessage={"Selected date cannot be a future date"}
                                                            name="date_of_application"
                                                            value={form.date_of_application}
                                                            onChange={(newDate) => this.handleChange("date_of_application", newDate, formIndex)}
                                                            error={Boolean(form.errors.date_of_application)}
                                                            helperText={form.errors.date_of_application}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} lg={6} className="text-field-container">
                                                <CustomFormLabel>Nutrient Quantity</CustomFormLabel>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        className="textfield-with-select"
                                                        data-testid="nutrient_quantity_value_testid"
                                                        fullWidth
                                                        placeholder="Enter Quantity"
                                                        type="number"
                                                        variant="outlined"
                                                        color="secondary"
                                                        name="nutrient_quantity_value"
                                                        value={form.nutrient_quantity_value}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("nutrient_quantity_value", event.target.value, formIndex)}
                                                        error={Boolean(form.errors.nutrient_quantity_value) || Boolean(form.errors.nutrient_quantity_unit)}
                                                        helperText={form.errors.nutrient_quantity_value || form.errors.nutrient_quantity_unit}
                                                        InputProps={{
                                                            endAdornment: this.state.unitOfNutrientQuantityList.length === 1 || !this.state.unitOfNutrientQuantityList.length ?
                                                                <>
                                                                    <CustomUnitLabel>
                                                                        <Typography noWrap>                                                                            
                                                                            {getLabelFromId(form.nutrient_quantity_unit, this.state.unitOfNutrientQuantityList)}                                                                            
                                                                        </Typography>
                                                                    </CustomUnitLabel>
                                                                </> :
                                                                (
                                                                    <SelectWithInput
                                                                        className="select-with-input"
                                                                        IconComponent={KeyboardArrowDown}
                                                                        data-testid="nutrient_quantity_unit_testid"
                                                                        value={form.nutrient_quantity_unit}
                                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("nutrient_quantity_unit", event.target.value, formIndex)}
                                                                        error={Boolean(form.errors.nutrient_quantity_unit)}
                                                                    >
                                                                        <MenuItem value={""}>
                                                                            <span className="opcity-48"><em>None</em></span>
                                                                        </MenuItem>
                                                                        {
                                                                            this.state.unitOfNutrientQuantityList.map((unit, index) => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={unit.id}
                                                                                        value={unit.id}
                                                                                    >
                                                                                        {unit.attributes.unit_name}
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </SelectWithInput>
                                                                ),
                                                                inputProps: inputNutrientQuanity
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} lg={6} className="text-field-container">
                                                <CustomFormLabel>Fertilizer Cost</CustomFormLabel>
                                                <FormControl>
                                                    <TextField
                                                        className="inputIconTextFieldCustom custom-textbox"
                                                        type="number"
                                                        variant="outlined"
                                                        color="secondary"
                                                        placeholder="Total Cost"
                                                        data-testid="fertilizer_cost_testid"
                                                        InputProps={inputpropFertilizerCost}
                                                        onKeyDown={keyDown}
                                                        name="fertilizer_cost"
                                                        value={form.fertilizer_cost}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("fertilizer_cost", event.target.value, formIndex)}
                                                        error={Boolean(form.errors.fertilizer_cost)}
                                                        helperText={form.errors.fertilizer_cost}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} lg={6} className="text-field-container">
                                                <CustomFormLabel>Labor Cost</CustomFormLabel>
                                                <FormControl>
                                                    <TextField
                                                        className="inputIconTextFieldCustom custom-textbox"
                                                        type="number"
                                                        variant="outlined"
                                                        color="secondary"
                                                        placeholder="Total Cost"
                                                        data-testid="labor_cost_testid"
                                                        InputProps={inputpropLaborCost}
                                                        onKeyDown={keyDown}
                                                        name="labor_cost"
                                                        value={form.labor_cost}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("labor_cost", event.target.value, formIndex)}
                                                        error={Boolean(form.errors.labor_cost)}
                                                        helperText={form.errors.labor_cost}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {this.state.nutrientManagementForms.length > 1 && (!this.props.isEditNutrientModalOpen || !Boolean(form.id))&& (
                                                <Box
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                    }}
                                                >
                                                    <IconButton
                                                        style={{ padding: 0 }}
                                                        data-testid={`remove_activity_button_${formIndex}`}
                                                        onClick={() => this.handleRemoveEntry(formIndex)}
                                                    >
                                                        <img
                                                            src={deleteIcon}
                                                            alt="Delete activity"
                                                            style={{ width: "24px", height: "24px" }}
                                                        />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </Grid>                                        
                                    </CustomBox>
                                    <CustomHR />
                                </>
                            )
                        })}
                        
                        {/* BUTTONS */}
                        <ButtonGrid>
                            <Button
                                type="submit"
                                style={{
                                    ...webStyle.cropSubmitButton,
                                    background: "#F0EBE0",
                                }}
                                data-testid={`add_activity_button`}
                                onClick={this.handleAddAnotherEntry}
                            >
                                <Add style={{ marginRight: "8px", opacity: "64%" }} />
                                <Typography
                                    style={{
                                        ...webStyle.cropSubmitButtonText,
                                        color: "#1C2324",
                                        opacity: "64%",
                                    }}
                                >
                                    Add another Entry
                                </Typography>
                            </Button>
                            <Button
                                type="submit"
                                style={{ ...webStyle.cropSubmitButton, background: "#DF5200", }}
                                data-testid="submit_button_testid"
                                onClick={this.onSubmit}
                                disabled={this.state.createActivityLoading || this.state.editActivityLoading}
                            >
                                    {this.state.createActivityLoading || this.state.editActivityLoading?
                                        <CircularProgress size={20} style={{ color: "#fff" }} />
                                            : <Typography style={webStyle.cropSubmitButtonText}>{this.props.isEditNutrientModalOpen ? "Update" : "Save"}</Typography>}
                            </Button>
                        </ButtonGrid>
                        </form>
                        }
                    </Box>
                </Card>
            </Modal>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            {this.props.isNutrientModalOpen && this.renderNutrientForm()}
            
            {this.state.isErrorModalOpen &&
                <ErrorModal
                    open={this.state.isErrorModalOpen}
                    errorTitle={this.state.ErrorModalTitle}
                    errorSubTitle={this.state.ErrorModalMessage}
                    BtnTitle="Okay"
                    handleBtnClick={this.handleCloseErrorModal}
                />
            }                
            </>
            // Customizable Area End


        )
    }
}

// Customizable Area Start
const CustomBoxHeader = styled(Box)({
    padding: "0px 32px 24px 32px",
    "@media (max-width:600px)": {
        padding: "0 24px 24px 24px",
    },
});

const ModalHeader = styled(Box)({
    display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "32px 32px 16px 32px",
        "@media (max-width:600px)": {
            padding: "32px 24px 16px 24px",
        },
})

const CustomSelectDD = styled(Select)({
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent !important"
    },
    "& .MuiSelect-iconOutlined":{
        right:"10px !important"
    }
})
const CustomArrowIcon = styled(KeyboardArrowDown)({
    fontSize:"26px",    
    color: "#1C2324",
    opacity: 0.40
});
const CustomBox = styled(Box)({
    padding: "24px 32px",
    "@media (max-width:600px)": {
      padding: "24px",
    },
  });

const CustomHR = styled(Divider)({
    background: "#F0EBE0",
    width: "100%",
    height: "2px",
})

const CustomFormLabel = styled(FormLabel)({
    fontStyle:"normal",
    fontWeight:400,
    fontSize:"14px",
    lineHeight:"22px",
    color:"#1C2324",
    opacity:0.64,
    marginBottom:"8px"
})

const CustomButton = styled(Button)({
    color: "#006A77",
    margin: "6px 0 0 12px",
    fontSize: "12px",
    fontWeight: 500,
    "& .MuiButton-startIcon": {
        marginRight: "2px ",
    },
});

const CustomFormControlLabel = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
        width: "100%",
    },
});

const SelectWithInput = styled(Select)({
    '&.select-with-input':{
      height:"28px !important"
    },
    '& .MuiInputBase-formControl': {
      height: "auto"
    },
    '&.select-with-input .MuiSelect-iconOutlined': {
        right: "0 !important"
    },
    '&.select-with-input .MuiSelect-root': {
      width: "83px",
      height: "28px",
      padding: "12px !important",
      boxSizing: "border-box",
      lineHeight: "2px",
      textAlign: "left",
      fontSize: "14px",
      borderRadius: "8px",
      background: "#F0EBE0",
    }
  })

const CustomUnitLabel = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "83px",
    height: "28px",
    padding: "0px 12px",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
})

const SearchBoxWrapper = styled(Box)({
    margin: "8px 16px",
    background: "#FCFAF7",
    borderRadius: "28px",
    border: "2px solid #F0EBE0",
    '& .custom_search_input>.MuiInputAdornment-positionStart': {
        margin: "0 6px 0 12px !important"
    }
})
const ButtonGrid = styled(Grid)({
    padding: "24px 32px 32px 32px",
    rowGap: "32px",
    display:"flex",
    flexDirection: "column",
    "@media (max-width:600px)": {
        padding: "24px",
    },
})

const webStyle = {
    secondaryModalBox: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        height: "100%",
    } as React.CSSProperties,
    modalBackdropProps: {
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    } as React.CSSProperties,
    AddCardBoxSuccess: {
        borderRadius: "12px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        maxWidth: "712px",
        flexDirection: "column",
        margin: "0",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95%",
        maxHeight: "95%",
        overflow: "hidden",
    } as React.CSSProperties,
    boxContainer: {
        fontSize: "16px",
        height: "100%",
        overflowY: "auto",
    } as React.CSSProperties,
    headerLeft: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    } as React.CSSProperties,
    headerTitle: {
        color: "#1C2324",
        fontFamily: "IBM Plex Sans",
        fontSize: "20px",
        fontWeight: 500,
    } as React.CSSProperties,
    headerImage: {
        width: "48px",
        height: "48px",
        objectFit: "cover",
    } as React.CSSProperties,
    // FORM
    customDateIcon: {
        color: "#1C2324",
        opacity: "0.48",
      } as React.CSSProperties,
      inputFieldStyle:{
        height: "56px"
      } as React.CSSProperties,
      cropDropDownLable: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#1C2324",
      } as React.CSSProperties,
      cropUnitText: {
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 400,
        fontStyle: "italic",
      } as React.CSSProperties,
      cropFormLoaderBox:{
        height:"100%",
        minHeight:"550px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }as React.CSSProperties,
    // SUBMIT BUTTON
    cropSubmitButton: {
        borderRadius: "28px",
        width: "100%",
        paddingBlock: "14px",
        height:"56px"
    } as React.CSSProperties,
    cropSubmitButtonText: {
        color: "#FFF",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        gap: 8,
    } as React.CSSProperties,
}
// Customizable Area End
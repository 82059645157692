import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Tabs,
    Tab,
    styled,
    TextField,
    FormControl,
    IconButton
} from "@material-ui/core";
import SimpleDropdownController, { ILanguageObject, Props, configJSON, isInputDisable } from "./SimpleDropdownController.web";
import { AddSVG, RemoveSVG } from "../assets";
import { LoadingButton, renderErrorMessage } from "../../../../components/src/AdminConsoleHelper";

// Customizable Area End

export default class SimpleDropdown extends SimpleDropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start   
        const { selectedTab, defaultForm, formArray } = this.state;
        const initialForm = defaultForm[selectedTab] as ILanguageObject
        // Customizable Area End


        return (
            // Customizable Area Start
            <>
                <Box>
                    <PaperWrapper square style={{ background: "transparent" }}>
                        <Tabs
                            className="tabs-wrapper"
                            variant="fullWidth"
                            value={selectedTab}
                            onChange={this.handleChangeTab}
                            data-testid="tab-wrapper"
                        >
                            <CustomTab label="English" value={configJSON.TAB.ENGLISH} data-testid="english-tab" />
                            <CustomTab label="Gujarati" value={configJSON.TAB.GUJARATI} data-testid="gujarati-tab" />
                            <CustomTab label="Hindi" value={configJSON.TAB.HINDI} data-testid="hindi-tab" />
                        </Tabs>
                    </PaperWrapper>
                    <Box style={style.listWrapper}>
                        {formArray.length ? formArray.map((form, formIndex) => {
                            const existingForm = form[selectedTab] as ILanguageObject
                            return (
                                <>
                                    {!form?._destroy ?
                                        <Box style={{ ...style.inputWrapper, marginBottom: "8px" }}>
                                            <FormControl fullWidth>
                                                <CustomTextField
                                                    autoComplete="off"
                                                    type="text"
                                                    variant="outlined"
                                                    color="secondary"
                                                    placeholder="Add another option"
                                                    data-testid="text_input"
                                                    name="add_another_option"
                                                    disabled={isInputDisable(form,this.props.dropdownName)}
                                                    value={existingForm?.value}                                                    
                                                    onChange={(event) => this.handleChangeInput(selectedTab, formIndex, "add_another_option", event.target.value)}
                                                />
                                                {renderErrorMessage(existingForm?.error)}
                                            </FormControl>
                                            <IconButton
                                                disabled={isInputDisable(form,this.props.dropdownName)}
                                                data-testid={`remove-input-testid-${formIndex}`}
                                                style={{...style.iconBtn,visibility:isInputDisable(form,this.props.dropdownName)?"hidden":"visible"}}
                                                onClick={() => this.handleRemoveInput(form, formIndex)}>
                                                <img src={RemoveSVG} />
                                            </IconButton>
                                        </Box>
                                        :
                                        <></>
                                    }
                                </>)
                        }) : <></>}
                        <Box style={style.inputWrapper}>
                            <FormControl fullWidth>
                                <CustomTextField
                                    autoComplete="off"
                                    type="text"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder="Add another option"
                                    data-testid="text_input_default"
                                    name="add_another_option"
                                    value={initialForm?.value}
                                    onChange={(event) => this.handleChangeInputDefault("add_another_option", event.target.value, selectedTab)}
                                />
                                {renderErrorMessage(initialForm?.error)}
                            </FormControl>
                            <IconButton
                                data-testid="add-input-testid"
                                style={style.iconBtn}
                                onClick={this.handleAddAnotherInput}>
                                <img src={AddSVG} />
                            </IconButton>
                        </Box>

                        <LoadingButton
                            data-testid="save-btn"
                            btnStyle={style.saveBtn}
                            loadingStyle={style.loadingStyle}
                            title={"SAVE"}
                            isLoading={this.props.submitLoading}
                            onClick={this.handleSubmit}
                        />
                    </Box>
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start

const PaperWrapper = styled(Paper)({
    background: "transparent",
    "& .MuiTabs-root": {
        minHeight: "21px",
    }
})
const CustomTab = styled(Tab)({
    fontWeight: 500,
    fontSize: "14px",
    color: "#1C2324",
    opacity: "0.48 !important",
    borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
    padding: "0px",
    alignItems: "end",
    minHeight: "21px !important",
    minWidth: "0px !important",
    "&.Mui-selected": {
        color: "#DF5200 !important",
        opacity: "1 !important",
    }
})

const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "40px"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
        backgroundColor: "#F8F6F1 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0EBE0 !important",
    },
    "& .Mui-disabled": {
        opacity: 1,
        color: "rgba(0, 0, 0, 0.87)"
    }
})

const style = {
    listWrapper: {
        padding: "12px 16px 16px 16px"
    } as React.CSSProperties,
    inputWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    } as React.CSSProperties,
    saveBtn: {
        width: "100%",
        height: "40px",
        backgroundColor: "#DF5200",
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center",
        fontWeight: 600,
        borderRadius: "28px",
        marginTop: "16px"
    } as React.CSSProperties,
    loadingStyle: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
    iconBtn: {
        margin: "0 0 0 16px",
        padding: "0"
    } as React.CSSProperties,
}
// Customizable Area End
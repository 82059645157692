import React from "react";
import {
  AccordionDetails,
  Box,
  Table,
  TableBody,
  IconButton,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Tooltip
} from "@material-ui/core";
import { IPestManagementActivity, CropDetailIdEntityCommon } from "../interface.web";
import { dateDDMMYYYY, returnTruthyString } from "../helper";

interface WeedManagementActivityDetailsPros {
  data: IPestManagementActivity[];
  handleDelete: (id: string) => void;
  handleEdit: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined) => void;
  handleShareLink: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined, message: string) => void;
}

const PestManagementActivityDetails = ({
  data = [],
  handleDelete,
  handleEdit,
  handleShareLink
}: WeedManagementActivityDetailsPros) => {
  const getPreFixAndSuffix = (label: string, value: string | Date | number) => {
    if (label.toLowerCase().includes("cost")) {
      return "₹ " + value.toString() + "/-"
    } else {
      return value.toString()
    }
  }

  const getDateLabel = (pestType: string) => {
    if (pestType.toLowerCase().includes("trap")){
      return "Date of Installation"
    } else if(pestType.toLowerCase().includes("bio")) {
      return "Date of Release"
    } else {
      return "Date of Application"
    }
  }

  const flatten:any = (arr: [CropDetailIdEntityCommon][]) => {
    const flatArr = [];
    for (const val of arr) {
      if (Array.isArray(val)) {
        flatArr.push(...flatten(val));
      } else {
        flatArr.push(val);
      }
    }
    return flatArr;
  }

  // RENDER TABLE ROW
  const rendertableRow = (label: string, value: string | Date | number | null | undefined, unit?: string) => {
    const formattedValue = (value !== "" && value !== null && value !== undefined) ? getPreFixAndSuffix(label, value) : "-";
    return (
      <TableRow>
        <TableCell
          style={{
            ...styleWeb.tableCell,
            ...styleWeb.opacity64,
          }}
        >
          {label}
        </TableCell>
        <TableCell style={styleWeb.tableCell}>{formattedValue}{" "}{unit}</TableCell>
      </TableRow>
    );
  };

  const renderTable = (data: IPestManagementActivity) => {
    const { id, attributes } = data ?? {};
    const { date_of_application, machine_cost_of_spraying, pesticide_cost, cost_of_input,
      quantity, quantity_unit_id, quantity_pesticide, unit_of_quantity_pesticide_id, number_of_trap,
      crop_id, labor_cost, bio_agent_id, land_detail_id, pest_managment_id, type_of_trap_id,
      pesticide_id, cost_of_trap, freeze_record } = attributes ?? {};

    let crop_id_name = Array.isArray(crop_id) && flatten(crop_id)
    let crop_ids = Array.isArray(crop_id_name) ? crop_id_name?.map((crop: { name: string, id: string }) => String(crop.id)) : []
    let crop_name = Array.isArray(crop_id_name) ?  crop_id_name?.map((crop: { name: string, id: string }) => String(crop?.name)).join(", ") : []
    const disable_crop = freeze_record ? freeze_record : false
    const farm_id = returnTruthyString(land_detail_id && land_detail_id[0]?.id)
    const farm_name = String(land_detail_id && land_detail_id[0]?.name)

    return (
      <Box style={{ width: "100%", borderTop: "1px solid #F0EBE0", }} key={id}>
        <Table>
          <TableHead>
            <TableRow style={styleWeb.tableHead}>
              <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
              <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pest_managment_id && rendertableRow("Pest Management", pest_managment_id[0].name)}
            {pesticide_id && rendertableRow("Pesticide Name", pesticide_id[0].name)}
            {pest_managment_id && rendertableRow(getDateLabel(pest_managment_id[0].name), dateDDMMYYYY(date_of_application))}
            {machine_cost_of_spraying !== null && machine_cost_of_spraying !== undefined && rendertableRow("Machine cost for Spraying", machine_cost_of_spraying)}
            {pesticide_cost !== null && pesticide_cost !== undefined && rendertableRow("Pesticide Cost", pesticide_cost)}
            {quantity_pesticide !== null && quantity_pesticide !== undefined && rendertableRow("Quantity of pesticide", quantity_pesticide, unit_of_quantity_pesticide_id ? unit_of_quantity_pesticide_id[0].name : "")}
            {labor_cost !== null && labor_cost !== undefined && rendertableRow("Labor Cost", labor_cost)}
            {type_of_trap_id && rendertableRow("Type of Trap", type_of_trap_id[0]?.name)}
            {number_of_trap !== null && number_of_trap !== undefined && rendertableRow("Number of Trap", number_of_trap)}
            {cost_of_trap !== null && cost_of_trap !== undefined && rendertableRow("Cost of Trap", cost_of_trap)}
            {bio_agent_id && rendertableRow("Bio-agent Name", bio_agent_id[0]?.name)}
            {quantity !== null && quantity !== undefined && rendertableRow("Quantity", quantity, quantity_unit_id ? quantity_unit_id[0]?.name : "")}
            {cost_of_input !== null && cost_of_input !== undefined && rendertableRow("Cost of Input", cost_of_input)}
          </TableBody>
        </Table>
        <Box style={styleWeb.tableFooter}>
          <Box style={styleWeb.iconsbox} onClick={()=> handleShareLink(id, farm_id, crop_ids, "pest_management_activities")}>
            <IconButton style={styleWeb.iconbutton}>
              <img
                src={require("../share-icons.svg")}
                alt="Share in chat"
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
            <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
          </Box>
          <Box style={styleWeb.iconsbox}>
            <Tooltip title={disable_crop ? "Activity Freezed" : ""}>
              <Box>
                <IconButton style={styleWeb.iconbutton}
                  onClick={() => handleEdit(id, farm_id, crop_ids)}
                  disabled={freeze_record}
                >
                  <img
                    src={require("../edit-icon.svg")}
                    alt="Edit activity"
                    style={styleWeb.actionIcon}
                  />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title={disable_crop ? "Activity Freezed" : ""}>
              <Box>
                <IconButton
                  style={styleWeb.iconbutton}
                  onClick={() => handleDelete(id)}
                  disabled={freeze_record}
                >
                  <img
                    src={require("../delete-icon.svg")}
                    alt="Delete activity"
                    style={styleWeb.actionIcon}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <AccordionDetails
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxHeight: "350px",
        overflow: "auto",
      }}
    >
      {data?.length > 0 ? (
        data.map((item) => {
          return renderTable(item);
        })
      ) : (
        <Box style={{ width: "100%" }}>
          <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
            No data found
          </Typography>
        </Box>
      )}
    </AccordionDetails>
  );
};

export default PestManagementActivityDetails;

const styleWeb = {
  highlightedText: {
    fontWeight: 500,
  } as React.CSSProperties,
  headInfo: {
    fontWeight: 400,
    padding: "8px 12px",
    fontSize: "12px",
    color: "#1C2324",
    border: "1px solid #F0EBE0",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  } as React.CSSProperties,
  iconsbox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer"
  } as React.CSSProperties,
  tableCell: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "50%",
  } as React.CSSProperties,
  tableHead: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "100%",
  } as React.CSSProperties,
  actionIcon: {
    width: "20px",
    height: "20px",
  } as React.CSSProperties,
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  tableFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
  } as React.CSSProperties,
  opacity64: {
    opacity: "64%",
  } as React.CSSProperties,
};

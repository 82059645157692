import React, { Fragment } from "react";

import {
    // Customizable Area Start
    Grid,
    Modal,
    Card,
    Box,
    Typography,
    IconButton,
    FormLabel,
    FormControl,
    Select,
    MenuItem,
    RadioGroup,
    Radio,
    Divider,
    TextField,
    Button,
    styled,
    FormControlLabel,
    InputAdornment,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { getLabelFromId, keyDown } from "../../../components/src/helper";
import { ISelectFarm } from "../../../components/src/interface.web";
import PreSowingActivityController, {
    IInitialValuePreSowing,
    Props,
    configJSON,
} from "./PreSowingActivityController.web";
import moment from "moment";
import { DateRange, Add, KeyboardArrowDown } from "@material-ui/icons";
import {
    closeIconSvg,
    deleteIcon,
    rupeeIconSVG,
    presowingImage,
} from "./assets";
import { NoMenuDataFound, renderErrorMessage } from "../../../components/src/AdminConsoleHelper";
import ErrorModal from "../../../components/src/ErrorModal.web";

const inputPropLaborCost = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 50,
        max: 50000,
        step: 100, // step
    },
}
const inputPropMachineCost = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 0,
        max: 3000,
        step: 100, // step
    },
};

const inputFuelQuanity = {
    min: 0,
    step: 10, // step
}
// Customizable Area End

export default class PreSowingActivity extends PreSowingActivityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderPreSowingForm = () => {
        return (
            <Modal
                open={this.OpenPreSowingModal()}
                style={style.mainModalStyle}
                BackdropProps={{
                    style: style.modalBackdropPropsStyle,
                }}
                data-testid="pre_sowing_activity_modal"
            >
                <Card variant="outlined" style={style.modalCardStyle}>
                    <Box style={style.modalBoxWrapper}>
                        {/* MODAL HEADER */}
                        <ModalHeader>
                            <Box style={style.headerLeftBoxWrapper}>
                                <img src={presowingImage} alt="pre" style={style.headerImage} />
                                <Typography style={style.headerTitle}>Pre Sowing</Typography>
                            </Box>
                            <Box>
                                <IconButton style={{ padding: 0 }} onClick={this.props.handleCloseAddActivityModal} >
                                    <img src={closeIconSvg} style={{ width: "24px", height: "24px" }} />
                                </IconButton>
                            </Box>
                        </ModalHeader>
                        {/* MODAL BODY */}
                        {this.state.fetchPreSowingLoading ?
                            <Box style={style.formLoaderBox}>
                                <CircularProgress size={40} color="secondary" />
                            </Box>
                            :
                            <form noValidate autoComplete="off" data-testid="pre-sowing-form-testid" onSubmit={(e) => e.preventDefault()}>
                                <CustomBoxHeader>
                                    <Grid container spacing={2}>
                                        <CustomFieldGrid item xs={12} lg={12} >
                                            <CustomFormLabel>Select Farm</CustomFormLabel>
                                            <CustomSelectDD
                                                disabled={this.props.isEditPreSowingModalOpen}
                                                IconComponent={CustomArrowIcon}
                                                style={style.inputFieldStyle}
                                                data-testid="select_farm_testid"
                                                variant="outlined"
                                                color="secondary"
                                                inputProps={{ "aria-label": "Without label" }}
                                                displayEmpty
                                                name="select_farm_id"
                                                renderValue={(value) => {
                                                    if (this.state.selectedFarmId) {
                                                        return this.state.farmNamesList.find((item) => item.attributes.id == this.state.selectedFarmId)?.attributes?.name
                                                    } else if (!this.state.selectedFarmId) {
                                                        return (
                                                            <span className="opcity-48">Select Farm</span>
                                                        );
                                                    }
                                                }}
                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleChangeSelectFarm(event.target.value) }}
                                                error={Boolean(this.state.selectedFarmIdError)}
                                                value={this.state.selectedFarmId}
                                            >
                                                <MenuItem value={""} disabled>
                                                    <span className="opcity-48">Select Farm</span>
                                                </MenuItem>
                                                {this.state.farmNamesList.map((item: ISelectFarm) => {
                                                    return (
                                                        <MenuItem
                                                            style={{ paddingBlock: "0px" }}
                                                            value={item.attributes.id}
                                                            key={item.id}
                                                        >
                                                            <RadioGroup
                                                                value={this.state.selectedFarmId}
                                                                style={{
                                                                    width: "100%",
                                                                    borderBottom: "1px solid #F0EBE0",
                                                                }}
                                                            >
                                                                <CustomFormControlLabel
                                                                    value={item.attributes.id}
                                                                    control={<Radio />}
                                                                    style={{ width: "100%" }}
                                                                    label={
                                                                        <Box
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography style={style.cropDropDownLabel}>
                                                                                    {item.attributes.name}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Typography style={style.cropUnitText}>
                                                                                {item.attributes.farm_area}{" "}{item.attributes?.unit_farm_area_id?.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </RadioGroup>
                                                        </MenuItem>
                                                    );
                                                })}
                                                <CustomButton
                                                    onClick={this.openAddFarm}
                                                    startIcon={<Add style={{ width: "18px", height: "18px" }} />}
                                                >
                                                    Add Farm
                                                </CustomButton>
                                            </CustomSelectDD>
                                            {renderErrorMessage(this.state.selectedFarmIdError)}
                                        </CustomFieldGrid>
                                    </Grid>
                                </CustomBoxHeader>
                                <CustomHR />

                                {/* MAP FORMS */}
                                {this.state.preSowingForms.map((form: IInitialValuePreSowing, formIndex: number) => {
                                    return (
                                        <>
                                            <CustomBox key={formIndex}>
                                                <Grid container spacing={2} style={{ position: "relative" }}>
                                                    <CustomFieldGrid item xs={12} lg={12}>
                                                        <CustomFormLabel>Pre-Sowing Activity</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <CustomSelectDD
                                                                IconComponent={CustomArrowIcon}
                                                                data-testid="pre_sowing_activity_id_testid"
                                                                variant="outlined"
                                                                color="secondary"
                                                                style={style.inputFieldStyle}
                                                                inputProps={{ "aria-label": "Without label" }}
                                                                displayEmpty
                                                                name="pre_sowing_activity_id"
                                                                value={form.pre_sowing_activity_id}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("pre_sowing_activity_id", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.pre_sowing_activity_id)}
                                                            >
                                                                <MenuItem value={""} disabled>
                                                                    <span className="opcity-48">Select Pre-Sowing Activity</span>
                                                                </MenuItem>
                                                                {this.state.activityNamesList.length > 0 ?
                                                                    this.state.activityNamesList.map((each) => (
                                                                        <MenuItem key={each.id} value={each.id}>
                                                                            {each.name}
                                                                        </MenuItem>
                                                                    ))
                                                                    : NoMenuDataFound()
                                                                }
                                                            </CustomSelectDD>
                                                            {renderErrorMessage(form.errors.pre_sowing_activity_id)}
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Date</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <KeyboardDatePicker
                                                                    required
                                                                    data-testid="date_testid"
                                                                    className="customDatePicker custom-textbox"
                                                                    placeholder="DD/MM/YY"
                                                                    format="DD/MM/yyyy"
                                                                    inputVariant="outlined"
                                                                    color="secondary"
                                                                    keyboardIcon={<DateRange style={style.customDateIcon} />}
                                                                    minDate={moment().subtract(360, "days").toDate()}
                                                                    minDateMessage={"Please select a valid date"}
                                                                    maxDate={moment()}
                                                                    maxDateMessage={"Selected date cannot be a future date"}
                                                                    name="date"
                                                                    value={form.date}
                                                                    onChange={(newDate) => this.handleChange("date", newDate, formIndex)}
                                                                    error={Boolean(form.errors.date)}
                                                                    helperText={form.errors.date}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Season</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <CustomSelectDD
                                                                IconComponent={CustomArrowIcon}
                                                                data-testid="crop_season_id_testid"
                                                                variant="outlined"
                                                                color="secondary"
                                                                style={style.inputFieldStyle}
                                                                inputProps={{ "aria-label": "Without label" }}
                                                                displayEmpty
                                                                name="crop_season_id"
                                                                value={form.crop_season_id}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("crop_season_id", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.crop_season_id)}
                                                            >
                                                                <MenuItem value={""} disabled>
                                                                    <span className="opcity-48">Select Season</span>
                                                                </MenuItem>
                                                                {this.state.cropSeasonNamesList.length > 0 ?
                                                                    this.state.cropSeasonNamesList.map((each) => (
                                                                        <MenuItem key={each.id} value={each.id}>
                                                                            {each.name}
                                                                        </MenuItem>
                                                                    ))
                                                                    : NoMenuDataFound()
                                                                }
                                                            </CustomSelectDD>
                                                            {renderErrorMessage(form.errors.crop_season_id)}
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Labor Cost</CustomFormLabel>
                                                        <FormControl>
                                                            <TextField
                                                                className="inputIconTextFieldCustom custom-textbox"
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                placeholder="Enter Labor Cost"
                                                                data-testid="labor_cost_testid"
                                                                InputProps={inputPropLaborCost}
                                                                onKeyDown={keyDown}
                                                                name="labor_cost"
                                                                value={form.labor_cost}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("labor_cost", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.labor_cost)}
                                                                helperText={form.errors.labor_cost}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Machine Cost</CustomFormLabel>
                                                        <FormControl>
                                                            <TextField
                                                                className="inputIconTextFieldCustom custom-textbox"
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                placeholder="Rupees/Acres"
                                                                data-testid="machine_cost_testid"
                                                                InputProps={inputPropMachineCost}
                                                                onKeyDown={keyDown}
                                                                name="machine_cost"
                                                                value={form.machine_cost}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("machine_cost", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.machine_cost)}
                                                                helperText={form.errors.machine_cost}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={12}>
                                                        <CustomFormLabel>Fuel Consumption <span style={style.optinalSpan}>- Optional</span></CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                className="textfield-with-select"
                                                                data-testid="fuel_consumption_value_testid"
                                                                fullWidth
                                                                placeholder="Enter Quantity"
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                onKeyDown={keyDown}
                                                                name="fuel_consumption_value"
                                                                value={form.fuel_consumption_value}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("fuel_consumption_value", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.fuel_consumption_value) || Boolean(form.errors.fuel_consumption_unit)}
                                                                helperText={form.errors.fuel_consumption_value || form.errors.fuel_consumption_unit}
                                                                InputProps={{
                                                                    endAdornment: this.state.fuelUnitList.length === 1 || !this.state.fuelUnitList.length ?
                                                                        <>
                                                                            <CustomUnitLabel>
                                                                                <Typography noWrap>
                                                                                    {getLabelFromId(form.fuel_consumption_unit, this.state.fuelUnitList)}
                                                                                </Typography>
                                                                            </CustomUnitLabel>
                                                                        </> :
                                                                        (
                                                                            <SelectWithInput
                                                                                className="select-with-input"
                                                                                IconComponent={KeyboardArrowDown}
                                                                                data-testid="fuel_consumption_unit_testid"
                                                                                value={form.fuel_consumption_unit}
                                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("fuel_consumption_unit", event.target.value, formIndex)}
                                                                                error={Boolean(form.errors.fuel_consumption_unit)}
                                                                            >
                                                                                <MenuItem value={""} disabled>
                                                                                    <span className="opcity-48"><em>None</em></span>
                                                                                </MenuItem>
                                                                                {
                                                                                    this.state.fuelUnitList.map((unit, index) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={unit.id}
                                                                                                value={unit.id}
                                                                                            >
                                                                                                {unit.attributes.unit_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </SelectWithInput>
                                                                        ),
                                                                    inputProps: inputFuelQuanity
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    {this.state.preSowingForms.length > 1 && (!this.props.isEditPreSowingModalOpen || !Boolean(form.id)) && (
                                                        <Box
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 0,
                                                            }}
                                                        >
                                                            <IconButton
                                                                style={{ padding: 0 }}
                                                                data-testid={`remove_activity_button_${formIndex}`}
                                                                onClick={() => this.handleRemoveEntry(formIndex)}
                                                            >
                                                                <img
                                                                    src={deleteIcon}
                                                                    alt="Delete activity"
                                                                    style={{ width: "24px", height: "24px" }}
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            </CustomBox>
                                            <CustomHR />
                                        </>
                                    )
                                })}

                                {/* BUTTONS */}
                                <ButtonGrid>
                                    <Button type="submit"
                                        style={{ ...style.commonButton, background: "#F0EBE0" }}
                                        data-testid={`add_activity_button`}
                                        onClick={this.handleAddAnotherEntry}
                                    >
                                        <Add style={{ marginRight: "8px", opacity: "64%" }} />
                                        <Typography style={{ ...style.commonButtonText, opacity: "64%", color: "#1C2324" }}>
                                            Add another Entry
                                        </Typography>
                                    </Button>
                                    <Button type="submit"
                                        style={{ ...style.commonButton, background: "#DF5200", }}
                                        onClick={this.onSubmitForm}
                                        data-testid="submit_button_testid"
                                        disabled={this.state.createPreSowingLoading || this.state.editPreSowingLoading}
                                    >
                                        {this.state.createPreSowingLoading || this.state.editPreSowingLoading ?
                                            <CircularProgress size={20} style={{ color: "#fff" }} />
                                            :
                                            <Typography style={style.commonButtonText}>{this.props.isEditPreSowingModalOpen ? "Update" : "Save"}</Typography>
                                        }
                                    </Button>
                                </ButtonGrid>
                            </form>
                        }
                    </Box>
                </Card>
            </Modal>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {this.props.isPreSowingModalOpen && this.renderPreSowingForm()}

                {this.state.customModalOpen &&
                    <ErrorModal
                        data-testid={"error_modal_testid"}
                        open={this.state.customModalOpen}
                        BtnTitle="Okay"
                        errorTitle={this.state.customModalTitle}
                        errorSubTitle={this.state.customModalMessage}
                        handleBtnClick={this.handleCloseCustomModal}
                    />
                }
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CustomBoxHeader = styled(Box)({
    padding: "0px 32px 24px 32px",
    "@media (max-width:600px)": {
        padding: "0 24px 24px 24px",
    },
});

const CustomHR = styled(Divider)({
    background: "#F0EBE0",
    width: "100%",
    height: "2px",
})

const CustomFieldGrid = styled(Grid)({
    display: "flex !important",
    flexDirection: "column",
    minHeight: "93px",
})

const CustomFormLabel = styled(FormLabel)({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    opacity: 0.64,
    marginBottom: "8px"
})

const CustomBox = styled(Box)({
    padding: "24px 32px",
    "@media (max-width:600px)": {
        padding: "24px",
    },
});

const CustomButton = styled(Button)({
    color: "#006A77",
    margin: "6px 0 0 12px",
    fontSize: "12px",
    fontWeight: 500,
    "& .MuiButton-startIcon": {
        marginRight: "2px ",
    },
});

const CustomFormControlLabel = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
        width: "100%",
    },
});

const CustomSelectDD = styled(Select)({
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent !important"
    },
    "& .MuiSelect-iconOutlined": {
        right: "10px !important"
    }
})

const CustomArrowIcon = styled(KeyboardArrowDown)({
    fontSize: "26px",
    color: "#1C2324",
    opacity: 0.40
});

const CustomUnitLabel = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "83px",
    height: "28px",
    padding: "0px 12px",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
})
const SelectWithInput = styled(Select)({
    '&.select-with-input': {
        height: "28px !important"
    },
    '& .MuiInputBase-formControl': {
        height: "auto"
    },
    '&.select-with-input .MuiSelect-iconOutlined': {
        right: "0 !important"
    },
    '&.select-with-input .MuiSelect-root': {
        width: "83px",
        height: "28px",
        padding: "12px !important",
        boxSizing: "border-box",
        lineHeight: "2px",
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "8px",
        background: "#F0EBE0",
    }
})

const ModalHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "32px 32px 16px 32px",
    "@media (max-width:600px)": {
        padding: "32px 24px 16px 24px",
    },
})

const ButtonGrid = styled(Grid)({
    padding: "24px 32px 32px 32px",
    rowGap: "32px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:600px)": {
        padding: "24px",
    },
})

const style = {
    mainModalStyle: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        height: "100%",
    } as React.CSSProperties,

    modalBackdropPropsStyle: {
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    } as React.CSSProperties,

    modalCardStyle: {
        borderRadius: "12px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        maxWidth: "712px",
        flexDirection: "column",
        margin: "0",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95%",
        maxHeight: "95%",
        overflow: "hidden",
    } as React.CSSProperties,

    modalBoxWrapper: {
        fontSize: "16px",
        height: "100%",
        overflowY: "auto",
    } as React.CSSProperties,

    headerLeftBoxWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    } as React.CSSProperties,

    headerTitle: {
        color: "#1C2324",
        fontFamily: "IBM Plex Sans",
        fontSize: "20px",
        fontWeight: 500,
    } as React.CSSProperties,

    headerImage: {
        width: "48px",
        height: "48px",
        objectFit: "cover",
    } as React.CSSProperties,

    formLoaderBox: {
        height: "100%",
        minHeight: "550px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,

    // FORM ELEMENTS STYLE
    inputFieldStyle: {
        height: "56px"
    } as React.CSSProperties,

    cropDropDownLabel: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#1C2324",
    } as React.CSSProperties,

    customDateIcon: {
        color: "#1C2324",
        opacity: "0.48",
    } as React.CSSProperties,

    cropUnitText: {
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 400,
        fontStyle: "italic",
    } as React.CSSProperties,

    optinalSpan: {
        fontStyle: "italic",
        fontSize: "12px"
    } as React.CSSProperties,
    // BUTTONS STYLE
    commonButton: {
        width: "100%",
        borderRadius: "28px",
        paddingBlock: "14px",
        height: "56px"
    } as React.CSSProperties,

    commonButtonText: {
        color: "#FFF",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "IBM Plex Sans",
        whiteSpace: "nowrap",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        gap: 8,
    } as React.CSSProperties,
}
// Customizable Area End

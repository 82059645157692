import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Input,
    InputAdornment,
    styled,
    Grid,
    IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PersonalDetailsDropdownController, { Props, configJSON } from "./PersonalDetailsDropdownController.web";
import { DataNotFound, LoaderCenter } from "../../../../components/src/AdminConsoleHelper";
import { IDropdownDetails, IHeadings } from "../../../../components/src/interface.web";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleDropdown from "./SimpleDropdown.web";
import ErrorModal from "../../../../components/src/ErrorModal.web";
import { DropdownKey } from "../../../../components/src/helper";
import ClearIcon from '@material-ui/icons/Clear';

const formateSimpleDropdownData = (data: IDropdownDetails[]) => {
    let formattedData = []
    formattedData = data[0]?.data.map((obj) => {
        return {
            "id": obj.id,
            "english": {
                value: obj.name_english,
                error: ""
            },
            "gujarati": {
                value: obj.name_gujrati,
                error: ""
            },
            "hindi": {
                value: obj.name_hindi,
                error: ""
            }
        }
    })

    return formattedData
}
// Customizable Area End

export default class PersonalDetailsDropdown extends PersonalDetailsDropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderAccordianDetails = (item: IHeadings) => {
        return (
            <SimpleDropdown id="" navigation={undefined} data-testid="simple-compnent"
                dataArray={formateSimpleDropdownData(this.state.dropdownDetailsData)}
                onSubmit={this.handleSubmit}
                submitLoading={this.state.updateDropdownLoading}
            />
        )
    }
    renderAccordians = (data: IHeadings[]) => {
        return (
            <>
                {data.map((item, itemIndex) => {
                    return (
                        <Box>
                            <CustomAccordian
                                expanded={this.state.currentExpanded === item.heading}
                                onChange={this.handleCurrentExpanded(item.heading)}
                                data-testid={`accordian-${itemIndex}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ width: "28px", height: "28px", color: "#1C2324", opacity: 0.72 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className="sub-dropdown-title">
                                            {item.heading}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className="main-accordian-details-wrapper">
                                    {this.state.dropdownDetailsLoading ?
                                        <LoaderCenter loadingStyle={style.smallLoader} /> : <></>}
                                    {(!this.state.dropdownDetailsLoading && this.state.dropdownDetailsData.length) && (this.state.currentExpanded == item.heading) ?
                                        this.renderAccordianDetails(item) : <></>}
                                </AccordionDetails>
                            </CustomAccordian>
                        </Box>
                    )
                })}
            </>
        )
    }
    renderDropdowns = () => {
        const { headingsData } = this.state;

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item lg={6} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(headingsData.slice(0, Math.ceil(headingsData.length / 2)))}
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(headingsData.slice(Math.ceil(headingsData.length / 2)))}
                    </Grid>

                </Grid>
            </Box>
        )
    }
    renderHeader = () => {
        const { subDropdownName } = DropdownKey()
        return (
            <HeaderBox>
                <Typography
                    style={style.headerTitle}>
                    {subDropdownName}
                </Typography>
                <SearchBoxWrapper>
                    <Input fullWidth disableUnderline className="custom_search_input"
                        data-testid="search_dropdown_testid"
                        placeholder="Search here..."
                        type="text"
                        name="search_dropdown"
                        value={this.state.searchValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setSearchValue(event.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon
                                    style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                />
                            </InputAdornment>
                        }
                        endAdornment={
                            this.state.searchValue && (
                                <InputAdornment position="end">
                                    <IconButton style={{padding:4}} onClick={() => { this.setSearchValue("") }}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    />
                </SearchBoxWrapper>
            </HeaderBox>
        )
    }
    renderMsgModal = () => {
        return (
            <>
                <ErrorModal
                    errorSubTitle={this.state.CustomModalMsg}
                    errorTitle={this.state.CustomModalTitle}
                    open={this.state.OpenCustomModal}
                    BtnTitle="Okay"
                    handleBtnClick={this.handleCloseCustomModal}
                />
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.mainWrapper}>
                    {this.renderHeader()}
                    {this.state.fetchHeadingsLoading ? <LoaderCenter /> : <></>}
                    {(!this.state.fetchHeadingsLoading && this.state.headingsData.length)
                        ? this.renderDropdowns() : <></>}
                    {(!this.state.fetchHeadingsLoading && !this.state.headingsData.length)
                        ? <DataNotFound fontStyle={{ ...style.noDataTxt, marginTop: "50px" }} /> : <></>}
                    {this.state.OpenCustomModal ? this.renderMsgModal() : <></>}
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start   

const SearchBoxWrapper = styled(Box)({
    minWidth: "300px",
    height: "40px",
    background: "#FCFAF7",
    borderRadius: "28px",
    border: "1px solid #F0EBE0",
    width: "100%",
    '& .custom_search_input>.MuiInputAdornment-positionStart': {
        margin: "0 4px 0 16px !important"
    },
    '& .custom_search_input>.MuiInputBase-input': {
        margin: "2px"
    },
    "@media (min-width: 959px) and (max-width: 1130px)": {
        minWidth: "200px",
    },
    "@media (max-width: 695px)": {
        minWidth: "200px",
        width: "100%"
    }
})
const HeaderBox = styled(Box)({
    marginBottom: "24px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: "nowrap",
    rowGap: "8px",
    "@media (max-width: 695px)": {
        flexWrap: "wrap",
        width: "100%"
    }
})
const CustomAccordian = styled(Accordion)({
    backgroundColor: "#FCFAF7 !important",
    borderRadius: "12px !important",
    "& .MuiAccordionSummary-root": {
        height: "52px !important",
        minHeight: "52px !important",
        padding: "0 12px 0 16px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
        "padding": "12px !important"
    }
})

const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%"
    } as React.CSSProperties,
    dropdownGrid: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "16px",
        width: "100%"
    } as React.CSSProperties,
    // NO DATA FOUND
    noDataTxt: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#1C2324",
        textAlign: "center",
    } as React.CSSProperties,
    smallLoader: {
        height: "24px",
        width: "24px"
    } as React.CSSProperties,
}
// Customizable Area End

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICreatedNutrient, INutrientManagementForms, INutrientManagementName, ISelectCrop,ISelectFarm, ISelectFarmDataResponse, IUnitDropdown, IUnitDropdownResponse } from "../../../components/src/interface.web";
import { debounce, defaultQuanitityUnit, returnTruthyString, getSowingDate } from "../../../components/src/helper";
import moment from "moment";

export const configJSON = require("./config");

const isValueFalsy = (value:unknown) => value === "" || value === null || value === undefined

const nutrientFormValue = {
    id: "",
    nutrient_name_id: "",
    nutrient_type_id: "",
    date_of_application: new Date(),
    nutrient_quantity_value: "",
    nutrient_quantity_unit: "",
    fertilizer_cost: "",
    labor_cost: "",
    errors: {
        nutrient_name_id: "",
        nutrient_type_id: "",
        date_of_application: "",
        nutrient_quantity_value: "",
        nutrient_quantity_unit: "",
        fertilizer_cost: "",
        labor_cost: "",
    }
}
type FarmIdTypes = string | undefined;
type CropIdTypes = string | string[] | undefined;
export interface Props {
    isNutrientModalOpen: boolean,
    selectedActivity:string | null | number,
    handleCloseAddActivityModal:()=>void,
    userAccountId:string | number,
    openAddCropModalfn: () => void,
    openAddFarmModalfn: () => void,
    // EDIT
    isEditNutrientModalOpen: boolean,
    activityFarmId: FarmIdTypes, // FARM ID
    activityCropId: any, // CROP ID ARR
    selectedyear: number
    onCreateOrUpdate: (status: string) => void;
}
interface S {
    authToken: string | null;
    openNutrientModal: boolean,
    minimumDate: Date; 
    // DROPDOWN STATES
    farmNamesList: ISelectFarm[],
    cropNamesList: ISelectCrop[],
    NutrientNamesList: INutrientManagementName[],
    filteredNutrientNamesList: INutrientManagementName[],
    NutrientTypesList: any[],
    unitOfNutrientQuantityList: IUnitDropdown[],
    // FORM STATES
    initialValues: INutrientManagementForms,
    nutrientManagementForms: INutrientManagementForms[]
    selectedNutrientName: any,
    selectedFarmId: string | number,
    selectedFarmIdError: string,
    selectedCropIdArr: any[],
    selectedCropIdError: string,
    searchNutrientName: string,
    // CREATE
    createActivityLoading: boolean,
    // EDIT
    editActivityLoading: boolean,
    // GET ACTIVITY DETAILS
    getActivityDetailsLoading: boolean,
    // ERROR MODAL
    isErrorModalOpen: boolean,
    ErrorModalTitle: string,
    ErrorModalMessage: string,
}
interface SS {}

interface IFetchActivityDetailsResponse {
    data?: ICreatedNutrient[]
    errors?: string[]
}
// Customizable Area End

export default class NutrientManagementController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    fetchFarmNamesDataApiCallId: string = "";
    fetchCropNamesDataApiCallId: string = "";
    fetchNutrientQuntityUnitDataApiCallId: string = "";
    fetchNutrientManagmentsApiCallId: string = "";
    fetchNutrientTypesApiCallId: string = "";
    createActivityApiCallId: string = "";
    editActivityApiCallId: string = "";
    fetchActivityDetailsApiCallId: string = "";
     
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),            
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            openNutrientModal: this.props.isNutrientModalOpen,
            minimumDate: moment().subtract(360, 'days').toDate(),
            // DROPDOWN STATES
            farmNamesList: [],
            cropNamesList: [],
            NutrientNamesList: [],
            NutrientTypesList: [],
            unitOfNutrientQuantityList: [],
            // FORM STATES
            initialValues: nutrientFormValue,
            nutrientManagementForms: [nutrientFormValue],
            selectedNutrientName: null,
            selectedFarmId: "",
            selectedFarmIdError: "",                        
            selectedCropIdArr: [],
            selectedCropIdError: "",
            searchNutrientName: "",
            filteredNutrientNamesList: [],
            // CREATE
            createActivityLoading: false,
            // EDIT
            editActivityLoading: false,
            // GET ACTIVITY DETAILS
            getActivityDetailsLoading: false,
            // ERROR MODAL
            isErrorModalOpen: false,
            ErrorModalTitle: "Error",
            ErrorModalMessage: "",

            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            switch (apiRequestCallId) {
                case this.fetchFarmNamesDataApiCallId:
                    this.handleFetchFarmNamesDataApiCallId(responseJson);
                    break;
                case this.fetchCropNamesDataApiCallId:
                    this.handleFetchCropNamesDataApiCallId(responseJson);
                    break;
                case this.fetchNutrientManagmentsApiCallId:
                    this.handleFetchNutrientManagmentsApiCallId(responseJson);
                    break;
                case this.fetchNutrientTypesApiCallId:
                    this.handleFetchNutrientTypesApiCallId(responseJson);
                    break;
                case this.fetchNutrientQuntityUnitDataApiCallId:
                    this.handleFetchNutrientQuntityUnitDataApiCallId(responseJson);
                    break;
                // CREATE
                case this.createActivityApiCallId:
                    this.handleCreateActivityApiCallId(responseJson);
                    break;
                // EDIT
                case this.editActivityApiCallId:
                    this.handleEditActivityApiCallId(responseJson);
                    break;
                // GET ACTIVITY DETAILS
                case this.fetchActivityDetailsApiCallId:
                    this.handleFetchActivityDetailsApiCallId(responseJson);
                    break;
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        if ((prevProps.isNutrientModalOpen !== this.props.isNutrientModalOpen) && this.props.isNutrientModalOpen && !this.props.isEditNutrientModalOpen) {
            this.setState({ getActivityDetailsLoading: false })
            this.resetFormValues()
            this.fetchFarmData(this.props.userAccountId);
            this.fetchNutrientQuntityUnitData()
            this.fetchNutrientManagementNamesData()
            this.fetchNutrientTypesData()
        }

        if (prevProps.isEditNutrientModalOpen !== this.props.isEditNutrientModalOpen && this.props.isEditNutrientModalOpen) {
            this.fetchFarmData(this.props.userAccountId);
            this.fetchNutrientQuntityUnitData()
            this.fetchNutrientManagementNamesData()
            this.fetchNutrientTypesData()
            this.fetchActivityDetails();
            this.fetchCropsData(this.props.activityFarmId)
          }
    }


    // API CALLS STARTS
    fetchFarmData = (userAccountId: string | number | null) => {
        if (!userAccountId) return
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
            type: "admin_user"
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchFarmNamesDataApiCallId = reqMessage.messageId;

        const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.userAccountId}`
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    fetchCropsData = (farmId:string | undefined) => {
        if (!this.props.userAccountId || !farmId) return;

        this.setState({cropNamesList:[]})
        const headers = {
          token: this.state.authToken,
          "Content-Type": configJSON.validationApiContentType,
          type: "admin_user",
        };
    
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchCropNamesDataApiCallId = reqMessage.messageId;

        const endpoint = `${configJSON.fetchCropListDataApiEndPoint}?farm_id=${farmId}&user_id=${this.props.userAccountId}`;    
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.GET_METHOD_TYPE);
    
        runEngine.sendMessage(reqMessage.id, reqMessage);
      };

    fetchNutrientManagementNamesData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchNutrientManagmentsApiCallId = reqMessage.messageId;

        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.nutrientManagmentsAPIEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    fetchNutrientTypesData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchNutrientTypesApiCallId = reqMessage.messageId;

        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.NutrientTypeAPIEndPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    fetchNutrientQuntityUnitData = () => {
        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchNutrientQuntityUnitDataApiCallId = reqMessage.messageId;
        const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'nutrient_management'}&dropdown=${'unit_of_nutrient_quantity_nutrient_management'}`

        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    createNutrientActivity = (requestBody: any) => {
        if(!this.props.userAccountId) return
        this.setState({ createActivityLoading: true })

        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createActivityApiCallId = reqMessage.messageId;
        const endpoint = `${configJSON.CreateNutrientAPIEndPoint}?account_id=${this.props.userAccountId}`

        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }    

    editNutrientActivity = (requestBody: any) => {
        if(!this.props.userAccountId) return
        this.setState({ editActivityLoading: true })

        const headers = {
            token: this.state.authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.editActivityApiCallId = reqMessage.messageId;
        const endpoint = `${configJSON.CreateNutrientAPIEndPoint}?account_id=${this.props.userAccountId}`

        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    fetchActivityDetails = () => {
        this.setState({ getActivityDetailsLoading: true })
        const farmId = this.props.activityFarmId
        const cropIds = this.props.activityCropId
        if(farmId && this.props.userAccountId){
            const headers = {
                token: this.state.authToken,
                "Content-Type": configJSON.validationApiContentType,
            };
    
            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.fetchActivityDetailsApiCallId = reqMessage.messageId;
            const endpoint = `admin/bx_block_farm_dairy/nutrients/nutrient_history?account_id=${this.props.userAccountId}&year=${this.props.selectedyear}&land_detail_ids=${farmId}&crop_ids=${cropIds}&for_edit=${true}`
    
            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    
            runEngine.sendMessage(reqMessage.id, reqMessage);
        } else {
            this.setState({ getActivityDetailsLoading: false })            
        }
    }
    // API CALLS ENDS

    // HANDLE API CALLS FUNCTIONS STARTS
    handleFetchFarmNamesDataApiCallId = (response: ISelectFarmDataResponse) => {
        if (response && response.data && response.data.length) {
            this.setState({ farmNamesList: response.data })
        } else {
            this.setState({ farmNamesList: [] })
        }
    }
    handleFetchCropNamesDataApiCallId = (response: { data: ISelectCrop[] } | null | undefined) => {
        if (response && response.data && response.data.length) {
            this.setState({ cropNamesList: response.data }, () => {
                if (this.props.isEditNutrientModalOpen) {
                    this.fetchActivityDetails();
                }
            });
        } else {
            this.setState({ cropNamesList: [] });
        }
    };
    handleFetchNutrientManagmentsApiCallId = (response: INutrientManagementName[] | null | undefined) => {
        if (response && response.length > 0) {
            this.setState({
                NutrientNamesList: response,
                filteredNutrientNamesList: response,
            })
        } else {
            this.setState({ NutrientNamesList: [],filteredNutrientNamesList:[] })
        }
    }
    handleFetchNutrientTypesApiCallId = (response: INutrientManagementName[] | null | undefined) => {
        if (response && response.length > 0) {
            this.setState({ NutrientTypesList: response })
        } else {
            this.setState({ NutrientTypesList: [] })
        }
    }
    handleFetchNutrientQuntityUnitDataApiCallId = (response: IUnitDropdownResponse | null | undefined) => {
        if (response && response.data.length > 0) {
            this.setState({ unitOfNutrientQuantityList: response.data })
            if (this.props.isNutrientModalOpen) {
                this.setState({
                    initialValues: {
                        ...this.state.initialValues,
                        nutrient_quantity_unit: defaultQuanitityUnit(response.data, "kg").unitId
                    },
                    nutrientManagementForms: [{
                        ...this.state.initialValues,
                        nutrient_quantity_unit: defaultQuanitityUnit(response.data, "kg").unitId
                    }]
                })
            }
        } else {
            this.setState({ unitOfNutrientQuantityList: [] })
        }
    }    
    handleCreateActivityApiCallId = (response: { errors: string[] } | { data: any }[]) => {
        if (response && 'errors' in response && !Array.isArray(response)) {
            this.setState({
                createActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0],
            })
        } else if (Array.isArray(response)) {
            this.setState({
                createActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: "Nutrient Activity added Successfully.",
            })
            this.props.onCreateOrUpdate("Success")
            this.handleCloseNutrienActivityModal()
        }
    }
    handleEditActivityApiCallId = (response: { errors: string[] } | { data: any }[]) => {
        if (response && 'errors' in response && !Array.isArray(response)) {
            this.setState({
                editActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: response.errors[0],
            })
        } else if (Array.isArray(response)) {
            this.setState({
                editActivityLoading: false,
                isErrorModalOpen: true,
                ErrorModalTitle: "Success",
                ErrorModalMessage: "Nutrient Activity updated Successfully.",
            })
            this.props.onCreateOrUpdate("Success")
            this.handleCloseNutrienActivityModal()
        }
    }
    handleFetchActivityDetailsApiCallId = (response: IFetchActivityDetailsResponse) => {
        if (response && response.data && response.data.length > 0) {
            this.formateData(response.data)
        } else {
            this.setState({ getActivityDetailsLoading: false })
        }
    }
    // HANDLE API CALLS FUNCTIONS ENDS

    // RESET FUNCTIONS STARTS
    resetFormValues = () => {
        this.setState({
            selectedFarmId: "",
            selectedFarmIdError:"",
            selectedCropIdArr: [],
            selectedCropIdError:"",
            initialValues: nutrientFormValue,
            nutrientManagementForms: [nutrientFormValue],
            minimumDate:  moment().subtract(360, 'days').toDate(),
        })
    }

    debouncedSearchNutrient = debounce((value: string) => this.handleFilteredNutrientNamesList(value), 500);
    
    resetSearchNuturientData = () => {
        this.setState({ searchNutrientName: "" }, () => {
            this.debouncedSearchNutrient("")
        })
    }
    // RESET FUNCTIONS ENDS


    openNutrienActivityModal = () => {
        return this.props.isNutrientModalOpen || this.props.isEditNutrientModalOpen
    }

    openAddCrop = () => {
        this.props.handleCloseAddActivityModal()
        this.props.openAddCropModalfn()
    }
    openAddFarm = () => {
        this.props.handleCloseAddActivityModal()
        this.props.openAddFarmModalfn()
    }

    handleCloseNutrienActivityModal = () => {
        this.props.handleCloseAddActivityModal()
        this.resetFormValues()
        this.setState({ openNutrientModal: false })
    }

    handleFilteredNutrientNamesList = (searchQuery: string) => {

        this.setState({ searchNutrientName: searchQuery })
        const query = searchQuery?.toLowerCase() || "";

        const filteredArray = query ? this.state.NutrientNamesList.filter((value: any) => value.name.toLowerCase().includes(query)) : this.state.NutrientNamesList;
        this.setState({ filteredNutrientNamesList: filteredArray });
    }


    handleAddAnotherEntry = () => {
        if (!this.isFormComplete()) {
            this.setState({
                isErrorModalOpen: true,
                ErrorModalTitle: "Error",
                ErrorModalMessage: "Please fill the existing forms with valid data."
            })
        } else {
            let existingForms = [...this.state.nutrientManagementForms];
            existingForms.push(this.state.initialValues);
            this.setState({ nutrientManagementForms: existingForms });
        }
    };

    handleRemoveEntry = (index: number) => {
        let existingForms = this.state.nutrientManagementForms;
        existingForms.splice(index, 1);
        this.setState({ nutrientManagementForms: existingForms });
    };

    handleCloseErrorModal = () =>{
        this.setState({
            isErrorModalOpen:false,
            ErrorModalMessage:"",
            ErrorModalTitle: "",
        })
    }

    formateData = (dataArray: ICreatedNutrient[]) => {
        let updateInitalValue = []
        const filteredData = dataArray.filter((item) => item.attributes.freeze_record !== true);
        updateInitalValue = filteredData.map((item:ICreatedNutrient) => {
            let nutrient_name_id = returnTruthyString(item.attributes.nutrient_name_id.id)
            let nutrient_managment_id = returnTruthyString(item.attributes.nutrient_managment_id?.id)
            let date_of_application = String(moment(item.attributes.date_of_application))
            let nutrient_quantity_value = Number(item.attributes.quantity_of_nutrient)
            let nutrient_quantity_unit = returnTruthyString(item.attributes.unit_of_measure_id?.id)
            let fertilizer_cost = Number(item.attributes.fertilizer_cost)
            let labor_cost = Number(item.attributes.labor_cost)
            const momentObject = moment(item.attributes.date_of_application);
            const dateObject = momentObject.toDate();

            return {
                id: returnTruthyString(item.id),
                nutrient_name_id: nutrient_name_id,
                nutrient_type_id: nutrient_managment_id,
                date_of_application: date_of_application,
                nutrient_quantity_value: nutrient_quantity_value,
                nutrient_quantity_unit: nutrient_quantity_unit,
                fertilizer_cost: fertilizer_cost,
                labor_cost: labor_cost,
                errors: {
                    nutrient_name_id: "",
                    nutrient_type_id: "",
                    date_of_application: this.validateMinDate(moment(dateObject).toDate(), this.props.activityCropId as number[]),
                    nutrient_quantity_value: "",
                    nutrient_quantity_unit: "",
                    fertilizer_cost: "",
                    labor_cost: "",
                }
            }
        })

        this.setState({
            selectedFarmId: this.props.activityFarmId ? this.props.activityFarmId : "",
            selectedCropIdArr: this.props.activityCropId?.length ? this.props.activityCropId : [],
            nutrientManagementForms: updateInitalValue,
            getActivityDetailsLoading: false,
            minimumDate: getSowingDate(this.props.activityCropId as number[], this.state.cropNamesList) ?? moment().subtract(360, 'days').toDate(),
        })
    }

    // VALIDATE SELECT FARM
    validateSelectFarmValue = (selectedFarmValue: string | number | null) => {
        let errorMessage = ""
        if (isValueFalsy(selectedFarmValue)) {
            errorMessage = "Please Select Farm Name"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    handleChangeSelectFarm = (selectedFarmValue: string | number | unknown) => {
        const updatedFormValues = this.state.nutrientManagementForms.map((item) => ({
            ...item,
            errors: { ...item.errors, date_of_application: this.validateMinDate(item.date_of_application as Date, []) },
        }));
        this.setState({
            selectedFarmId: selectedFarmValue as string,
            selectedFarmIdError: this.validateSelectFarmValue(selectedFarmValue as string).errorMessage,
            selectedCropIdArr: [],
            selectedCropIdError: "",
            minimumDate: moment().subtract(360, 'days').toDate(),
            nutrientManagementForms: updatedFormValues,
        }, () => {
            this.fetchCropsData(selectedFarmValue as string)
        })
    }
   
    // VALIDATE SELECT CROPS
    validateSelectCropValue = (selectedCropIDs: string[] | number[]) => {
        let errorMessage = ""
        if (selectedCropIDs.length === 0) {
            errorMessage = "Please Select Crop Names"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    handleChangeSelectCrop = (newCropIDs: string[] | number[] | unknown) => {
        const minimumDate = getSowingDate(newCropIDs as number[],this.state.cropNamesList) || moment().subtract(360, 'days').toDate();
        this.setState({ selectedCropIdArr: newCropIDs as number[],
            selectedCropIdError: this.validateSelectCropValue(newCropIDs as number[]).errorMessage, minimumDate })
        const updatedFormValues = [...this.state.nutrientManagementForms];
        this.state.nutrientManagementForms.map((item, index) => {
          updatedFormValues[index] = {
            ...updatedFormValues[index],
            errors: {
              ...updatedFormValues[index].errors,
              date_of_application: this.validateDateOfApplication(item.date_of_application as Date, newCropIDs as number[]).errorMessage,
            }
          };
        });
        this.setState({ nutrientManagementForms: updatedFormValues });
       
    }

    // VALIDATE NUTRIENT NAME
    validateNutrientName = (nutrientName: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(nutrientName)) {
            errorMessage = "Please select Nutrient Name"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateNutrientNameValue = (name: string, value: unknown, formIndex: number) => {
        const nutrientNameError = this.validateNutrientName(value as string).errorMessage
        this.setFormValue(name, value, formIndex, nutrientNameError)
    }
    // VALIDATE NUTRIENT TYPE
    validateNutrientType = (nutrientType: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(nutrientType)) {
            errorMessage = "Please select Nutrient Management Type"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateNutrientTypeValue = (name: string, value: unknown, formIndex: number) => {
        const nutrientTypeError = this.validateNutrientType(value as string).errorMessage
        this.setFormValue(name, value, formIndex, nutrientTypeError)
    }

    validateMinDate = (selectedDate: Date, cropValue?: number[]) => {
        const minimumDate = (cropValue && cropValue.length > 0 && getSowingDate(cropValue, this.state.cropNamesList)) || moment().subtract(361, 'days').toDate();
        return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
    };

    // VALIDATE DATE OF APPLICATION
    validateDateOfApplication = (applicationDate: string | Date | null, cropValue?: number[]) => {
        let errorMessage = ""
        const today = moment().endOf('day');
        const minDate = (cropValue && cropValue.length > 0 && getSowingDate(cropValue, this.state.cropNamesList)) || moment().subtract(361, 'days').toDate();
        if (isValueFalsy(applicationDate) || !moment(applicationDate).isValid()) {
            errorMessage = "Please enter valid Date"
        }
        if(moment(applicationDate) < moment(minDate)){
            errorMessage = "Please enter valid date"
        }
        if (moment(applicationDate).isAfter(today)) {
            errorMessage = "Date can not be more than Today's Date"

        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateDateOfApplicationValue = (name: string, value: unknown, formIndex: number) => {
        const dateOfApplicationError = this.validateDateOfApplication(value as string, this.state.selectedCropIdArr).errorMessage
        this.setFormValue(name, value, formIndex, dateOfApplicationError)
    }
    // VALIDATE QUANTITY VALUE
    validateNutrientQuantityValue = (quantityValue: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(quantityValue)) {
            errorMessage = "Please enter Nutrient Quantity"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateNutrientQuantityValue = (name: string, value: unknown, formIndex: number) => {
        const nutrientQuantityValueError = this.validateNutrientQuantityValue(value as string).errorMessage
        this.setFormValue(name, value, formIndex, nutrientQuantityValueError)
    }
    // QUANITITY UNIT
    validateNutrientQuantityUnit = (quantityUnit: string | number) => {
        let errorMessage = ""
        if (isValueFalsy(quantityUnit)) {
            errorMessage = "Please enter Quantity Unit"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateNutrientQuantityUnit = (name: string, value: unknown, formIndex: number) => {
        const nutrientQuantityUnitError = this.validateNutrientQuantityUnit(value as string).errorMessage
        this.setFormValue(name, value, formIndex, nutrientQuantityUnitError)
    }


    // VALIDATE FERTILIZER COST
    validateFertilizerCost = (fertilizerCost: string | number) => {
        let errorMessage = ""
        // Convert the value to an integer
        const floatValue = parseFloat(String(fertilizerCost));

        if (isValueFalsy(fertilizerCost)) {
            errorMessage = "Please enter Fertilizer Cost"
        }
        if (isNaN(floatValue) && !isValueFalsy(fertilizerCost)) {
            errorMessage = "Please enter valid Value"
        }
        if (floatValue < 0) {
            errorMessage = "Minimum allowed value is 0"
        }
        if (floatValue > 100000) {
            errorMessage = "Maximum allowed value is 100000"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateFertilizerCostValue = (name: string, value: unknown, formIndex: number) => {
        const fertilizerCostError = this.validateFertilizerCost(value as string).errorMessage
        this.setFormValue(name, value, formIndex, fertilizerCostError)
    }
    // VALIDATE LABOR COST 
    validateLaborCost = (laborCost: string | number) => {
        let errorMessage = ""
        // Convert the value to an integer
        const floatValue = parseFloat(String(laborCost));

        if (isValueFalsy(laborCost)) {
            errorMessage = "Please enter Labor Cost"
        }
        if (isNaN(floatValue) && !isValueFalsy(laborCost)) {
            errorMessage = "Please enter valid Value"
        }
        if (floatValue < 0) {
            errorMessage = "Minimum allowed value is 0"
        }
        if (floatValue > 50000) {
            errorMessage = "Maximum allowed value is 50000"
        }
        const isError = Boolean(errorMessage)
        return { errorMessage, isError }
    }
    updateLaborCostValue = (name: string, value: unknown, formIndex: number) => {
        const laborCostError = this.validateLaborCost(value as string).errorMessage
        this.setFormValue(name, value, formIndex, laborCostError)
    }

    handleChange = (name: string, value: unknown, formIndex: number) => {
        switch (name) {
            case "nutrient_name_id":
                this.updateNutrientNameValue(name, value, formIndex)
                break;
            case "nutrient_type_id":
                this.updateNutrientTypeValue(name, value, formIndex)
                break;
            case "date_of_application":
                this.updateDateOfApplicationValue(name, value, formIndex)
                break;
            case "nutrient_quantity_value":
                this.updateNutrientQuantityValue(name, value, formIndex)
                break;
            case "nutrient_quantity_unit":
                this.updateNutrientQuantityUnit(name, value, formIndex)
                break;
            case "fertilizer_cost":
                this.updateFertilizerCostValue(name, value, formIndex)
                break;
            case "labor_cost":
                this.updateLaborCostValue(name, value, formIndex)
                break;
        }
    };

    setFormValue = (name: string, value: unknown, formIndex: number, errorMessage: string) => {
        const updatedFormValues = [...this.state.nutrientManagementForms];

        updatedFormValues[formIndex] = {
            ...updatedFormValues[formIndex],
            [name]: value,
            errors: {
                ...updatedFormValues[formIndex].errors,
                [name]: errorMessage
            },
        };
        this.setState({ nutrientManagementForms: updatedFormValues });

    }

    isAllFormDataIsValidated = () => {   
        this.setAllFieldErrors()
        let allValidationPassed = false

        let validatedFormArrayBoolean: Boolean[] = []

        const allFormsData = [...this.state.nutrientManagementForms];

        allFormsData.forEach((form, index) => {
            let eachFormFieldsValidated = false
            const NutrientNameValidatedNot = this.validateNutrientName(form.nutrient_name_id).isError
            const NutrientTypeValidatedNot = this.validateNutrientType(form.nutrient_type_id).isError
            const DateOfApplicationValidatedNot = this.validateDateOfApplication(form.date_of_application, this.state.selectedCropIdArr).isError
            const NutrientQuantityValueValidatedNot = this.validateNutrientQuantityValue(form.nutrient_quantity_value).isError
            const NutrientQuantityUnitValidatedNot = this.validateNutrientQuantityUnit(form.nutrient_quantity_unit).isError
            const FertilizerCostValidateNot = this.validateFertilizerCost(form.fertilizer_cost).isError
            const LaborCostValidateNot = this.validateLaborCost(form.labor_cost).isError

            if (!NutrientNameValidatedNot && !NutrientTypeValidatedNot &&
                !DateOfApplicationValidatedNot && !NutrientQuantityValueValidatedNot && !NutrientQuantityUnitValidatedNot &&
                !FertilizerCostValidateNot && !LaborCostValidateNot) {
                eachFormFieldsValidated = true
            }
            validatedFormArrayBoolean.push(eachFormFieldsValidated)
        })

        allValidationPassed = validatedFormArrayBoolean.every(value => value === true)

        return allValidationPassed
    }

    isFarmAndCropValueIsValidated = () => {
        let isBothValueValidated = false;
        const farmValue = this.validateSelectFarmValue(this.state.selectedFarmId)
        const cropValue = this.validateSelectCropValue(this.state.selectedCropIdArr)

        this.setState({
            selectedFarmIdError:farmValue.errorMessage,
            selectedCropIdError:cropValue.errorMessage
        })

        if(!farmValue.isError && !cropValue.isError){
            isBothValueValidated = true
        }
        return isBothValueValidated
    }

    setAllFieldErrors = () => {
        const existingForms = [...this.state.nutrientManagementForms];

        const updatedForms = existingForms.map((form) => {
            return {
                ...form,
                errors: {
                    ...form.errors,
                    nutrient_name_id: this.validateNutrientName(form.nutrient_name_id).errorMessage,
                    nutrient_type_id: this.validateNutrientType(form.nutrient_type_id).errorMessage,
                    date_of_application: this.validateDateOfApplication(form.date_of_application, this.state.selectedCropIdArr).errorMessage,
                    nutrient_quantity_value: this.validateNutrientQuantityValue(form.nutrient_quantity_value).errorMessage,
                    nutrient_quantity_unit: this.validateNutrientQuantityUnit(form.nutrient_quantity_unit).errorMessage,
                    fertilizer_cost: this.validateFertilizerCost(form.fertilizer_cost).errorMessage,
                    labor_cost: this.validateLaborCost(form.labor_cost).errorMessage
                }
            }
        })
        this.setState({nutrientManagementForms:updatedForms})
    }

    isFormComplete = () => {
        let valid = true;
        if (!this.isFarmAndCropValueIsValidated()) {
          valid = false;
        }
        if (!this.isAllFormDataIsValidated()) {
          valid = false;
        }
        return valid;
    };

    onSubmit = () => {
        if(!this.isFormComplete()){
            this.setState({
                isErrorModalOpen: true,
                ErrorModalMessage:"Please fill the existing forms with valid data.",
                ErrorModalTitle: "Error",
            })
        } else {
            const bodyDataArray = this.reShapeBodyDataArray()
            const requestBody = {
                "farm_dairy": {
                    "nutrient": bodyDataArray
                }
            }
            if (this.props.isEditNutrientModalOpen) {
                this.editNutrientActivity(requestBody)
            } else {
                this.createNutrientActivity(requestBody)
            }
        }
    }
    reShapeBodyDataArray = () => {
        // CHECK ADD SCENARIO
        let reShapedArray = []
        const formStateData = [...this.state.nutrientManagementForms]

        reShapedArray = formStateData.map((eachFormData) => {
            return {
                // RESTRICT UPDATE FARM AND CROP WHILE EDIT
                ...(this.props.isEditNutrientModalOpen && eachFormData.id &&
                    {
                        "id": eachFormData.id ? eachFormData.id : ""
                    }
                ), 
                "nutrient_name_id": eachFormData.nutrient_name_id,
                "nutrient_managment_id": eachFormData.nutrient_type_id,
                "date_of_application": moment(eachFormData.date_of_application).format("DD/MM/YYYY"),
                "quantity_of_nutrient": Number(eachFormData.nutrient_quantity_value),
                "unit_of_measure_id": eachFormData.nutrient_quantity_unit,
                "fertilizer_cost": eachFormData.fertilizer_cost,
                "labor_cost": eachFormData.labor_cost,
                // RESTRICT UPDATE FARM AND CROP WHILE EDIT
                ...(!Boolean(eachFormData.id) &&
                    {
                        "land_detail_id": this.state.selectedFarmId,
                        "nutrient_crops_attributes": this.reShapeSelectedCropArray()
                    }
                ), 
                                                    
            }
        })
        return reShapedArray;
        
    }
    reShapeSelectedCropArray = () => {
        let finalArray = []

        const existingCropIdArray = [...this.state.selectedCropIdArr]
        finalArray = existingCropIdArray.map((id) => ({ "crop_id": id }))

        return finalArray
    }
    // Customizable Area End
}
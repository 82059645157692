// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple1, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdown, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateCropNameBody, formateCropNameData, formateSimpleBodyType1, formateUnitBodyData, getBase64, getTitleMessage, returnError, returnTruthyString, toLowerSnakeCase, trimStart, validateCropNameForm } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface ILanguageObject {
    crop_name: string,
    crop_name_error: string
}
interface IErrorObject {
    image: string
    crop_days: string
    harvest_type: string
    crop_season: string
}
interface IDefaultCropForm {
    id: string | number;
    image: string | null | File;
    crop_days: string | number;
    harvest_type: string;
    crop_season: string;
    error: IErrorObject;
    [language: string]: ILanguageObject | IErrorObject | string | number | null | File
}

const DefaultCropForm = {
    id: "",
    image: "",
    crop_days: "",
    harvest_type: "",
    crop_season: "",
    "english": {
        crop_name: "",
        crop_name_error: "",
    },
    "gujarati": {
        crop_name: "",
        crop_name_error: "",
    },
    "hindi": {
        crop_name: "",
        crop_name_error: "",
    },
    error: {
        image: "",
        crop_days: "",
        harvest_type: "",
        crop_season: "",
    }
}






export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    // CROP NAME STATES
    selectedCropTab: string,
    cropSeasonsData: ISimpleDropdown[],
    CropNameforms: IDefaultCropForm[],
    defaultCropForm: IDefaultCropForm,

    currentExpanded: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    submitLoading: boolean
    dropdownDataLoading: boolean,
    dropdownData: IDropdownDetailsData[],
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
    // SEARCH
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class CropsDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    fetchCropSeasonsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    updateDropdownsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            // CROP NAME STATES
            selectedCropTab: configJSON.TAB.ENGLISH,
            cropSeasonsData: [],
            CropNameforms: [],
            defaultCropForm: { ...DefaultCropForm },
            // CROP NAME STATES ENDS
            currentExpanded: null,
            submitLoading: false,
            titleMsgModal: "",
            descMsgModal: "",
            searchValue: "",
            fetchHeadingsLoading: false,
            headingsData: [],
            dropdownDataLoading: false,
            dropdownData: [],
            openMsgModal: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.fetchCropSeasonsApiCallId:
                        this.fetchCropSeasonsResp(responseJson)
                        break;
                    case this.getHeadingsApiCallId:
                        this.GetHeadingsApiCallIdResp(responseJson)
                        break;
                    case this.getDetailsApiCallId:
                        this.GetDetailsApiCallIdResp(responseJson)
                        break;
                    case this.updateDropdownsApiCallId:
                        this.UpdateDropdownsResp(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    GetHeadingsApiCallIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }

    fetchCropSeasonsResp = (response: ISimpleDropdown[] | undefined | null) => {
        if (response && response.length) {
            this.setState({
                cropSeasonsData: response
            })
        } else {
            this.setState({
                cropSeasonsData: []
            })
        }
    }
    GetDetailsApiCallIdResp = (responseJson: IDropdownDetailsData[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: responseJson
            })
            if (this.state.currentExpanded == configJSON.CROPS.CROP_NAME) {
                this.setState({
                    CropNameforms: formateCropNameData(responseJson)
                })
            }

        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }
    UpdateDropdownsResp = (responseData: IResponseDropdowns[] | null | undefined) => {

        this.setState({ submitLoading: false })
        if (!Array.isArray(responseData) || responseData.length === 0) return;

        const hasMessageKey = responseData.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseData);
            this.handleOpenMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(responseData);
        }
    }

    successUpdate = (responseData: IResponseDropdowns[]) => {
        if (responseData && responseData.length) {
            this.handleOpenMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.handleOpenMsgModal(true, "Error", "Something went wrong!")
        }
    }

    

    // API CALLS
    fetchCropSeasons = () => {
        const headers = {
            token: this.state.authToken,
        };

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchCropSeasonsApiCallId = reqMessage.messageId;

        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.CropSeasonsEndpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestapiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        requestapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestapiMsg.id, requestapiMsg);
    }

    fetchDropdownDetails = (title: string) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        if (dropdownName && subDropdownName && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: [],
                selectedCropTab: configJSON.TAB.ENGLISH,
            })

            const header = { token: this.state.authToken };
            const requestapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiCallId = requestapiMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestapiMsg.id, requestapiMsg);
        } else {
            return
        }
    }

    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple1 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsApiCallId = requestapiMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestapiMsg.id, requestapiMsg);
        } else {
            return
        }
    }

    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
        this.fetchCropSeasons()
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            selectedCropTab: newValue
        })
    }


    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: [],
                // RESET
                defaultCropForm: { ...DefaultCropForm },
                selectedCropTab: configJSON.TAB.ENGLISH,
                CropNameforms: []
            }, () => {
                this.fetchDropdownDetails(heading)

            })
        }
    };
    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }
   
    handleOpenMsgModal = (open: boolean, title: string, message: string) => {
        this.setState({
            openMsgModal: open,
            titleMsgModal: title,
            descMsgModal: message,
        })
    }
    handleSearch = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchValue: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }
    handleSubmitSimple = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType1(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
    }
    handleCloseMsgModal = () => {
        this.setState({
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
        })
    }

    // CROP NAME FUNCTIONS

    handleChangeDefault = (fieldName: string, fieldValue: string | number, language?: string) => {
        if (language) {
            this.setState(prevState => ({
                defaultCropForm: {
                    ...prevState.defaultCropForm,
                    [language]: {
                        ...prevState.defaultCropForm[language] as ILanguageObject,
                        crop_name: trimStart(String(fieldValue)),
                        crop_name_error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty"
                    }
                }
            }));
        } else {
            this.setState(prevState => ({
                defaultCropForm: {
                    ...prevState.defaultCropForm,
                    [fieldName]: trimStart(String(fieldValue)),
                    error: {
                        ...prevState.defaultCropForm.error,
                        [fieldName]: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty"
                    }
                }
            }));
        }

    }

    changeImageDefault = async (ImageFile: File) => {

        const fileUrl = await getBase64(ImageFile).then(res => res)

        this.setState(prevState => ({
            defaultCropForm: {
                ...prevState.defaultCropForm,
                image: fileUrl as string,
                error: {
                    ...prevState.defaultCropForm.error,
                    image: ImageFile ? "" : "Field cannot be empty"
                }
            }
        }))
    }

    handleChangeForm = (fieldIndex: number, fieldName: string, fieldValue: string | number, language?: string) => {
        if (language) {
            const formArrayCopy = [...this.state.CropNameforms];
            formArrayCopy[fieldIndex] = {
                ...formArrayCopy[fieldIndex],
                [language]: {
                    ...formArrayCopy[fieldIndex][language] as ILanguageObject,
                    crop_name: trimStart(String(fieldValue)),
                    crop_name_error: returnError(returnTruthyString(fieldValue))
                }
            };
            this.setState({
                CropNameforms: formArrayCopy
            })

        } else {
            const formArrayCopy = [...this.state.CropNameforms];
            formArrayCopy[fieldIndex] = {
                ...formArrayCopy[fieldIndex],
                [fieldName]: trimStart(String(fieldValue)),
                error: {
                    ...formArrayCopy[fieldIndex].error,
                    [fieldName]: returnError(returnTruthyString(fieldValue))
                }
            };
            this.setState({
                CropNameforms: formArrayCopy
            })
        }
    }
    changeImageForm = async (fieldIndex: number, ImageFile: File | null) => {
        const fileUrl = ImageFile ? await getBase64(ImageFile).then(res => res) : null

        const formArrayCopy = [...this.state.CropNameforms];
        formArrayCopy[fieldIndex] = {
            ...formArrayCopy[fieldIndex],
            image: fileUrl ? fileUrl as string : formArrayCopy[fieldIndex].image as string,
            error: {
                ...formArrayCopy[fieldIndex].error,
                image: returnError(returnTruthyString(formArrayCopy[fieldIndex].image as string|| fileUrl as string))
            }
        };
        this.setState({
            CropNameforms: formArrayCopy
        })
    }

    handleAddInput = () => {
        const newForm = JSON.parse(JSON.stringify(this.state.defaultCropForm))
        const { formObj, isFormHasError,
            isErrorEnglish, isErrorGujarati, isErrorHindi,
            isErrorInOtherField } = validateCropNameForm(newForm)
        if (!isFormHasError) {
            let existingData = [...this.state.CropNameforms]
            existingData.push(formObj);
            this.setState({ CropNameforms: existingData, defaultCropForm: { ...DefaultCropForm } });
            this.setDefaultTab()
        } else {
            this.changeTabByLanguage(isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField)
            this.setState({ defaultCropForm: newForm });
        }
    }
    handleRemoveInput = (formItem: IDefaultCropForm, index: number) => {
        let existingData = [...this.state.CropNameforms]
        if (index >= 0 && index < existingData.length) {
            const obj = existingData[index];
            if (obj.id) {
                obj["_destroy"] = formItem.id;
            } else {
                existingData.splice(index, 1);
            }
        }
        this.setState({ CropNameforms: existingData });
    };

    // VALIDATE NEW INPUT
    
    changeTabByLanguage = (isErrorEnglish: boolean,
        isErrorGujarati: boolean,
        isErrorHindi: boolean,
        isErrorInOtherField: boolean) => {
        if (isErrorEnglish || isErrorInOtherField) {
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if (isErrorGujarati || isErrorInOtherField) {
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if (isErrorHindi || isErrorInOtherField) {
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
    }

    setDefaultTab = (tabValue: string = configJSON.TAB.ENGLISH) => {
        this.setState({
            selectedCropTab: tabValue
        })
    }

    // VALIDATE ALL FORMS
    validateAllCropForms = () =>{
        const allForms = [...this.state.CropNameforms]
        let isFormValidatedArr:boolean[] = []
        let isErrorEnglishArr:boolean[] = [];
        let isErrorGujaratiArr:boolean[] = [];
        let isErrorHindiArr:boolean[] = [];
        let isErrorInOtherFieldArr:boolean[] = [];

        let UpdatedFormArray:IDefaultCropForm[] = []

        allForms.forEach((newForm) => {
            const { isFormHasError, isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField, formObj } = validateCropNameForm(newForm)
            isFormValidatedArr.push(isFormHasError)
            isErrorEnglishArr.push(isErrorEnglish)
            isErrorGujaratiArr.push(isErrorGujarati)
            isErrorHindiArr.push(isErrorHindi)
            isErrorInOtherFieldArr.push(isErrorInOtherField)

            UpdatedFormArray.push(formObj)
        })

        const isFormError = isFormValidatedArr.includes(true);
        const isErrorInEnglishTab = isErrorEnglishArr.includes(true);
        const isErrorInGujaratiTab = isErrorGujaratiArr.includes(true);
        const isErrorInHindiTab = isErrorHindiArr.includes(true);
        const isErrorOtherFields = isErrorInOtherFieldArr.includes(true);
        
        this.changeTabByLanguage(isErrorInEnglishTab, isErrorInGujaratiTab, isErrorInHindiTab, isErrorOtherFields)

        this.setState({
            CropNameforms: UpdatedFormArray
        })

        if (isErrorInEnglishTab || isErrorInGujaratiTab || isErrorInHindiTab || isErrorOtherFields || isFormError) {
            return false
        } else {
            return true
        }

    }    

    handleSubmitCropName = () => {
        this.setState({ defaultCropForm: { ...DefaultCropForm } });
        const allFormsValidated = this.validateAllCropForms()
        if (allFormsValidated) {
            const bodyData = formateCropNameBody(this.state.CropNameforms)
            this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
        }
    }
    // Customizable Area End
}
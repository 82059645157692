Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.acceptAll = '*/*';
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";
exports.imageSizeLimit = 25000000;

exports.btnExampleTitle = "CLICK ME";
exports.surveyDetailsShareLink = "https://co:farm/surveydetails/";

exports.screenDefaultsTexts = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  heading1: "Your Profile",
  profileTextEmail: "Email Id",
  profileTextUsername: "Username",
  profileTextLocation: "Location",
  profileTextDOB: "Date of Birth",
  profileTextDevice: "Mobile Device Model",
  buttonText1: "edit",
  buttonText2: "delete",
};

exports.screenFarmerDefaultsTexts = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  heading1: "Your Profile",

  profileTextUsername: "Username",
  profileTextLocation: "Location",

  profileTextDOB: "Date of Birth",
  profileAadhar: "Aadhar Card Number",
  profileGender: "Gender",
  profileEducation: "Highest Education",
  profileMobileType: "Mobile Type",
  profileState: "State",
  profileDistrict: "District",
  profileFamily: "Total Family Members",
  profileBelongsTo: "Number belongs to",
  profileTaluka: "Taluka",
  profileVillage: "Village",

  profileFarmPhoto: "Farm Photo",
  profileFarmMap: "Farm Map",
  profileFarmName: "Farm Name",
  profileFarmArea: "Total Farm Area",
  profileAreaIn: "Area in",
  profileMotorHP: "Motor in HP",
  profileSourceIrrigation: "Source of Irrigation",
  profileFarmSurvey: "Farm Survey Number",
  profileOwership: "Owership of Land",
  profileLandType: "Land Type",
  profileMotorDepth: "Depth of Motor",
  profileIrrigationType: "Type of Irrigation",
  buttonText1: "edit",
  buttonText2: "delete",
};

exports.landingScreenDefaults = {
  // fontFamily: "IBM Plex Sans",
  fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  subHeadingText: "Quick Links",
  buttonText: "add user",
};

exports.acceptGlobal = "*/*";
exports.getTableAPiContentType = "application/json";
exports.getTableAPiEndPoint = "admin/account_users";
exports.getTableAPiMethod = "GET";
exports.imageLimit_250 = 262144000;

exports.userToken =
  "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTksImV4cCI6MTY4NTI1ODI4MiwidG9rZW5fdHlwZSI6ImFkbWluX2xvZ2luIn0.9wwENqhiG1W93OKS3OpW8dDkVNLwJLB361l9H59kIx6Opsu2QfOqxF0LLebeL1ZsrI9_LteVYvBEoPCvzeNFYg";
exports.token =
  "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTksImV4cCI6MTY4NjQ4MjYxMiwidG9rZW5fdHlwZSI6ImFkbWluX2xvZ2luIn0.bp74gMvDCZcMogDfRBGfa2a7zTTAAZEEJPPpSh8pdlkPVryevAYEoOmCBKdFuEApmH7p4hZvzbCQYikvCil_pQ";
exports.jsonContentType = "application/json";
exports.getAPiMethod = "GET";
exports.postAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.addAdminAPiMethod = "POST";
exports.removeNotification = "admin/logout_admin_user";
exports.POST_METHOD_TYPE = "POST";
exports.DELETE_METHOD_TYPE = "DELETE";
exports.GET_METHOD_TYPE = "GET";
exports.PATCH_METHOD_TYPE = "PATCH";

exports.USERROLES = {
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin"
}

exports.deleteUserApiEndPoint = "admin/account_users";
exports.getStateAPiEndPoint = "admin/state_listing";
exports.getDistrictAPiEndPoint = `admin/district_listing`;
exports.getTalukaAPiEndPoint = "admin/taluka_listing";
exports.getVillageAPiEndPoint = "admin/village_listing";
exports.getEducationFarmerAPiEndPoint = "admin/highest_education_list";
exports.getNumberBeongsToAPiEndPoint = "admin/number_belongs_to_listing";

exports.getOwnerListFarmerAPiEndPoint = "admin/ownership_of_land_listing";
exports.getUnitOfMeasureFarmerAPiEndPoint = "admin/unit_of_measure_listing";
exports.getDistanceInAPiEndPoint = "admin/bx_block_profile_bio/distance_ins";
exports.getLandTypeFarmerAPiEndPoint = "admin/land_type_listing";
exports.getSoilTextureFarmerAPiEndPoint = "bx_block_profile_bio/soil_textures";
exports.getSourceOfIrrigationFarmerAPiEndPoint =
  "admin/source_of_irrigation_listing";
exports.getTypeIrrigationListingAPiEndPoint =
  "admin/type_of_irrigation_listing";
exports.getFEListAPiEndPoint = "admin/fe_listing";

exports.getMobileDeviceModelFEAPiEndPoint = "admin/mobile_type_listing";
exports.getLocationUrl = "admin/get_location";
exports.adminGetUrl = "admin/show_current_admin";
//latest changes
exports.getStateList = "admin/state_listing";
exports.getDistrictList = "admin/district_listing";
exports.getTalukaList = "admin/taluka_listing";
exports.getVillageList = "admin/village_listing";
exports.getFieldExecutiveList = "admin/fe_listing";

exports.updateAvatar = "admin/update_avatar";
//latest changes end

// user-farm-diary
exports.preSowingActivityUrl =
  "admin/bx_block_farm_dairy/pre_sowing_activities";
exports.farmerListUrl = "admin/farmer_data_listing";

exports.CropNameUrl = "admin/bx_block_farm_dairy/crop_names";
exports.UnitFarmAreaUrl = "bx_block_profile_bio/unit_farm_areas";
exports.CropSeasonUrl = "admin/bx_block_farm_dairy/crop_seasons";
exports.CropTypeUrl = "admin/bx_block_farm_dairy/crop_types";
exports.VarietyUrl = "admin/bx_block_farm_dairy/varieties";
exports.SeedSourceUrl = "admin/bx_block_farm_dairy/seed_sources";
exports.BioAgentNameUrl = "admin/bx_block_farm_dairy/bio_agents";

exports.WeedingTypeUrl = "admin/bx_block_farm_dairy/weeding_types";
exports.WeedicideNameUrl = "admin/bx_block_farm_dairy/weedicides";

exports.NutrientManagementUrl = "admin/bx_block_farm_dairy/nutrient_managments";

exports.PestManagementUrl = "admin/bx_block_farm_dairy/pest_managments";
exports.PesticideNameUrl = "admin/bx_block_farm_dairy/pesticides";
exports.BioAgentReleaseUrl = "admin/bx_block_farm_dairy/bio_agent_releases";
exports.TypeOfTrapsUrl = "admin/bx_block_farm_dairy/type_of_traps";

exports.SourceofIrrigationUrl = "admin/source_of_irrigation_listing";
exports.TypeofIrrigationUrl = "admin/type_of_irrigation_listing";

exports.StorageTypeUrl = "admin/bx_block_farm_dairy/storage_types";
exports.VehicleTypeUrl = "admin/bx_block_farm_dairy/vehical_types";
// common
exports.UnitofMeasureUrl = "admin/bx_block_farm_dairy/unit_of_measures";
exports.farmDiaryBaseUrl = "admin/bx_block_admin_farm_dairy/admin_farm_dairies";
exports.updateFarmDiaryUrl =
  "admin/bx_block_admin_farm_dairy/admin_farm_dairies";

exports.calendarScreenDefaults = {
  // fontFamily: "IBM Plex Sans",
  ROOT_CALENDAR_URL: "admin/bx_block_phase/",
  GET_ACTIVITY_URL: "admin/bx_block_phase/phase_activities",
  CREATE_PHASE_URL: "admin/bx_block_phase/phases",
  GET_SUBGROUPS_URL: "admin/bx_block_phase/phase_sub_groups",
  ROOT_PHASE_URL: "admin/bx_block_phase/phases",
  fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  heading1: "Calendar",
  buttonText: "ADD NEW",
  modalHeaderText: "Add New",
  modalCalendarHeader:"Add New Calender",
  modalHeaderTextEdit: "Edit Phase",
  editButtonText: "save edited details",
  addButtonText: "add new phase",
  addActivityText: "ADD NEW ACTIVITY",
  addSubActivityText: "ADD NEW SUB-ACTIVITY",
  text: "test-pipeline",
};



exports.ERROR_MESSAGES = {
  preSowing: {
    date: "Please add valid Date",
    cost: "Please add Cost",
    compost_cost: "Please add Cost",
    labor_cost: "Please add Cost",
    pre_sowing_activity_id: "Please select Pre-Sowing",
    crop_season_id: "Please select Crop Season",
  },
  sowing: {
    date_of_sowing: "Please add valid Date",
    crop_name_id: "Please select Crop Name",
    crop_area: "Please add Crop Area",
    unit_farm_area_id: "Please select Unit",
    crop_season_id: "Please select Crop Season",
    crop_type_id: "Please select Crop Type",
    variety_id: "Please select Variety",
    seed_source_id: "Please select Seed Source",
    number_of_packet: "Please add Number",
    quantity_in_kg_other_crop: "Please add Quantity",
    seed_price: "Please add Cost",
    labor_cost: "Please add Cost",
    gmo_seed: "Please select Value",
    seed_treatment: "Please select Value",
    bio_agent_id: "Please select Bio Agent",
    bio_agent_cost: "Please add Cost",
  },
  gapFilling: {
    date: "Please add valid Date",
    labor_cost: "Please add Cost",
    price_of_seed: "Please add Price",
    crop_season_id: "Please select Crop Season",
  },
  weedManagement: {
    weeding_date: "Please add valid Date",
    weeding_type_id: "Please select Weeding Type",
    machine_charges: "Please add Charge",
    labor_cost: "Please add Cost",
    weedicide_id: "Please select Weedicide",
    quantity_of_weedicide: "Please add Quantity",
    cost_of_weedicide: "Please add Cost",
    labor_cost_of_spraying: "Please add Cost",
    crop_season_id: "Please select Crop Season",
  },
  nutrientManagement: {
    nutrient_managment_id: "Please select Nutrient",
    date_of_application: "Please add valid Date",
    quantity_of_nutrient: "Please add Quantity",
    unit_of_measure_id: "Please select Unit",
    fertilizer_cost: "Please add Cost",
    labor_cost: "Please add Cost",
    crop_season_id: "Please select Crop Season",
  },
  pestManagement: {
    pest_managment_id: "Please select Pest",
    bio_agent_release_id: "Please select Bio Agent Release",
    date_of_release: "Please select valid Date",
    quantity: "Please add Quantity",
    cost_of_input: "Please add Cost",
    pesticide_id: "Please select Pesticide",
    date_of_application: "Please select valid Date",
    machine_cost_of_spraying: "Please add Cost",
    quantity_of_pesticide: "Please add Quantity",
    pesticide_cost: "Please add Pesticide",
    type_of_trap_id: "Please select Trap Type",
    date_of_installation: "Please select valid Date",
    number_of_trap: "Please add Number",
    cost_of_trap: "Please add Cost",
    unit_of_measure_id: "Please select Unit",
    labor_cost: "Please add Cost",
    crop_season_id: "Please select Crop Season",
  },
  irrigation: {
    date_of_irrigation: "Please add valid Date",
    source_irrigation_id: "Please select Source",
    type_irrigation_id: "Please select Irrigation Type",
    hours_of_irrigation: "Please add Irrigation Hours",
    number_of_nozels: "Please add Number",
    spacing_of_dripper: "Please add Spacing of Dripper",
    drip_irrigated_area: "Please add Irrigated Area",
    row_to_row_spacing: "Please add Row to Row Spacing",
    irrigation_cost: "Please add Cost",
    labor_cost: "Please add Cost",
    crop_season_id: "Please select Crop Season",
  },
  harvest: {
    date_of_picking: "Please add valid Date",
    quantity_picked: "Please add Quantity",
    total_picking_cost: "Please add Cost",
    storage_type_id: "Please select Storage Type",
    crop_season_id: "Please select Crop Season",
  },
  sell: {
    "date_of_sale": "Please add valid Date",
    "quantity_of_cotton_sold": "Please add Quantity",
    "cotton_price": "Please add Cotton Price",
    "total_amount": "Please add Amount",
    "buyer_name": "Please add Buyer Name",
    "transportation_cost": "Please add Cost",
    "vehical_type_id": "Please select Vehicle",
    "labor_cost_of_load_and_unload": "Please add Cost",
    "distance_from_farmer_location_to_buyer": "Please add Distance",
    "crop_season_id": "Please select Crop Season"
  },
  state: "Please select State",
  district: "Please select District",
  taluka: "Please select Taluka",
  village: "Please select Village",
  defaultFilter: "*Please select atleast one field"
}

exports.FARMER = "Farmer"
exports.FIELD_EXECUTIVE = "Field Executive"
exports.ADMIN = "Admin"
exports.SUPER_ADMIN = "Super Admin"

exports.AdminConsoleDefaults = {
  LIST_FARMER: "List of Farmers",
  LIST_FE: "List of Field Executives",
  LIST_ADMIN: "List of Admins",
  LIST_SUPER_ADMIN: "List of Super Admins",
  ErrorDefault: "Something went wrong",
  deleteUserTitle: "Delete User",
  deleteUserDescription: "Are you sure? You want to delete this User?",
  userNotFound: "Oops, User not found!"
}
// surveys
exports.deletePopupDescription = "By deleting survey from here it will be removed from all the users. Are you sure? You want to delete this Survey?"
exports.SURVEYS_LIST = "admin/bx_block_admin_farm_dairy/surveys";
exports.GET_SURVEYS_RESULT =
  "/admin/bx_block_admin_farm_dairy/surveys/all_user_responses?survey_id=";
exports.SURVEYS_USER_RESPONSE = "/admin/bx_block_admin_farm_dairy/surveys/user_responses?account_id=";
exports.NUMERIC = "Numeric";
exports.RADIO = "Radio";
exports.DROPDOWN = "Dropdown";
exports.TEXT = "Text";
exports.CHECK = "Check";
exports.DATE = "Date";
exports.TIME = "Time";

// DropDown Module
exports.dropDownTitle = "Dropdowns";
exports.getDropdownAPIEndPoint = "admin/bx_block_dropdown/dropdowns"
exports.getStateListApiEndPoint = 'admin/state_listing';
exports.getDistrictListApiEndPoint = 'admin/district_listing';
exports.getTalukaListApiEndPoint = 'admin/taluka_listing';
exports.getVillageListApiEndPoint = 'admin/village_listing'

// NEW DROPDOWN
exports.fetchDropdownIndexDataEndPoint = 'admin/bx_block_dropdown/dropdown_count'
exports.fetchSubDropdownHeadingDataEndPoint = 'admin/bx_block_dropdown/dropdown_activity'
exports.fetchSubDropdownDetailsDataEndPoint = 'admin/bx_block_dropdown/dropdown_index'
exports.postSubmitDropdownEndpoint = 'admin/bx_block_dropdown/dropdown_edit_options'


exports.getUsersFarmsById='admin/bx_block_profile_bio/land_details'
exports.imagePlaceholder='https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM='

exports.updateUserStatusAPiEndPoint = "admin/update_active_inactive";
exports.farmDairyShareLink = "https://co:farm/farmdairy/";

// Customizable Area End

exports.SUCCESSMSG ={
  ADD_FARMER:"New Farmer Added Successfully",
  ADD_FE:"New Field Executive Added Successfully",
  ADD_ADMIN:"New Admin Added Successfully",
  ADD_SA:"New Super Admin Added Successfully",
  UPDATE_FARMER:"Farmer Updated Successfully",
  UPDATE_FE:"Field Executive Updated Successfully",
  UPDATE_ADMIN:"Admin Updated Successfully",
  UPDATE_SA:"Super Admin Updated Successfully",
  DELETE_FARMER:"Farmer Deleted Successfully",
  DELETE_FE:"Field Executive Deleted Successfully",
  DELETE_ADMIN:"Admin Deleted Successfully",
  DELETE_SA:"Super Admin Deleted Successfully",
}


// USER FARM DIARY
exports.cropIndexAPIEndPoint = "admin/bx_block_profile_bio/crops";

// Gap filling Apis end-points
exports.gapFillingApiEndPonit="admin/bx_block_farm_dairy/gap_fillings";

// DUMMY GAP FILLING DATA
exports.dummyGapFillingData ={
  "data": [
      {
          "id": "22",
          "type": "pre_sowing",
          "attributes": {
              "date": "2024-03-06",
              "cost": 12.0,
              "crop_start_year": "2024",
              "crop_end_year": "2025",
              "account_id": 66,
              "land_detail_id": 191,
              "crop_id":10,
              "compost_cost": null,
              "labor_cost": 0.0,
              "total_cost": null,
              "price_of_seed":32,
              "fuel_consumption": 12.0,
              "unit_of_fuel_consumption_id": 6,
              "data_entered_on": "2024-03-06T21:57:08.268+05:30",
              "submitted_by": "2",
              "admin_session_control_id": {
                  "id": 1,
                  "name": "A1",
                  "created_at": "2024-01-19T20:28:43.578+05:30",
                  "updated_at": "2024-01-19T20:28:43.578+05:30"
              },
              "sent_delete_request": false,
              "type": "BxBlockFarmDairy::PreSowing",
              "crop_season_id": [
                  {
                      "id": 1,
                      "name": "Rabi",
                      "name_english": "Rabi",
                      "name_hindi": "रबी",
                      "name_gujrati": "રબી",
                      "active": true,
                      "created_at": "2024-01-19T20:29:07.144+05:30",
                      "updated_at": "2024-01-19T20:29:07.144+05:30"
                  }
              ],
              "pre_sowing_activity_id": [
                  {
                      "id": 1,
                      "name": "Harrowing",
                      "name_english": "Harrowing",
                      "name_hindi": "शोकजनक",
                      "name_gujrati": "કષ્ટદાયક",
                      "active": true,
                      "created_at": "2024-01-19T20:29:20.604+05:30",
                      "updated_at": "2024-01-19T20:29:20.604+05:30"
                  }
              ]
          }
      },
      {
        "id": "23",
        "type": "pre_sowing",
        "attributes": {
            "total_cost": null,
            "price_of_seed":32,
            "crop_end_year": "2025",
            "fuel_consumption": 12.0,
            "account_id": 66,
            "unit_of_fuel_consumption_id": 6,
            "data_entered_on": "2024-03-06T21:57:08.268+05:30",
            "crop_start_year": "2024",
            "submitted_by": "2",
            "cost": 12.0,
            "land_detail_id": 191,
            "labor_cost": 0.0,
            "date": "2024-03-06",
            "crop_id":10,
            "compost_cost": null,
        }
    }
  ]
}

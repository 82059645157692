import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from 'yup'
import { TDeletedSurvey } from "../../../components/src/interface.web";


export interface SurveyListType {
  id: string;
  attributes: {
    name: string;
    name_hindi: string;
    name_gujarati: string;
    active: boolean;
    submitted_by: string;
    created_at?: string;
    survey_response_count: number;
    questions: {
      data: Array<{
        id: string,
        type: string,
        attributes: {
          question: string,
          question_hindi: string,
          question_gujrati: string,
          question_type_id: number,
          question_type: string,
          question_options: {
            data:  Array<{
              id: string;
              attributes: {
                value: string, value_hindi: string, value_gujrati: string
              }
            }>
          }
        }
      }>
    }
  };
}
type TLanguageTab = 0 | 1 | 2;
export interface FormikInitalValue {
  languageActiveTab: TLanguageTab,
  title: { 0: string, 1: string, 2: string },
  id?: string,
  fieldArray: Array<{
    id?: string
    question: string,
    question_hindi: string,
    question_gujrati: string,
    question_type_id: number,
    question_options_attributes: Array<{
      id?: string,
      value: string, 
      value_hindi: string, 
      value_gujrati: string
      _destroy?: string
    }>,
    _destroy?: number
  }>
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  surveyList: Array<SurveyListType> | string;
  tempID: string | null;
  allQuestion: Array<{ id: number, question_type: string }> | null;
  deleteId: string | null;
  formikInitalValue: FormikInitalValue | null;
  isShare: boolean,
  linkShare:string
  messageHeading:string,
  page: number,
  isLoading: boolean,
  // MODAL
  openCustomModal: boolean,
  titleCustomModal: string,
  descCustomModal: string,
  // FORMIK
  initialValues: FormikInitalValue,
  // LOADER
  surveyListLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  surveyListId: string = "";
  changeActiveStateSurveyId: string = "";
  deleteSurveyId: string = "";
  getAllQuestionId: string = "";
  createSurveyId: string = "";
  updateSurveyId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      surveyList: [],
      tempID: null,
      allQuestion: null,
      deleteId: null,
      formikInitalValue: null,
      isShare: false,
      linkShare:'',
      messageHeading:'',
      page: 1,
      isLoading: false,
      // MODAL
      openCustomModal: false,
      titleCustomModal: "",
      descCustomModal: "",
      // FORMIK
      initialValues: {
        languageActiveTab: 0 as (0 | 1 | 2),
        title: { 0: "", 1: "", 2: "" },
        fieldArray: [
          JSON.parse(JSON.stringify({
            question: '',
            question_hindi: "",
            question_gujrati: "",
            question_type_id: "none",
            question_options_attributes: [JSON.parse(JSON.stringify(configJSON.optionInitialValue))]
          }))
        ]
      },
      // LOADER
      surveyListLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson !== null) {
        switch (apiRequestCallId) {
          case this.surveyListId:
            return this.handelSurveyList(responseJson)
          case this.deleteSurveyId:
            return this.deleteSurvey(responseJson);
          case this.getAllQuestionId:
            return this.setState({ allQuestion: responseJson.length ? responseJson : [] });
          case this.createSurveyId:
            return this.handleCreateResp(responseJson)
          case this.updateSurveyId:
            return this.updateSurveyList(responseJson.data);
        }
      }
    }
    // Customizable Area End
  }
  // web events
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  updateSurveyList = (data : any) => {
    let updatedData  =  JSON.parse(JSON.stringify(this.state.surveyList));
    if(Array.isArray(this.state.surveyList)){
      const updateDataIndex = this.state.surveyList.findIndex((item) =>  item.id === data.id);
      updatedData[updateDataIndex] = data;
      this.setState({ surveyList: updatedData , isLoading: false});
      this.openCustomModal(true, "Success", "Survey updated Successfully.")
    }
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area Start

  async componentDidMount() {
    this.getSurveysList(this.state.page);
    this.getAllQuestion();
  }

  checkOptionsIsRequired = (id: number | undefined) => {
    const selectFieldType = this.state.allQuestion?.find((data) => data.id === id);
    return (selectFieldType?.question_type.toLocaleLowerCase().includes("check") || selectFieldType?.question_type.toLocaleLowerCase().includes("dropdown") || selectFieldType?.question_type.toLocaleLowerCase().includes("radio"))
  }

  validation = () => Yup.object().shape({
    title: Yup.object().shape({
      0: Yup.string().required("Please fill out the survey name in English.").trim(),
      1: Yup.string().required("Please fill out the survey name in Gujarati.").trim(),
      2: Yup.string().required("Please fill out the survey name in Hindi.").trim(),
    }),

    fieldArray: Yup.array().of(Yup.object().shape({
      question: Yup.string().required("Please fill question in English.").trim(),
      question_hindi: Yup.string().required("Please fill question in Hindi.").trim(),
      question_gujrati: Yup.string().required("Please fill question in Gujarati.").trim(),
      question_type_id: Yup.number().required("Please select the type.").test("none", "Please select the type.", (value) =>  value && value !== 'none'),

      question_options_attributes: Yup.array().when("question_type_id", (values : number | undefined) => {
        if (this.checkOptionsIsRequired(values)){
          return Yup.array().transform((field) => field.length > 1 ? field.slice(0,-1) : field).of(Yup.object().shape({
            value: Yup.string().required("Options is required").trim(),
            value_hindi: Yup.string().required("Options is required").trim(),
            value_gujrati: Yup.string().required("Options is required").trim(),
          }))
        }
        return Yup.array().of(Yup.object().shape({
          value: Yup.string().trim(),
          value_hindi: Yup.string().trim(),
          value_gujrati: Yup.string().trim(),
        }))  
      })
    }))
  })

  scrollToForm = () => {
    const targetElement: any = document.getElementById("survey-form");

    // Check if the element is visible in the viewport
    const elementRect = targetElement.getBoundingClientRect();
    const isVisible = (
      elementRect.top >= 0 &&
      elementRect.left >= 0 &&
      elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      elementRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );

    if (!isVisible) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      });
    }
  }

  editSurvey = (survey: SurveyListType ) => {
    const initialValue: FormikInitalValue = {
      languageActiveTab: 0 ,
      title: { 0: survey.attributes.name, 1: survey.attributes.name_gujarati, 2: survey.attributes.name_hindi},
      id: survey.id,
      fieldArray: []
    }

    survey.attributes.questions.data.forEach((data) => {
      initialValue.fieldArray.push({
        "id": data.id,
        "question": data.attributes.question,
        "question_hindi": data.attributes.question_hindi,
        "question_gujrati": data.attributes.question_gujrati,
        "question_type_id": data.attributes.question_type_id,
        "question_options_attributes":  data.attributes.question_options.data.map((data) => ({ id: data.id ,...data.attributes})).concat({...configJSON.optionInitialValue}) || [{...configJSON.optionInitialValue}],
      })
    })
    this.setState({ formikInitalValue: initialValue }, () => {
      this.scrollToForm()
    })
  }
  
  removeField = (values: FormikInitalValue, setFieldValue: (key: string , value : any[] | string) => void, deleteIndex: number) => {
    if(values.fieldArray[deleteIndex].id){
      const leftField = values.fieldArray.filter((data) => !data._destroy);
      if(leftField.length > 1){
        setFieldValue(`fieldArray[${deleteIndex}]._destroy`, "1")  
      }
      return;
    }

    if (values.fieldArray.length > 1) {
      setFieldValue("fieldArray", values.fieldArray.filter((_, index) => index !== deleteIndex))
    }
  }
  

  handleSubmit = (value: FormikInitalValue, resetForm: () => void) => {
    const { formikInitalValue } = this.state

    const field: any[] = []

    value.fieldArray.forEach((data) => {
      field.push({
        ...data,
        question_options_attributes: Object.assign( {} , data.question_options_attributes.filter((data) => !!data.value || !!data.value_hindi || !!data.value_gujrati))
      })
    })
    
    const body = {
      "survey": [{        
        "name": value.title[0],
        "name_hindi": value.title[2],
        "name_gujarati": value.title[1],
        "active": "1",
        "questions_attributes":  Object.assign({}, field),
      }]
    };


    if(formikInitalValue){
      this.updateSurvey(formikInitalValue.id || "", JSON.stringify(body.survey[0]))
      return ;
    }

    this.createSurvey(JSON.stringify(body))
  }

  
  checkErrors = (errors: any, setFieldValue: (key : string , value: number) => void) => {    

    let field = 0;

    errors?.fieldArray?.find((data: any) => data?.question_options_attributes?.find((items: any) => {
        if(items?.value) {
          field = 1
          return 1;
        }
        if(items?.value_gujrati){
          field = 2
          return 2;
        } 
          field = 3
    }) )

    
    if( (errors?.title && errors.title[0]) ||  field === 1 || (Array.isArray(errors?.fieldArray) && errors.fieldArray.find((data: any) => data?.question))){
      setFieldValue("languageActiveTab", 0)
    } else if ((errors?.title  && errors?.title[1]) || field === 2 || (Array.isArray(errors?.fieldArray) && errors.fieldArray.find((data: any) => data?.question_gujrati))){
      setFieldValue("languageActiveTab", 1)
    } else {
      setFieldValue("languageActiveTab", 2)
    }
    window.scrollTo(0,1);
  }

  updateActiveState = () => {
    const { surveyList, tempID } = this.state;

    if (Array.isArray(surveyList) && surveyList.length > 0) {
      const changeIndex: number = surveyList.findIndex((data) => data.id === tempID);
      const tempList: Array<SurveyListType> = surveyList;
      tempList[changeIndex].attributes.active = !tempList[changeIndex]?.attributes?.active;
      this.setState({ surveyList: JSON.parse(JSON.stringify(tempList)) });
    }
  }

  deleteSurvey = (responseJson: TDeletedSurvey) => {
    if (responseJson && responseJson.data && responseJson.data.message) {
      this.setState(({ surveyList, deleteId }) => ({ surveyList: Array.isArray(surveyList) ? surveyList.filter((data) => data.id !== deleteId) : surveyList, deleteId: null }));
      this.openCustomModal(true, "Success", "Survey deleted Successfully.")
    } else if (responseJson && responseJson.errors && responseJson.errors.length) {
      this.openCustomModal(true, "Error", responseJson.errors[0])
    } else {
      this.openCustomModal(true, "Error", "Something went wrong")
    }
  }

  toggleDelete = (id: string | null) => {
    this.setState({ deleteId: id })
  }

  updateSurvey = (id: string, body : string) => {
    this.setState({ isLoading: true })
    this.updateSurveyId = this.apiCall(
      configJSON.PUT_METHOD_TYPE,
      configJSON.editSurvey(id),
      body
    )
  }

  getSurveysList = (page: number) => {
    this.setState({ page, surveyListLoading: true });
    this.surveyListId = this.apiCall(
      configJSON.GET_METHOD_TYPE,
      `${configJSON.SURVEYS_LIST}?per_page=6&page=${page}`
    );
  };

  changeSurveysActionState = (id: string, active: boolean) => {
    this.setState({ tempID: id }, () => this.updateActiveState())
    this.changeActiveStateSurveyId = this.apiCall(
      configJSON.PUT_METHOD_TYPE,
      configJSON.changeSurveysState(id),
      JSON.stringify({ active })
    );
  }

  deleteSurveyAPI = () => {
    this.deleteSurveyId = this.apiCall(
      configJSON.DELETE_METHOD_TYPE,
      `${configJSON.SURVEYS_LIST}/${this.state.deleteId}`
    );
  }

  getAllQuestion = () => {
    this.getAllQuestionId = this.apiCall(
      configJSON.GET_METHOD_TYPE,
      configJSON.GET_ALL_QUESTION
    );
  }

  createSurvey = (body: string) => {
    this.setState({ isLoading: true })
    this.createSurveyId = this.apiCall(
      configJSON.POST_METHOD_TYPE,
      configJSON.CREATE_SURVEY,
      body
    );
  }

  handleShareLink =(serveyObj?:any) => { 
   const shareLink = configJSON.surveyShareLink + serveyObj?.type +'/'+ serveyObj?.id
    const heading = `${serveyObj?.type} : `+serveyObj?.attributes?.name
    this.setState({
      isShare : !this.state.isShare,
      linkShare:shareLink,
      messageHeading:heading
    })   
  }

  apiCall = (method: string, endpoint: string, body?: string) => {
    const header = {
      token: localStorage.getItem("tokenn"),
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  openCustomModal = (open: boolean, title: string, description: string) => {
    this.setState({
      openCustomModal: open,
      titleCustomModal: title,
      descCustomModal: description,
    })
  }
  
  closeCustomModal = () => {
    this.setState({
      openCustomModal: false,
      titleCustomModal: "",
      descCustomModal: "",
    })
  }
  
  handleCreateResp = (responseJson: { data: SurveyListType[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ surveyList: responseJson.data.concat(...this.state.surveyList as SurveyListType[]), isLoading: false });
      this.openCustomModal(true, "Success", "Survey added Successfully.")
      this.setState({
        initialValues: {
          languageActiveTab: 0 as (0 | 1 | 2),
          title: { 0: "", 1: "", 2: "" },
          fieldArray: [
            JSON.parse(JSON.stringify({
              question: '',
              question_hindi: "",
              question_gujrati: "",
              question_type_id: "none",
              question_options_attributes: [JSON.parse(JSON.stringify(configJSON.optionInitialValue))]
            }))
          ]
        },
        formikInitalValue: null
      })
    } else {
      this.openCustomModal(true, "Error", "Something went wrong")
    }
  }

  navigateToSurveyDetails = (surveyId: string) => {
    this.props.navigation.navigate("SurveysDetails", { id: surveyId })
  }
  handelSurveyList = (responseJson: { data: SurveyListType[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ surveyListLoading: false, surveyList: [...this.state.surveyList, ...responseJson.data] as SurveyListType[] });
    } else {
      this.setState({ surveyListLoading: false, surveyList: [] });
    }
  }
  // Customizable Area End
}

// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple2, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateSimpleBodyType2, formateUnitBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}

interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    submitLoading: boolean
    dropdownDataLoading: boolean,
    dropdownData: IDropdownDetailsData[],
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
    // SEARCH
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class FarmsDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    updateDropdownsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            currentExpanded: null,
            // MSG MODAL
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
            // API CALLS
            dropdownDataLoading: false,
            dropdownData: [],
            submitLoading: false,
            searchValue: "",
            fetchHeadingsLoading: false,
            headingsData: [],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getHeadingsApiCallId:
                        this.GetHeadingsApiHandle(responseJson)
                        break;
                    case this.getDetailsApiCallId:
                        this.GetDetailsApiHandle(responseJson)
                        break;
                    case this.updateDropdownsApiCallId:
                        this.UpdateDropdownsApiHandle(responseJson)
                        break;
                }

            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsApiHandle = (response: IHeadings[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: response
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    GetDetailsApiHandle = (response: IDropdownDetailsData[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: response
            })
        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }
    successUpdate = (responseJson: IResponseDropdowns[]) => {
        if (responseJson && responseJson.length) {
            this.handleOpenMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.handleOpenMsgModal(true, "Error", "Something went wrong!")
        }
    }
    UpdateDropdownsApiHandle = (response: IResponseDropdowns[] | null | undefined) => {

        this.setState({ submitLoading: false })
        if (!Array.isArray(response) || response.length === 0) return;

        const hasErrorKey = response.some((item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasErrorKey) {
            const { title, message } = getTitleMessage(response);
            this.handleOpenMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(response);
        }
    }

    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }

    // API CALLS
    fetchDropdownDetails = (title: string) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        if (dropdownName && subDropdownName && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            })

            const header = { token: this.state.authToken };
            const requestApiMessag = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiCallId = requestApiMessag.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            requestApiMessag.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMessag.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMessag.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestApiMessag.id, requestApiMessag);
        } else {
            return
        }
    }
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMessag = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestApiMessag.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        requestApiMessag.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMessag.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMessag.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMessag.id, requestApiMessag);
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple2 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestApiMessag = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsApiCallId = requestApiMessag.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestApiMessag.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMessag.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMessag.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestApiMessag.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestApiMessag.id, requestApiMessag);
        } else {
            return
        }
    }

    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }
    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType2(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple2)[])
    }

    handleOpenMsgModal = (open: boolean, title: string, message: string) => {
        this.setState({
            openMsgModal: open,
            titleMsgModal: title,
            descMsgModal: message,
        })
    }
    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({ dropdownDataLoading: true, dropdownData: [] }, () => {
                this.fetchDropdownDetails(heading)
            })
        }
    };


    setSearch = (value: string) => {
        const queryVal = trimStart(String(value))
        this.setState({
            searchValue: queryVal,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }
    handleCloseMsgModal = () => {
        this.setState({
            openMsgModal: false,
            descMsgModal: "",
            titleMsgModal: "",
        })
    }
    // Customizable Area End
}
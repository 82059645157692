// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { isLanguageKey, returnTruthyString, trimStart, validateAllSimple } from "../../../../components/src/helper";

export const configJSON = require("../config");
const inputValueArray = [
    "by Machine",
    "by Labor",
    "by Weedicide",
    "by Bullock Drawn",

    "Trap Installation",
    "Foliar Spray",
    "Bio-Agent Release",

    "Drip",
    "Sprinkler",
    "Sprinklers",
    "Flood",

    "Alert",
    "Reminder",
    "Weather",
    "Mandi Price",
    "Activities",
    "Events And News",
    "Chat"
]
const dropdownNameArray = [
    "Weeding type",    
    "Type of Irrigation",
    "Pest management",
    "notification type"
]

export const isInputDisable = (item: IDefaultValue,dropdownName?:string) => {
    let disable = false;
    const exists = inputValueArray.some(key => key.toLowerCase() == (item["english"] as ILanguageObject).value.toLowerCase());

    const dropdown = dropdownName ? dropdownNameArray.includes(dropdownName):""
    if (item.id && exists && dropdown) {
        disable = true
    }
    return disable
}
export interface ILanguageObject {
    value: string;
    error: string;
    stateError?: string;
    districtError?: string;
    talukaError?: string;
}

interface IDefaultValue {
    id: string | number;
    [key: string]: ILanguageObject | string | number;
}

const defaultValue = {
    id: "",
    english: {
        value: "",
        error: ""
    },
    gujarati: {
        value: "",
        error: ""
    },
    hindi: {
        value: "",
        error: ""
    },
}

export interface Props {
    navigation: any;
    id: string;
    dataArray: IDefaultValue[],
    onSubmit?: (data: any) => void,
    submitLoading: boolean,
    dropdownName?:string
}
interface S {
    selectedTab: string;
    defaultForm: IDefaultValue,
    formArray: IDefaultValue[],
}
interface SS { }
// Customizable Area End

export default class SimpleDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start     
            selectedTab: configJSON.TAB.ENGLISH,
            defaultForm: { ...defaultValue },
            formArray: [],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
        }
        // Customizable Area End

    }

    // Customizable Area Start

    async componentDidMount() {
        this.setState({ formArray: [...this.props.dataArray] })
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            selectedTab: newValue
        })
    }
    handleAddAnotherInput = () => {
        const newForm = JSON.parse(JSON.stringify(this.state.defaultForm))
        if (this.validateNewForm(newForm)) {
            let existingData = [...this.state.formArray]
            existingData.push(newForm);
            this.setState({ formArray: existingData, defaultForm: { ...defaultValue } });
            this.setDefaultTab()
        } else {
            this.setState({ defaultForm: newForm });
        }
    }
    handleRemoveInput = (form: IDefaultValue, index: number) => {
        let existingData = [...this.state.formArray]

        if (index >= 0 && index < existingData.length) {
            const obj = existingData[index];
            if (obj.id) {
                obj["_destroy"] = form.id;
            } else {
                existingData.splice(index, 1);
            }
        }
        this.setState({ formArray: existingData });
    };

    // CHANGE EXISTING INPUTS
    handleChangeInput = (language: string, fieldIndex: number, fieldName: string, fieldValue: string,) => {
        this.setValue(language, fieldIndex, fieldName, fieldValue)
    }
    setValue = (language: string, fieldIndex: number, fieldName: string, fieldValue: string,) => {
        this.setState(prevState => {
            const updatedFormArray = [...prevState.formArray];

            updatedFormArray[fieldIndex] = {
                ...updatedFormArray[fieldIndex],
                [language]: {
                    ...updatedFormArray[fieldIndex][language] as ILanguageObject,
                    value: trimStart(String(fieldValue)),
                    error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty"

                }
            };
            return { formArray: updatedFormArray };
        });
    }

    // CHANGE DEFAULT INPUTS
    handleChangeInputDefault = (fieldName: string, fieldValue: string | number, language: string) => {
        this.setState(prevState => ({
            defaultForm: {
                ...prevState.defaultForm,
                [language]: {
                    ...prevState.defaultForm[language] as ILanguageObject,
                    value: trimStart(String(fieldValue)),
                    error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty"
                }
            }
        }));
    }
    setDefaultTab = (tabValue: string = configJSON.TAB.ENGLISH) => {
        this.setState({
            selectedTab: tabValue
        })
    }
    // VALIDATE NEW INPUT
    validateNewForm = (newForm: IDefaultValue) => {

        let isErrorEnglish = false
        let isErrorGujarati = false
        let isErrorHindi = false

        for (const language in newForm) {
            if (isLanguageKey(language)) {
                const currentLanguage = newForm[language] as ILanguageObject;
                if (currentLanguage.value.trim() == '') {
                    currentLanguage.error = "Field cannot be empty";
                } else {
                    currentLanguage.error = "";
                }
            }
        }

        if ((newForm[configJSON.TAB.ENGLISH] as ILanguageObject).error) {
            isErrorEnglish = true
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if ((newForm[configJSON.TAB.GUJARATI] as ILanguageObject).error) {
            isErrorGujarati = true
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if ((newForm[configJSON.TAB.HINDI] as ILanguageObject).error) {
            isErrorHindi = true
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
        const isFormHasError = isErrorEnglish || isErrorGujarati || isErrorHindi
        if (isFormHasError) {
            return false
        } else {
            return true
        }
    }

    changeTab = (isErrorEnglish: boolean, isErrorGujarati: boolean, isErrorHindi: boolean) => {
        if (isErrorEnglish) {
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if (isErrorGujarati) {
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if (isErrorHindi) {
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
        return isErrorEnglish || isErrorGujarati || isErrorHindi
    }
    // VALIDATE ALL INPUT
    validateAllForms = () => {
        const allForms = [...this.state.formArray]
        const { isErrorEnglish:English,isErrorGujarati:Gujarati,isErrorHindi:Hindi,allForms:UpdatedForms} = validateAllSimple(allForms)
        // SET THE NEW VALUE AND ERROR
        this.setState({
            formArray: UpdatedForms,
        })

        return !this.changeTab(English, Gujarati, Hindi) ? true : false
    }

    handleSubmit = () => {
        this.setState({ defaultForm: { ...defaultValue } })
        if (this.validateAllForms()) {
            this.props.onSubmit && this.props.onSubmit(this.state.formArray)
        }
    }


    // Customizable Area End
}
import React, { Fragment } from "react";

import {
  // Customizable Area Start
  Grid,
  Modal,
  Card,
  Box,
  Typography,
  IconButton,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Divider,
  TextField,
  Button,
  styled,
  FormControlLabel,
  InputAdornment,
  FormHelperText,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateRange, Add } from "@material-ui/icons";
import { keyDown } from "../../../components/src/helper";
import { ICropDetails, ICropName, UserFarm } from "../../../components/src/interface.web";
import GapFillingActivityController, {
  IntialGapFillingValuesTypes,
  Props,
  configJSON,
} from "./GapFillingActivityController";
import {
  closeIconSvg,
  deleteIcon,
  gapFillingSvg,
  rupeeIconSVG,
} from "./assets";
import moment from "moment";
import CustomModal from "../../../components/src/customModal.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const inputpropSeedCost = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 0,
    max: 50000,
    step: 1, // Allow Integer values
  },
};
// Customizable Area End

export default class GapFillingActivity extends GapFillingActivityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  gapFillingActivity = () => {
    return (
      <Grid item>
        <Modal
          open={this.props.isGapFillingActivity}
          onClose={this.handleCloseModal}
          data-testid="close_modal_button"
          style={webStyle.secondaryModalBox}
          BackdropProps={{
            style: webStyle.modalBackdropProps,
          }}
          className="addCropModal"
        >
          <Card variant="outlined" style={webStyle.AddCardBoxSuccess}>
            <Box style={webStyle.boxContainer}>
              <CustomAddCropBox>
                <Box style={webStyle.cropHeader}>
                  <Box style={webStyle.headerLeft}>
                    <img
                      src={gapFillingSvg}
                      alt="Crop image"
                      style={webStyle.headerImage}
                    />
                    <Typography style={webStyle.headerTitle}>
                      Gap Filling
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={this.handleCloseModal}
                    >
                      <img
                        src={closeIconSvg}
                        style={{ width: "24px", height: "24px" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                {this.state.getActivityDetailsLoading ?
                            <Box style={webStyle.cropFormLoaderBox}>
                                <CircularProgress size={40} color="secondary" />
                            </Box>
                            :
                <form
                  data-testid="from-submit-testid"
                  noValidate
                  autoComplete="off"
                  className="customFormSpacing"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGridWrapper container>
                    <ResponsiveGrid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className="text-field-container"
                      >
                        <FormLabel className="phasesLabelCustom">
                          Select Farm
                        </FormLabel>
                        <FormControl fullWidth>
                          <Select
                            style={webStyle.inputFieldStyle}
                            className="selectDDCustom"
                            data-testid="select_farm_testid"
                            variant="outlined"
                            color="secondary"
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            name="select_farm"
                            renderValue={(value) => {
                              if (!this.state.formLandDetailId) {
                                return (
                                  <span className="opcity-48">Select farm</span>
                                );
                              }
                              if (this.state.formLandDetailId) {
                                return this.state.farmNamesList.find(
                                  (item) => item?.attributes?.id == this.state.formLandDetailId
                                )?.attributes?.name ;
                              }
                            }}
                            disabled={this.props.isEditActivity}
                            value={this.state.formLandDetailId}
                            onChange={(
                              event: React.ChangeEvent<{
                                value: unknown | string;
                              }>
                            ) =>
                              this.handleFarmSelect(event.target.value as string)
                            }
                            error={!!this.state.formLandDetailIdError}
                            MenuProps={{
                              style: { maxWidth: '400px' }
                            }}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select farm</span>
                            </MenuItem>
                            {this.state.farmNamesList.map((farm: UserFarm) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={farm?.attributes?.id}
                                  key={farm?.attributes?.id}
                                >
                                  <RadioGroup
                                    value={this.state.formLandDetailId}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomFormControlLabel
                                      value={farm?.attributes?.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              style={webStyle.cropDropDownLable}
                                            >
                                              {
                                                farm.attributes?.name
                                              }
                                            </Typography>
                                          </Box>
                                          <Typography
                                            style={webStyle.cropUnitText}
                                          >
                                            {farm.attributes?.farm_area}{" "}
                                            {
                                              farm.attributes?.unit_farm_area_id
                                                ?.name
                                            }
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              style={{
                                ...webStyle.shareInChat,
                                marginLeft: "16px",
                                color: "#006A77",
                                marginTop: "8px",
                              }}
                              onClick={this.handleAddFarm}
                              startIcon={
                                <Add
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                            >
                              Add Farm
                            </CustomButton>
                          </Select>
                          {this.state.formLandDetailIdError && (
                            <FormHelperText style={webStyle.errorStyle}>
                              {this.state.formLandDetailIdError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className="text-field-container"
                      >
                        <FormLabel className="phasesLabelCustom">
                          Select Crop
                        </FormLabel>
                        <FormControl fullWidth>
                          <Select
                            style={webStyle.inputFieldStyle}
                            className="selectDDCustom"
                            data-testid="select_farm_testid"
                            variant="outlined"
                            color="secondary"
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            name="select_farm"
                            renderValue={(value) => {
                              if (!this.state.formCropId) {
                                return (
                                  <span className="opcity-48">Select crop</span>
                                );
                              }
                              if (this.state.formCropId) {
                                return this.state.cropNameList.find(
                                  (item) => item?.attributes?.id == this.state.formCropId
                                )?.attributes?.crop_name_id?.name ;
                              }
                            }}
                            value={this.state.formCropId}
                            disabled={!this.state.formLandDetailId || this.props.isEditActivity}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) =>
                             this.handleCropSelection(event.target.value as string)
                            }
                            error={!!this.state.formCropIdError}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select crop</span>
                            </MenuItem>
                            {this.state.cropNameList.map((item: ICropDetails) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={item?.attributes?.id}
                                  key={item?.attributes?.id}
                                >
                                  <RadioGroup
                                    value={this.state.formCropId}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomFormControlLabel
                                      value={item?.attributes?.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              style={webStyle.cropDropDownLable}
                                            >
                                              {
                                                item.attributes?.crop_name_id
                                                  ?.name
                                              }{" "}
                                              |{" "}
                                              <span
                                                style={{ color: "#108B88" }}
                                              >
                                                {
                                                  item.attributes?.crop_type_id
                                                    ?.name_english
                                                }
                                              </span>
                                            </Typography>
                                          </Box>
                                          <Typography
                                            style={webStyle.cropUnitText}
                                          >
                                            {item.attributes?.total_crop_area}{" "}
                                            {
                                              item.attributes?.unit_of_area_id
                                                ?.name
                                            }
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              style={{
                                ...webStyle.shareInChat,
                                marginLeft: "16px",
                                color: "#006A77",
                                marginTop: "8px",
                              }}
                              onClick={this.handleAddCrop}
                              startIcon={
                                <Add
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                            >
                              Add Crop
                            </CustomButton>
                          </Select>
                          {this.state.formCropIdError && (
                            <FormHelperText style={webStyle.errorStyle}>
                              {this.state.formCropIdError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </ResponsiveGrid>
                    <Grid container>
                      <Divider
                        component={"hr"}
                        style={{
                          background: "#F0EBE0",
                          width: "100%",
                          height: "2px",
                          marginTop: "8px",
                        }}
                      />
                    </Grid>
                    {this.state.gapFillingFormValues.map(
                      (formValue: IntialGapFillingValuesTypes, i: number) => {
                        return (
                          <Fragment key={i}>
                            <ResponsiveGrid
                              container
                              spacing={2}
                              style={{ position: "relative" }}
                            >
                              <Grid
                                item
                                xs={12}
                                lg={6}
                                className="text-field-container addCropDate"
                              >
                                <FormLabel className="phasesLabelCustom">
                                  Date
                                </FormLabel>
                                <FormControl fullWidth>
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      required
                                      data-testid="sowing_date_testid"
                                      className="customDatePicker custom-textbox"
                                      placeholder="DD/MM/YYYY"
                                      format="DD/MM/yyyy"
                                      inputVariant="outlined"
                                      color="secondary"
                                      keyboardIcon={
                                        <DateRange
                                          style={webStyle.customDateIcon}
                                        />
                                      }
                                      minDate={this.state.minimumDate}
                                      minDateMessage={
                                        "Please select a valid date"
                                      }
                                      maxDate={moment()}
                                      maxDateMessage={
                                        "Selected date cannot be a future date"
                                      }
                                      name="date"
                                      value={formValue.date}
                                      onChange={(newDate) =>
                                        this.handleInputChange(
                                          {
                                            target: {
                                              name: "date",
                                              value: newDate,
                                            },
                                          },
                                          i
                                        )
                                      }
                                      error={!!formValue.errors.date}
                                      helperText={formValue.errors.date}
                                    />
                                  </MuiPickersUtilsProvider>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                lg={6}
                                className="text-field-container"
                              >
                                <FormLabel className="phasesLabelCustom">
                                  Labor cost
                                </FormLabel>
                                <FormControl>
                                  <TextField
                                    className="inputIconTextFieldCustom custom-textbox"
                                    type="number"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder="Total cost"
                                    data-testid="labor_cost_testid"
                                    InputProps={inputpropSeedCost}
                                    onKeyDown={keyDown}
                                    name="labor_cost"
                                    value={formValue.labor_cost}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                    error={!!formValue.errors.labor_cost}
                                    helperText={formValue.errors.labor_cost}
                                  />
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                lg={6}
                                className="text-field-container"
                              >
                                <FormLabel className="phasesLabelCustom">
                                  Seed Cost (if any)
                                </FormLabel>
                                <FormControl>
                                  <TextField
                                    className="inputIconTextFieldCustom custom-textbox"
                                    type="number"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder="Total cost"
                                    data-testid="price_of_seed_testid"
                                    InputProps={inputpropSeedCost}
                                    onKeyDown={keyDown}
                                    name="price_of_seed"
                                    value={formValue.price_of_seed}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                    error={!!formValue.errors.price_of_seed}
                                    helperText={formValue.errors.price_of_seed}
                                  />
                                </FormControl>
                              </Grid>
                              {this.state.gapFillingFormValues.length > 1 && (
                                <Box
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    visibility:!!formValue?.id ? "hidden" : "visible"
                                  }}
                                >
                                  <IconButton
                                    style={webStyle.iconbutton}
                                    onClick={() =>
                                      this.handleRemoveGapFilling(i)
                                    }
                                    disabled={!!formValue?.id }
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt="Delete activity"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  </IconButton>
                                </Box>
                              )}
                            </ResponsiveGrid>
                            <Divider
                              component={"hr"}
                              style={{
                                background: "#F0EBE0",
                                width: "100%",
                                height: "2px",
                                marginTop: "8px",
                              }}
                            />
                          </Fragment>
                        );
                      }
                    )}

                    <ButtonGrid container>
                      <Button
                        type="submit"
                        style={{
                          ...webStyle.cropSubmitButton,
                          background: "#F0EBE0",
                        }}
                        onClick={this.handleAddAnotherEntryGapFilling}
                      >
                        <Add style={{ marginRight: "8px", opacity: "64%" }} />
                        <Typography
                          style={{
                            ...webStyle.cropSubmitButtonText,
                            color: "#1C2324",
                            opacity: "64%",
                          }}
                        >
                          Add another Entry
                        </Typography>
                      </Button>

                      <Button
                        type="submit"
                        style={{
                          ...webStyle.cropSubmitButton,
                          marginTop: "32px",
                        }}
                        data-testid="submit_button_testid"
                        onClick={this.handleFormSubmit}
                        disabled={this.state.createGapFillingLoading}
                      >
                        <Typography style={webStyle.cropSubmitButtonText}>
                          {this.state.createGapFillingLoading ? (
                            <CircularProgress
                              size={28}
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            this.props.isEditActivity ? "UPDATE":"Save"
                          )}
                        </Typography>
                      </Button>
                    </ButtonGrid>
                  </FormGridWrapper>
                </form>
                }
              </CustomAddCropBox>
            </Box>
          </Card>
        </Modal>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.gapFillingActivity()}
        {this.state.customMessage && (
          <CustomModal
            open={!!this.state.customMessage}
            onClose={this.handleCloseCustomModal}
            title={this.state.customMessageTitle}
            description={this.state.customMessage}
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ButtonGrid = styled(Grid)({
  margin: "32px",
  marginTop: "8px",
  "@media (max-width:600px)": {
    marginInline: "24px",
  },
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
});

const CustomButton = styled(Button)({
  "& .MuiButton-startIcon": {
    marginRight: "2px ",
  },
});

const ResponsiveGrid = styled(Grid)({
  marginInline: "24px",
  "@media (max-width:600px)": {
    marginInline: "16px",
  },
});

const CustomAddCropBox = styled(Box)({});

const FormGridWrapper = styled(Grid)({
  rowGap: "16px",
});

const webStyle = {
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  } as React.CSSProperties,
  activityLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
    marginTop: "4px",
    color: "#1C2324",
  } as React.CSSProperties,
  AddCardBoxSuccess: {
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    maxWidth: "712px",
    flexDirection: "column",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  boxContainer: {
    fontSize: "16px",
    height: "100%",
    overflowY: "auto",
  } as React.CSSProperties,
  cropHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "32px",
    marginBottom: "16px",
  } as React.CSSProperties,
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  inputFieldStyle: {
    height: "56px",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  cropSubmitButton: {
    borderRadius: "28px",
    background: "#DF5200",
    width: "100%",
    paddingBlock: "14px",
  } as React.CSSProperties,
  cropSubmitButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
  addActivityModalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as React.CSSProperties,
  closeIcon: {
    width: "24px",
    height: "24px",
  } as React.CSSProperties,
  activityItem: {
    width: "142px",
    height: "142px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0EBE0",
    paddingInline: "10px",
    cursor: "pointer",
  } as React.CSSProperties,
  activityImage: {
    width: "56px",
    height: "56px",
  } as React.CSSProperties,
  cropDropDownLable: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  cropUnitText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontStyle: "italic",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  },
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  errorStyle: {
    fontSize: "0.75rem",
    color: "#f44336",
  } as React.CSSProperties,
  textOverflowEllipsis: {
    width:"100%",
    overflow:"hidden",
    textOverflow:"ellipsis",
    margin:0
  } as React.CSSProperties,
  cropFormLoaderBox:{
    height:"100%",
    minHeight:"550px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
}as React.CSSProperties,
  
};
// Customizable Area End

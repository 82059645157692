// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple1, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateSimpleBodyType1, formateUnitBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    // API CALLS
    submitLoading: boolean
    dropdownDataLoading: boolean,
    dropdownData: IDropdownDetailsData[],
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
    // SEARCH
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class WeedDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsCallid: string = "";
    getDetailsCallid: string = "";
    updateDropdownsCallid: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            currentExpanded: null,
            searchValue: "",
            openMsgModal: false,
            submitLoading: false,
            fetchHeadingsLoading: false,
            dropdownDataLoading: false,
            titleMsgModal: "",
            headingsData: [],
            dropdownData: [],
            descMsgModal: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getHeadingsCallid:
                        this.GetHeadingsHandleResp(responseJson)
                        break;
                    case this.getDetailsCallid:
                        this.GetDetailsHandleResp(responseJson)
                        break;
                    case this.updateDropdownsCallid:
                        this.UpdateDropdownHandleResp(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }

    // HANDLE API CALLS
    UpdateDropdownHandleResp = (response: IResponseDropdowns[] | null | undefined) => {

        this.setState({ submitLoading: false })
        if (!Array.isArray(response) || response.length === 0) {
            this.openMsgModal(true, "Error", "Something went wrong!")
            return;
        }

        const isError = response.some((item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (isError) {
            const { title, message } = getTitleMessage(response);
            this.openMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(response);
        }
    }

    GetHeadingsHandleResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    successUpdate = (response: IResponseDropdowns[]) => {
        if (response && response.length) {
            this.openMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) { this.fetchDropdownDetails(this.state.currentExpanded) }
        } else {
            this.openMsgModal(true, "Error", "Something went wrong!")
        }
    }
    GetDetailsHandleResp = (responseJson: IDropdownDetailsData[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: responseJson
            })
        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }


    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsCallid = reqMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqMsg.id, reqMsg);
    }
    fetchDropdownDetails = (title: string) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        if (dropdownName && subDropdownName && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            })

            const header = { token: this.state.authToken };
            const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsCallid = reqMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            reqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(reqMsg.id, reqMsg);
        } else {
            return
        }
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple1 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsCallid = reqMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            reqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            reqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(reqMsg.id, reqMsg);
        } else {
            return
        }
    }


    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType1(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
    }

    closeMsgModal = () => {
        this.setState({
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
        })
    }
    handleCurrentExpanded = (heading: string) =>
        (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            this.setState({
                currentExpanded: isExpanded ? heading : null
            })
            if (isExpanded) {
                this.setState({
                    dropdownDataLoading: true, dropdownData: []
                }, () => {
                    this.fetchDropdownDetails(heading)
                })
            }
        };
    openMsgModal = (open: boolean,
        title: string,
        message: string) => {
        this.setState({
            openMsgModal: open,
            titleMsgModal: title,
            descMsgModal: message,
        })
    }
    setSearchValue = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchValue: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }

    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }


    // Customizable Area End
}
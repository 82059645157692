import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Badge,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Tooltip,
  Avatar,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  FormLabel,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "./AdminConsole.web.css";
import "./AdminConsoleAdminUserScreen.web.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { PhotoCamera } from "@material-ui/icons";
import { AddUserPlusIconSVG, imgIconFilter, linkSeparatorSVG } from "./assets";
import { DataGrid } from "@mui/x-data-grid";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom"
// Customizable Area End

import AdminConsoleUserController, {
  Props,
  configJSON,
} from "./AdminConsoleAdminUserScreenController";
import CustomModal from "../../../components/src/customModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { StyledBadgeViewAdmin, fieldExecutiveColumns, themeAdmin, totalAreaCottonValue } from "../../../components/src/AdminConsoleHelper";
import UserTableFilter from "../../../components/src/UserTableFilter";
import AdminUserForm from "../../../components/src/AdminUserForm.web";
import FieldExecutiveForm from "../../../components/src/FieldExecutiveForm.web";
import { SchemaFarmerForm, SchemaFieldExecutiveForm, keyDown, keyDownInt } from "../../../components/src/helper";
import FarmerUserForm from "../../../components/src/FarmerForm.web";
import AssignVillageFilter from "../../../components/src/AssignVillageFilter";
import ImageCropEditor from "../../../components/src/ImageCropper";
const { screenDefaultsTexts: screenDefaults } = configJSON;

export default class AdminConsole extends AdminConsoleUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getEyeIconPassword = () => {
    return this.state.showEyeIconPassword ? (
      <Visibility style={userModalStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={userModalStyle.customDateIcon} />
    );
  };
  villageNameAvatar = (index: number, option: any) => {
    return (
      <>
        <Avatar
          style={{
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "18px",
            backgroundColor: `${index % 2 === 0 ? "#108B88" : "#EB8F06"}`,
            color: "#fff",
            height: "28px",
            width: "28px",
          }}
        >
          {option?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </>
    );
  };


  getEyeIconConfirmPassword = () => {
    return this.state.showEyeIconConfirmPassword ? (
      <Visibility style={userModalStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={userModalStyle.customDateIcon} />
    );
  };
  getCustomErrorMessage = (touched: any, errors: any, value: string) => {
    return (
      touched[value] &&
      errors[value] && (
        <div>
          <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
            {errors[value]}
          </Typography>
        </div>
      )
    );
  };


  showFEListTable = () => {
    return (
      <DataGrid
        data-testid="adminTableTestid"
        disableSelectionOnClick
        style={{ height: "inherit" }}
        disableColumnMenu
        rowHeight={46}
        rows={this.state.FEListData}
        columns={fieldExecutiveColumns(
          (data: any) => this.handleClickFeEdit(data),
          (data: any) => this.handleClickFEDelete(data)
        )}
        pageSize={this.state.FEPageSize}
        onPageSizeChange={(page: number) => {
          this.setState({ FEPageSize: page });
        }}
        loading={this.state.FEListDataLoading}
        rowsPerPageOptions={[15, 30, 50]}
        getRowId={(row: any) => row.id}
        rowCount={this.state.totalSearchedCount}
        page={this.state.FETableCurrentPage}
        onPageChange={(page: number) => {
          this.setState({
            FETableCurrentPage: page,
          });
        }}
      />
    );
  };

  adminAvatar = () => {
    return (
      this.state.currentAdminData.first_name && (
        <Avatar
          style={profileBlockStyles.profileAvatar}
          src={this.state.currentAdminData.avatar}
        >
          {this.state.currentAdminData.first_name[0]}
        </Avatar>
      )
    )
  }
  adminPhoneNumber = () => {
    return this.state.currentAdminData.phone_number ? "+91-" + this.state.currentAdminData.phone_number : ""
  }
  adminDateOfBirth = () => {
    return this.state.currentAdminData.dob ? moment(this.state.currentAdminData.dob).format("DD MMM YYYY") : ""
  }

  adminProfileSection = () => this.state.adminProfileImage?.icon && <ImageCropEditor open={this.state.cropImageModal} src={this.state.adminProfileImage.icon} onClose={this.handleCloseCropModalAdmin} title="Admin profile" buttonText="Submit" handleUploadImage={this.handleSubmitAdminProgfileImage} />

  render() {
    // Customizable Area Start
    const { navigation, id } = this.props;
    const { currentAdminData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={themeAdmin}>
          <Container className="mainConatiner" maxWidth={"xl"}>
            {this.adminProfileSection()}
            <Box display="flex" alignItems="center" justifyContent="space-between" >
              <Box>
                <Box display="flex" gridGap="10px" alignItems="center">
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "48px",
                      color: "#1C2324",
                    }}
                  >
                    Users
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row-reverse"
                gridGap={5}
              >
                <Button variant="contained"
                  data-testid="addUserModalBtn"
                  style={userModalStyle.addUserButton}
                  onClick={this.handleOpenAddUserModal}
                // disabled
                >
                  <img src={AddUserPlusIconSVG} alt="+" style={{ marginRight: "8px" }} />Add User
                </Button>
              </Box>
            </Box>

            <Box className="breadcrumbMain">
              <Breadcrumbs
                separator={<img src={linkSeparatorSVG} alt={"separator"} />}
                aria-label="breadcrumb"
              >
                <Link style={{ textDecoration: "none" }} className="menuLinkUser" to="/users" >
                  Users
                </Link>
                <Typography className="menuTextUser">View Profile</Typography>
              </Breadcrumbs>
            </Box>

            <Box mt={1.5}>
              <Grid
                container
                justifyContent="space-between"
                spacing={3}
                direction="row"
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="profile-card-wrapper"
              >
                <Grid container item spacing={1} lg={6} md={12} sm={12} xs={12} className="profile-card-left-wrapper">
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "4px" }}
                  >
                    <Box sx={adminViewStyle.maincard}>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        style={profileBlockStyles.profileContainer}
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid item xs={12} sm={12} md={3} spacing={1}>
                          <Box
                            style={profileBlockStyles.profileAvatarContainer}
                          >
                            <input type="file" id="upload" data-test-id="uploadProfileButton" onChange={(event) => event.currentTarget?.files?.length && this.onProfileImageUpload(event)} accept="image/*" style={{ display: 'none' }} />

                            <Box>
                              <label htmlFor="upload" style={{ display: 'flex', alignItems: 'center' }}>

                                <StyledBadgeViewAdmin
                                  overlap="rectangular"
                                  badgeContent={
                                    <PhotoCamera
                                      fontSize="small"
                                      style={profileBlockStyles.profileIconButton}
                                    />
                                  }
                                  data-testid="badge-content"
                                >
                                  <IconButton
                                    disableRipple={true}
                                    aria-label="camera-edit_icon"
                                    style={profileBlockStyles.avatarContainer}
                                  >
                                    {this.adminAvatar()}
                                  </IconButton>
                                </StyledBadgeViewAdmin>
                              </label>
                            </Box>
                            <Box className="profileName">
                              <Tooltip
                                title={`
                                  ${currentAdminData.first_name} 
                                  ${currentAdminData.last_name}
                                `}
                              >
                                <Typography
                                  noWrap
                                  data-testid="profile-name"
                                  style={
                                    profileBlockStyles.avatarTypographyTitle
                                  }
                                >{`${currentAdminData.first_name} ${currentAdminData.last_name}`}</Typography>
                              </Tooltip>
                              <Typography
                                noWrap
                                style={
                                  profileBlockStyles.avatarTypographyContact
                                }
                              >
                                {this.adminPhoneNumber()}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          direction="column"
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Grid item xs={12} justifyContent="flex-end">
                            <Box
                              style={profileBlockStyles.profileLabelContainer}
                            >
                              <Box
                                component="span"
                                style={profileBlockStyles.labelSpan}
                              >
                                <Box style={profileBlockStyles.profileLabel}>
                                  {screenDefaults.profileTextEmail}&#58;
                                </Box>
                                <Tooltip title={currentAdminData.email}>
                                  <Typography
                                    noWrap
                                    style={{
                                      ...profileBlockStyles.profileLabelValue,
                                      ...{ maxWidth: "70%" },
                                    }}
                                  >
                                    {currentAdminData.email}
                                  </Typography>
                                </Tooltip>

                              </Box>
                              <Box
                                component="span"
                                style={profileBlockStyles.labelSpan}
                              >
                                <Box style={profileBlockStyles.profileLabel}>
                                  {screenDefaults.profileTextUsername}&#58;
                                </Box>
                                <Typography
                                  noWrap
                                  style={profileBlockStyles.profileLabelValue}
                                >
                                  {currentAdminData.user_name}
                                </Typography>
                              </Box>
                              <Box
                                component="span"
                                style={profileBlockStyles.labelSpan}
                              >
                                <Box style={profileBlockStyles.profileLabel}>
                                  {screenDefaults.profileTextDOB}&#58;
                                </Box>
                                <Typography
                                  noWrap
                                  style={profileBlockStyles.profileLabelValue}
                                >
                                  {this.adminDateOfBirth()}
                                </Typography>
                                <Typography
                                  noWrap
                                  style={profileBlockStyles.profileLabelValue}
                                ></Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            alignItems="flex-end"
                          >
                            <Box
                              style={profileBlockStyles.functionButtonContainer}
                              data-test-id={"profile-container"}
                            >
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  disableRipple
                                  style={{ padding: "0px" }}
                                  data-testid="profileEditButton"
                                  onClick={() => {
                                    this.handleEditAdmin();
                                  }}
                                // disabled
                                >
                                  <EditIcon
                                    fontSize="medium"
                                    style={{
                                      cursor: "pointer",
                                      color: "#EB8F06",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={this.state.isLoggedUserAdmin ? "Disabled" : "Delete"}>
                                <span>
                                  <IconButton
                                    aria-label="delete"
                                    disableRipple
                                    style={{ padding: "0px" }}
                                    data-testid={`profileDeleteButton`}
                                    onClick={() => {
                                      this.setState({ openAdminDeletePopup: true })
                                    }}
                                    disabled={this.state.isLoggedUserAdmin}
                                  >
                                    <DeleteIcon
                                      fontSize="medium"
                                      style={{
                                        cursor: "pointer",
                                        color: "#B3261E",
                                      }}
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  wrap="wrap"
                  justifyContent="space-between"
                  alignContent="flex-start"
                  style={{ marginTop: "8px" }}
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  className="sub-div-row-wrapper"
                  xs={12}
                >
                  <Grid item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="sub-div-column">
                    <Box sx={adminViewStyle.sideCard} className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Role:
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Admin
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12} className="sub-div-column">
                    <Box sx={adminViewStyle.sideCard} className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Total number of
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Farmers
                            </Typography>
                          </Box>
                          <Box className="fontBlue">
                            <Typography
                              data-testid="farmer-card"
                              className="countTxtInfoCard"
                            >
                              {this.state.totalFarmerCount}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item
                    xl={6} lg={6} md={12} sm={12} xs={12}
                    className="sub-div-column">
                    <Box sx={adminViewStyle.sideCard} className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Total number of
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Field Executives
                            </Typography>
                          </Box>
                          <Box className="fontBlue">
                            <Typography
                              data-testid="fe-card"
                              className="countTxtInfoCard"
                            >
                              {this.state.totalFECount}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item
                    xl={6} lg={6} md={12} sm={12} xs={12} className="sub-div-column">
                    <Box sx={adminViewStyle.sideCard} className="infocard">
                      <Box className="infocardContent">
                        <Box className="setContentLine">
                          <Box className="leftSubdiv">
                            <Typography className="subTitleInfoCard">
                              Total area of
                            </Typography>
                            <Typography className="mainTitleInfoCard">
                              Cotton
                            </Typography>
                          </Box>
                          <Box className="fontBlue">
                            <Typography className="countTxtInfoCard">
                              {totalAreaCottonValue(this.state.totalAreaCotton)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mt={5} sx={adminViewStyle.tableWrapper}>
              <Box mb={2} sx={adminViewStyle.tableFilterWrapper}>
                <Grid container direction="row" spacing={3}>
                  <Grid item lg={4} md={5} sm={6}>
                    <Typography className="list-user-title">
                      {configJSON.AdminConsoleDefaults.LIST_FE}
                    </Typography>
                  </Grid>
                  <Grid item
                    lg={8}
                    md={7}
                    sm={12}
                    style={{ paddingRight: "0px" }}
                  >
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <Box
                        style={{
                          background: "#FCFAF7",
                          border: "2px solid #F0EBE0",
                          borderRadius: "28px",
                          width: "100%",
                          padding: "7px",
                        }}
                      >
                        <Input
                          id="searchInputId"
                          type="search"
                          name="searchInput"
                          disableUnderline
                          fullWidth
                          autoComplete="off"
                          className="tableSearchInputfilter"
                          data-testid="tableInputSearchTestid"
                          placeholder="Search by Name / Mobile number"
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon
                                style={{ color: "#1C2324", opacity: 0.48 }}
                              />
                            </InputAdornment>
                          }
                          onChange={(e: any) =>
                            this.setState({
                              searchFEbyNameValue: e.target.value,
                              FETableCurrentPage: 0,
                            }, () => {
                              this.debouncedSearchTable();
                            })
                          }
                          value={this.state.searchFEbyNameValue}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          data-testid="tableFilterIconBtnTestid"
                          onClick={(event) => {
                            this.setState({ anchorEl: event.currentTarget, openTableFilterPopup: true })
                          }}
                        >
                          <Badge
                            variant="dot"
                            color="primary"
                            className="filterApplied"
                            invisible={this.state.hideBadge}
                          >
                            <img src={imgIconFilter} />
                          </Badge>
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className="tableGirdDiv" sx={adminViewStyle.mainTable}>
                {this.showFEListTable()}
              </Box>
            </Box>
          </Container>


          <Dialog maxWidth={"md"}
            open={this.state.userModal}
            PaperProps={{
              style: { borderRadius: 12, width: "712px" },
            }}
          >
            <Box
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box>
                <DialogTitle style={{padding:"32px 32px 19px 32px"}}>
                  <Typography variant="subtitle1">
                    {this.getAddUserModalTitleTxt()}
                  </Typography>
                </DialogTitle>
              </Box>
              <Box style={{ marginRight: 20 }}>
                <IconButton
                  aria-label="close"
                  data-testid="modalCloseBtnTestid"
                  onClick={this.handleCloseAddUserModal}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent dividers className="modalBodyWrapper">
              <Box className="userFormBodyWrapper">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12}
                    md={12} lg={12} xl={12} className="formfieldWrapper"
                  >
                    <FormLabel className="customLabelField">
                      User Type
                    </FormLabel>
                    <FormControl fullWidth className="userTypeFormControl">
                      <Select
                        data-testid="createUserModalDropdown"
                        className="farmerFormDDClass"
                        variant="outlined"
                        color="secondary"
                        value={this.state.seletedUserTypeAddUser}
                        onChange={(e: any) =>
                          this.updateSeletedUserTypeAddUser(e.target.value)
                        }
                        displayEmpty
                        readOnly={this.editModalStatus()}
                        disabled={this.editModalStatus()}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={"Farmers"}>Farmers</MenuItem>
                        <MenuItem value={"FieldExecutive"}>
                          Field Executive
                        </MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                        {!this.state.isLoggedUserAdmin ? <MenuItem value={"SA"}>Super Admin</MenuItem> : (<></>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {this.state.seletedUserTypeAddUser === "Admin" ||
                  this.state.seletedUserTypeAddUser === "SA" ? (
                  <AdminUserForm
                    adminInitialValues={this.state.adminInitialValues}
                    validationSchemaAdminobj={this.handleAdminValidationSchema}
                    getErrorMessage={this.getErrorMessageBoolean}
                    getCustomErrorMessage={this.getCustomErrorMessage}
                    getHelperText={this.getHelperText}
                    getPasswordType={this.getPasswordType}
                    getConfirmPasswordType={this.getConfirmPasswordType}
                    getEyeIconPassword={this.getEyeIconPassword}
                    getEyeIconConfirmPassword={this.getEyeIconConfirmPassword}
                    getshowEyeIcon={this.getshowEyeIcon}
                    showEyeIconConfirm={this.showEyeIconConfirm}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    disableSubmitBtn={this.state.addAdminLoading || this.state.updateAdminLoading || this.state.addSALoading}
                    handleSubmitAdmin={this.handleSubmitAdmin}
                  />
                ) : (
                  <></>
                )}
                {this.state.seletedUserTypeAddUser === "FieldExecutive" ? (
                  <FieldExecutiveForm
                    feInitialValues={this.state.feInitialValues}
                    validationSchemaFEobj={SchemaFieldExecutiveForm}
                    getMobileDeviceModelFEData={this.state.getMobileDeviceModelFEData}
                    selectedLocationArr={this.state.selectedLocationArr}
                    getLocationsListFEData={this.state.AllVillageListData}

                    onFeLocationsChange={this.onFeLocationsChange}
                    assignVillagePlaceholder={this.assignVillagePlaceholder}
                    villageNameAvatar={this.villageNameAvatar}
                    handleVillageDelete={this.handleVillageDelete}

                    getErrorMessage={this.getErrorMessageBoolean}
                    getCustomErrorMessage={this.getCustomErrorMessage}
                    getHelperText={this.getHelperText}

                    showTableFilterModal={this.showTableFilterModal}
                    anchorCurrentTarget={this.anchorCurrentTarget}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    disableSubmitBtn={this.state.addFELoading || this.state.updateFELoading}
                    handleSubmitFEUser={this.handleSubmitFEUser}
                  />
                ) : (
                  <></>
                )}
                {this.state.seletedUserTypeAddUser === "Farmers" ? (
                  <FarmerUserForm
                    data-testid="addUserFarmerForm"
                    farmerInitialValues={this.state.farmerInitialValues}
                    validationSchemaFarmerobj={SchemaFarmerForm}
                    handleSubmitFarmer={this.handleSubmitFarmer}

                    keyDown={keyDown}
                    keyDownInt={keyDownInt}
                    getErrorMessage={this.getErrorMessageBoolean}
                    getCustomErrorMessage={this.getCustomErrorMessage}
                    getHelperText={this.getHelperText}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    disableSubmitBtn={this.state.addFarmerLoading}
                    disableStateFarmer={false}
                    getStateData={this.state.getFarmerStateData}
                    getDistrictData={this.state.getFarmerDistrictData}
                    getTalukaData={this.state.getFarmerTalukaData}
                    getVillageData={this.state.getFarmerVillageData}
                    fetchDistrict={this.fetchFarmerFormDistrict}
                    fetchTaluka={this.fetchFarmerFormTaluka}
                    fetchVillage={this.fetchFarmerFormVillage}
                    getDistrictFarmerValue={this.getDistrictFarmerValue}
                    getTalukaFarmerValue={this.getTalukaFarmerValue}
                    getVillageFarmerValue={this.getVillageFarmerValue}
                    disableDistrictFarmerValue={this.disableDistrictFarmerValue}
                    disableTalukaFarmerValue={this.disableTalukaFarmerValue}
                    disableVillageFarmerValue={this.disableVillageFarmerValue}

                    getEducationFarmerData={this.state.getEducationFarmerData}
                    getNumberBelongsToData={this.state.getNumberBelongsToData}
                    getMobileDeviceModelFEData={this.state.getMobileDeviceModelFEData}
                    FElistData={this.state.FElistData}                    
                    selectedFEformData={this.state.selectedFEformData}
                    handleAssignFEchange={this.handleAssignFEchange}
                    assignFEPlaceholder={this.assignFEPlaceholder}                    
                  />
                ) : (
                  <></>
                )}
              </Box>
            </DialogContent>
          </Dialog>
          
          <UserTableFilter
            openTableFilter={this.state.openTableFilterPopup}
            anchorEl={this.state.anchorEl}
            HideFEFilter={true}
            handleCloseFilter={this.handleCloseTableFilterPopup}
            handleVillageFilterClear={this.handleVillageFilterClear}
            handleSubmitFilter={this.handleSubmitFilter}
            expanded={this.state.expanded}
            handleChangePanelState={this.handleChangePanel("panel1")}
            handleChangePanelDistrict={this.handleChangePanel("panel2")}
            handleChangePanelTaluka={this.handleChangePanel("panel3")}
            handleChangePanelVillage={this.handleChangePanel("panel4")}
            handleChangeAtoZ={this.handleChangeAtoZ}
            handleChangeZtoA={this.handleChangeZtoA}
            isDisabled={this.state.isDisabled}
            sortAscending={this.state.sortAscending}
            sortDescending={this.state.sortDescending}
            filteredStates={this.state.filteredStates}
            filteredDistricts={this.state.filteredDistricts}
            filteredTalukas={this.state.filteredTalukas}
            filteredVillages={this.state.filteredVillages}
            selectedState={this.state.selectedState}
            selectedDistrict={this.state.selectedDistrict}
            selectedTaluka={this.state.selectedTaluka}
            selectedVillage={this.state.selectedVillage}
            stateInputValue={this.state.stateInputValue}
            districtInputValue={this.state.districtInputValue}
            talukaInputValue={this.state.talukaInputValue}
            villageInputValue={this.state.villageInputValue}
            handleChangeStateField={this.handleChangeStateField}
            handleChangeDistrictField={this.handleChangeDistrictField}
            handleChangeTalukaField={this.handleChangeTalukaField}
            handleChangeVillageField={this.handleChangeVillageField}
            handleStateSearch={this.handleStateSearch}
            handleDistrictSearch={this.handleDistrictSearch}
            handleTalukaSearch={this.handleTalukaSearch}
            handleVillageSearch={this.handleVillageSearch}
            opacityStateCalculator={this.opacityStateCalculator}
            opacityDistrictCalculator={this.opacityDistrictCalculator}
            opacityTalukaCalculator={this.opacityTalukaCalculator}
            opacityVillageCalculator={this.opacityVillageCalculator}

            filterHasError={this.state.filterHasError}
          />

          {/* FILTER-ASSIGN VILLAGE STARTS */}
          <AssignVillageFilter
            data-testid={"assignVillageFilterViewAdmin"}
            isVillageFilterOpen={this.state.openVillageFilter}
            anchorElVillageFilter={this.state.anchorElVillageFilter}
            handleSubmitAccordion={() => this.handleSubmitAccordionVF()}
            handleVillageFilterClear={() => this.handleVillageFilterClearVF()}
            closeVillageFilter={() => this.closeVillageFilterVF()}
            isAccordianError={this.state.isAccordianErrorVF}
            isDisabled={this.state.isDisabledVF}
            expanded={this.state.expandedVF}
            handleChangePanelState={this.handleChangePanelVF("panel1")}
            handleChangePanelDistrict={this.handleChangePanelVF("panel2")}
            handleChangePanelTaluka={this.handleChangePanelVF("panel3")}
            handleChangePanelVillage={this.handleChangePanelVF("panel4")}
            opacityStateCalculator={this.opacityStateCalculatorVF}
            opacityDistrictCalculator={this.opacityDistrictCalculatorVF}
            opacityTalukaCalculator={this.opacityTalukaCalculatorVF}
            opacityVillageCalculator={this.opacityVillageCalculatorVF}
            handleChangeStateField={this.handleChangeStateFieldVF}
            handleChangeDistrictField={this.handleChangeDistrictFieldVF}
            handleChangeTalukaField={this.handleChangeTalukaFieldVF}
            handleChangeVillageField={this.handleChangeVillageFieldVF}
            selectedState={this.state.selectedStateVF}
            stateInputValue={this.state.stateInputValueVF}
            handleStateSearch={this.handleStateSearchVF}
            filteredStates={this.state.filteredStatesVF}
            selectedDistrict={this.state.selectedDistrictVF}
            districtInputValue={this.state.districtInputValueVF}
            handleDistrictSearch={this.handleDistrictSearchVF}
            filteredDistricts={this.state.filteredDistrictsVF}
            selectedTaluka={this.state.selectedTalukaVF}
            talukaInputValue={this.state.talukaInputValueVF}
            handleTalukaSearch={this.handleTalukaSearchVF}
            filteredTalukas={this.state.filteredTalukasVF}
            selectedVillage={this.state.selectedVillageVF}
            villageInputValue={this.state.villageInputValueVF}
            handleVillageSearch={this.handleVillageSearchVF}
            filteredVillages={this.state.filteredVillagesVF}
          />
          {/* FILTER-ASSIGN VILLAGE ENDS */}

          {/* DELETE MODAL FOR ADMIN*/}
          <CustomModal
            open={this.state.openAdminDeletePopup}
            title={configJSON.AdminConsoleDefaults.deleteUserTitle}
            description={configJSON.AdminConsoleDefaults.deleteUserDescription}
            confirmTitle={this.state.deleteAdminLoading ? "Deleting..." : "Delete"}
            confirmHandler={this.deleteAdmin}
            onClose={this.handleCloseDeletePopup}
            disableConfirmHandler={this.state.deleteAdminLoading}
          />
          {/* DELETE MODAL FOR FE USER*/}
          <CustomModal
            open={this.state.openFEDeletePopup}
            title={configJSON.AdminConsoleDefaults.deleteUserTitle}
            description={configJSON.AdminConsoleDefaults.deleteUserDescription}
            confirmTitle={this.state.deleteFELoading ? "Deleting..." : "Delete"}
            confirmHandler={this.deleteFE}
            onClose={this.handleCloseDeletePopup}
            disableConfirmHandler={this.state.deleteFELoading}
          />
          {/* ERROR-MODAL */}
          <ErrorModal
            open={this.state.errorPopupOpen}
            errorTitle="Error"
            errorSubTitle={this.state.errorPopupMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleCloseErrorPopup}
          />
          {/* SUCCESS-MODAL STARTS */}
          <ErrorModal
            open={this.state.successModalOpen}
            errorTitle="Success"
            errorSubTitle={this.state.successModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleCloseSuccessModal}
          />
          {/* SUCCESS-MODAL ENDS */}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}



// Customizable Area Start
const profileBlockStyles = {
  profileContainer: {
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    height: "100%",
    width: "100%",
    padding: "24px",
  } as React.CSSProperties,

  avatarContainer: {
    padding: "0.5rem",
    width: "7rem",
    objectFit: "cover",
    height: "6.5rem",
  } as React.CSSProperties,

  profileAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  avatarTypographyTitle: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    maxWidth: "100%",
    lineHeight: "30px",
    color: "#1C2324",
    wordWrap: "break-word",
  } as React.CSSProperties,
  profileIconButton: {
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    fontSize: "1rem",
  } as React.CSSProperties,

  profileAvatar: {
    width: "6rem",
    height: "6rem",
    backgroundColor: "#108B88",
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "3rem",
    lineHeight: "3.5rem",
    textTransform: "uppercase",
    color: "#FFFFFF",
  } as React.CSSProperties,

  profileLabelContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1.4rem",
    marginTop: "8px",
  } as React.CSSProperties,

  avatarTypographyContact: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: ".85rem",
    lineHeight: "22px",
    textAlign: "center",
  } as React.CSSProperties,

  profileLabel: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,

  labelSpan: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  } as React.CSSProperties,

  profileLabelValue: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "23px",
    color: "#1C2324",
  } as React.CSSProperties,

  functionButtonContainer: {
    display: "flex",
    gap: "1.5rem",
    justifyContent: "flex-end",
    marginTop: "1.5rem",
  } as React.CSSProperties,
};
// Customizable Area End

// Customizable Area Start
const adminViewStyle = {
  tableFilterWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "24px 12px 24px 24px",
  },
  tableWrapper: {
    background: "#F8F6F1",
    border: "2px",
    borderRadius: "12px",
    marginTop: "24px"
  },
  mainTable: {
    height: "583px",
  },
  maincard: {
    background: "#F8F6F1",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sideCard: {
    minWidth: "auto",
    minHeight: "78px",
    width: "100%"
  },
  expandMoreArrow: {
    colot: "#1C2324",
    fontSize: "14px",
  },
  photoCamera: {
    width: 60,
    height: 60,
    color: "#1C2324",
    opacity: 0.5,
  },
};
const userModalStyle = {
  addUserButton: {
    height:"56px",
    width: "185px",
    borderRadius: "28px",
    fontWeight: 700,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    background: "#DF5200",
    fontSize: "18px",
    color: "#fff",
    padding: "14px 32px",
    boxShadow: "none",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
}
// Customizable Area End

import React from "react";
import {
    Box,
    Table,
    TableBody,
    IconButton,
    Typography,
    TableRow,
    TableCell,
    TableHead,
    Tooltip
} from "@material-ui/core";
import { ICreatedSell } from "../interface.web";
import { dateDDMMYYYY, returnTruthyString } from "../helper";

interface IProps {
    data: ICreatedSell[];
    handleDelete: (id: string) => void;
    handleEdit: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined) => void;
    handleShareLink: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined, message: string) => void;
}
const SellActivityDetails = ({
    data = [],
    handleDelete,
    handleEdit,
    handleShareLink
}: IProps) => {
    // RENDER TABLE ROW
    const rendertableRow = (label: string, value: string | Date) => {
        return (
            <TableRow>
                <TableCell
                    style={{
                        ...styleWeb.tableCell,
                        ...styleWeb.opacity64,
                    }}
                >
                    {label}
                </TableCell>
                <TableCell style={styleWeb.tableCell}>{value}</TableCell>
            </TableRow>
        );
    };

    const renderTable = (data: ICreatedSell) => {
        const { id: itemId, attributes } = data ?? {};
        const { land_detail_id, crop_id,
            date_of_sell,
            quantity_of_crop_sold, crop_price, total_amount,
            distance_from_farmer_location_to_buyer,
            buyer_name,
            transportation_cost,
            labor_cost_of_load_and_unload,
            vehical_type_id, freeze_record,
            unit_of_quantity_of_crop_sold_id,
            unit_of_distance_location_id,
            default_unit_crop_info
        } = attributes ?? {}

        const farmId = returnTruthyString(land_detail_id && land_detail_id[0]?.id)
        const cropId = returnTruthyString(crop_id && crop_id?.[0]?.id)

        const farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)
        const crop_name = returnTruthyString(crop_id && crop_id?.[0]?.name)

        const date_of_sell_value = dateDDMMYYYY(date_of_sell)

        const quantity_of_crop_sold_unit_value = returnTruthyString(unit_of_quantity_of_crop_sold_id && unit_of_quantity_of_crop_sold_id[0]?.name)
        const quantity_of_crop_sold_value = String(quantity_of_crop_sold) + " " + quantity_of_crop_sold_unit_value

        const crop_price_heading = "Crop Price" + "/" + returnTruthyString(default_unit_crop_info && default_unit_crop_info[0]?.name)
        const crop_price_value = "₹ " + String(crop_price)
        const total_amount_value = "₹ " + String(total_amount) + "/-"

        const distance_unit_value = returnTruthyString(unit_of_distance_location_id && unit_of_distance_location_id[0]?.name)
        const distance_value = String(distance_from_farmer_location_to_buyer) + " " + distance_unit_value

        const buyer_name_value = returnTruthyString(buyer_name)
        const transportation_cost_value = "₹ " + String(transportation_cost) + "/-"
        const vehical_type_value = returnTruthyString(vehical_type_id && vehical_type_id[0]?.name)
        const labor_cost_value = "₹ " + String(labor_cost_of_load_and_unload) + "/-"

        const disable_crop = freeze_record
        return (
            <Box style={{ width: "100%" }} key={itemId}>
                <Table>
                    <TableHead>
                        <TableRow style={styleWeb.tableHead}>
                            <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name}</span> </TableCell>
                            <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rendertableRow("Date of Sell", date_of_sell_value)}
                        {rendertableRow("Quantity of Crop Sold", quantity_of_crop_sold_value)}
                        {rendertableRow(crop_price_heading, crop_price_value)}
                        {rendertableRow("Total Amount", total_amount_value)}
                        {rendertableRow("Distance from Farmer location to Buyer", distance_value)}
                        {rendertableRow("Buyer Name", buyer_name_value)}
                        {rendertableRow("Transportation Cost", transportation_cost_value)}
                        {rendertableRow("Vehicle Type", vehical_type_value)}
                        {rendertableRow("Labor cost of load & unload", labor_cost_value)}
                    </TableBody>
                </Table>
                <Box style={styleWeb.tableFooter}>
                    <Box style={styleWeb.iconsbox} onClick={()=> handleShareLink(String(itemId), farmId, cropId, "sells")}>
                        <IconButton style={styleWeb.iconbutton}>
                            <img
                                src={require("../share-icons.svg")}
                                alt="Share in chat"
                                style={{ width: "18px", height: "18px" }}
                            />
                        </IconButton>
                        <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
                    </Box>
                    <Box style={styleWeb.iconsbox}>
                        <Tooltip title={disable_crop ? "Activity Freezed" : ""}>
                            <Box>
                                <IconButton style={styleWeb.iconbutton}
                                    onClick={() => handleEdit(String(itemId), farmId, cropId)}
                                    disabled={disable_crop}
                                >
                                    <img
                                        src={require("../edit-icon.svg")}
                                        alt="Edit activity"
                                        style={styleWeb.actionIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={disable_crop ? "Activity Freezed" : ""}>
                            <Box>
                                <IconButton
                                    style={styleWeb.iconbutton}
                                    onClick={() => handleDelete(String(itemId))}
                                    disabled={disable_crop}
                                >
                                    <img
                                        src={require("../delete-icon.svg")}
                                        alt="Delete activity"
                                        style={styleWeb.actionIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <>
            {data?.length > 0 ? (
                data.map((item, index) => {
                    return renderTable(item);
                })
            ) : (
                <Box style={{ width: "100%" }}>
                    <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
                        No data found
                    </Typography>
                </Box>
            )}
        </>
    )
};

export default SellActivityDetails;

const styleWeb = {
    tableCell: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
        width: "50%"
    } as React.CSSProperties,
    shareInChat: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#006A77",
        lineHeight: "18px",
    } as React.CSSProperties,
    iconsbox: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        cursor: "pointer"
    } as React.CSSProperties,
    actionIcon: {
        width: "20px",
        height: "20px",
    } as React.CSSProperties,
    iconbutton: {
        padding: 0,
    } as React.CSSProperties,
    tableFooter: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 12px",
    } as React.CSSProperties,
    opacity64: {
        opacity: "64%",
    } as React.CSSProperties,
    tableHead: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
        width: "100%",
    } as React.CSSProperties,
    highlightedText: {
        fontWeight: 500,
    } as React.CSSProperties,
    headInfo: {
        fontWeight: 400,
        padding: "8px 12px",
        fontSize: "12px",
        color: "#1C2324",
        borderBottom: "none"
    } as React.CSSProperties,
};

// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDefaultLibraryFAQ, IDropdownDetailsData, IHeadings, IRequestBodyLibraryFAQ, IResponseDropdowns } from "../../../../components/src/interface.web";
import { DropdownKey, formateLibraryFAQBody, getTitleMessage, toLowerSnakeCase } from "../../../../components/src/helper";

export const configJSON = require("../config");

interface ISubDropDown {
    parentTitle: string,
    subDropdown: { heading: string, count?: string }
}
export interface Props {
    navigation: any;
    id: string;
    subDropdown?:ISubDropDown | null
}
interface S {
    authToken: string | null,
    currentExpanded: null | string,
    // API STATE
    headingsData: IHeadings[],
    dropdownDetailsData: IDropdownDetailsData[],
    // LOADING
    headingsDataLoading: boolean,
    dropdownDetailsDataLoading: boolean,
    saveLoading: boolean,
    // ALERT
    openAlertModal: boolean,
    titleAlertModal: string,
    descAlertModal: string,
}
interface SS { }
// Customizable Area End

export default class LibraryChapterController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    getDropdownDataApiCallId: string = "";
    updateApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            currentExpanded: null,
            // API STATE
            headingsData: [],
            dropdownDetailsData: [],
            // LOADING
            headingsDataLoading: false,
            dropdownDetailsDataLoading: false,
            saveLoading: false,
            // ALERT
            openAlertModal: false,
            titleAlertModal: "",
            descAlertModal: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.getHeadingsApiCallId) {
                    this.GetHeadingsApiCallIdResp(responseJson)
                }
                if (apiRequestCallId === this.getDropdownDataApiCallId) {
                    this.GetDropdownDataResp(responseJson)
                }
                if (apiRequestCallId === this.updateApiCallId) {
                    this.UpdateApiCallIdResp(responseJson)
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsApiCallIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                headingsDataLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                headingsDataLoading: false,
                headingsData: []
            })
        }
    }
    GetDropdownDataResp = (response: IDropdownDetailsData[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                dropdownDetailsData: response,
                dropdownDetailsDataLoading: false,
            })
        } else {
            this.setState({
                dropdownDetailsData: [],
                dropdownDetailsDataLoading: false,
            })
        }
    }
    UpdateApiCallIdResp = (responseData: IResponseDropdowns[] | null | undefined) => {

        this.setState({ saveLoading: false })
        if (!Array.isArray(responseData) || responseData.length === 0){ 
            this.openAlert(true, "Error", "Something went wrong!")
            return;
        }

        const hasMessageKey = responseData.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseData);
            this.openAlert(true, title, message)
            if (this.state.currentExpanded) {
                this.getDropdownData(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(responseData);
        }
    }
    

    successUpdate = (responseData: IResponseDropdowns[]) => {
        if (responseData && responseData.length) {
            this.openAlert(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.getDropdownData(this.state.currentExpanded)
            }
        } 
    }
    openAlert = (open: boolean, title: string, message: string) => {
        this.setState({
            openAlertModal: open,
            titleAlertModal: title,
            descAlertModal: message,
        })
    }
    closeAlert = () => {
        this.setState({
            openAlertModal: false,
            titleAlertModal: "",
            descAlertModal: "",
        })
    }
    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }
    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
    ): void {
        if ((prevProps.subDropdown !== this.props.subDropdown) && this.props.subDropdown) {
            this.resetStates()
            const { dropdownName, subDropdownName } = DropdownKey()
            // FETCH HEADING
            if (dropdownName && subDropdownName) {
                this.getDropdownHeadings(dropdownName, subDropdownName)
            }
        }
    }
    resetStates =() =>{
        this.setState({
            currentExpanded: null,
            dropdownDetailsData: [],
            dropdownDetailsDataLoading: false,
            headingsData: [],
            headingsDataLoading: false,
            saveLoading: false,
            openAlertModal: false,
            titleAlertModal: "",
            descAlertModal: "",
        })
    }

    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            headingsDataLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestApiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}`
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
    }
    getDropdownData = (title: string) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        if (dropdownName && subDropdownName && title) {
            this.setState({
                dropdownDetailsDataLoading: true,
                dropdownDetailsData: []
            })

            const header = { token: this.state.authToken };
            const requestApiMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDropdownDataApiCallId = requestApiMSG.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestApiMSG.id, requestApiMSG);
        } else {
            return
        }
    }
    updateDropdowns = (requestBody: ({ _destroy: string } | IRequestBodyLibraryFAQ)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                saveLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateApiCallId = requestapiMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestapiMsg.id, requestapiMsg);
        } else {
            return
        }
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDetailsDataLoading: true,
                dropdownDetailsData: []
            }, () => {
                this.getDropdownData(heading)
            })
        }
    };

    handleSubmit = (formData: IDefaultLibraryFAQ[]) => {
        const bodyData = formateLibraryFAQBody(formData)
        this.updateDropdowns(bodyData)
    }
    // Customizable Area End
}
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple1, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateSimpleBodyType1, formateUnitBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

interface IRequestBody {
    id?: string
    name: string
    name_english: string
    name_hindi: string
    name_gujrati: string
    active: boolean
}
export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    dropdownDetails: IDropdownDetailsData[],
    dropdownDetailsLoading: boolean,
    submitLoading: boolean
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class LocationsDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    getDetailsDataApiCallId: string = "";
    updateDetailsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            fetchHeadingsLoading: false,
            headingsData: [],
            currentExpanded: null,
            dropdownDetails: [],
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
            searchValue: "",
            dropdownDetailsLoading: false,
            submitLoading: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.getHeadingsApiCallId) {
                    this.GetHeadingsApiCallIdResp(responseJson)
                }
                if (apiRequestCallId === this.getDetailsDataApiCallId) {
                    this.GetDetailsDataResp(responseJson)
                }
                if (apiRequestCallId === this.updateDetailsApiCallId) {
                    this.UpdateDetailsApiCallIdResp(responseJson)
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsApiCallIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    async componentDidMount(): Promise<void> {
        const { dropdownName,
            subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }

    GetDetailsDataResp = (responseJson: IDropdownDetailsData[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetails: responseJson
            })
        } else {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetails: []
            })
        }
    }
    UpdateDetailsApiCallIdResp = (responseJson: IResponseDropdowns[] | null | undefined) => {

        this.setState({ submitLoading: false })
        if (!Array.isArray(responseJson) || responseJson.length === 0) {
            this.handleOpenMsgModal(true, "Error", "Something went wrong")
            return
        }
        const hasMessageKey = responseJson.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseJson);
            this.handleOpenMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(responseJson);
        }
    }


    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const reqApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = reqApiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        reqApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
    }

    fetchDropdownDetails = (title: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown && title) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetails: []
            })

            const header = { token: this.state.authToken };
            const reqApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsDataApiCallId = reqApiMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            reqApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            reqApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
        } else {
            return
        }
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple1 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const reqApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDetailsApiCallId = reqApiMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            reqApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            reqApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            reqApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(reqApiMsg.id, reqApiMsg);
        } else {
            return
        }
    }
    successUpdate = (responseJsonData: IResponseDropdowns[]) => {
        if (responseJsonData && responseJsonData.length) {
            this.handleOpenMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.handleOpenMsgModal(true, "Error", "Something went wrong!")
        }
    }

    handleCloseMsgModal = () => {
        this.setState({ openMsgModal: false, titleMsgModal: "", descMsgModal: "", })
    }
    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetails: []
            }, () => {
                this.fetchDropdownDetails(heading)

            })
        }
    };
    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType1(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
    }
    handleOpenMsgModal = (open: boolean, title: string, message: string) => {
        this.setState({ openMsgModal: open, titleMsgModal: title, descMsgModal: message, })
    }
    setSearchValue = (searchValue: string) => {
        const queryValue = trimStart(String(searchValue))
        this.setState({
            searchValue: queryValue, currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) {
                this.getDropdownHeadings(dropdownName, subDropdownName)
            }
        })
    }
    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }
    // Customizable Area End
}
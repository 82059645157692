// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IUnitMenu } from "../../../../components/src/interface.web";

export const configJSON = require("../config");
export interface Props {
    MenuList: IUnitMenu[],
    onSubmit: (data: IUnitMenu[]) => void,
    submitLoading: boolean,
    openUnitsDrodpowns: () => void
}
interface S {
    searchValue: string,
    menuArray: IUnitMenu[],
    filteredArray: IUnitMenu[]
}
interface SS { }
// Customizable Area End

export default class UnitComponentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            searchValue: "",
            filteredArray: this.props.MenuList,
            menuArray: this.props.MenuList
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
        }
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        this.setState({ filteredArray: [...this.props.MenuList], menuArray: [...this.props.MenuList] })
    }

    handleSearchValue = (searchQuery: string) => {
        const query = searchQuery?.toLowerCase().trim() || "";
        const filteredArray = query ? this.state.menuArray.filter((value) => value.name.toLowerCase().includes(query)) : this.state.menuArray
        this.setState({
            searchValue: query,
            filteredArray: filteredArray
        })
    }
    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, objId: string | number) => {
        const checked = event.target.checked;

        let updatedCheckboxes = [...this.state.menuArray];
        updatedCheckboxes = updatedCheckboxes.map(checkbox => checkbox.id === objId ? { ...checkbox, checked: checked } : checkbox);

        let filteredCheckboxes = [...this.state.filteredArray]
        filteredCheckboxes = filteredCheckboxes.map(checkbox => checkbox.id === objId ? { ...checkbox, checked: checked } : checkbox);


        this.setState({
            menuArray: updatedCheckboxes,
            filteredArray: filteredCheckboxes
        });
    };
    handleSubmit = () => {
        this.setState({
            searchValue: "",
            filteredArray: this.state.menuArray
        })
        this.props.onSubmit(this.state.menuArray)
    }
    // Customizable Area End
}
import React, { Fragment } from "react";
import {
  // Customizable Area Start
  Grid,
  Modal,
  Card,
  Box,
  Typography,
  IconButton,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Divider,
  TextField,
  Button,
  styled,
  FormControlLabel,
  InputAdornment,
  FormHelperText,
  CircularProgress,
  Input
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { NoMenuDataFound } from "../../../components/src/AdminConsoleHelper";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateRange, Add, KeyboardArrowDown } from "@material-ui/icons";
import { keyDown } from "../../../components/src/helper";
import { ICropDetails, ISelectFarm } from "../../../components/src/interface.web";
import SearchIcon from "@material-ui/icons/Search";
import WeedManagementController, { InitialFormValues, DropdownOptions } from "./WeedManagementController.web";
import { closeIconSvg, deleteIcon, weedManagementSvg, rupeeIconSVG } from "./assets";
import moment from "moment";
import CustomModal from "../../../components/src/customModal.web";

const inputpropSeedCost = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 0,
    max: 50000,
    step: 1,
  },
};
// Customizable Area End

export default class WeedManagement extends WeedManagementController {

  // Customizable Area Start
  renderButtonGroup = () => {
    return (
      <ButtonGrid container>
        <Button
          type="submit"
          data-testid="addBtn"
          style={{
            ...webStyle.cropSubmitButton,
            background: "#F0EBE0",
          }}
          onClick={this.handleFormAdd}
        >
          <Add style={{ marginRight: "8px", opacity: "64%" }} />
          <Typography
            style={{
              ...webStyle.cropSubmitButtonText,
              color: "#1C2324",
              opacity: "64%",
            }}
          >
            Add another Entry
          </Typography>
        </Button>

        <Button
          type="submit"
          style={{
            ...webStyle.cropSubmitButton,
            marginTop: "32px",
          }}
          data-testid="submit_button_testid"
          onClick={this.handleFormSubmit}
          disabled={this.state.loader}
        >
          <Typography style={webStyle.cropSubmitButtonText}>
            {this.state.loader ? (
              <CircularProgress
                size={28}
                style={{ color: "#fff" }}
              />
            ) : (
             this.props.isEditActivity ? "Update" : "Save"
            )}
          </Typography>
        </Button>
      </ButtonGrid>
    )
  }

  renderWeedingType = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions } = this.state;
    return (
      <Grid
        item
        xs={12}
        lg={12}
        className="text-field-container addCropDate"
      >
        <FormLabel className="phasesLabelCustom">
          Weeding Type
        </FormLabel>
        <FormControl fullWidth>
          <Select
            disabled={!!formValue?.id}
            style={webStyle.inputFieldStyle}
            className="selectDDCustom"
            data-testid="weedingType"
            variant="outlined"
            color="secondary"
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            name="select_weeding"
            value={formValue.weeding_type}
            error={!!formValue.errors.weeding_type}
            onChange={(
              event: React.ChangeEvent<{
                value: unknown, name?: string
              }>
            ) => {
              let newValue = event.target.value as string;
              this.handleWeedingType(
                {
                  target: {
                    name: "weeding_type",
                    value: newValue,
                  },
                }, index)
              this.setState({
                weedingType: event.target.value as string,
              })
            }}
          >
            <MenuItem value={""} disabled>
              <span className="opcity-48">Select Type</span>
            </MenuItem>
            {weedingTypeOptions.map((farm: DropdownOptions) => {
              return (
                <MenuItem value={farm.id} key={farm.id}>
                  {farm.name_english}
                </MenuItem>
              );
            })}
          </Select>
          {formValue.errors.weeding_type && (
            <FormHelperText style={webStyle.errorStyle}>
              {formValue.errors.weeding_type}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    )
  }

  renderWeedingDate = (formValue: InitialFormValues, index: number) => {
    return <Grid
      item
      xs={12}
      lg={12}
      className="text-field-container addCropDate"
    >
      <FormLabel className="phasesLabelCustom">
        Weeding Date
      </FormLabel>
      <FormControl fullWidth>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            clearable
            data-testid="weedingDate"
            className="customDatePicker custom-textbox"
            placeholder="DD/MM/YYYY"
            format="DD/MM/yyyy"
            inputVariant="outlined"
            color="secondary"
            keyboardIcon={
              <DateRange
                style={webStyle.customDateIcon}
              />
            }
            minDate={this.state.minimumDate}
            minDateMessage={'Please select a valid date'}
            maxDate={moment()}
            maxDateMessage={'Selected date cannot be a future date'}
            name="date"
            value={formValue.date}
            onChange={(newDate) =>
              this.handleInputChange(
                {
                  target: {
                    name: "date",
                    value: newDate,
                  },
                },
                index
              )
            }
            error={!!formValue.errors.date}
            helperText={formValue.errors.date}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </Grid>
  }

  renderMachineCost = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions } = this.state;
    let weedingType = formValue.weeding_type;
    const byMachineId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by machine")?.id
    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by weedicide" && option.name_english.toLowerCase() !== "by bullock drawn" && option.name_english.toLowerCase() !== "by labor").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));
    return (isWeedicideFieldRequired || weedingType === "") && <Grid
      item
      xs={12}
      lg={6}
      className="text-field-container"
    >
      <FormLabel className="phasesLabelCustom">
        Machine Cost {(weedingType != byMachineId) && <span> - Optional </span>}
      </FormLabel>
      <FormControl>
        <TextField
          className="inputIconTextFieldCustom custom-textbox"
          type="number"
          variant="outlined"
          color="secondary"
          placeholder="Total Cost"
          data-testid="machineCost"
          InputProps={inputpropSeedCost}
          onKeyDown={keyDown}
          name="machine_cost"
          value={formValue.machine_cost}
          onChange={(e) =>
            this.handleInputChange(e, index, weedingType != byMachineId)
          }
          error={!!formValue.errors.machine_cost}
          helperText={formValue.errors.machine_cost}
        />
      </FormControl>
    </Grid>
  }

  renderFuelCost = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions, unitOfFuelConsumptionList } = this.state;

    let weedingType = formValue.weeding_type;
    const byMachineId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by machine")?.id

    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by weedicide" && option.name_english.toLowerCase() !== "by bullock drawn" && option.name_english.toLowerCase() !== "by labor").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));
    return (isWeedicideFieldRequired || weedingType === "") && <Grid item xs={12} lg={6} className="text-field-container">
      <FormLabel className="phasesLabelCustom">Fuel Consumption {(weedingType != byMachineId) && <span> - Optional </span>}</FormLabel>
      <FormControl fullWidth>
        <TextField
          className="textfield-with-select"
          fullWidth
          data-testid="fuelValue"
          placeholder="Enter Fuel"
          type="number"
          variant="outlined"
          onKeyDown={keyDown}
          color="secondary"
          name="fuel_value"
          value={formValue.fuel_value}
          onChange={(e) =>
            this.handleInputChange(e, index, weedingType != byMachineId)
          }
          error={!!formValue.errors.fuel_value || !!formValue.errors.quantity_of_fuel_unit}
          helperText={formValue.errors.fuel_value != "" ? formValue.errors.fuel_value : formValue.errors.quantity_of_fuel_unit}
          InputProps={{
            inputProps: { min: 0, type: 'number' },
            endAdornment: unitOfFuelConsumptionList.length === 1 || !unitOfFuelConsumptionList.length ?
              <CustomUnitLabel>
                <Typography noWrap>
                  {this.getLabelFromId(formValue.fuel_value, unitOfFuelConsumptionList)}
                </Typography>
              </CustomUnitLabel>
              :
              (
                <SelectWithInput
                  className="select-with-input"
                  error={!!formValue.errors.quantity_of_fuel_unit}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  value={formValue.quantity_of_fuel_unit}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: unknown, name?: string
                    }>
                  ) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "quantity_of_fuel_unit",
                          value: event.target.value as string,
                        },
                      }, index, weedingType != byMachineId)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {
                    unitOfFuelConsumptionList.map((unit, index) => {

                      return (
                        <MenuItem
                          key={unit.id}
                          value={unit.id}
                        >
                          {unit.attributes.unit_name}
                        </MenuItem>
                      )
                    })
                  }
                </SelectWithInput>
              ),
          }}
        />
      </FormControl>
    </Grid>
  }

  renderWeedicideName = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions, weedicideNamesList, searchWeedicideName, filteredWeedicideNamesList } = this.state;
    let weedingType = formValue.weeding_type;
    const byWeedicideId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by weedicide")?.id

    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by machine" && option.name_english.toLowerCase() !== "by labor" && option.name_english.toLowerCase() !== "by bullock drawn").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));

    return (isWeedicideFieldRequired || weedingType === "") && <Grid item xs={12} lg={12} className="text-field-container">
      <FormLabel className="phasesLabelCustom">Weedicide Name{(weedingType != byWeedicideId) && <span> - Optional </span>}</FormLabel>
      <FormControl fullWidth>
        <Select
          className="selectDDCustom"
          data-testid="weedicideName"
          variant="outlined"
          color="secondary"
          style={webStyle.inputFieldStyle}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
          name="weedicide_name"
          renderValue={(value) => {
            if (formValue.weedicide_name) {
              return weedicideNamesList.find((item) => item.id.toString() == formValue.weedicide_name)?.name_english
            } else if (!formValue.weedicide_name) {
              return (
                <span className="opcity-48">Select Weedicide Name</span>
              );
            }
          }}
          onClose={this.resetSearchNuturientData}
          error={!!formValue.errors.weedicide_name}
        >
          <MenuItem value={""} disabled>
            <span className="opcity-48">Select Weedicide Name</span>
          </MenuItem>

          <SearchBoxWrapper>
            <Input
              fullWidth
              disableUnderline
              autoFocus
              className="custom_search_input"
              data-testid="searchWeedicideName"
              type="text"
              placeholder="Search by name"
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon
                    style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              name="search_nutrient_name"
              value={searchWeedicideName}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                this.handleFilteredWeedicideNamesList(event.target.value)
              }}
            />
          </SearchBoxWrapper>
          <Box>
            {filteredWeedicideNamesList.length > 0 ?
              filteredWeedicideNamesList.map((each, ind) => (
                <MenuItem
                  key={each.id}
                  value={each.id}
                  data-testid={`nutrient_name_menu_${ind}`}
                  onClick={(event) => {
                    this.handleInputChange({
                      target: {
                        name: "weedicide_name",
                        value: each.id,
                      },
                    }, index, weedingType != byWeedicideId)
                  }}
                  selected={each.id.toString() == formValue.weedicide_name}
                >
                  {each.name_english}
                </MenuItem>
              ))
              : NoMenuDataFound()
            }
          </Box>
        </Select>
        {formValue.errors.weedicide_name && (
          <FormHelperText style={webStyle.errorStyle}>
            {formValue.errors.weedicide_name}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  }

  renderWeedicideQuantity = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions, unitOfWeedicideQuantityList } = this.state;
    let weedingType = formValue.weeding_type;
    const byWeedicideId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by weedicide")?.id;
    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by machine" && option.name_english.toLowerCase() !== "by labor" && option.name_english.toLowerCase() !== "by bullock drawn").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));

    return (isWeedicideFieldRequired || weedingType === "") &&
      <Grid item xs={12} lg={6} className="text-field-container">
        <FormLabel className="phasesLabelCustom">Weedicide Quantity{(weedingType != byWeedicideId) && <span> - Optional </span>}</FormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            fullWidth
            data-testid="weedicideQuantity"
            placeholder="Enter Quantity"
            type="number"
            onKeyDown={keyDown}
            variant="outlined"
            color="secondary"
            name="weedicide_quantity_value"
            value={formValue.weedicide_quantity_value}
            onChange={(e) =>
              this.handleInputChange(e, index, weedingType != byWeedicideId)
            }
            error={!!formValue.errors.weedicide_quantity_value || !!formValue.errors.quantity_of_weedicide_unit}
            helperText={formValue.errors.weedicide_quantity_value != "" ? formValue.errors.weedicide_quantity_value : formValue.errors.quantity_of_weedicide_unit}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfWeedicideQuantityList.length === 1 || !unitOfWeedicideQuantityList.length ?
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(formValue.weedicide_quantity_value, unitOfWeedicideQuantityList)}
                  </Typography>
                </CustomUnitLabel>
                :
                (
                  <SelectWithInput
                    className="select-with-input"
                    error={!!formValue.errors.quantity_of_weedicide_unit}
                    value={formValue.quantity_of_weedicide_unit}
                    IconComponent={KeyboardArrowDown}
                    data-testid="nutrient_quantity_unit_testid"
                    onChange={(
                      event: React.ChangeEvent<{
                        value: unknown, name?: string
                      }>
                    ) => {
                      this.handleInputChange(
                        {
                          target: {
                            name: "quantity_of_weedicide_unit",
                            value: event.target.value as string,
                          },
                        }, index, weedingType != byWeedicideId)
                    }}
                  >
                    <MenuItem value={""} disabled>
                      <span className="opcity-48"><em>None</em></span>
                    </MenuItem>
                    {
                      unitOfWeedicideQuantityList.map((unit, index) => {
                        return (
                          <MenuItem
                            key={unit.id}
                            value={unit.id}
                          >
                            {unit.attributes.unit_name}
                          </MenuItem>
                        )
                      })
                    }
                  </SelectWithInput>
                ),
            }}
          />
        </FormControl>
      </Grid>
  }

  renderBullockCost = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions } = this.state;
    let weedingType = formValue.weeding_type;
    const byBullockId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by bullock drawn")?.id

    return ((weedingType == byBullockId) && <Grid
      item
      xs={12}
      lg={6}
      className="text-field-container"
    >
      <FormLabel className="phasesLabelCustom">
        Bullock Drawn Cost
      </FormLabel>
      <FormControl>
        <TextField
          className="inputIconTextFieldCustom custom-textbox"
          type="number"
          variant="outlined"
          color="secondary"
          placeholder="Total Cost"
          data-testid="bullockCost"
          InputProps={inputpropSeedCost}
          onKeyDown={keyDown}
          name="bullock_cost"
          value={formValue.bullock_cost}
          onChange={(e) =>
            this.handleInputChange(e, index)
          }
          error={!!formValue.errors.bullock_cost}
          helperText={formValue.errors.bullock_cost}
        />
      </FormControl>
    </Grid>)
  }

  renderLaborCost = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions } = this.state;
    let weedingType = formValue.weeding_type;
    const byLaborId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by labor")?.id
    const byBullockId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by bullock drawn")?.id
    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by machine" && option.name_english.toLowerCase() !== "by weedicide").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));

    return (isWeedicideFieldRequired || weedingType === "") && <Grid
      item
      xs={12}
      lg={6}
      className="text-field-container"
    >
      <FormLabel className="phasesLabelCustom">
        Labor Cost {(weedingType != byLaborId && weedingType != byBullockId) && <span> - Optional </span>}
      </FormLabel>
      <FormControl>
        <TextField
          className="inputIconTextFieldCustom custom-textbox"
          type="number"
          variant="outlined"
          color="secondary"
          placeholder="Total Cost"
          data-testid="laborCost"
          InputProps={inputpropSeedCost}
          onKeyDown={keyDown}
          name="labor_cost"
          value={formValue.labor_cost}
          onChange={(e) =>
            this.handleInputChange(e, index, weedingType != byLaborId && weedingType != byBullockId)
          }
          error={!!formValue.errors.labor_cost}
          helperText={formValue.errors.labor_cost}
        />
      </FormControl>
    </Grid>
  }

  renderWeedicideCost = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions } = this.state;
    let weedingType = formValue.weeding_type;
    const byWeedicideId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by weedicide")?.id
    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by machine" && option.name_english.toLowerCase() !== "by bullock drawn" && option.name_english.toLowerCase() !== "by labor").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));
    return (isWeedicideFieldRequired || weedingType === "") && <Grid
      item
      xs={12}
      lg={6}
      className="text-field-container"
    >
      <FormLabel className="phasesLabelCustom">
        Cost of Weedicide {(weedingType != byWeedicideId) && <span> - Optional </span>}
      </FormLabel>
      <FormControl>
        <TextField
          className="inputIconTextFieldCustom custom-textbox"
          type="number"
          variant="outlined"
          color="secondary"
          placeholder="Total Cost"
          data-testid="weedicideCost"
          InputProps={inputpropSeedCost}
          onKeyDown={keyDown}
          name="weedicide_cost"
          value={formValue.weedicide_cost}
          onChange={(e) =>
            this.handleInputChange(e, index, weedingType != byWeedicideId)
          }
          error={!!formValue.errors.weedicide_cost}
          helperText={formValue.errors.weedicide_cost}
        />
      </FormControl>
    </Grid>
  }

  renderSprayingCost = (formValue: InitialFormValues, index: number) => {
    const { weedingTypeOptions } = this.state;
    let weedingType = formValue.weeding_type;
    const byWeedicideId = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by weedicide")?.id
    const weedicideRequiredIds = weedingTypeOptions.filter((option) => option.name_english.toLowerCase() !== "by machine" && option.name_english.toLowerCase() !== "by bullock drawn" && option.name_english.toLowerCase() !== "by labor").map((option) => option.id);
    const isWeedicideFieldRequired = weedicideRequiredIds.includes(Number(weedingType));


    return (isWeedicideFieldRequired || weedingType === "") && <Grid
      item
      xs={12}
      lg={6}
      className="text-field-container"
    >
      <FormLabel className="phasesLabelCustom">
        Spraying Labor Cost {(weedingType != byWeedicideId) && <span> - Optional </span>}
      </FormLabel>
      <FormControl>
        <TextField
          className="inputIconTextFieldCustom custom-textbox"
          type="number"
          variant="outlined"
          color="secondary"
          placeholder="Total Cost"
          data-testid="sprayCost"
          InputProps={inputpropSeedCost}
          onKeyDown={keyDown}
          name="spraying_cost"
          value={formValue.spraying_cost}
          onChange={(e) =>
            this.handleInputChange(e, index, weedingType != byWeedicideId)
          }
          error={!!formValue.errors.spraying_cost}
          helperText={formValue.errors.spraying_cost}
        />
      </FormControl>
    </Grid>
  }

  innerForm = () => {
    const { weedManagementFormValues } = this.state;
    return weedManagementFormValues.map(
      (formValue: InitialFormValues, index: number) => {
        return (
          <Fragment key={index}>
            <ResponsiveGrid
              container
              spacing={2}
              style={{ position: "relative" }}
            >
              {/* Weeding Type */}
              {this.renderWeedingType(formValue, index)}

              {/* Weeding Date */}
              {this.renderWeedingDate(formValue, index)}

              {/* Machine Cost */}
              {this.renderMachineCost(formValue, index)}

              {/* Fuel Cost */}
              {this.renderFuelCost(formValue, index)}

              {/* Weedicide Name */}
              {this.renderWeedicideName(formValue, index)}

              {/* Weedicide Quantity */}
              {this.renderWeedicideQuantity(formValue, index)}

              {/* Labor Cost */}
              {this.renderBullockCost(formValue, index)}

              {/* Labor Cost */}
              {this.renderLaborCost(formValue, index)}

              {/* Weedicide Cost */}
              {this.renderWeedicideCost(formValue, index)}

              {/* Sparying Cost */}
              {this.renderSprayingCost(formValue, index)}

              {/* Delete Button */}
              {weedManagementFormValues.length > 1 && (
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 8,
                    visibility:!!formValue?.id ? "hidden" : "visible"
                  }}
                >
                  <IconButton
                    style={webStyle.iconbutton}
                    data-testid="deleteIcon"
                    onClick={() =>
                      this.handleFormRemove(index)
                    }
                    disabled={!!formValue?.id}
                  >
                    <img
                      src={deleteIcon}
                      alt="Delete activity"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </IconButton>
                </Box>
              )}
            </ResponsiveGrid>
            <Divider
              component={"hr"}
              style={{
                background: "#F0EBE0",
                width: "100%",
                height: "2px",
                marginTop: "8px",
              }}
            />
          </Fragment>
        );
      }
    )
  }

  weedManagementActivity = () => {
    return (
      <Grid item>
        <Modal
          open={this.props.isWeedManagementModal}
          data-testid="close_modal_button"
          style={webStyle.secondaryModalBox}
          BackdropProps={{
            style: webStyle.modalBackdropProps,
          }}
          className="addCropModal"
        >
          <Card variant="outlined" style={webStyle.AddCardBoxSuccess}>
            <Box style={webStyle.boxContainer}>
              <CustomAddCropBox>
                <Box style={webStyle.cropHeader}>
                  <Box style={webStyle.headerLeft}>
                    <img
                      src={weedManagementSvg}
                      alt="Crop"
                      style={webStyle.headerImage}
                    />
                    <Typography style={webStyle.headerTitle}>
                      Weed management
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={this.props.handleClose}
                    >
                      <img
                        src={closeIconSvg}
                        alt="closeIcon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <form
                  data-testid="from-submit-testid"
                  noValidate
                  autoComplete="off"
                  className="customFormSpacing"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGridWrapper container>
                    <ResponsiveGrid container spacing={2}>
                      <Grid item xs={12} lg={6} className="text-field-container">
                        <FormLabel className="phasesLabelCustom">Select Farm</FormLabel>
                        <FormControl fullWidth>
                          <Select
                            style={webStyle.inputFieldStyle}
                            className="selectDDCustom"
                            data-testid="selectFarm"
                            variant="outlined"
                            color="secondary"
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            disabled={this.props.isEditActivity}
                            name="select_farm"
                            renderValue={(value) => {
                              if (this.state.formLandDetailId) {
                                return this.state.farmNamesList.find((item) => item.attributes.id == this.state.formLandDetailId)?.attributes?.name
                              } else if (!this.state.formLandDetailId) {
                                return (
                                  <span className="opcity-48">Select Farm</span>
                                );
                              }
                            }}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              this.handleFarmSelect( event.target.value as string)
                              
                            }}
                            value={this.state.formLandDetailId}
                            error={!!this.state.formLandDetailIdError}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select Farm</span>
                            </MenuItem>
                            {this.state.farmNamesList.map((item: ISelectFarm) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={item.attributes.id}
                                  key={item.id}
                                >
                                  <RadioGroup
                                    value={this.state.formLandDetailId}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomFormControlLabel
                                      value={item.attributes.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography style={webStyle.cropDropDownLable}>
                                              {item.attributes.name}
                                            </Typography>
                                          </Box>
                                          <Typography style={webStyle.cropUnitText}>
                                            {item.attributes.farm_area}{" "}{item.attributes?.unit_farm_area_id?.name}
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              style={{
                                ...webStyle.shareInChat,
                                marginLeft: "16px",
                                color: "#006A77",
                                marginTop: "8px",
                              }}
                              onClick={this.handleAddFarm}
                              startIcon={
                                <Add
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                            >
                              Add Farm
                            </CustomButton>
                          </Select>
                          {this.state.formLandDetailIdError && (
                            <FormHelperText style={webStyle.errorStyle}>
                              {this.state.formLandDetailIdError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className="text-field-container"
                      >
                        <FormLabel className="phasesLabelCustom">
                          Select Crop
                        </FormLabel>
                        <FormControl fullWidth>
                          <Select
                            style={webStyle.inputFieldStyle}
                            className="selectDDCustom"
                            data-testid="selectCrop"
                            variant="outlined"
                            color="secondary"
                            disabled={this.props.isEditActivity || !this.state.formLandDetailId}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            name="select_farm"
                            renderValue={(value) => {
                              if (!this.state.formCropId) {
                                return (
                                  <span className="opcity-48">Select Crop</span>
                                );
                              }
                              if (this.state.formCropId) {
                                return this.state.cropNameList.find(
                                  (item) => item.attributes.id == this.state.formCropId
                                )?.attributes?.crop_name_id?.name;
                              }
                            }}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) => 
                              this.handleCropSelection(event.target.value as string)
                            }
                            value={this.state.formCropId}
                            error={!!this.state.formCropIdError}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select crop</span>
                            </MenuItem>
                            {this.state.cropNameList.map((item: ICropDetails) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={item.attributes.id}
                                  key={item.id}
                                >
                                  <RadioGroup
                                    value={this.state.formCropId}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomFormControlLabel
                                      value={item.attributes.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              style={webStyle.cropDropDownLable}
                                            >
                                              {
                                                item.attributes?.crop_name_id
                                                  ?.name
                                              }{" "}
                                              |{" "}
                                              <span
                                                style={{ color: "#108B88" }}
                                              >
                                                {
                                                  item.attributes?.crop_type_id
                                                    ?.name_english
                                                }
                                              </span>
                                            </Typography>
                                          </Box>
                                          <Typography
                                            style={webStyle.cropUnitText}
                                          >
                                            {item.attributes?.total_crop_area}{" "}
                                            {
                                              item.attributes?.unit_of_area_id
                                                ?.name
                                            }
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              style={{
                                ...webStyle.shareInChat,
                                marginLeft: "16px",
                                color: "#006A77",
                                marginTop: "8px",
                              }}
                              onClick={this.handleAddCrop}
                              startIcon={
                                <Add
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                            >
                              Add Crop
                            </CustomButton>
                          </Select>
                          {this.state.formCropIdError && (
                            <FormHelperText style={webStyle.errorStyle}>
                              {this.state.formCropIdError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </ResponsiveGrid>
                    <Grid container>
                      <Divider
                        component={"hr"}
                        style={{
                          background: "#F0EBE0",
                          width: "100%",
                          height: "2px",
                          marginTop: "8px",
                        }}
                      />
                    </Grid>
                    {this.innerForm()}
                    {this.renderButtonGroup()}
                  </FormGridWrapper>
                </form>
              </CustomAddCropBox>
            </Box>
          </Card>
        </Modal>
      </Grid>
    );
  };

  render() {
    return (
      <>
        {this.weedManagementActivity()}
        {this.state.customMessage && (
          <CustomModal
            open={!!this.state.customMessage}
            onClose={this.handleCloseCustomModal}
            title={this.state.customMessageTitle}
            description={this.state.customMessage}
          />
        )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const ButtonGrid = styled(Grid)({
  margin: "32px",
  marginTop: "8px",
  "@media (max-width:600px)": {
    margin: "12px 24px 32px",
  },
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
});

const CustomButton = styled(Button)({
  "& .MuiButton-startIcon": {
    marginRight: "2px ",
  },
});

const ResponsiveGrid = styled(Grid)({
  marginInline: "24px",
  "@media (max-width:600px)": {
    marginInline: "16px 24px",
  },
});

const CustomAddCropBox = styled(Box)({});

const FormGridWrapper = styled(Grid)({
  rowGap: "16px",
  "& .phasesLabelCustom": {
    "& span": {
      fontSize: "12px"
    }
  }
});

const webStyle = {
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  } as React.CSSProperties,
  activityLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
    marginTop: "4px",
    color: "#1C2324",
  } as React.CSSProperties,
  AddCardBoxSuccess: {
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    maxWidth: "712px",
    flexDirection: "column",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  boxContainer: {
    fontSize: "16px",
    height: "100%",
    overflowY: "auto",
  } as React.CSSProperties,
  cropHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "32px",
    marginBottom: "16px",
  } as React.CSSProperties,
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  inputFieldStyle: {
    height: "56px",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  cropSubmitButton: {
    borderRadius: "28px",
    background: "#DF5200",
    width: "100%",
    paddingBlock: "14px",
  } as React.CSSProperties,
  cropSubmitButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
  addActivityModalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as React.CSSProperties,
  closeIcon: {
    width: "24px",
    height: "24px",
  } as React.CSSProperties,
  activityItem: {
    width: "142px",
    height: "142px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0EBE0",
    paddingInline: "10px",
    cursor: "pointer",
  } as React.CSSProperties,
  activityImage: {
    width: "56px",
    height: "56px",
  } as React.CSSProperties,
  cropDropDownLable: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  cropUnitText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontStyle: "italic",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  },
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  errorStyle: {
    fontSize: "0.75rem",
    color: "#f44336",
  } as React.CSSProperties,
};

const SelectWithInput = styled(Select)({
  '&.select-with-input': {
    height: "28px !important"
  },
  '& .MuiInputBase-formControl': {
    height: "auto"
  },
  '& .MuiSelect-icon': {
    color: "#aaaca4"
  },
  '&.select-with-input .MuiSelect-iconOutlined': {
    right: "0 !important"
  },
  '&.select-with-input .MuiSelect-root': {
    width: "83px",
    height: "28px",
    padding: "5px 12px",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "left",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
    color: "#94949b"
  }
})

const CustomUnitLabel = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "83px",
  height: "28px",
  padding: "0px 12px",
  boxSizing: "border-box",
  lineHeight: "2px",
  textAlign: "center",
  fontSize: "14px",
  borderRadius: "8px",
  background: "#F0EBE0",
  color: "#94949b"
})

const SearchBoxWrapper = styled(Box)({
  margin: "8px 16px",
  background: "#FCFAF7",
  borderRadius: "28px",
  border: "2px solid #F0EBE0",
  '& .custom_search_input>.MuiInputAdornment-positionStart': {
    margin: "0 6px 0 12px !important"
  }
})
// Customizable Area End

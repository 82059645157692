import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  Tooltip as TooltipMui
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, Cell } from "recharts";
import { ICropDetailsProfile, IUserFarmFarmerProfile } from "../../../components/src/interface.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { allSvg } from "./assets"
// Customizable Area End

import Cfmultiplecropspart2Controller, {
  Props,
  configJSON,
} from "./Cfmultiplecropspart2Controller";

export default class Cfmultiplecropspart2 extends Cfmultiplecropspart2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  formatYAxisLabel = (value: number): string => {
    const absValue = Math.abs(value);
    if (absValue >= 1000000) {
      return `${(absValue / 1000000).toFixed(1)}M`;
    }
    return value.toString();
  };

  returnImageStyle = (farmId: string) => {
    const { selectedFarmIds } = this.state;
    if (selectedFarmIds.includes(farmId)) {
      return { border: "1.5px solid #DF5200", ...styleWeb.cardImage };
    }
    return styleWeb.cardImage;
  };

  renderUserFarm = (farmData: IUserFarmFarmerProfile, i: number) => {
    let { name, farm_image } = farmData.attributes;
    return (
      <Box style={styleWeb.cardContainer}>
        <Box style={styleWeb.cardImageContainer}>
          {name === "All" ? (
            <img
              alt="Farm Image"
              src={allSvg}
              data-test-id="select-farm-button"
              onClick={() => this.handleSelectFarms(farmData.id)}
              style={{ ...this.returnImageStyle(farmData.id), padding: "10px" }}
            />
          ) : (
            <img
              alt="Farm Image"
              src={farm_image?.url || configJSON.imagePlaceholder}
              data-test-id="select-farm-button"
              onClick={() => this.handleSelectFarms(farmData.id)}
              style={this.returnImageStyle(farmData.id)}
            />
          )}
        </Box>
        <Typography title={name} style={styleWeb.cardTitle}>{name}</Typography>
      </Box>
    );
  };

  renderUserFarms = () => {
    const { userFarmsLoading, userFarmsData } = this.state;
    if (userFarmsLoading) {
      return (
        <Box style={styleWeb.loaderWrap}>
          <CircularProgress size={32} style={{ color: "#DF5200" }} />
        </Box>
      );
    }
    if (userFarmsData.length === 0) {
      return (
        <Box className="farm-body-box-wrapper">
          <Typography
            style={styleWeb.centerText}
            className="farm-body-sub-text"
          >
            No farm available
          </Typography>
        </Box>
      );
    }
    return (
      <>
        <Box style={styleWeb.cardsContainer}>
          <Grid container spacing={1}>
            {userFarmsData.map((farm: IUserFarmFarmerProfile, i: number) => {
              return (
                <Grid key={i} xs={4} sm={4} md={3} item>
                  {this.renderUserFarm(farm, i)}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </>
    );
  };

  renderCrop = (cropData: ICropDetailsProfile, cropIndex: number) => {
    const { selectedCropsId } = this.state;
    const { flag, crop_ended } = cropData.attributes;
    const { name, crop_photo } = cropData.attributes.crop_name_id ?? {};
    return name === "All" ? (
      <Box style={styleWeb.cardContainer}>
        <Box
          style={{ ...styleWeb.cropImageContainer, cursor: "pointer" }}
          data-test-id="select-crop-button"
        >
          <img
            alt="crop"
            src={allSvg}
            data-test-id={`crop-image-testid-${cropIndex}`}
            onClick={() => this.handleSelectCrops(cropData.id)}
            style={{ ...styleWeb.cropCardImage, padding: "10px", border: selectedCropsId.includes(cropData.id) ? "1.5px solid #DF5200" : "1.5px solid #F0EBE0"}}
          />
        </Box>
        <TooltipMui title={name}><Typography style={styleWeb.cardTitle}>{name}</Typography></TooltipMui>
      </Box>
    ) : (
      <Box style={{ ...styleWeb.cardContainer, opacity: crop_ended ? 0.5 : 1 }}>
        <Box
          style={{ ...styleWeb.cropImageContainer, cursor: flag ? "pointer" : "not-allowed"}}
          data-test-id="select-crop-button"
        >
          <img
            alt="crop"
            src={crop_photo?.url || configJSON.imagePlaceholder}
            data-test-id={`crop-image-testid-${cropIndex}`}
            onClick={() => (flag ? this.handleSelectCrops(cropData.id) : {})}
            style={{ ...styleWeb.cropCardImage, filter: crop_ended ? "grayscale(1)" : "grayscale(0)", border: selectedCropsId.includes(cropData.id) ? "1.5px solid #DF5200" : "1.5px solid #F0EBE0" }}
          />
        </Box>
        <TooltipMui title={name}><Typography style={styleWeb.cardTitle}>{name}</Typography></TooltipMui>
      </Box>
    );
  };
  
  renderCropsSection = () => {
    const { userCropsData, userCropsDataLoading } = this.state;

    if (userCropsDataLoading) {
      return (
        <Box style={styleWeb.loaderWrap}>
          <CircularProgress size={32} style={{ color: "#DF5200" }} />
        </Box>
      );
    }
    if (userCropsData.length === 0 && !userCropsDataLoading) {
      return (
        <>
          <Box className="farm-body-box-wrapper">
            <Typography
              style={styleWeb.centerText}
              className="farm-body-sub-text"
            >
              No crop available
            </Typography>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box style={styleWeb.cardsContainer}>
          <Grid container spacing={1}>
            {userCropsData.map(
              (crop: ICropDetailsProfile, cropIndex: number) => {
                return (
                  <Grid key={cropIndex} xs={4} sm={4} md={3} item>
                    {this.renderCrop(crop, cropIndex)}
                  </Grid>
                );
              }
            )}
          </Grid>
        </Box>
      </>
    );
  };

  currentYear = new Date().getFullYear();
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Grid container item xs={12} className="farmerViewRightSectionBoxes">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="leftBlock">
              <Box className="cardLabel">Total number of crops</Box>
              <Typography noWrap className="cardLabelValue">
                {this.state.numOfCrops}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="leftBlock">
              <Box className="cardLabel">Total farm area</Box>
              <Typography noWrap className="cardLabelValue">
                {this.state.farmArea} ACRES
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="leftBlock">
              <Box className="cardLabel">Last cotton sowing date</Box>
              <Typography noWrap className="cardLabelValue">
                {this.state.sowingDate.length === 0 ? "-" : this.state.sowingDate}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="leftBlock">
              <Box className="cardLabel">Total active crop area</Box>
              <Typography noWrap className="cardLabelValue">
                {this.state.cropArea} ACRES
              </Typography>
            </Box>
          </Grid>
        </Grid>
      <ChartContainer>
        <Box className="userFarmDiaryDetailMain" style={styleWeb.parentDiv}>
          <Box style={styleWeb.topHeader}>
            <Box style={styleWeb.usernameTitleWrapper}>Farm Diary summary</Box>
            <Box style={styleWeb.farmDiaryUpdateBtnWrapper} onClick={this.handleRedirection}>
              View Entries <ArrowForwardIcon className="arrowSvg" />
            </Box>
          </Box>
          <Box>
            <Paper square>
              <Tabs
                variant="fullWidth"
                data-testid="year-tab-testid"
                value={this.state.currentSelectedYear}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChangeCurrentYear}
                aria-label="disabled tabs example"
                className="custom-year-tab-wrapper"
                style={styleWeb.yearTabs}
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                <Tab
                  label={`${this.currentYear - 3}-${this.currentYear - 2}`}
                  style={styleWeb.tabHeight}
                  value={this.currentYear - 3}
                  className="custom-year-tab"
                />
                <Tab
                  label={`${this.currentYear - 2}-${this.currentYear - 1}`}
                  style={styleWeb.tabHeight}
                  value={this.currentYear - 2}
                  className="custom-year-tab"
                />
                <Tab
                  label={`${this.currentYear - 1}-${this.currentYear}`}
                  style={styleWeb.tabHeight}
                  value={this.currentYear - 1}
                  className="custom-year-tab"
                />
                <Tab
                  label={`${this.currentYear}-${this.currentYear + 1}`}
                  style={styleWeb.tabHeight}
                  value={this.currentYear}
                  className="custom-year-tab"
                />
                <Tab
                  label={`${this.currentYear + 1}-${this.currentYear + 2}`}
                  style={styleWeb.tabHeight}
                  value={this.currentYear + 1}
                  className="custom-year-tab-last"
                  disabled
                />
              </Tabs>
            </Paper>
            <Box className="farm-crop-box-wrapper-main">
              <Box className="farm-crop-box-wrapper">
                <Box className="title-btn-wrapper">
                  <Box>
                    <Typography className="farm-crop-title-text">
                      Choose Farm
                    </Typography>
                  </Box>
                </Box>
                {this.renderUserFarms()}
              </Box>
              <Box className="farm-crop-box-wrapper">
                <Box className="title-btn-wrapper">
                  <Box>
                    <Typography className="farm-crop-title-text">
                      Choose Crop
                    </Typography>
                  </Box>
                </Box>
                {this.renderCropsSection()}
              </Box>
            </Box>
            <Box style={styleWeb.chartWrapper}>
              <Box style={styleWeb.chartTitle}>
                <Typography style={styleWeb.titleFonts}>
                  Total Expense
                </Typography>
                <Typography style={styleWeb.amountFonts}>
                  ₹{this.state.totalExpense}/-
                </Typography>
              </Box>
              <Box className="fullWidthChart">
                <BarChart
                  width={500}
                  height={350}
                  data={this.state.chartData}
                  margin={{ top: 30, right: 10, left: 5, bottom: 5 }}
                >
                  <CartesianGrid
                    strokeDasharray="none"
                    stroke="#F0EBE0"
                    vertical={false}
                  />
                  <XAxis
                    stroke="#F0EBE0"
                    dataKey="name"
                    className="recharts-label"
                    tick={{ fill: "#1C2324", fontSize: 12 }}
                  />
                  <YAxis
                    className="recharts-label"
                    stroke="#FFFFF0"
                    tickCount={11}
                    tick={{ fill: "#1C2324", fontSize: 12 }}
                    tickFormatter={(value) => {
                      const absValue = Math.abs(value);
                      if (absValue >= 1000000) {
                        return `${(absValue / 1000000).toFixed(1)}M`;
                      }
                      return value;
                    }}
                  />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={() => null}
                  />
                  <Bar
                    dataKey="pv"
                    fill="#108b88"
                    className="recharts-bar-custom"
                    radius={[6, 6, 6, 6]}
                    barSize={30}
                    label={{
                      position: "top",
                      fill: "#1C2324",
                      fontSize: 12,
                      formatter: (value:number) => {
                        const absValue = Math.abs(value);
                        if (absValue >= 1000000) {
                          return `₹${(absValue / 1000000).toFixed(1)}M`;
                        }
                        return `₹${value.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                      }
                    }}
                  >
                    {this.state.chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={"#108b88"} />
                    ))}
                  </Bar>
                </BarChart>
              </Box>
            </Box>
            <Box className="cropSummaryWrap">
              <Typography className="cropSummary">Crop Summary</Typography>
              <Box className="flex">
                <Typography className="labelLeft">Total Income:</Typography>
                <Typography className="labelRight">
                  ₹{this.state.totalIncome}
                </Typography>
              </Box>
              <Box className="flex">
                <Typography className="labelLeft">Net Quantity:</Typography>
                <Typography className="labelRight">
                  {this.state.netQuantity} kg
                </Typography>
              </Box>
              <Box className="flex">
                <Typography className="labelLeft">Total Expense:</Typography>
                <Typography className="labelRight">
                  ₹{this.state.totalExpense}
                </Typography>
              </Box>
              {this.state.profit ? (
                <Box className="flex green">
                  <Typography className="labelLeft">Net Profit</Typography>
                  <Typography className="labelRight">
                    ₹{this.state.netProfit}
                  </Typography>
                </Box>
              ) : (
                <Box className="flex red">
                  <Typography className="labelLeft">Net Loss</Typography>
                  <Typography className="labelRight">
                    ₹{this.state.netLoss}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </ChartContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ChartContainer = styled(Box)({
  marginBottom: "20px",
  "& .fullWidthChart": { 
    width: "100%", 
    "@media (max-width: 540px)": {
      width: "300px",
      maxWidth: "300px",
      overflowX: "scroll",
      overflowY: "hidden",
    }
  },
  "& .recharts-label": {
    fontFamily: "IBM Plex Sans"
  },
  "& .recharts-bar-custom": {
    borderRadius: "5px",
    "& .recharts-bar-rectangle": {
      borderRadius: "15px",
    }
  }, 
  "& .cropSummary": {
    fontFamily: 'IBM Plex Sans', 
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1C2324',
  }, 
  "& .flex": {
    display: "flex",
    justifyContent: "space-between", 
    alignItems: "center",
    marginTop: "10px", 
    "&.green" : {
      background: "#479348", 
      color: "#fff", 
      borderRadius: "8px", 
      padding: "10px 20px", 
      "& p": {
        color: "#fff"
      }
    }, 
    "&.red" : {
      background: "#B3261E", 
      color: "#fff", 
      borderRadius: "8px", 
      padding: "10px 20px", 
      "& p": {
        color: "#fff"
      }
    }
  }, 
  "& .labelLeft": {
    fontFamily: 'IBM Plex Sans', 
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#1C2324',
  }, 
  "& .labelRight": {
    fontFamily: 'IBM Plex Sans', 
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#1C2324',
  },
  "& .cropSummaryWrap": {
    padding: "20px 20px 0px"
  }, 
  "& .arrowSvg": {
    padding: "2px"
  }
})

const styleWeb = {
  cardsContainer: {
    marginTop: "12px"
  } as React.CSSProperties,
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  } as React.CSSProperties,
  cardImageContainer: {
    position: "relative",
    width: "48px",
    height: "48px",
    cursor: "pointer"
  } as React.CSSProperties,
  cropImageContainer: {
    position: "relative",
    width: "48px",
    height: "48px",
  } as React.CSSProperties,
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "6px"
  } as React.CSSProperties,
  cardTitle: {
    fontSize: "14px",
    lineHeight: "22px",
    marginTop: "4px",
    textTransform: "capitalize",
    textAlign: "center",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    userSelect: "none"
  } as React.CSSProperties,
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  cropCardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "24px",
    userSelect: "none",
  } as React.CSSProperties,
  chartWrapper: {
    background: "#FCFAF7",
    margin: "0px 20px",
    borderRadius: "8px",
    border: "1px solid #F0EBE0"
  } as React.CSSProperties,
  chartTitle: {
    background: "#F0EBE0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px"
  } as React.CSSProperties,
  titleFonts: {
    color: "#1C2324",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "IBM Plex Sans"
  } as React.CSSProperties,
  amountFonts: {
    color: "#006A77",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "IBM Plex Sans"
  } as React.CSSProperties,
  centerText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    minHeight: "100px"
  } as React.CSSProperties,
  tabHeight: {
    height: "40px"
  } as React.CSSProperties,
  usernameTitleWrapper: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "IBM Plex Sans",
    color: "#1C2324",
    textTransform: "uppercase"
  } as React.CSSProperties,
  farmDiaryUpdateBtnWrapper: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "IBM Plex Sans",
    color: "#DF5200",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center", 
    cursor: "pointer"
  } as React.CSSProperties,
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px"
  } as React.CSSProperties,
  loaderWrap: {
    display: "flex", justifyContent: "center", paddingBlock: "16px"
  } as React.CSSProperties,
  yearTabs: { minHeight: "40px", height: "40px" } as React.CSSProperties,
  parentDiv: { marginLeft: "0px", paddingBottom: "20px" } as React.CSSProperties
}
// Customizable Area End

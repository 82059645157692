Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethodType = "GET";
exports.postMethodType = "POST";
exports.putMethodType = "PUT";
exports.deleteMethosTYpe="DELETE"
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfmultiplefarms";
exports.labelBodyText = "cfmultiplefarms Body";
//api endpoints
exports.owerListEndpoit = "/admin/ownership_of_land_listing";
exports.typeofIrrigationEndPoint = "/admin/land_type_listing";
exports.getUnitOfMeasureFarmerEndPoint = "/bx_block_profile_bio/unit_farm_areas";
exports.soilTexturesEndPoint = "/admin/bx_block_profile_bio/soil_textures";
exports.landdetailsEndPoint = "/admin/bx_block_profile_bio/land_details";
exports.cropsEndPoint="admin/bx_block_profile_bio/crops";
exports.unitDropdownEndPonit="admin/bx_block_dropdown/unit_dropdowns"
exports.imagePlaceholder='https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM='
exports.getPreSowings = 'admin/bx_block_farm_dairy/pre_sowings/pre_sowing_history'


exports.LinkShare = "https://co:farm/farmdairy/";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
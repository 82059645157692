// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple1, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateSimpleBodyType1, formateUnitBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    // SEARCH
    searchValue: string,
    // API CALLS
    submitLoading: boolean,
    dropdownData: IDropdownDetailsData[],
    dropdownDataLoading: boolean,
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
}
interface SS { }
// Customizable Area End

export default class NutrientDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    updateDropdownsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            fetchHeadingsLoading: false,
            currentExpanded: null,
            // API CALLS
            dropdownDataLoading: false,
            dropdownData: [],
            submitLoading: false,
            headingsData: [],
            // MSG MODAL
            openMsgModal: false,
            descMsgModal: "",
            titleMsgModal: "",
            // SEARCH
            searchValue: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.updateDropdownsApiCallId:
                        this.UpdateDropdownHandleApi(responseJson)
                        break;
                    case this.getDetailsApiCallId:
                        this.GetDetailsHandleApi(responseJson)
                        break;
                    case this.getHeadingsApiCallId:
                        this.GetHeadingsHandleApi(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsHandleApi = (responseJsonData: IHeadings[] | null | undefined) => {
        if (responseJsonData && responseJsonData.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJsonData
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    GetDetailsHandleApi = (responseJsonData: IDropdownDetailsData[] | null | undefined) => {
        if (responseJsonData && responseJsonData.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: responseJsonData
            })
        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }
    UpdateDropdownHandleApi = (responseJsonData: IResponseDropdowns[] | null | undefined) => {
        this.setState({ submitLoading: false })
        if (!Array.isArray(responseJsonData) || responseJsonData.length === 0) {
            this.handleOpenMsgModal(true, "Error", "Something went wrong")
            return
        }
        const hasMessageKey = responseJsonData.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseJsonData);
            this.handleOpenMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdateCase(responseJsonData);
        }
    }

    successUpdateCase = (responseJsonData: IResponseDropdowns[]) => {
        if (responseJsonData && responseJsonData.length) {
            this.handleOpenMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.handleOpenMsgModal(true, "Error", "Something went wrong!")
        }
    }
    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }
    handleCloseMsgModal = () => {
        this.setState({
            openMsgModal: false, titleMsgModal: "",
            descMsgModal: "",
        })
    }
    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestMSG.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        requestMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMSG.id, requestMSG);
    }
    fetchDropdownDetails = (title: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            })

            const header = { token: this.state.authToken };
            const requestMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiCallId = requestMSG.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            requestMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestMSG.id, requestMSG);
        } else {
            return
        }
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple1 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (dropdownName && title && subDropdownName) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsApiCallId = requestMSG.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMSG.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestMSG.id, requestMSG);
        } else {
            return
        }
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({  dropdownDataLoading: true, dropdownData: []
            }, () => {
                this.fetchDropdownDetails(heading)
            })
        }
    };

    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType1(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
    }

    handleOpenMsgModal = (open: boolean, title: string, message: string) => {
        this.setState({
            openMsgModal: open,
            titleMsgModal: title,
            descMsgModal: message,
        })
    }

    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }

    handleSearch = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchValue: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })

    }
    // Customizable Area End
}
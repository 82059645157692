Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfmandiprices";
exports.labelBodyText = "cfmandiprices Body";

exports.btnExampleTitle = "CLICK ME";

// GENERAL
exports.GET_METHOD_TYPE = "GET";
exports.POST_METHOD_TYPE = "POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.PATCH_METHOD_TYPE = "PATCH";
exports.DELETE_METHOD_TYPE = "DELETE";

exports.TAB = {
  ENGLISH: "english",
  GUJARATI: "gujarati",
  HINDI: "hindi"
}

exports.ENDPOINT = {
  STATE_LIST: "admin/state_listing",
  DISTRICT_LIST: "admin/district_listing",
  MANDI_LIST: "admin/bx_block_dropdown/get_mandi_list"
}
// Customizable Area End
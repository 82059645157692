import React from "react";
import {
    Avatar,
    Box,
    Badge,
    Button,
    Grid,
    Typography,
    Tooltip,
    styled
} from "@material-ui/core";
import {
    Forum,
    Room,
    Add
} from "@material-ui/icons";

import LandingPageController, { Props, configJSON } from "./AdminConsoleLandingPageController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { ChatSVG, DateSVG, HelpSVG, NotificationSVG, PersonSVG, ScheduleSVG, SurveySVG, LocationSVG } from "./assets";
import { Link } from "react-router-dom";
import "./AdminConsoleLandingPage.web.css";

const { landingScreenDefaults: screenDefaults } = configJSON;
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    updateChatCount = (count: any) => {
        this.setState({
            chatCount: count
        })
    }

    render() {
        const { navigation, id } = this.props;
        const _this = this;
        const icon_series = [{ title: "Users", icon: PersonSVG, text: "add user", linkto: "users", openModal: true }, { title: "Chat", icon: ChatSVG, text: "chat now", linkto: "chat" }];
        const icon_series2 = [{ title: "Farm Diary Entries", icon: ScheduleSVG, linkto: "users-farm-diary" }, { title: "Surveys", icon: SurveySVG, linkto: "surveys-section" }, { title: "Notifications", icon: NotificationSVG, linkto: "notifications" }, { title: "Materrapedia", icon: HelpSVG, linkto: "materrapedia" }];

        return (
            // Customizable Area Start
            <NavigationMenu navigation={navigation} id={id} onChatCountFetch={this.updateChatCount}>
                <Grid container justifyContent="space-between" style={styles.rootNavigationGrid} >
                    <Grid item xs={12}>
                        <Grid item>
                            <Box style={styles.container}>
                                <Box style={styles.containerNext}>
                                    <Typography style={styles.typography1} className="dateValueFirst">{this.state.dateArray[2]}</Typography>

                                    <Typography style={styles.typography2} className="dateValueSecond">{`${this.state.dateArray[1]} ${this.state.dateArray[3]}, ${this.state.dateArray[0]}`}</Typography>
                                </Box>

                                <Box style={styles.containerNext}>
                                    <img src={LocationSVG} style={styles.roomIcon} />

                                    <Typography variant="subtitle2" style={styles.typography3}>{this.state.geolocation}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container spacing={3}>
                            {icon_series.map(((item, key) => (
                                <React.Fragment key={key}>
                                    <Grid item xs={12} md={6} lg={6} data-testid="menu-widgets">
                                        <Box style={styles.mainDisplay} data-testid={'main_link_item'}>
                                            <Box style={styles.mainDisplayContent}>
                                                <img src={item.icon} style={styles.mainDisplayIcon} />

                                                <Box>
                                                    <Typography style={styles.mainDisplayText}>{item.title}</Typography>
                                                </Box>
                                            </Box>
                                            <Link to={{ pathname: item.linkto, state: { openModal: item.openModal} }} style={styles.propagateLink}>
                                                <Button
                                                    component="span"
                                                    aria-label="edit-profile"
                                                    disableRipple={true}
                                                    style={styles.bottomActionLabel}
                                                >
                                                    <StyledBadge
                                                        overlap="rectangular"
                                                        badgeContent={
                                                            <Avatar style={styles.badgeIcon}>
                                                                {this.state.chatCount}
                                                            </Avatar>
                                                        }
                                                        invisible={item.title === "Chat" ? false : true}
                                                    >
                                                        {item.title === "Chat" ? <Forum fontSize="medium" /> : <Add fontSize="medium" />}
                                                    </StyledBadge>
                                                    <Typography noWrap={true} style={styles.buttonTypography}>
                                                        {item.text}
                                                    </Typography>
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            )))}
                        </Grid>

                        <Grid item>
                            <Box style={styles.containerLink}>
                                <Typography variant="h6" style={styles.quickLinkHeader}>{screenDefaults.subHeadingText}</Typography>
                            </Box>
                        </Grid>

                        <Grid item container justifyContent="space-between" spacing={2}>
                            {icon_series2.map(((item, key) => (
                                <React.Fragment key={key}>
                                    <Grid item style={{}} xs={12} md={6} lg={3}>
                                        <Link to={item.linkto} style={styles.propagateLink}>
                                            <Tooltip title={item.title}>

                                                <Box style={styles.quickLinkContainer} data-testid={'quick_link_item'}>
                                                    <Box>
                                                        <img src={item.icon} style={styles.quickLinkIcon} />
                                                    </Box>
                                                    <Typography style={styles.quickLinkText} noWrap>{item.title}</Typography>
                                                </Box>
                                            </Tooltip>
                                        </Link>
                                    </Grid>
                                </React.Fragment>
                            )))}
                        </Grid>
                    </Grid>
                </Grid>
            </NavigationMenu>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
        right: "8%",
        top: "1%",
        width: ".5rem",
        height: ".35rem",
        // padding: ".8rem",
        backgroundColor: "#E4DDC9",
        borderRadius: "50%",
        color: "#EB8F06",
    },
}));

const styles = {
    rootNavigationGrid: {
        flexGrow: 1,
    } as React.CSSProperties,

    container: {
        marginBlockStart: ".1rem",
        marginBlockEnd: "1rem"
    },

    containerNext: {
        display: "flex",
        alignItems: "baseline",
        gap: ".3rem"
    },
    typography1:
    {
        fontFamily: screenDefaults.fontFamily,
        fontWeight: 500,
        fontSize: "2.5rem",
        color: "#1C2324"
    },
    typography2: {
        fontFamily: screenDefaults.fontFamily,
        fontWeight: 400,
        fontSize: "1.5rem",
        color: "#1C2324"
    },
    typography3: {
        fontFamily: screenDefaults.fontFamily,
        fontWeight: 300,
        color: "#1C2324",
        opacity: 0.72,
        fontSize: "18px"
    },
    roomIcon: {
        alignSelf: "center",
        color: "#1C2324",
        opacity: 0.72
    },
    mainDisplay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#F8F6F1",
        borderRadius: ".75rem",
        width: "100%",
        height: "240px"
    } as React.CSSProperties,

    mainDisplayContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: ".6rem",
        height: "100%"
    } as React.CSSProperties,

    mainDisplayIcon: {
        fontSize: "6rem",
        color: "#006A77",
    } as React.CSSProperties,

    badgeIcon: {
        // backgroundColor: "#F0EBE0",
        height: "1rem",
        borderRadius: "50%",
        fontSize: "0.5625rem",
        lineHeight: ".75rem",
        color: "#1C2324",
        backgroundColor: "#E4DDC9",
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 500,
        width: 'fit-content'
    } as React.CSSProperties,

    mainDisplayText: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.25rem",
        lineHeight: "1.8rem",
        textTransform: "uppercase",
        color: "#1C2324"
    } as React.CSSProperties,

    bottomActionLabel: {
        color: "#DF5200",
        backgroundColor: "#F0EBE0",
        width: "100%",
        paddingBlock: ".75rem",
        borderBottomLeftRadius:12,
        borderBottomRightRadius:12,
    } as React.CSSProperties,

    buttonTypography: {
        marginInlineStart: ".5rem",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: 600,
        textTransform: 'uppercase',
        color: "#DF5200",
    } as React.CSSProperties,

    containerLink: {
        marginBlockStart: "1.2rem",
        marginBlockEnd: "1rem"
    } as React.CSSProperties,

    quickLinkContainer: {
        display: "flex",
        gap: ".1rem",
        alignItems: "center",
        backgroundColor: "#F8F6F1",
        borderRadius: ".75rem",
        height: "100%",
        paddingInlineStart: "1rem",
        paddingInlineEnd: "0.7rem",
        paddingBlock: ".4rem",
        cursor: "pointer",
    } as React.CSSProperties,

    quickLinkHeader: {
        fontFamily: screenDefaults.fontFamily,
        fontSize: "1.25rem",
        color: "#1C2324",
        textTransform: "uppercase",
        opacity: 0.64,
        fontWeight: 500,
        linHeight: "1.875rem",
        fontStyle: "normal"
    } as React.CSSProperties,

    quickLinkIcon: {
        color: "#006A77",
        fontSize: "2rem",
    } as React.CSSProperties,

    quickLinkText: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "1.125rem",
        lineHeight: "1.875rem",
        textTransform: "uppercase",
        color: "#1C2324"
    } as React.CSSProperties,

    propagateLink: { textDecoration: "none", width: "100%" }
}
// Customizable Area End
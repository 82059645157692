import React from "react";

import {
    // Customizable Area Start
    Box,
    Input,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
    Tooltip,
    Avatar,
    Breadcrumbs,
    Dialog,
    DialogTitle,
    DialogContent,
    FormLabel,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
    Chip
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom"
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// ICON IMPORT STARTS
import { Visibility, VisibilityOff, PhotoCamera } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {
    AddUserPlusIconSVG,
    ChecokedRadioIconSVG,
    EditIconOrangeSVG,
    UnchecokedRadioIconSVG,
    deleteIconRedSVG,
    imgIconFilter,
    linkSeparatorSVG,
    smallCloseIconSVG
} from "./assets";
// ICON IMPORT ENDS

import { Formik } from "formik";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";


// Customizable Area Start
import ViewSuperAdminController, {
    Props,
    configJSON,
} from "./ViewSuperAdminController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "./ViewSuperAdmin.web.css";
import CustomModal from "../../../components/src/customModal.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { SchemaFarmerForm, SchemaFieldExecutiveForm, keyDown, keyDownInt } from "../../../components/src/helper";
import { NoMenuDataFound, StyledBadge, adminColumns, selectMenuItem } from "../../../components/src/AdminConsoleHelper";
import AssignVillageFilter from "../../../components/src/AssignVillageFilter";
import ImageCropEditor from "../../../components/src/ImageCropper";

// Customizable Area End

const themeSuperAdmin = createTheme({
    palette: {
        primary: {
            main: "#000",
            contrastText: "#fff",
        },
        secondary: {
            main: "#DF5200",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    },
});

// Customizable Area End

export default class ViewSuperAdmin extends ViewSuperAdminController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    getCustomErrorMessage = (touched: any, errors: any, value: string) => {
        return (
            touched[value] &&
            errors[value] && (
                <div>
                    <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
                        {errors[value]}
                    </Typography>
                </div>
            )
        );
    };
    getVisibilityIconPassword = () => {
        return this.state.showEyeIconPassword ? (
            <Visibility style={webStyle.customDateIcon} />
        ) : (
            <VisibilityOff style={webStyle.customDateIcon} />
        );
    };

    getVisibilityIconConfirmPassword = () => {
        return this.state.showEyeIconConfirmPassword ? (
            <Visibility style={webStyle.customDateIcon} />
        ) : (
            <VisibilityOff style={webStyle.customDateIcon} />
        );
    };

    villageAvatar = (index: number, option: any) => {
        return (
            <>
                <Avatar
                    style={{
                        color: "#fff",
                        height: "28px",
                        backgroundColor: `${index % 2 === 0 ? "#108B88" : "#EB8F06"}`,
                        fontWeight: 500,
                        lineHeight: "18px",
                        width: "28px",
                        fontSize: "12px",
                        fontStyle: "normal",
                    }}
                >
                    {option?.name?.charAt(0).toUpperCase()}
                </Avatar>
            </>
        );
    };

    screenTitle = () => {
        return (
            <>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box>
                        <Box display="flex" gridGap="10px" alignItems="center">
                            <Typography
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "32px",
                                    lineHeight: "48px",
                                    color: "#1C2324",
                                }}
                            >
                                Users
                            </Typography>
                        </Box>
                        <Box className='breadcrumbMain'>
                            <Breadcrumbs
                                separator={<img src={linkSeparatorSVG} alt={"separator"} />}
                                aria-label="breadcrumb"
                            >
                                <Link style={{ textDecoration: "none" }} className="menuLinkUser" to="/users" >
                                    Users
                                </Link>
                                <Typography className="menuTextUser">
                                    View Profile
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row-reverse"
                        gridGap={5}
                    >
                        <Button
                            variant="contained"
                            style={styleProfile.addUserModalBtnStyle}
                            onClick={() => { this.handleAddUserModalOpen() }}
                            data-testid="addUserModalBtn"
                        >
                            <img src={AddUserPlusIconSVG} alt="+" style={{ marginRight: "8px" }} />Add User
                        </Button>
                    </Box>
                </Box>

            </>
        )
    }

    superAdminProfileSection = () =>  this.state.superAdminProfileImage?.icon && <ImageCropEditor open={this.state.cropImageModal} src={this.state.superAdminProfileImage.icon} onClose={this.handleCloseCropModalSuperAdmin} title="Super Admin profile" buttonText="Submit" handleUploadImage={this.handleSubmitSAProgfileImage} />

    profileSection = () => {
        return (
            <>
                <Box mt={1.5}>
                    <Grid
                        container
                        className="main-profile-section-wrapper"
                        spacing={3}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Grid
                                container
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="profile-card-left-div"
                            >
                                <input type="file" id="upload" data-test-id="uploadProfileButton" onChange={(event) => event.currentTarget?.files?.length && this.onProfileImageUpload(event)} accept="image/*" style={{ display: 'none' }} />
                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                    <Box className="profileIconWrapper">
                                        <label htmlFor="upload" className="saProfileImage" style={{ display: 'flex', alignItems: 'center' }}>

                                            <StyledBadge
                                                overlap="rectangular"
                                                badgeContent={
                                                    <PhotoCamera
                                                        fontSize="small"
                                                        style={styleProfile.profileIconButton}
                                                    />
                                                }
                                                data-testid="badge-content"
                                            >
                                                <IconButton
                                                    disableRipple={true}
                                                    aria-label="camera-edit_icon"
                                                    style={styleProfile.avatarContainer}
                                                >

                                                    <Avatar
                                                        style={styleProfile.profileAvatar}
                                                        src={this.state.currentSuperAdminData.avatar}
                                                    >
                                                        {this.state.currentSuperAdminData?.first_name && String(this.state.currentSuperAdminData.first_name[0]).toUpperCase()}
                                                    </Avatar>
                                                </IconButton>
                                            </StyledBadge>
                                        </label>
                                    </Box>
                                    <Box className="profileNameWrapper" style={{ marginTop: "8px" }}>
                                        <Tooltip
                                            title={`${this.state.currentSuperAdminData?.first_name + " " + this.state.currentSuperAdminData?.last_name}`}
                                        >
                                            <Typography
                                                noWrap
                                                data-testid="profile-name"
                                                style={
                                                    styleProfile.avatarTypographyTitle
                                                }
                                            >{`${this.state.currentSuperAdminData?.first_name + " " + this.state.currentSuperAdminData?.last_name}`}</Typography>
                                        </Tooltip>
                                        <Typography
                                            noWrap
                                            className="avatarTypographyContact">
                                            {"+91-" + `${this.state.currentSuperAdminData?.phone_number ? this.state.currentSuperAdminData?.phone_number : ""}`}
                                        </Typography>
                                    </Box>

                                </Grid>
                                <Grid item lg={8} md={12} sm={12} xs={12}>
                                    <Box style={{ marginTop: "8px" }}>
                                        <Typography noWrap>
                                            <span className="ProfileLabelName">Email Id:</span>
                                            <Tooltip title={this.state.currentSuperAdminData?.email ? this.state.currentSuperAdminData?.email : ""}>
                                                <span className="ProfileLabelValue">{this.state.currentSuperAdminData?.email ? this.state.currentSuperAdminData?.email : ""}</span>
                                            </Tooltip>
                                        </Typography>
                                        <Typography className="margin-top16" noWrap>
                                            <span className="ProfileLabelName">Username:</span>
                                            <Tooltip title={this.state.currentSuperAdminData?.user_name ? this.state.currentSuperAdminData?.user_name : ""}>
                                                <span className="ProfileLabelValue">{this.state.currentSuperAdminData?.user_name ? this.state.currentSuperAdminData?.user_name : ""}</span>
                                            </Tooltip>
                                        </Typography>
                                        <Typography className="margin-top16" noWrap>
                                            <span className="ProfileLabelName">Date of Birth:</span>
                                            <Tooltip title={this.state.currentSuperAdminData?.dob ? moment(this.state.currentSuperAdminData?.dob).format("DD MMM YYYY") : ""}>
                                                <span className="ProfileLabelValue">{this.state.currentSuperAdminData?.dob ? moment(this.state.currentSuperAdminData?.dob).format("DD MMM YYYY") : ""}</span>
                                            </Tooltip>
                                        </Typography>
                                    </Box>
                                    {!this.state.isLoggedUserAdmin ? (<>
                                        <Box
                                            style={styleProfile.functionButtonContainer}
                                            data-test-id={"profile-container"}
                                        >
                                            <Tooltip title={this.state.isLoggedUserAdmin ? "Disabled" : "Edit"}>
                                                <IconButton
                                                    aria-label="edit"
                                                    disableRipple
                                                    style={{ padding: "0px" }}
                                                    data-testid="profileEditButton"
                                                    onClick={(e) => { this.handleEditSuperAdmin() }}
                                                    disabled={this.state.isLoggedUserAdmin}
                                                >
                                                    <img src={EditIconOrangeSVG} alt="edit_icon" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={this.state.isLoggedUserAdmin ? "Disabled" : "Delete"}>
                                                <span>
                                                    <IconButton
                                                        aria-label="delete"
                                                        disableRipple
                                                        style={{ padding: "0px" }}
                                                        data-testid={`profileDeleteButton`}
                                                        onClick={(e) => {
                                                            this.setState({ openSuperAdminDeletePopup: true })
                                                        }}
                                                        disabled={this.state.isLoggedUserAdmin}
                                                    >
                                                        <img src={deleteIconRedSVG} alt="delete_icon" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </>) : (<></>)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Grid
                                container
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="profile-card-right-div-top"
                            >
                                <Grid item lg={12} md={12} sm={12} xs={12} className="sub-card-right-top">
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Box style={{ marginRight: "8px" }}>
                                            <Typography>
                                                <span className="ProfileLabelName">Role:</span>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Select
                                                className="changeRoleSelectMenu"
                                                value={this.state.setAdminRole}
                                                onChange={(e: any) => this.setState({ setAdminRole: e.target.value })}
                                                data-testid="changeRoleTestid"
                                                disableUnderline
                                                disabled
                                                inputProps={{ disableUnderline: true }}
                                            >
                                                <MenuItem value={"admin"}>Admin</MenuItem>
                                                <MenuItem value={"super_admin"}>
                                                    Super Admin
                                                </MenuItem>
                                            </Select>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="profile-card-right-div-bottom"
                            >
                                <Grid item lg={6} md={12} sm={12} xs={12} className="sub-card-right-bottom-Wrapper">
                                    <Box className="sub-card-right-bottom">
                                        <Box className="setContentLine">
                                            <Box>
                                                <Typography className="subTitleInfoCard" noWrap>
                                                    Total number of
                                                </Typography>
                                                <Typography className="mainTitleInfoCard" noWrap>
                                                    Admin
                                                </Typography>
                                            </Box>
                                            <Box className="fontBlue">
                                                <Typography
                                                    data-testid="farmer-card"
                                                    className="countNumbertxt"
                                                >
                                                    {this.state.totalAdminsCount}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={12} sm={12} xs={12} className="sub-card-right-bottom-Wrapper sub-card-right-bottom-Wrapper-right">
                                    <Box className="sub-card-right-bottom">
                                        <Box className="setContentLine">
                                            <Box>
                                                <Typography className="subTitleInfoCard" noWrap>
                                                    Total number of
                                                </Typography>
                                                <Typography className="mainTitleInfoCard" noWrap>
                                                    Field Executive
                                                </Typography>
                                            </Box>
                                            <Box className="fontBlue">
                                                <Typography
                                                    data-testid="farmer-card"
                                                    className="countNumbertxt2"
                                                    noWrap
                                                >
                                                    {this.state.totalFECount}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }

    adminTable = () => {
        return (
            <DataGrid
                data-testid="adminTableSATestid"
                disableSelectionOnClick
                style={{ height: "inherit" }}
                disableColumnMenu
                rowHeight={46}
                rows={this.state.adminTableData}
                columns={adminColumns(
                    (data) => this.handleAdminEditClick(data),
                    (data) => this.handleAdminDelete(data),
                    this.state.isLoggedUserAdmin
                )}
                pageSize={this.state.adminTablePageSize}
                onPageSizeChange={(page: number) => {
                    this.setState({ adminTablePageSize: page });
                }}
                loading={this.state.adminTableDataLoading}
                rowsPerPageOptions={[15, 30, 50]}
                getRowId={(row: any) => row.id}
                rowCount={this.state.totalSearchedCount}
                page={this.state.adminTableCurrentPage}
                onPageChange={(page: number) => {
                    this.setState({
                        adminTableCurrentPage: page,
                    });
                }}
            />
        );
    };

    renderForms = () => {
        switch (this.state.seletedUserTypeForm) {
            case "Farmer":
              return this.showFarmerForm()
            case "FieldExecutive":
              return this.showFEForm()
            case "Admin":
            case "SuperAdmin":
              return this.AdminForm()
            default:
              return <></>
          }
    }    
    // USER FORMS STARTS
    showFarmerForm = () => {
        return (
            <>
                <Formik
                    data-testid="farmersFormikTestid"
                    initialValues={this.state.farmerInitialValues}
                    validationSchema={SchemaFarmerForm}
                    onSubmit={(values: any) => {
                        this.addFarmer(values);
                    }}
                    validateOnChange
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleSubmit,
                    }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off"
                                className="customFormSpacing"
                            >                            
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ marginTop: "8px" }}
                                    spacing={2}                                    
                                >
                                    <Grid item className="formfieldWrapper"
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}                                        
                                    >
                                        <FormLabel
                                            id="firstnameFarmerId"
                                            className="customLabelField"
                                        >
                                            First Name
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <TextField
                                                data-testid="firstnameFarmerTestId"
                                                style={webStyle.customInputField}
                                                placeholder="Enter First Name"
                                                type="text"
                                                name="firstnameFarmer"
                                                variant="outlined"
                                                color="secondary"
                                                value={values.firstnameFarmer}
                                                onChange={handleChange}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "firstnameFarmer"
                                                )}
                                                helperText={this.getHelperText(
                                                    touched,
                                                    errors,
                                                    "firstnameFarmer"
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel
                                            id="middlenameFarmerId"
                                            className="customLabelField"
                                        >
                                            Middle Name
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <TextField
                                                data-testid="middlenameFarmerTestId"
                                                style={webStyle.customInputField}
                                                placeholder="Enter Middle Name"
                                                type="text"
                                                name="middlenameFarmer"
                                                variant="outlined"
                                                color="secondary"
                                                value={values.middlenameFarmer}
                                                onChange={handleChange}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "middlenameFarmer"
                                                )}
                                                helperText={this.getHelperText(
                                                    touched,
                                                    errors,
                                                    "middlenameFarmer"
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel
                                            id="surnameFarmerId"
                                            className="customLabelField"
                                        >
                                            Surname
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <TextField
                                                data-testid="surnameFarmerTestId"
                                                style={webStyle.customInputField}
                                                placeholder="Enter Surname"
                                                type="text"
                                                name="surnameFarmer"
                                                variant="outlined"
                                                color="secondary"
                                                value={values.surnameFarmer}
                                                onChange={handleChange}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "surnameFarmer"
                                                )}
                                                helperText={this.getHelperText(
                                                    touched,
                                                    errors,
                                                    "surnameFarmer"
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel
                                            id="mobileNumberFarmerId"
                                            className="customLabelField"
                                        >
                                            Mobile Number
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Input
                                                disableUnderline
                                                className="custom-mobile-input"
                                                style={{
                                                    marginTop: "8px",
                                                    padding: "8px 8px 8px 0",
                                                    background: "#FCFAF7",
                                                    borderRadius: "12px",
                                                    border: "1px solid #F0EBE0",
                                                    height:"56px"
                                                }}
                                                placeholder="Enter Mobile Number"
                                                type="text"
                                                name="mobileNumberFarmer"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Box
                                                            style={{
                                                                padding: "0 16px",
                                                                borderRight: "1px solid #F0EBE0",
                                                                marginRight: "8px"
                                                            }}
                                                        >
                                                            <p>+91</p>
                                                        </Box>
                                                    </InputAdornment>
                                                }
                                                value={values.mobileNumberFarmer}
                                                onChange={handleChange}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "mobileNumberFarmer"
                                                )}
                                            />
                                            {this.getCustomErrorMessage(
                                                touched,
                                                errors,
                                                "mobileNumberFarmer"
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" justifyContent="center" alignItems="center" 
                                spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} 
                                    className="formfieldWrapper">
                                        <FormLabel id="numberBelongsToFarmerId" className="customLabelField" >
                                            Number belongs to
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                data-testid="numberBelongsToFarmerTestid"
                                                className="mt-8 farmerFormDDClass"
                                                name="numberBelongsToFarmer"
                                                variant="outlined"                                                
                                                color="secondary"
                                                inputProps={{ "aria-label": "Without label" }}
                                                displayEmpty
                                                onChange={handleChange}
                                                value={values.numberBelongsToFarmer|| ""}                                                                                                
                                                error={this.getCustomErrorMessage(touched, errors, "numberBelongsToFarmer")}
                                            >
                                                {selectMenuItem("Select Option")}
                                                {this.state.getNumberBelongsTo.length ?
                                                    this.state.getNumberBelongsTo.map((ech: any) => (
                                                        <MenuItem key={ech.id} value={ech.id} data-testid={`numberBelongsToFarmerMenusTestid-${ech.id}`}>
                                                            {ech.name}
                                                        </MenuItem>
                                                    )): NoMenuDataFound()}
                                            </Select>
                                            {this.getCustomErrorMessage( touched, 
                                                errors, "numberBelongsToFarmer" )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} 
                                    className="formfieldWrapper">
                                        <FormLabel id="mobileTypeFarmerId" className="customLabelField" >
                                            Mobile Type
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                data-testid="mobileTypeFarmerTestid"
                                                variant="outlined"
                                                className="mt-8 farmerFormDDClass"
                                                color="secondary"
                                                name="mobileTypeFarmer"
                                                inputProps={{ "aria-label": "Without label" }}
                                                value={values.mobileTypeFarmer|| ""}
                                                onChange={handleChange}
                                                displayEmpty                                                
                                                error={this.getCustomErrorMessage(touched, errors, "mobileTypeFarmer")}
                                            >
                                                {selectMenuItem("Select Option")}
                                                {this.state.getMobileDeviceModelFEData.length ? this.state.getMobileDeviceModelFEData.map((ech: any) => (
                                                    <MenuItem key={ech.id} value={ech.id} data-testid={`mobileTypeFarmerMenusTestid-${ech.id}`}>
                                                        {ech.name}
                                                    </MenuItem>
                                                )) : NoMenuDataFound()}
                                            </Select>
                                                {this.getCustomErrorMessage(
                                                    touched, errors, "mobileTypeFarmer")
                                                    }
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" justifyContent="center"
                                    alignItems="center" spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="formfieldWrapper">
                                        <FormLabel
                                            id="adharcardNumberFarmerId"
                                            className="customLabelField"
                                        >
                                            Aadhar Card <span style={{fontStyle:"italic",fontSize:"12px"}}>- Optional</span>
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <TextField
                                                style={webStyle.customInputField}
                                                placeholder="Enter Aadhar Card Number"
                                                type="number"
                                                name="adharcardNumberFarmer"
                                                variant="outlined"
                                                color="secondary"
                                                onKeyDown={keyDownInt}
                                                value={values.adharcardNumberFarmer}
                                                onChange={handleChange}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "adharcardNumberFarmer"
                                                )}
                                                helperText={this.getHelperText(
                                                    touched,
                                                    errors,
                                                    "adharcardNumberFarmer"
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel
                                            id="dateOfBirthFarmerId"
                                            className="customLabelField"
                                        >
                                            Date of Birth
                                        </FormLabel>
                                        <FormControl fullWidth style={{ marginTop: "8px" }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    className="customDatePicker"
                                                    clearable
                                                    data-testid="dateOfBirthTestid"
                                                    value={values.dateOfBirthFarmer}
                                                    placeholder="DD/MM/YYYY"
                                                    onChange={(date) =>
                                                        setFieldValue("dateOfBirthFarmer", date)
                                                    }
                                                    maxDate={moment().subtract(18, "years").toDate()}
                                                    invalidDateMessage={`Invalid date`}
                                                    format="DD/MM/yyyy"
                                                    name="dateOfBirthFarmer"
                                                    inputVariant="outlined"
                                                    color="secondary"
                                                    error={this.getErrorBoolean(
                                                        touched,
                                                        errors,
                                                        "dateOfBirthFarmer"
                                                    )}
                                                    helperText={this.getHelperText(
                                                        touched,
                                                        errors,
                                                        "dateOfBirthFarmer"
                                                    )}
                                                    keyboardIcon={
                                                        <DateRangeIcon style={webStyle.customDateIcon} />
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item className="formfieldWrapper"
                                        xs={12} sm={12} md={6} lg={6} xl={6}                                        
                                    >
                                        <FormLabel id="totalFamilyMemberFarmerId"
                                            className="customLabelField"
                                        >
                                            Total Family Members
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <TextField
                                                style={webStyle.customInputField}
                                                inputProps={{ inputMode: "numeric" }}
                                                placeholder="Family Members"
                                                type="number"
                                                name="totalFamilyMemberFarmer"
                                                variant="outlined"
                                                onKeyDown={keyDownInt}
                                                color="secondary"
                                                value={values.totalFamilyMemberFarmer}
                                                onChange={handleChange}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "totalFamilyMemberFarmer"
                                                )}
                                                helperText={this.getHelperText(
                                                    touched,
                                                    errors,
                                                    "totalFamilyMemberFarmer"
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel
                                            id="educationFarmerId"
                                            className="customLabelField"
                                        >
                                            Education
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                data-testid="educationFarmerTestid"
                                                value={values.educationFarmer|| ""}
                                                variant="outlined"
                                                className="mt-8 farmerFormDDClass"
                                                name="educationFarmer"
                                                color="secondary"
                                                onChange={handleChange}
                                                inputProps={{ "aria-label": "Without label" }}
                                                displayEmpty
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "educationFarmer"
                                                )}
                                            >                                                
                                                {selectMenuItem("Select Education")}
                                                {this.state.getEducationFarmerData.length > 0 ?
                                                    this.state.getEducationFarmerData.map((ech: any) => (
                                                        <MenuItem
                                                            data-testid={`educationFarmerMenusTestid-${ech.id}`}
                                                            value={ech.id}
                                                            key={ech.id}
                                                        >
                                                            {ech.name}
                                                        </MenuItem>
                                                    )): NoMenuDataFound()}
                                            </Select>
                                            {this.getCustomErrorMessage(
                                                touched,
                                                errors,
                                                "educationFarmer"
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Box style={{ margin: "8px 0 20px 0px" }}>
                                    <Box style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}>
                                        <Box>
                                            <FormLabel style={{ marginRight: "24px" }} className="customLabelField">
                                                Select Gender:
                                            </FormLabel>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    name="genderFarmer"
                                                    value={values.genderFarmer}
                                                    onChange={handleChange}
                                                    aria-labelledby="userGenderId"
                                                >
                                                    <FormControlLabel
                                                        style={{marginRight:"12px"}}
                                                        value="male"
                                                        label="Male"
                                                        control={
                                                            <Radio
                                                                icon={<img src={UnchecokedRadioIconSVG} />}
                                                                checkedIcon={
                                                                    <img src={ChecokedRadioIconSVG} />
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        style={{marginRight:"12px"}}
                                                        value="female"
                                                        label="Female"
                                                        control={
                                                            <Radio
                                                                icon={<img src={UnchecokedRadioIconSVG} />}
                                                                checkedIcon={
                                                                    <img src={ChecokedRadioIconSVG} />
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        style={{marginRight:"12px"}}
                                                        value="others"
                                                        label="Other"
                                                        control={
                                                            <Radio
                                                                icon={<img src={UnchecokedRadioIconSVG} />}
                                                                checkedIcon={
                                                                    <img src={ChecokedRadioIconSVG} />
                                                                }
                                                            />
                                                        }
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    {this.getCustomErrorMessage(touched, errors, "genderFarmer")}
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"

                                >
                                    <Grid
                                        item
                                        className="formfieldWrapper"
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <FormLabel id="stateFarmerId" className="customLabelField">
                                            State
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={values.stateFarmer|| ""}
                                                className="mt-8 farmerFormDDClass"
                                                data-testid="stateFarmerTestid"
                                                variant="outlined"
                                                name="stateFarmer"
                                                color="secondary"
                                                onChange={async (e: any) => {
                                                    await setFieldValue("stateFarmer", e.target.value, true);
                                                    setFieldTouched("stateFarmer", true);
                                                    await setFieldValue("districtFarmer", "", true);
                                                    await setFieldValue("talukaFarmer", "", true);
                                                    await setFieldValue("villageFarmer", "", true);
                                                    this.fetchDistrict(e.target.value);
                                                }}
                                                displayEmpty
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "stateFarmer"
                                                )}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                {selectMenuItem("Select State")}                                                
                                                {this.state.getStateData.length > 0 ?
                                                    this.state.getStateData.map((ech: any) => (
                                                        <MenuItem
                                                            data-testid={`stateSubTestid-${ech.id}`}
                                                            key={ech.id}
                                                            value={ech.id}
                                                        >
                                                            {ech.name}
                                                        </MenuItem>
                                                    )) : NoMenuDataFound()}
                                            </Select>
                                            {this.getCustomErrorMessage(
                                                touched,
                                                errors,
                                                "stateFarmer"
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item className="formfieldWrapper"
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <FormLabel
                                            id="districtFarmerId"
                                            className="customLabelField"
                                        >
                                            District
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={this.getDistrictFarmerValue(values)|| ""}
                                                variant="outlined"
                                                className="mt-8 farmerFormDDClass"
                                                name="districtFarmer"
                                                data-testid="districtFarmerTestid"
                                                color="secondary"
                                                onChange={async (e: any) => {
                                                    await setFieldValue("districtFarmer", e.target.value, true);
                                                    setFieldTouched("districtFarmer", true);
                                                    setFieldValue("talukaFarmer", "", true);
                                                    setFieldValue("villageFarmer", "", true);
                                                    this.fetchTaluka(e.target.value);
                                                }}
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "districtFarmer"
                                                )}
                                                displayEmpty
                                                disabled={this.disableDistrictFarmerValue()}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                {selectMenuItem("Select District")}
                                                {this.state.getDistrictData.length > 0 ?
                                                    this.state.getDistrictData.map((ech: any) => (
                                                        <MenuItem
                                                            data-testid={`distSubTestid-${ech.id}`}
                                                            key={ech.id} value={ech.id}
                                                        >
                                                            {ech.name}
                                                        </MenuItem>
                                                    )) : NoMenuDataFound()}
                                            </Select>
                                            {this.getCustomErrorMessage(touched, errors, "districtFarmer")}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container
                                    spacing={2}
                                    justifyContent="center"
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Grid item className="formfieldWrapper"
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <FormLabel id="talukaFarmerId" className="customLabelField">
                                            Taluka
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={this.getTalukaFarmerValue(values)|| ""}
                                                variant="outlined"
                                                className="mt-8 farmerFormDDClass"
                                                name="talukaFarmer"
                                                data-testid="talukaFarmerTestid"
                                                color="secondary"
                                                onChange={async (e: any) => {
                                                    await setFieldValue("talukaFarmer", e.target.value, true);
                                                    setFieldTouched("talukaFarmer", true);
                                                    setFieldValue("villageFarmer", "", true);
                                                    this.fetchVillage(e.target.value);
                                                }}
                                                displayEmpty
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "talukaFarmer"
                                                )}
                                                disabled={this.disableTalukaFarmerValue()}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                {selectMenuItem("Select Taluka")}
                                                {this.state.getTalukaData.length > 0 ?
                                                    this.state.getTalukaData.map((ech: any) => (
                                                        <MenuItem key={ech.id} value={ech.id}
                                                            data-testid={`talukaSubTestid-${ech.id}`}
                                                        >
                                                            {ech.name}
                                                        </MenuItem>
                                                    )) : NoMenuDataFound()}
                                            </Select>
                                            {this.getCustomErrorMessage(touched, errors, "talukaFarmer")}
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel
                                            id="villageFarmerId"
                                            className="customLabelField"
                                        >
                                            Village
                                        </FormLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={this.getVillageFarmerValue(values)|| ""}
                                                variant="outlined"
                                                className="mt-8 farmerFormDDClass"
                                                name="villageFarmer"
                                                data-testid="villageFarmerTestid"
                                                color="secondary"
                                                onChange={async (e: any) => {
                                                    await setFieldValue("villageFarmer", e.target.value);
                                                    setFieldTouched("villageFarmer", true);
                                                }}
                                                displayEmpty
                                                error={this.getErrorBoolean(touched, errors, "villageFarmer")}
                                                disabled={this.disableVillageFarmerValue()}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >                                                
                                                {selectMenuItem("Select Village")}
                                                {this.state.getVillageData.length > 0 ?
                                                    this.state.getVillageData.map((ech: any) => (
                                                        <MenuItem key={ech.id} value={ech.id}
                                                            data-testid={`villageSubTestid-${ech.id}`}
                                                        >
                                                            {ech.name}
                                                        </MenuItem>
                                                    )) : NoMenuDataFound()}
                                            </Select>
                                            {this.getCustomErrorMessage(touched, errors, "villageFarmer")}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                        
                                <Box id="assignFieldExecutiveId">
                                    <Typography className="userModalBodySubHeading">
                                        Assign Field Executive
                                    </Typography>
                                    <Box>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            direction="row"
                                        >
                                            <Grid item className="formfieldWrapper"
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}

                                            >
                                                <FormLabel
                                                    id="emailAddressAdminId"
                                                    className="customLabelField"
                                                >
                                                    Assign Field Executive
                                                </FormLabel>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        className="customAutoComplete mt-8"
                                                        data-testid="assigneFETestid"
                                                        filterSelectedOptions // remove selected FE from the list
                                                        options={this.state.FElistData}
                                                        value={this.state.selectedFEformData}
                                                        onChange={this.handleAssignFEchange}
                                                        getOptionLabel={(option: any) =>
                                                            option.first_name + " " + option.last_name
                                                        }
                                                        renderInput={(params: any) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                color="secondary"
                                                                placeholder={this.assignFEPlaceholder()}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box className="modalSubmitButtonWrapper">
                                    <Button variant="contained"
                                        fullWidth
                                        data-testid="FEsubmitBtnTestid"
                                        className="modalSubmitButton"
                                        type="submit"
                                        disabled={this.state.addFarmerLoading}
                                    >
                                        {this.getSubmitFormBtnTxt()}
                                    </Button>
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </>
        )
    };
    showFEForm = () => {
        return (
            <>
                <Formik
                    initialValues={this.state.feInitialValues}
                    validationSchema={SchemaFieldExecutiveForm}
                    onSubmit={(values: any) => {
                        this.addFieldExecutive(values);
                    }}
                    validateOnChange
                    data-testid="fieldExecutiveFormikTestid"
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                            autoComplete="off"
                            className="customFormSpacing"
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="firstnameFEId" className="customLabelField">
                                        First Name
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter First Name"
                                            type="text"
                                            name="firstnameFE"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.firstnameFE}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "firstnameFE"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "firstnameFE"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="lastnameFEId" className="customLabelField">
                                        Last Name
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Last Name"
                                            type="text"
                                            name="lastnameFE"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.lastnameFE}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "lastnameFE"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "lastnameFE"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="mobileNumberFEId" className="customLabelField">
                                        Mobile Number
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Input
                                            disableUnderline
                                            fullWidth
                                            type="text"
                                            className="custom-mobile-input"
                                            name="mobileNumberFE"
                                            style={{
                                                marginTop: "8px",
                                                background: "#FCFAF7",
                                                borderRadius: "12px",
                                                padding: "8px 8px 8px 0",
                                                border: "1px solid #F0EBE0",
                                                height:"56px"
                                            }}
                                            value={values.mobileNumberFE}
                                            onChange={handleChange}
                                            placeholder="Enter Mobile Number"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Box
                                                        style={{
                                                            padding: "0 16px",
                                                            borderRight: "1px solid #F0EBE0",
                                                            marginRight: "8px"
                                                        }}
                                                    >
                                                        <p>+91</p>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                            error={this.getErrorBoolean(touched, errors, "mobileNumberFE")}
                                        />
                                        {this.getCustomErrorMessage(touched, errors, "mobileNumberFE")}
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="locationFEId" className="customLabelField">
                                        Location
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Village, State"
                                            type="text"
                                            name="locationFE"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.locationFE}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "locationFE"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "locationFE"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="dateOfBirthFEId" className="customLabelField">
                                        Date of Birth
                                    </FormLabel>
                                    <FormControl fullWidth style={{ marginTop: "8px" }}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                className="customDatePicker"
                                                clearable
                                                data-testid="dateOfBirthFETestid"
                                                value={values.dateOfBirthFE}
                                                placeholder="DD/MM/YYYY"
                                                onChange={(date) =>
                                                    setFieldValue("dateOfBirthFE", date)
                                                }
                                                maxDate={moment().subtract(18, "years").toDate()}
                                                invalidDateMessage={`Invalid date`}
                                                format="DD/MM/yyyy"
                                                inputVariant="outlined"
                                                color="secondary"
                                                name="dateOfBirthFE"
                                                error={this.getErrorBoolean(
                                                    touched,
                                                    errors,
                                                    "dateOfBirthFE"
                                                )}
                                                helperText={this.getHelperText(
                                                    touched,
                                                    errors,
                                                    "dateOfBirthFE"
                                                )}
                                                keyboardIcon={
                                                    <DateRangeIcon style={webStyle.customDateIcon} />
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="emailAddressFEId" className="customLabelField">
                                        Email Address
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Email Id"
                                            type="email"
                                            name="emailAddressFE"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.emailAddressFE}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "emailAddressFE"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "emailAddressFE"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="usernameFEId" className="customLabelField">
                                        Username
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Username"
                                            type="text"
                                            name="usernameFE"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.usernameFE}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "usernameFE"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "usernameFE"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel
                                        id="mobileDeviceModelFEId"
                                        className="customLabelField"
                                    >
                                        Mobile Device Model
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            value={values.mobileDeviceModelFE || ""}
                                            variant="outlined"
                                            className="mt-8 farmerFormDDClass"
                                            name="mobileDeviceModelFE"
                                            color="secondary"
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "mobileDeviceModelFE"
                                            )}
                                            onChange={handleChange}
                                            inputProps={{ "aria-label": "Without label" }}
                                            displayEmpty
                                        >                                            
                                            {selectMenuItem("Select Mobile Device Model")}
                                            {this.state.getMobileDeviceModelFEData.length > 0 ?
                                                this.state.getMobileDeviceModelFEData.map(
                                                    (ech: any) => (
                                                        <MenuItem value={ech.id} key={ech.id}
                                                            data-testid={`getMobileDeviceModelFEData-${ech.id}`}
                                                        >
                                                            {ech.name}
                                                        </MenuItem>
                                                    )
                                                ): NoMenuDataFound()}
                                        </Select>
                                        {this.getCustomErrorMessage(touched, errors, "mobileDeviceModelFE")}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container
                                alignItems="center"
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                            >
                                <Grid item
                                    style={{ paddingRight: "0px" }}
                                    xs={11}
                                    sm={11}
                                    md={11}
                                    lg={11}
                                    xl={11}
                                >
                                    <FormLabel className="customLabelField">
                                        Assign Village
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            filterSelectedOptions
                                            className="customAutoComplete mt-8"
                                            options={this.state.AllVillageListData}
                                            getOptionLabel={(option: any) => option.name}
                                            value={this.state.selectedLocationArr}
                                            onChange={this.onFeLocationsChange}
                                            data-testid="assigneVillageTestid"
                                            ChipProps={{
                                                avatar: (
                                                    <Avatar
                                                        style={{
                                                            background: "#108B88",
                                                            color: "#fff",
                                                        }}
                                                    />
                                                ),
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    color="secondary"
                                                    variant="outlined"
                                                    placeholder={this.assignVillagePlaceholder()}
                                                />
                                            )}
                                            renderTags={(value, getTagProps) => {
                                                return value?.map((option, index) => (
                                                    <Chip
                                                        style={{
                                                            backgroundColor: "#F0EBE0",
                                                            color: "#1C2324",
                                                            fontSize: "16px",
                                                            fontWeight: 400,
                                                            lineHeight: "24px",
                                                        }}
                                                        key={option.id}
                                                        label={option.name}
                                                        avatar={this.villageAvatar(index, option)}
                                                        onDelete={(event: any) =>
                                                            this.handleVillageDelete(event, option)
                                                        }
                                                        deleteIcon={<img src={smallCloseIconSVG} />}
                                                        {...getTagProps({ index })}
                                                    />
                                                ));
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}
                                    style={{
                                        marginTop: "18px",
                                        padding: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <Box>
                                        <IconButton data-testid="iconBtnFE"
                                            onClick={(event) => {
                                                this.setState({
                                                    isVillageFilterOpen: true,
                                                    anchorElVillageFilter: event.currentTarget,
                                                });
                                            }}
                                        >
                                            <img src={imgIconFilter} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box className="modalSubmitFEButtonWrapper">
                                <Button fullWidth
                                    className="modalSubmitButton"
                                    type="submit"
                                    variant="contained"
                                    disabled={this.state.addFELoading}
                                >
                                    {this.getSubmitFormBtnTxt()}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </>
        )
    };
    AdminForm = () => {
        return (
            <>
                <Formik
                    data-testid="adminFormikTestid"
                    initialValues={this.state.adminInitialValues}
                    validationSchema={this.setAdminSchema()}
                    onSubmit={(values: any) => {
                        this.handleSubmitAdmin(values);
                    }}
                    validateOnChange
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                            autoComplete="off"
                            className="customFormSpacing"
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="firstnameAdminId" className="customLabelField">
                                        First Name
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter First Name"
                                            type="text"
                                            name="firstnameAdmin"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.firstnameAdmin}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "firstnameAdmin"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "firstnameAdmin"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="lastnameAdminId" className="customLabelField">
                                        Last Name
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Last Name"
                                            type="text"
                                            name="lastnameAdmin"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.lastnameAdmin}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "lastnameAdmin"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "lastnameAdmin"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel
                                        id="mobileNumberAdminId"
                                        className="customLabelField"
                                    >
                                        Mobile Number
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <Input fullWidth
                                            disableUnderline
                                            style={{
                                                background: "#FCFAF7",
                                                borderRadius: "12px",
                                                border: "1px solid #F0EBE0",
                                                marginTop: "8px",
                                                padding: "8px 8px 8px 0",
                                                height:"56px"
                                            }}
                                            type="text"
                                            className="custom-mobile-input"
                                            placeholder="Enter Mobile Number"
                                            name="mobileNumberAdmin"
                                            value={values.mobileNumberAdmin}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Box style={{
                                                        padding: "0 16px",
                                                        borderRight: "1px solid #F0EBE0",
                                                        marginRight: "8px"
                                                    }}
                                                    >
                                                        <p>+91</p>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "mobileNumberAdmin"
                                            )}
                                        />
                                        {this.getCustomErrorMessage(
                                            touched,
                                            errors,
                                            "mobileNumberAdmin"
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel
                                        id="dateOfBirthAdminId"
                                        className="customLabelField"
                                    >
                                        Date of Birth
                                    </FormLabel>
                                    <FormControl fullWidth style={{ marginTop: "8px" }}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                required
                                                className="customDatePicker"
                                                clearable
                                                data-testid="dateOfBirthAdminTestid"
                                                value={values.dateOfBirthAdmin}
                                                maxDate={moment().subtract(18, "years").toDate()}
                                                invalidDateMessage={`Invalid date`}
                                                placeholder="DD/MM/YYYY"
                                                onChange={(date) =>
                                                    setFieldValue("dateOfBirthAdmin", date)
                                                }
                                                format="DD/MM/yyyy"
                                                inputVariant="outlined"
                                                color="secondary"
                                                name="dateOfBirthAdmin"
                                                keyboardIcon={<DateRangeIcon style={webStyle.customDateIcon} />}
                                                error={this.getErrorBoolean(touched, errors, "dateOfBirthAdmin")}
                                                helperText={this.getHelperText(touched, errors, "dateOfBirthAdmin")}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container
                                justifyContent="center"
                                alignItems="center"
                                direction="row"
                                spacing={2}
                            >
                                <Grid item className="formfieldWrapper"
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                >
                                    <FormLabel id="emailAddressAdminId" className="customLabelField" >
                                        Email Address
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Email Id"
                                            type="email"
                                            name="emailAddressAdmin"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.emailAddressAdmin}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "emailAddressAdmin"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "emailAddressAdmin"
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="usernameAdminId" className="customLabelField">
                                        Username
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            placeholder="Enter Username"
                                            type="text"
                                            name="usernameAdmin"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.usernameAdmin}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "usernameAdmin"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "usernameAdmin"
                                            )}
                                            InputProps={{
                                                autoComplete: "new-username",
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    className="formfieldWrapper"
                                >
                                    <FormLabel id="passwordAdminId" className="customLabelField">
                                        Password
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            style={webStyle.customInputField}
                                            placeholder="Enter Password"
                                            type={this.getPasswordType()}
                                            name="passwordAdmin"
                                            variant="outlined"
                                            color="secondary"
                                            value={values.passwordAdmin}
                                            onChange={handleChange}
                                            error={this.getErrorBoolean(
                                                touched,
                                                errors,
                                                "passwordAdmin"
                                            )}
                                            helperText={this.getHelperText(
                                                touched,
                                                errors,
                                                "passwordAdmin"
                                            )}
                                            InputProps={{
                                                autoComplete: "new-password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-testid="showPasswordTestid"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showEyeIconPassword:
                                                                        !this.state.showEyeIconPassword,
                                                                });
                                                            }}
                                                            edge="end"
                                                        >
                                                            {this.getVisibilityIconPassword()}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="formfieldWrapper"
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                >
                                    <FormLabel id="confirmPasswordAdminId" className="customLabelField" >
                                        Confirm Password
                                    </FormLabel>
                                    <FormControl fullWidth>
                                        <TextField
                                            style={webStyle.customInputField}
                                            type={this.getConfirmPasswordType()}
                                            variant="outlined"
                                            color="secondary"
                                            placeholder="Enter Confirm Password"
                                            name="confirmPasswordAdmin"
                                            value={values.confirmPasswordAdmin}
                                            onChange={handleChange}
                                            error={
                                                (touched?.confirmPasswordAdmin ||
                                                    touched?.passwordAdmin) &&
                                                Boolean(errors?.confirmPasswordAdmin)
                                            }
                                            helperText={
                                                (touched.confirmPasswordAdmin ||
                                                    touched?.passwordAdmin) &&
                                                errors.confirmPasswordAdmin
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-testId="showConfirmPasswordTestid"
                                                            edge="end"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showEyeIconConfirmPassword:
                                                                        !this.state.showEyeIconConfirmPassword,
                                                                });
                                                            }}
                                                        >
                                                            {this.getVisibilityIconConfirmPassword()}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Box className="modalSubmitButtonWrapper">
                                <Button variant="contained" fullWidth
                                    data-testid="adminFormSubmitBtnTestid"
                                    className="modalSubmitButton"
                                    type="submit"
                                    disabled={this.state.addAdminLoading || this.state.updateAdminLoading || this.state.addSALoading || this.state.updateSALoading}
                                >
                                    {this.getSubmitFormBtnTxt()}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </>
        )
    };
    // USER FORMS ENDS
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { navigation, id } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <NavigationMenu navigation={navigation} id={id}>
                <ThemeProvider theme={themeSuperAdmin}>
                    {this.screenTitle()}
                    {this.profileSection()}
                    {this.superAdminProfileSection()}
                    <Box mt={5} sx={styleTable.tableWrapper}>
                        <Box mb={2} sx={styleTable.tableFilterWrapper}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item lg={4} md={5} sm={6}>
                                    <Typography className="list-user-title">
                                        {configJSON.AdminConsoleDefaults.LIST_ADMIN}
                                    </Typography>
                                </Grid>
                                <Grid item lg={8} md={7} sm={12} style={{ paddingRight: "0px" }}>
                                    <Box style={{ display: "flex", flexDirection: "row" }}>
                                        <Box
                                            style={{
                                                background: "#FCFAF7",
                                                border: "2px solid #F0EBE0",
                                                borderRadius: "28px",
                                                width: "100%",
                                                padding: "7px",
                                            }}
                                        >
                                            <Input
                                                type="search"
                                                name="searchInput"
                                                disableUnderline
                                                fullWidth
                                                autoComplete="off"
                                                className="tableSearchInputfilter"
                                                data-testid="tableInputSearchTestid"
                                                placeholder="Search by Name / Mobile number"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SearchIcon
                                                            style={{ color: "#1C2324", opacity: 0.48 }}
                                                        />
                                                    </InputAdornment>
                                                }
                                                onChange={(e: any) =>
                                                    this.setState({
                                                        searchAdminInputValue: e.target.value,
                                                        adminTableCurrentPage: 0,
                                                    }, () => {
                                                        this.debouncedSearchTable();
                                                    })
                                                }
                                                value={this.state.searchAdminInputValue}
                                            />
                                        </Box>
                                        <Box>
                                            <IconButton disabled={true} >
                                                <img src={imgIconFilter} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="table-grid-div" sx={styleTable.mainTable}>
                            {this.adminTable()}
                        </Box>
                    </Box>

                    {/* ADD-EDIT USER MODAL START */}
                    <Dialog
                        aria-labelledby="customized-dialog-title"
                        open={this.state.addUserModal}
                        maxWidth={"md"}
                        PaperProps={{
                            style: { borderRadius: 12, width: "712px" },
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <DialogTitle style={{padding:"32px 32px 19px 32px"}}>
                                    <Typography className="add-user-modal-title-txt">
                                        {this.getAddUserModalTitleTxt()}
                                    </Typography>
                                </DialogTitle>
                            </div>
                            <div style={{ marginRight: 20 }}>
                                <IconButton
                                    aria-label="close"
                                    data-testid="modalCloseBtnTestid"
                                    onClick={this.handleAddUserModalClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <DialogContent dividers className="modalBodyWrapper">
                            <Box className="userFormBodyWrapper">
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className="formfieldWrapper"
                                    >
                                        <FormLabel className="customLabelField">
                                            User Type
                                        </FormLabel>
                                        <FormControl fullWidth className="userTypeFormControl">
                                            <Select
                                                value={this.state.seletedUserTypeForm}
                                                variant="outlined"
                                                className="farmerFormDDClass"
                                                color="secondary"
                                                data-testid="createUserModalDropdown"
                                                onChange={(e: any) => {
                                                    this.resetFarmerFormValues()
                                                    this.resetFEFormValues()
                                                    this.resetAdminFormValues()
                                                    this.setState({ seletedUserTypeForm: e.target.value })
                                                }}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                readOnly={this.isEditUserModalOpen()}
                                                disabled={this.isEditUserModalOpen()}
                                            >
                                                <MenuItem value={"Farmer"}>{configJSON.FARMER}</MenuItem>
                                                <MenuItem value={"FieldExecutive"}>{configJSON.FIELD_EXECUTIVE}</MenuItem>
                                                <MenuItem value={"Admin"}>{configJSON.ADMIN}</MenuItem>
                                                {!this.state.isLoggedUserAdmin ? <MenuItem value={"SuperAdmin"}>{configJSON.SUPER_ADMIN}</MenuItem> : (<></>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {this.renderForms()}                                
                            </Box>
                        </DialogContent>
                    </Dialog>
                    {/* ADD-EDIT USER MODAL END */}
                    {/* DELETE MODAL FOR SUPER ADMIN*/}
                    <CustomModal
                        open={this.state.openSuperAdminDeletePopup}
                        title={configJSON.AdminConsoleDefaults.deleteUserTitle}
                        description={configJSON.AdminConsoleDefaults.deleteUserDescription}
                        confirmHandler={this.deleteSuperAdmin}
                        confirmTitle={this.state.deleteSALoading ? "Deleting..." : "Delete"}
                        onClose={this.handleDeletePopupClose}
                        disableConfirmHandler={this.state.deleteSALoading}
                    />
                    {/* DELETE MODAL FOR TABLE*/}
                    <CustomModal
                        open={this.state.openDeletePopup}
                        title={configJSON.AdminConsoleDefaults.deleteUserTitle}
                        description={configJSON.AdminConsoleDefaults.deleteUserDescription}
                        confirmHandler={this.handleDeleteUser}
                        confirmTitle={this.state.deleteAdminLoading ? "Deleting..." : "Delete"}
                        onClose={this.handleDeletePopupClose}
                        disableConfirmHandler={this.state.deleteAdminLoading}
                    />
                    {/* ERROR-MODAL STARTS */}
                    <ErrorModal
                        open={this.state.errorPopupOpen}
                        errorTitle="Error"
                        errorSubTitle={this.state.errorPopupMsg}
                        BtnTitle="Okay"
                        handleBtnClick={this.handleErrorPopupClose}
                    />
                    {/* ERROR-MODAL ENDS */}

                    {/* FILTER-ASSIGN VILLAGE STARTS */}
                    <AssignVillageFilter
                        isVillageFilterOpen={this.state.isVillageFilterOpen}
                        anchorElVillageFilter={this.state.anchorElVillageFilter}
                        handleSubmitAccordion={() => this.handleSubmitAccordion()}
                        handleVillageFilterClear={() => this.handleVillageFilterClear()}
                        closeVillageFilter={() => this.closeVillageFilter()}
                        isAccordianError={this.state.isAccordianError}
                        isDisabled={this.state.isDisabled}
                        expanded={this.state.expanded}
                        handleChangePanelState={this.handleChangePanel("panel1")}
                        handleChangePanelDistrict={this.handleChangePanel("panel2")}
                        handleChangePanelTaluka={this.handleChangePanel("panel3")}
                        handleChangePanelVillage={this.handleChangePanel("panel4")}
                        opacityStateCalculator={this.opacityStateCalculator}
                        opacityDistrictCalculator={this.opacityDistrictCalculator}
                        opacityTalukaCalculator={this.opacityTalukaCalculator}
                        opacityVillageCalculator={this.opacityVillageCalculator}
                        handleChangeStateField={this.handleChangeStateField}
                        handleChangeDistrictField={this.handleChangeDistrictField}
                        handleChangeTalukaField={this.handleChangeTalukaField}
                        handleChangeVillageField={this.handleChangeVillageField}
                        selectedState={this.state.selectedState}
                        stateInputValue={this.state.stateInputValue}
                        handleStateSearch={this.handleStateSearch}
                        filteredStates={this.state.filteredStates}
                        selectedDistrict={this.state.selectedDistrict}
                        districtInputValue={this.state.districtInputValue}
                        handleDistrictSearch={this.handleDistrictSearch}
                        filteredDistricts={this.state.filteredDistricts}
                        selectedTaluka={this.state.selectedTaluka}
                        talukaInputValue={this.state.talukaInputValue}
                        handleTalukaSearch={this.handleTalukaSearch}
                        filteredTalukas={this.state.filteredTalukas}
                        selectedVillage={this.state.selectedVillage}
                        villageInputValue={this.state.villageInputValue}
                        handleVillageSearch={this.handleVillageSearch}
                        filteredVillages={this.state.filteredVillages}
                    />
                    {/* FILTER-ASSIGN VILLAGE ENDS */}
                    {/* SUCCESS-MODAL STARTS */}
                    <ErrorModal
                        open={this.state.successModalOpen}
                        errorTitle="Success"
                        errorSubTitle={this.state.successModalMsg}
                        BtnTitle="Okay"
                        handleBtnClick={this.handleCloseSuccessModal}
                    />
                    {/* SUCCESS-MODAL ENDS */}
                </ThemeProvider>
            </NavigationMenu>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const webStyle = {
    center: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        alignItem: "center",
    },
    contentCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    customDateIcon: {
        color: "#1C2324",
        opacity: "0.48",
    },
    hide: {
        display: "none",
    },
    customInputField: {
        margin: "8px 0 0 0",
    },

    photoCamera: {
        width: 60,
        height: 60,
        color: "#1C2324",
        opacity: 0.5,
    },
    badgeButton: {
        backgroundColor: "rgba(28, 35, 36, .56)",
        padding: "0.375rem",
    },
    badgeCardRoot: {
        padding: "0",
        marginTop: "8px",
        marginBottom: "20px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "12px",
        minHeight: "150",
    },
}
const styleProfile = {
    addUserModalBtnStyle: {
        height:"56px",
        width: "185px",
        borderRadius: "28px",
        fontWeight: 700,
        lineHeight: "28px",
        display: "flex",
        alignItems: "center",
        background: "#DF5200",
        fontSize: "18px",
        color: "#fff",
        padding: "14px 32px",
        boxShadow: "none",
    } as React.CSSProperties,
    profileIconButton: {
        backgroundColor: "#F0EBE0",
        borderRadius: "50%",
    } as React.CSSProperties,
    avatarContainer: {
        padding: "0.5rem",
        width: "7rem",
        objectFit: "cover",
        height: "6.5rem"
    } as React.CSSProperties,
    profileAvatar: {
        width: "6.25rem",
        height: "6.25rem",
        backgroundColor: "#108B88",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "3rem",
        lineHeight: "3.5rem",
        textTransform: "uppercase",
        color: "#FFFFFF",
    } as React.CSSProperties,
    avatarTypographyTitle: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        maxWidth: "100%",
        lineHeight: "30px",
        color: "#1C2324",
        wordWrap: "break-word",
    } as React.CSSProperties,
    functionButtonContainer: {
        display: "flex",
        gap: "1rem",
        justifyContent: "flex-end",
        marginTop: "1rem",
    } as React.CSSProperties,
}


const styleTable = {
    tableWrapper: {
        background: "#F8F6F1",
        border: "2px",
        borderRadius: "12px",
        marginTop: "24px"
    },
    tableFilterWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: "24px 12px 24px 24px",
    },
    mainTable: {
        height: "583px",
    },
};
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {
  InputProps
} from "@material-ui/core";
import { OnProgressProps } from "react-player/base";
import firebase from "firebase"
import { IParticipants } from "../../../components/src/interface.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    is_default_chat: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    json_message_histories?: any[];
    participants_count: number;
    messages: IMessage[];
    group_profile_image: string;
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

type lastMessageTypes=string|number|null
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  readUnreadmessage: any;
  getChatList: ({ chat_id, data ,isUpdateCount,isUpdateOnly}: { chat_id: number; data: any,isUpdateCount:boolean ,isUpdateOnly?:boolean}) => void;
  accountId: string;
  updateList: ({ chat_id, data }: { chat_id: number; data: any }) => void;
  lastMessageId?:lastMessageTypes;
  isMobile?: boolean;
  goBack: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  chatId: string;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  messagesData: TMessageElement[];
  isVisibleModal: boolean;
  isVisibleSearchModel: boolean;
  isVisiblePreviewModal: boolean;
  isRecording: boolean;
  showRecordingMic: boolean;
  audioBlob: Blob | null;
  recordingDuration: number;
  imageUrl: string;
  videoURL: any;
  video: any;
  videoFileMaxSize: boolean;
  document: any;
  webcamUrl: string;
  openWebcam: boolean;
  cameraDenied: boolean;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  searchInputValue: string;
  permission: boolean;
  stream: any;
  recordingStatus: string;
  audioChunks: Blob[];
  audio: string | null;
  showPicker: boolean;
  chatMediadata: any;
  record_voice: boolean;
  disable_send_button: boolean;
  playing: boolean;
  playTime: number;
  attachment_file_type: string;
  lastUnreadId: number;
  user: Record<"first_name" | "last_name" | "email" | "initials" | "id", string>,
  profileImg: any,
  error: string,
  scrollController: number,
  cropCalendarData: any,
  isCropCalendar: boolean,
  expandCropCalendarInputDetails: boolean,
  isNewsLink: boolean,
  isFaqLInk: boolean,
  isLibrariesLink: boolean,
  errorModalOpen: boolean;
  errorModalMsg: string;
  isSurveyDeatilsLInk: boolean,
  isSurveyLInk: boolean,
  showLinkObj: any,
  isFarmLInk: boolean,
  farmType: string,
  isProfileImgUploading: boolean
  addParticipantModal: boolean
  chatList: any
  searchText: string
  page: number
  isLoading: boolean
  selectedParticipant: any,
  isAddingUser: boolean
  removeUserModal: boolean,
  isUserRemoving: boolean,
  selectedUser: any,
  isEditGroupName: boolean,
  chatName: string | undefined,
  apiError: string | null,
  cropImageModal: boolean,
  groupProfileImageUrl: string,
  showAttachmentOptions: boolean,
  played: any,
  isNotifyLInk: boolean,
  notiFyType: string,
  chatViewPage: number,
  isChatDetailsLoading: boolean,
  messageAdding: boolean,
  // NEW
  groupMembersLoading: boolean,
  groupMembers: IParticipants[]
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export type TMessageAccount = {
  "id": number,
  "first_name": string,
  "last_name": string,
  "full_phone_number": string,
  "country_code": number,
  "phone_number": number,
  "email": string,
  "activated": boolean,
  "device_id": null | string,
  "unique_auth_id": string,
  "password_digest": string,
  "created_at": string,
  "updated_at": string,
  "user_name": string,
  "platform": null | string,
  "user_type": null | string,
  "app_language_id": null | number,
  "last_visit_at": null | string,
  "is_blacklisted": boolean,
  "suspend_until": null | string,
  "status": string,
  "stripe_id": null | number,
  "stripe_subscription_id": null | number,
  "stripe_subscription_date": null | number,
  "role_id": number,
  "full_name": null | string,
  "gender": string,
  "date_of_birth": string,
  "age": null | number,
  "is_paid": boolean,
  "middle_name": string,
  "number_belongs_to_id": number,
  "aadhaar_number": string,
  "total_family_members": number,
  "highest_education_id": number,
  "village_id": number,
  "mobile_type_id": number,
  "state_id": number,
  "district_id": number,
  "taluka_id": number,
  "fe_update_count": number,
  "farmer_update_count": number,
  "assigned_fe_id": number,
  "location": string,
  "user_id": string,
  "mobile_device_id": null | number,
  "mobile_device": string,
  "admin_session_control_id": string,
  "is_fe_exclusive": boolean
}

export type TMessageAdmin = {
  "id": number,
  "email": string,
  "created_at": string,
  "updated_at": string,
  "role": string,
  "first_name": string,
  "last_name": string,
  "phone_number": string,
  "dob": string,
  "user_name": string,
  "google_unique_auth_id": any,
  "google_type": boolean,
  "full_phone_number": string,
  "country_code": number,
  role_id: number
}

export type TMessageElement = {
  date: string,
  messagelist: TMessageList[]
}

export type TMessageList = {
  "id": string,
  "type": string,
  "attributes": {
    "attachment"?: any;
    "id": number,
    "message": string,
    "account_id": any,
    "admin_user_id": number | null,
    "chat_id": number,
    "created_at": string,
    "updated_at": string,
    "attachment_file_type": any,
    "is_mark_read": boolean,
    "is_mark_delivered":boolean,
    "deleted": boolean,
    "reply_message_user_id": any,
    "reply_message_id": any,
    "reply_message_admin_id": any,
    "account": null | TMessageAccount,
    "admin_user": null | TMessageAdmin,
    "attachments"?: any,
    "participants": any,
    "link_share": string
  }
}

export default class ChatViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  fetchChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  updatedeliverMessageApiCallId:string="";
  updateProfileImg: string = "";
  refreshChatInterval: unknown;
  scrollViewRef: React.RefObject<any>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  mediaRecorder: any;
  adminProfileCallId: string = '';
  ID: boolean = false;
  mimeType: string = "audio/webm";
  removeChatUserId: string = ""
  fetchCropCalendarApiCallId: string = ""
  fetchNewsAndEventApiCallId: string = ""
  fetchLibrariesApiCallId: string = ""
  fetchFaqsApiCallId: string = ""
  fetchSurveyApiCallId: string = ""
  fetchSurveyDetailsApiCallId: string = ""
  fetchFarmDiaryEntriesApiCallId: string = ""
  getChatListApiCallId: string = ""
  addChatUsersId: string = ""
  fetchNotificationApiCallId: string = ""
  attachmentOptionsRef: React.RefObject<HTMLDivElement>;
  notifCallId: any
  webcamRef: any;
  boxRef: any;
  timerInterval: any = null;
  messageInputRef: React.RefObject<any>;
  getParticipantsCallId: string = "" // GET PARTICIPANTS
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
      chatId: "",
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      messagesData: [],
      isVisibleModal: false,
      isVisibleSearchModel: false,
      isVisiblePreviewModal: false,
      isRecording: false,
      showRecordingMic: true,
      audioBlob: null,
      recordingDuration: 0,
      imageUrl: "",
      videoURL: null,
      video: null,
      videoFileMaxSize: false,
      document: null,
      webcamUrl: "",
      openWebcam: false,
      cameraDenied: false,
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      searchInputValue: "",
      permission: false,
      recordingStatus: "inactive",
      stream: null,
      audioChunks: [],
      audio: null,
      showPicker: false,
      chatMediadata: null,
      record_voice: false,
      disable_send_button: false,
      playing: false,
      playTime: 0,
      attachment_file_type: '',
      lastUnreadId: 0,
      user: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        initials: ""
      },
      profileImg: {},
      error: "",
      scrollController: 0,
      cropCalendarData: {},
      isCropCalendar: false,
      expandCropCalendarInputDetails: false,
      isNewsLink: false,
      isFaqLInk: false,
      isLibrariesLink: false,
      showLinkObj: {},
      errorModalOpen: false,
      errorModalMsg: "",
      isSurveyDeatilsLInk: false,
      isSurveyLInk: false,
      isFarmLInk: false,
      farmType: '',
      isProfileImgUploading: false,
      addParticipantModal: false,
      chatList: [],
      searchText: "",
      page: 1,
      isLoading: false,
      selectedParticipant: [],
      isAddingUser: false,
      removeUserModal: false,
      isUserRemoving: false,
      selectedUser: null,
      isEditGroupName: false,
      chatName: "",
      apiError: null,
      cropImageModal: false,
      groupProfileImageUrl: "",
      showAttachmentOptions: false,
      played: null,
      isNotifyLInk: false,
      notiFyType: '',
      chatViewPage: 1,
      isChatDetailsLoading: false,
      messageAdding: false,
      // PARTICIPANTS
      groupMembersLoading: false,
      groupMembers: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.mediaRecorder = React.createRef();
    this.attachmentOptionsRef = React.createRef();
    this.webcamRef = React.createRef();
    this.messageInputRef=React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let messaging = firebase.messaging()
    this.notifCallId = messaging.onMessage(this.handleIncomingMessage);
    this.getAdmin();
    document.addEventListener('click', this.handleOutsideClick);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevProps.id != this.props.id) {
      this.setState({ chatId: this.props.id, chatData: null, chatViewPage: 1, messagesData: [] })
      setTimeout(() => this.getChatDetails(this.props.id), 100)
      this.getParticipantsByChatId(this.props.id)
      this.messageInputRef.current?.focus()
      this.hideVoiceRecord();
      this.hideModal();
    }

    if (prevProps.lastMessageId !== this.props.lastMessageId) {
      this.markReadMessage(this.props.lastMessageId as number)
    }

    if (prevState.addParticipantModal != this.state.addParticipantModal && this.state.chatList.length == 0) {
      this.setState({ isLoading: true })
      this.getChatUserList()
    }
  }

  async componentWillUnmount() {
    if (this.notifCallId) {
      this.notifCallId()
    }
    document.removeEventListener('click', this.handleOutsideClick);
  }

  // async componentWillUnmount() {
  //   clearInterval(this.refreshChatInterval as number);
  // }

  showEmojiPicker = () => {
    this.setState(prevState => ({ showPicker: !prevState.showPicker }))
  }

  closePicker = () => {
    this.setState(prevState => ({ showPicker: false }))
  }

  onEmojiClick = (event: any, emojiObject: any) => {
    this.setState((prevState) => ({ message: prevState.message + emojiObject.emoji }));
  };

  searchScroll = (arg: any) => {
    let scrollController: number;
    if (arg) {
      scrollController = this.state.scrollController + 1
      this.setState(prev => ({ scrollController }));
    } else {
      scrollController = this.state.scrollController < 0 ? 0 : this.state.scrollController - 1;
      this.setState(prev => ({ scrollController }));
    }
    this.scrollOptionService(1);
  };

  scrollOptionService = (id?: number) => {
    if (!id) {
      let items = document.querySelectorAll("#message_identifier");
      let lastElement = items[0];
      if (items.length) {
        lastElement.scrollIntoView({ behavior: 'auto' });
      }
    } else {
      let items = document.querySelectorAll("#message_identifier_active");
      let selectedElement = items[this.state.scrollController];
      if (items.length && selectedElement) {
        selectedElement.scrollIntoView(false);

      } else {
        this.setState({ scrollController: 0 });
      }
    }

  };


  showModal = () => {
    if(!this.state.isChatDetailsLoading){
      this.setState({ isVisibleModal: true },()=>{
        window.scrollTo({ top: 0})
      });
    }
  }

  handleShareLinkPopup = (linkType?: any, subActivityProgressId?: any, linkId?: any, accountId?: any, type?: any) => {
    console.log("HANDLE SHARE LINK POPUP", linkType);
    switch (linkType) {
      case 'newsandevents':
        this.setState({
          isNewsLink: false
        })
        this.getNewsAndEventDetails(linkId)
        break;
      case 'cropcalendar':
        console.log("I AM IN CROP CALENDAR", subActivityProgressId)
        this.setState({
          isCropCalendar: false
        })
        this.getCropCalendarModalDetails(subActivityProgressId);
        break;
      case 'materrapedialibraries':
        this.setState({
          isLibrariesLink: false
        })
        this.getLibraryDetails(linkId)
        break;
      case 'materrapediafaq':
        this.setState({
          isFaqLInk: false
        })
        this.getFaqsDetails(accountId)
        break;
      case 'surveydetails':
        this.setState({
          isSurveyDeatilsLInk:false
        })
        this.getSurveyDetails(linkId, accountId)
        break;
      case 'survey':
        this.setState({
          isSurveyLInk: false
        })
        this.getSurvey(linkId)
        break;
      case 'farmdairy':
        this.setState({
          farmType: type,
          isFarmLInk: false
        })
        this.getFarmDiaryEntries(linkId, type)
        break;
      case 'notification':
        this.setState({
          isNotifyLInk: false,
          notiFyType:type
        })
        this.getNotification(linkId, type)
        break;
    }
  }

  handleErrorModalClose = () => {
    this.setState({
      isFarmLInk: false,
      isFaqLInk: false,
      isSurveyLInk: false,
      isSurveyDeatilsLInk: false,
      isNewsLink: false,
      isCropCalendar: false,
      isLibrariesLink: false,
      isNotifyLInk: false,
      errorModalOpen: false,
      errorModalMsg: "",
    });
  };

  showPicker = (showPicker: any) => {
    return showPicker ? `chat-textarea chat-textarea-flag` : `chat-textarea`
  }

  addChatActionClass = () => {
    return this.state.imageUrl ? 'attachment_form' : ''
  }

  hideModal = () => {
    this.setState({ isVisibleModal: false, isEditGroupName: false, chatName: this.state.chatData?.attributes?.name });
  }

  showSearchModal = () => {
    this.setState({ isVisibleSearchModel: true });
  }

  hideSearchModal = () => {
    this.setState({ isVisibleSearchModel: false });
    this.setState({ searchInputValue: "" });
  }

  searchMessageInputValue = (event: any) => {
    const searchInputValue = event.target.value;
    this.setState({ searchInputValue }, () => {
      if (!searchInputValue) return;
      let items = document.querySelectorAll("#message_identifier_active");
      const id = items.length > 0 ? items.length - 1 : items.length;
      this.setState({ scrollController: id }, () => this.scrollOptionService(1));
    });
  }

  filteredMessages() {
    const groupByDate = this.state?.chatData?.attributes?.messages.reduce((dates: any, message) => {
      const date = message.attributes?.created_at?.split('T')[0];
      if (!dates[date]) {
        dates[date] = [];
      }
      dates[date].push(message)
      return dates
    }, {})

    const groupArrays = Object.keys(groupByDate).map(date => {
      return {
        date,
        messagelist: groupByDate[date]
      }
    })
    this.setState({ messagesData: [...groupArrays]});
    if(this.state.chatViewPage ==2){
      this.scrollOptionService();
    }
  }

  handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const message = event.target.value;
    this.setState({ message });
  };

  handleProgress = (state: OnProgressProps) => {
    this.setState({
      played: state.played
    })
  }

  capture = () => {
    const imageSrc = this.webcamRef.current?.getScreenshot();
    this.setState({
      webcamUrl: imageSrc,
      attachment_file_type: 'webcam_image',
      audioBlob: null
    });
  };

  handleCameraDenied = () => {
    this.setState({ cameraDenied: true });
  }

  retakeImage = (event: any) => {
    event?.preventDefault();
    this.setState({ webcamUrl: '', attachment_file_type: '', audioBlob: null });
  };

  openWebCamera = () => {
    this.setState({ openWebcam: true, audioBlob: null });
  }

  closeMaxVideoSizeModal = () => {
    this.setState({ videoFileMaxSize: false })
  }

  handleVideoUpload = (event: any, fileType?: string) => {
    const file = event.target.files[0];
    if (file?.type.startsWith('video/')) {
      fileType = 'video'
    }

    const maxSizeInBytes = 250 * 1024 * 1024; // 10 MB
    if (file && file.size > maxSizeInBytes) {
      this.setState({ videoFileMaxSize: true })
      return;
    }

    this.setState({
      video: file,
      videoURL: URL.createObjectURL(file),
      attachment_file_type: fileType as string,
      imageUrl: "",
      docRes: null,
      document: null,
      webcamUrl: '',
      audioBlob: null
    });
  }

  handleVoiceUpload = () => {
    const file = new File(this.state.audioChunks, 'recorded_voice.ogg', { type: 'audio/ogg' });
    setTimeout(() => {
      this.state.audioBlob && this.setState({ video: file });
    }, 51);
  }

  handleDocumentUpload = (event: any) => {
    const file = event.target.files[0];
    let fileType;
    if (file?.type.startsWith('application/') || file?.type.startsWith('text/')) {
      fileType = 'application'
    }

    this.setState({
      document: file, video: '',
      attachment_file_type: fileType as string,
      videoURL: '', imageUrl: '', docRes: null,
      webcamUrl: '', audioBlob: null
    });
  }

  startAudioRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event: any) => {
          if (event.data.size > 0) {
            this.state.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.state.audioChunks, { type: 'audio/ogg' });
          const audioFile = new File([audioBlob], 'recorded_audio.ogg', { type: 'audio/ogg' });
          this.setState({ video: audioFile, audioBlob: audioBlob, disable_send_button: false });
          this.handleVoiceUpload();
        };

        this.mediaRecorder.start();
        // Start the recording timer
        this.timerInterval = setInterval(() => {
          this.setState((prevState) => ({
            recordingDuration: prevState.recordingDuration + 1
          }));
        }, 1000);

        this.setState({
          isRecording: true,
          attachment_file_type: 'voice',
          disable_send_button: true
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  stopAudioRecording = () => {
    clearInterval(this.timerInterval);
    if (this.mediaRecorder && this.state.isRecording) {
      this.mediaRecorder.stop();
      this.setState({
        isRecording: false,
        showRecordingMic: false,
        audioBlob: null,
        recordingDuration: 0,
        audioChunks: [],
        disable_send_button: false
      });
    }
  };

  handleFileUpload = (event: any) => {
    if (event.target.files?.length !== undefined) {
      let fileType = event.target.files[0].type;
      if (fileType.startsWith('video/')) {
        this.handleVideoUpload(event, 'video')
      } else if (fileType.startsWith('audio/')) {
        this.handleVideoUpload(event, 'audio');
      } else if (fileType.startsWith('application/') || fileType.startsWith('text/')) {
        this.handleDocumentUpload(event);
      } else {
        console.log(fileType);
        this.setState({ attachment_file_type: 'image' })
        const file = event.target.files[0] as Blob;
        let reader = new FileReader();
        reader.onload = (readerEvent) => {
          this.setState({ imageUrl: readerEvent.target?.result as string, docRes: file, document: null, audioBlob: null, webcamUrl: '' });
        };
        reader.readAsDataURL(file);
        this.setState({ docRes: file, videoURL: null, video: null, document: null, webcamUrl: '', audioBlob: null });
      }
    }
    else {
      this.setState({ imageUrl: "", docRes: null, video: null, videoURL: null, document: null, webcamUrl: '', audioBlob: null })
    }
  };

  handleSendMessage = () => {
    const { message, video, imageUrl, document, chatId, webcamUrl } = this.state;
    const anyFile = video || imageUrl || document || webcamUrl;

    (message || anyFile) && this.sendChatMessage(chatId, message, anyFile);
    this.setState({
      message: "",
      imageUrl: "",
      video: null,
      videoURL: null,
      webcamUrl: '',
      openWebcam: false,
      isVisiblePreviewModal: false,
      showPicker: false,
      attachment_file_type: '',
      document: null,
      recordingDuration: 0,
      showRecordingMic: true,
      record_voice: false,
      audioBlob: null,
      audioChunks: []
    });
  };

  getChatDetails = (chatId: string | number) => {
    if (!chatId) return;
    this.setState({ isChatDetailsLoading: true })
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatListApiEndPoint}/${chatId}?page=${this.state.chatViewPage}`
      // `${configJSON.getChatListApiEndPoint}/454`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createFormImg(image: any) {
    const formdata: FormData = new FormData();
    formdata.append('chat[logo]', image[0]);

    this.setState({ profileImg: formdata });
  }

  generateUniqueName = () => {
    const timestamp = new Date().getTime();
    return `cropped_image_${timestamp}.png`;
  };

  uploadGroupProfileImg = (cropperRef: any) => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob: any) => {
        this.uploadGroupProfileImage(blob)
      })
    }
  }

  uploadGroupProfileImage = async (logo: any) => {
    this.setState({ isProfileImgUploading: true })
    const header = {
      token: this.state.token,
    };

    let reqFormdata = new FormData();
    reqFormdata.append("chat[logo]", logo, this.generateUniqueName())
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileImg = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateProfileImage}?chat_id=${this.state.chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (chatId: string, message: string, file?: any) => {
    const header = {
      token: this.state.token
    };
    let attachment_file_type;
    if (this.state.attachment_file_type === 'webcam_image') {
      attachment_file_type = 'image';
    } else if (this.state.attachment_file_type === 'voice') {
      attachment_file_type = 'audio';
    } else {
      attachment_file_type = this.state.attachment_file_type;
    }

    let formData = new FormData();
    formData.append("data[attributes][chat_id]", chatId);
    formData.append("data[attributes][reply_message_id]", "");
    formData.append("data[attributes][reply_message_user_id]", "");
    formData.append("data[attributes][message]", message.trim());
    if (file) {
      console.log(attachment_file_type)
      formData.append("data[attributes][attachment]", file);
      formData.append("data[attributes][attachment_file_type]", attachment_file_type);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOutsideClick = (event: any) => {
    if (
      this.attachmentOptionsRef.current &&
      !this.attachmentOptionsRef.current.contains(event.target) &&
      !event.target.closest('.attachment-button')
    ) {
      // Click occurred outside the attachment options, so close it
      this.setState({
        showAttachmentOptions: false,
        video: null,
        videoURL: "",
        attachment_file_type: "",
        webcamUrl: '',
        document: null,
        openWebcam: false
      });
    }
  };

  toggleAttachmentOptions = () => {
    setTimeout(() => {
      this.setState((prevState) => ({
        showAttachmentOptions: !prevState.showAttachmentOptions,
      }));
    }, 49);
  };

  removeMediaAttached = () => {
    this.setState({
      showAttachmentOptions: false,
      video: null,
      videoURL: "",
      attachment_file_type: "",
      imageUrl: "",
      docRes: null,
      document: null,
      openWebcam: false,
      webcamUrl: '',
      audioBlob: null,
      disable_send_button: false
    });
  }

  ifAnyMediaAttached = () => {
    return this.state.videoURL || this.state.imageUrl || this.state.document || this.state.openWebcam || this.state.webcamUrl;
  }

  ifAnyMediaUrl = () => {
    return this.state.videoURL || this.state.imageUrl || this.state.document || this.state.webcamUrl || this.state.message.trim();
  }

  openVoiceRecord = () => {
    this.setState({ record_voice: true, disable_send_button: true })
  }

  hideVoiceRecord = () => {
    this.setState({ record_voice: false })
  }


  startRecording = async () => {
    this.setState({ recordingStatus: "active" })
  }

  stopRecording = () => {
    this.setState({ recordingStatus: 'inactive' });
  }

  deleteRecording = () => {
    clearInterval(this.timerInterval);
    if (this.mediaRecorder && this.state.isRecording) {
      this.mediaRecorder.stop();
    }
  
    // Reset recording-related state
    this.setState({
      record_voice: false,
      isRecording: false,
      showRecordingMic: true,
      audioBlob: null,
      recordingDuration: 0,
      audioChunks: [],
      video: null,
      disable_send_button: false
    });
  };

  getAdmin() {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adminProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adminGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }

  updateProfileData(data: any) {
    const { first_name, last_name, email } = data.attributes
    const initials: string = first_name[0] + last_name[0]
    this.setState(
      (prev) => {
        return {
          ...prev,
          user: {
            ...prev.user,
            email,
            first_name,
            last_name,
            id: data.id,
            initials: initials.toUpperCase()
          }
        };
      }
    );
  }

  apiRequestCallGetChatCallResponse(responseChatData: any) {
    let chatData = { ...responseChatData };
    if (this.state.chatData && !this.state.messageAdding) {
      let data = { ...this.state.chatData }
      data["attributes"]["messages"] = [...data.attributes.messages, ...chatData.attributes.messages]
      chatData = { ...data }
    }
    const chatMediadata = chatData?.attributes?.messages?.filter((ele: any) => ele?.attributes?.attachments?.url);
    let messageId = chatData?.attributes?.messages[chatData?.attributes?.messages?.length - 1]
    const chatId = chatData?.id
    this.setState({
      chatId,
      chatData,
      chatMediadata,
      lastUnreadId: messageId?.attributes?.id,
      chatName: chatData?.attributes?.name,
      message: "",
      video: null,
      videoURL: "",
      attachment_file_type: "",
      imageUrl: "",
      webcamUrl: "",
      isChatDetailsLoading:false,
      chatViewPage:!this.state.messageAdding ? this.state.chatViewPage+1 :this.state.chatViewPage,
      messageAdding:false
    });
    this.props.updateList({ chat_id: +chatId, data: chatData })
    this.filteredMessages();
  }


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

      switch (apiRequestCallId) {
        case this.adminProfileCallId:
          if (successResponse.data) {
            this.updateProfileData(successResponse.data);
          } else {
            this.setState({
              error: "Something went wrong",
              // loading: false
            });
          }
          break;
        case this.fetchChatApiCallId:
          this.getChatApiCall({ successResponse, errorResponse });
          break;
          case this.sendMessageApiCallId:
            this.sendMessageApiCall({ successResponse, errorResponse });
          break; 
          case this.updateProfileImg:
            this.updateProfileImageApiCall({ successResponse, errorResponse });
          break;   
          case this.removeChatUserId:
            this.updateRemoveUser({ successResponse, errorResponse })
          break; 
          case this.fetchCropCalendarApiCallId:
            this.getCropCalendarApiCall({ successResponse, errorResponse,key:"isCropCalendar" });
          break;  
          case this.fetchNewsAndEventApiCallId:
            this.getShowLinkApiCall({ successResponse, errorResponse,key:"isNewsLink" });
          break;   
          case this.fetchLibrariesApiCallId:
            this.getShowLinkApiCall({ successResponse, errorResponse ,key:"isLibrariesLink"});
          break;   
          case this.fetchFaqsApiCallId:
            this.getShowLinkApiCall({ successResponse, errorResponse ,key:"isFaqLInk"});
          break;   
          case this.fetchSurveyApiCallId:
            this.getShowLinkApiCall({ successResponse, errorResponse ,key:"isSurveyLInk"});
          break;             
          case this.fetchSurveyDetailsApiCallId:
            this.getSurveyDetailsLinkApiCall({ successResponse, errorResponse,key:"isSurveyDeatilsLInk"});
          break;   
          case this.fetchFarmDiaryEntriesApiCallId:
            this.getShowLinkApiCall({ successResponse, errorResponse,key:"isFarmLInk" });
          break; 
          case this.addChatUsersId:
            this.updateRemoveUser({ successResponse, errorResponse });
          break;   
          case this.getChatListApiCallId:
            this.getChatListUserCall({ successResponse, errorResponse })
          break;  
          case this.fetchNotificationApiCallId:
            this.getShowLinkApiCall({ successResponse, errorResponse,key:"isNotifyLInk" });
          break; 
          case this.getParticipantsCallId:
            this.RespGetParticipantsCallId(successResponse)
          break; 
      }
  }

  getChatApiCall({ successResponse, errorResponse }: any) {
    if (successResponse?.errors) {
      const [error] = successResponse.errors
      this.setState({
        error: error,
        isChatDetailsLoading: false
      });
    } else {
      let data: any = successResponse.data
      if (data) {
        this.apiRequestCallGetChatCallResponse(data);
      } else {
        this.setState({
          error: 'Something went wrong, data not found',
          isChatDetailsLoading: false
          // loading: false
        });
      }
    }
  }

  getShowLinkApiCall({ successResponse, errorResponse ,key}: any) {
    if (successResponse.errors) {
      const error = successResponse.errors
      this.setState({
        errorModalMsg: error,
        errorModalOpen: true,
        showLinkObj: {}
      });
    } else {   
      let data: any = successResponse.data
      if (data) {
        this.setState((prevState:any)=>({...prevState,[key]:true, showLinkObj:data?.attributes}))    
      } else {
        this.setState({
          errorModalMsg: 'Something went wrong, data not found',
          errorModalOpen: true,
          showLinkObj: {}
        });
      }
    }
  }

  handleExpandDetails = () => {
    this.setState(prevState => ({ expandCropCalendarInputDetails: !prevState.expandCropCalendarInputDetails }));
  }

  getCropCalendarApiCall({ successResponse, errorResponse ,key}: any) {
    if (successResponse.errors) {
      const [error] = successResponse.errors
      this.setState({
        errorModalMsg: error,
        errorModalOpen: true,
        cropCalendarData: {}
      });
    } else {
      let data: any = successResponse.data
      this.setState({
        isFarmLInk: true
      })
      if (data) {
        if(data.attributes.before_after_day < 0){
          data.attributes.before_after_day = -1 * data.attributes.before_after_day;
        }
        this.setState((prevState:any)=>({...prevState,[key]:true, showLinkObj:data?.attributes}))    
      } else {
        this.setState({
          errorModalMsg: 'Something went wrong, data not found',
          errorModalOpen: true,
          showLinkObj: {}
        });
      }
    }
    console.log(this.state.isCropCalendar, this.state.showLinkObj)
  }

  getSurveyDetailsLinkApiCall({ successResponse, errorResponse ,key}: any) {
    if (successResponse.errors) {
      const [error] = successResponse.errors
      this.setState({
        errorModalMsg: error,
        errorModalOpen: true,
        showLinkObj: {}
      });
    } else {
      let data: any = successResponse.data
      if (data) {
        this.setState((prevState:any)=>({...prevState,[key]:true, showLinkObj:data}))     
      } else {
        this.setState({
          errorModalMsg: 'Something went wrong, data not found',
          errorModalOpen: true,
          showLinkObj: {}
        });
      }
    }
  }

  sendMessageApiCall({ successResponse, errorResponse }: any) {
    if (successResponse.errors) {
      const [error] = successResponse.errors
      this.setState({
        error: error.token,
        // loading: false,
        // form_loading: false,
        // button_loader: false
      });

    } else {
      let data: {
        "id": number,
        "message": string,
        "account_id": null | string,
        "admin_user_id": number,
        "chat_id": number,
        "created_at": string,
        "updated_at": string,
        "attachment_file_type": null | string,
        "is_mark_read": boolean,
        "deleted": boolean,
        "reply_message_user_id": null | string,
        "reply_message_id": null | string,
        "reply_message_admin_id": null | string,
        "account": null | string,
        "admin_user": {
          "id": number,
          "email": string,
          "created_at": string,
          "updated_at": string,
          "role": string,
          "first_name": string,
          "last_name": string,
          "phone_number": string,
          "dob": string,
          "user_name": string,
          "google_unique_auth_id": null | number,
          "google_type": boolean,
          "full_phone_number": string,
          "country_code": number
        },
        "attachments": null | string,
        "participants": string,
        "push_notification_response": [
          null
        ]
      } = successResponse.data
      if (data) {
        this.setState({ message: '',messageAdding:true})
        this.updateChatData({message:data},data.chat_id)
      } else {
        this.setState({
          error: 'Something went wrong, data not found',
          // loading: false
        });
      }
    }
  }

  updateProfileImageApiCall({ successResponse }: any) {
    if (successResponse?.data) {
      this.setState({ chatData: successResponse.data, chatName: successResponse.data?.attributes?.name, isEditGroupName: false, isProfileImgUploading: false, cropImageModal: false, groupProfileImageUrl: "" });
      this.props.updateList({ chat_id: +this.state.chatId, data: successResponse.data })
      return
    }
    this.setState({ apiError: this.state.isEditGroupName ? "Could not able the update the group name" : "Could not able the upload the group profile", isProfileImgUploading: false })
  }

  removeUser = () => {
    // Customizable Area Start
    this.setState({ isUserRemoving: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    }
    let params: any = {
      chat_id: this.state.chatId,
    }
    if (this.state.selectedUser?.role === "admin" || this.state.selectedUser?.role === "super_admin") {
      params['admin_user_id'] = this.state.selectedUser?.id
    } else {
      params["account_id"] = this.state.selectedUser?.id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeChatUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeChatUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(params)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }
  updateRemoveUser = ({ successResponse }: any) => {
    if (successResponse?.error) {
      this.setState({ apiError: "Something went wrong, please try after some time" })
      return
    }
    this.setState({ isAddingUser: false, addParticipantModal: false, selectedParticipant: [], selectedUser: null, removeUserModal: false, isUserRemoving: false, chatViewPage: 1, messageAdding: true })
    this.getChatDetails(this.state.chatId)
    this.getParticipantsByChatId(this.state.chatId)
  }

  getNewsAndEventDetails = async (newsEventId: string | number) => {
    if (!newsEventId) return;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchNewsAndEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getnewsAndEventApiEndPoint}/${newsEventId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCropCalendarModalDetails = async (subActivityProgressId: string | number) => {
    if (!subActivityProgressId) return;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const params = `sub_activity_progress=${subActivityProgressId}`;

    console.log("BODY OF CROP CALENDAR", params)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCropCalendarApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCropCalendarChatDetailsEndPoint}?${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLibraryDetails = async (libraryId: string | number) => {
    if (!libraryId) return;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchLibrariesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getlibraryApiEndPoint}/${libraryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFaqsDetails = async (faqId: string | number) => {
    if (!faqId) return;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchFaqsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFaqsApiEndPoint}${faqId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSurvey = async (surveyId: string | number) => {
    if (!surveyId) return;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSurveyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSurveyApiEndPoint}${surveyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSurveyDetails = async (surveyId: string | number, accountId: number | string) => {
    if (!surveyId) return;
    let endpoint: string = `${configJSON.getSurveyDeatilsApiEndPoint}?survey_id=${Number(surveyId)}&account_id=${Number(accountId)}`;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSurveyDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFarmDiaryEntries = async (entriesId: string | number, type: string) => {
    if (!entriesId) return;
    let endpoint: string = `${configJSON.getFarmDiaryShareChatApiEndPoint}?activity=${type}&id=${Number(entriesId)}`;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
      type: "admin_user"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchFarmDiaryEntriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openAddParticipantModal = () => {
    this.setState({ addParticipantModal: true, selectedParticipant: [] })
  }
  closeAddParticipantModal = () => {
    this.setState({ addParticipantModal: false, selectedParticipant: [] })
  }

  filterList = (e: any) => {
    const searchQuery = e.target.value;
    this.setState({ searchText: searchQuery, chatList: [], page: 1, isLoading: searchQuery.length ? false : true },
      () => {
        this.getChatUserList(searchQuery);
      });

  }

  userChatListRenderer = () => {
    return
  }

  onInputChange = (event: any, value: string) => {
    this.setState({
      searchText: value,
      chatList: [],
      page: 1,
      isLoading: value.length ? false : true
    },
      () => {
        this.getChatUserList(value)
      });
  }

  handleParticipant = (selectedParticipant: any) => {
    let participant = [...selectedParticipant]

    let removeDuplicateParticipant = [];
    let uniqueObject = {} as any;

    for (let i in participant) {
      const objId = participant[i]['id'];
      uniqueObject[objId] = participant[i];
    }

    for (let i in uniqueObject) {
      removeDuplicateParticipant.push(uniqueObject[i]);
    }

    this.setState({ selectedParticipant: removeDuplicateParticipant })
  }

  getChatListUserCall = ({ successResponse, errorResponse }: any) => {
    if (successResponse?.errors) {
      const [error] = successResponse.errors
      this.setState({
        error: error.token,
      });

    } else {
      const order = ["super_admin", "admin", 1, 2];
      let flattenedData: any = [...successResponse.admin_users.data, ...successResponse.account_users.data]
      const sortedArray = flattenedData.sort((a: any, b: any) => {
        const aOrder = order.indexOf(a.attributes.role || a.attributes.role_id);
        const bOrder = order.indexOf(b.attributes.role || b.attributes.role_id);
        return aOrder - bOrder;
      });
      if (sortedArray) {
        this.apiRequestCallGetChatListCallResponse(sortedArray);
      } else {
        this.setState({
          error: 'Something went wrong, data not found',
        });
      }
    }
    this.setState({ isLoading: false })
  }

  apiRequestCallGetChatListCallResponse = (data: any) => {
    let results = data.map((item: any) => {
      return {
        id: item.id,
        name: item.attributes.name ?? `${item.attributes.first_name} ${item.attributes.last_name}`,
        unreadCount: item?.attributes.unread_count,
        lastMessage: item?.attributes.last_message,
        logo: item?.attributes?.logo?.url ?? null,
        role: item?.attributes?.role || null
      };
    })

    const arrChatList = [...this.state.chatList, ...results]
    this.setState(prev => {
      return {
        chatList: [...arrChatList],
        page: prev.page + 1
      }
    });
  }

  getChatUserList = async (search?: string) => {
    let queryParameters = [];
    queryParameters.push(`search=${search || this.state.searchText}`)

    const queryParametersString = queryParameters.filter(Boolean).join('&')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Accept": configJSON.acceptAll,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllUsers}?${queryParametersString}&page=${this.state.page}&per_page=${configJSON.perPageUserList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUsers = () => {
    this.setState({ isAddingUser: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    let params: {
      chat_id: string | number,
      account_ids: any[],
      admin_ids: any[],
    } = {
      chat_id: this.state.chatId,
      account_ids: [],
      admin_ids: [],
    };
    for (let item of this.state.selectedParticipant) {
      if (item?.role == "admin" || item?.role == "super_admin") {
        params.admin_ids.push(item.id);
      } else {
        params.account_ids.push(item.id);
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addChatUsersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(params)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  closeRemoveUserModal = () => {
    this.setState({ removeUserModal: false, selectedUser: null });
  };

  openRemoveUserModal = (user: any) => {
    this.setState({ removeUserModal: true, selectedUser: user });
  };

  handleEditGroupName = () => {
    this.setState({ isEditGroupName: !this.state.isEditGroupName, chatName: this.state.chatData?.attributes?.name });
  };

  handleChangeChatname = (event: any) => {
    this.setState({ chatName: event.target.value });
  };

  uploadGroupName = async (event: any) => {
    const header = {
      token: this.state.token,
    };

    let reqFormdata: any = new FormData();
    reqFormdata.append("chat[name]", this.state.chatName);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileImg = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatName}?chat_id=${this.state.chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  markReadMessage = (messageId: number | string | null) => {
    if (!messageId) return
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.apiContentType
    };
    const data = {
      "attributes": {
        "message_id": messageId,
        "read": true
      }
    }
    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  markDeliveredMessage=(messageId:number|string|null)=>{
    if(!messageId) return
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.apiContentType
    };
    const data = {
      "attributes":{
        "message_id": messageId,
        "delivered": true
      }
    }
    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatedeliverMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateDeliverMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeErrorModal=()=>{
    this.setState({apiError:null})
  }

  handleSelectGroupImage = (e: any) => {
    e.persist()
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ groupProfileImageUrl: reader.result as string, cropImageModal: true })
    };
    reader.readAsDataURL(files[0]);
    if (e.target) {
      e.target.value = '';
    }
  }

  handleCloseCropModal = () => {
    this.setState({ cropImageModal: false, groupProfileImageUrl: "" })
  }

  handleKeyPress = (e: any) => {
    if ((e.keyCode == 13 || e.key == "Enter") && this.state.message.trim()) {
      this.handleSendMessage();
    }
  }

  getNotification = async (notifyId: string | number, type: string) => {
    if (!notifyId) return;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${type === configJSON.notificationType ? configJSON.getNotificationAdminApiEndPoint : configJSON.getNotificationPushApiEndPoint}${notifyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  currentChatMarkRead=(id:any,chatId:any)=>{
    if(id){
     this.markDeliveredMessage(id)
    }
    if(chatId == this.state.chatId) {
      this.markReadMessage(id)
    }
  }

  updateChatData=(data:any,chatId?:any)=>{
    let latestData=data.message
    if(data?.admin_user){
      latestData={...latestData,admin_user:data.admin_user}
    }
    if(data?.account){
      latestData={...latestData,account:data.account}
    }
    if(this.state.chatData && chatId == this.state.chatId){
      let latestMessage={id:latestData.id,type:"chat_message",attributes:latestData}
      let data:any={...this.state.chatData}
      data.attributes.messages.unshift(latestMessage)
      data["attributes"]["last_message"]={data:latestMessage}
      this.apiRequestCallGetChatCallResponse(data)
    }
    this.props.getChatList({ chat_id: latestData.chat_id, data:latestData ,isUpdateCount:chatId != this.state.chatId,isUpdateOnly:false});
  }

  updateChatMessageStatus=(data:any,chatId?:any)=>{
    let latestData=data.message
        if(this.state.chatData && chatId == this.state.chatId){
      this.state.chatData.attributes.messages.forEach((item:any)=>{
      if(!item.attributes.is_mark_delivered && !item.attributes.is_mark_read && latestData.is_mark_delivered){
        item["attributes"]["is_mark_delivered"]=true
       }
       if(!item.attributes.is_mark_read && latestData.is_mark_read){
        item["attributes"]["is_mark_read"]=true
       }
     })
     let newData={...this.state.chatData}
          this.setState({chatData:newData})
     this.filteredMessages()
    }
    this.props.getChatList({ chat_id: latestData.chat_id, data:latestData ,isUpdateCount:false,isUpdateOnly:true});
  }
  
  handleIncomingMessage=(payload:any) => {
    console.log("handleIncomingMessage",payload)
    let parseMessage=JSON.parse(payload.data?.message)
    if(payload.data?.type == "message_read" || payload.data?.type == "message_delivered"){
        this.updateChatMessageStatus(parseMessage,payload.data?.chat_id)
          }
    if(payload.data?.type == "new_message"){
        this.currentChatMarkRead(parseMessage?.id,payload.data?.chat_id)
      if(payload.data?.chat_id){
        this.setState({messageAdding:true})
        this.updateChatData(parseMessage,payload.data.chat_id)
      }
    }
  }
  getChatDetailWithPages = () => {
    if (!this.state.isChatDetailsLoading) {
      this.getChatDetails(this.state.chatId);
    }
  }

  getParticipantsByChatId = (chatId: string | number) => {
    if (!chatId) return;
    this.setState({
      groupMembersLoading: true,
      groupMembers: []
    });

    const header = { token: this.state.token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getParticipantsCallId = requestMsg.messageId;

    const endpoint = `${configJSON.ENDPOINT.GET_PARTICIPANTS}?chat_id=${chatId}`
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }
  
  RespGetParticipantsCallId = (responseJson: { participants: IParticipants[] }) => {
    if (responseJson && responseJson.participants && responseJson.participants.length) {
      this.setState({
        groupMembersLoading: false,
        groupMembers: responseJson.participants
      })
    } else {
      this.setState({
        groupMembersLoading: false,
        groupMembers: []
      })
    }
  }
  // Customizable Area End
}

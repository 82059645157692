import React from "react";
import {    
    Box,
    Table,
    TableBody,
    IconButton,
    Typography,
    TableRow,
    TableCell,
    TableHead,
    Tooltip
} from "@material-ui/core";
import { dateDDMMYYYY, returnTruthyString } from "../helper";
import { ICreatedPreSowing } from "../interface.web";

interface IProps {
    data: ICreatedPreSowing[];
    handleDelete: (id: string) => void;
    handleEdit: (id: string, farmId: string, cropId: string) => void;
    handleShareLink: (id: string, farmId: string , cropId: string, message: string) => void;
}

const PresowingActivityDetails = ({
    data = [],
    handleDelete,
    handleEdit,
    handleShareLink
}: IProps) => {

    // RENDER TABLE ROW
    const rendertableRow = (label: string, value: string | Date) => {
        return (
            <TableRow>
                <TableCell
                    style={{
                        ...styleWeb.tableCell,
                        ...styleWeb.opacity64,
                    }}
                >
                    {label}
                </TableCell>
                <TableCell style={styleWeb.tableCell}>{value}</TableCell>
            </TableRow>
        );
    };

    const renderTable = (data: ICreatedPreSowing) => {
        const { id: itemId, attributes } = data;
        const { land_detail_id, pre_sowing_activity_id,
            date, crop_season_id, labor_cost, cost, fuel_consumption,
            unit_of_fuel_consumption_id, freeze_record } = attributes;

        const farmId = returnTruthyString(land_detail_id?.id)

        const farm_name = returnTruthyString(land_detail_id?.name)
        const crop_season = returnTruthyString(crop_season_id && crop_season_id[0]?.name)
        const pre_sowing_activity = returnTruthyString(pre_sowing_activity_id && pre_sowing_activity_id[0]?.name)
        const date_value = dateDDMMYYYY(date)
        const labor_cost_value = "₹ " + String(labor_cost) + "/-"
        const machine_cost_value = "₹ " + String(cost) + "/-"
        const fuel_consumption_unit = returnTruthyString(unit_of_fuel_consumption_id?.name)
        const fuel_consumption_value = String(fuel_consumption) + " " + fuel_consumption_unit // NEED TO UPDATE

        const disable_activity = freeze_record

        return (
            <Box style={{ width: "100%", borderTop: "1px solid #F0EBE0", }} key={itemId}>
                <Table>
                    <TableHead>
                        <TableRow style={styleWeb.tableHead}>
                            <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name}</span> </TableCell>
                            <TableCell style={styleWeb.headInfo}>Season: <span style={styleWeb.highlightedText}>{" "}{crop_season}</span></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rendertableRow("Pre-Sowing Activity", pre_sowing_activity)}
                        {rendertableRow("Date", date_value)}
                        {rendertableRow("Labor Cost", labor_cost_value)}
                        {rendertableRow("Machine Cost", machine_cost_value)}
                        { fuel_consumption ? rendertableRow("Fuel Consumption", fuel_consumption_value) : <></>}
                    </TableBody>
                </Table>
                <Box style={styleWeb.tableFooter}>
                    <Box style={styleWeb.iconsbox} onClick={()=> handleShareLink(String(itemId), String(farmId), "", "pre_sowings")}>
                        <IconButton style={styleWeb.iconbutton}>
                            <img
                                src={require("../share-icons.svg")}
                                alt="Share in chat"
                                style={{ width: "18px", height: "18px" }}
                            />
                        </IconButton>
                        <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
                    </Box>
                    <Box style={styleWeb.iconsbox}>
                        <Tooltip title={disable_activity ? "Activity Freezed" : ""}>
                            <Box>
                                <IconButton style={styleWeb.iconbutton}
                                    onClick={() => handleEdit(String(itemId), String(farmId), "")} // PASS EMPTY CROP ID
                                    disabled={disable_activity}
                                >
                                    <img
                                        src={require("../edit-icon.svg")}
                                        alt="Edit activity"
                                        style={styleWeb.actionIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title={disable_activity ? "Activity Freezed" : ""}>
                            <Box>
                                <IconButton
                                    style={styleWeb.iconbutton}
                                    onClick={() => handleDelete(String(itemId))}
                                    disabled={disable_activity}
                                >
                                    <img
                                        src={require("../delete-icon.svg")}
                                        alt="Delete activity"
                                        style={styleWeb.actionIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <>
            {data?.length > 0 ? (
                data.map((item, index) => {
                    return renderTable(item);
                })
            ) : (
                <Box style={{ width: "100%" }}>
                    <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
                        No data found
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default PresowingActivityDetails;

const styleWeb = {
    tableCell: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
        width: "50%"
    } as React.CSSProperties,
    shareInChat: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#006A77",
        lineHeight: "18px",
    } as React.CSSProperties,
    iconsbox: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        cursor: "pointer"
    } as React.CSSProperties,
    actionIcon: {
        width: "20px",
        height: "20px",
    } as React.CSSProperties,
    iconbutton: {
        padding: 0,
    } as React.CSSProperties,
    tableFooter: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 12px",
    } as React.CSSProperties,
    opacity64: {
        opacity: "64%",
    } as React.CSSProperties,
    tableHead: {
        border: "1px solid #F0EBE0",
        fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#1C2324",
        padding: "8px 12px",
        width: "100%",
    } as React.CSSProperties,
    highlightedText: {
        fontWeight: 500,
    } as React.CSSProperties,
    headInfo: {
        fontWeight: 400,
        padding: "8px 12px",
        fontSize: "12px",
        color: "#1C2324",
        borderBottom: "none"
    } as React.CSSProperties,
};

import React from "react";
import {
  // Customizable Area Start
  Grid,
  Modal,
  Card,
  Box,
  Typography,
  IconButton,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Divider,
  Button,
  styled,
  FormControlLabel,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  Badge,
  Tooltip,
  CardMedia,
  TextField,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DateRange, Add, Edit, KeyboardArrowDown } from "@material-ui/icons";
import { ICropDetails, ISelectFarm } from "../../../components/src/interface.web";
import CropFailureController, { InitialFormValues, DropdownOptions } from "./CropFailureController.web";
import { closeIconSvg, BackArrowIconSVG, cropFailureSvg, mapPlusSVG } from "./assets";
import moment from "moment";
import CustomModal from "../../../components/src/customModal.web";
import FarmMapping from "../../../components/src/FarmMapping/FarmMapping.web";
import { keyDown } from "../../../components/src/helper";
// Customizable Area End

export default class CropFailure extends CropFailureController {

  // Customizable Area Start
  farmMappingImage = ({ attachment }: { attachment: string | null }) => {
    const imgPartial = attachment ?? undefined
    return (
      <>
        {attachment ? (
          <CameraStyledBadge
            style={{ display: "block" }}
            overlap="rectangular"
            badgeContent={
              <Box>
                <label htmlFor="edit-crop-mapping">
                  <Tooltip title="Edit">
                    <IconButton
                      component="span"
                      aria-label="edit"
                      disableRipple={true}
                      style={{ padding: "0.375rem" }}
                      onClick={() => { this.setState({ openFarmMapping: true, coordinates: this.state.tempCoordinates }) }}
                    >
                      <Edit fontSize="small" htmlColor="#fff" />
                    </IconButton>
                  </Tooltip>
                </label>
              </Box>
            }
          >
            <Box>
              <Card variant="outlined" style={webStyle.badgeCardRoot}>
                <CardMedia
                  component="img"
                  image={imgPartial}
                  src={imgPartial}
                  alt="cover-images"
                />
              </Card>
            </Box>
          </CameraStyledBadge>
        ) : (
          <Box
            style={{
              background: "#FCFAF7",
              border: this.state.farmMappingURLError ? "1px solid red" : "1px solid #F0EBE0",
              borderRadius: "12px",
              padding: "16px",
              boxSizing: "border-box",
              height: "156px",
            }}
          >
            <Typography className="uploadTxt">
              {" "}
              Plot your uprooted crop’s location on map...
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label
                htmlFor="add-crop-mapping"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  component={"span"}
                  onClick={() => {
                    this.setState({ openFarmMapping: true, coordinates: [], tempCoordinates: [] }, () => {
                      if (!this.state.farmMappingURL) {
                        this.fetchCurrentLocation()
                      }
                    })
                  }}
                  data-test-id="open-farm-mapping-btn"
                >
                  <img src={mapPlusSVG} alt="mapIcon" />
                </IconButton>
              </label>
            </Box>
          </Box>
        )}
      </>
    );
  };

  renderCropMapping = () => {
    return (
      <Dialog
        open={this.state.openFarmMapping}
        aria-labelledby="farm-mapping-modal"
        PaperProps={{
          style: { borderRadius: 12, width: "712px" },
        }}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={this.toggleFarmMappingModal}
              style={{ padding: 0, marginRight: "16px" }}
              data-testid="backFarmMappingbtn"
            >
              <img src={BackArrowIconSVG} alt="Back icon" />
            </IconButton>
            <Typography variant="subtitle1">Uprooted crop mapping</Typography>
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <FarmMapping
            OpenFarmMapping={this.state.openFarmMapping}
            closeFarmMapping={this.toggleFarmMappingModal}
            onSave={this.onSaveFarmMapping}
            CoordinatesArr={this.state.coordinates.length ? this.state.coordinates : this.state.tempCoordinates}
            userLocation={this.state.userLocation}
            markerDefault={!this.state.coordinates.length ? this.state.userLocation : this.state.markerDefault}
            data-test-id="farmMappingComponent"
            isEditModal={this.state.openEditUserModal}
          />
        </DialogContent>
      </Dialog>
    );
  };

  renderUprootedCropMapping = (formValue: InitialFormValues) => {
    return (
      <Grid item xs={12} lg={12} className="text-field-container" style={{ minHeight: "unset%" }}>
        <FormLabel className="phasesLabelCustom">
          Uprooted Crop Mapping
        </FormLabel>
        {this.farmMappingImage({ attachment: this.state.farmMappingURL })}
        {this.state.farmMappingURLError && (
        <FormHelperText style={webStyle.errorStyle}>
          {this.state.farmMappingURLError}
        </FormHelperText>
        )}
      </Grid>
    )
  }

  dateOfUprootingCrop = (formValue: InitialFormValues) => {
    return (
      <>
        <Grid item xs={12} lg={6} className="text-field-container addCropDate">
          <CustomFormLabel>Date of Uprooting Crop</CustomFormLabel>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                required
                data-testid="sowing_date_testid"
                className="customDatePicker custom-textbox"
                inputVariant="outlined"
                color="secondary"
                keyboardIcon={<DateRange style={webStyle.customDateIcon} />}
                minDate={this.state.minimumDate}
                minDateMessage={"Please select a valid date"}
                maxDate={moment()}
                maxDateMessage={"Selected date cannot be a future date"}
                name="date"
                value={formValue.date}
                error={!!formValue.errors.date}
                helperText={formValue.errors.date}
                clearable
                placeholder="DD/MM/YYYY"
                format="DD/MM/yyyy"
                onChange={(newDate) =>
                  this.handleInputChange(
                    {
                      target: {
                        name: "date",
                        value: newDate,
                      },
                    })
                }
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}
          className="text-field-container addCropDate"
        >
          <FormLabel className="phasesLabelCustom">
            Reason of Uprooting
          </FormLabel>
          <FormControl fullWidth>
            <Select
              style={webStyle.inputFieldStyle}
              className="selectDDCustom"
              placeholder="Select Uprooting Reason"
              data-testid="selectDropdownValue"
              variant="outlined"
              color="secondary"
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="uprooting_reason"
              value={formValue.uprooting_reason}
              error={!!formValue.errors.uprooting_reason}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown, name?: string
                }>
              ) => {
                let newValue = event.target.value as string;
                this.handleInputChange(
                  {
                    target: {
                      name: "uprooting_reason",
                      value: newValue,
                    }
                  })
                this.setState({
                  selectDropdownValue: event.target.value as string,
                })
              }}
            >
              <MenuItem value={""} disabled>
                <span className="opcity-48">Select Uprooting Reason</span>
              </MenuItem>
              {this.state.uprootingReasonOptions.map((farm: DropdownOptions) => {
                return (
                  <MenuItem value={farm.id} key={farm.id}>
                    {farm.name_english}
                  </MenuItem>
                );
              })}
            </Select>
            {formValue.errors.uprooting_reason && (
              <FormHelperText style={webStyle.errorStyle}>
                {formValue.errors.uprooting_reason}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </>
    )
  }


  uprootedCropArea = (formValue: InitialFormValues) => {
    return (
      <>
        <Grid item xs={12} lg={6} className="text-field-container">
          <FormLabel className="phasesLabelCustom">Uprooted Crop Area </FormLabel>
          <FormControl fullWidth>
            <TextField
              className="textfield-with-select"
              fullWidth
              data-testid="fuelValue"
              placeholder="Enter Crop Area"
              type="number"
              variant="outlined"
              color="secondary"
              name="uprooted_area"
              onKeyDown={keyDown}
              value={formValue.uprooted_area}
              onChange={(e) =>
                this.handleInputChange(e)
              }
              error={!!formValue.errors.uprooted_area || !!formValue.errors.unit_of_uprooted_area}
              helperText={formValue.errors.uprooted_area != "" ? formValue.errors.uprooted_area : formValue.errors.unit_of_uprooted_area}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: this.state.unitOfFuelConsumptionList.length === 1 || !this.state.unitOfFuelConsumptionList.length ?
                  <CustomUnitLabel>
                    <Typography noWrap style={{ color: "#9b9b94" }}>
                      {this.getLabelFromId(formValue.uprooted_area, this.state.unitOfFuelConsumptionList)}
                    </Typography>
                  </CustomUnitLabel>
                  : (
                    <SelectWithInput
                      className="select-with-input"
                      error={!!formValue.errors.unit_of_uprooted_area}
                      IconComponent={KeyboardArrowDown}
                      data-testid="nutrient_quantity_unit_testid"
                      value={formValue.unit_of_uprooted_area}
                      onChange={(
                        event: React.ChangeEvent<{
                          value: unknown, name?: string
                        }>
                      ) => {
                        this.handleInputChange({
                          target: {
                            name: "unit_of_uprooted_area",
                            value: event.target.value as string,
                          },
                        })
                      }}
                    >
                      <MenuItem value={""} disabled>
                        <span className="opcity-48"><em>None</em></span>
                      </MenuItem>
                      {
                        this.state.unitOfFuelConsumptionList.map((unit, index) => {
                          return (
                            <MenuItem
                              key={unit.id}
                              value={unit.id}
                            >
                              {unit.attributes.unit_name}
                            </MenuItem>
                          )
                        })
                      }
                    </SelectWithInput>
                  ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}
          className="text-field-container addCropDate"
        >
          <FormLabel className="phasesLabelCustom">
            Next Action on Farm
          </FormLabel>
          <FormControl fullWidth>
            <Select
              style={webStyle.inputFieldStyle}
              className="selectDDCustom"
              placeholder="Select Uprooting Reason"
              data-testid="selectDropdownValue"
              variant="outlined"
              color="secondary"
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              name="next_action"
              value={formValue.next_action}
              error={!!formValue.errors.next_action}
              onChange={(
                event: React.ChangeEvent<{
                  value: unknown, name?: string
                }>
              ) => {
                let newValue = event.target.value as string;
                this.handleInputChange(
                  {
                    target: {
                      name: "next_action",
                      value: newValue,
                    },
                  })
                this.setState({
                  selectDropdownValue: event.target.value as string,
                })
              }}
            >
              <MenuItem value={""} disabled>
                <span className="opcity-48">Select Next Action</span>
              </MenuItem>
              {this.state.nextActionOptions.map((farm: DropdownOptions) => {
                return (
                  <MenuItem value={farm.id} key={farm.id}>
                    {farm.name_english}
                  </MenuItem>
                );
              })}
            </Select>
            {formValue.errors.next_action && (
              <FormHelperText style={webStyle.errorStyle}>
                {formValue.errors.next_action}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </>
    )
  }

  innerForm = () => {
    const { cropFailureFormValues } = this.state;
    return cropFailureFormValues.map(
      (formValue: InitialFormValues, index: number) => {
        return (
          <ResponsiveGrid
            container
            spacing={2}
            key={index}
            style={{ position: "relative" }}
          >
            {this.dateOfUprootingCrop(formValue)}
            {this.renderUprootedCropMapping(formValue)}
            {this.uprootedCropArea(formValue)}
            <Grid container spacing={2}>
              <Button
                type="submit"
                style={{
                  ...webStyle.cropSubmitButton,
                  margin: "32px 15px",
                }}
                data-testid="submit_button_testid"
                onClick={this.handleFormSubmit}
                disabled={this.state.loader}
              >
                <Typography style={webStyle.cropSubmitButtonText}>
                  {this.state.loader ? (
                    <CircularProgress
                      size={28}
                      style={{ color: "#fff" }}
                    />
                  ) : (
                    this.props.isEditActivity ? "UPDATE":"Save"
                  )}
                </Typography>
              </Button>
            </Grid>
          </ResponsiveGrid>
        );
      }
    )
  }

  cropFailureActivity = () => {
    return (
      <Grid item>
        <Modal
          open={this.props.isCropFailureModal}
          onClose={this.props.handleClose}
          data-testid="close_modal_button"
          style={webStyle.secondaryModalBox}
          className="addCropModal"
          BackdropProps={{
            style: webStyle.modalBackdropProps,
          }}
        >
          <Card variant="outlined" style={webStyle.AddCardBoxSuccess}>
            <Box style={webStyle.boxContainer}>
              <CustomAddCropBox>
                <Box style={webStyle.cropHeader}>
                  <Box style={webStyle.headerLeft}>
                    <img
                      src={cropFailureSvg}
                      alt="Crop"
                      style={webStyle.headerImage}
                    />
                    <Typography style={webStyle.headerTitle}>
                      Crop Failure
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={this.props.handleClose}
                    >
                      <img
                        src={closeIconSvg}
                        alt="closeIcon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <form
                  data-testid="from-submit-testid"
                  noValidate
                  autoComplete="off"
                  className="customFormSpacing"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGridWrapper container>
                  <ResponsiveGrid container spacing={2}>
                      <Grid item xs={12} lg={6} className="text-field-container">
                        <FormLabel className="phasesLabelCustom">Select Farm</FormLabel>
                        <FormControl fullWidth>
                          <Select
                            style={webStyle.inputFieldStyle}
                            className="selectDDCustom"
                            data-testid="selectFarm"
                            variant="outlined"
                            color="secondary"
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            disabled={this.props.isEditActivity}
                            name="select_farm"
                            renderValue={(value) => {
                              if (this.state.formLandDetailId) {
                                return this.state.farmNamesList.find((item) => item.attributes.id == this.state.formLandDetailId)?.attributes?.name
                              } else if (!this.state.formLandDetailId) {
                                return (
                                  <span className="opcity-48">Select Farm</span>
                                );
                              }
                            }}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              this.handleFarmSelect( event.target.value as string)
                              
                            }}
                            value={this.state.formLandDetailId}
                            error={!!this.state.formLandDetailIdError}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select Farm</span>
                            </MenuItem>
                            {this.state.farmNamesList.map((item: ISelectFarm) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={item.attributes.id}
                                  key={item.id}
                                >
                                  <RadioGroup
                                    value={this.state.formLandDetailId}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomFormControlLabel
                                      value={item.attributes.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography style={webStyle.cropDropDownLable}>
                                              {item.attributes.name}
                                            </Typography>
                                          </Box>
                                          <Typography style={webStyle.cropUnitText}>
                                            {item.attributes.farm_area}{" "}{item.attributes?.unit_farm_area_id?.name}
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              style={{
                                ...webStyle.shareInChat,
                                marginLeft: "16px",
                                color: "#006A77",
                                marginTop: "8px",
                              }}
                              onClick={this.handleAddFarm}
                              startIcon={
                                <Add
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                            >
                              Add Farm
                            </CustomButton>
                          </Select>
                          {this.state.formLandDetailIdError && (
                            <FormHelperText style={webStyle.errorStyle}>
                              {this.state.formLandDetailIdError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={6} className="text-field-container">
                        <FormLabel className="phasesLabelCustom">
                          Select Crop
                        </FormLabel>
                        <FormControl fullWidth>
                          <Select
                            style={webStyle.inputFieldStyle}
                            className="selectDDCustom"
                            data-testid="selectCrop"
                            variant="outlined"
                            color="secondary"
                            disabled={this.props.isEditActivity || !this.state.formLandDetailId}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            name="select_farm"
                            renderValue={(value) => {
                              if (!this.state.formCropId) {
                                return (
                                  <span className="opcity-48">Select Crop</span>
                                );
                              }
                              if (this.state.formCropId) {
                                return this.state.cropNameList.find(
                                  (item) => item.attributes.id == this.state.formCropId
                                )?.attributes?.crop_name_id?.name;
                              }
                            }}
                            onChange={(
                              event: React.ChangeEvent<{ value: unknown }>
                            ) => 
                              this.handleCropSelection(event.target.value as string)
                            }
                            value={this.state.formCropId}
                            error={!!this.state.formCropIdError}
                          >
                            <MenuItem value={""} disabled>
                              <span className="opcity-48">Select crop</span>
                            </MenuItem>
                            {this.state.cropNameList.map((item: ICropDetails) => {
                              return (
                                <MenuItem
                                  style={{ paddingBlock: "0px" }}
                                  value={item.attributes.id}
                                  key={item.id}
                                >
                                  <RadioGroup
                                    value={this.state.formCropId}
                                    style={{
                                      width: "100%",
                                      borderBottom: "1px solid #F0EBE0",
                                    }}
                                  >
                                    <CustomFormControlLabel
                                      value={item.attributes.id}
                                      control={<Radio />}
                                      style={{ width: "100%" }}
                                      label={
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              style={webStyle.cropDropDownLable}
                                            >
                                              {
                                                item.attributes?.crop_name_id
                                                  ?.name
                                              }{" "}
                                              |{" "}
                                              <span
                                                style={{ color: "#108B88" }}
                                              >
                                                {
                                                  item.attributes?.crop_type_id
                                                    ?.name_english
                                                }
                                              </span>
                                            </Typography>
                                          </Box>
                                          <Typography
                                            style={webStyle.cropUnitText}
                                          >
                                            {item.attributes?.total_crop_area}{" "}
                                            {
                                              item.attributes?.unit_of_area_id
                                                ?.name
                                            }
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </RadioGroup>
                                </MenuItem>
                              );
                            })}
                            <CustomButton
                              style={{
                                ...webStyle.shareInChat,
                                marginLeft: "16px",
                                color: "#006A77",
                                marginTop: "8px",
                              }}
                              onClick={this.handleAddCrop}
                              startIcon={
                                <Add
                                  style={{ width: "18px", height: "18px" }}
                                />
                              }
                            >
                              Add Crop
                            </CustomButton>
                          </Select>
                          {this.state.formCropIdError && (
                            <FormHelperText style={webStyle.errorStyle}>
                              {this.state.formCropIdError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </ResponsiveGrid>
                    <Grid container>
                      <Divider
                        component={"hr"}
                        style={{
                          background: "#F0EBE0",
                          width: "100%",
                          height: "2px",
                          marginTop: "8px",
                        }}
                      />
                    </Grid>
                    {this.innerForm()}
                  </FormGridWrapper>
                </form>
              </CustomAddCropBox>
            </Box>
          </Card>
        </Modal>
      </Grid>
    );
  };

  render() {
    return (
      <>
        {this.cropFailureActivity()}
        {this.renderCropMapping()}

        {this.state.customMessage && (
          <CustomModal
            open={!!this.state.customMessage}
            onClose={this.handleCloseCustomModal}
            title={this.state.customMessageTitle}
            description={this.state.customMessage}
          />
        )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .5)",
  } as React.CSSProperties,
  AddCardBoxSuccess: {
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    maxWidth: "712px",
    flexDirection: "column",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  boxContainer: {
    fontSize: "16px",
    height: "100%",
    overflowY: "auto",
  } as React.CSSProperties,
  cropHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "32px",
    marginBottom: "16px",
  } as React.CSSProperties,
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  inputFieldStyle: {
    height: "56px",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  cropSubmitButton: {
    borderRadius: "28px",
    background: "#DF5200",
    width: "100%",
    paddingBlock: "14px",
  } as React.CSSProperties,
  closeIcon: {
    width: "24px",
    height: "24px",
  } as React.CSSProperties,
  cropDropDownLable: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  cropUnitText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontStyle: "italic",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  },
  errorStyle: {
    fontSize: "0.75rem",
    color: "#f44336",
  } as React.CSSProperties,
  badgeCardRoot: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    minHeight: "150",
    padding: "0",
  } as React.CSSProperties,
  cropSubmitButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
};

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
});

const CustomButton = styled(Button)({
  "& .MuiButton-startIcon": {
    marginRight: "2px ",
  },
});

const ResponsiveGrid = styled(Grid)({
  marginInline: "24px",
  "@media (max-width:600px)": {
    marginInline: "16px",
  },
});

const CustomAddCropBox = styled(Box)({});
const CustomFormLabel = styled(FormLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#1C2324",
  opacity: 0.64,
  marginBottom: "8px"
})
const FormGridWrapper = styled(Grid)({
  rowGap: "16px",
  "& .phasesLabelCustom": {
    "& span": {
      fontSize: "12px"
    }
  }
});

export const CameraStyledBadge = styled(Badge)(
  {
    "& .MuiBadge-badge": {
      right: "6%",
      top: "15%",
    }
  }
);

const CustomUnitLabel = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "83px",
  height: "28px",
  padding: "0px 12px",
  boxSizing: "border-box",
  lineHeight: "2px",
  textAlign: "center",
  fontSize: "14px",
  borderRadius: "8px",
  background: "#F0EBE0",
  color: "#94949b"
})

const SelectWithInput = styled(Select)({
  '&.select-with-input': {
    height: "28px !important"
  },
  '& .MuiSelect-icon': {
    color: "#aaaca4"
  },
  '& .MuiInputBase-formControl': {
    height: "auto"
  },
  '&.select-with-input .MuiSelect-iconOutlined': {
    right: "0 !important"
  },
  '&.select-with-input .MuiSelect-root': {
    width: "83px",
    height: "28px",
    padding: "5px 12px",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "left",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
    color: "#94949b"
  }
})

// Customizable Area End
// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { TLocation, IDefaultFormTaluka, ILanguageObjectTaluka } from "../../../components/src/interface.web";
import { returnError, returnTruthyString, trimStart, validateTalukaForm } from "../../../components/src/helper";

export const configJSON = require("./config");

const DefaultForm = {
    id: "",
    stateId: "",
    districtId: "",
    mandiName: "",
    stateError: "",
    districtError: "",
    mandiError: "",
    english: {
        value: "",
        error: "",
    },
    gujarati: {
        value: "",
        error: "",
    },
    hindi: {
        value: "",
        error: "",
    },
}
export interface Props {
    navigation: any;
    id: string;
    dataArray: IDefaultFormTaluka[],
    onSubmit: (data: IDefaultFormTaluka[]) => void,
    submitLoading: boolean,
}
interface S {
    selectedTab: string;
    authToken: string | null;
    // DROPDOWNS
    stateList: TLocation[];
    districtList: TLocation[];
    mandiList: string[];
    stateListLoading: boolean;
    districtListLoading: boolean;
    mandiListLoading: boolean;
    // DEFAULT FORM
    stateId: "",
    districtId: "",
    mandiName: "",
    defaultForm: IDefaultFormTaluka,
    // FORM ARRAY
    formArray: IDefaultFormTaluka[]
}
interface SS { }
// Customizable Area End

export default class TalukaDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    stateListApiCallId: string = "";
    districtListApiCallId: string = "";
    mandiListApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start  
            authToken: localStorage.getItem("tokenn"),
            selectedTab: configJSON.TAB.ENGLISH,
            // DROPDOWNS
            stateList: [],
            stateListLoading: false,
            districtList: [],
            districtListLoading: false,
            mandiList: [],
            mandiListLoading: false,
            // DEFAULT FORM
            stateId: "",
            districtId: "",
            mandiName: "",
            defaultForm: { ...DefaultForm },
            // FORM ARRAY
            formArray: [],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.stateListApiCallId:
                        this.RespStateListApiCallId(responseJson);
                        break;
                    case this.districtListApiCallId:
                        this.RespDistrictListApiCallId(responseJson);
                        break;
                    case this.mandiListApiCallId:
                        this.RespMandiListApiCallId(responseJson);
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // MOUNT FUNCTIONS
    async componentDidMount() {
        this.getStateList();
        this.getMandiList();
        this.setState({ formArray: this.props.dataArray })
    }

    // API CALLS
    getStateList = () => {
        this.setState({
            // LIST
            stateList: [],
            districtList: [],
            // LOADER
            stateListLoading: true,
            districtListLoading: true
        })
        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.stateListApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.ENDPOINT.STATE_LIST}`
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    getMandiList = () => {
        this.setState({
            mandiList: [],
            mandiListLoading: true
        })
        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.mandiListApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.ENDPOINT.MANDI_LIST}`
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    getDistrictList = (stateId: string) => {
        this.setState({
            districtList: [],
            districtListLoading: true
        })
        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.districtListApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.ENDPOINT.DISTRICT_LIST}?state_id=${stateId}`
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }


    // HANDLE API CALL RESPONSES
    RespStateListApiCallId = (responseJson: TLocation[]) => {
        if (responseJson && responseJson.length) {
            this.setState({
                stateList: responseJson,
                stateListLoading: false,
                districtListLoading: false
            })
        } else {
            this.setState({
                stateList: [],
                stateListLoading: false,
                districtListLoading: false
            })
        }
    }
    RespDistrictListApiCallId = (responseJson: TLocation[]) => {
        if (responseJson && responseJson.length) {
            this.setState({
                districtList: responseJson,
                districtListLoading: false
            })
        } else {
            this.setState({
                districtList: [],
                districtListLoading: false
            })
        }
    }
    RespMandiListApiCallId = (responseJson: { data: string[] }) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({
                mandiList: responseJson.data,
                mandiListLoading: false
            })
        } else {
            this.setState({
                mandiList: [],
                mandiListLoading: false
            })
        }
    }

    // HANDLE FUNCTIONS
    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            selectedTab: newValue
        })
    }
    setDefaultTab = (tabValue: string = configJSON.TAB.ENGLISH) => {
        this.setState({
            selectedTab: tabValue
        })
    }
    changeTabByLanguage = (isErrorEnglish: boolean,
        isErrorGujarati: boolean,
        isErrorHindi: boolean,
        isErrorInOtherField: boolean) => {
        if (isErrorEnglish || isErrorInOtherField) {
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if (isErrorGujarati || isErrorInOtherField) {
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if (isErrorHindi || isErrorInOtherField) {
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
    }

    // DEFAULT FORM HANDLE CHANGE
    handleChangeInputDefault = (fieldValue: string | number, language: string) => {
        this.setState(prevState => ({
            defaultForm: {
                ...prevState.defaultForm,
                [language]: {
                    ...prevState.defaultForm[language] as ILanguageObjectTaluka,
                    value: trimStart(String(fieldValue)),
                    error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty",
                }
            }
        }));
    }
    handleChangeState = (fieldValue: string | number) => {
        this.setState(prevState => ({
            defaultForm: {
                ...prevState.defaultForm,
                stateId: returnTruthyString(String(fieldValue)),
                stateError: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty",
                districtId: "",
                districtError: ""
            }
        }));
        this.getDistrictList(returnTruthyString(String(fieldValue)))
    }
    handleChangeDropdowns = (fieldKey: string, fieldErrorKey: string, fieldValue: string | number) => {
        this.setState(prevState => ({
            defaultForm: {
                ...prevState.defaultForm,
                [fieldKey]: returnTruthyString(String(fieldValue)),
                [fieldErrorKey]: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty",

            }
        }));
    }

    // FORM LIST HANDLE CHANGE
    handleChangeForm = (fieldIndex: number, fieldName: string, fieldErrorKey: string, fieldValue: string | number, language?: string) => {
        if (language) {
            const formArrayCopy = [...this.state.formArray];
            formArrayCopy[fieldIndex] = {
                ...formArrayCopy[fieldIndex],
                [language]: {
                    ...formArrayCopy[fieldIndex][language] as ILanguageObjectTaluka,
                    value: trimStart(String(fieldValue)),
                    error: returnError(returnTruthyString(fieldValue))
                }
            };
            this.setState({
                formArray: formArrayCopy
            })

        } else {
            const formArrayCopy = [...this.state.formArray];
            formArrayCopy[fieldIndex] = {
                ...formArrayCopy[fieldIndex],
                [fieldName]: returnTruthyString(String(fieldValue)),
                [fieldErrorKey]: returnError(returnTruthyString(fieldValue))
            };
            this.setState({
                formArray: formArrayCopy
            })
        }
    }

    validateAllForms = () => {
        const allForms = [...this.state.formArray]
        let isFormValidatedArr: boolean[] = []
        let isErrorEnglishArr: boolean[] = [];
        let isErrorGujaratiArr: boolean[] = [];
        let isErrorHindiArr: boolean[] = [];
        let isErrorInOtherFieldArr: boolean[] = [];

        let UpdatedFormArray: IDefaultFormTaluka[] = []

        allForms.forEach((newForm) => {
            const { isFormHasError, isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField, formObj } = validateTalukaForm(newForm, true)
            isFormValidatedArr.push(isFormHasError)
            isErrorEnglishArr.push(isErrorEnglish)
            isErrorGujaratiArr.push(isErrorGujarati)
            isErrorHindiArr.push(isErrorHindi)
            isErrorInOtherFieldArr.push(isErrorInOtherField)

            UpdatedFormArray.push(formObj)
        })

        const isFormError = isFormValidatedArr.includes(true);
        const isErrorInEnglishTab = isErrorEnglishArr.includes(true);
        const isErrorInGujaratiTab = isErrorGujaratiArr.includes(true);
        const isErrorInHindiTab = isErrorHindiArr.includes(true);
        const isErrorOtherFields = isErrorInOtherFieldArr.includes(true);
        this.changeTabByLanguage(isErrorInEnglishTab, isErrorInGujaratiTab, isErrorInHindiTab, isErrorOtherFields)

        this.setState({
            formArray: UpdatedFormArray
        })

        if (isErrorInEnglishTab || isErrorInGujaratiTab || isErrorInHindiTab || isErrorOtherFields || isFormError) {
            return false
        } else {
            return true
        }

    }

    // HANDLE BUTTON FUNCTION 
    handleSubmit = () => {
        this.setState({ defaultForm: { ...DefaultForm } })
        if (this.validateAllForms()) {
            this.props.onSubmit(this.state.formArray)
        }
    }
    handleAddAnotherInput = () => {
        const newForm = JSON.parse(JSON.stringify(this.state.defaultForm))
        const { formObj,
            isFormHasError,
            isErrorEnglish,
            isErrorGujarati,
            isErrorHindi,
            isErrorInOtherField } = validateTalukaForm(newForm, false)
        if (!isFormHasError) {
            let existingData = [...this.state.formArray]
            existingData.push(formObj);
            this.setState({
                formArray: existingData,
                defaultForm: { ...DefaultForm },
            });
            this.setDefaultTab()
        } else {
            this.changeTabByLanguage(isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField)
            this.setState({ defaultForm: formObj });
        }
    }
    handleRemoveInput = (form: IDefaultFormTaluka, index: number) => {
        let existingData = [...this.state.formArray]

        if (index >= 0 && index < existingData.length) {
            const obj = existingData[index];
            if (obj.id) {
                obj["_destroy"] = form.id;
            } else {
                existingData.splice(index, 1);
            }
        }
        this.setState({ formArray: existingData });
    };
    // Customizable Area End
}
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple1, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateSimpleBodyType1, formateUnitBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null
    // API CALLS
    submitLoading: boolean
    dropdownDataLoading: boolean,
    dropdownData: IDropdownDetailsData[],
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
    // SEARCH
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class CropFailureController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiId: string = "";
    getDetailsApiId: string = "";
    updateDropdownsApiId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            currentExpanded: null,
            // API CALLS
            fetchHeadingsLoading: false,
            dropdownDataLoading: false,
            submitLoading: false,
            headingsData: [],
            dropdownData: [],
            // MSG MODAL
            titleMsgModal: "",
            descMsgModal: "",
            // SEARCH
            searchValue: "",
            openMsgModal: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getHeadingsApiId:
                        this.GetHeadingsApiIdResp(responseJson)
                        break;
                    case this.getDetailsApiId:
                        this.GetDetailsApiIdResp(responseJson)
                        break;
                    case this.updateDropdownsApiId:
                        this.UpdateDropdownsApiIdResp(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsApiIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }

    GetDetailsApiIdResp = (responseJson: IDropdownDetailsData[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: responseJson
            })
        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }
    UpdateDropdownsApiIdResp = (responseJson: IResponseDropdowns[] | null | undefined) => {
        this.setState({ submitLoading: false })
        if (!Array.isArray(responseJson) || responseJson.length === 0) {
            this.OpenMsgModal(true, "Error", "Something went wrong")
            return
        }
        const hasMessageKey = responseJson.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseJson);
            this.OpenMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(responseJson);
        }
    }

    successUpdate = (response: IResponseDropdowns[]) => {
        if (response && response.length) {
            this.OpenMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.OpenMsgModal(true, "Error", "Something went wrong!")
        }
    }

    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }

    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiId = requestApiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
    }
    fetchDropdownDetails = (title: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            })

            const header = { token: this.state.authToken };
            const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiId = requestApiMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
        } else {
            return
        }
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple1 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (title && subDropdownName && dropdownName) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsApiId = requestMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = title == configJSON.PRE_SOWING.SEASON ? "crop_season" : toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    handleCloseMsgModal = () => {
        this.setState({
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
        })
    }
    OpenMsgModal = (open: boolean, title: string, message: string) => {
        this.setState({
            openMsgModal: open,
            titleMsgModal: title,
            descMsgModal: message,
        })
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({ dropdownDataLoading: true, dropdownData: [] }, () => {
                this.fetchDropdownDetails(heading)

            })
        }
    };

    setSearchValue = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchValue: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }

    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType1(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
    }

    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }
    // Customizable Area End
}
import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Input,
    InputAdornment,
    styled,
    Grid,
    IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FarmsDropdownController, { Props, configJSON } from "./FarmsDropdownController.web";
import { DataNotFound, LoaderCenter } from "../../../../components/src/AdminConsoleHelper";
import { IHeadings } from "../../../../components/src/interface.web";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleDropdown from "../Registration/SimpleDropdown.web";
import UnitComponent from "../Units/UnitComponent.web";
import { DropdownKey, formateDataSimple, formateUnitData } from "../../../../components/src/helper";
import ErrorModal from "../../../../components/src/ErrorModal.web";
// Customizable Area End

export default class FarmsDropdown extends FarmsDropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderAccordianDetails = (item: IHeadings) => {
        if (item.heading == configJSON.FARMS.UNIT_OF_AREA) {
            return (<UnitComponent MenuList={formateUnitData(this.state.dropdownData)} submitLoading={this.state.submitLoading}
                onSubmit={this.handleSubmitUnit} openUnitsDrodpowns={this.props.openUnitsDrodpowns} data-testid={`unit-component`}
            />)
        } else {
            return (<SimpleDropdown navigation={undefined} id="" dataArray={formateDataSimple(this.state.dropdownData)}
                submitLoading={this.state.submitLoading} onSubmit={this.handleSubmit} data-testid={`simple-component`}
            />)
        }
    }
    renderAccordians = (data: IHeadings[]) => {
        return (
            <>
                {data.map((item, itemIndex) => {
                    return (
                        <Box>
                            <CustomAccordian expanded={this.state.currentExpanded === item.heading}
                                onChange={this.handleCurrentExpanded(item.heading)} data-testid={`accordian-${itemIndex}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ width: "28px", height: "28px", color: "#1C2324", opacity: 0.72 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className="sub-dropdown-title">{item.heading}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    className="main-accordian-details-wrapper"
                                >
                                    {this.state.dropdownDataLoading ?
                                        <LoaderCenter loadingStyle={{ height: "24px", width: "24px" }} />
                                        : <></>
                                    }
                                    {((!this.state.dropdownDataLoading && this.state.currentExpanded == item.heading))
                                        ?
                                        this.renderAccordianDetails(item) : <></>}
                                </AccordionDetails>
                            </CustomAccordian>
                        </Box>
                    )
                })}

            </>
        )
    }
    renderDropdowns = () => {
        const { headingsData } = this.state;

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item lg={6} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(headingsData.slice(0, Math.ceil(headingsData.length / 2)))}
                    </Grid>
                    <Grid item lg={6} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(headingsData.slice(Math.ceil(headingsData.length / 2)))}
                    </Grid>

                </Grid>
            </Box>
        )
    }

    renderMsgModal = () => {
        return (
            <>
                <ErrorModal open={this.state.openMsgModal} errorTitle={this.state.titleMsgModal} errorSubTitle={this.state.descMsgModal}
                    BtnTitle="Okay" handleBtnClick={this.handleCloseMsgModal}
                />
            </>
        )
    }
    renderHeader = () => {
        const { subDropdownName } = DropdownKey()
        return (
            <HeaderBox>
                <Typography style={style.headerTitle}>{subDropdownName}</Typography>
                <SearchBoxWrapper>
                    <Input fullWidth disableUnderline
                        className="custom_search_input"
                        data-testid="search_dropdown_testid"
                        type="text"
                        placeholder="Search here..."
                        value={this.state.searchValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setSearch(event.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                />
                            </InputAdornment>
                        }
                        endAdornment={
                            this.state.searchValue && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => { this.setSearch("") }} style={{padding:4}}> <ClearIcon /> </IconButton>
                                </InputAdornment>
                            )
                        }
                        name="search_dropdown"
                    />
                </SearchBoxWrapper>
            </HeaderBox>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.mainWrapper}>
                    {this.renderHeader()}
                    {this.state.fetchHeadingsLoading ? <LoaderCenter /> : <></>
                    }
                    {(!this.state.fetchHeadingsLoading && this.state.headingsData.length) ? this.renderDropdowns() : 
                    <>
                    </>}
                    {(!this.state.fetchHeadingsLoading && !this.state.headingsData.length) ? <DataNotFound fontStyle={style.noDataTxt} /> : <></>}
                    {this.state.openMsgModal ? this.renderMsgModal() : <>
                    </>}
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start   

const SearchBoxWrapper = styled(Box)({
    minWidth: "300px",
    height: "40px",
    background: "#FCFAF7",
    borderRadius: "28px",
    border: "1px solid #F0EBE0",
    width: "100%",
    '& .custom_search_input>.MuiInputAdornment-positionStart': {
        margin: "0 4px 0 16px !important"
    },
    '& .custom_search_input>.MuiInputBase-input': {
        margin: "2px"
    },
    "@media (min-width: 959px) and (max-width: 1130px)": {
        minWidth: "200px",
    },
    "@media (max-width: 695px)": {
        minWidth: "200px",
        width: "100%"
    }
})
const HeaderBox = styled(Box)({
    marginBottom: "24px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: "nowrap",
    rowGap: "8px",
    "@media (max-width: 695px)": {
        flexWrap: "wrap",
        width: "100%"
    }
})
const CustomAccordian = styled(Accordion)({
    backgroundColor: "#FCFAF7 !important",
    borderRadius: "12px !important",
    "& .MuiAccordionSummary-root": {
        height: "52px !important",
        minHeight: "52px !important",
        padding: "0 12px 0 16px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
        "padding": "12px !important"
    }
})
const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%"
    } as React.CSSProperties,
    dropdownGrid: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "16px",
        width: "100%"
    } as React.CSSProperties,
    // NO DATA FOUND
    noDataTxt: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#1C2324",
        textAlign: "center",
        marginTop: "50px"
    } as React.CSSProperties,
}
// Customizable Area End

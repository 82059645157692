import React from "react";
import { Box, Typography,Divider, Tooltip } from "@material-ui/core";
import {
  ExpandMore,
  ExpandLess,
  CalendarToday,
  WatchLater,
  LocationOn,
  Share,
  Edit,
  Delete,
  PhotoCamera
} from "@material-ui/icons";
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import { configJSON } from "../NewsAndEventsController";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

export interface DataNewsAndEventType {
    description: string,
    event_date: string,
    event_time: string,
    image: {
      url: string,
      thumb: {
        url: string
      }
    },
    image_or_video: string,
    name: string,
    news_event_type: string,
    language: string,
    written_by?: string,
    location?: string,
    admin_event_id: number
}

export interface DataType {
  id: string,
  type: string,
  attributes: DataNewsAndEventType
}

export interface AccordionSectionPropsType {
  data: DataType;
  selected: number;
  handleAccordion: (id: number) => void;
  deleteEvent: (id: number, recordType: string) => void;
  editSection: (adminId: number) => void;
  handleShareLink: (eventsObj?:any) => void;
  expandTab?: boolean;
}

export interface SubComponentPropsType {
  data: any;
  expandTab: boolean;
}

const SubDetailSection = ({ data, expandTab }: SubComponentPropsType) => {
  const classes = useStyles();
  if (!expandTab && data.attributes?.news_event_type === configJSON.news) { 
    return null;
  }

  if (!expandTab ) {
    return (
      <Typography className={classes.dateContainer}>
        <CalendarToday className={classes.dateIcon} />
        { moment(data.attributes?.event_date).format('D MMM YYYY')  }
      </Typography>
    );
  }
  if (expandTab &&  data.attributes?.news_event_type === configJSON.events) {
    return (
      <Box className={classes.boxIconsContainer}>
        <Box className={classes.boxIcons}>
          <CalendarToday className={classes.dateIcon} />
          <Typography className={classes.fontSmall}>
          { moment(data.attributes?.event_date).format('D MMM YYYY')  }
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className={classes.boxIcons}>
          <WatchLater className={classes.dateIcon} />
          <Typography className={classes.fontSmall}>
            { moment(data.attributes?.event_time).format('hh:mm a')  } onwards
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Tooltip title={data.attributes?.location || ''}> 
        <Box className={classes.boxIcons}>
          <LocationOn className={classes.font15} />
          <Typography className={`${classes.locationTextOverflow}`}>{ data.attributes?.location || '-' }</Typography>
        </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Typography className={classes.location}>
      { moment(data.attributes?.event_date).format('D MMMM YYYY')  }
    </Typography>
  );
};

const ActionBtn = ({ data, expandTab, deleteEvent, editSection,handleShareLink }: Omit<AccordionSectionPropsType, "handleAccordion" | "selected" > ) => { 
  const classes = useStyles();  
  return(
  <Box className={(expandTab && data.attributes?.news_event_type === configJSON.events) ? classes.btnSection : classes.btnContainer}>
    <Share className={classes.shareBtn}  style={{cursor:'pointer'}} id="share-btn" onClick={()=> handleShareLink(data)} />
    <Edit className={classes.editBtn} onClick={() => editSection(Number(data.id))} id="edit-btn" />
    <Delete
      className={classes.deleteBtn}
      onClick={() => deleteEvent(data.attributes?.admin_event_id, data.attributes?.news_event_type)}
      id="delete-btn"
    />   
  </Box>
);}

const ShowIcon = (data: DataType, expandTab: any, classes: any) => {
  return data.attributes.image.url ?
    <>
      {
        data.attributes.image_or_video === "Image" ? <img
          src={data.attributes.image.url}
          alt=""
          className={classes.img}
        />
          :
          <>
          {!expandTab && <PlayArrowIcon style={{  
            borderRadius: '50%',
            position: 'absolute',
            left: '38px',
            top: '38px',
            fill: 'white',
            background: 'rgba(0, 0, 0, 0.7)'}}/>}
          <video src={data.attributes.image.url} width="100%" height="100%" className={classes.img} muted controls={expandTab} />
          </>
      }
    </> : <Box className={classes.notImgContainer}>
      <PhotoCamera style={{ opacity: 0.5 }} />
    </Box>
}

const AccordionSection = (props: AccordionSectionPropsType) => {
  const { data, selected, handleAccordion} = props;

  if(!data){
    return null;
  }

  const expandTab = Number(data.id) === selected;  
  const classes = useStyles(expandTab);
  const expandedClassTypeNews = data.attributes?.news_event_type === configJSON.news ? classes.ellipsisNews  : classes.ellipsis

  return (
    <Box className={classes.root} key={data.id}>
      <Box className={classes.container}>
        {ShowIcon(data, expandTab, classes)}
      </Box>
      <Box className={classes.boxContainer}>
        <Box className={classes.between}>
          <Tooltip title={data.attributes?.name}> 
          <Typography
            className={`${classes.fontStyle} ${
              expandTab  ? "" : expandedClassTypeNews
            }`}
          >
            {data.attributes?.name}
          </Typography>
          </Tooltip>
            {expandTab ? (
              <ExpandLess
                className={`${classes.expandBtn}`}
                id="expandMore"
                onClick={() => handleAccordion(Number(data.id))}
              />
            ) : (
              <ExpandMore
                className={classes.expandBtn}
                id="expandLess"
                onClick={() => handleAccordion(Number(data.id))}
              />
            )}
        </Box>

        <SubDetailSection expandTab={expandTab} data={data} />

        <Box className={classes.between}>
          <Typography
            className={expandTab ? classes.location : classes.locationExpand}
          >
            {  expandTab ? (
              <span style={{ whiteSpace: 'pre-wrap'}}>{data.attributes?.description}</span>
            ) : (
               <div style={{ display: "flex", alignItems: "center" }}>
                  {data.attributes?.news_event_type === configJSON.events ? 
                    <>
                   <LocationOn className={classes.font15} />
                   <Tooltip  title={data.attributes?.location || "No location available"} >
                      <Typography className={classes.collapseCardLoc}> {data.attributes?.location || '-'}</Typography>
                    </Tooltip>
                    </>
                    :
                    <Typography className={classes.dateStyleClose} >{moment(data.attributes?.event_date).format('D MMM YYYY')}</Typography>
              }
              </div>
            )}
            {(!expandTab || data.attributes?.news_event_type === configJSON.events) && (
              <ActionBtn {...props} />
            )}
          </Typography>
        </Box>

        {(expandTab && data.attributes?.news_event_type === configJSON.news) && (
          <Box className={classes.between}>
            <Typography className={classes.writerSection}><i>Written by {"  "} <span className={classes.fw500} >{data.attributes?.written_by}</span> </i> </Typography>
            <ActionBtn {...props} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

//style
const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: ".5rem",
    background: "#F8F6F1",
    borderRadius: "12px",
    marginBottom: ".2rem",
    position: "relative",
    transition: "all 2s",
    flexDirection: (isOpen) => (isOpen ? "column" : "row"),
  },
  container: {
    flexGrow: (isOpen) => (isOpen ? 0 : 0.1),
  },
  boxContainer: {
    width: "100%",
    padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
  },
  img: {
    borderRadius: "6px",
    transition: "all 0.2s",
    height: (isOpen) => (isOpen ? "180px" : "82px"),
    width: (isOpen) => (isOpen ? "100%" : "82px"),
  },
  between: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  notImgContainer:{
    width: (isOpen) => (isOpen ? "100%" : "82px"),
    height: (isOpen) => (isOpen ? "180px" : "82px"),
    display: 'flex',
    justifyContent:'center', 
    alignItems:'center', 
    border: '1px solid rgb(240, 235, 224)',
    borderRadius: '0.75rem',
    gap: '10px',
  },
  dateContainer: {
    color: "#006A77",
    fontSize: "14px",
    padding: ".4rem 0",
  },
  dateIcon: {
    color: "#006A77",
    fontSize: "14px",
    marginRight: "5px",
  },
  fontStyle: {
    fontStyle: "IBM Plex Sans",
    fontWeight: 500,
    fontSize: "16px",
    color: "#1C2324",
    margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
  },
  collapseCardLoc: {
    fontSize: "15px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    width: "60px",
    height:'20px',
  },
  font15:{
    fontSize: "15px",
  },
  writerSection:{
    color: '#108B88',
  },
  btnContainer: {
    display: "flex",
    flexGrow: 0.1,
    justifyContent: "space-between",
    alignItem: "center",
    gap: "8px",
  },
  shareBtn: {
    color: "#006A77",
    fontSize: "18px",
  },
  editBtn: { color: "#EB8F06", fontSize: "18px", cursor:"pointer" },
  deleteBtn: { color: "#B3261E", fontSize: "18px",cursor:"pointer" },
  expandBtn: {
    position: "absolute",
    right: "10px",
    top: "10px" ,
  },
  location: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "rgba(28, 35, 36, 0.8)",
    width: '100%',
    marginBottom:'8px',
    display: 'contents'
  },
  boxIconsContainer: {
    margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
    display: "flex",
    justifyContent: "space-between",
  },
  boxIcons: {
    display: "flex",
    alignItems: "center",
  },
  fontSmall: {
    fontSize: "13px",
    color:'#006A77',
  },
  locationTextOverflow:{
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "13px",
    textOverflow: "ellipsis",
    lineClamp: 1,
    boxOrient: "vertical",
    width: "60px",
    height:'20px',
    color:'#1C2324',
  },
  whiteColor: {
    color: "#fff",
  },
  ellipsis: {
    overflow: "hidden",
    lineClamp: 1,
    boxOrient: "vertical",
    maxWidth: "80%",
    display: "-webkit-inline-box",
  },
  btnSection: {
    display: "flex",
    float: "right",
    gap: '10px',
    paddingTop: '7px',
    marginRight:'10px',
  },
  locationExpand: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  font18: {
    fontSize: "14px",
  },
  fw500: {
    fontWeight: 500,
  },
  dateStyleClose: {
    color: "#1C2324",
    fontWeight: 450,
    fontSize: "12px",
  },
  ellipsisNews: {
    fontWeight: 400,
    fontSize: "13px",
    height: "60px",
    overflow: "hidden",
    maxWidth: "80%",
    textOverflow: "ellipsis",
    boxOrient: "vertical",
    lineClamp: 3,
    color: "#1c2324c7"
  }
});

export default AccordionSection;

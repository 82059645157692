// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDefaultFormTaluka, IDropdownDetails, IHeadings, IResponseDropdowns, ISimpleDropdownFormData } from "../../../../components/src/interface.web";
import { DropdownKey, formateTalukaBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";
import { ILanguageObject } from "./SimpleDropdownController.web";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
}


interface IDefaultValue {
    id: string | number;
    [key: string]: ILanguageObject | string | number;
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    subDropdown: string,
    dropDown: string,
    dropDownListData: any,
    updateDropdownLoading: boolean,
    OpenCustomModal: boolean,
    CustomModalTitle: string,
    CustomModalMsg: string,
    selectedTab: string;
    defaultForm: IDefaultValue,
    stateDropDownOptions: any,
    stateId: string,
    talukaId: string,
    districtDropDownOptions: any,
    talukaDropDownOptions: any,
    districtId: string,
    updateResponse: any,
    dropDownDataLoading: boolean,
    searchDropdown: string
}
interface SS {
    id: any;
}

interface IRequestBody {
    id?: string
    name: string
    name_english: string
    name_hindi: string
    name_gujrati: string
    active: boolean
}

const defaultValue = {
    id: "",
    english: {
        value: "",
        error: "",
        stateError: "",
        districtError: "",
        talukaError: ""
    },
    gujarati: {
        value: "",
        error: "",
        stateError: "",
        districtError: "",
        talukaError: ""
    },
    hindi: {
        value: "",
        error: "",
        stateError: "",
        districtError: "",
        talukaError: ""

    },
}
// Customizable Area End

export default class LocationsDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    getParticularBlockDataAPICall: string = "";
    updateDetailsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    getStateDetailsApiCallId:string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            fetchHeadingsLoading: false,
            headingsData: [],
            currentExpanded: null,
            subDropdown: "",
            dropDown: "",
            dropDownListData: [],
            updateDropdownLoading: false,
            OpenCustomModal: false,
            CustomModalTitle: "",
            CustomModalMsg: "",
            selectedTab: configJSON.TAB.ENGLISH,
            defaultForm: { ...defaultValue },
            stateDropDownOptions: [],
            stateId: "",
            districtDropDownOptions: [],
            talukaDropDownOptions: [],
            districtId: "",
            talukaId: "",
            updateResponse: [],
            dropDownDataLoading: false,
            searchDropdown: ""
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start  
    handleCondition = (condition: any, truePart: any, falsePart: any) => {
        return condition ? truePart : falsePart;
    };

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.getHeadingsApiCallId) {
                    this.GetHeadingsApiCallIdResp(responseJson)
                }
            }

            if (apiRequestCallId === this.updateDetailsApiCallId) {
                this.UpdateDetailsApiCallIdResp(responseJson)
            }
            if (apiRequestCallId === this.getParticularBlockDataAPICall) {
                this.getParticularBlockDataResp(responseJson)
            }

            if (apiRequestCallId === this.getStateDetailsApiCallId) {
                this.handleGetStateDetails(responseJson)
            }
        }
        // Customizable Area End

    }

    handleGetStateDetails = (responseJson: any) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({
                stateDropDownOptions: responseJson[0]?.data
            })
        }
    }

    getStateData = (title: string | null) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        if (dropdownName && subDropdownName && title) {

            const header = { token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getStateDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    // VALIDATE ALL INPUT

    UpdateDetailsApiCallIdResp = (responseJson: IResponseDropdowns[] | null | undefined) => {
        this.setState({ updateDropdownLoading: false })
        if (!Array.isArray(responseJson) || responseJson.length === 0) {
            this.setCustomModal(true, "Error", "Something went wrong")
            return
        }
        const hasMessageKey = responseJson.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseJson);
            this.setCustomModal(true, title, message)
            if (this.state.currentExpanded) {
                this.getParticularBlockData(this.state.dropDown, this.state.subDropdown, this.state.currentExpanded)
            }
        }
        else {
            this.successUpdate(responseJson);
        }
    }

    handleCloseCustomModal = () => {
        this.setState({
            OpenCustomModal: false,
            CustomModalTitle: "",
            CustomModalMsg: "",
        })
    }

    successUpdate = (responseJson: IResponseDropdowns[]) => {
        if (responseJson && responseJson.length) {
            this.setCustomModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.getParticularBlockData(this.state.dropDown, this.state.subDropdown, this.state.currentExpanded)
            }
        } else {
            this.setCustomModal(true, "Error", "Something went wrong!")
        }
    }



    setCustomModal = (open: boolean, title: string, message: string) => {
        this.setState({
            OpenCustomModal: open,
            CustomModalTitle: title,
            CustomModalMsg: message,
        })
    }
    GetHeadingsApiCallIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }

    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBody)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                updateDropdownLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    formateSimpleDropdownData = (data: IDropdownDetails[]) => {
        let formattedData: any = []
        formattedData = data[0]?.data?.map((obj) => {
            return {
                "id": obj.id,
                "english": {
                    value: obj.name_english,
                    error: ""
                },
                "gujarati": {
                    value: obj.name_gujrati,
                    error: ""
                },
                "hindi": {
                    value: obj.name_hindi,
                    error: ""
                }                
            }
        })

        return formattedData
    }

    getParticularBlockDataResp = (responseJson: any) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropDownDataLoading: false,
                dropDownListData: responseJson,
            })
        }else{
            this.setState({
                dropDownDataLoading: false,
                dropDownListData: [],
            })
        }
    }

    async componentDidMount(): Promise<void> {
        const searchParams = new URLSearchParams(window.location.search);
        const dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        // FETCH HEADING
        if (dropdown !== null && subDropdown !== null) {

            this.setState({
                dropDown: dropdown,
                subDropdown: subDropdown
            })
        }

        if (dropdown && subDropdown) {
            this.getDropdownHeadings(dropdown, subDropdown)
        }
        this.getStateData("State")
}

    // API CALLS

    handleSubmitDropDown = (formData: ISimpleDropdownFormData[]) => {
        const requestBody = this.formateBodyData(formData)
        this.updateDropdownDetails(requestBody)
    }
    handleSubmitTalukaDropDown = (formData: IDefaultFormTaluka[]) =>{
        const requestBody = formateTalukaBodyData(formData)
        this.updateDropdownDetails(requestBody)
    }

    formateBodyData = (formData: any) => {
        let bodyData = []
        bodyData = formData.map((form: any) => {
            if (form?._destroy) {
                return { _destroy: form.id };
            }

            return {
                ...(form.id &&
                {
                    "id": form.id
                }),
                name: form.english.value,
                name_english: form.english.value,
                name_gujrati: form.gujarati.value,
                name_hindi: form.hindi.value,
                active: true,
                state_id: form?.state_id,
                district_id: form?.district_id,
                taluka_id: form?.taluka_id,
            }
        })

        return bodyData
    }

    getParticularBlockData = (dropdown: string, subDropdown: string, title: string | null) => {
        this.setState({
            dropDownDataLoading: true,
            dropDownListData: [],
        })
        const header = { token: this.state.authToken };
        const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getParticularBlockDataAPICall = requestApiMsg.messageId;

        const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${title}`
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
    }
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestApiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchDropdown}`
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
    }

    renderTitle = () => {
        let title = ""
        const searchParams = new URLSearchParams(window.location.search);
        const subDropdown = searchParams.get('sub-dropdown');
        if (subDropdown) {
            title = subDropdown
        }

        return title
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if(isExpanded){
            this.getParticularBlockData(this.state.dropDown, this.state.subDropdown, heading);
        }
    };

    // SEARCH
    setSearchValue = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchDropdown: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }
    // Customizable Area End
}
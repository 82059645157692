import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { debounce } from "../../../components/src/helper";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  handleShareLink:(eventsObj?:any)=> void;
  isShare:boolean;
  linkShare:string;
  messageHeading:string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  chatList:any;
  searchText:string;
  editProfileID:string;
  error:string;
  isLoading:boolean;
  page: number;
  token:string | null;
  openSucessModal: boolean;
  isSent:any;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface IChat {
  id: string;
  muted: boolean;
  name: string;
  logo: string | null;
}
export default class LinkShareController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  sendMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      chatList:[],
      searchText:"",
      editProfileID:"",
      error:"",
      isLoading:false,
      page: 1,
      isSent:[],
      openSucessModal:false,
      token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,     
    
      
      
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

 
    // Customizable Area Start
    async componentDidMount() {
      this.setState({ isLoading: true })
      this.getChatList();
      //latest changes ends
    }
  

  // Customizable Area Start

  handleScroll = (event: any) => {
    const scrollableDiv = event;
    if(scrollableDiv.scrollHeight - scrollableDiv.scrollTop === scrollableDiv.clientHeight){
      this.userChatListRenderer();
    };
  }

  debouncedHandleScroll = (event: EventTarget) => {
    debounce(this.handleScroll(event), 1500);
  }

  filterList = (e: any) => {
    const searchQuery = e.target.value;
    this.setState({ searchText: searchQuery, chatList: [], page: 1, isLoading: searchQuery.length ? false : true },
      () => {
        this.getChatList(searchQuery);
      });

  }

  userChatListRenderer = () => {
    if (this.props.isShare) {    
      return this.getChatList()
    }
  }

  handleSendShareLink = (chatId: string,link: string, message: string) => {
    console.log("send ButtonClick", chatId, link, message)
    if(link){
      this.sendChatMessage(chatId,link,message)
    }
  };

  getChatListCall = ({ successResponse, errorResponse }: any) => {
    if (successResponse.errors) {
      const [error] = successResponse.errors
      this.setState({
        error: error.token,
      });

    } else {
      let data: any = successResponse.data  
      if (data) {
        this.apiRequestCallGetChatListCallResponse(data);
      } else {
        this.setState({
          error: 'Something went wrong, data not found',
        });
      }
    }
    this.setState({ isLoading: false })
  }  

  apiRequestCallGetChatListCallResponse = (data: any[]) => {    
    const results = data.map((item:any) => {
      return {
        id: item.id,
        name: item.attributes.name ?? `${item.attributes.first_name} ${item.attributes.last_name}`,
        logo: item?.attributes?.logo?.url ?? null
      };
    })
    const arrChatList = [...this.state.chatList, ...results];
    this.setState(prev => {
      return {
        chatList: [...arrChatList],
        page: prev.page + 1
      }
    });
  }

sendMessageApiCall({ successResponse, errorResponse }: any) {
    if (successResponse.errors) {
      const [error] = successResponse.errors
      this.setState({
        error: error.token,
      });
    } else {
      let data: any = successResponse.data
      if (data) { 
         this.setState({
          openSucessModal: true,
        });                  
      } else {
        this.setState({
          error: 'Something went wrong, data not found',
          // loading: false
        });
      }
    }
  }

  handleClose = () => {
    this.setState({
    openSucessModal:false
    }); 
  };

  handleSendButtonClick = (itemIdx: number) => {
    this.state.isSent?.push(itemIdx);
    this.setState({
      isSent: this.state.isSent
    })


    // You can also perform other actions here after the button is clicked
    // ...
  };

  sendChatMessage = async (chatId: string,link: string, message: string) => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.apiContentType
    };
    const data = {
      "attributes": {
        "chat_id": chatId,
        "reply_message_id": "",
        "reply_message_user_id": "",
        "message": message.trim(),
        "link_share":link, 
        "attachment": '',
        "attachment_file_type": ''
      }
    }
    const httpBody = {
      data: data
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 
  getChatList = async (search?: string) => {    
   let queryParameters = [];   
   
    queryParameters.push(`search=${search || this.state.searchText}`)

    const queryParametersString = queryParameters.filter(Boolean).join('&') 
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Accept": configJSON.acceptAll,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatListApiEndPoint}?${queryParametersString}&page=${this.state.page}&per_page=${configJSON.perPageUserList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorResponse) {
      this.setState({
        error: "something went wrong!",
      });

      return
    }   
    // Customizable Area Start

    if (apiRequestCallId === this.getChatListApiCallId) {
      this.getChatListCall({ successResponse, errorResponse });
    }

    if (apiRequestCallId === this.sendMessageApiCallId) {
      this.sendMessageApiCall({ successResponse, errorResponse });
    }
   
    // Customizable Area End
  }



  // Customizable Area End
}

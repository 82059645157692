import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { debounce } from "lodash";
import { IILibraryTopic, ILibraryChapter, ILibraryChaptersResponse, ILibraryTopicsResponse, TEditLibraryResp, TLibraryDetails } from "../../../components/src/interface.web";
import React from "react";
import { extractFileNameFromUrl, handleDownload } from "../../../components/src/helper";

export interface LibraryAttributes {
  topic: string,
  description: string,
  libraries_faq_id: number
  language: string,
  image: {
    url: string
  },
  updated_at: string,
  library_chapter_id?: number,
  name_hindi?: string,
  name_gujarati?: string,
  name?: string,

}

interface UpdateReduce {
  topic: string,
  description: string,
  language: string,
  faqId: number,
  libraryChapterId: number | undefined,
  image: string,
  id: number
}

export interface Library {
  id: string,
  type: string
  attributes: LibraryAttributes
}
let intialFormData = [
  {
    topic: "",
    language: "English",
    description: "",
  },
  {
    topic: "",
    language: "Gujarati",
    description: "",
  },
  {
    topic: "",
    language: "Hindi",
    description: "",
  },
]

let initialFaqFormData = [
  {
    question: "",
    language: "English",
    description: "",
  },
  {
    question: "",
    language: "Gujarati",
    description: "",
  },
  {
    question: "",
    language: "Hindi",
    description: "",
  },
];

export interface FaqCategoryResp {
  id?: number,
  name: string,
  name_gujarati: string,
  name_hindi: string,
  updated_at?: string,
  created_at?: string,
  document?: {
    url: string
  },
  image?: {
    resized: {
      url: string
    },
    url: string
  }

};

export interface FaqResponse {
  id: string,
  type: string,
  attributes: {
    created_at: string,
    description: string,
    document: string,
    faq_category_id: {
      created_at: string,
      document: {
        url: string,
      },
      id: number,
      image: {
        url: string,
        resized: {
          url: string
        }
      },
      name: string,
      name_gujarati: string,
      name_hindi: string,
      updated_at: string,
    },
    image: {
      url: string,
      thumb: {
        url: string
      }
    },
    image_or_video: string,
    language: string,
    libraries_faq_id: {
      admin_user_id: number,
      created_at: string,
      id: number,
      updated_at: string
    },
    question: string,
    updated_at: string
  }
};

export interface FaqssResponse {
id: string,
type: string,
attributes: {
  created_at: string,
  document: string,
image: string ,
name: string,
name_english: string,
name_gujarati: string,
name_hindi: string,
updated_at: string
}};

export interface FormDataInitial {
  topic: string,
  language: string,
  description: string
}

type SelectLibrary = string | number | null

type FaqCategoryIdTypes = string | number | null

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedLibrary: number | null
  languageTab: string
  isLibraryModalOpen: boolean
  img_aux: string,
  librariesData: Library[],
  faqsData: any,
  formData: any,
  faqFormData: any,
  libraryFormImage: any
  deleteLiraryModal: boolean
  selectedDeleteLibrary: SelectLibrary
  isAddingLibrary: boolean
  isAddingFaq: boolean
  alertOpen: boolean
  isDeleted: boolean
  isLibraryAdded: boolean
  isFaqAdded: boolean
  isDeleting: boolean
  libraryEditModal: boolean
  selectedEditLibrary: number | null
  selectedVideo: number | null
  librariesDataAll: any
  isSelectedFileBig: boolean
  actionApiError: boolean
  isFetchingLibraryData: boolean
  selectedFaqCategory: any
  faqCategories: any
  faqAddModelOpen: boolean
  faqEditModel: boolean
  selectedEditFaq: number | null
  selectedDeleteFaq: number | null
  deleteFaqModal: boolean
  isDeletingFaq: boolean
  isDeletedFaq: boolean
  faqsDataAll: any
  isFecthingFaqData: boolean
  isShare: boolean,
  linkShare: string
  messageHeading: string
  libraryPage: number
  faqPage: number
  per_page: number
  librarySearchTopic: string
  faqSearchTopic: string,
  selectedFAQId: FaqCategoryIdTypes
  selectedFAQIdTopic: FaqCategoryIdTypes
  getFaqQuestionPage: number
  faqQuestions: any
  prevSelectedFAQId: FaqCategoryIdTypes
  isFetchingQuestions: boolean
  // NEW STATES LIBRARY
  libraryChaptersLoading: boolean
  libraryChaptersData: ILibraryChapter[]
  libraryChaptersCurrentPage: number
  libraryChaptersPerPage: number
  allLibraryChapters: ILibraryChapter[]
  allLibraryChaptersLoading: boolean
  // LIBRARY SUB TOPICS 
  libraryTopicsLoading: boolean,
  libraryTopicsData: IILibraryTopic[],
  libraryTopicsCurrentPage: number
  libraryTopicsPerPage: number

  selectedLibraryChapterId: FaqCategoryIdTypes
  selectedSubLibraryTopicId: FaqCategoryIdTypes
  selectedEditFormDataValue: any
  selectedDropDownValue: string
  libraryId: number,
  successFaq: boolean,
  addFaq: boolean,
  successLibrary: boolean,
  addLibrary: boolean, 
  token: string | null;
  document: File | null;
  loading: boolean;
  openModal: string;
  errorMessage: string;
  fileName: string;
  inputRef: React.RefObject<HTMLInputElement>;
  // MODAL
  customModalOpen: boolean;
  customModalTitle: string;
  customModalDesc: string;

  downloadProcess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllLibraryGetApiCallId: string = "";
  getAllFaqDataGetApiCallId: string = "";
  getFaqCategoryApiCallId: string = "";
  addAllLibraryPostApiCallId: string = "";
  addFaqPostApiCallId: string = "";
  editAllLibraryPatchApiCallId: string = "";
  editAllFaqPatchApiCallId: string = "";
  deletLibraryDeleteApiCallId: string = "";
  editLibraryGetApiCalled: string = "";
  faqDeleteApiCallId: string = "";
  getFaqApiCallId: string = "";
  getFaqQuestionsCallId: string = ""
  // NEW LIBRARY
  getLibraryChaptersApiCallId: string = "";
  getLibraryChapterApiCallId: string = "";
  getLibraryTopicsByChapterIdApiCallId: string = "";
  allLibrayChaptersCallId: string = ""; // LIBRARY FORM DROPDOWN 
  exportFileApiCallId: string = "";
  importFileApiCallId: string = "";
  downloadTemplateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedLibrary: null,
      languageTab: "english",
      img_aux: '',
      librariesData: [],
      faqsData: [],
      libraryFormImage: { src: "", file: null },
      isLibraryModalOpen: false,
      selectedDeleteLibrary: null,
      formData: JSON.parse(JSON.stringify(intialFormData)),
      faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)),
      isAddingLibrary: false,
      isAddingFaq: false,
      deleteLiraryModal: false,
      alertOpen: false,
      isDeleted: false,
      isLibraryAdded: false,
      isFaqAdded: false,
      isDeleting: false,
      libraryEditModal: false,
      selectedEditLibrary: null,
      selectedVideo: null,
      librariesDataAll: [],
      isSelectedFileBig: false,
      actionApiError: false,
      isFetchingLibraryData: false,
      selectedFaqCategory: 0,
      faqCategories: [],
      faqAddModelOpen: false,
      faqEditModel: false,
      selectedEditFaq: null,
      selectedDeleteFaq: null,
      deleteFaqModal: false,
      isDeletingFaq: false,
      isDeletedFaq: false,
      faqsDataAll: [],
      isFecthingFaqData: false,
      isShare: false,
      linkShare: '',
      messageHeading: '',
      libraryPage: 1,
      faqPage: 1,
      per_page: 10,
      librarySearchTopic: "",
      faqSearchTopic: "",
      selectedFAQId: null,
      selectedFAQIdTopic: null,
      getFaqQuestionPage: 1,
      faqQuestions: [],
      prevSelectedFAQId: null,
      isFetchingQuestions: false,
      // NEW STATES LIBRARY
      libraryChaptersLoading: false,
      libraryChaptersData: [],
      libraryChaptersPerPage: 10,
      libraryChaptersCurrentPage: 1,
      allLibraryChapters: [],
      allLibraryChaptersLoading: false,
      // LIBRARY SUB TOPICS 
      libraryTopicsLoading: false,
      libraryTopicsData: [],
      libraryTopicsCurrentPage: 1,
      libraryTopicsPerPage: 5,
      selectedLibraryChapterId: null,
      selectedSubLibraryTopicId: null,
      selectedEditFormDataValue: null,
      selectedDropDownValue: "Select Chapter",
      libraryId: 0,
      successFaq: false,
      addFaq: false,
      successLibrary: false,
      addLibrary: false,
      token: localStorage.getItem("tokenn"),
      document: null,
      loading: false,
      openModal:"",
      errorMessage: "",
      fileName: "",
      inputRef: React.createRef(),
      // MODAL
      customModalOpen: false,
      customModalTitle: "",
      customModalDesc: "",
      downloadProcess: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getLibraryChaptersApiCallId:
        this.handleResponseGetLibryChptApiCallId(responseJson)
        break;
      // ALL LIBRARY CHAPTER FOR DROPDOWN
      case this.allLibrayChaptersCallId:
        this.allLibrayChaptersResp(responseJson)
        break;

        case this.getLibraryChapterApiCallId:
        this.handleFirstGetLibryChptApiCallId(responseJson)
        break;
      case this.getLibraryTopicsByChapterIdApiCallId:
        this.handleRespGLbryTpcByChptIdApiCallId(responseJson)
        break;

      case this.getAllLibraryGetApiCallId:
        this.haddleGetAllLibraries(responseJson)
        break;
      // ADD - LIBRARY TOPIC
      case this.addAllLibraryPostApiCallId:
        this.handleAddAllLibraryPostApiCall(responseJson)
        break;
      case this.addFaqPostApiCallId:
        this.handleAddFaqPostApiCall(responseJson);
        break;
      case this.deletLibraryDeleteApiCallId:
        if (responseJson?.error) {
          this.setState({ isDeleting: false, actionApiError: true });
          break;
        }
        let libraryChaptersData = [...this.state.libraryChaptersData];
        let libraryTopicsData = [...this.state.libraryTopicsData];
        libraryTopicsData =  libraryTopicsData.filter((item) => {
          return this.state.selectedDeleteLibrary !== item.attributes.libraries_faq_id
        });
        libraryChaptersData = libraryChaptersData.map((item) => {
          if(item.id === this.state.selectedLibraryChapterId){
            return {
              ...item,
              attributes: {
                ...item.attributes,
                topic_count: libraryTopicsData.length
              }
            }
          }
          return {...item };
        });
        this.setState({ selectedDeleteLibrary: null, libraryChaptersData, libraryTopicsData, isDeleting: false, isDeleted: true, actionApiError: false, libraryPage: 1, librariesData: [], deleteLiraryModal: false })
        this.openCustomModal(true, "Success", "Library deleted Successfully.")

        setTimeout(this.setStateOnDeleteLibrary, 2000);
        break;
      // GET - LIBRARY TOPIC DETAILS
      case this.editLibraryGetApiCalled:
        this.handleEditLibraryResponse(responseJson);
        break;

      case this.editAllFaqPatchApiCallId:
        this.handleEditAllFaqPatchApiCall(responseJson)
    break;
      case this.getAllFaqDataGetApiCallId:
        this.handleGetAllFaqs(responseJson)
        break;
      case this.getFaqCategoryApiCallId:
        this.handleGetFaqCategory(responseJson)
        break;
      case this.faqDeleteApiCallId:
        if (responseJson?.error) {
          this.setState({ isDeletingFaq: false, actionApiError: true })
          break;
        }
        this.setState({ deleteFaqModal: false, isDeletingFaq: false, isDeletedFaq: true, getFaqQuestionPage: 1, faqQuestions: [], actionApiError: false }, () => {
          this.getFaqQuestions(this.state.selectedFAQId)
        })
        this.openCustomModal(true, "Success", "FAQ deleted Successfully.")
        setTimeout(this.setStateOnDeleteFaq, 2000)
        break;
      case this.getFaqApiCallId:
        let faq_category = responseJson["data"][0]["attributes"]["faq_category_id"]?.id
        let image = responseJson["data"][0]["attributes"]["image"]
        let library_faq_id = responseJson["data"][0]["attributes"]["libraries_faq_id"]?.id
        let faqFormData = responseJson.data.map((item: any) => {
          let { question, description, language } = item.attributes
          return { id: item.id, question, description, language }
        })
        this.setState({ faqFormData, selectedFaqCategory: faq_category, libraryFormImage: { src: image?.url, file: null }, faqEditModel: true, selectedEditFaq: library_faq_id, languageTab: "English" })
        break;
      case this.getFaqQuestionsCallId:
        this.handleGetFaqQuestions(responseJson);
        break;
        // EDIT - LIBRARY TOPIC
      case this.editAllLibraryPatchApiCallId:
        this.successRespEdit(responseJson)
        if (responseJson?.error) {
          this.setState({ isAddingLibrary: false, actionApiError: true, selectedDropDownValue: "Select Chapter" })
          break;
        }
        this.setState({ isAddingLibrary: false,successLibrary:true, selectedDropDownValue: "Select Chapter", isLibraryAdded: true, libraryEditModal: false, alertOpen: false, actionApiError: false, libraryPage: 1, librariesData: [] })
        this.setStateOnEditLibrary()
        break;
      case this.exportFileApiCallId: 
        this.exportFileApiResponse(responseJson);
        break;
      case this.importFileApiCallId:
        this.importFileApiResponse(responseJson);
        break;
      case this.downloadTemplateApiCallId:
        this.templateApiResponse(responseJson);
        break;
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let mesage = new Message(getName(MessageEnum.AccoutLoginSuccess));
    mesage.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(mesage);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAllFaqs();
    this.getFaqCategory();
    this.getFirstChapters();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.selectedFAQId && prevState.selectedFAQId !== this.state.selectedFAQId && this.state.prevSelectedFAQId !== this.state.selectedFAQId) {
      this.setState({ faqQuestions: [], getFaqQuestionPage: 1, prevSelectedFAQId: this.state.selectedFAQId }, () => {
        this.getFaqQuestions(this.state.selectedFAQId)
      })

    }
    if (((prevState.isLibraryModalOpen !== this.state.isLibraryModalOpen) && this.state.isLibraryModalOpen) ||
      ((prevState.libraryEditModal !== this.state.libraryEditModal) && this.state.libraryEditModal)) {
      this.setState({ languageTab: "english" })
      this.getAllLibraryChapters()
    }
  }

  onModalClose = () => {
    this.setState({
      openModal: "",
      loading: false,
      document: null,
      isLibraryModalOpen: false,
      faqAddModelOpen: false
    });
  }

  importFileApiResponse = (responseJson: { error?: string; message?: string }) => {
    if (responseJson && responseJson.error) {
      this.setState({ errorMessage: responseJson.error, openModal: "error", loading: false });
    } else {
      setTimeout(() => { this.setState({ openModal: "", loading: false, document: null, isLibraryModalOpen: false, faqAddModelOpen: false }) }, 2500);
    }
  }

  exportFileApiResponse = (responseJson: { error?: string; csv_url: string}) => {
    if (responseJson && responseJson.error) {
      this.setState({ errorMessage: responseJson.error, openModal: "error" });
    } else {
      handleDownload(responseJson.csv_url);
      let fileName = extractFileNameFromUrl(responseJson.csv_url);
      this.setState({ openModal: "export", fileName: fileName ?? "", downloadProcess: false, });
      setTimeout(() => { this.setState({ openModal: "", isLibraryModalOpen: false, faqAddModelOpen: false }) }, 2500);
    }
  }

  templateApiResponse = (responseJson: { error?: string; data: { attributes: { library_file: { url: string } } | {faq_file: { url: string } } }; }) => {
    if (responseJson && responseJson.error) {
      this.setState({ errorMessage: responseJson.error, openModal: "error" });
    } else {
      if(this.state.isLibraryModalOpen && "library_file" in responseJson.data.attributes){
        handleDownload(responseJson.data.attributes.library_file.url);
        let fileName = extractFileNameFromUrl(responseJson.data.attributes.library_file.url);
        this.setState({ openModal: "template", fileName: fileName ?? "" });
      } else if("faq_file" in responseJson.data.attributes){
        handleDownload(responseJson.data.attributes.faq_file.url);
        let fileName = extractFileNameFromUrl(responseJson.data.attributes.faq_file.url);
        this.setState({ openModal: "template", fileName: fileName ?? "" });
      }
      setTimeout(() => { this.setState({ openModal: "", isLibraryModalOpen: false, faqAddModelOpen: false }) }, 2500);
    }
  }

  handleAddFaqPostApiCall = (responseJson: any) => {
    if (responseJson?.error) {
      this.setState({ isAddingFaq: false, actionApiError: true })
    }
    this.setState({ isAddingFaq: false, isFaqAdded: true,faqAddModelOpen: false,  actionApiError: false, selectedFaqCategory: 0, getFaqQuestionPage: 1, faqQuestions: [] }, () => {
      this.getFaqQuestions(this.state.selectedFAQId)
    })
    this.setStateOnAddFaq()
  }
  handleEditAllFaqPatchApiCall = (responseJson: any) => {
    if (responseJson?.error) {
      this.setState({ isAddingFaq: false, actionApiError: true })
    }
    this.setState({ isAddingFaq: false, isFaqAdded: true, getFaqQuestionPage: 1, faqQuestions: [], actionApiError: false }, () => {
      this.getFaqQuestions(this.state.selectedFAQId)
    })
    this.setStateOnEditFaq();
  }
  handleEditLibraryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.getLibraryName(responseJson.data[0].attributes.library_chapter_id)
      this.updateFormData(responseJson.data as TLibraryDetails[])
      this.setState({
        selectedEditFormDataValue: responseJson.data,
        libraryFormImage:{
          src:  responseJson.data[0]?.attributes?.image.url,
          file: null
        }
      })
    }
  }
  handleAddAllLibraryPostApiCall = (responseJson: any) => {
    this.successRespAdd(responseJson)
    if (responseJson?.error) {
      this.setState({ isAddingLibrary: false, actionApiError: true })
    }
    this.setState({ isAddingLibrary: false,successLibrary:true, isLibraryAdded: true, actionApiError: false, libraryPage: 1, librariesData: [], selectedDropDownValue: "Select Chapter" })
    this.setStateOnAddLibrary();
  }

  successRespAdd = (responseJson: { data: TLibraryDetails[] }) => {
    if (responseJson && responseJson.data && responseJson.data?.length) {
      const englishObj = responseJson.data.find((item) => item.attributes.language == "English")

      const chapterId = String(englishObj?.attributes.library_chapter_id)
      const cloneChapters = [...this.state.libraryChaptersData]

      const updatedChapters = cloneChapters.map((item) => {
        return String(item.id) === String(chapterId) ?
          {
            ...item,
            attributes: {
              ...item.attributes,
              topic_count: Number(item.attributes.topic_count) + 1
            }
          }
          : item
      })
      this.setState({ libraryChaptersData: updatedChapters, selectedSubLibraryTopicId: null, libraryTopicsCurrentPage: 1 })
      if (englishObj && String(this.state.selectedLibraryChapterId) == String(englishObj.attributes.libraries_faq_id) ) {
        const subLibraries = [englishObj, ...this.state.libraryTopicsData]
        this.setState({ libraryTopicsData: subLibraries })
      }
    }
  }
  successRespEdit = (responseJson: TEditLibraryResp) => {
    if (responseJson && responseJson.data && responseJson.data.data.attributes.libraries && responseJson.data.data.attributes.libraries.data.length && this.state.selectedLibraryChapterId) {
      const responseData = responseJson.data.data.attributes.libraries.data
      const englishObj = responseData.find((item) => item.attributes.language == "English")
      const cloneTopics = [...this.state.libraryTopicsData]
      if (englishObj) {
        const index = cloneTopics.findIndex(item => String(item.attributes.libraries_faq_id) == String(englishObj.attributes.libraries_faq_id));
        if (index !== -1) {
          cloneTopics.splice(index, 1);
          cloneTopics.unshift(englishObj);
          this.setState({ selectedSubLibraryTopicId: null, libraryTopicsData: cloneTopics })
        }
      }
    }
  }
  handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    props: {
    setFieldValue: (name: string, value: File) => void;
  }) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.setFieldValue("document", e.target.files[0]);
      this.setState({ document: e.target.files[0] });
    }
  };
  
  openImportModal = () => {
    this.setState({ openModal: "import" });
  }

  closeModal = () => {
    this.setState({ openModal: "", document: null });
  }

  importFile = async (values: { document: File | null }) => {
    this.setState({ loading: true, fileName: values?.document?.name ?? "", openModal: "importing" });
    const header = { token: this.state.token, type: "admin_user" };
    let formData = new FormData();
    if (values.document) {
      formData.append("[file]", values.document);
    }
    const url = this.state.isLibraryModalOpen
    ? `/mobile_and_web/farm_dairy/mattera_pedia_faq_bulk_uploadings`
    : `/mobile_and_web/farm_dairy/create_faq`;

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.importFileApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),url);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  exportFile = async () => {
    this.setState({ downloadProcess: true, fileName: "" })
    const header = { token: this.state.token, type: "admin_user" };
    const url = this.state.isLibraryModalOpen
    ? `/mobile_and_web/farm_dairy/library_export`
    : `/mobile_and_web/farm_dairy/faq_export`;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.exportFileApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),url);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadTemplate = async() => {
    const header = { token: this.state.token, type: "admin_user" };
    const url = this.state.isLibraryModalOpen
    ? `/mobile_and_web/farm_dairy/mattera_pedia_faq_bulk_uploadings`
    : `/mobile_and_web/farm_dairy/faq_template`;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.downloadTemplateApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),url);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getToken = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem("tokenn")
    }
    return configJSON.token

  }
  handleConditions = (condition: any, trueSta: any, falseSta: any)=> {
    return condition ? trueSta : falseSta
  }
  handleGetFaqCategory = (responseJson: FaqCategoryResp[]) => {
    if (responseJson?.length) {
      this.setState({ faqCategories: responseJson })
    }
  }

  handleClick = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };

  updateFormData = (data: TLibraryDetails[]) => {
    const sortOrder = ["English", "Gujarati", "Hindi"];
    data.sort((aItem, bItem) => sortOrder.indexOf(aItem.attributes.language) - sortOrder.indexOf(bItem.attributes.language))
    const formattedresponse = data.map(item => ({
        topic: item.attributes.topic,
        description: item.attributes.description,
        language: item.attributes.language,
        faqId: item.attributes.libraries_faq_id,
        libraryChapterId: item.attributes.library_chapter_id,
        image: item.attributes.image.url,
        id: Number(item.id)
    }));
    this.setState({
      formData: formattedresponse
    })

  }

  handleGetFaqQuestions = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ faqQuestions: [...this.state.faqQuestions, ...responseJson.data], getFaqQuestionPage: this.state.getFaqQuestionPage + 1, isFetchingQuestions: false })
    }
  }
  handleResponseGetLibryChptApiCallId = (responseJson: ILibraryChaptersResponse | undefined) => {
    if (responseJson && responseJson.data) {
      this.setState({
        libraryChaptersLoading: false,
        libraryChaptersData: [...this.state.libraryChaptersData, ...responseJson.data]
      })
    } else {
      this.setState({
        libraryChaptersLoading: false,
        libraryChaptersData: []
      })
    }
  }


  haddleGetAllLibraries = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ librariesData: [...this.state.librariesData, ...responseJson.data], librariesDataAll: [...this.state.librariesData, ...responseJson.data], isFetchingLibraryData: false, libraryPage: this.state.libraryPage + 1 })
    }
  }

  handleGetAllFaqs = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ faqsData: [...this.state.faqsData, ...responseJson.data], isFecthingFaqData: false, faqPage: this.state.faqPage + 1 })
    }
  }

  handleFirstGetLibryChptApiCallId = (responseJson: ILibraryChaptersResponse | undefined) => {
    if (responseJson && responseJson.data) {
      this.setState({
        libraryChaptersLoading: false,
        libraryChaptersData: responseJson.data
      })
    } else {
      this.setState({
        libraryChaptersLoading: false,
        libraryChaptersData: []
      })
    }
  }

  handleRespGLbryTpcByChptIdApiCallId = (responseJson: ILibraryTopicsResponse | undefined) => {
    if (responseJson && responseJson.data) {
      this.setState({
        libraryTopicsLoading: false,
        libraryTopicsData: [...this.state.libraryTopicsData, ...responseJson.data]
      })
    } else {
      this.setState({
        libraryTopicsLoading: false,
        libraryTopicsData: [],
      })
    }
  }

  getEditFormData = (editFormId: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
    };
    this.editLibraryGetApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint + `?libraries_faq_id=${editFormId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEditFormCategoryMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setStateOnAddLibrary = () => {
    this.setState({ isLibraryAdded: false, formData: JSON.parse(JSON.stringify(intialFormData)), isLibraryModalOpen: false, libraryFormImage: { src: "", file: null }, alertOpen: false, actionApiError: false })
  }
  setStateOnAddFaq = () => {
    this.setState({ isFaqAdded: false, successFaq: true , faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), libraryFormImage: { src: "", file: null }, alertOpen: false, actionApiError: false })
  }

  setStateOnEditFaq = () => {
    this.setState({ isFaqAdded: false, successFaq: true, faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), faqEditModel: false, selectedFaqCategory: 0, libraryFormImage: { src: "", file: null }, alertOpen: false, actionApiError: false })
  }
  setStateOnDeleteLibrary = () => {
    this.setState({ deleteLiraryModal: false, isDeleted: false })
  }
  setStateOnDeleteFaq = () => {
    this.setState({ deleteFaqModal: false, isDeletedFaq: false })
  }
  setStateOnEditLibrary = () => {
    this.setState({ isLibraryAdded: false,  formData: JSON.parse(JSON.stringify(intialFormData)), libraryEditModal: false, libraryFormImage: { src: "", file: null }, alertOpen: false })
  }

  onClickReadMore = (index: number) => {
    this.setState({ selectedLibrary: index })
  }

  onClickReadLess = () => {
    this.setState({ selectedLibrary: null })
  }

  handleButtonCancel = () => {
    this.setState({ isLibraryModalOpen: false,successLibrary: false,addLibrary:false,selectedDropDownValue: "Select Chapter",addFaq: false, successFaq: false,  deleteLiraryModal: false, libraryEditModal: false, actionApiError: false, alertOpen: false, formData: JSON.parse(JSON.stringify(intialFormData)), libraryFormImage: { src: "", file: null }, deleteFaqModal: false })
    document.body.style.overflow = '';
  }
  handleTabChange = (event: any, tabss: string) => {
    this.setState({ languageTab: tabss })
  }
  handleAddLibrary = () => {
    this.setState({ isLibraryModalOpen: true, languageTab: "english", formData: JSON.parse(JSON.stringify(intialFormData)), alertOpen: false })
    document.body.style.overflow = 'hidden';
  }

  updatedTopicName = (event: any, index: number) => {
    let newFormData = this.state.formData
    newFormData[index].topic = event.target.value
    this.setState({ formData: newFormData, alertOpen: false })
  }

  getlibraryId = (name: string) => {
    const filterData = this.state.libraryChaptersData.filter((item) => (
      item.attributes.name === name && item.id
    ))
    return Number(filterData[0].attributes.id);
  }

  updatedlessonID = (event: any, index: number) => {
    let newFormData = this.state.formData
    newFormData[index].libraryId = this.getlibraryId(event.target.value)
    this.setState({ formData: newFormData, alertOpen: false, selectedDropDownValue: event.target.value, libraryId: this.getlibraryId(event.target.value) })
  }

  updatedDescription = (event: any, index: number) => {
    let newFormData = this.state.formData
    newFormData[index].description = event.target.value
    this.setState({ formData: newFormData, alertOpen: false })
  }

  convertFileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  handleAlertCancel = () => {
    this.setState({ alertOpen: false, isSelectedFileBig: false })
  }

  handleSearch = (event: any) => {
    let value = event.target.value
    this.setState({
      librarySearchTopic: value,
      libraryChaptersLoading: true,
      selectedLibraryChapterId: null, 
      libraryChaptersData: [],
      libraryChaptersCurrentPage: 1,
      // RESET SUB LIBRARIES STATES
      selectedSubLibraryTopicId: null, 
      libraryTopicsData: [],
      libraryTopicsCurrentPage: 1,      
    }, () => {
      this.getLibraryChapters()
    })
  }

  debouncedGetFaqsList = debounce(this.getAllFaqs, 500);

  handleFaqSearch = (event: any) => {
    let value = event.target.value.trim()
    this.setState({ faqSearchTopic: value, faqPage: 1, faqsData: [], isFecthingFaqData: true })
    this.debouncedGetFaqsList()
  }

  handleShareLink = (materrapediaObj?: any) => {
    let shareLink;
    let heading;
    if (materrapediaObj) {
      if (materrapediaObj.type === 'faqs') {
        shareLink = configJSON.materrapediaShareLink + 'materrapediafaq/' + materrapediaObj.type + '/' + materrapediaObj.attributes.faq_category_id.id + '/' + materrapediaObj.id
        heading = materrapediaObj.type
      } else {
        shareLink = configJSON.materrapediaShareLink + 'materrapedialibraries/' + materrapediaObj.type + '/' + materrapediaObj.id
        heading = `${materrapediaObj.type} : ` + materrapediaObj.attributes?.topic
      }
      this.setState({
        isShare: !this.state.isShare,
        linkShare: shareLink,
        messageHeading: heading
      })
    } else {
      return
    }
  }

  handleImageSelect = async (event: React.ChangeEvent<any>) => {
    const file: File = event.target.files[0]
    let mediaType = file.type.includes("video") ? "video" : "image"
    if ((file.size / (1024 * 1024) > 250)) {
      this.setState({ isSelectedFileBig: true })
      return
    }
    let base64Url = await this.convertFileToBase64(file)
    this.setState({ libraryFormImage: { src: base64Url, file } })
  }


  handleClickDeleteIcon = (libraries_faq_id: number) => {
    this.setState({ selectedDeleteLibrary: libraries_faq_id, deleteLiraryModal: true })
  }

  handleClickFaqDeleteIcon = (deleteId: number) => {
    this.setState({ selectedDeleteFaq: deleteId, deleteFaqModal: true })
  }

  handleClickFaqEditIcon = (editId: number) => {
    if (!editId) return null;
    this.getFaq(editId)
    this.setState({ selectedEditFaq: editId })
    document.body.style.overflow = 'hidden';
  }

  librarySubmissionValidation = (): boolean => {
    let valid = true

    for (let item of this.state.formData) {
      if (!item.topic.trim() || !item.description.trim() || !this.state.libraryFormImage?.src || this.state.selectedDropDownValue == "Select Chapter") {
        valid = false
        this.setState({ languageTab: item.language })
        break
      }
    }
    return valid
  }


  getFileTypeFromRegularUrl = (imageUrl: string) => {
    const extension: string = imageUrl.split('?')[0].split('.').pop()?.toLowerCase() || '';
    const imageExtension: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtension: string[] = ['mp4', 'avi', 'mov', 'wmv', 'flv', "webm", "mkv"];

    if (videoExtension.includes(extension)) {
      return "video"
    }
    if (imageExtension.includes(extension)) {
      return "img"
    }
    return null
  }

  getFileTypeFromBase64Url = (base64Url: any) => {
    const match = base64Url.match(/^data:(.+);base64,/);
    if (match && match[1].startsWith('image/')) {
      return "img"
    }
    if (match && match[1].startsWith('video/')) {
      return "video"
    }
    return null
  }


  checkMediaType = (imageUrl: string): any => {
    if (!imageUrl && typeof imageUrl !== "string") return null
    if (imageUrl.startsWith("http")) {
      return this.getFileTypeFromRegularUrl(imageUrl)
    } else {
      return this.getFileTypeFromBase64Url(imageUrl)
    }
  }

  librarySubmissionEditValidation = (): boolean => {
    let valid = true
    for (let item of this.state.formData) {
      if (!item.topic.trim() || !item.description.trim() || !this.state.libraryFormImage?.src) {
        valid = false
        this.setState({ languageTab: item.language })
        break
      }
    }
    return valid
  }

  handleAddFaqForm = () => {
    this.setState({ faqAddModelOpen: true, languageTab: "english", faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), alertOpen: false, selectedFaqCategory: 0, libraryFormImage: { src: "", file: null }, actionApiError: false })
    document.body.style.overflow = 'hidden';
  }
  handleEventChange = (event: any) => {
    this.setState({ selectedFaqCategory: event?.target?.value })
  }
  updatedFaqQuestionName = (event: any, index: number) => {
    let newFaqFormData = this.state.faqFormData
    newFaqFormData[index].question = event.target.value
    this.setState({ faqFormData: newFaqFormData, alertOpen: false })
  }
  updatedFaqDescription = (event: any, index: number) => {
    let newFaqFormData = this.state.faqFormData
    newFaqFormData[index].description = event.target.value
    this.setState({ faqFormData: newFaqFormData, alertOpen: false })
  }

  getAllFaqs() {
    this.setState({ isFecthingFaqData: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.GetLibraryFaqsApiContentType,
      token: this.getToken(),
      language: "English"
    };
    this.getAllFaqDataGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLibraryFaqsAPiEndPoint + `?page=${this.state.faqPage}&per_page=${this.state.per_page}&topic=${this.state.faqSearchTopic}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getLibraryFaqsMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFaqButtonCancel = () => {
    this.setState({ faqAddModelOpen: false, faqEditModel: false, faqFormData: JSON.parse(JSON.stringify(initialFaqFormData)), libraryFormImage: { src: "", file: null }, actionApiError: false, selectedFaqCategory: 0 })
    document.body.style.overflow = '';
  }
  handleFaqDeleteImage = () => {
    this.setState({ libraryFormImage: "" })
  }
  faqSubmissionValidation = (): boolean => {
    let valid = true;
    for (let item of this.state.faqFormData) {
      if (!item.question.trim() || !item.description.trim() ||  !this.state.selectedFaqCategory) {
        valid = false
        this.setState({ languageTab: item.language })
        break
      }
    }
    return valid;
  };

  getLibraryName = (getLibraryNameId: number) => {
    const value = this.state.libraryChaptersData.filter((item) => Number(item.id) === getLibraryNameId && item);
    this.setState({
      selectedDropDownValue: value[0].attributes.name
    })
  }

  getFaqCategory() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.GetFaqCategoryApiContentType,
      token: this.getToken(),
    };
    this.getFaqCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqCategoryAPiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getFaqCategoryMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  addNewTOpic = () => {
    let isValid = this.librarySubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingLibrary: true, addLibrary: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.addAllLibraryPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    let reqFormdata = new FormData();
    let formData = [...this.state.formData]
    let libraryId = formData[0].libraryId;
    formData.forEach((item, index) => {
      reqFormdata.append(`libraries[${index}][topic]`, item.topic)
      reqFormdata.append(`libraries[${index}][library_chapter_id]`, libraryId)
      reqFormdata.append(`libraries[${index}][language]`, item.language)
      reqFormdata.append(`libraries[${index}][description]`, item.description)
      reqFormdata.append(`libraries[${index}][image]`, this.state.libraryFormImage.file)
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editTopic = async () => {
    let isValid = this.librarySubmissionEditValidation();
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingLibrary: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.editAllLibraryPatchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    let reqFormdata = new FormData();
    let formData = [...this.state.formData];
    let libraryId = formData[0].libraryChapterId;
    let libraryFaq = formData[0].faqId;
    formData.forEach((item, index) => {
      reqFormdata.append(`libraries[${index}][topic]`, item.topic)
      reqFormdata.append(`libraries[${index}][language]`, item.language)
      reqFormdata.append(`libraries[${index}][id]`, item.id)
      reqFormdata.append(`libraries[${index}][description]`, item.description)
      reqFormdata.append(`libraries[${index}][library_chapter_id]`, libraryId)
      if (this.state.libraryFormImage?.file  ) {
        reqFormdata.append(`libraries[${index}][image]`, this.state.libraryFormImage?.file)
      } else {
        reqFormdata.append(`libraries[${index}][image]`, item.image)

      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint + `/${libraryFaq}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editFaq = async () => {
    let isValid = this.faqSubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingFaq: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.editAllFaqPatchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    let reqFormdata = new FormData();
    let formData = [...this.state.faqFormData]
    formData.forEach((item, index) => {
      reqFormdata.append(`faqs[${index}][faq_category_id]`, this.state.selectedFaqCategory)
      reqFormdata.append(`faqs[${index}][language]`, item.language)
      reqFormdata.append(`faqs[${index}][id]`, item.id)
      reqFormdata.append(`faqs[${index}][description]`, item.description)
      reqFormdata.append(`faqs[${index}][question]`, item.question)
      if (this.state.libraryFormImage?.file && !this.state.libraryFormImage?.src?.startsWith("http")) {
        reqFormdata.append(`faqs[${index}][image]`, this.state.libraryFormImage.file)
      } else if(!this.state.libraryFormImage){
        reqFormdata.append(`faqs[${index}][image]`, "destroy");
      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint + `/${this.state.selectedEditFaq}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  addFaqTopic = () => {
    let isValid = this.faqSubmissionValidation()
    if (!isValid) {
      this.setState({ alertOpen: true })
      return
    }
    this.setState({ isAddingFaq: true, addFaq: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      token: this.getToken(),
    };
    this.addFaqPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    let reqFormdata = new FormData();
    let formData = [...this.state.faqFormData]
    formData.forEach((item, index) => {
      reqFormdata.append(`faqs[${index}][faq_category_id]`, this.state.selectedFaqCategory)
      reqFormdata.append(`faqs[${index}][language]`, item.language)
      reqFormdata.append(`faqs[${index}][description]`, item.description)
      reqFormdata.append(`faqs[${index}][question]`, item.question)
      if (this.state.libraryFormImage?.file) {
        reqFormdata.append(`faqs[${index}][image]`, this.state.libraryFormImage.file)
      }
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqFormdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postFaqMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaq(getFaqId: number) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
    };
    this.getFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint + `?libraries_faq_id=${getFaqId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteFaq = () => {
    this.setState({ isDeletingFaq: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.faqApiContentType,
      token: this.getToken(),
    };
    this.faqDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.faqAPiEndPoint + `/${this.state.selectedDeleteFaq}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteFaqMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectFaq =
    (getFaqQuesId: FaqCategoryIdTypes) => (event: any, newExpanded: boolean) => {
      this.setState({ selectedFAQId: newExpanded ? getFaqQuesId : null })
    };

  handleSelectFaqTopic =
    (getFaqQuesTopicId: FaqCategoryIdTypes) => (event: any, newExpanded: boolean) => {
      this.setState({ selectedFAQIdTopic: newExpanded ? getFaqQuesTopicId : null })
    };

  // NEW LIBRARY HANDLE FUNCTIONS
  handleSelectLibraryChapter = (selectedLibraryId: FaqCategoryIdTypes) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      this.setState({ selectedLibraryChapterId: selectedLibraryId, libraryTopicsData: [], libraryTopicsCurrentPage: 1 }, () => {
        this.getLibraryTopicsByChapterId()
      })
    } else {
      this.setState({ selectedLibraryChapterId: null, selectedSubLibraryTopicId: null, libraryTopicsCurrentPage: 1 })
    }
  };
  getFaqQuestions(getFaqQuesId: FaqCategoryIdTypes) {
    if (!getFaqQuesId) return
    this.setState({ isFetchingQuestions: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
      language: "english"
    };
    this.getFaqQuestionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqQuestionsEndPoint + `?faq_category_id=${getFaqQuesId}&page=${this.state.getFaqQuestionPage}&per_page=${this.state.per_page}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteTopic = () => {
    this.setState({ isDeleting: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken(),
    };
    this.deletLibraryDeleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.libraryActionsAPiEndPoint + `/${Number(this.state.selectedDeleteLibrary)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangeSubLibrarySelect = (topicId: FaqCategoryIdTypes) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    this.setState({ selectedSubLibraryTopicId: newExpanded ? topicId : null })
  };

  fetchNextLibraryChapters = () => {
    this.setState(prev => ({ ...prev, libraryChaptersCurrentPage: prev.libraryChaptersCurrentPage + 1, 
      selectedSubLibraryTopicId: null 
    }), () => {
      this.getLibraryChapters()
    });
  }


  fetchNextTopics = () => {
    this.setState(prev => ({ ...prev, libraryTopicsCurrentPage: prev.libraryTopicsCurrentPage + 1 }), () => {
      this.getLibraryTopicsByChapterId()
    });
  }


  getLibraryChapters() {
    this.setState({ libraryChaptersLoading: true })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const ApiEndpoint = configJSON.getLibraryChaptersEndPoint + `?page=${this.state.libraryChaptersCurrentPage}&per_page=${this.state.libraryChaptersPerPage}&name=${this.state.librarySearchTopic}`
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken()
    };
    this.getLibraryChaptersApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), ApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFirstChapters() {
    this.setState({ libraryChaptersLoading: true })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const ApiEndpoint = configJSON.getLibraryChaptersEndPoint + `?page=1&per_page=10`
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken()
    };
    this.getLibraryChapterApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), ApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  defaultImageUrl = (imageUrl: string) => {
    if(imageUrl){
      return imageUrl;
    }else{
      return "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
    }
  }

  getLibraryTopicsByChapterId() {
    if (!this.state.selectedLibraryChapterId) return

    this.setState({ libraryTopicsLoading: true })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const ApiEndpoint = configJSON.getLibraryTopicsByChapterIdEndPoint + `?page=${this.state.libraryTopicsCurrentPage}&per_page=${this.state.libraryTopicsPerPage}&library_chapter_id=${this.state.selectedLibraryChapterId}`

    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken()
    };
    this.getLibraryTopicsByChapterIdApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), ApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // FOR DROPDOWN
  getAllLibraryChapters = () => {
    const header = {
      "Content-Type": configJSON.LibraryApiContentType,
      token: this.getToken()
    };
    this.setState({
      allLibraryChapters: [],
      allLibraryChaptersLoading: true
    })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const ApiEndpoint = configJSON.getLibraryChaptersEndPoint
    
    this.allLibrayChaptersCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), ApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  allLibrayChaptersResp = (responseJson: ILibraryChaptersResponse | null) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({
        allLibraryChapters: responseJson.data,
        allLibraryChaptersLoading: false
      })
    } else {
      this.setState({
        allLibraryChapters: [],
        allLibraryChaptersLoading: false
      })
    }
  }
  closeCustomModal = () => {
    this.setState({
      customModalOpen: false,
      customModalTitle: "",
      customModalDesc: "",
    })
  }
  openCustomModal = (open: boolean, title: string, description: string) => {
    this.setState({
      customModalOpen: open,
      customModalTitle: title,
      customModalDesc: description,
    })
  }
  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE";
exports.putMethod = "PUT";

exports.notifications = "Notifications";
exports.deleteText = "DELETE";
exports.okText = "OK";
exports.deleteMessage = "Notifications deleted!";
exports.initalParams = (type)  => ({ id: '', name : `Select ${type}` });
exports.initalParamsFirstName = (type)  => ({ id: '', first_name : `Select ${type}` , last_name: "" });
exports.limitMb = 26214400
exports.createParam = {
  tab :  0,
  notification_type_id: "none",
  chips: [],
  image: "",
  isImage: true,
  originalImg: "",
  data: [
    {
      title: '',
      language: 0,
      description: "" ,
    },
    {
      title: '',
      language: 1,
      description: "" ,
    },
    {
      title: '',
      language: 2,
      description: "" ,
    },
  ] 
};
exports.validFiles = ['image/jpeg', 'image/png','video/mp4']
//ApiCall
exports.getStateList = "admin/state_listing";
exports.getDistrictList = "admin/district_listing";
exports.getTalukaList = "admin/taluka_listing";
exports.getVillageList = "admin/village_listing";
exports.getGroupList = "admin/group_listing";
exports.getFieldExecutiveList = "admin/fe_listing";
exports.getFarmerList = "admin/farmer_listing";
exports.listNotificationEndPoint = (type,page,per_page) => `admin/bx_block_admin_notification/admin_notifications?${type}=true&page=${page}&per_page=${per_page}`;
exports.notificationType = "admin/bx_block_admin_notification/notification_types_listing";
exports.createNotification = "admin/bx_block_admin_notification/admin_notifications";
exports.deleteHolder = "/admin/bx_block_admin_notification/admin_notifications/";
exports.notificationDetailsShareLink = "https://co:farm/notification/";
exports.getNotificationPushApiEndPoint = 'admin/bx_block_admin_chat/push_notifications/';
exports.getNotificationAdminApiEndPoint = 'admin/bx_block_admin_notification/admin_notifications/';

// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface DropdownIndex {
  dropdown: string
  subdropdowns: Subdropdown[]
}

export interface Subdropdown {
  heading: string
  count: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  authToken: string | null;
  dropdownIndexData: DropdownIndex[];
  dropdownIndexDataLoading: boolean;
  currentExpandedDD: string | null;
  selectedSubDropdown: {
    parentTitle: string,
    subDropdown: { heading: string, count?: string }
  } | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfunitconversionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchIndexDropdownsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      authToken: localStorage.getItem("tokenn"),
      dropdownIndexData: [],
      dropdownIndexDataLoading: false,
      currentExpandedDD: null,
      selectedSubDropdown: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.fetchIndexDropdownsApiCallId) {
        this.handleFetchIndexDropdownsApiCallId(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  // HANDLE API CALLS
  handleFetchIndexDropdownsApiCallId = (responseJson: DropdownIndex[] | undefined | null) => {
    if (responseJson && responseJson.length) {
      this.setState({ dropdownIndexData: responseJson, dropdownIndexDataLoading: false })
    } else {
      this.setState({ dropdownIndexData: [], dropdownIndexDataLoading: false })
    }
  } 

  async componentDidMount(): Promise<void> {
    this.fetchIndexDropdowns()
    const searchParams = new URLSearchParams(window.location.search);
    const dropdown = searchParams.get('dropdown');
    const subDropdown = searchParams.get('sub-dropdown');
    if(dropdown && subDropdown){
      this.setState({
        currentExpandedDD: dropdown,
        selectedSubDropdown: {
          parentTitle: dropdown,
          subDropdown: { heading: subDropdown }
        }
      })
    }
  }

  // API CALLS
  fetchIndexDropdowns = () => {
    this.setState({
      dropdownIndexDataLoading: true,
      dropdownIndexData: []
    });

    const header = {
      Accept: configJSON.getTableAPiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchIndexDropdownsApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchDropdownIndexEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // ON CHANGE PARENT DD
  handleCurrentExpandedDD = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({
      currentExpandedDD: isExpanded ? panel : null
    })
  };

  // RENDER ORANGE BORDER
  subDropdownBorderColor = (subItem: Subdropdown): string => {
    if (this.state.selectedSubDropdown && this.state.selectedSubDropdown.subDropdown && (this.state.selectedSubDropdown.subDropdown.heading === subItem.heading)) {
      return "#DF5200"
    } else {
      return "#F0EBE0"
    }
  }

  // ON CLICK SUB DD
  setSubDropDownData = (parent: DropdownIndex, subDropdown: Subdropdown) => {
    this.setState({
      selectedSubDropdown: {
        parentTitle: parent.dropdown,
        subDropdown: subDropdown
      }
    })
    // PUSH NEW SELECTED DROPDOWN TO URL PARAMS
    this.props.navigation.navigate('Cfunitconversions', {},
      {
        "dropdown": parent.dropdown,
        "sub-dropdown": subDropdown.heading,
      })
  }

  openUnitsDrodpowns = () => {
    this.setState({
      currentExpandedDD: configJSON.UNITS.TITLE,
      selectedSubDropdown: {
        parentTitle: configJSON.UNITS.UNITS,
        subDropdown: { heading: configJSON.UNITS.UNITS }
      }
    })

    this.props.navigation.navigate('Cfunitconversions', {},
      {
        "dropdown": configJSON.UNITS.TITLE,
        "sub-dropdown": configJSON.UNITS.UNITS,
      })
  }
  // Customizable Area End
}

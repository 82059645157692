import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { debounce } from "lodash";
import { returnTruthyString } from '../../../components/src/helper';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface LastMessage {
  data: {
    id:string|number,
    attributes: {
      account: string | null;
      account_id: number | null;
      admin_user_id: number;
      attachments: null;
      chat_id: number;
      created_at: string;
      deleted: false;
      id: number;
      is_mark_read: false;
      is_mark_delivered:boolean;
      message: string;
      participants: string;
      reply_message_admin_id: null;
      reply_message_id: null;
      reply_message_user_id: null;
      updated_at: string;
    }
  }
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: LastMessage;
  name: string;
  logo: string | null;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    first_name: string;
    last_name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    logo: {
      url: string;
      resized: {
        url: string;
      }
    };
    last_message: LastMessage;
    unread_count: number;
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

type UserIdTypes = number|string|null
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type MessageReadTypes=string|number|null

interface S {
  // Customizable Area Start
  token: string | null;
  accountId: string;
  chatName: string;
  chatList: IChat[];
  allDataFetched: boolean;
  page: number;
  anchorEl: any;
  editProfileID: string;
  searchText: string;
  isVisibleModal: boolean;
  imageUrl: string,
  filteredAtoZ: boolean,
  filteredZtoA: boolean,
  isMobile: boolean,
  error: string,
  isAddGroup: boolean,
  isCreateGroup: boolean,
  //Starts
  isFilterOpen: boolean,
  sortAll: boolean;
  isDisabled: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
    farmer: boolean,
    fe: boolean,
    admin: boolean,
    superAdmin: boolean,
  },
  expanded: string | boolean,
  selectedState: any,
  selectedDistrict: any,
  selectedTaluka: any,
  selectedVillage: any,
  selectedFarmer: any,
  selectedAdmin: any,
  selectedSuperAdmin: any,
  selectedFieldExecutive: any;
  stateInputValue: string,
  districtInputValue: string,
  talukaInputValue: string,
  villageInputValue: string,
  adminInputValue: string,
  superAdminInputValue: string,
  fieldExecutiveInputValue: string,
  farmerInputValue: string,
  filteredStates: any[],
  filteredDistricts: any[],
  filteredTalukas: any[],
  filteredVillages: any[],
  filteredFarmers: any[],
  filteredAdmin: any[],
  filteredSuperAdmin: any[],
  filteredFieldExecutive: any[],

  initialStates: any[],
  initalDistricts: any[],
  initialTalukas: any[],
  initialVillages: any[],
  initialFarmers: any[],
  initialFieldExective: any[],
  initialAdmin: any[],
  initialSuperAdmin: any[],
  savedState: any,
  savedDistrict: any,
  savedTaluka: any,
  savedSortAll: boolean;
  savedVillage: any,
  savedFarmer: any,
  savedFieldExecutive: any,
  savedAdmin: any,
  savedSuperAdmin: any,
  savedIsDisabled: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
    farmer: boolean,
    fe: boolean,
    admin: boolean,
    superAdmin: boolean,
  },
  TokenValue: string | null,
  sortAscending: boolean;
  sortDescending: boolean;
  savedSortAscending: boolean,
  savedSortDescending: boolean,
  isLoading: boolean,
  selectedParticipant: any[],
  createGroup: { name: string, icon: string }
  showEmojiPicker:boolean
  lastMessageId:MessageReadTypes
  apiError:string,
  isCreatingGroup:boolean,
  cropImageModal:boolean,
  croppedImage:string,
  readMessageId:MessageReadTypes
  currentUser:UserIdTypes
  //Ends
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start 
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  updateProfileImg: string = "";
  updateReadMessageApiCallId: string = "";
  getStateData: string = "";
  getDistrictData: string = "";
  getTalukaData: string = "";
  getVillageData: string = "";
  getFarmerData: string = "";
  //starts
  getFieldExecutiveData: string = "";
  getAdminData: string = "";
  getSuperAdminData: string = "";
  getNewGroupUserApi: string = "";
  createNewGroupApi: string = "";
  updatedeliveredMessageApiCallId:string=""
  //end
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
      accountId: "",
      page: 1,
      isMobile: false,
      chatName: "",
      chatList: [],
      allDataFetched: false,
      anchorEl: null,
      editProfileID: "",
      searchText: "",
      isVisibleModal: false,
      imageUrl: "",



      filteredAtoZ: false,
      filteredZtoA: false,
      error: '',
      isAddGroup: false,
      isCreateGroup: false,
      //Starts
      isFilterOpen: false,
      sortAll: false,
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        farmer: false,
        fe: false,
        admin: false,
        superAdmin: false,
      },
      expanded: false,
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      selectedFarmer: {
        first_name: "Select Farmer",
        id: ""
      },
      selectedAdmin: {
        first_name: "Select Admin",
        id: ""
      },
      selectedSuperAdmin: {
        first_name: "Select Super Admin",
        id: ""
      },
      savedFieldExecutive: {
        first_name: "Select Field Executive",
        id: ""
      },
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      adminInputValue: "",
      superAdminInputValue: "",

      farmerInputValue: "",
      fieldExecutiveInputValue: "",
      filteredStates: [],
      filteredDistricts: [],
      filteredTalukas: [],
      filteredVillages: [],
      filteredFarmers: [],
      filteredAdmin: [],
      filteredSuperAdmin: [],
      initialStates: [],
      initalDistricts: [],
      initialTalukas: [],
      initialVillages: [],
      initialFarmers: [],
      initialAdmin: [],
      initialSuperAdmin: [],
      initialFieldExective: [],
      filteredFieldExecutive: [],
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      savedFarmer: {
        first_name: "Select Farmer",
        id: ""
      },
      savedAdmin: {
        first_name: "Select Admin",
        id: ""
      },
      savedSuperAdmin: {
        first_name: "Select Super Admin",
        id: ""
      },
      selectedFieldExecutive: {
        first_name: "Select Field Executive",
        id: ""
      },
      savedSortAll: false,
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        farmer: false,
        fe: false,
        admin: false,
        superAdmin: false,
      },
      TokenValue: localStorage.getItem("tokenn"),
      sortAscending: false,
      sortDescending: false,
      savedSortAscending: false,
      savedSortDescending: false,
      isLoading: false,
      selectedParticipant: [],
      createGroup: { name: "", icon: "" },
      showEmojiPicker:false,
      lastMessageId:null,
      apiError:"",
      isCreatingGroup:false,
      cropImageModal:false,
      croppedImage:"",
      readMessageId:null,
      currentUser:null
      //Ends
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let url = new URL(window.location.href);
    let chatId = url.searchParams.get("chat_id");
    let lastMessageId = url.searchParams.get("last_messageId");
    this.setState({ isLoading: true, isMobile: window.innerWidth  < 770 ,accountId:chatId||"" ,lastMessageId:lastMessageId})
    this.getChatList();
    //latest changes starts
    this.getIndexFilterCall(this.state.isAddGroup)
    this.setCurrentUser()
    //latest changes ends
  }


  checkToShow = () =>  !this.state.isMobile || (this.state.isMobile && !this.state.accountId)

  toggleAccountId = () => this.setState({ accountId: "" }) 

  filterList = (e: any) => {
    const searchQuery = e.target.value;
    this.setState({ searchText: searchQuery, accountId:"",chatList: [], page: 1, isLoading: true },
      () => {
        this.debouncedGetChatList(searchQuery);
      });

  }


  showSearchFilterModal = (event: any) => {
    this.setState({
      isFilterOpen: true,
      anchorEl: event.currentTarget,
    });
  }

  showEditProfilePage = (event: any, id: string) => {
    this.setState({ editProfileID: id })
  }

  showChatMessages = (id: string, chatList?: any,lastMessage?:any) => {
    let lastMessageId=null
    if(lastMessage && !lastMessage?.attributes?.is_mark_read){
      lastMessageId=lastMessage.attributes.id
    }
    this.setState({ accountId: id, chatList ,lastMessageId,readMessageId:lastMessageId})
    this.chatNavigation()
  };

  districtSelect = (selectedDistrict: number) => this.setState({ selectedDistrict });
  talukaSelect = (selectedTaluka: number) => this.setState({ selectedTaluka });
  villageSelect = (selectedVillage: number) => this.setState({ selectedVillage });
  stateSelect = (selectedState: number) => this.setState({ selectedState });
  setFilterFirst = (filteredAtoZ: boolean) => this.setState({ filteredAtoZ });
  setFilterLast = (filteredZtoA: boolean) => this.setState({ filteredZtoA });





  getSearchChatList = async (searchText: string) => {
    const header = {
      "Accept": configJSON.acceptAll,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchChatGroupApiEndPoint}?query=${searchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getChatList = async (search?: string) => {
    let queryParameters = [
      `state_id=${this.state.selectedState?.id}`,
      `district_id=${this.state.selectedDistrict?.id}`,
      `taluka_id=${this.state.selectedTaluka.id}`,
      `village_id=${this.state.selectedVillage.id}`
    ];
    if (this.state.sortAscending) {
      queryParameters.push(`sort_by_a_to_z=${this.state.sortAscending}`)
    }
    if (this.state.sortDescending) {
      queryParameters.push(`sort_by_z_to_a=${this.state.sortDescending}`)
    }
   
    queryParameters.push(`search=${search || this.state.searchText}`)

    const queryParametersString = queryParameters.filter(Boolean).join('&') 
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Accept": configJSON.acceptAll,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatListApiEndPoint}?${queryParametersString}&page=${this.state.page}&per_page=${configJSON.perPageUserList}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedGetChatList = debounce(this.getChatList, 500);


  getChatListPaginate = ({ chat_id, data,isUpdateCount ,isUpdateOnly}: { chat_id: number, data: any,isUpdateCount:boolean ,isUpdateOnly?:boolean}) => {

    this.setState(prev => {
      const chatList = prev.chatList.map(item => {
        if (isUpdateOnly ? (+item.id === chat_id && data.id == item?.lastMessage?.data.id) : +item.id === chat_id) {
          let newdata = { ...item.lastMessage.data, attributes: data}
          let lastMessage = { ...item.lastMessage, data:newdata}

          return { ...item, lastMessage ,unreadCount:isUpdateCount ?  item.unreadCount+1:item.unreadCount}
        }
        return item
      })

      return { ...prev, chatList ,readMessageId:isUpdateCount ? data.id :null};
    })
  };

  updateList= ({ chat_id, data }: { chat_id: number, data: any }) => {
    const {name,group_profile_image,last_message,unread_count}=data.attributes
    this.setState(prev => {
      const chatList = prev.chatList.map(item => {
        if (+item.id == chat_id) {
          return {...item,name,logo:group_profile_image,lastMessage:last_message,unreadCount:unread_count}
        }
        return item
      })
      return { ...prev, chatList ,readMessageId:last_message?.data?.id};
    })
  };

  markMessageAsDeliver=async(item:any)=>{
    if(item.attributes?.last_message?.data){
      let lastMessage=item.attributes?.last_message?.data
      if(!lastMessage.attributes?.is_mark_delivered && !lastMessage.attributes?.is_mark_read && lastMessage.attributes?.admin_user_id != this.state.currentUser && lastMessage.attributes?.account_id != this.state.currentUser ){
        this.markDeliveredChatMessage(item.attributes.last_message.data.id)
      }
    }
  }
  getUnreadCount=(item:any)=>{
    return this.state.accountId == item.id? 0:item.attributes.unread_count
  }

  apiRequestCallGetChatListCallResponse = (data: any) => {
    let results = data.map((item: IChatResponse) => {
      this.markMessageAsDeliver(item)
      return {
        id: item.id,
        name: item.attributes.name ?? `${returnTruthyString(item.attributes.first_name)} ${returnTruthyString(item.attributes.last_name)}`,
        unreadCount:this.getUnreadCount(item) ,
        lastMessage: item?.attributes.last_message,
        logo: item?.attributes?.logo?.url ?? null
      };
    })
    if(this.state.selectedFieldExecutive?.id){
      let selectedFieldExecutive=this.state.selectedFieldExecutive
      results=[{
        id: selectedFieldExecutive.id,
        name: selectedFieldExecutive.name ?? `${selectedFieldExecutive.first_name} ${selectedFieldExecutive.last_name}`,
        unreadCount: selectedFieldExecutive.unread_count,
        lastMessage: selectedFieldExecutive.last_message,
        logo: selectedFieldExecutive?.logo?.url ?? null
    },...results]
    }
    if(this.state.selectedFarmer?.id){
      let selecteItem=this.state.selectedFarmer
      results=[{
        id: selecteItem.id,
        name: selecteItem.name ?? `${selecteItem.first_name} ${selecteItem.last_name}`,
        unreadCount: selecteItem.unread_count,
        lastMessage: selecteItem.last_message,
        logo: selecteItem?.logo?.url ?? null
    }]
    }
    if(this.state.selectedAdmin?.id){
      let selecteItem=this.state.selectedAdmin
      results=[{
        id: selecteItem.id,
        name: selecteItem.name ?? `${selecteItem.first_name} ${selecteItem.last_name}`,
        unreadCount: selecteItem.unread_count,
        lastMessage: selecteItem.last_message,
        logo: selecteItem?.logo?.url ?? null,
        type:selecteItem?.type
        
    }]
    }
    if(this.state.selectedSuperAdmin?.id){
      let selecteItem=this.state.selectedSuperAdmin
      results=[{
        id: selecteItem.id,
        name: selecteItem.name ?? `${selecteItem.first_name} ${selecteItem.last_name}`,
        unreadCount: selecteItem.unread_count,
        lastMessage: selecteItem.last_message,
        logo: selecteItem?.logo?.url ?? null,
        type:selecteItem?.type
    }]
    }
    const arrChatList = [...this.state.chatList, ...results]
    this.setState(prev => {
      return {
        chatList: [...arrChatList],
        page: prev.page + 1
      }
    });
  }


  receive = async (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorResponse) {
      this.setState({
        error: "something went wrong!",
      });

      return
    }
    if (apiRequestCallId === this.getChatListApiCallId) {
      this.getChatListCall({ successResponse, errorResponse });
    }


    if (apiRequestCallId === this.getNewGroupUserApi) {
      this.getChatListCall({ successResponse, errorResponse });
    }
    if (apiRequestCallId === this.createNewGroupApi) {
      this.getNewGroupDataRes({ successResponse, errorResponse })
    }
    this.filterReponseHandler(apiRequestCallId, successResponse)

  }
  filterReponseHandler = (apiRequestCallId: string, successResponse: any) => {
    //Latest changes started 
    if (apiRequestCallId === this.getAdminData) {
      this.getAdminDataRes(successResponse);
    }
    if (apiRequestCallId === this.getSuperAdminData) {
      this.getSuperAdminDataRes(successResponse);
    }
    if (apiRequestCallId === this.getStateData) {
      this.getStateDataRes(successResponse);
    }
    if (apiRequestCallId === this.getDistrictData) {
      this.getDistrictDataRes(successResponse);
    }
    if (apiRequestCallId === this.getTalukaData) {
      this.getTalukaDataRes(successResponse);
    }
    if (apiRequestCallId === this.getVillageData) {
      this.getVillageDataRes(successResponse);
    }
    if (apiRequestCallId === this.getFieldExecutiveData) {
      this.getFEDataRes(successResponse);
    }
    if (apiRequestCallId === this.getFarmerData) {
      this.getFarmerDataRes(successResponse);
    }
    //latest changes ended
  }
  getChatListCall = ({ successResponse, errorResponse }: any) => {
    if (successResponse.errors) {
      const [error] = successResponse.errors
      this.setState({
        error: error.token,
      });

    } else {
      let data: any = successResponse.data
      
      if (data) {
        this.apiRequestCallGetChatListCallResponse(data);
      } else {
        this.setState({
          error: 'Something went wrong, data not found',
        });
      }
    }
    this.setState({ isLoading: false })
  }

  getIndexFilterCall = (isAddGroup: boolean = false) => {
    this.getStates();
    this.getDistricts();
    this.getTalukas();
    this.getVillages();
    if (isAddGroup) {
      this.getFarmers();
      this.getFieldExecutives();
      this.getAdmins();
      this.getSuperAdmins();
    }
  }
  //Starts
  handleClear = () => {
    this.showChatMessages("",[])
    this.setState({
      isLoading: true,
      sortAll: false,
      sortDescending: false,
      sortAscending: false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filteredFarmers: this.state.initialFarmers,
      filteredFieldExecutive: this.state.initialFieldExective,
      filteredAdmin: this.state.filteredAdmin,
      filteredSuperAdmin: this.state.filteredSuperAdmin,
      page: 1,
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      savedFarmer: {
        first_name: "Select Farmer",
        id: ""
      },
      savedAdmin: {
        first_name: "Select Admin",
        id: ""
      },
      savedSuperAdmin: {
        first_name: "Select Super Admin",
        id: ""
      },
      savedFieldExecutive: {
        first_name: "Select Field Executive",
        id: ""
      },
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        farmer: false,
        fe: false,
        admin: false,
        superAdmin: false,
      },

      expanded: false,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      farmerInputValue: "",
      adminInputValue: "",
      superAdminInputValue: "",
      isFilterOpen: false,
      chatList: [],
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      selectedFarmer: {
        first_name: "Select Farmer",
        id: ""
      },
      selectedAdmin: {
        first_name: "Select Admin",
        id: ""
      },
      selectedSuperAdmin: {
        first_name: "Select Super Admin",
        id: ""
      },
      selectedFieldExecutive: {
        first_name: "Select Field Executive",
        id: ""
      },
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        farmer: false,
        fe: false,
        admin: false,
        superAdmin: false,
      },
    }, () => {
      this.getIndexFilterCall(this.state.isAddGroup)
      if (this.state.isAddGroup) {
        this.getNewGroupUser()
      } else {
        this.getChatList()
      }
    });

  };

  closeFilter = () => {
    this.setState({
      isFilterOpen: false,
      expanded: false,
      sortAscending: this.state.savedSortAscending,
      sortDescending: this.state.savedSortDescending,
      sortAll: this.state.savedSortAll,
      isDisabled: this.state.savedIsDisabled,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      adminInputValue: "",
      superAdminInputValue: "",
      farmerInputValue: "",
      fieldExecutiveInputValue: "",
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filteredFarmers: this.state.initialFarmers,
      filteredFieldExecutive: this.state.initialFieldExective,
      filteredSuperAdmin: this.state.initialSuperAdmin,
      filteredAdmin: this.state.initialAdmin,
      selectedState:this.state.savedState,
      selectedDistrict:this.state.savedDistrict,
      selectedTaluka:this.state.savedTaluka,
      selectedVillage:this.state.savedVillage,
      selectedFarmer:this.state.savedFarmer,
      selectedAdmin:this.state.savedAdmin,
      selectedSuperAdmin:this.state.savedSuperAdmin,
      selectedFieldExecutive:this.state.savedFieldExecutive
    })

  }
  openFilter = () => {
    this.setState({
      isFilterOpen: true,

    })

  }
  handleSubmitAccordion = () => {
    this.showChatMessages("",[])
    this.setState({
      isLoading: true,
      expanded: false,
      savedState: this.state.selectedState,
      savedDistrict: this.state.selectedDistrict,
      savedTaluka: this.state.selectedTaluka,
      savedVillage: this.state.selectedVillage,
      savedFarmer: this.state.selectedFarmer,
      savedFieldExecutive: this.state.selectedFieldExecutive,
      savedAdmin: this.state.selectedAdmin,
      savedSuperAdmin: this.state.selectedSuperAdmin,
      savedIsDisabled: this.state.isDisabled,
      savedSortAll: this.state.sortAll,
      isFilterOpen: false,
      savedSortAscending: this.state.sortAscending,
      savedSortDescending: this.state.sortDescending,
      page: 1,
    }, () => {
      if (this.state.isAddGroup) {
        this.getNewGroupUser()
      } else {
        this.getChatList()
      }
    });
  }
  handleChangeField = (key1: any, key2: any, key3: any, key4: any, key5: any, ...props: any) => {
    const isDisabledInitialValues = {
      state: true,
      district: true,
      taluka: true,
      village: true,
      superAdmin: true,
      admin: true,
      fe: true,
      farmer: true,
    }
    // change required
    this.setState({
      isDisabled: {
        ...isDisabledInitialValues,
        ...(key1 && { [key1]: false }),
        ...(key2 && { [key2]: false }),
        ...(key3 && { [key3]: false }),
        ...(key4 && { [key4]: false }),
        ...(key5 && { [key5]: false }),
        ...(props[0] && { [props[0]]: false }),
        ...(props[1] && { [props[1]]: false }),
        ...(props[2] && { [props[2]]: false }),
      }
    })

  }
  handleChangePanel = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expanded: newExpanded ? panel : false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filteredFarmers: this.state.initialFarmers,
      filteredFieldExecutive: this.state.initialFieldExective,
      filteredAdmin: this.state.initialAdmin,
      filteredSuperAdmin: this.state.initialSuperAdmin,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      farmerInputValue: "",
      fieldExecutiveInputValue: "",
      adminInputValue: "",
      superAdminInputValue: ""
    });
  };
  opacityStateCalculator = () => {
    return 1
  }
  opacityDistrictCalculator = () => {
    return 1
  }
  opacityTalukaCalculator = () => {
    return 1
  }
  opacityVillageCalculator = () => {
    return 1
  }
  opacityFarmerCalculator = () => {
    return 1
  }
  opacityAdminCalculator = () => {
    return 1
  }
  opacitySuperAdminCalculator = () => {
    return 1
  }
  opacityFieldExecutiveCalculator = () => {
    return 1
  }

  handleChangeStateField = (stateData: {
    name: string,
    id: string
  }) => {
    this.setState({
      selectedState: stateData,
      expanded: false,
    }, () => {
      this.getDistricts()
      if (this.state.isAddGroup) {
        this.getFieldExecutives()
        this.getFarmers()
      }
    });
    this.handleChangeField("state", "district","", "", "", "", "fe", "farmer")
  }
  handleChangeDistrictField = (districtData: {
    name: string,
    id: string
  }) => {
    this.setState({
      selectedDistrict: districtData,
      expanded: false,
    }, () => {
      this.getTalukas()
      if (this.state.isAddGroup) {
        this.getFieldExecutives()
        this.getFarmers()
      }
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", "district", "taluka", "", "", "", "fe", "farmer")
  }
  handleChangeTalukaField = (talukaData: {
    name: string,
    id: string
  }) => {
    this.setState({
      selectedTaluka: talukaData,
      expanded: false,
    }, () => {
      this.getVillages()
      if (this.state.isAddGroup) {
        this.getFieldExecutives()
        this.getFarmers()
      }
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", "taluka", "village", "", "", "fe", "farmer")
  }
  handleChangeVillageField = (villageData: {
    name: string,
    id: string
  }) => {
    this.setState({
      selectedVillage: villageData,
      expanded: false,
    }, () => {
      if (this.state.isAddGroup) {
        this.getFieldExecutives()
        this.getFarmers()
      }
    });
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", "village", "", "", "fe", "farmer")
  }
  handleChangeFieldExecutiveField = (fieldExecutiveData: {
    first_name: string,
    id: string,
    name: string
  }) => {
    this.setState(prev => {
      return {
        selectedFieldExecutive: fieldExecutiveData,
        expanded: false,
      }
    }, () => {
      if (this.state.isAddGroup) {
        this.getFarmers()
      }
    });
    //change this
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "",
      this.state.selectedDistrict.name !== "Select District" ? "district" : "",
      this.state.selectedTaluka.name !== "Select Taluka" ? "taluka" : "",
      this.state.selectedVillage.name !== "Select Village" ? "village" : "",
      "", "", "fe", "farmer")
  }
  handleChangeFarmerField = (farmerData: {
    first_name: string,
    id: string,
    name: string
  }) => {
    this.setState(prev => {
      return {
        selectedFarmer: farmerData,
        expanded: false,
      }
    });
    //change this 
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "",
      this.state.selectedDistrict.name !== "Select District" ? "district" : "",
      this.state.selectedTaluka.name !== "Select Taluka" ? "taluka" : "",
      this.state.selectedVillage.name !== "Select Village" ? "village" : "",
       "", "",this.state.selectedFieldExecutive.first_name !== "Select Field Executive" ? "fe" : "" ,"farmer")
  }
  handleChangeAdminField = (adminData: {
    first_name: string,
    id: string,
    name: string
  }) => {
    this.setState(prev => {
      return {
        selectedAdmin: adminData,
        expanded: false,
      }
    });
    //change this 
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "",
      this.state.selectedDistrict.name !== "Select District" ? "district" : "",
      this.state.selectedTaluka.name !== "Select Taluka" ? "taluka" : "",
      this.state.selectedVillage.name !== "Select Village" ? "village" : "",
      "", "admin", "", "")
  }
  handleChangeSuperAdminField = (superAdminData: {
    first_name: string,
    id: string,
    name: string
  }) => {
    this.setState(prev => {
      return {
        selectedSuperAdmin: superAdminData,
        expanded: false,
      }
    });
    //change this 
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "",
      this.state.selectedDistrict.name !== "Select District" ? "district" : "",
      this.state.selectedTaluka.name !== "Select Taluka" ? "taluka" : "",
      this.state.selectedVillage.name !== "Select Village" ? "village" : "",
      "superAdmin", "", "", "")
  }
  getStates = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getDistricts = () => {
    let params = this.state.selectedState.id
    this.setState({
      selectedDistrict: {
        name: "Select District",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDistrictData = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictList}?state_id=${params}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getTalukas = () => {
    let params = this.state.selectedDistrict.id
    this.setState({
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTalukaData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaList}?district_id=${params}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getVillages = () => {
    let params = this.state.selectedTaluka.id
    this.setState({
      selectedVillage: {
        name: "Select Village",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageList}?taluka_id=${params}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getFieldExecutives = () => {
    //change this
    this.setState({
      selectedFieldExecutive: {
        first_name: "Select Field Executive",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };
    //change this
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFieldExecutiveData = getValidationsMsg.messageId;
    //change this
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFieldExecutiveList}?state_id=${this.state.selectedState.id || ""}&district_id=${this.state.selectedDistrict.id || ""}&taluka_id=${this.state.selectedTaluka.id || ""}&village_id=${this.state.selectedVillage.id || ""}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getFarmers = () => {
    this.setState({
      selectedFarmer: {
        first_name: "Select Farmer",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFarmerData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFarmerList}?state_id=${this.state.selectedState.id}&district_id=${this.state.selectedDistrict.id}&taluka_id=${this.state.selectedTaluka.id}&village_id=${this.state.selectedVillage.id}&field_executive_id=${this.state.selectedFieldExecutive.id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getAdmins = () => {
    // Customizable Area Start
    this.setState({
      selectedAdmin: {
        first_name: "Select Admin",
        id: "",
      }
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: typeof window !== 'undefined' ? localStorage.getItem("tokenn") : configJSON.token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }
  getSuperAdmins = () => {
    // Customizable Area Start
    this.setState({
      selectedSuperAdmin: {
        first_name: "Select Super Admin",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSuperAdminData = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSupeAdminList}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    // Customizable Area End
  }

  handleStateSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialStates.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStates;
    this.setState({
      filteredStates: filteredArray,
    });
  }
  handleDistrictSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initalDistricts.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistricts;
    this.setState({
      filteredDistricts: filteredArray,
    });
  }
  handleTalukaSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state?.initialTalukas?.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukas;
    this.setState({
      filteredTalukas: filteredArray,
    });
  }

  handleVillageSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialVillages.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillages;
    this.setState({
      filteredVillages: filteredArray,
    });
  }
  handleFarmerSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ farmerInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialFarmers.filter((value: any) =>
        value.first_name.toLowerCase().includes(query)
      )
      : this.state.initialFarmers;
    this.setState({
      filteredFarmers: filteredArray,
    });
  }
  handleAdminSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ adminInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialAdmin.filter((value: any) =>
        value.first_name.toLowerCase().includes(query)
      )
      : this.state.initialAdmin;
    this.setState({
      filteredAdmin: filteredArray,
    });
  }
  handleSuperAdminSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ superAdminInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialSuperAdmin.filter((value: any) =>
        value.first_name.toLowerCase().includes(query)
      )
      : this.state.initialSuperAdmin;
    this.setState({
      filteredSuperAdmin: filteredArray,
    });
  }

  handleFieldExectiveSearch = (e: any) => {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ fieldExecutiveInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialFieldExective.filter((value: any) =>
        value.first_name.toLowerCase().includes(query)
      )
      : this.state.initialFieldExective;
    this.setState({
      filteredFieldExecutive: filteredArray,
    });

  }
  getStateDataRes = (responseJson: any) => {
    if (responseJson && responseJson.length) {
      this.setState({
        initialStates: responseJson,
        filteredStates: responseJson
      })
    } else {
      this.setState({
        initialStates: [],
        filteredStates: []
      })
    }
  }
  getDistrictDataRes = (responseJson: any) => {
    if (responseJson && responseJson.length) {
      this.setState({
        initalDistricts: responseJson,
        filteredDistricts: responseJson
      })
    } else {
      this.setState({
        initalDistricts: [],
        filteredDistricts: []
      })
    }
  }
  getTalukaDataRes = (responseJson: any) => {
    if (responseJson && responseJson.length) {
      this.setState({
        initialTalukas: responseJson,
        filteredTalukas: responseJson
      })
    } else {
      this.setState({
        initialTalukas: [],
        filteredTalukas: []
      })
    }
  }
  getVillageDataRes = (responseJson: any) => {
    if (responseJson && responseJson.length) {
      this.setState({
        initialVillages: responseJson,
        filteredVillages: responseJson
      })
    } else {
      this.setState({
        initialVillages: [],
        filteredVillages: []
      })
    }
  }
  getFarmerDataRes = (responseJson: any) => {
    const users = this.mapUserTypes(responseJson, "farmer")
    this.setState({
      initialFarmers: users,
      filteredFarmers: users
    })
  }
  getAdminDataRes = (responseJson: any) => {
    const users = this.mapUserTypes(responseJson.data, "admin")
    this.setState({
      initialAdmin: users,
      filteredAdmin: users
    })
  }
  getSuperAdminDataRes = (responseJson: any) => {
    const users = this.mapUserTypes(responseJson.data, "sa")
    this.setState({
      initialSuperAdmin: users,
      filteredSuperAdmin: users
    })
  }
  getFEDataRes = (responseJson: any) => {
    const users = this.mapUserTypes(responseJson, "fe")
    this.setState({
      initialFieldExective: users,
      filteredFieldExecutive: users
    })
  }
  sortaz = () => {
    this.setState({
      sortAscending: !this.state.sortAscending,
      sortDescending: false
    });
  }
  sortza = () => {
    this.setState({
      sortDescending: !this.state.sortDescending,
      sortAscending: false
    });
  }

  checkFilterActive = () => {
    if (this.state.sortDescending ||
      this.state.sortAscending ||
      this.state.selectedState.id ||
      this.state.selectedDistrict.id ||
      this.state.selectedTaluka.id ||
      this.state.selectedVillage.id ||
      this.state.selectedFarmer.id ||
      this.state.selectedAdmin.id ||
      this.state.selectedSuperAdmin.id ||
      this.state.selectedFieldExecutive.id) {
      return true;
    }
    return false;
  }
  handleParticipant = (selectedParticipant: IChat[]) => {
    let participant = [...selectedParticipant]

    let removeDuplicateParticipant = [];
    let uniqueObject = {} as any;

    for (let i in participant) {
      const objId = participant[i]['id'];
      uniqueObject[objId] = participant[i];
    }
    
    for (let i in uniqueObject) {
      removeDuplicateParticipant.push(uniqueObject[i]);
    }

    this.setState({ selectedParticipant: removeDuplicateParticipant })
  }

  getNewGroupUser = (search?: string) => {

    let queryParameters = [
      `state_id=${this.state.selectedState?.id}`,
      `district_id=${this.state.selectedDistrict?.id}`,
      `taluka_id=${this.state.selectedTaluka.id}`,
      `village_id=${this.state.selectedVillage.id}`,
      `field_executive_id=${this.state.selectedFieldExecutive.id}`,
    ]
    if (this.state.sortAscending) {
      queryParameters.push(`sort_by_a_to_z=${this.state.sortAscending}`)
    }

    if (this.state.sortDescending) {
      queryParameters.push(`sort_by_z_to_a=${this.state.sortDescending}`)
    }
    if (search) {
      queryParameters.push(`search=${search}`)
    }
    if (!search) {
      queryParameters.push(`per_page=${configJSON.perPageUserList}`)
      queryParameters.push(`page=${this.state.page}`)
    }
    const queryParametersString = queryParameters.filter(Boolean).join('&') 
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Accept": configJSON.acceptAll,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNewGroupUserApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.newGroupApi}?${queryParametersString}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  debouncedGetNewGroupUser = debounce(this.getNewGroupUser, 500);
  
  handleNewGroupNavigation = () => {
    this.setState({ isAddGroup: true, chatList: [], page: 1, searchText: "" }, () => this.handleClear())
    this.chatNavigation()
  }

  userChatListRenderer = () => {
    if (this.state.isAddGroup) {
      return this.getNewGroupUser()
    } else {
      return this.getChatList()
    }
  }

  backBtnHandler = () => {
    this.setState({ isLoading: true, chatList: [], page: 1, selectedParticipant: [] })
    this.handleClear()

    if (this.state.isCreateGroup) {
      this.setState({ isAddGroup: true })
      this.setState({ isCreateGroup: false })
    } else {
      this.setState({ isAddGroup: false })
    }

  }
  handleGroupName = (text: string) => {
    if (text !== " " && text.length <= 25)
      this.setState(prev => {
        return { createGroup: { name: text, icon: prev.createGroup.icon } }
      })
  }

  async handleImageUpload(event: any) {
    event.persist();
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.setState(prev => {
        return { createGroup: { name: prev.createGroup.name, icon: reader.result as string},cropImageModal:true }
      });
    };
    reader.readAsDataURL(selectedFile);
    if(event.target){
      event.target.value = '';
    }
  }

  handleSubmitProgfileImage=(cropperRef:any)=>{
    let croppedImage=cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
    this.setState({croppedImage,cropImageModal:false })
  }

  getUsersId = () => {
    const accountIds: string[] = []
    const adminId: string[] = []
    this.state.selectedParticipant.forEach(user => {
      if (user?.type) {
        switch (user?.type) {
          case "sa":
            adminId.push(user.id);
            break;
          case "admin":
            adminId.push(user.id);
            break;
          default:
            accountIds.push(user.id);
            break;
        }
      } else {
        accountIds.push(user.id)
      }
    })
    return { accountIds, adminId }
  }

  mapUserTypes = (data: any, type: string) => {
    const mappedUsers = data.map((user: any) => {
      user.type = type
      return user
    })
    return mappedUsers
  }

  createNewGroup = () => {
    this.setState({isCreatingGroup:true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Accept": configJSON.acceptAll,
      token: this.state.token,
    };
    const { accountIds, adminId } = this.getUsersId()
    const params = {
      name: this.state.createGroup.name,
      account_ids: accountIds,
      admin_ids: adminId,
      logo: this.state.croppedImage
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewGroupApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createNewGroup
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(params)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNewGroupDataRes = ({ successResponse, errorResponse }: any) => {
    if(successResponse?.error){
      this.setState({apiError:"Something went wrong!",isCreatingGroup:false})
      return
    }
    this.setState({
      isCreateGroup: false,
      isAddGroup: false,
      selectedParticipant: [],
      createGroup: {
        name: "",
        icon: ""
      },
      isCreatingGroup:false,
      croppedImage:""
    },()=>{
      this.handleClear()
    })
  }

  onInputChange = (event: any, value: string) => {
    this.setState({
      searchText: value,
      chatList: [],
      page: 1,
      isLoading: value.length ? false : true
    },
      () => {
        this.debouncedGetNewGroupUser(value)
      });
  }

  toggleEmojiPicker=()=>{
    this.setState({
      showEmojiPicker:!this.state.showEmojiPicker
    })
  }

  handleClickEmoji=(event:any,emojiObject:any)=>{
    this.setState(prev => {
      return { createGroup: { name: prev.createGroup.name+ emojiObject.emoji, icon: prev.createGroup.icon } }
    })  }
    closeErrorModal=()=>{
      this.setState({apiError:""})
    }
    handleCloseCropModal=()=>{
      this.setState({cropImageModal:false})
    }
    markDeliveredChatMessage=(messageID:number|string|null)=>{
      if(!messageID) return
      const header = {
        token: this.state.token,
        "Content-Type": configJSON.apiContentType
      };
      const data = {
        "attributes":{
          "message_id": messageID,
          "delivered": true
        }
      }
      const httpsBody = {
        data: data
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updatedeliveredMessageApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateDeliverMessageApiEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpsBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    chatNavigation=()=>{
      if(this.props.navigation?.navigate){
        this.props.navigation.navigate("Chat")
      }
    }
    setCurrentUser=async()=>{
      this.setState({currentUser :await StorageProvider.get("userID") })
    }
  //Ends
  // Customizable Area End
}

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICropDetails, IWeedManagementDataTypes, IUnitDropdown, IUnitDropdownResponse, ISelectFarm, ISelectFarmDataResponse } from "../../../components/src/interface.web";
import moment from "moment";
import { debounce, getSingleCropSowingDate } from "../../../components/src/helper";
export const configJSON = require("./config");

type FarmIdTypes = string | undefined;
type CropIdTypes = string | string[] | undefined;
export interface Props {
  id: string;
  handleClose: () => void;
  isWeedManagementModal: boolean;
  accountId: string | number;
  handleAddCropModalCallback: () => void;
  isEditActivity: boolean;
  selectedActivityFarmId: FarmIdTypes;
  selectedActivityCropId: CropIdTypes;
  selectedyear: number;
  handleOpenFarmModalCallback: () => void;
  onCreateOrUpdate: (status: string) => void;
}

export interface InitialFormValues {
  id: string;
  weeding_type: string | number;
  date: Date;
  labor_cost: string;
  machine_cost: string;
  weedicide_quantity_value: string;
  weedicide_cost: string;
  spraying_cost: string;
  bullock_cost: string;
  fuel_value: string;
  weedicide_name: string;
  quantity_of_weedicide_unit: string;
  quantity_of_fuel_unit: string;
  errors: InitialFormErrorValues;
}
interface InitialFormErrorValues {
  weeding_type: string;
  date: string;
  labor_cost: string;
  machine_cost: string;
  weedicide_quantity_value: string;
  weedicide_cost: string;
  spraying_cost: string;
  bullock_cost: string;
  fuel_value: string;
  weedicide_name: string;
  quantity_of_weedicide_unit: string;
  quantity_of_fuel_unit: string;
}

const intialFormValues = {
  id: "",
  weeding_type: "",
  date: new Date(),
  labor_cost: "",
  machine_cost: "",
  weedicide_quantity_value: "",
  weedicide_cost: "",
  spraying_cost: "",
  bullock_cost: "",
  fuel_value: "",
  weedicide_name: "",
  quantity_of_weedicide_unit: "",
  quantity_of_fuel_unit: "",
  errors: {
    weeding_type: "",
    date: "",
    labor_cost: "",
    machine_cost: "",
    weedicide_quantity_value: "",
    weedicide_cost: "",
    spraying_cost: "",
    bullock_cost: "",
    fuel_value: "",
    weedicide_name: "",
    quantity_of_fuel_unit: "",
    quantity_of_weedicide_unit: ""
  }
};

export interface WeedicideNameList {
  id: number;
  name: string;
  name_english: string;
  name_hindi: string;
  name_gujrati: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

export interface DropdownOptions {
  id: number;
  name: string;
  name_english: string;
  name_hindi: string;
  name_gujrati: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

interface S {
  // FORM STATES
  formLandDetailId: string | unknown;
  formCropId: string | unknown;
  formLandDetailIdError: string;
  formCropIdError: string;
  weedManagementFormValues: InitialFormValues[];
  customMessage: string;
  customMessageTitle: string;
  authToken: string | null;
  cropNameList: ICropDetails[];
  loader: boolean;
  weedingType: string;
  searchWeedicideName: string;
  minimumDate: Date; 
  // DROPDOWN STATES
  weedicideNamesList: WeedicideNameList[],
  filteredWeedicideNamesList: WeedicideNameList[]
  unitOfWeedicideQuantityList: IUnitDropdown[],
  unitOfFuelConsumptionList: IUnitDropdown[],
  weedingTypeOptions: WeedicideNameList[],
  farmNamesList: ISelectFarm[],
}

interface SS {
  id: string;
}

// Customizable Area End

export default class WeedManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFarmNamesDataApiCallId: string = "";
  fetchCropNameDataApiCallId: string = "";
  fetchFuelConsumptionUnitDataApiCallId: string = "";
  fetchWeedicideQuntityUnitDataApiCallId: string = "";
  fetchWeedicideNameDataApiCallId: string = "";
  fetchWeedingTypeOptionsApiCallId: string = "";
  createWeedManagementApiCallId: string = "";
  fetchActivityDetailsApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formLandDetailId: "",
      formLandDetailIdError: "",
      formCropId: "",
      formCropIdError: "",
      weedManagementFormValues: [intialFormValues],
      customMessage: "",
      customMessageTitle: "",
      authToken: localStorage.getItem("tokenn"),
      cropNameList: [],
      loader: false,
      weedingType: "",
      searchWeedicideName: "",
      minimumDate: moment().subtract(360, 'days').toDate(),
      // DROPDOWN STATES
      weedicideNamesList: [],
      filteredWeedicideNamesList: [],
      unitOfWeedicideQuantityList: [],
      unitOfFuelConsumptionList: [],
      weedingTypeOptions: [],
      farmNamesList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.fetchFarmNamesDataApiCallId:
            this.handleFetchFarmNamesDataApiCallId(responseJson);
            break;
          case this.fetchCropNameDataApiCallId:
            this.handleFetchCropListResponse(responseJson);
            break;
          case this.fetchFuelConsumptionUnitDataApiCallId:
            this.handleFetchFuelConsumptionResponse(responseJson)
            break;
          case this.fetchWeedicideQuntityUnitDataApiCallId:
            this.handleFetchWeedicideQuntityResponse(responseJson);
            break;
          case this.fetchWeedicideNameDataApiCallId:
            this.handleFetchWeedicideNameResponse(responseJson);
            break;
          case this.fetchWeedingTypeOptionsApiCallId:
            this.handleFetchWeedingTypeOptionsResponse(responseJson);
            break;
          case this.createWeedManagementApiCallId:
            this.handleWeedManagementCreationResponse(responseJson);
            break;
          // GET ACTIVITY DETAILS
          case this.fetchActivityDetailsApiCallId:
            this.handleFetchActivityDetailsApiCallId(responseJson);
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {   
    this.resetErrorObject(intialFormValues.errors)

    const updatedFormValues = [...this.state.weedManagementFormValues];
    updatedFormValues[0].quantity_of_weedicide_unit = this.getDefaultValue(this.state.unitOfWeedicideQuantityList, "Number") ?? ""
    updatedFormValues[0].quantity_of_fuel_unit = this.getDefaultValue(this.state.unitOfWeedicideQuantityList, "Litre") ?? ""

    this.setState({
      weedManagementFormValues: updatedFormValues
    })
    if(this.props.isEditActivity) {
      this.fetchFarmData(this.props.accountId);
      this.fetchCropNameData(this.props.accountId);
      this.fetchWeeidicideNamesData()
      this.fetchFuelConsumptionUnitData()
      this.fetchWeedicideQuntityUnitData()
      this.fetchWeedingTypeOptions()
    }
    if(!this.props.isEditActivity){
      this.fetchFarmData(this.props.accountId);
      this.fetchWeeidicideNamesData()
      this.fetchFuelConsumptionUnitData()
      this.fetchWeedicideQuntityUnitData()
      this.fetchWeedingTypeOptions()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevState.formLandDetailId !== this.state.formLandDetailId) {
      this.fetchCropNameData(this.props.accountId);
    }
  }

  // API CALLS STARTS
  fetchFarmData = (userAccountId: string | number | null) => {
    if (!userAccountId) return
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmNamesDataApiCallId = reqMessage.messageId;

    const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.accountId}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropNameData = (accountID: string | number) => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user",
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropNameDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.fetchCropListDataApiEndPoint}?farm_id=${this.state.formLandDetailId ?? this.props.selectedActivityFarmId}&user_id=${accountID}`;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchFuelConsumptionUnitData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFuelConsumptionUnitDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'weed_management'}&dropdown=${'unit_of_fuel_consumption_weed_management'}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchWeedicideQuntityUnitData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchWeedicideQuntityUnitDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'weed_management'}&dropdown=${'unit_of_weedicide_quantity_weed_management'}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchWeeidicideNamesData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchWeedicideNameDataApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/admin/bx_block_farm_dairy/weedicides");
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchWeedingTypeOptions = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchWeedingTypeOptionsApiCallId = reqMessage.messageId;
    const endpoint = `/admin/bx_block_farm_dairy/weeding_types`;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  createWeedManagement = () => {
    this.setState({ loader: true})
    let formValues = [...this.state.weedManagementFormValues];
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    let formData = formValues.map((item) => {
      return {
        ...(item.id ? { id: item.id } : {}),
        weeding_type_id: item.weeding_type,
        weeding_date: moment(item.date).format("YYYY-MM-DD"),
        machine_charges: item.machine_cost,
        fuel_consumption: item.fuel_value,
        fuel_consumption_unit_id: item.quantity_of_fuel_unit,
        weedicide_id: item.weedicide_name,
        quantity_of_weedicide: item.weedicide_quantity_value,
        weedicide_unit_id: item.quantity_of_weedicide_unit,
        labor_cost: item.labor_cost,
        cost_of_weedicide: item.weedicide_cost,
        labor_cost_of_spraying: item.spraying_cost,
        bullock_drawn_cost: item.bullock_cost,
        account_id: this.props.accountId,
        crop_id: this.state.formCropId,
        land_detail_id: this.state.formLandDetailId,
      };
    });

    let httpBody = { weed_management: formData };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createWeedManagementApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/mobile_and_web/farm_dairy/activity_weed_managements?user_id=${this.props.accountId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchActivityDetails = () => {
    this.setState({ loader: true })
    const farmId = this.props.selectedActivityFarmId
    const cropIds = this.props.selectedActivityCropId
    if (farmId && cropIds && this.props.accountId) {
      const headers = {
        token: this.state.authToken,
        "Content-Type": configJSON.validationApiContentType,
        type: "admin_user"
      };

      const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.fetchActivityDetailsApiCallId = reqMessage.messageId;
      const endpoint = `/mobile_and_web/farm_dairy/activity_weed_managements?user_id=${this.props.accountId}&year=${this.props.selectedyear}&land_id=${farmId}&crop_id=${cropIds}`

      reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

      runEngine.sendMessage(reqMessage.id, reqMessage);
    } else {
      this.setState({ loader: false })
    }
  }

  // API CALLS ENDS

  // HANDLE API CALLS FUNCTIONS STARTS
  handleFetchFarmNamesDataApiCallId = (response: ISelectFarmDataResponse) => {
    if (response?.data?.length) {
      this.setState({ farmNamesList: response.data })
    } else {
      this.setState({ farmNamesList: [] })
    }
  }

  handleFetchFuelConsumptionResponse = (response: IUnitDropdownResponse) => {
    let updatedFormValues = [...this.state.weedManagementFormValues];
   
    if (response?.data.length > 1) {
      this.setState({ unitOfFuelConsumptionList: response.data });
    } else if (response?.data?.length === 1) {
      const unitId = response.data[0]?.id ?? "";
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        quantity_of_fuel_unit: unitId ?? null,
      }));
      this.setState({ unitOfFuelConsumptionList: response.data, weedManagementFormValues: updatedFormValues });
    } else {
      this.setState({ unitOfFuelConsumptionList: [] });
    }
  }

  handleFetchWeedicideQuntityResponse = (response: IUnitDropdownResponse) => {
    let updatedFormValues = [...this.state.weedManagementFormValues];

    if (response?.data.length > 1) {
      this.setState({ unitOfWeedicideQuantityList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        quantity_of_weedicide_unit: unitId ?? null,
      }));
      this.setState({ unitOfWeedicideQuantityList: response.data, weedManagementFormValues: updatedFormValues });
    } else {
      this.setState({ unitOfWeedicideQuantityList: [] });
    }
  }

  handleFetchWeedicideNameResponse = (response: WeedicideNameList[] | null | undefined) => {
    if (response && response.length > 0) {
      this.setState({
        weedicideNamesList: response,
        filteredWeedicideNamesList: response,
      })
    } else {
      this.setState({ weedicideNamesList: [], filteredWeedicideNamesList: [] })
    }
  }

  handleFetchWeedingTypeOptionsResponse = (response: WeedicideNameList[] | null | undefined) => {
    if (response && response.length > 0) {
      this.setState({
        weedingTypeOptions: response,
      })
    } else {
      this.setState({ weedingTypeOptions: [] })
    }
  }

  handleFetchCropListResponse = (response: { data: ICropDetails[] }) => {
    if (response?.data) {
      this.setState({ cropNameList: response.data } ,() => {
        if(this.props.isEditActivity){
          this.fetchActivityDetails();
        }
      });
    } else {
      this.setState({ cropNameList: [] });
    }
  };

  handleWeedManagementCreationResponse = (response: any) => {
    if (response?.errors?.length) {
      this.setState({
        loader: false,
        customMessageTitle: "Error",
        customMessage: response.errors[0] || "Something went wrong!",
      })
    } else if (response[0].data) {
      this.setState({
        loader: false,
        customMessageTitle: "Success",
        customMessage: this.props.isEditActivity ? "Weed management activity updated successfully.":  "Weed management activity added successfully.",
      })
      this.props.onCreateOrUpdate("Success")
    }
  }

  handleFetchActivityDetailsApiCallId = (response:{ data: IWeedManagementDataTypes[] } ) => {
    if (response?.data?.length > 0) {
      this.formateData(response.data)
    } else {
      this.setState({ loader: false })
    }
  }

  // HANDLE API CALLS FUNCTIONS ENDS
  formateData = (dataArray: IWeedManagementDataTypes[]) => {
    let updateInitalValue = []
    const filteredData = dataArray.filter((item) => item.attributes.freeze_record !== true);
    updateInitalValue = filteredData.map((item: IWeedManagementDataTypes) => {
      const momentObject = moment(item.attributes.weeding_date);
      const dateObject = momentObject.toDate();
      let weedicideName = item.attributes.weedicide_id ? item.attributes.weedicide_id[0].id : "";

      return {
        id: item.id,
        weeding_type: item.attributes.weeding_type_id ? item.attributes.weeding_type_id[0].id.toString() : "",
        date: dateObject,
        labor_cost:  item.attributes.labor_cost ?? "",
        machine_cost: item.attributes.machine_charges ?? "",
        weedicide_quantity_value: item.attributes.quantity_of_weedicide ?? "",
        weedicide_cost: item.attributes.cost_of_weedicide ?? "",
        spraying_cost: item.attributes.labor_cost_of_spraying ?? "",
        bullock_cost: item.attributes.bullock_drawn_cost ?? "",
        fuel_value: item.attributes.fuel_consumption?.toString() ?? "",
        weedicide_name: weedicideName,
        quantity_of_weedicide_unit:  item.attributes.weedicide_unit_id ? item.attributes.weedicide_unit_id[0].id : "",
        quantity_of_fuel_unit: item.attributes.fuel_consumption_unit_id ? item.attributes.fuel_consumption_unit_id[0].id : "",
        errors: {
          weeding_type: "",
          date: this.validateMinDate(moment(dateObject).toDate(), this.props.selectedActivityCropId as string),
          labor_cost: "",
          machine_cost: "",
          weedicide_quantity_value: "",
          weedicide_cost: "",
          spraying_cost: "",
          bullock_cost: "",
          fuel_value: "",
          weedicide_name: "",
          quantity_of_fuel_unit: "",
          quantity_of_weedicide_unit: ""
        }
      }
    })
    this.setState({
      formLandDetailId: this.props.selectedActivityFarmId ? this.props.selectedActivityFarmId : "",
      formCropId: this.props.selectedActivityCropId ? this.props.selectedActivityCropId : "",
      weedManagementFormValues: updateInitalValue,
      loader: false, 
      minimumDate: getSingleCropSowingDate(this.props.selectedActivityCropId as string, this.state.cropNameList)
      ?? moment().subtract(360, 'days').toDate(),
    })
  }

  getLabelFromId = (valueId: string | number, unitList: IUnitDropdown[]) => {
    let label = ""
    const firstUnitId = unitList[0]?.id;
    label = !firstUnitId ? "" : unitList[0]?.attributes.name;

    return label
  }

  getDefaultValue = (areaUnitList: IUnitDropdown[], valueName: string) => {
    let defaultValue = ""
    const acreObject = areaUnitList.find((each) => each.attributes.unit_name.toLowerCase() === valueName.toLowerCase())
    if (acreObject) {
      defaultValue = acreObject.id
    } else {
      defaultValue = areaUnitList[0]?.id
    }
    return defaultValue;
  }

  debouncedSearchNutrient = debounce((value: string) => this.handleFilteredWeedicideNamesList(value), 500);

  resetSearchNuturientData = () => {
    this.setState({ searchWeedicideName: "" }, () => {
      this.debouncedSearchNutrient("")
    })
  }

  handleFilteredWeedicideNamesList = (searchQuery: string) => {
    this.setState({ searchWeedicideName: searchQuery })
    const query = searchQuery?.toLowerCase() || "";

    const filteredArray = query ? this.state.weedicideNamesList.filter((value: DropdownOptions) => value.name_english.toLowerCase().includes(query)) : this.state.weedicideNamesList;
    this.setState({ filteredWeedicideNamesList: filteredArray });
  }

  handleFormAdd = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customMessageTitle: "Error",
        customMessage: "Please fill the existing forms with valid data.",
      });
      return;
    }
    let cloneFormValues = [...this.state.weedManagementFormValues];
    this.resetErrorObject(intialFormValues.errors)

    let newInitialFormValues: InitialFormValues = intialFormValues;
    if (this.state.unitOfFuelConsumptionList.length === 1) {
      const unitId = this.state.unitOfFuelConsumptionList[0]?.id;
      newInitialFormValues = {
        ...intialFormValues,
        quantity_of_fuel_unit: unitId ?? null,
        errors: {
          ...intialFormValues.errors
        }
      };
    }
    if (this.state.unitOfWeedicideQuantityList.length === 1) {
      const unitId = this.state.unitOfWeedicideQuantityList[0]?.id;
      newInitialFormValues = {
        ...intialFormValues,
        quantity_of_weedicide_unit: unitId ?? null,
        errors: {
          ...intialFormValues.errors
        }
      };
    }
    cloneFormValues.push(newInitialFormValues);
    this.setState({ weedManagementFormValues: cloneFormValues });
  };

  resetErrorObject(data: InitialFormErrorValues) {
    Object.keys(data).forEach((key) => {
      if (key !== "date") {
        data[key as keyof InitialFormErrorValues] = "";
      }
    });
    return data;
  }

  handleFormRemove = (index: number) => {
    let cloneFormValues = this.state.weedManagementFormValues;
    cloneFormValues.splice(index, 1);
    this.setState({ weedManagementFormValues: cloneFormValues });
  };

  checkValidationMachine = (entry: InitialFormValues) => {
    entry.errors.machine_cost = entry.machine_cost ? this.handleNestedCondition(Number(entry.machine_cost), 200, 100000, "Machine cost should be in between 200 and 10000") : "Please enter machine cost";
    entry.errors.fuel_value = !(entry.fuel_value && entry.quantity_of_fuel_unit) ? "Please enter fuel value" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.formCropId as string) ? "Please enter date" : "";
    return !!entry.fuel_value && !!entry.quantity_of_fuel_unit && !entry.errors.machine_cost && !entry.errors.date;
  }

  checkValidationBullock = (entry: InitialFormValues) => {
    entry.errors.labor_cost = entry.labor_cost ? this.handleNestedCondition(Number(entry.labor_cost), 50, 100000, "Labor cost should be in between 50 and 10000") : "Please enter labor cost";
    entry.errors.bullock_cost = !entry.bullock_cost ? "Please enter bullock drawn cost" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.formCropId as string) ? "Please enter date" : "";
    return !!entry.bullock_cost && !entry.errors.labor_cost && !entry.errors.date;
  }

  checkValidationLabor = (entry: InitialFormValues) => {
    entry.errors.labor_cost = entry.labor_cost ? this.handleNestedCondition(Number(entry.labor_cost), 50, 100000, "Labor cost should be in between 50 and 10000") : "Please enter labor cost";
    entry.errors.date = this.validateMinDate(entry.date, this.state.formCropId as string) ? "Please enter date" : "";
    return !entry.errors.labor_cost && !entry.errors.date;
  }

  checkValidationWeedicide = (entry: InitialFormValues) => {
    entry.errors.weedicide_quantity_value = !entry.weedicide_quantity_value ? "Please enter weedicide quantity" : "";
    entry.errors.weedicide_cost = entry.weedicide_cost ? this.handleNestedCondition(Number(entry.weedicide_cost), 0, 100000, "Weedicide cost should be in between 0 and 10000") : "Please enter weedicide cost";
    entry.errors.spraying_cost = entry.spraying_cost ? this.handleNestedCondition(Number(entry.spraying_cost), 0, 100000, "Spray cost should be in between 0 and 10000") : "Please enter spraying cost";
    entry.errors.weedicide_name = !entry.weedicide_name ? "Please select weedicide name" : "";
    entry.errors.date = this.validateMinDate(entry.date, this.state.formCropId as string) ? "Please enter date" : "";
    return !!entry.quantity_of_weedicide_unit && !!entry.weedicide_quantity_value && !entry.errors.weedicide_cost && !entry.errors.spraying_cost && !entry.errors.date && !!entry.weedicide_name;
  }

  checkDefaultForm = (entry: InitialFormValues) => {
    entry.errors.weeding_type = !entry.weeding_type ? "Please select weeding type" : "";
    entry.errors.date = !entry.date ? "Please enter date" : "";
    if (entry.fuel_value) {
      entry.errors.fuel_value = !entry.quantity_of_fuel_unit ? "Please enter fuel consumption" : ""
    }
    if (entry.weedicide_quantity_value) {
      entry.errors.weedicide_quantity_value = !entry.quantity_of_weedicide_unit ? "Please enter weedicide quantity" : ""
    }
    if (entry.labor_cost) {
      entry.errors.labor_cost = this.handleNestedCondition(Number(entry.labor_cost), 50, 100000, "Labor cost should be in between 50 and 10000");
    }
    if (entry.machine_cost) {
      entry.errors.machine_cost = this.handleNestedCondition(Number(entry.machine_cost), 200, 100000, "Machine cost should be in between 200 and 10000");
    }
    return !entry.errors.weeding_type &&
      !entry.errors.date &&
      !entry.errors.fuel_value &&
      !entry.errors.weedicide_quantity_value &&
      !entry.errors.labor_cost &&
      !entry.errors.machine_cost
  }

  isDataComplete = () => {
    const { weedingTypeOptions } = this.state;
    let valid = true;
    const updatedFormValues = [...this.state.weedManagementFormValues];
    const machineObject = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by machine")?.id
    const weedicideObject = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by weedicide")?.id
    const laborObject = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by labor")?.id
    const bullockObject = weedingTypeOptions.find((obj: DropdownOptions) => obj.name_english.toLowerCase() === "by bullock drawn")?.id

    for (const entry of updatedFormValues) {
      switch (entry.weeding_type) {
        case (machineObject):
          valid = valid && this.checkValidationMachine(entry);
          break;
        case bullockObject:
          valid = valid && this.checkValidationBullock(entry)
          break;
        case laborObject:
          valid = valid && this.checkValidationLabor(entry)
          break;
        case weedicideObject:
          valid = valid && this.checkValidationWeedicide(entry)
          break;
        default:
          valid = valid && this.checkDefaultForm(entry)
          break;
      }
    }

    if (!valid) {
      this.setState({ weedManagementFormValues: updatedFormValues });
    }
    return valid;
  };


  handleNestedCondition = (value: number, min: number, max: number, message: string) => {
    if (value < min || value > max) {
      return message
    } else {
      return ""
    }
  }

  handleMinMaxErrors = (fieldName: string, value: number | Date | string, isOptional?: boolean) => {
    const fieldErrors = {
      machine_cost: { min: 200, max: 100000, message: "Machine cost should be in between 200 and 10000" },
      labor_cost: { min: 50, max: 100000, message: "Labor cost should be in between 50 and 10000" },
      weedicide_cost: { min: 0, max: 100000, message: "Weedicide cost should be in between 0 and 10000" },
      spraying_cost: { min: 0, max: 100000, message: "Spraying cost should be in between 0 and 10000" },
    };

    const fieldConfig = fieldErrors[fieldName as keyof typeof fieldErrors];
    if (fieldConfig) {
      if (value) {
        return this.handleNestedCondition(Number(value), fieldConfig.min, fieldConfig.max, fieldConfig.message);
      } else {
        return isOptional ? "" : `Please enter ${fieldName.replace("_", " ")}`;
      }
    } else {
      return "";
    }
  };

  handleFuelValueChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
    error = value ? "" : "Please enter fuel consumption";
    newError = {
      quantity_of_fuel_unit: this.state.weedManagementFormValues[index].quantity_of_fuel_unit ? "" : "Please enter fuel consumption"
    }
    if (!value && !this.state.weedManagementFormValues[index].quantity_of_fuel_unit && isOptional) {
      error = ""; newError = { quantity_of_fuel_unit: "" }
    }
    if (!value && isOptional && this.state.weedManagementFormValues[index].quantity_of_fuel_unit && this.state.unitOfFuelConsumptionList.length === 1) {
      error = "";
    }
    return { error, newError };
  }

  handleWeedicideValueChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
    error = value ? "" : "Please enter weedicide quantity";
    newError = {
      quantity_of_weedicide_unit: this.state.weedManagementFormValues[index].quantity_of_weedicide_unit ? "" : "Please enter weedicide quantity"
    }
    if (!value && !this.state.weedManagementFormValues[index].quantity_of_weedicide_unit && isOptional) {
      error = ""; newError = { quantity_of_weedicide_unit: "" }
    }
    if (!value && isOptional && this.state.weedManagementFormValues[index].quantity_of_weedicide_unit && this.state.unitOfWeedicideQuantityList.length === 1) {
      error = "";
    }
    return { error, newError };
  }

  handleFuelUnitChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
    error = value ? "" : "Please enter fuel consumption";
    newError = {
      fuel_value: this.state.weedManagementFormValues[index].fuel_value ? "" : "Please enter fuel consumption"
    }
    if (!value && !this.state.weedManagementFormValues[index].fuel_value && isOptional) {
      error = ""; newError = { fuel_value: "" }
    }
    if (!value && isOptional && this.state.weedManagementFormValues[index].fuel_value && this.state.unitOfFuelConsumptionList.length === 1) {
      error = "";
    }
    return { error, newError }
  }

  handleWeedicideUnitChange = (value: number | Date | string, index: number, isOptional?: boolean) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
    error = value ? "" : "Please enter weedicide quantity";
    newError = {
      weedicide_quantity_value: this.state.weedManagementFormValues[index].weedicide_quantity_value ? "" : "Please enter weedicide quantity"
    }
    if (!value && !this.state.weedManagementFormValues[index].weedicide_quantity_value && isOptional) {
      error = ""; newError = { weedicide_quantity_value: "" }
    }
    if (!value && isOptional && this.state.weedManagementFormValues[index].weedicide_quantity_value && this.state.unitOfWeedicideQuantityList.length === 1) {
      error = "";
    }
    return { error, newError }
  }

  handleErrorText = (value: number | Date | string, fieldName: string, index: number, isOptional?: boolean) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
    error = this.handleMinMaxErrors(fieldName, value, isOptional)

    if (fieldName == "fuel_value") {
      let fuelValueErrors = this.handleFuelValueChange(value, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "quantity_of_fuel_unit") {
      let fuelValueErrors = this.handleFuelUnitChange(value, index, isOptional)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }

    if (fieldName == "weedicide_quantity_value") {
      let weedicideValueErrors = this.handleWeedicideValueChange(value, index, isOptional)
      error = weedicideValueErrors.error;
      newError = weedicideValueErrors.newError

    }
    if (fieldName == "quantity_of_weedicide_unit") {
      let weedicideValueErrors = this.handleWeedicideUnitChange(value, index, isOptional)
      error = weedicideValueErrors.error;
      newError = weedicideValueErrors.newError
    }
    if (fieldName == "date") {
      error = value ? this.validateMinDate(value as Date, this.state.formCropId as string) : "Please enter Date";
    }
    return { error, newError };
  };

  handleInputChange = (
    e: { target: { name: string; value: number | string | Date } },
    index: number, isOptional?: boolean
  ) => {
    const { name, value } = e.target;
    const updatedFormValues = [...this.state.weedManagementFormValues];
    // Ensure that name is a valid property
    if (name in updatedFormValues[index]) {
      const { error, newError } = this.handleErrorText(value, name, index, isOptional);
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        [name]: value ?? null,
        errors: {
          ...updatedFormValues[index].errors,
          [name]: error,
          ...(newError || {})
        }
      };
    }
    this.setState({ weedManagementFormValues: updatedFormValues });
  };

  handleWeedingType = (e: { target: { name: string; value: number | string | Date } },
    index: number) => {
    const { name, value } = e.target;
    const updatedFormValues = [...this.state.weedManagementFormValues];
    let newInitialFormValues: InitialFormValues = intialFormValues;
    if (this.state.unitOfFuelConsumptionList.length === 1) {
      const unitId = this.state.unitOfFuelConsumptionList[0]?.id;
      newInitialFormValues = {
        ...intialFormValues,
        quantity_of_fuel_unit: unitId ?? null,
        errors: {
          ...intialFormValues.errors,
          [name]: value && ""
        }
      };
    }
    if (this.state.unitOfWeedicideQuantityList.length === 1) {
      const unitId = this.state.unitOfWeedicideQuantityList[0]?.id;
      newInitialFormValues = {
        ...intialFormValues,
        quantity_of_weedicide_unit: unitId ?? null,
        errors: {
          ...intialFormValues.errors,
          [name]: value && ""
        }
      };
    }
    
    if (this.state.unitOfWeedicideQuantityList.length === 1 && this.state.unitOfFuelConsumptionList.length === 1) {
      const unitIdBio = this.state.unitOfWeedicideQuantityList[0]?.id;
      const unitIdPest = this.state.unitOfFuelConsumptionList[0]?.id;
      newInitialFormValues = {
          ...intialFormValues,
          quantity_of_weedicide_unit: unitIdBio ?? null,
          quantity_of_fuel_unit: unitIdPest ?? null,
          errors: {
              ...intialFormValues.errors,
              [name]: value && ""
          }
      };
    }

    updatedFormValues[index] = {
      ...newInitialFormValues,
      [name]: value ?? null,
      errors: { ...newInitialFormValues.errors, [name]: value && "" }
    };

    this.setState({ weedManagementFormValues: updatedFormValues });
  }

  handleCloseCustomModal = () => {
    if (this.state.customMessageTitle.toLocaleLowerCase() == "success") {
      this.props.handleClose();
    }
    this.setState({ customMessage: "", customMessageTitle: "" });
  };

  isFormComplete = () => {
    let valid = true;
    if (!this.state.formLandDetailId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        formLandDetailIdError: "Please select Farm",
      }));
    }
    if (!this.state.formCropId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        formCropIdError: "Please select Crop",
      }));
    }
    if (!this.isDataComplete()) {
      valid = false;
    }
    return valid;
  };

  handleFormSubmit = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customMessageTitle: "Error",
        customMessage:
          "Please fill the existing forms with valid data.",
      });
      return;
    }
    this.createWeedManagement();
  };

  handleAddCrop = () => {
    this.props.handleClose();
    this.props.handleAddCropModalCallback();
  };
  handleAddFarm = ()  => {
    this.props.handleClose();
    this.props.handleOpenFarmModalCallback();
  };

  validateMinDate = (selectedDate: Date, cropValue?: string) => {
    const minimumDate = (cropValue && getSingleCropSowingDate(cropValue, this.state.cropNameList)) || moment().subtract(361, 'days').toDate();
    return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
  };

  handleFarmSelect = (value: string) => {
    const updatedFormValues = this.state.weedManagementFormValues.map((item) => ({
      ...item,
      errors: { ...item.errors, date: this.validateMinDate(item.date, "") },
    }));

    this.setState({
      formLandDetailId: value as string,
      formCropId: "",
      formLandDetailIdError: "",
      minimumDate: moment().subtract(360, 'days').toDate(),
      weedManagementFormValues: updatedFormValues,
    }, () => {
      this.fetchCropNameData(this.props.accountId);
    });
  };

  handleCropSelection = (value: string) => {
    const minimumDate = getSingleCropSowingDate(value, this.state.cropNameList) || moment().subtract(360, 'days').toDate();
    this.setState({ formCropId: value, formCropIdError: "", minimumDate })
    const updatedFormValues = [...this.state.weedManagementFormValues];
    this.state.weedManagementFormValues.map((item, index) => {
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        errors: {
          ...updatedFormValues[index].errors,
          date: this.validateMinDate(item.date, value),
        }
      };
    });
    this.setState({ weedManagementFormValues: updatedFormValues });
  }
  // Customizable Area End
}

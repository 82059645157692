Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";

// HTTP METHODS
exports.GET_METHOD_TYPE = "GET";
exports.POST_METHOD_TYPE = "POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.PATCH_METHOD_TYPE = "PATCH";
exports.DELETE_METHOD_TYPE = "DELETE";

exports.ENDPOINTS = {
  GET_ALL_ANALYTICS : "admin/bx_block_visual_analytic/visual_analytics",
}
exports.MSG = {
  ERROR_TITLE : "Error",
  ERROR_DESC : "Oops, something went wrong. Please try again later.",
}

// Customizable Area End
import React from "react";

import {
    // Customizable Area Start
    Typography,
    Modal,
    Button,
    // Customizable Area End
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

interface ErrorModalPropsType {
    open: boolean;
    errorTitle: string;
    errorSubTitle: string;
    BtnTitle: string;
    styles?: React.CSSProperties;
    handleBtnClick: () => void;
}

const ErrorModal = (props: ErrorModalPropsType) => {
    const { errorTitle, errorSubTitle, BtnTitle, open, handleBtnClick, styles } = props;

    const classes = useStyles();

    return (
        <Modal onClose={handleBtnClick} open={open} className={classes.modal} style={styles}>
            <div className={classes.paper}>
                <Typography variant="h2" className={classes.modalHeading}>
                    {errorTitle}
                </Typography>
                <p id="server-modal-description" className={classes.modalPara}>
                    {errorSubTitle}
                </p>

                <div className={classes.modalBtn}>
                    <Button
                        variant="contained"
                        className={classes.DeleteBtn}
                        onClick={() => handleBtnClick()}
                    >
                        {BtnTitle}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        textAlign: "center",
        width: "528px",
        padding: "70px 5px",
        backgroundColor: "#fff",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: " translate(-50%, -50%)",
        boxShadow: " 2px 8px 32px 4px rgba(0, 0, 0, 0.06)",
        borderRadius: "12px",
        "@media (max-width: 528px)": {
            width: "428px",
        },
        "@media (max-width: 428px)": {
            width: "328px",
        }
    },
    modal: {
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
    },
    DeleteBtn: {
        background: "#B3261E",
        borderRadius: " 28px",
        color: "#FFFFFF",
        width: "130px",
        height: "56px",
        fontWeight: 700,
        fontSize: "18px",
        "&:hover": {
            background: "#B3261E",
            boxShadow: "none",
        },
    },
    modalHeading: {
        color: " #1C2324",
        fontFamily: "IBM Plex Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: " 24px",
        paddingBottom: "16px",
    },
    modalBtn: {
        maxWidth: "300px",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        paddingTop: "24px",
    },
    modalPara: {
        maxWidth: "400px",
        fontFamily: "IBM Plex Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: " 28px",
        textAlign: "center",
        color: " #1C2324",
        margin: "auto",
        paddingBottom: "24px",
    },
}));

export default ErrorModal;

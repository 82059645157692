// Customizable Area Start
import { getBase64, returnError, returnTruthyString, trimStart, validateLibraryFAQForm } from "../../../../components/src/helper";
import { IDefaultLibraryFAQ, IInputAndLabel, ISubValueLibraryFAQ } from "../../../../components/src/interface.web";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

export const configJSON = require("../config");

const defaultInput = {
    id: "",
    image: "",
    image_error: "",
    english: {
        title: "",
        title_error: ""
    },
    gujarati: {
        title: "",
        title_error: ""
    },
    hindi: {
        title: "",
        title_error: ""
    }
}

export interface Props {
    dataArray: IDefaultLibraryFAQ[],
    InputAndLabel: IInputAndLabel,
    onSubmit: (data: IDefaultLibraryFAQ[]) => void,
    saveLoading: boolean
}
interface S {
    selectedTab: string,
    defualtForm: IDefaultLibraryFAQ,
    formArray: IDefaultLibraryFAQ[],
}
interface SS { }
// Customizable Area End

export default class LibraryFAQController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End

        this.state = {
            // Customizable Area Start 
            selectedTab: configJSON.TAB.ENGLISH,
            defualtForm: { ...defaultInput },
            formArray: [],

            // Customizable Area End
        }

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.setState({ formArray: [...this.props.dataArray] })
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newTab: string) => {
        this.setState({
            selectedTab: newTab
        })
    }
    handleAddInput = () => {
        const newForm = JSON.parse(JSON.stringify(this.state.defualtForm))
        const { formObj,
            isFormHasError,
            isErrorEnglish,
            isErrorGujarati,
            isErrorHindi,
            isErrorInOtherField } = validateLibraryFAQForm(newForm)

        if (!isFormHasError) {
            let existingData = [...this.state.formArray]
            existingData.push(formObj);
            this.setState({
                formArray: existingData,
                defualtForm: { ...defaultInput }
            });
            this.setTab()
        } else {
            this.setState({ defualtForm: formObj });
            this.changeTab(isErrorEnglish, isErrorGujarati, isErrorHindi, isErrorInOtherField)
        }
    }
    changeTab = (isErrorEnglish: boolean,
        isErrorGujarati: boolean,
        isErrorHindi: boolean,
        isErrorInOtherField: boolean) => {
        if (isErrorEnglish || isErrorInOtherField) {
            this.setTab(configJSON.TAB.ENGLISH)
        } else if (isErrorGujarati || isErrorInOtherField) {
            this.setTab(configJSON.TAB.GUJARATI)
        } else if (isErrorHindi || isErrorInOtherField) {
            this.setTab(configJSON.TAB.HINDI)
        }
    }

    setTab = (tabValue: string = configJSON.TAB.ENGLISH) => {
        this.setState({
            selectedTab: tabValue
        })
    }
    handleRemoveInput = (formItem: IDefaultLibraryFAQ, index: number) => {
        let existingData = [...this.state.formArray]
        if (index >= 0 && index < existingData.length) {
            const obj = existingData[index];
            if (obj.id) {
                obj["_destroy"] = formItem.id;
            } else {
                existingData.splice(index, 1);
            }
        }
        this.setState({ formArray: existingData });
    };

    // CHANGE DEFAULT VALUE
    setValueDefault = (fieldValue: string | number, language: string) => {
        if (language) {
            this.setState(prevState => ({
                defualtForm: {
                    ...prevState.defualtForm,
                    [language]: {
                        ...prevState.defualtForm[language] as ISubValueLibraryFAQ,
                        title: trimStart(String(fieldValue)),
                        title_error: returnTruthyString(trimStart(String(fieldValue))) ? "" : "Field cannot be empty"
                    }
                }
            }));
        }
    }

    setImageDefault = async (ImageFile: File) => {
        const fileUrl = await getBase64(ImageFile).then(res => res)
        this.setState(prevState => ({
            defualtForm: {
                ...prevState.defualtForm,
                image: fileUrl as string,
                image_error: ImageFile ? "" : "Field cannot be empty"
            }
        }))
    }

    setValueForm = (fieldIndex: number, fieldValue: string, language: string) => {
        const formArrayCopy = [...this.state.formArray];
        formArrayCopy[fieldIndex] = {
            ...formArrayCopy[fieldIndex],
            [language]: {
                ...formArrayCopy[fieldIndex][language] as ISubValueLibraryFAQ,
                title: trimStart(String(fieldValue)),
                title_error: returnError(returnTruthyString(fieldValue))
            }
        };
        this.setState({ formArray: formArrayCopy })
    }

    setImageForm = async (fieldIndex: number, ImageFile: File | null) => {
        const fileUrl = ImageFile ? await getBase64(ImageFile).then(res => res) : null

        const formArrayCopy = [...this.state.formArray];
        formArrayCopy[fieldIndex] = {
            ...formArrayCopy[fieldIndex],
            image: fileUrl ? fileUrl as string : formArrayCopy[fieldIndex].image,
            image_error: returnError(returnTruthyString(formArrayCopy[fieldIndex].image || fileUrl as string))

        };
        this.setState({ formArray: formArrayCopy })
    }
    handleSubmit = () => {
        this.setState({ defualtForm: { ...defaultInput } });
        const allFormsValidated = this.validateAllForms()
        if (allFormsValidated) {
            this.props.onSubmit && this.props.onSubmit(this.state.formArray)
        }
    }

    validateAllForms = () => {
        const allForms = [...this.state.formArray]
        let isFormValidatedArr: boolean[] = []
        let isErrorEnglishArr: boolean[] = [];
        let isErrorGujaratiArr: boolean[] = [];
        let isErrorHindiArr: boolean[] = [];
        let isErrorInOtherFieldArr: boolean[] = [];

        let UpdatedFormArray: IDefaultLibraryFAQ[] = []

        allForms.forEach((newForm) => {
            const {
                isFormHasError,
                isErrorEnglish,
                isErrorGujarati,
                isErrorHindi,
                isErrorInOtherField,
                formObj } = validateLibraryFAQForm(newForm)
            isFormValidatedArr.push(isFormHasError)
            isErrorEnglishArr.push(isErrorEnglish)
            isErrorGujaratiArr.push(isErrorGujarati)
            isErrorHindiArr.push(isErrorHindi)
            isErrorInOtherFieldArr.push(isErrorInOtherField)

            UpdatedFormArray.push(formObj)
        })

        const isFormError = isFormValidatedArr.includes(true);
        const isErrorInEnglishTab = isErrorEnglishArr.includes(true);
        const isErrorInGujaratiTab = isErrorGujaratiArr.includes(true);
        const isErrorInHindiTab = isErrorHindiArr.includes(true);
        const isErrorOtherFields = isErrorInOtherFieldArr.includes(true);

        this.changeTab(isErrorInEnglishTab, isErrorInGujaratiTab, isErrorInHindiTab, isErrorOtherFields)

        this.setState({ formArray: UpdatedFormArray })

        if (isErrorInEnglishTab || isErrorInGujaratiTab ||
            isErrorInHindiTab || isErrorOtherFields || isFormError) {
            return false
        } else {
            return true
        }
    }
    // Customizable Area End
}
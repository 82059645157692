import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CfunitconversionsController, {
  DropdownIndex,
  Props,
  Subdropdown,
  configJSON,
} from "./CfunitconversionsController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { LoaderCenter, themeUser } from "../../../components/src/AdminConsoleHelper";
import { FarmDiarySVG, MaterrapediaSVG, RegistrationSVG, RightArrowSVG, UnitSVG, NotificationSVG } from "./assets";
// REGISTARTION
import PersonalDetailsDropdown from "./Registration/PersonalDetailsDropdown.web";
import LocationsDropdown from "./Registration/LocationsDropdown.web";
// FARM-DAIRY
import FarmsDropdown from "./FarmDiary/FarmsDropdown.web";
import CropsDropdown from "./FarmDiary/CropsDropdown.web";
import PreSowingDropdown from "./FarmDiary/PreSowingDropdown.web";
import CropFailure from "./FarmDiary/CropFailure.web";
import WeedDropdown from "./FarmDiary/WeedDropdown.web";
import NutrientDropdown from "./FarmDiary/NutrientDropdown.web";
import PestDropdown from "./FarmDiary/PestDropdown.web";
import IrrigationDropdown from "./FarmDiary/IrrigationDropdown.web";
import HarvestDropdown from "./FarmDiary/HarvestDropdown.web";
import SellDropdown from "./FarmDiary/SellDropdown.web";
// MATERRAPEDIA
import LibraryChapter from "./Materrapedia/LibraryChapter.web";
// NOTIFICATIONS
import NotificationType from "./Notification/NotificationType.web";
// UNITS
import Units from "./Units/Units.web";


const getDropdownData = (item: DropdownIndex) => {
  let imageSrc: string = ""
  let margin: string = "0 12px 0 0"
  let title: string = item.dropdown

  switch (item.dropdown) {
    case configJSON.REGISTRATION.TITLE:
      imageSrc = RegistrationSVG
      break;
    case configJSON.FARM_DIARY.TITLE:
      imageSrc = FarmDiarySVG
      margin = "0 16px 0 0"
      break;
    case configJSON.MATERRAPEDIA.TITLE:
      imageSrc = MaterrapediaSVG
      break;
    case configJSON.NOTIFICATION.TITLE:
      imageSrc = NotificationSVG
      break;
    case configJSON.UNITS.TITLE:
      imageSrc = UnitSVG
      break;
  }
  return { imageSrc, margin, title }
}
// Customizable Area End
export default class Cfunitconversions extends CfunitconversionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRegistrtaionDD = (selectedDD: string) => {
    switch (selectedDD) {
      case configJSON.REGISTRATION.PERSONAL_DETAILS:
        return (
          <PersonalDetailsDropdown
            navigation={this.props.navigation}
            id={this.props.id}
          />)
      case configJSON.REGISTRATION.LOCATIONS:
        return (
          <LocationsDropdown
            navigation={this.props.navigation}
            id={this.props.id}
          />)
      default:
        return <></>
    }
  }
  renderFarmDiaryDD = (selectedDD: string) => {
    switch (selectedDD) {
      case configJSON.FARM_DIARY.FARMS:
        return (
          <FarmsDropdown
            navigation={this.props.navigation}
            id={this.props.id}
            openUnitsDrodpowns={this.openUnitsDrodpowns}
          />)
      case configJSON.FARM_DIARY.CROPS:
        return (
          <CropsDropdown
            navigation={this.props.navigation}
            id={this.props.id}
            openUnitsDrodpowns={this.openUnitsDrodpowns}
          />
        )
      case configJSON.FARM_DIARY.PRE_SOWING:
        return (
          <PreSowingDropdown
            navigation={this.props.navigation}
            id={this.props.id}
            openUnitsDrodpowns={this.openUnitsDrodpowns}
          />)
      case configJSON.FARM_DIARY.CROP_FAILURE:
        return (
          <CropFailure
            navigation={this.props.navigation}
            id={this.props.id}
            openUnitsDrodpowns={this.openUnitsDrodpowns}
          />)
      case configJSON.FARM_DIARY.WEED_MANAGEMENT:
        return (
          <WeedDropdown
            navigation={this.props.navigation}
            id={this.props.id} 
            openUnitsDrodpowns={this.openUnitsDrodpowns}
            />
        )
      case configJSON.FARM_DIARY.NUTRIENT_MANAGEMENT:
        return (
          <NutrientDropdown
            navigation={this.props.navigation}
            id={this.props.id} 
            openUnitsDrodpowns={this.openUnitsDrodpowns}
            />
        )
      case configJSON.FARM_DIARY.PEST_MANAGEMENT:
        return (
          <PestDropdown
            navigation={this.props.navigation}
            id={this.props.id} 
            openUnitsDrodpowns={this.openUnitsDrodpowns}
            />
        )
      case configJSON.FARM_DIARY.IRRIGATION:
        return (
          <IrrigationDropdown
            navigation={this.props.navigation}
            id={this.props.id} 
            openUnitsDrodpowns={this.openUnitsDrodpowns}            
            />
        )
      case configJSON.FARM_DIARY.HARVEST:
        return (
          <HarvestDropdown
            navigation={this.props.navigation}
            id={this.props.id}
            openUnitsDrodpowns={this.openUnitsDrodpowns}
            />
        )
      case configJSON.FARM_DIARY.SELL:
        return (
          <SellDropdown
            navigation={this.props.navigation}
            id={this.props.id} 
            openUnitsDrodpowns={this.openUnitsDrodpowns}
            />
        )
      default:
        return <></>
    }
  }
  renderRestDD = (selectedDD: string) => {
    switch (selectedDD) {
      // MATERRAPEDIA
      case configJSON.MATERRAPEDIA.LIBRARY_CHAPTER:
      case configJSON.MATERRAPEDIA.FAQ_CATEGORY:
        return (
          <LibraryChapter
            navigation={this.props.navigation}
            id={this.props.id}
            subDropdown={this.state.selectedSubDropdown}

          />)

      // NOTIFICATION
      case configJSON.NOTIFICATION.NOTIFICATION_TYPE:
        return (
          <NotificationType
            navigation={this.props.navigation}
            id={this.props.id}
          />)

      // UNITS
      case configJSON.UNITS.UNITS:
        return (
          <Units
            navigation={this.props.navigation}
            id={this.props.id}
            dropdown={this.state.selectedSubDropdown ? this.state.selectedSubDropdown : null}
          />)
      default:
        return <></>
    }
  }

  renderSelectDropdown = () => {
    return (
      <Box style={{
        borderRadius: "12px",
        padding: "24px"
      }}>
        {this.state.dropdownIndexDataLoading ? <LoaderCenter /> :
          <>
            <Typography style={webStyle.rightHeader}>Select Dropdown</Typography>
            <Typography style={webStyle.noDataTxt}>No Data Found!</Typography>
          </>
        }
      </Box>

    )
  }

  renderDropdownDetails = () => {
    const selectedDD = this.state.selectedSubDropdown && this.state.selectedSubDropdown.subDropdown && this.state.selectedSubDropdown.subDropdown.heading ? this.state.selectedSubDropdown.subDropdown.heading : null
    if (!selectedDD) return
    return (
      <>
        {this.renderRegistrtaionDD(selectedDD)}
        {this.renderFarmDiaryDD(selectedDD)}
        {this.renderRestDD(selectedDD)}
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={themeUser}>
        <NavigationMenu navigation={this.props.navigation} id={this.props.id}>
          <Grid container spacing={2}>
            <Grid item md={4} lg={4} sm={12} xs={12} style={{ padding: "0" }}>
              <Typography style={webStyle.dropDownTitle}>Dropdowns</Typography>
              <Box style={webStyle.leftBoxWrapper}>
                {this.state.dropdownIndexData.length ? this.state.dropdownIndexData.map((item: DropdownIndex, itemIndex: number) => {
                  const dropdownData = getDropdownData(item)
                  return (
                    <>
                      <Accordion className="main-accordian-wrapper"
                        expanded={this.state.currentExpandedDD === item.dropdown}
                        onChange={this.handleCurrentExpandedDD(item.dropdown)}
                        data-testid={`dropdown-parent-${itemIndex}`}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon style={{ width: "24px", height: "24px", color: "#1C2324", opacity: 0.72 }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box style={{ display: "flex", alignItems: "center" }}>
                            <img src={dropdownData.imageSrc} style={{ margin: dropdownData.margin }} />
                            <Typography className="main-dropdown-title">{dropdownData.title}</Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails className="main-accordian-details-wrapper">
                          <Divider style={{ marginTop: 0, height: "2px", background: "#F0EBE0" }} />
                          <Box className="main-sub-dropdown-wrapper">
                            {item.subdropdowns.length ? item.subdropdowns.map((subItem: Subdropdown, subItemIndex: number) => {
                              return (
                                <>
                                  <Box className="sub-dropdown-wrapper"
                                    style={{
                                      border: "1px solid",
                                      borderColor: this.subDropdownBorderColor(subItem)
                                    }}
                                    onClick={() => this.setSubDropDownData(item, subItem)}
                                    data-testid={`dropdown-sub-${subItemIndex}-${itemIndex}`}
                                  >
                                    <Box>
                                      <Box>
                                        <Typography className="subdropdown-title">{subItem.heading}</Typography>
                                      </Box>
                                      <Box>
                                        <Typography className="subdropdown-count">{subItem.count}</Typography>
                                      </Box>
                                    </Box>
                                    <img src={RightArrowSVG} className="subdropdown-expand-arrow" />
                                  </Box>
                                </>
                              )
                            }) : (<></>)}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )
                })
                  : <>
                    <Typography style={webStyle.noDataFoundText}>{this.state.dropdownIndexDataLoading ? <LoaderCenter /> : "No Data Found!"}</Typography>
                  </>}

              </Box>
            </Grid>
            <RightGrid item md={8} lg={8} sm={12} xs={12}>
              <Box style={webStyle.rightDivWrapper}>
                {this.state.selectedSubDropdown?.subDropdown?.heading ? this.renderDropdownDetails() : this.renderSelectDropdown()}
              </Box>
            </RightGrid>
          </Grid>
        </NavigationMenu>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const RightGrid = styled(Grid)({
  padding: "0 0 0 24px !important",
  "@media (max-width: 960px)": {
    padding: "24px 0 0 0 !important",
  }
})
const webStyle = {
  // LEFT SIDE
  dropDownTitle: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: '48px',
    marginBottom: '24px',
    color: "#1C2324"
  } as React.CSSProperties,
  leftBoxWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "12px"
  } as React.CSSProperties,
  noDataFoundText: {
    textAlign: 'center',
    padding: '1rem'
  } as React.CSSProperties,

  // RIGHT SIDE
  rightDivWrapper: {
    boxShadow: "2px 8px 32px 4px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px",
    minHeight: "800px",
    height: "100%"
  } as React.CSSProperties,
  rightHeader: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#1C2324"
  } as React.CSSProperties,
  noDataTxt:{
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#1C2324",
    textAlign: "center",
    marginTop: "50px"
  }as React.CSSProperties,
};
// Customizable Area End

import React from "react";
// Customizable Area Start
import {
    styled,
    Box,
    Modal,
    Card,
    IconButton,
    Typography,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    RadioGroup,
    Radio,
    CircularProgress
} from "@material-ui/core";
import SellActivityController, { Props, configJSON } from "./SellActivityController";
import { closeIconSvg, saleSvg, deleteIcon, rupeeIconSVG } from "./assets";
import { DateRange, Add, KeyboardArrowDown } from "@material-ui/icons";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { NoMenuDataFound, renderErrorMessage } from "../../../components/src/AdminConsoleHelper";
import { getLabelFromId, keyDown } from "../../../components/src/helper";
import { ICropDetails, IInitailValueSell, ISelectFarm } from "../../../components/src/interface.web";
import ErrorModal from "../../../components/src/ErrorModal.web";

const inputCottonQuanity = {
    min: 10,
    max: 100000,
    step: 10, // Allow decimal values
}
const inputPriceCotton = {
    min: 10,
    max: 200,
    step: 10, // Allow decimal values
}
const inputDistanceFarmerBuyer = {
    min: 0,
    max: 1000,
    step: 10, // Allow decimal values
}

const inputPropTotalAmount = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 500,
        max: 1000000,
        step: 100, // Allow Integer values
    },
}

const inputPropTransportCost = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 100,
        max: 200000,
        step: 100, // Allow Integer values
    },
}

const inputPropLaborCost = {
    startAdornment: (
        <InputAdornment className="startIconCustom" position="start">
            <img src={rupeeIconSVG} alt="rupees_image" />
        </InputAdornment>
    ),
    inputProps: {
        min: 0,
        max: 1000000,
        step: 100, // increase by 100
    },
}
// Customizable Area End

export default class Sell extends SellActivityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSellForm = () => {
        return (
            <Modal
                open={this.openSellActivityModal()}
                style={style.mainModalStyle}
                BackdropProps={{
                    style: style.modalBackdropPropsStyle,
                }}
                data-testid="sell_activity_modal"
            >
                <Card variant="outlined" style={style.modalCardStyle}>
                    <Box style={style.modalBoxWrapper}>
                        {/* MODAL HEADER */}
                        <ModalHeader>
                            <Box style={style.headerLeftBoxWrapper}>
                                <img src={saleSvg} alt="sell" style={style.headerImage} />
                                <Typography style={style.headerTitle}>Sell</Typography>
                            </Box>
                            <Box>
                                <IconButton style={{ padding: 0 }} onClick={this.handleCloseSellActivityModal} >
                                    <img src={closeIconSvg} style={{ width: "24px", height: "24px" }} />
                                </IconButton>
                            </Box>
                        </ModalHeader>
                        {/* MODAL BODY */}
                        {this.state.getActivityDetailsLoading ?
                            <Box style={style.formLoaderBox}>
                                <CircularProgress size={40} color="secondary" />
                            </Box>
                            :
                            <form noValidate autoComplete="off" data-testid="sell-form-submit-testid" onSubmit={(e) => e.preventDefault()}>

                                <CustomBoxHeader>

                                    <Grid container spacing={2}>
                                        <CustomFieldGrid item xs={12} lg={6} >
                                            <CustomFormLabel>Select Farm</CustomFormLabel>
                                            <CustomSelectDD
                                                disabled={this.props.isEditSellModalOpen}
                                                IconComponent={CustomArrowIcon}
                                                style={style.inputFieldStyle}
                                                data-testid="select_farm_testid"
                                                variant="outlined"
                                                color="secondary"
                                                inputProps={{ "aria-label": "Without label" }}
                                                displayEmpty
                                                name="select_farm_id"
                                                renderValue={(value) => {
                                                    if (this.state.selectedFarmId) {
                                                        return this.state.farmNamesList.find((item) => item.attributes.id == this.state.selectedFarmId)?.attributes?.name
                                                    } else if (!this.state.selectedFarmId) {
                                                        return (
                                                            <span className="opcity-48">Select Farm</span>
                                                        );
                                                    }
                                                }}
                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleChangeSelectFarm(event.target.value) }}
                                                error={Boolean(this.state.selectedFarmIdError)}
                                                value={this.state.selectedFarmId}
                                            >
                                                <MenuItem value={""} disabled>
                                                    <span className="opcity-48">Select Farm</span>
                                                </MenuItem>
                                                {this.state.farmNamesList.map((item: ISelectFarm) => {
                                                    return (
                                                        <MenuItem
                                                            style={{ paddingBlock: "0px" }}
                                                            value={item.attributes.id}
                                                            key={item.id}
                                                        >
                                                            <RadioGroup
                                                                value={this.state.selectedFarmId}
                                                                style={{
                                                                    width: "100%",
                                                                    borderBottom: "1px solid #F0EBE0",
                                                                }}
                                                            >
                                                                <CustomFormControlLabel
                                                                    value={item.attributes.id}
                                                                    control={<Radio />}
                                                                    style={{ width: "100%" }}
                                                                    label={
                                                                        <Box
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography style={style.cropDropDownLabel}>
                                                                                    {item.attributes.name}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Typography style={style.cropUnitText}>
                                                                                {item.attributes.farm_area}{" "}{item.attributes?.unit_farm_area_id?.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </RadioGroup>
                                                        </MenuItem>
                                                    );
                                                })}
                                                <CustomButton
                                                    onClick={this.openAddFarm}
                                                    startIcon={<Add style={{ width: "18px", height: "18px" }} />}
                                                >
                                                    Add Farm
                                                </CustomButton>
                                            </CustomSelectDD>
                                            {renderErrorMessage(this.state.selectedFarmIdError)}
                                        </CustomFieldGrid>
                                        <CustomFieldGrid item xs={12} lg={6} >
                                            <CustomFormLabel>Select Crop</CustomFormLabel>
                                            <CustomSelectDD
                                                IconComponent={CustomArrowIcon}
                                                style={style.inputFieldStyle}
                                                data-testid="select_crop_testid"
                                                variant="outlined"
                                                color="secondary"
                                                inputProps={{ "aria-label": "Without label" }}
                                                displayEmpty
                                                name="select_crop_id"
                                                renderValue={(value) => {
                                                    if (this.state.selectedCropId) {
                                                        return this.state.cropNamesList.find((item) => item.attributes.id == this.state.selectedCropId)?.attributes?.crop_name_id?.name
                                                    } else if (!this.state.selectedCropId) {
                                                        return (
                                                            <span className="opcity-48">Select Crop</span>
                                                        );
                                                    }
                                                }}
                                                value={this.state.selectedCropId}
                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => { this.handleChangeSelectCrop(event.target.value) }}
                                                error={Boolean(this.state.selectedCropIdError)}
                                                disabled={!this.state.selectedFarmId || this.props.isEditSellModalOpen}
                                            >
                                                <MenuItem value={""} disabled>
                                                    <span className="opcity-48">Select Crop</span>
                                                </MenuItem>
                                                {this.state.cropNamesList.map((item: ICropDetails) => {
                                                    return (
                                                        <MenuItem
                                                            style={{ paddingBlock: "0px" }}
                                                            value={item.attributes.id}
                                                            key={item.id}
                                                        >
                                                            <RadioGroup
                                                                value={this.state.selectedCropId}
                                                                style={{
                                                                    width: "100%",
                                                                    borderBottom: "1px solid #F0EBE0",
                                                                }}
                                                            >
                                                                <CustomFormControlLabel
                                                                    value={item.attributes.id}
                                                                    control={<Radio />}
                                                                    style={{ width: "100%" }}
                                                                    label={
                                                                        <Box
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <Typography style={style.cropDropDownLabel}>
                                                                                    {item.attributes?.crop_name_id?.name}{" | "}<span style={{ color: "#108B88" }}>{item.attributes?.crop_type_id?.name}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Typography style={style.cropUnitText}>
                                                                                {item.attributes?.total_crop_area}{" "}{item.attributes?.unit_of_area_id?.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </RadioGroup>
                                                        </MenuItem>
                                                    );
                                                })}
                                                <CustomButton
                                                    startIcon={<Add style={{ width: "18px", height: "18px" }} />}
                                                    onClick={this.openAddCrop}
                                                >
                                                    Add Crop
                                                </CustomButton>
                                            </CustomSelectDD>
                                            {renderErrorMessage(this.state.selectedCropIdError)}
                                        </CustomFieldGrid>
                                    </Grid>
                                </CustomBoxHeader>
                                <CustomHR />

                                {/* MAP FORMS */}
                                {this.state.sellForms.map((form: IInitailValueSell, formIndex: number) => {
                                    return (
                                        <>
                                            <CustomBox key={formIndex}>
                                                <Grid container spacing={2} style={{ position: "relative" }}>
                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Date of Sell</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <KeyboardDatePicker
                                                                    required
                                                                    data-testid="date_of_sell_testid"
                                                                    className="customDatePicker custom-textbox"
                                                                    placeholder="DD/MM/YY"
                                                                    format="DD/MM/yyyy"
                                                                    inputVariant="outlined"
                                                                    color="secondary"
                                                                    keyboardIcon={<DateRange style={style.customDateIcon} />}
                                                                    minDate={this.state.minimumDate}
                                                                    minDateMessage={"Please select a valid date"}
                                                                    maxDate={moment()}
                                                                    maxDateMessage={"Selected date cannot be a future date"}
                                                                    name="date_of_sell"
                                                                    value={form.date_of_sell}
                                                                    onChange={(newDate) => this.handleChange("date_of_sell", newDate, formIndex)}
                                                                    error={Boolean(form.errors.date_of_sell)}
                                                                    helperText={form.errors.date_of_sell}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Quantity of Crop Sold</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                className="textfield-with-select"
                                                                data-testid="quantity_of_crop_sold_value_testid"
                                                                fullWidth
                                                                placeholder="Enter Quantity"
                                                                type="number"
                                                                onKeyDown={keyDown}
                                                                variant="outlined"
                                                                color="secondary"
                                                                name="quantity_of_crop_sold_value"
                                                                value={form.quantity_of_crop_sold_value}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("quantity_of_crop_sold_value", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.quantity_of_crop_sold_value) || Boolean(form.errors.quantity_of_crop_sold_unit_id)}
                                                                helperText={form.errors.quantity_of_crop_sold_value || form.errors.quantity_of_crop_sold_unit_id}
                                                                InputProps={{
                                                                    endAdornment: this.state.unitQuantityOfCottonList.length === 1 || !this.state.unitQuantityOfCottonList.length ?
                                                                        <>
                                                                            <CustomUnitLabel>
                                                                                <Typography noWrap>
                                                                                    {getLabelFromId(form.quantity_of_crop_sold_unit_id, this.state.unitQuantityOfCottonList)}
                                                                                </Typography>
                                                                            </CustomUnitLabel>
                                                                        </> :
                                                                        (
                                                                            <SelectWithInput
                                                                                className="select-with-input"
                                                                                IconComponent={KeyboardArrowDown}
                                                                                data-testid="quantity_of_crop_sold_unit_id_testid"
                                                                                value={form.quantity_of_crop_sold_unit_id}
                                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("quantity_of_crop_sold_unit_id", event.target.value, formIndex)}
                                                                                error={Boolean(form.errors.quantity_of_crop_sold_unit_id)}
                                                                            >
                                                                                <MenuItem value={""}>
                                                                                    <span className="opcity-48"><em>None</em></span>
                                                                                </MenuItem>
                                                                                {
                                                                                    this.state.unitQuantityOfCottonList.map((unit, index) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={unit.id}
                                                                                                value={unit.id}
                                                                                            >
                                                                                                {unit.attributes.unit_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </SelectWithInput>
                                                                        ),
                                                                    inputProps: inputCottonQuanity
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6} style={{ opacity: 0.6 }}>
                                                        <CustomFormLabel>{`Crop Price/${form.crop_price_unit}`}</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                disabled
                                                                className="inputIconTextFieldCustom textfield-with-select"
                                                                data-testid="cotton_price_value_testid"
                                                                fullWidth
                                                                placeholder={`Price/${form.crop_price_unit}`}
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                name="crop_price_value"
                                                                onKeyDown={keyDown}
                                                                value={form.crop_price_value}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("crop_price_value", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.crop_price_value)}
                                                                helperText={form.errors.crop_price_value}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment className="startIconCustom" position="start">
                                                                            <img src={rupeeIconSVG} alt="rupees_image" />
                                                                        </InputAdornment>
                                                                    ),
                                                                    inputProps: inputPriceCotton
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Total Amount</CustomFormLabel>
                                                        <FormControl>
                                                            <TextField
                                                                className="inputIconTextFieldCustom custom-textbox"
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                placeholder="Total Amount"
                                                                data-testid="total_amount_testid"
                                                                InputProps={inputPropTotalAmount}
                                                                onKeyDown={keyDown}
                                                                name="total_amount"
                                                                value={form.total_amount}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("total_amount", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.total_amount)}
                                                                helperText={form.errors.total_amount}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={12}>
                                                        <CustomFormLabel>Distance from Farmer location to Buyer</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                className="textfield-with-select"
                                                                data-testid="distance_farmer_buyer_value_testid"
                                                                fullWidth
                                                                placeholder="Enter Distance"
                                                                type="number"
                                                                onKeyDown={keyDown}
                                                                variant="outlined"
                                                                color="secondary"
                                                                name="distance_farmer_buyer_value"
                                                                value={form.distance_farmer_buyer_value}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("distance_farmer_buyer_value", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.distance_farmer_buyer_value) || Boolean(form.errors.distance_farmer_buyer_unit_id)}
                                                                helperText={form.errors.distance_farmer_buyer_value || form.errors.distance_farmer_buyer_unit_id}
                                                                InputProps={{
                                                                    endAdornment: this.state.unitDistanceFarmerToBuyerList.length === 1 || !this.state.unitDistanceFarmerToBuyerList.length ?
                                                                        <>
                                                                            <CustomUnitLabel>
                                                                                <Typography noWrap>
                                                                                    {getLabelFromId(form.distance_farmer_buyer_unit_id, this.state.unitDistanceFarmerToBuyerList)}
                                                                                </Typography>
                                                                            </CustomUnitLabel>
                                                                        </> :
                                                                        (
                                                                            <SelectWithInput
                                                                                className="select-with-input"
                                                                                IconComponent={KeyboardArrowDown}
                                                                                data-testid="distance_farmer_buyer_unit_id_testid"
                                                                                value={form.distance_farmer_buyer_unit_id}
                                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("distance_farmer_buyer_unit_id", event.target.value, formIndex)}
                                                                                error={Boolean(form.errors.distance_farmer_buyer_unit_id)}
                                                                            >
                                                                                <MenuItem value={""}>
                                                                                    <span className="opcity-48"><em>None</em></span>
                                                                                </MenuItem>
                                                                                {
                                                                                    this.state.unitDistanceFarmerToBuyerList.map((unit, index) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={unit.id}
                                                                                                value={unit.id}
                                                                                            >
                                                                                                {unit.attributes.unit_name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </SelectWithInput>
                                                                        ),
                                                                    inputProps: inputDistanceFarmerBuyer
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Buyer Name</CustomFormLabel>
                                                        <TextField
                                                            className="custom-textbox"
                                                            type="text"
                                                            variant="outlined"
                                                            color="secondary"
                                                            placeholder="Enter Name"
                                                            data-testid="buyer_name_testid"
                                                            name="buyer_name"
                                                            value={form.buyer_name}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("buyer_name", event.target.value, formIndex)}
                                                            error={Boolean(form.errors.buyer_name)}
                                                            helperText={form.errors.buyer_name}
                                                        />
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Transportation Cost</CustomFormLabel>
                                                        <FormControl>
                                                            <TextField
                                                                className="inputIconTextFieldCustom custom-textbox"
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                placeholder="Total Cost"
                                                                data-testid="transportation_cost_testid"
                                                                InputProps={inputPropTransportCost}
                                                                onKeyDown={keyDown}
                                                                name="transportation_cost"
                                                                value={form.transportation_cost}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("transportation_cost", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.transportation_cost)}
                                                                helperText={form.errors.transportation_cost}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Vehicle Type</CustomFormLabel>
                                                        <FormControl fullWidth>
                                                            <CustomSelectDD
                                                                IconComponent={CustomArrowIcon}
                                                                data-testid="vehicle_type_id_testid"
                                                                variant="outlined"
                                                                color="secondary"
                                                                style={style.inputFieldStyle}
                                                                inputProps={{ "aria-label": "Without label" }}
                                                                displayEmpty
                                                                name="vehicle_type_id"
                                                                value={form.vehicle_type_id}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("vehicle_type_id", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.vehicle_type_id)}
                                                            >
                                                                <MenuItem value={""} disabled>
                                                                    <span className="opcity-48">Select Vehicle</span>
                                                                </MenuItem>
                                                                {this.state.vehicleTypesList.length > 0 ?
                                                                    this.state.vehicleTypesList.map((each) => (
                                                                        <MenuItem key={each.id} value={each.id}>
                                                                            {each.name}
                                                                        </MenuItem>
                                                                    ))
                                                                    : NoMenuDataFound()
                                                                }
                                                            </CustomSelectDD>
                                                            {renderErrorMessage(form.errors.vehicle_type_id)}
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    <CustomFieldGrid item xs={12} lg={6}>
                                                        <CustomFormLabel>Labor cost of load & unload</CustomFormLabel>
                                                        <FormControl>
                                                            <TextField
                                                                className="inputIconTextFieldCustom custom-textbox"
                                                                type="number"
                                                                variant="outlined"
                                                                color="secondary"
                                                                placeholder="Total Cost"
                                                                data-testid="labor_cost_load_unload_testid"
                                                                InputProps={inputPropLaborCost}
                                                                onKeyDown={keyDown}
                                                                name="labor_cost_load_unload"
                                                                value={form.labor_cost_load_unload}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleChange("labor_cost_load_unload", event.target.value, formIndex)}
                                                                error={Boolean(form.errors.labor_cost_load_unload)}
                                                                helperText={form.errors.labor_cost_load_unload}
                                                            />
                                                        </FormControl>
                                                    </CustomFieldGrid>

                                                    {/* REMOVE BUTTON */}
                                                    {this.state.sellForms.length > 1  && (!this.props.isEditSellModalOpen || !Boolean(form.id))  && (
                                                        <Box
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 0,
                                                            }}
                                                        >
                                                            <IconButton
                                                                style={{ padding: 0 }}
                                                                data-testid={`remove_activity_button_${formIndex}`}
                                                                onClick={() => this.handleRemoveEntry(formIndex)}
                                                            >
                                                                <img
                                                                    src={deleteIcon}
                                                                    alt="Delete activity"
                                                                    style={{ width: "24px", height: "24px" }}
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            </CustomBox>
                                            <CustomHR />
                                        </>
                                    )
                                }
                                )}

                                {/* BUTTONS */}
                                <ButtonGrid>
                                    <Button type="submit"
                                        style={{ ...style.commonButton, background: "#F0EBE0" }}
                                        data-testid={`add_activity_button`}
                                        onClick={this.handleAddAnotherEntry}
                                    >
                                        <Add style={{ marginRight: "8px", opacity: "64%" }} />
                                        <Typography style={{ ...style.commonButtonText, opacity: "64%", color: "#1C2324" }}>
                                            Add another Entry
                                        </Typography>
                                    </Button>
                                    <Button type="submit"
                                        style={{ ...style.commonButton, background: "#DF5200", }}
                                        onClick={this.onSubmitSellForm}
                                        data-testid="submit_button_testid"
                                        disabled={this.state.createActivityLoading || this.state.editActivityLoading}
                                    >
                                        {this.state.createActivityLoading || this.state.editActivityLoading ?
                                            <CircularProgress size={20} style={{ color: "#fff" }} />
                                            :
                                            <Typography style={style.commonButtonText}>{this.props.isEditSellModalOpen ? "Update" : "Save"}</Typography>
                                        }
                                    </Button>
                                </ButtonGrid>
                            </form>
                        }
                    </Box>
                </Card>
            </Modal>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                {this.props.isSellModalOpen && this.renderSellForm()}

                {this.state.isErrorModalOpen &&
                    <ErrorModal
                        data-testid={"sell_error_modal_testid"}
                        open={this.state.isErrorModalOpen}
                        BtnTitle="Okay"
                        errorTitle={this.state.ErrorModalTitle}
                        errorSubTitle={this.state.ErrorModalMessage}
                        handleBtnClick={this.handleCloseErrorModal}
                    />
                }
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start 
const CustomBoxHeader = styled(Box)({
    padding: "0px 32px 24px 32px",
    "@media (max-width:600px)": {
        padding: "0 24px 24px 24px",
    },
});

const ModalHeader = styled(Box)({
    display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "32px 32px 16px 32px",
        "@media (max-width:600px)": {
            padding: "32px 24px 16px 24px",
        },
})

const CustomFormLabel = styled(FormLabel)({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    opacity: 0.64,
    marginBottom: "8px"
})

const CustomSelectDD = styled(Select)({
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent !important"
    },
    "& .MuiSelect-iconOutlined": {
        right: "10px !important"
    }
})

const CustomArrowIcon = styled(KeyboardArrowDown)({
    fontSize: "26px",
    color: "#1C2324",
    opacity: 0.40
});

const CustomFormControlLabel = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
        width: "100%",
    },
});

const CustomButton = styled(Button)({
    color: "#006A77",
    margin: "6px 0 0 12px",
    fontSize: "12px",
    fontWeight: 500,
    "& .MuiButton-startIcon": {
        marginRight: "2px ",
    },
});

const CustomHR = styled(Divider)({
    background: "#F0EBE0",
    width: "100%",
    height: "2px",
})

const CustomFieldGrid = styled(Grid)({
    display: "flex !important",
    flexDirection: "column",
    minHeight: "93px",
})

const CustomBox = styled(Box)({
    padding: "24px 32px",
    "@media (max-width:600px)": {
        padding: "24px",
    },
});
const CustomUnitLabel = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "83px",
    height: "28px",
    padding: "0px 12px",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
})

const SelectWithInput = styled(Select)({
    '&.select-with-input': {
        height: "28px !important"
    },
    '& .MuiInputBase-formControl': {
        height: "auto"
    },
    '&.select-with-input .MuiSelect-iconOutlined': {
        right: "0 !important"
    },
    '&.select-with-input .MuiSelect-root': {
        width: "83px",
        height: "28px",
        padding: "12px !important",
        boxSizing: "border-box",
        lineHeight: "2px",
        textAlign: "left",
        fontSize: "14px",
        borderRadius: "8px",
        background: "#F0EBE0",
    }
})
const ButtonGrid = styled(Grid)({
    padding: "24px 32px 32px 32px",
    rowGap: "32px",
    display:"flex",
    flexDirection: "column",
    "@media (max-width:600px)": {
        padding: "24px",
    },
})

const style = {
    mainModalStyle: {
        display: "flex",
        gap: 1,
        fontSize: "16px",
        justifyContent: "center",
        borderBlockStart: "1px solid #ccc",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        height: "100%",
    } as React.CSSProperties,

    modalBackdropPropsStyle: {
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    } as React.CSSProperties,

    modalCardStyle: {
        borderRadius: "12px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        maxWidth: "712px",
        flexDirection: "column",
        margin: "0",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95%",
        maxHeight: "95%",
        overflow: "hidden",
    } as React.CSSProperties,

    modalBoxWrapper: {
        fontSize: "16px",
        height: "100%",
        overflowY: "auto",
    } as React.CSSProperties,

    headerLeftBoxWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    } as React.CSSProperties,

    headerTitle: {
        color: "#1C2324",
        fontFamily: "IBM Plex Sans",
        fontSize: "20px",
        fontWeight: 500,
    } as React.CSSProperties,

    headerImage: {
        width: "48px",
        height: "48px",
        objectFit: "cover",
    } as React.CSSProperties,

    // FORM ELEMENTS STYLE
    inputFieldStyle: {
        height: "56px"
    } as React.CSSProperties,

    cropDropDownLabel: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#1C2324",
    } as React.CSSProperties,

    customDateIcon: {
        color: "#1C2324",
        opacity: "0.48",
    } as React.CSSProperties,

    cropUnitText: {
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 400,
        fontStyle: "italic",
    } as React.CSSProperties,

    // BUTTONS STYLE

    commonButton: {
        width: "100%",
        borderRadius: "28px",
        paddingBlock: "14px",
        height:"56px"
    } as React.CSSProperties,

    commonButtonText: {
        color: "#FFF",
        textAlign: "center",
        fontSize: "18px",
        fontFamily: "IBM Plex Sans",
        whiteSpace: "nowrap",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        gap: 8,
    } as React.CSSProperties,
    // EDIT LOADER
    formLoaderBox: {
        height: "100%",
        minHeight: "550px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,
}
// Customizable Area End
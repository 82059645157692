import React from "react";
// Customizable Area Start
import {
  styled,
  Box,
  Modal,
  Card,
  IconButton,
  Typography,
  Divider,
  Grid,
  Button,
  FormLabel,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  RadioGroup,
  Radio,
  Input,
  FormHelperText,
  CircularProgress,
  Checkbox
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PestManagmentActivityController, { Props } from "./PestManagmentActivityController";
import { closeIconSvg, deleteIcon, pestManagementSvg, rupeeIconSVG } from "./assets";
import { DateRange, Add, KeyboardArrowDown } from "@material-ui/icons";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  NoMenuDataFound,
  renderErrorMessage,
} from "../../../components/src/AdminConsoleHelper";
import { keyDown } from "../../../components/src/helper";
import CustomModal from "../../../components/src/customModal.web";
import {
  IIntialValuePestManagment,
  ISelectFarm,
  ISelectCropAttributesCropTypeId as IDropDownOptions,
} from "../../../components/src/interface.web";

const inputPropPickingCost = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 0,
    step: 1, 
  },
};
// Customizable Area End

export default class PestManagmentActivity extends PestManagmentActivityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDateOfApplication = (
    form: IIntialValuePestManagment,
    formIndex: number,
    label: string
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>{label}</CustomFormLabel>
        <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required
              clearable
              data-testid="date_of_picking_testid"
              className="customDatePicker custom-textbox"
              placeholder="DD/MM/YYYY"
              format="DD/MM/yyyy"
              inputVariant="outlined"
              color="secondary"
              keyboardIcon={<DateRange style={webStyle.customDateIcon} />}
              minDate={this.state.minimumDate}
              minDateMessage={'Please select a valid date'}
              maxDate={moment()}
              maxDateMessage={'Selected date cannot be a future date'}
              name="date"
              value={form.date}
              onChange={(newDate) =>
                this.handleInputChange(
                  {
                    target: {
                      name: "date",
                      value: newDate,
                    },
                  },
                  formIndex
                )
              }
              error={!!form.errors.date}
              helperText={form.errors.date}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderPesticideCostField = (
    form: IIntialValuePestManagment,
    formIndex: number
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Pesticide Cost</CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="pesticide_cost_testid"
            InputProps={inputPropPickingCost}
            onKeyDown={keyDown}
            name="pesticide_cost"
            value={form.pesticide_cost}
            onChange={(e) =>
              this.handleInputChange(e, formIndex)
            }
            error={Boolean(form.errors.pesticide_cost)}
            helperText={form.errors.pesticide_cost}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderQuantityOfPesticideField = (
    form: IIntialValuePestManagment,
    formIndex: number
  ) => {
    const { unitOfPesticideQuantityList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Quantity of pesticide</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="quantity_of_pesticide_testid"
            fullWidth
            placeholder="Select Quantity"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="quantity_of_pesticide"
            value={form.quantity_of_pesticide}
            error={!!form.errors.quantity_of_pesticide || !!form.errors.unit_of_quantity_pesticide_id}
            helperText={form.errors.quantity_of_pesticide != "" ? form.errors.quantity_of_pesticide : form.errors.unit_of_quantity_pesticide_id}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "quantity_of_pesticide",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfPesticideQuantityList.length === 1 || !unitOfPesticideQuantityList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.quantity_of_pesticide, unitOfPesticideQuantityList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_of_quantity_pesticide_id}
                  value={form.unit_of_quantity_pesticide_id}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_of_quantity_pesticide_id",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfPesticideQuantityList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderQuantityField = (
    form: IIntialValuePestManagment,
    formIndex: number
  ) => {
    const { unitOfBioAgentQuantityList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Quantity</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="nutrient_quantity_value_testid"
            fullWidth
            placeholder="Select Quantity"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="quantity"
            value={form.quantity}
            error={!!form.errors.quantity || !!form.errors.unit_of_quantity_id}
            helperText={form.errors.quantity != "" ? form.errors.quantity : form.errors.unit_of_quantity_id}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "quantity",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfBioAgentQuantityList.length === 1 || !unitOfBioAgentQuantityList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.quantity, unitOfBioAgentQuantityList)}
                  </Typography>
                </CustomUnitLabel>
            ) : (
              <SelectWithInput
                className="select-with-input"
                error={!!form.errors.unit_of_quantity_id}
                value={form.unit_of_quantity_id}
                IconComponent={KeyboardArrowDown}
                data-testid="nutrient_quantity_unit_testid"
                onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                  this.handleInputChange(
                    {
                      target: {
                        name: "unit_of_quantity_id",
                        value: event.target.value as string,
                      },
                    }, formIndex)
                }}
              >
                <MenuItem value={""} disabled>
                  <span className="opcity-48"><em>None</em></span>
                </MenuItem>
                {unitOfBioAgentQuantityList.map(
                  (unit) => {
                    return (
                      <MenuItem key={unit.id} value={unit.id}>
                        {unit.attributes.unit_name}
                      </MenuItem>
                    );
                  }
                )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderPesticideNameField = (
    form: IIntialValuePestManagment,
    formIndex: number
  ) => {
    const { pesticideNameList, filteredPesticideList, searchName } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Pesticide Name</CustomFormLabel>
        <FormControl fullWidth>
          <Select
            className="selectDDCustom"
            data-testid="pesticide_name_testid"
            variant="outlined"
            color="secondary"
            style={webStyle.inputFieldStyle}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            name="pesticide_name"
            error={!!form.errors.pesticide_name}
            renderValue={(value) => {
              if (form.pesticide_name) {
                return pesticideNameList.find((item) => item.id.toString() == form.pesticide_name)?.name_english
              } else if (!form.pesticide_name) {
                return (
                  <span className="opcity-48">Select Pesticide Name</span>
                );
              }
            }}
          >
            <MenuItem value={""} disabled>
              <span className="opcity-48">Select Pesticide Name</span>
            </MenuItem>

            <SearchBoxWrapper>
              <Input
                fullWidth
                disableUnderline
                autoFocus
                className="custom_search_input"
                data-testid="search_nutrient_name_testid"
                type="text"
                placeholder="Search name"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                name="search_pesticide_name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                value={searchName}
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  this.handleFilteredPesticideList(event.target.value)
                }}
              />
            </SearchBoxWrapper>
            <Box>
              {filteredPesticideList.length > 0
                ? filteredPesticideList.map((each, index) => (
                  <MenuItem
                    key={each.id}
                    value={each.id}
                    data-testid={`pesticide_name_menu_${index}`}
                    onClick={(event) => {
                      this.handleInputChange({
                        target: {
                          name: "pesticide_name",
                          value: each.id,
                        },
                      }, formIndex)
                    }}
                    selected={each.id == form.pesticide_name}
                  >
                    {each.name}
                  </MenuItem>
                ))
                : NoMenuDataFound()}
            </Box>
          </Select>
          {form.errors.pesticide_name && (
            <FormHelperText style={webStyle.errorStyle}>
              {form.errors.pesticide_name}
            </FormHelperText>
          )}
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderBioAgentNameField = (
    form: IIntialValuePestManagment,
    formIndex: number
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Bio-agent Name</CustomFormLabel>
        <FormControl fullWidth>
          <Select
            className="selectDDCustom"
            data-testid="bioagent_name_testid"
            variant="outlined"
            color="secondary"
            style={webStyle.inputFieldStyle}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            name="bioagent_name"
            onClose={this.resetSearchNuturientData}
            error={!!form.errors.bioagent_name}
            renderValue={(value) => {
              if (form.bioagent_name) {
                return this.state.bioAgentList.find((item) => item.id.toString() == form.bioagent_name)?.name_english
              } else if (!form.bioagent_name) {
                return (
                  <span className="opcity-48">Select Bio-agent Name</span>
                );
              }
            }}
          >
            <MenuItem value={""} disabled>
              <span className="opcity-48">Select Bio-agent Name</span>
            </MenuItem>

            <SearchBoxWrapper>
              <Input
                fullWidth
                disableUnderline
                autoFocus
                className="custom_search_input"
                data-testid="search_bioagent_name_testid"
                type="text"
                placeholder="Search name"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }} />
                  </InputAdornment>
                }
                name="search_bioagent_name"
                value={this.state.searchName}
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  this.handleFilteredBioAgentList(event.target.value)
                }}
              />
            </SearchBoxWrapper>
            <Box>
              {this.state.filteredBioAgentList.length > 0
                ? this.state.filteredBioAgentList.map((each, index) => (
                  <MenuItem
                    key={each.id}
                    value={each.id}
                    data-testid={`bioagent_name_menu_${index}`}
                    selected={each.id.toString() == form.bioagent_name}
                    onClick={(event) => {
                      this.handleInputChange({
                        target: {
                          name: "bioagent_name",
                          value: each.id,
                        },
                      }, formIndex)
                    }}
                  >
                    {each.name}
                  </MenuItem>
                ))
                : NoMenuDataFound()}
            </Box>
          </Select>
          {form.errors.bioagent_name && (
            <FormHelperText style={webStyle.errorStyle}>
              {form.errors.bioagent_name}
            </FormHelperText>
          )}
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderMachineCostForSpraying = (
    form: IIntialValuePestManagment,
    formIndex: number
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Machine Cost for Spraying </CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="machine_cost_for_spraying_testid"
            InputProps={inputPropPickingCost}
            onKeyDown={keyDown}
            name="machine_cost_for_spraying"
            value={form.machine_cost_for_spraying}
            onChange={(e) =>
              this.handleInputChange(e, formIndex)
            }
            error={Boolean(form.errors.machine_cost_for_spraying)}
            helperText={form.errors.machine_cost_for_spraying}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderLaborCostField = (
    form: IIntialValuePestManagment,
    formIndex: number,
    isMinMax: boolean
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Labor Cost</CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="labor_cost_testid"
            InputProps={inputPropPickingCost}
            onKeyDown={keyDown}
            name="labor_cost"
            value={form.labor_cost}
            onChange={(e) =>
              this.handleInputChange(e, formIndex, false, isMinMax)
            }
            error={!!form.errors.labor_cost}
            helperText={form.errors.labor_cost}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderTypeOfTrap = (form: IIntialValuePestManagment, formIndex: number) => {
    const { trapsList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Type of Trap</CustomFormLabel>
        <FormControl fullWidth>
          <Select
            style={webStyle.inputFieldStyle}
            className="selectDDCustom"
            data-testid="type_of_trap_testid"
            variant="outlined"
            color="secondary"
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            name="type_of_trap"
            value={form.type_of_trap}
            onChange={(
              event: React.ChangeEvent<{
                value: unknown | string;
              }>
            ) => {
              this.handleInputChange({
                target: {
                  name: "type_of_trap",
                  value: event.target.value as string,
                },
              }, formIndex)
            }}
            error={!!form.errors.type_of_trap}
            MenuProps={{
              style: { maxWidth: "400px" },
            }}
            renderValue={(value) => {
              if (form.type_of_trap) {
                return trapsList.find((item) => item.id == form.type_of_trap)?.name_english
              } else {
                return <span className="opcity-48">Select Type of Trap</span>
              }
            }}
          >
            <MenuItem value={""} disabled>
              <span className="opcity-48">Select Type of Trap</span>
            </MenuItem>
            {trapsList.map((item: IDropDownOptions) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.name_english}
                </MenuItem>
              );
            })}
          </Select>
          {form.errors.type_of_trap && (
            <FormHelperText style={webStyle.errorStyle}>
              {form.errors.type_of_trap}
            </FormHelperText>
          )}
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderNumberOfTrap = (form: IIntialValuePestManagment, formIndex: number) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Number of Trap</CustomFormLabel>
        <FormControl>
          <TextField
            className="input-left-padding custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Enter Number"
            onKeyDown={keyDown}
            data-testid="number_of_trap_testid"
            name="number_of_trap"
            value={form.number_of_trap}
            onChange={(e) =>
              this.handleInputChange(e, formIndex)
            }
            error={Boolean(form.errors.number_of_trap)}
            helperText={form.errors.number_of_trap}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderCostOfTrap = (form: IIntialValuePestManagment, formIndex: number) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Cost of Trap</CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="cost_of_trap_testid"
            InputProps={inputPropPickingCost}
            onKeyDown={keyDown}
            name="cost_of_trap"
            value={form.cost_of_trap}
            onChange={(e) =>
              this.handleInputChange(e, formIndex)
            }
            error={Boolean(form.errors.cost_of_trap)}
            helperText={form.errors.cost_of_trap}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderCostOfInput = (form: IIntialValuePestManagment, formIndex: number) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Cost of Input</CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="cost_of_input_testid"
            InputProps={inputPropPickingCost}
            onKeyDown={keyDown}
            name="cost_of_input"
            value={form.cost_of_input}
            onChange={(e) =>
              this.handleInputChange(e, formIndex)
            }
            error={Boolean(form.errors.cost_of_input)}
            helperText={form.errors.cost_of_input}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderPestManagementField = (form: IIntialValuePestManagment, formIndex: number) => {
    const { pestManagementList } = this.state;
    const updatedFormValues = [...this.state.pestManagnmentForms];
    return <CustomFieldGrid item xs={12} lg={6}>
      <CustomFormLabel>Pest Management</CustomFormLabel>
      <FormControl fullWidth>
        <Select
          disabled={!!form?.id}
          style={webStyle.inputFieldStyle}
          className="selectDDCustom"
          data-testid="pest_management_testid"
          variant="outlined"
          color="secondary"
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
          name="pest_management"
          value={form.pest_management}
          onChange={(
            event: React.ChangeEvent<{
              value: unknown | string;
            }>
          ) => {
            this.handleInputChange({
              target: {
                name: "pest_management",
                value: event.target.value as string,
              },
            }, formIndex, false, false, true)
          }}
          error={!!form.errors.pest_management}
          MenuProps={{
            style: { maxWidth: "400px" },
          }}
        >
          <MenuItem value={""} disabled>
            <span className="opcity-48">
              Select Pest Management
            </span>
          </MenuItem>
          {pestManagementList.map((item: IDropDownOptions) => {
            return (
              <MenuItem value={item.id} key={item.id}>
                {item.name_english}
              </MenuItem>
            );
          })}
        </Select>
        {form.errors.pest_management && (
          <FormHelperText style={webStyle.errorStyle}>
            {form.errors.pest_management}
          </FormHelperText>
        )}
      </FormControl>
    </CustomFieldGrid>
  }

  renderFieldsBasedOnPestManagement = (pestManagement: string, form: IIntialValuePestManagment, formIndex: number) => {
    const objectWithFoliarName: IDropDownOptions | undefined  = this.state.pestManagementList.find((obj: IDropDownOptions) => obj.name.toLowerCase().includes("foliar"));
    const objectWithTrapName: IDropDownOptions | undefined = this.state.pestManagementList.find((obj: IDropDownOptions) => obj.name.toLowerCase().includes("trap"));
    const objectWithBioAgentName: IDropDownOptions | undefined = this.state.pestManagementList.find((obj: IDropDownOptions) => obj.name.toLowerCase().includes("bio"));
    switch (pestManagement.toString()) {
      case (objectWithFoliarName?.id.toString()):
        return (
          <>
            {this.renderPesticideNameField(form, formIndex)}
            {this.renderDateOfApplication(form, formIndex, "Date of Application")}
            {this.renderMachineCostForSpraying(form, formIndex)}
            {this.renderQuantityOfPesticideField(form, formIndex)}
            {this.renderPesticideCostField(form, formIndex)}
            {this.renderLaborCostField(form, formIndex, true)}
          </>
        )
      case (objectWithTrapName?.id.toString()):
        return (
          <>
            {this.renderTypeOfTrap(form, formIndex)}
            {this.renderDateOfApplication(form, formIndex, "Date of Installation")}
            {this.renderNumberOfTrap(form, formIndex)}
            {this.renderCostOfTrap(form, formIndex)}
            {this.renderLaborCostField(form, formIndex, false)}
          </>
        )
      case (objectWithBioAgentName?.id.toString()):
        return (
          <>
            {this.renderBioAgentNameField(form, formIndex)}
            {this.renderDateOfApplication(form, formIndex, "Date of Release")}
            {this.renderQuantityField(form, formIndex)}
            {this.renderCostOfInput(form, formIndex)}
            {this.renderLaborCostField(form, formIndex, false)}
          </>
        )
      default:
        return (
          <>
            {this.renderDateOfApplication(form, formIndex, "Date of Application")}
            {this.renderPesticideCostField(form, formIndex)}
            {this.renderQuantityOfPesticideField(form, formIndex)}
          </>
        )
    }
  }

  renderPestManagmentForm = () => {
    return (
      <Modal
        open={this.props.isPestManagmentActivity}
        style={webStyle.mainModalStyle}
        BackdropProps={{
          style: webStyle.modalBackdropPropsStyle,
        }}
        data-testid="nutrient_activity_modal"
      >
        <Card variant="outlined" style={webStyle.modalCardStyle}>
          <Box style={webStyle.modalBoxWrapper}>
            {/* MODAL HEADER */}
            <Box style={webStyle.modalHeaderWrapper}>
              <Box style={webStyle.headerLeftBoxWrapper}>
                <img
                  src={pestManagementSvg}
                  alt="Pest Managment"
                  style={webStyle.headerImage}
                />
                <Typography style={webStyle.headerTitle}>
                  Pest Managment
                </Typography>
              </Box>
              <Box>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={this.handleCloseHarvestActivityModal}
                >
                  <img
                    src={closeIconSvg}
                    style={{ width: "24px", height: "24px" }}
                  />
                </IconButton>
              </Box>
            </Box>

            {/* MODAL BODY */}
            <form
              noValidate
              autoComplete="off"
              data-testid="pestmanagment-form-submit-testid"
              onSubmit={(e) => e.preventDefault()}
            >
              <CustomBoxHeader>
                <Grid container spacing={2}>
                  <CustomFieldGrid item xs={12} lg={6}>
                    <CustomFormLabel>Select Farm</CustomFormLabel>
                    <CustomSelectDD
                      IconComponent={CustomArrowIcon}
                      style={webStyle.inputFieldStyle}
                      data-testid="select_farm_testid"
                      variant="outlined"
                      color="secondary"
                      disabled={this.props.isEditActivity}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      name="select_farm_id"
                      renderValue={(value) => {
                        if (this.state.selectedFarmId) {
                          return this.state.farmNamesList.find((item) => item.attributes.id == this.state.selectedFarmId)?.attributes?.name
                        } else if (!this.state.selectedFarmId) {
                          return (
                            <span className="opcity-48">Select Farm</span>
                          );
                        }
                      }}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        this.handleFarmChange(event.target.value as string)
                      }}
                      error={Boolean(this.state.selectedFarmIdError)}
                    >
                      <MenuItem value={""} disabled>
                        <span className="opcity-48">Select Farm</span>
                      </MenuItem>
                      {this.state.farmNamesList.map((item: ISelectFarm) => {
                        return (
                          <MenuItem
                            style={{ paddingBlock: "0px" }}
                            value={item.id}
                            key={item.id}
                          >
                            <RadioGroup
                              value={this.state.selectedFarmId}
                              style={{
                                width: "100%",
                                borderBottom: "1px solid #F0EBE0",
                              }}
                            >
                              <CustomFormControlLabel
                                value={item.id}
                                control={<Radio />}
                                style={{ width: "100%" }}
                                label={
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        style={webStyle.cropDropDownLabel}
                                      >
                                        {item.attributes.name}
                                      </Typography>
                                    </Box>
                                    <Typography style={webStyle.cropUnitText}>
                                      {item.attributes.farm_area}{" "}
                                      {item.attributes?.unit_farm_area_id?.name}
                                    </Typography>
                                  </Box>
                                }
                              />
                            </RadioGroup>
                          </MenuItem>
                        );
                      })}
                      <CustomButton
                        onClick={this.handleAddFarm}
                        startIcon={
                          <Add style={{ width: "18px", height: "18px" }} />
                        }
                      >
                        Add Farm
                      </CustomButton>
                    </CustomSelectDD>
                    {renderErrorMessage(this.state.selectedFarmIdError)}
                  </CustomFieldGrid>
                  <CustomFieldGrid item xs={12} lg={6}>
                    <CustomFormLabel>Select Crop</CustomFormLabel>
                    <CustomSelectDD
                      style={webStyle.inputFieldStyle}
                      IconComponent={CustomArrowIcon}
                      data-testid="select_crop_testid"
                      variant="outlined"
                      color="secondary"
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      name="select_crop"
                      multiple
                      disabled={this.props.isEditActivity || !this.state.selectedFarmId}
                      error={Boolean(this.state.selectedCropIdError)}
                      value={this.state.selectedCropIdArr}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => { 
                          this.handleChangeSelectCrop(event.target.value as number[])                                            
                      }}
                      renderValue={(selected:any) => {
                        if (!this.state.selectedCropIdArr.length) {
                          return <span className="opcity-48">Select Crop</span>;
                        } else {
                          let selectedCropsLabel: string[] = [];
                          selected.forEach((value: string | number) => {
                            const result = this.state.cropNamesList.find(
                              (option) =>
                                String(option.attributes.id) == String(value)
                            );
                            if (result) {
                              selectedCropsLabel.push(
                                result.attributes.crop_name_id.name
                              );
                            }
                          });
                          const labels = selectedCropsLabel.join(", ");
                          return <Typography noWrap>{labels}</Typography>;
                        }
                      }}
                    >
                      <MenuItem value={""} disabled>
                        <span className="opcity-48">Select Crop</span>
                      </MenuItem>
                      {this.state.cropNamesList.map((option) => (
                        <MenuItem
                          key={option.attributes.id}
                          value={option.attributes.id}
                          style={{ paddingBottom: 0, flexDirection: "column" }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              alignItems: "center",
                              marginLeft: "-22px",
                            }}
                          >
                            <Checkbox
                              checked={
                                this.state.selectedCropIdArr.indexOf(
                                  option.attributes.id
                                ) != -1
                              }
                            />
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography style={webStyle.cropDropDownLable}>
                                  {option.attributes.crop_name_id.name}
                                  {" | "}
                                  <span style={{ color: "#108B88" }}>
                                    {option.attributes?.crop_type_id?.name}
                                  </span>
                                </Typography>
                              </Box>
                              <Typography style={webStyle.cropUnitText}>
                                {option.attributes.total_crop_area}{" "}
                                {option.attributes?.unit_of_area_id?.name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box style={{ padding: "0", width: "100%" }}>
                            <Divider style={{ background: "#F0EBE0" }} />
                          </Box>
                        </MenuItem>
                      ))}
                      <CustomButton
                        onClick={this.handleAddCrop}
                        startIcon={
                          <Add style={{ width: "18px", height: "18px" }} />
                        }
                      >
                        Add Crop
                      </CustomButton>
                    </CustomSelectDD>
                    {renderErrorMessage(this.state.selectedCropIdError)}
                  </CustomFieldGrid>
                </Grid>
              </CustomBoxHeader>
              <CustomHR />

              {/* MAP FORMS */}
              {this.state.pestManagnmentForms.map(
                (form: IIntialValuePestManagment, formIndex: number) => {
                  return (
                    <>
                      <CustomBox key={formIndex}>
                        <Grid
                          container
                          spacing={2}
                          style={{ position: "relative" }}
                        >
                          {this.renderPestManagementField(form, formIndex)}
                          {this.renderFieldsBasedOnPestManagement(
                            form.pest_management,
                            form,
                            formIndex
                          )}
                          {this.state.pestManagnmentForms.length > 1 && (
                            <Box
                              style={{
                                visibility:!!form?.id ? "hidden" : "visible",
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                            >
                              <IconButton
                                style={webStyle.iconbutton}
                                disabled={!!form?.id}
                                onClick={() =>
                                  this.handleRemoveActivity(formIndex)
                                }
                              >
                                <img
                                  src={deleteIcon}
                                  alt="Delete activity"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </IconButton>
                            </Box>
                          )}
                        </Grid>
                      </CustomBox>
                      <CustomHR />
                    </>
                  );
                }
              )}
              {/* BUTTONS */}
              <CustomButtonWrapper container>
                <Button
                  type="submit"
                  style={{ ...webStyle.commonButton, background: "#F0EBE0" }}
                  data-testid={`add_activity_button`}
                  onClick={this.handleAddAnotherEntry}
                >
                  <Add style={{ marginRight: "8px", opacity: "64%" }} />
                  <Typography
                    style={{
                      ...webStyle.commonButtonText,
                      color: "#1C2324",
                      opacity: "64%",
                    }}
                  >
                    Add another Entry
                  </Typography>
                </Button>
                <Button
                  type="submit"
                  style={{ ...webStyle.commonButton, background: "#DF5200" }}
                  data-testid="submit_button_testid"
                  onClick={this.onSubmitPestManagmentForm}
                  disabled={this.state.loader}
                >
                  <Typography style={webStyle.commonButtonText}>
                    {this.state.loader ? (
                      <CircularProgress
                        size={28}
                        style={{ color: "#fff" }}
                      />
                    ) : (
                    this.props.isEditActivity ? "Update" : "Save"
                    )}
                  </Typography>
                </Button>
              </CustomButtonWrapper>
            </form>
          </Box>
        </Card>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        {this.props.isPestManagmentActivity && this.renderPestManagmentForm()}
        {this.state.isErrorModalOpen && (
          <CustomModal
            open={!!this.state.isErrorModalOpen}
            onClose={this.handleCloseErrorModal}
            title={this.state.ErrorModalTitle}
            description={this.state.ErrorModalMessage}
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomFormLabel = styled(FormLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#1C2324",
  opacity: 0.64,
  marginBottom: "8px",
});

const CustomSelectDD = styled(Select)({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent !important",
  },
  "& .MuiSelect-iconOutlined": {
    right: "10px !important",
  },
});

const CustomArrowIcon = styled(KeyboardArrowDown)({
  fontSize: "26px",
  color: "#1C2324",
  opacity: 0.4,
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
});

const CustomButton = styled(Button)({
  color: "#006A77",
  margin: "6px 0 0 12px",
  fontSize: "12px",
  fontWeight: 500,
  "& .MuiButton-startIcon": {
    marginRight: "2px ",
  },
});

const CustomHR = styled(Divider)({
  background: "#F0EBE0",
  width: "100%",
  height: "2px",
});

const CustomFieldGrid = styled(Grid)({
  display: "flex !important",
  flexDirection: "column",
  minHeight: "93px",
});

const CustomBox = styled(Box)({
  padding: "24px 32px",
  "@media (max-width:600px)": {
    padding: "16px 24px",
  },
});

const CustomBoxHeader = styled(Box)({
  padding: "0px 32px 24px 32px",
  "@media (max-width:600px)": {
    padding: "16px 24px",
  },
});

const CustomUnitLabel = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "83px",
  height: "28px",
  padding: "0px 12px",
  boxSizing: "border-box",
  lineHeight: "2px",
  textAlign: "center",
  fontSize: "14px",
  borderRadius: "8px",
  background: "#F0EBE0",
});

const SelectWithInput = styled(Select)({
  "&.select-with-input": {
    height: "28px !important",
  },
  "& .MuiInputBase-formControl": {
    height: "auto",
  },
  '&.select-with-input .MuiSelect-iconOutlined': {
    right: "0 !important"
  },
  "&.select-with-input .MuiSelect-root": {
    width: "83px",
    height: "28px",
    padding: "12px !important",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "left",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
  },
});

const SearchBoxWrapper = styled(Box)({
  margin: "8px 16px",
  background: "#FCFAF7",
  borderRadius: "28px",
  border: "2px solid #F0EBE0",
  "& .custom_search_input>.MuiInputAdornment-positionStart": {
    margin: "0 6px 0 12px !important",
  },
});

const CustomButtonWrapper = styled(Grid)({
  padding: "24px 32px 32px",
  flexDirection: "row",
  rowGap: "32px",
  "@media (max-width:600px)": {
    padding: "24px 24px 32px",
  },
})

const webStyle = {
  mainModalStyle: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropPropsStyle: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  } as React.CSSProperties,
  modalCardStyle: {
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    maxWidth: "712px",
    flexDirection: "column",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  modalBoxWrapper: {
    fontSize: "16px",
    height: "100%",
    overflowY: "auto",
  } as React.CSSProperties,
  modalHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "32px 32px 16px 32px",
  } as React.CSSProperties,
  headerLeftBoxWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  // FORM ELEMENTS STYLE
  inputFieldStyle: {
    height: "56px",
  } as React.CSSProperties,
  cropDropDownLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  cropUnitText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontStyle: "italic",
  } as React.CSSProperties,
  // BUTTONS STYLE
  commonButton: {
    borderRadius: "28px",
    width: "100%",
    paddingBlock: "14px",
  } as React.CSSProperties,
  commonButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
  iconbutton: {
    padding: 0
  } as React.CSSProperties,
  cropDropDownLable: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  errorStyle: {
    fontSize: "0.75rem",
    color: "#f44336",
  } as React.CSSProperties,
};
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Button,

    FormControlLabel,
    InputAdornment,
    IconButton,
    MenuItem,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Checkbox,
    Divider,
    styled
} from "@material-ui/core";
import { BlankCheckbox, checkedCheckBox } from "../../../AdminConsole/src/assets";

import CloseIcon from "@material-ui/icons/Close";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SearchIcon from '@material-ui/icons/Search';
import "../components/PopoverComponent.css"


// Customizable Area End
interface SelectedField {
    name: string,
    id: string,
}
interface SelectedFieldFarmer {
    first_name: string,
    id: string,
}

interface SelectedFieldExecutive {
    first_name: string,
    id: string,
}

interface MyComponentProps {
    isFilterOpen: boolean;
    anchorEl: HTMLElement | null;
    closeFilter: () => void;
    sortAll: boolean;
    isDisabled: {
        state: boolean,
        district: boolean,
        taluka: boolean,
        village: boolean,
        farmer: boolean,
        fe: boolean,
        admin: boolean,
        superAdmin: boolean
    };
    expanded: string | boolean;
    handleChangePanel: any;
    handleSubmitAccordion: () => void;
    handleClear: () => void;

    opacityStateCalculator: () => number | string;
    selectedState: SelectedField;
    stateInputValue: string;
    handleStateSearch: (value: string) => void;
    filteredStates: SelectedField[];
    handleChangeStateField: any;

    opacityDistrictCalculator: () => number | string;
    selectedDistrict: SelectedField;
    districtInputValue: string;
    handleDistrictSearch: (value: string) => void;
    filteredDistricts: SelectedField[];

    handleChangeDistrictField: any;

    opacityTalukaCalculator: () => number | string;
    selectedTaluka: SelectedField;
    talukaInputValue: string;
    handleTalukaSearch: (value: string) => void;
    filteredTalukas: SelectedField[];
    handleChangeTalukaField: any;

    opacityVillageCalculator: () => number | string;
    selectedVillage: SelectedField;
    villageInputValue: string;
    handleVillageSearch: (value: string) => void;
    filteredVillages: SelectedField[];
    handleChangeVillageField: any;

    opacityFarmerCalculator: () => number | string;
    selectedFarmer: SelectedFieldFarmer;
    farmerInputValue: string;
    handleFarmerSearch: (value: string) => void;
    filteredFarmers: SelectedFieldFarmer[];
    handleChangeFarmerField: any;

    opacityFieldExecutiveCalculator: () => number | string;
    selectedFieldExecutive: SelectedFieldExecutive;
    fieldExecutiveInputValue: string;
    handleFieldExectiveSearch: (value: string) => void;
    filteredFieldExecutive: SelectedFieldFarmer[];
    handleChangeFieldExecutiveField: any;

    opacityAdminCalculator: () => number | string;
    selectedAdmin: SelectedFieldFarmer;
    adminInputValue: string;
    handleAdminSearch: (value: string) => void;
    filteredAdmin: SelectedFieldFarmer[];
    handleChangeAdminField: any;

    opacitySuperAdminCalculator: () => number | string;
    selectedSuperAdmin: SelectedFieldFarmer;
    superAdminInputValue: string;
    handleSuperAdminSearch: (value: string) => void;
    filteredSuperAdmin: SelectedFieldFarmer[];
    handleChangeSuperAdminField: any;

    sortaz: any;
    sortza: any;
    sortAscending: boolean;
    sortDescending: boolean;
    isAddGroup: boolean;
    isCreateGroup: boolean;

}
export default function PopoverComponent({
    isFilterOpen,
    anchorEl,
    closeFilter,
    sortAll,
    isDisabled,
    expanded,
    handleChangePanel,

    opacityStateCalculator,
    selectedState,
    stateInputValue,
    handleStateSearch,
    filteredStates,
    handleChangeStateField,

    opacityDistrictCalculator,
    selectedDistrict,
    districtInputValue,
    handleDistrictSearch,
    filteredDistricts,
    handleChangeDistrictField,


    opacityTalukaCalculator,
    selectedTaluka,
    talukaInputValue,
    handleTalukaSearch,
    filteredTalukas,
    handleChangeTalukaField,

    opacityVillageCalculator,
    selectedVillage,
    villageInputValue,
    handleVillageSearch,
    filteredVillages,
    handleChangeVillageField,


    opacityFarmerCalculator,
    selectedFarmer,
    farmerInputValue,
    handleFarmerSearch,
    filteredFarmers,
    handleChangeFarmerField,

    opacityFieldExecutiveCalculator,
    selectedFieldExecutive,
    fieldExecutiveInputValue,
    handleFieldExectiveSearch,
    filteredFieldExecutive,
    handleChangeFieldExecutiveField,

    opacityAdminCalculator,
    selectedAdmin,
    adminInputValue,
    handleAdminSearch,
    filteredAdmin,
    handleChangeAdminField,

    opacitySuperAdminCalculator,
    selectedSuperAdmin,
    superAdminInputValue,
    handleSuperAdminSearch,
    filteredSuperAdmin,
    handleChangeSuperAdminField,

    handleSubmitAccordion,
    handleClear,
    sortaz,
    sortza,
    sortAscending,
    sortDescending,
    isAddGroup,
    isCreateGroup,
}: MyComponentProps) {

    return (
        <Popover
            className="filterPopover chat_container"
            open={isFilterOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            onClose={closeFilter}
        >

            <Box
                style={{
                    padding: "21px 16px 16px 16px",
                    fontFamily: "IBM Plex Sans",
                    fontStyle: "normal",
                    overflow: "inherit !important",
                    width: "270px",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#1C2324",
                            fontWeight: 500,
                        }}
                    >
                        Filter
                    </Typography>
                    <IconButton
                        aria-label="close"
                        data-testid="filterModalCloseBtnTestid"
                        className="close_btn"
                        style={{ padding: "0px" }}
                        onClick={closeFilter}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    className="checkbox-container"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "6px",
                    }}
                >
                    <FormControlLabel
                        className="temp-checkbox"
                        control={
                            <Checkbox
                                color="secondary"
                                disableRipple
                                className="azcheckbox"

                                icon={<img src={BlankCheckbox} />}
                                checkedIcon={
                                    <img src={checkedCheckBox} className="checkboxImgCustom" />
                                }
                                onChange={sortaz}
                                checked={sortAscending}
                            />
                        }
                        label={
                            <Typography style={{
                                fontWeight: 400, fontSize: "14px",
                                lineHeight: "22px"
                            }} component="span">
                                Sort by <b>A-Z</b>
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        className="temp-checkbox"
                        style={{ transform: " translateX(2px) !important" }}
                        control={
                            <Checkbox
                                color="secondary"
                                disableRipple
                                className="zacheckbox"
                                icon={<img src={BlankCheckbox} />}
                                checkedIcon={
                                    <img src={checkedCheckBox} className="checkboxImgCustom" />
                                }
                                onChange={sortza}

                                checked={sortDescending}
                            />
                        }
                        label={
                            <Typography style={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "22px"
                            }}
                                component="span">
                                Sort by <b>Z-A</b>
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
                <Box style={{ marginTop: "12px" }}>
                    <Grid container style={{ display: "grid", gridGap: "12px" }}>
                        <Grid item container md={12} lg={12}>
                            <Grid item xs={3}>
                                <Box>
                                    <Typography
                                        style={{
                                            transform: "translateY(4px)",
                                            display: "flex",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                        }}
                                    >
                                        State
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                className="accordion-grid"
                                xs={9}
                                style={{
                                    position: "relative",
                                    zIndex: 10,
                                }}
                            >
                                <Accordion
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                    }}
                                    disabled={isDisabled.state}
                                    expanded={expanded === "panel1"}
                                    onChange={handleChangePanel("panel1")}
                                >
                                    <AccordionSummaryCustom
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            minHeight: "28px",
                                            height: "28px",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                                opacity: opacityStateCalculator()

                                            }}
                                        >
                                            {selectedState.name}
                                        </Typography>
                                    </AccordionSummaryCustom>
                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <Divider
                                            style={{
                                                width: "80%",
                                                height: "1px",
                                                background: "#F0EBE0",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Box>
                                    <AccordianDetailsCustom>
                                        <Box style={{ display: "flex", flexDirection: "column" }}>
                                            <TextField
                                                placeholder="Search State"
                                                variant="outlined"
                                                color="secondary"
                                                data-testid="stateTextfieldTestid"
                                                className="state-field-class"
                                                value={stateInputValue}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            style={{ background: "#ffffff" }}
                                                        >
                                                            <SearchIconCustom
                                                                fontSize="small"
                                                                style={{ opacity: "0.32", color: "#1C2324" }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                style={{ marginBottom: "7px" }}
                                                onChange={(e: any) => {
                                                    handleStateSearch(e);
                                                }}
                                                id="state-textfield"
                                            />
                                            <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                {filteredStates.map((stateData: any,index:number) => (
                                                    <MenuItem
                                                        key={`state-${index}`}
                                                        className="state-menu-items"
                                                        value={stateData.name}
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: "12px",
                                                            lineHeight: "18px",
                                                            padding: "0px",
                                                            marginBottom: "2px",
                                                            color: "#1C2324",
                                                        }}
                                                        onClick={() => {
                                                            handleChangeStateField(stateData)

                                                        }}
                                                    >
                                                        {stateData.name}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Box>
                                    </AccordianDetailsCustom>
                                </Accordion>
                            </Grid>
                        </Grid>
                        <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                            <Grid item xs={3}>
                                <Box>
                                    <Typography
                                        style={{
                                            transform: "translateY(4px)",
                                            display: "flex",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                        }}
                                    >
                                        District
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                className="accordion-grid"
                                style={{
                                    position: "relative",
                                    zIndex: 9,
                                }}
                            >
                                <Accordion
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                    }}
                                    disabled={isDisabled.district}
                                    expanded={
                                        expanded === "panel2"
                                    }
                                    onChange={handleChangePanel("panel2")}
                                >
                                    <AccordionSummaryCustom
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            minHeight: "28px",
                                            height: "28px",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                                opacity: opacityDistrictCalculator()

                                            }}
                                        >
                                            {selectedDistrict.name}
                                        </Typography>
                                    </AccordionSummaryCustom>
                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <Divider
                                            style={{
                                                width: "80%",
                                                height: "1px",
                                                background: "#F0EBE0",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Box>
                                    <AccordianDetailsCustom>
                                        <Box style={{ display: "flex", flexDirection: "column" }}>
                                            <TextField
                                                placeholder="Search District "
                                                className="district-field-class"
                                                data-testid="districtTextFieldTestid"
                                                variant="outlined"
                                                value={districtInputValue}
                                                id="district-textfield"
                                                style={{ marginBottom: "7px" }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIconCustom
                                                                fontSize="small"
                                                                style={{ opacity: "0.32", color: "#1C2324" }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e: any) => {
                                                    handleDistrictSearch(e);
                                                }}
                                            />
                                            <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                {Array.isArray(filteredDistricts) && filteredDistricts?.map((districtData: any,index:number) => (
                                                    <MenuItem
                                                        key={`district-${index}`}
                                                        value={districtData.name}
                                                        className="district-menu-items"
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: "12px",
                                                            lineHeight: "18px",
                                                            padding: "0px",
                                                            marginBottom: "2px",
                                                            color: "#1C2324",
                                                        }}
                                                        onClick={() => {
                                                            handleChangeDistrictField(districtData)
                                                        }}
                                                    >
                                                        {districtData.name}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Box>
                                    </AccordianDetailsCustom>
                                </Accordion>
                            </Grid>
                        </Grid>
                        <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                            <Grid item xs={3}>
                                <Box>
                                    <Typography
                                        style={{
                                            transform: "translateY(4px)",
                                            display: "flex",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Taluka
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                className="accordion-grid"
                                style={{
                                    position: "relative",
                                    zIndex: 8,
                                }}
                            >
                                <Accordion
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                    }}
                                    disabled={isDisabled.taluka}
                                    expanded={expanded === "panel3"}
                                    onChange={handleChangePanel("panel3")}
                                >
                                    <AccordionSummaryCustom
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            minHeight: "28px",
                                            height: "28px",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                                opacity: opacityTalukaCalculator()
                                            }}
                                        >

                                            {selectedTaluka?.name}
                                        </Typography>
                                    </AccordionSummaryCustom>
                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <Divider
                                            style={{
                                                width: "80%",
                                                height: "1px",
                                                background: "#F0EBE0",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Box>
                                    <AccordianDetailsCustom>
                                        <Box style={{ display: "flex", flexDirection: "column" }}>
                                            <TextField
                                                placeholder="Search Taluka"
                                                className="taluka-field-class"
                                                data-testid="talukaTextFieldTestid"
                                                variant="outlined"
                                                value={talukaInputValue}
                                                id="taluka-textfield"
                                                style={{ marginBottom: "7px" }}
                                                onChange={(e: any) => {
                                                    handleTalukaSearch(e);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            style={{ background: "#ffffff" }}
                                                        >
                                                            <SearchIconCustom
                                                                fontSize="small"
                                                                style={{ opacity: "0.32", color: "#1C2324" }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                {filteredTalukas.map((talukaData: any,index:number) => (
                                                    <MenuItem
                                                        key={`taluka-${index}`}
                                                        value={talukaData.name}
                                                        className="taluka-menu-items"
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: "12px",
                                                            lineHeight: "18px",
                                                            padding: "0px",
                                                            marginBottom: "2px",
                                                            color: "#1C2324",
                                                        }}
                                                        onClick={() => {
                                                            handleChangeTalukaField(talukaData)

                                                        }}
                                                    >

                                                        {talukaData.name}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Box>
                                    </AccordianDetailsCustom>
                                </Accordion>
                            </Grid>
                        </Grid>
                        <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                            <Grid item xs={3}>
                                <Box>
                                    <Typography
                                        style={{
                                            transform: "translateY(4px)",
                                            display: "flex",
                                            fontSize: "14px",
                                            lineHeight: "22px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                        }}
                                    >
                                        Village
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                className="accordion-grid"
                                style={{
                                    position: "relative",
                                    zIndex: 7,
                                }}
                            >
                                <Accordion
                                    style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                    }}
                                    disabled={isDisabled.village}
                                    expanded={expanded === "panel4"}
                                    onChange={handleChangePanel("panel4")}
                                >
                                    <AccordionSummaryCustom
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            minHeight: "28px",
                                            height: "28px",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                color: "#1C2324",
                                                fontWeight: 400,
                                                opacity: opacityVillageCalculator()
                                            }}
                                        >
                                            {selectedVillage.name}
                                        </Typography>
                                    </AccordionSummaryCustom>
                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <Divider
                                            style={{
                                                width: "80%",
                                                height: "1px",
                                                background: "#F0EBE0",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Box>
                                    <AccordianDetailsCustom>
                                        <Box style={{ display: "flex", flexDirection: "column" }}>
                                            <TextField
                                                placeholder="Search Village "
                                                className="village-field-class"
                                                data-testid="villageTextFieldTestid"
                                                id="village-textfield"
                                                value={villageInputValue}
                                                variant="outlined"
                                                style={{ marginBottom: "7px" }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            style={{ background: "#ffffff" }}
                                                        >
                                                            <SearchIconCustom
                                                                fontSize="small"
                                                                style={{ opacity: "0.32", color: "#1C2324" }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e: any) => {
                                                    handleVillageSearch(e);
                                                }}
                                            />
                                            <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                {filteredVillages.map((villageData: any,index:number) => (
                                                    <MenuItem
                                                        key={`village-${index}`}
                                                        value={villageData.name}
                                                        className="village-menu-items"
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: "12px",
                                                            lineHeight: "18px",
                                                            padding: "0px",
                                                            marginBottom: "2px",
                                                            color: "#1C2324",
                                                        }}
                                                        onClick={() => {
                                                            handleChangeVillageField(villageData)

                                                        }}
                                                    >
                                                        {villageData.name}
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Box>
                                    </AccordianDetailsCustom>
                                </Accordion>
                            </Grid>
                        </Grid>
                        {isAddGroup && !isCreateGroup &&
                            <>
                                <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                    <Grid item xs={3}>
                                        <Box>
                                            <Typography
                                                style={{
                                                    transform: "translateY(4px)",
                                                    display: "flex",
                                                    fontSize: "14px",
                                                    lineHeight: "22px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                SA
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        className="accordion-grid"
                                        style={{
                                            position: "relative",
                                            zIndex: 6,
                                        }}
                                    >
                                        <Accordion
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "#FCFAF7",
                                                border: "1px solid #F0EBE0",
                                                borderRadius: "8px",
                                                width: "176px",
                                            }}
                                            disabled={isDisabled.superAdmin}
                                            expanded={
                                                expanded === "panel5"
                                            }
                                            onChange={handleChangePanel("panel5")}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    minHeight: "28px",
                                                    height: "28px",
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: "12px",
                                                        lineHeight: "18px",
                                                        color: "#1C2324",
                                                        fontWeight: 400,
                                                        opacity: opacitySuperAdminCalculator()

                                                    }}
                                                >
                                                    {selectedSuperAdmin.first_name}
                                                </Typography>
                                            </AccordionSummaryCustom>
                                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                                <Divider
                                                    style={{
                                                        width: "80%",
                                                        height: "1px",
                                                        background: "#F0EBE0",
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </Box>
                                            <AccordianDetailsCustom>
                                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                                    <TextField
                                                        placeholder="Search SA "
                                                        className="farmer-field-class"
                                                        data-testid="super-adminTextFieldTestid"
                                                        variant="outlined"
                                                        value={superAdminInputValue}
                                                        id="farmer-textfield"
                                                        style={{ marginBottom: "7px" }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIconCustom
                                                                        fontSize="small"
                                                                        style={{ opacity: "0.32", color: "#1C2324" }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e: any) => {
                                                            handleSuperAdminSearch(e);
                                                        }}
                                                    />
                                                    <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                        {filteredSuperAdmin?.map((superAdminData: any,index:number) => (
                                                            <MenuItem
                                                                key={`SA-${index}`}
                                                                value={superAdminData.first_name}
                                                                className="farmer-menu-items super-admin-menu-items"
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                }}
                                                                onClick={() => {
                                                                    handleChangeSuperAdminField(superAdminData)
                                                                }}
                                                            >

                                                                {superAdminData.first_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </AccordianDetailsCustom>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                    <Grid item xs={3}>
                                        <Box>
                                            <Typography
                                                style={{
                                                    transform: "translateY(4px)",
                                                    display: "flex",
                                                    fontSize: "14px",
                                                    lineHeight: "22px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Admin
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        className="accordion-grid"
                                        style={{
                                            position: "relative",
                                            zIndex: 5,
                                        }}
                                    >
                                        <Accordion
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "#FCFAF7",
                                                border: "1px solid #F0EBE0",
                                                borderRadius: "8px",
                                                width: "176px",
                                            }}
                                            disabled={isDisabled.admin}
                                            expanded={
                                                expanded === "panel6"
                                            }
                                            onChange={handleChangePanel("panel6")}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    minHeight: "28px",
                                                    height: "28px",
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: "12px",
                                                        lineHeight: "18px",
                                                        color: "#1C2324",
                                                        fontWeight: 400,
                                                        opacity: opacityAdminCalculator()

                                                    }}
                                                >
                                                    {selectedAdmin.first_name}
                                                </Typography>
                                            </AccordionSummaryCustom>
                                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                                <Divider
                                                    style={{
                                                        width: "80%",
                                                        height: "1px",
                                                        background: "#F0EBE0",
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </Box>
                                            <AccordianDetailsCustom>
                                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                                    <TextField
                                                        placeholder="Search Admin "
                                                        className="farmer-field-class admin-field-class"
                                                        data-testid="farmerTextFieldTestid adminTextFieldTestid"
                                                        variant="outlined"
                                                        value={adminInputValue}
                                                        id="farmer-textfield"
                                                        style={{ marginBottom: "7px" }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIconCustom
                                                                        fontSize="small"
                                                                        style={{ opacity: "0.32", color: "#1C2324" }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e: any) => {
                                                            handleAdminSearch(e);
                                                        }}
                                                    />
                                                    <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                        {filteredAdmin.map((adminData: any,index:number) => (
                                                            <MenuItem
                                                            key={`admin-${index}`}
                                                                value={adminData.first_name}
                                                                className="farmer-menu-items admin-menu-items"
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                }}
                                                                onClick={() => {
                                                                    handleChangeAdminField(adminData)
                                                                }}
                                                            >

                                                                {adminData.first_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </AccordianDetailsCustom>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                    <Grid item xs={3}>
                                        <Box>
                                            <Typography
                                                style={{
                                                    transform: "translateY(4px)",
                                                    display: "flex",
                                                    fontSize: "14px",
                                                    lineHeight: "22px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                F.E.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        className="accordion-grid"
                                        style={{
                                            position: "relative",
                                            zIndex: 4,
                                        }}
                                    >
                                        <Accordion
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "#FCFAF7",
                                                border: "1px solid #F0EBE0",
                                                borderRadius: "8px",
                                                width: "176px",
                                            }}
                                            disabled={isDisabled.fe}
                                            expanded={expanded === "panel7"}
                                            onChange={handleChangePanel("panel7")}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    minHeight: "28px",
                                                    height: "28px",
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: "12px",
                                                        lineHeight: "18px",
                                                        color: "#1C2324",
                                                        fontWeight: 400,
                                                        opacity: opacityFieldExecutiveCalculator()
                                                    }}
                                                >
                                                    {selectedFieldExecutive.first_name}
                                                </Typography>
                                            </AccordionSummaryCustom>
                                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                                <Divider
                                                    style={{
                                                        width: "80%",
                                                        height: "1px",
                                                        background: "#F0EBE0",
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </Box>
                                            <AccordianDetailsCustom>
                                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                                    <TextField
                                                        placeholder="Search F.E. "
                                                        className="fieldExecutive-field-class"
                                                        data-testid="fieldExectiveTestId"
                                                        id="fieldExective-textfield"
                                                        value={fieldExecutiveInputValue}
                                                        variant="outlined"
                                                        style={{ marginBottom: "7px" }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ background: "#ffffff" }}
                                                                >
                                                                    <SearchIconCustom
                                                                        fontSize="small"
                                                                        style={{ opacity: "0.32", color: "#1C2324" }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e: any) => {
                                                            handleFieldExectiveSearch(e);
                                                        }}
                                                    />
                                                    <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                        {filteredFieldExecutive.map(
                                                            (fieldExectiveData: any,index:number) => (
                                                                <MenuItem
                                                                    key={`fe-${index}`}
                                                                    value={fieldExectiveData.first_name}
                                                                    className="fe-menu-items fe-menu-items"
                                                                    style={{
                                                                        fontWeight: 400,
                                                                        fontSize: "12px",
                                                                        lineHeight: "18px",
                                                                        padding: "0px",
                                                                        marginBottom: "2px",
                                                                        color: "#1C2324",
                                                                    }}
                                                                    onClick={() => {
                                                                        handleChangeFieldExecutiveField(fieldExectiveData)

                                                                    }}
                                                                >
                                                                    {fieldExectiveData.first_name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Box>
                                                </Box>
                                            </AccordianDetailsCustom>
                                        </Accordion>
                                    </Grid>
                                </Grid>

                                <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                    <Grid item xs={3}>
                                        <Box>
                                            <Typography
                                                style={{
                                                    transform: "translateY(4px)",
                                                    display: "flex",
                                                    fontSize: "14px",
                                                    lineHeight: "22px",
                                                    color: "#1C2324",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Farmer
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        className="accordion-grid"
                                        style={{
                                            position: "relative",
                                            zIndex: 3,
                                        }}
                                    >
                                        <Accordion
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "#FCFAF7",
                                                border: "1px solid #F0EBE0",
                                                borderRadius: "8px",
                                                width: "176px",
                                            }}
                                            disabled={isDisabled.farmer}
                                            expanded={
                                                expanded === "panel8"
                                            }
                                            onChange={handleChangePanel("panel8")}
                                        >
                                            <AccordionSummaryCustom
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    minHeight: "28px",
                                                    height: "28px",
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: "12px",
                                                        lineHeight: "18px",
                                                        color: "#1C2324",
                                                        fontWeight: 400,
                                                        opacity: opacityFarmerCalculator()

                                                    }}
                                                >
                                                    {selectedFarmer.first_name}
                                                </Typography>
                                            </AccordionSummaryCustom>
                                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                                <Divider
                                                    style={{
                                                        width: "80%",
                                                        height: "1px",
                                                        background: "#F0EBE0",
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </Box>
                                            <AccordianDetailsCustom>
                                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                                    <TextField
                                                        placeholder="Search Farmer "
                                                        className="farmer-field-class"
                                                        data-testid="farmerTextFieldTestid"
                                                        variant="outlined"
                                                        value={farmerInputValue}
                                                        id="farmer-textfield"
                                                        style={{ marginBottom: "7px" }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIconCustom
                                                                        fontSize="small"
                                                                        style={{ opacity: "0.32", color: "#1C2324" }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e: any) => {
                                                            handleFarmerSearch(e);
                                                        }}
                                                    />
                                                    <Box style={{ maxHeight: "80px", overflowY: "scroll" }}>
                                                        {filteredFarmers.map((farmerData: any,index:number) => (
                                                            <MenuItem
                                                                key={`farmer-${index}`}
                                                                value={farmerData.first_name}
                                                                className="farmer-menu-items"
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    lineHeight: "18px",
                                                                    padding: "0px",
                                                                    marginBottom: "2px",
                                                                    color: "#1C2324",
                                                                }}
                                                                onClick={() => {
                                                                    handleChangeFarmerField(farmerData)
                                                                }}
                                                            >

                                                                {farmerData.first_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </AccordianDetailsCustom>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid
                            item
                            container
                            md={12}
                            lg={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexFlow: "row",
                                marginTop: "10px",
                                transform: "translateX(-7px)"
                            }}
                        >
                            <Button
                                data-test-id="submit_accordion_btn"
                                onClick={handleSubmitAccordion}
                                className="submit_accordion_btn"
                                variant="contained"
                                style={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    textTransform: "uppercase",
                                    background: "#DF5200",
                                    padding: "5px 40px",
                                    borderRadius: "16px",
                                    color: "#FFFFFF",
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                style={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    textTransform: "uppercase",
                                    border: "1px solid #DF5200",
                                    borderRadius: "16px",
                                    background: "#FFFFF",
                                    padding: "5px 40px",
                                    marginLeft: "12px",
                                    color: "#1C2324",
                                }}
                                variant="outlined"
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Popover>
    )
}
const AccordianDetailsCustom = styled(AccordionDetails)({
    display: 'block',
    padding: "4px 8px 8px!important"
})
const SearchIconCustom = styled(SearchIcon)({
    width: "14px"
})
const AccordionSummaryCustom = styled(AccordionSummary)({
    "& .MuiAccordionSummary-content": {
        overflow: "hidden"
    }
})
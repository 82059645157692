Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfmultiplecrops";
exports.labelBodyText = "cfmultiplecrops Body";

exports.btnExampleTitle = "CLICK ME";

// GENERAL
exports.GET_METHOD_TYPE = "GET";
exports.POST_METHOD_TYPE = "POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.PATCH_METHOD_TYPE = "PATCH";
exports.DELETE_METHOD_TYPE = "DELETE";

// ADD CROP DROPDOWNS 

exports.fetchFarmListDataAPiEndPoint = "mobile_and_web/farm_dairy/selected_farm" // FARM LIST
exports.fetchCropNameDataAPiEndPoint = "admin/bx_block_farm_dairy/crop_names" // CROP NAME
exports.fetchCropTypeDataAPiEndPoint = "admin/bx_block_farm_dairy/crop_types" // CROP TYPE
exports.fetchCropVarietyDataAPiEndPoint = "admin/bx_block_farm_dairy/varieties" // CROP VARIETY
exports.fetchSeedSourceDataAPiEndPoint = "admin/bx_block_farm_dairy/seed_sources" // SEED SOURCE
exports.fetchBioAgentNameDataAPiEndPoint = "admin/bx_block_farm_dairy/bio_agents" // BIO AGENT NAME
exports.fetchCropAreaUnitDataAPiEndPoint = "admin/bx_block_dropdown/unit_dropdowns?activity=crops&dropdown=unit_of_area_crops" // UNIT OF CROP AREA
exports.fetchQuantityOfSeedUnitDataAPiEndPoint = "admin/bx_block_dropdown/unit_dropdowns?activity=crops&dropdown=unit_of_seed_quantity_crops" // UNIT OF QUANTITY OF SEED 
exports.fetchBioAgentQuntityUnitDataAPiEndPoint = "admin/bx_block_dropdown/unit_dropdowns?activity=crops&dropdown=unit_of_bio_agent_quantity_crops" // UNIT OF BIO AGENT QUANTITY 

exports.cropsCRUDApiEndPoint = 'admin/bx_block_profile_bio/crops'
exports.EndRestartCropApiEndPoint = 'mobile_and_web/farm_dairy/activity_crop_failures'

// STATIC STRINGS
exports.ViewCrop = {
  EndCropModalTitle:"Are you sure?",
  EndCropModalDescription:"Once you end crop, farm diary for this crop will be ended. You can’t add/edit/delete any activity.",
  RestartCropModalOpen:"Are you sure?",
  RestartCropModalDescription:"Do you want to restart this crop?",
  DeleteCropModalTitle:"Delete Crop",
  DeleteCropModalDescription:"Are you sure you want to delete this Crop?",
}
exports.farmDairyShareLink = "https://co:farm/farmdairy/";


// Customizable Area End
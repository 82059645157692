// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { returnTruthyString, toLowerSnakeCase, isLanguageKey, getTitleMessage, validateAllUnit } from "../../../../components/src/helper";
import { ICreatedUnits, IDefaultUnits, IHeadings, IResponseDropdowns } from "../../../../components/src/interface.web";

export const configJSON = require("../config");
export interface IValue {
    [key: string]: { value: string | number, error: string },
}
interface IDefaultValue {
    id: string | number
    [key: string]: IValue | string | number,
}
const defaultValue = {
    id: "",
    english: {
        unit_name: { value: "", error: "" },
        formula: { value: "", error: "" },
        area_name: { value: "", error: "" },
    },
    gujarati: {
        unit_name: { value: "", error: "" },
        formula: { value: "", error: "" },
        area_name: { value: "", error: "" },
    },
    hindi: {
        unit_name: { value: "", error: "" },
        formula: { value: "", error: "" },
        area_name: { value: "", error: "" },
    },
}
interface IRequestBody {
    id?: string | number
    unit_name: string
    unit_name_hindi: string
    unit_name_gujarati: string
    formula: string | number,
    unit_id: string | number,
}
export interface ISubdropdown {
    heading: string
    count?: string
}
interface IDetailsResponse {
    "dropdown name": string,
    "data": ICreatedUnits[]
}
export interface Props {
    navigation: any;
    id: string;
    dropdown: {
        parentTitle: string,
        subDropdown: ISubdropdown
    } | null
}
interface S {
    authToken: string | null,
    selectedTab: string,
    selectedAccordianIndex: number | null,
    defaultAccordian: string | null,
    defaultForm: IDefaultValue,
    formArray: IDefaultValue[],
    // API CALLS
    defaultUnitsLoading: boolean,
    defaultUnitsData: IDefaultUnits[],
    headingsLoading: boolean,
    headingsData: IHeadings[],
    dropdownDetailsLoading: boolean,
    dropdownDetailsData: ICreatedUnits[],
    updateDropdownLoading: boolean,
    // CUSTOM MODAL
    OpenCustomModal: boolean,
    CustomModalTitle: string,
    CustomModalMsg: string,
}
interface SS { }
// Customizable Area End

export default class UnitsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDefaultUnitsApiCallId: string = ""
    getHeadingsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    updateDetailsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            selectedTab: configJSON.TAB.ENGLISH,
            // CUSTOM DROPDOWN STATES
            selectedAccordianIndex: null,
            defaultAccordian: null,
            // FORM
            defaultForm: { ...defaultValue },
            formArray: [],
            // API CALLS
            defaultUnitsLoading: false,
            defaultUnitsData: [],
            headingsLoading: false,
            headingsData: [],
            dropdownDetailsLoading: false,
            dropdownDetailsData: [],
            updateDropdownLoading: false,
            // CUSTOM MODAL
            OpenCustomModal: false,
            CustomModalTitle: "",
            CustomModalMsg: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.getDefaultUnitsApiCallId) {
                    this.GetDefaultUnitsResp(responseJson)
                }
                if (apiRequestCallId === this.getHeadingsApiCallId) {
                    this.GetHeadingsApiCallIdResp(responseJson)
                }
                if (apiRequestCallId === this.getDetailsApiCallId) {
                    this.GetDetailsApiCallIdResp(responseJson)
                }
                if (apiRequestCallId === this.updateDetailsApiCallId) {
                    this.UpdateDetailsApiCallIdResp(responseJson)
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetDefaultUnitsResp = (response: IDefaultUnits[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                defaultUnitsLoading: false,
                defaultUnitsData: response
            })
        } else {
            this.setState({
                defaultUnitsLoading: false,
                defaultUnitsData: []
            })
        }
    }
    GetHeadingsApiCallIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                headingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                headingsLoading: false,
                headingsData: []
            })
        }
    }
    GetDetailsApiCallIdResp = (responseJson: IDetailsResponse[] | null | undefined) => {
        if (responseJson && responseJson.length && responseJson[0]?.data?.length) {
            this.setState({
                dropdownDetailsData: responseJson[0]?.data,
                formArray: this.formateData(responseJson[0]?.data),
                dropdownDetailsLoading: false,
            })

        } else {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetailsData: []
            })
        }
    }
    UpdateDetailsApiCallIdResp = (responseJson: IResponseDropdowns[] | null | undefined) => {
        this.setState({ updateDropdownLoading: false })
        if (!Array.isArray(responseJson) || responseJson.length === 0) {
            this.setCustomModal(true, "Error", "Something went wrong")
            return
        }
        const hasError = responseJson.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasError) {
            const { title, message } = getTitleMessage(responseJson);
            this.setCustomModal(true, title, message)
            this.getDropdownDetails()
        } else {
            this.successUpdate(responseJson);
        }
    }
    successUpdate = (response: IResponseDropdowns[]) => {
        if (response && response.length) {
            this.setCustomModal(true, "Success", "Dropdown Saved Successfully.")
            this.getDropdownDetails()
        } else {
            this.setCustomModal(true, "Error", "Something went wrong!")
        }
    }

    async componentDidMount(): Promise<void> {
        this.setDefaultTab()
        this.getDefaultUnits()
        const searchParams = new URLSearchParams(window.location.search);
        const dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        // FETCH HEADING
        if (dropdown && subDropdown) {
            this.getDropdownHeadings(dropdown, subDropdown)
            this.getDropdownDetails()
        }
    }

    // API CALLS
    getDefaultUnits = () => {
        this.setState({
            defaultUnitsLoading: true,
            defaultUnitsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDefaultUnitsApiCallId = requestApiMsg.messageId;

        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.DefaultDropdownsEndpoint);
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
    }
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            headingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestApiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}`
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMsg.id, requestApiMsg);
    }
    getDropdownDetails = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetailsData: []
            })

            const header = { token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${subDropdown}`
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    updateDropdownDetails = (requestBody: ({ _destroy: string | number } | IRequestBody)[]) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown) {
            this.setState({
                updateDropdownLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${subDropdown}`
            const dropdown_type = toLowerSnakeCase(subDropdown)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    formateData = (response: ICreatedUnits[]) => {
        let formattedData = []
        formattedData = response.map((obj) => {
            return {
                id: obj.id,
                english: {
                    unit_name: { value: returnTruthyString(obj.name_english), error: "" },
                    formula: { value: returnTruthyString(obj.formula), error: "" },
                    area_name: { value: returnTruthyString(obj.unit_id), error: "" },
                },
                gujarati: {
                    unit_name: { value: returnTruthyString(obj.name_gujrati), error: "" },
                    formula: { value: returnTruthyString(obj.formula), error: "" },
                    area_name: { value: returnTruthyString(obj.unit_id), error: "" },
                },
                hindi: {
                    unit_name: { value: returnTruthyString(obj.name_hindi), error: "" },
                    formula: { value: returnTruthyString(obj.formula), error: "" },
                    area_name: { value: returnTruthyString(obj.unit_id), error: "" },
                },
            }
        })
        return formattedData
    }

    renderTitle = () => {
        let title = ""
        const searchParams = new URLSearchParams(window.location.search);
        const subDropdown = searchParams.get('sub-dropdown');
        if (subDropdown) {
            title = subDropdown
        }
        return title
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            selectedTab: newValue
        })
        this.closeAccordians()
    }
    setDefaultTab = (tabValue: string = configJSON.TAB.ENGLISH) => {
        this.setState({
            selectedTab: tabValue
        })
    }

    // CUSTOM UNIT DROPDOWN FUNCTIONS
    handleSelectAccordian = (index: number) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
        this.setState({ selectedAccordianIndex: newExpanded ? index : null, defaultAccordian: null })
    };
    handleDefaultAccordian = (status: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
        this.setState({ defaultAccordian: newExpanded ? status : null, selectedAccordianIndex: null })
    };
    closeAccordians = () => {
        this.setState({ selectedAccordianIndex: null, defaultAccordian: null })
    }

    // HANDLE ADD AND REMOVE
    handleAddInput = () => {
        this.closeAccordians()
        const newForm = JSON.parse(JSON.stringify(this.state.defaultForm))
        if (this.formValidated(newForm)) {
            let existingData = [...this.state.formArray]
            existingData.push(newForm);
            this.setState({ formArray: existingData, defaultForm: { ...defaultValue } });

            this.setDefaultTab()
        } else {
            this.setState({ defaultForm: newForm });
        }
    }
    handleRemoveInput = (form: IDefaultValue, index: number) => {
        this.closeAccordians()
        let existingData = [...this.state.formArray]
        if (index >= 0 && index < existingData.length) {
            const obj = existingData[index];
            if (obj.id) {
                obj["_destroy"] = form.id;
            } else {
                existingData.splice(index, 1);
            }
        }
        this.setState({ formArray: existingData });
    };

    // HANDLE CHANGE INPUT FOR FORMS
    handleChangeInput = (language: string, fieldIndex: number, fieldName: string, fieldValue: string,) => {
        switch (fieldName) {
            case "unit_name":
                this.setValue(language, fieldIndex, fieldName, fieldValue)
                break;
            // SET COMMON  VALUE FOR ALL LANGUAGE
            case "formula":
            case "area_name":
                this.setValue(configJSON.TAB.ENGLISH, fieldIndex, fieldName, fieldValue)
                this.setValue(configJSON.TAB.GUJARATI, fieldIndex, fieldName, fieldValue)
                this.setValue(configJSON.TAB.HINDI, fieldIndex, fieldName, fieldValue)
                break;
            default:
                return;
        }
    }

    setValue = (language: string, fieldIndex: number, fieldName: string, fieldValue: string,) => {
        this.setState(prevState => {
            const updatedFormArray = [...prevState.formArray];

            updatedFormArray[fieldIndex] = {
                ...updatedFormArray[fieldIndex],
                [language]: {
                    ...updatedFormArray[fieldIndex][language] as IValue,
                    [fieldName]: {
                        value: fieldValue,
                        error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty"
                    }
                }
            };
            return { formArray: updatedFormArray };
        });
    }

    // HANDLE CHANGE DEFAULT FORM
    handleChangeInputDefault = (fieldName: string, fieldValue: string | number, language: string) => {
        switch (fieldName) {
            case "unit_name":
                this.setDefaultValue(fieldName, fieldValue, language)
                break;
            // SET COMMON  VALUE FOR ALL LANGUAGE
            case "formula":
            case "area_name":
                this.setDefaultValue(fieldName, fieldValue, configJSON.TAB.ENGLISH)
                this.setDefaultValue(fieldName, fieldValue, configJSON.TAB.GUJARATI)
                this.setDefaultValue(fieldName, fieldValue, configJSON.TAB.HINDI)
                break;
            default:
                return;
        }
    }

    setDefaultValue = (fieldName: string, fieldValue: string | number, language: string) => {

        this.setState(prevState => ({
            defaultForm: {
                ...prevState.defaultForm,
                [language]: {
                    ...prevState.defaultForm[language] as IValue,
                    [fieldName]: {
                        value: fieldValue,
                        error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty"
                    }
                }
            }
        }));
    }

    // VALIDATE NEW FORM
    formValidated = (newForm: IDefaultValue) => {

        let isFormValidated = false

        let isErrorEnglish = false
        let isErrorGujarati = false
        let isErrorHindi = false

        for (const language in newForm) {
            if (isLanguageKey(language)) {
                const currentLanguage = newForm[language] as IValue;

                for (const field in currentLanguage) {
                    if (currentLanguage[field].value.toString().trim() === '') {
                        currentLanguage[field].error = "Field cannot be empty";
                        isFormValidated = false;
                    } else {
                        currentLanguage[field].error = "";
                    }
                }
            }
        }

        isErrorEnglish = Object.values(newForm[configJSON.TAB.ENGLISH]).filter((item) => !item.value).length > 0
        isErrorGujarati = Object.values(newForm[configJSON.TAB.GUJARATI]).filter((item) => !item.value).length > 0
        isErrorHindi = Object.values(newForm[configJSON.TAB.HINDI]).filter((item) => !item.value).length > 0

        if (this.changeLanguageTab(isErrorEnglish, isErrorGujarati, isErrorHindi)) {
            isFormValidated = false
        } else {
            isFormValidated = true
        }

        return isFormValidated
    }

    // VALIDATE ALL FORMS
    validateAllForms = () => {
        const allForms = [...this.state.formArray]
        const { isErrorEnglish,isErrorGujarati,isErrorHindi,allForms:UpdatedForms} = validateAllUnit(allForms)

        this.setState({
            formArray: UpdatedForms,
        })

        return !this.changeLanguageTab(isErrorEnglish, isErrorGujarati, isErrorHindi)
    }

    changeLanguageTab = (isErrorEnglish: boolean, isErrorGujarati: boolean, isErrorHindi: boolean) => {
        if (isErrorEnglish) {
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if (isErrorGujarati) {
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if (isErrorHindi) {
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
        return isErrorEnglish || isErrorGujarati || isErrorHindi
    }

    // HANDLE SUBMIT
    handleSubmit = () => {
        this.setState({ defaultForm: { ...defaultValue } })
        if (this.validateAllForms()) {
            const bodyData = this.returnBodyData()
            this.updateDropdownDetails(bodyData)
        }
    }
    returnBodyData = () => {
        const formData = [...this.state.formArray]
        let bodyData = []
        bodyData = formData.map((form) => {
            const objId = form?.id
            const formEnglish = form["english"] as IValue
            const formGujarati = form["gujarati"] as IValue
            const formHindi = form["hindi"] as IValue

            if (form?._destroy) {
                return { _destroy: objId };
            }

            return {
                ...(objId &&
                {
                    "id": objId
                }),
                unit_name: String(formEnglish?.unit_name?.value),
                // unit_name: String(formEnglish?.unit_name?.value),
                unit_name_gujarati: String(formGujarati?.unit_name?.value),
                unit_name_hindi: String(formHindi?.unit_name?.value),
                formula: Number(formEnglish?.formula?.value),
                unit_id: formEnglish?.area_name?.value,
            }
        })
        return bodyData
    }

    // HANDLE CUSTOM MODAL FUNCTIONS
    setCustomModal = (open: boolean, title: string, message: string) => {
        this.setState({
            OpenCustomModal: open,
            CustomModalTitle: title,
            CustomModalMsg: message,
        })
    }
    handleCloseCustomModal = () => {
        this.setState({
            OpenCustomModal: false,
            CustomModalTitle: "",
            CustomModalMsg: "",
        })
    }

    // Customizable Area End
}
import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Typography
} from "@material-ui/core";
import TermsAndConditionController, { Props, configJSON } from "./TermsAndConditionController.web";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { DataNotFound, HTMLView, LoaderCenter, themeUser } from "../../../components/src/AdminConsoleHelper";
import { ThemeProvider } from "@material-ui/core/styles";
import { returnTruthyString } from "../../../components/src/helper";

// Customizable Area End

export default class TermsAndCondition extends TermsAndConditionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { navigation, id } = this.props;
        const { TermsConditionsData, TermsConditionsLoading } = this.state;
        const description = returnTruthyString(TermsConditionsData?.description)
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <NavigationMenu navigation={navigation} id={id}>
                    <ThemeProvider theme={themeUser}>
                        <ScreenTitle>{configJSON.TITLE.TERMS_CONDITIONS}</ScreenTitle>
                        {TermsConditionsLoading ? (
                            <Wrapper>
                                <LoaderCenter />
                            </Wrapper>
                        ) : <></>}
                        {!TermsConditionsLoading && TermsConditionsData ? (
                            <ContentWrapper>
                                <HTMLView content={description} />
                            </ContentWrapper>
                        ) : <></>}
                        {!TermsConditionsLoading && !TermsConditionsData ? (
                            <Wrapper>
                                <DataNotFound />
                            </Wrapper>
                        ) : <></>}
                    </ThemeProvider>
                </NavigationMenu>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start   
const ScreenTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "48px",
    "@media (max-width: 420px)": {
        fontSize: "24px",
    }
})
const ContentWrapper = styled(Box)({
    marginTop: "24px",
    padding: "0 40px",
    width: "100%",
    maxWidth: "844px",
    "@media (max-width: 960px)": {
        padding: "0 20px !important",
    },
    "@media (max-width: 420px)": {
        padding: "0 !important",
    }
})
const Wrapper = styled(Box)({
    padding: "40px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
// Customizable Area End
import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Badge,
  Button,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Avatar,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  FormControl,
  FormLabel,
  Select, 
  TextField, 
  Link as LinkMui, 
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import Cf2enhancementsfromversion1 from "../../cf2enhancementsfromversion1/src/Cf2enhancementsfromversion1.web";
import PublishIcon from '@material-ui/icons/Publish';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "./AdminConsole.web.css";
import "./AdminConsoleFarmerUserScreen.web.css";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import CustomModal from "../../../components/src/customModal.web";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import AdminUserForm from "../../../components/src/AdminUserForm.web";
import FieldExecutiveForm from "../../../components/src/FieldExecutiveForm.web";
import FarmerUserForm from "../../../components/src/FarmerForm.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import AssignVillageFilter from "../../../components/src/AssignVillageFilter";
import ImageCropEditor from "../../../components/src/ImageCropper";
import Cfmultiplecropspart2 from "../../cfmultiplecropspart2/src/Cfmultiplecropspart2.web";
import moment from "moment";
import { Link } from "react-router-dom"
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import { Formik } from "formik";
import * as Yup from "yup";
import { IDocumentData } from "../../../components/src/interface.web";
import {
  linkSeparatorSVG,
  AddUserPlusIconSVG,
} from "./assets";

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "IBM Plex Sans",
  },
});

import AdminConsoleFarmerUserScreenController, {
  Props,
  configJSON,
} from "./AdminConsoleFarmerUserScreenController";
import UserFarmDiary from "../../AdminConsole2/src/UserFarmDiary.web";
import { SchemaAdminFormADD, SchemaFarmerForm, SchemaFieldExecutiveForm, getErrorMessage, getHelperText, getUserFullName, keyDown, keyDownInt, importValidationSchema, handleDownload } from "../../../components/src/helper";
import { getCustomErrorMessage, unVerifiedBadge} from "../../../components/src/AdminConsoleHelper";
import { UserBasedSurveys } from "../../../components/src/UserBasedSurvery.web";
import LinkShare from "../../LinkShare/src/LinkShare";
import DescriptionIcon from '@material-ui/icons/Description';
const { screenFarmerDefaultsTexts: screenDefaults } = configJSON;

export default class AdminConsoleFarmerUserScreen extends AdminConsoleFarmerUserScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }




  disaplyLeftBlock = () => {
    const feName = `${this.state.currentFarmerData.assigned_field_executive?.first_name || ''} 
    ${this.state.currentFarmerData.assigned_field_executive?.last_name || ''}`;
    return (
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        className="farmerViewRightSection"
      >
        <Grid item xs={12}>
          <Box
            className="leftBlock assignedFEBox"
            component="span"
            style={profileBlockStyles.labelSpan}
            mb={4}
          >
            <Box style={profileBlockStyles.profileLabelRight}>
              Assigned Field Executive&#58;
            </Box>
            <Tooltip title={feName}>
              <Typography noWrap style={profileBlockStyles.profileLabelValueRight}>
                {feName}
              </Typography>
            </Tooltip>
          </Box>
        </Grid>

       

        <Cfmultiplecropspart2 navigation={this.props.navigation} id={""}/>

        {!!this.shouldRenderUserBasedSurveys() ? (
          <Grid item xs={12}>
            <UserBasedSurveys data={this.state.userSurveyData} handleAccordionChange={this.handleSurveyAccordionChange} isExpanded={this.state.surveyExpanded} handleShareLink={this.handleShareLink} />
          </Grid>
        ) : null}
      </Grid>
    );
  };
  profileDetail = ({ label, value, formatter = (val: any) => val || '-' }: { label: string; value: string; formatter?: any }) => (
    <Box component="span" style={profileBlockStyles.labelSpan}>
      <Grid xs={6}>
        <Tooltip title={label}>
          <Typography style={profileBlockStyles.profileLabel} noWrap>
            {label}&#58;
          </Typography>
        </Tooltip>
      </Grid>
      <Grid xs={6}>
        <Tooltip title={formatter(value)}>
          <Typography noWrap style={profileBlockStyles.profileLabelValue}>
            {formatter(value)}
          </Typography>
        </Tooltip>
      </Grid>
    </Box>
  );


  assignFEPlaceholder = () => {
    return this.state.selectedFEformData ? "" : "Search by field executive name";
  };

  getAddUserModalTitleTxt = () => {
    return this.state.openEditUserModal === true ? "Edit User" : "Add New User";
  };

  getSubmitFormBtnTxt = () => {
    const updateBtnTxt = this.state.updateFarmerLoading ? "Updating..." : "Update User"
    const addBtnTxt = this.state.addFarmerLoading || this.state.addFELoading || this.state.addAdminLoading || this.state.addSALoading ? "Adding..." : "Add User"
    return (this.state.openEditUserModal === true ? updateBtnTxt : addBtnTxt)
  };

  getEyeIconConfirmPassword = () => {
    return this.state.showEyeIconConfirmPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  getshowEyeIcon = () => {
    return this.setState({
      showEyeIconPassword: !this.state.showEyeIconPassword,
    });
  };

  showEyeIconConfirm = () => {
    return this.setState({
      showEyeIconConfirmPassword: !this.state.showEyeIconConfirmPassword,
    });
  };

  villageNameAvatar = (index: number, option: any) => {
    return (
      <>
        <Avatar
          style={{
            backgroundColor: `${index % 2 === 0 ? "#108B88" : "#EB8F06"}`,
            color: "#fff",
            height: "28px",
            width: "28px",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "18px",
          }}
        >
          {option?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </>
    );
  };

  anchorCurrentTarget = (event: any) => {
    this.setState({
      anchorElVillageFilter: event.currentTarget,
    });
  };

  getEyeIconPassword = () => {
    return this.state.showEyeIconPassword ? (
      <Visibility style={webStyle.customDateIcon} />
    ) : (
      <VisibilityOff style={webStyle.customDateIcon} />
    );
  };

  handleSubmitAdmin = (values: any) => {
    if (this.state.seletedUserTypeAddUser === "SA") {
      this.addSuperAdmin(values);
    } else {
      this.addAdmin(values);
    }
  };

  handleSubmitFEUser = (values: any) => {
    this.addFieldExecutive(values);
  };

  showTotalCottonSold = () => {
    const { total_sold_cotton_till_now = 0 } = this.state.currentFarmerMeta;
    return total_sold_cotton_till_now;
  }

  showTotalExpense = () => {
    const { total_expense_till_now = 0 } = this.state.currentFarmerMeta;
    return total_expense_till_now;
  }

  shouldRenderUserBasedSurveys = () => {
    return this.state.userSurveyData && Array.isArray(this.state.userSurveyData) && this.state.userSurveyData.length;
  }

  shouldRenderUserFarmDiary = () => {
    return this.state.farmDiaryData && Array.isArray(this.state.farmDiaryData) && this.state.farmDiaryData.length;
  }
  adminAdharCard = () => {
    return this.state.currentFarmerData.aadhaar_number != null ? this.state.currentFarmerData.aadhaar_number : '-'
  }
  adminPhoneNumber = () => {
    return this.state.currentFarmerData.phone_number ? "+91-" + this.state.currentFarmerData.phone_number : ""
  }
  adminAvtar = () => {
    const name = getUserFullName(this.state.currentFarmerData.first_name, this.state.currentFarmerData.last_name)
    return (
      name || this.state.currentFarmerData.avatar?.url ?
        <Avatar style={profileBlockStyles.profileAvatar} src={this.state.currentFarmerData.avatar?.url}>
          {name[0]}
        </Avatar> : <Avatar style={profileBlockStyles.profileAvatar} />
    )
  }

  checkNoDataFlow = () => {
    return this.state.errorModalMsgDoc === "No documents!" 
    ?  <Typography className="noDocWrapper">No document available!</Typography> 
    : <Box className="loaderWrapper"><CircularProgress size={28}/></Box>
  }

  farmerProfileSection = () => this.state.farmerProfileImage?.icon && <ImageCropEditor open={this.state.cropImageModal} src={this.state.farmerProfileImage.icon} onClose={this.handleCloseCropModalFarmer} title="Farmer profile" buttonText="Submit" handleUploadImage={this.handleSubmitFarmerProgfileImage} />

  renderDocumentBox = () => {
    return <DocumentBox style={{ ...profileBlockStyles.gridContainer, width: "100%", borderTop: "2px solid #F0EBE0", paddingTop: "30px", marginTop: "30px", maxHeight: "800px", overflowY: "scroll" }}>
      <Box className="titleWrapper">
        <Typography className="title"><DescriptionIcon />Documents</Typography>
        <Button className="addBtn" data-test-id="addDoc" onClick={() => { this.setState({ openDocumentModal: true }) }}> <AddIcon style={{color: "#006A77", paddingRight: "5px"}}/> Add Doc</Button>
      </Box>
      {this.state.farmerDocumentList.length ? this.state.farmerDocumentList.map((item: IDocumentData) => {
        return <Box className="downloadDocWrapper">
          <Box>
          <Tooltip title={item.attributes.document_name} placement="bottom-start">
            <Typography className="cardTitle">{item.attributes.document_name}</Typography></Tooltip>
            <Typography className="cardDate">{item.attributes.document.date}</Typography>
          </Box>
          <a onClick={() => handleDownload(item.attributes.document.url)} className="download" data-test-id="download">
            Download <GetAppIcon />
          </a>
        </Box>
      }): this.checkNoDataFlow()}
    </DocumentBox>
  }

  renderAddDocModal = () => {
    return <AddDocumentDialog
      open={this.state.openDocumentModal}
      aria-labelledby="customized-dialog-title"
      maxWidth={"md"}
      PaperProps={{ style: { borderRadius: 12, width: "528px" }}}
    >
      <div style={{ position: "relative" }}>
        <DialogTitle style={{ padding: "45px 32px 10px", textAlign: "center" }}>
          <Typography variant="subtitle1">Add document</Typography>
        </DialogTitle>
        <div style={{ position: "absolute", right: "15px", top: "15px" }}>
          <IconButton
            data-testid="closeDocumentModal"
            aria-label="close"
            onClick={() => this.handleCloseDocumentModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <DialogContent dividers className="modalBodyWrapper">
        <Formik
          initialValues={{ name: "", document: null, }}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().trim().required("Document name is required"),
              document: Yup.mixed().required("Document is required").test("fileSize", "Document is not of valid size",
              (file) => file?.size <= 250 * 1024 * 1024
            )})
          }
          onSubmit={(values) => {this.setState({ loader: true},() => this.uploadFarmerDocument(values)) }}
          validateOnChange={true}
          validateOnBlur={true}
          data-testid="formikForm"
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid className="label-parent" container spacing={3}>
                <CustomFieldGrid item xs={12} lg={12}>
                  <CustomFormLabel>Document name</CustomFormLabel>
                  <FormControl>
                    <TextField
                      name="name"
                      id="name"
                      fullWidth
                      value={props.values.name}
                      onBlur={(e) => {props.handleBlur(e)}}
                      onChange={props.handleChange}
                      className="inputIconTextFieldCustom custom-textbox"
                      variant="outlined"
                      color="secondary"
                      placeholder="Enter document name"
                      data-testid="document_name_testid"
                      error={!!(props.errors.name && props.touched.name)}
                    />
                  </FormControl>
                  {props.errors.name && props.touched.name ? (
                    <p className="validation_errors">{props.errors.name}</p>
                  ) : null}
                </CustomFieldGrid>

                <CustomFieldGrid item xs={12} lg={12}>
                  <CustomFormLabel>Choose document</CustomFormLabel>
                  <FormControl>
                    <label className="documentPicker" htmlFor="document" style={{ border: (props.touched.document && props.errors.document) ? "1px solid red" : "1px solid #F0EBE0" }}>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,application/pdf,application/msword"
                        hidden
                        id="document"
                        data-testid="filePicker"
                        style={{ display: "none" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                          this.handleChange(event, props) 
                        }}
                      />
                      <Typography className="documentPickerText">{this.state.document?.name ? this.state.document?.name : "Choose document file (image/pdf)"}</Typography>
                      <DescriptionIcon className="documentPickerText" />
                    </label>
                    <Box mt="3px" fontSize="14px">
                      {this.state.document && (
                        <LinkMui target="_blank" href={URL.createObjectURL(this.state.document)} className="viewFile" style={{ cursor: "pointer" }}>
                          View File
                        </LinkMui>
                      )}
                    </Box>
                  </FormControl>
                  {props.touched.document && props.errors.document && (<p className="validation_errors"> {props.errors.document}</p>)}
                </CustomFieldGrid>

                <Grid item xs={12} alignItems="center">
                  <Box style={profileBlockStyles.modalButtonContainer} data-test-id={"profile-container"}>
                    <Button style={profileBlockStyles.buttonModal} data-testid={"importDoc"} type="submit" disabled={this.state.loader}>
                      <Typography style={profileBlockStyles.buttonFunctionTxt}>{this.state.loader ? (
                        <CircularProgress size={28} style={{ color: "#fff" }} />
                      ) : (
                        "IMPORT"
                      )} </Typography>
                    </Button>
                    <Button
                      component="span"
                      onClick={() => this.handleCloseDocumentModal()}
                      style={{ ...profileBlockStyles.buttonModal, backgroundColor: "#E4DDC9"}}
                      data-testid={"cancelBtn"}
                    >
                      <Typography style={{
                        fontFamily: screenDefaults.fontFamily,
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "24px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#1C2324",
                        opacity: "0.72"
                      }}>
                        CANCEL
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </AddDocumentDialog>
  }

  renderAddDocument = () => {
    return (
      <AddDocumentDialog
        aria-labelledby="customized-dialog-title"
        maxWidth={"md"}
        PaperProps={{ style: { borderRadius: 12, width: "528px" }}}
        open={this.state.openImportModal}
      >
      <div style={{ position: "relative"}}>
        <DialogTitle style={{ padding: "50px 32px 10px", textAlign: "center" }}><Typography variant="subtitle1">Import file!</Typography>
        </DialogTitle>
        <div style={{ position: "absolute", right: "20px", top: "20px" }}>
          <IconButton
            aria-label="close"
            data-testid="modalCloseBtnTestid"
            onClick={() => this.handleCloseDocumentModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <DialogContent dividers className="modalBodyWrapper">
      <Formik
        initialValues={{ document: null, }}
        validationSchema={importValidationSchema(this.state.bulkUpload)}
        onSubmit={(values) => {
          if (this.state.bulkUpload === "add") {
            this.handleImportFile(values)
          } else { this.handleImportFileForUpdate(values) }
        }}
        validateOnBlur={true}
        validateOnChange={true}
        data-testid="formikForm"
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Grid className="label-parent" container spacing={3}>
              <CustomFieldGrid item  lg={12} xs={12}>
                <CustomFormLabel>Choose file</CustomFormLabel>
                <FormControl>
                  <label htmlFor="document" className="documentPicker" style={{ border: (props.touched.document && props.errors.document) ? "1px solid red" : "1px solid #F0EBE0" }}>
                    <input
                      hidden
                      type="file"
                      id="document"
                      accept={this.state.bulkUpload === "update" ? ".xlsx" : ".csv"}
                      data-testid="filePicker"
                      style={{ display: "none" }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.handleChange(event, props) }}
                    />
                    <Typography className="documentPickerText">{this.state.document?.name ? this.state.document?.name : "Choose file to import"}</Typography>
                    <PublishIcon className="documentPickerText" />
                  </label>
                </FormControl>
                {props.touched.document && props.errors.document && (
                  <p className="validation_errors"> {props.errors.document}</p>
                )}
              </CustomFieldGrid>
            <Grid item xs={12} alignItems="center">
            <Box style={webStyle.functionButtonContainer} data-test-id={"profile-container"}>
              <Button
                data-testid={"importDoc"}
                style={webStyle.buttonFunction}
                type="submit"
              >
                {this.state.loader ? <CircularProgress size={23} style={{ color: "#fff" }} /> : <Typography style={webStyle.buttonFunctionTxt}>IMPORT</Typography>}
              </Button>
              <Button
                component="span"
                data-testid={"cancelBtn"}
                onClick={() => this.handleCloseDocumentModal()}
                style={{ ...webStyle.buttonFunction, backgroundColor: "#E4DDC9"}}
              >
                <Typography style={{...webStyle.buttonFunctionTxt,  color: "#1C2324", opacity: "0.72"}}>
                  CANCEL
                </Typography>
              </Button>
            </Box>
          </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </DialogContent>
    </AddDocumentDialog>
    )
  }

  renderTopBox = () => {
    return <TopBox>
      <Button
        disableElevation
        variant="contained"
        className="updateBtn"
        aria-haspopup="true"
        data-testid="updateStatus"
        onClick={this.handleUpdateClick}
        endIcon={!this.state.openMenu ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      >
        Update status
      </Button>
      <MenuStyled
        style={{ display: this.state.openMenu ? "block" : "none" }}
        className="menuCustom"
      >
        <MenuItem onClick={() => this.openImportModal("update")} className="menuItem" data-testid="import">Import</MenuItem>
        <MenuItem onClick={() => this.handleTemplateFileForUpdate()} className="menuItem" data-testid="template">Template</MenuItem>
      </MenuStyled>

      <Box style={{ display: "flex" }}>
        <Button
          variant="contained"
          className="addUserBtn"
          data-testid="addUserModalBtn"
          onClick={() => this.showUserModal()}
          style={webStyle.addUserModalBtnStyle}
        >
          Add User
        </Button>
        <Button
          disableElevation
          variant="contained"
          className="addUsers"
          data-testid="addUsersBtn"
          onClick={this.handleAddUserMenu}
          endIcon={!this.state.openMenu2 ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        >
        </Button>
        <MenuStyled
          className="menuCustom2"
          style={{ display: this.state.openMenu2 ? "block" : "none" }}
        >
          <MenuItem className="menuItem" onClick={() => this.openImportModal("add")} data-testid="importAdd">Import</MenuItem>
          <MenuItem className="menuItem" onClick={() => this.handleExportFile()}  data-testid="export">Export</MenuItem>
          <MenuItem className="menuItem" onClick={() => this.handleTemplateFile()} data-testid="templateAdd">Template</MenuItem>
        </MenuStyled>
      </Box>
    </TopBox>
  }

  render() {
    // Customizable Area Start
    const { navigation, id } = this.props;
    const { currentFarmerData } = this.state;
    const ProfileDetail = this.profileDetail;    
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={theme}>
          <Container className="mainConatiner" maxWidth={"xl"}>
            {this.farmerProfileSection()}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Box display="flex" gridGap="10px" alignItems="center">
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "48px",
                      color: "#1C2324",
                    }}
                  >
                    Users
                  </Typography>
                </Box>
              </Box>
              {this.renderTopBox()}
            </Box>

            <Box className="breadcrumbMain" mb={4}>
              <Breadcrumbs
                separator={<img src={linkSeparatorSVG} alt={"separator"} />}
                aria-label="breadcrumb"
              >
                <Link style={{ textDecoration: "none"}} className="menuLinkUser" to="/users" >
                  Users
                </Link>
                <Typography className="menuTextUser">View Profile</Typography>
              </Breadcrumbs>
            </Box>

            <Grid
              container
              direction="row"
              xs={12}
            >
              <Grid item lg={6} md={6} sm={12} xs={12} style={adminViewStyle.maincard} className="farmerViewLeftSection">
                <Grid
                  container
                  xs={12}
                  style={profileBlockStyles.profileContainer}
                >
                  <Grid item xs={12} style={{...profileBlockStyles.gridContainer, paddingTop: "16px"}}>
                    <Box
                      style={profileBlockStyles.profileAvatarContainer}
                    >
                      <input type="file" id="upload" data-test-id="uploadProfileButton" onChange={(event) => event.currentTarget?.files?.length && this.onProfileImageUpload(event)} accept="image/*" style={{ display: 'none' }} />
                      <Box>
                        <label htmlFor="upload" style={{ display: 'flex', alignItems: 'center' }}>
                          <StyledBadge
                            overlap="rectangular"
                            badgeContent={
                              <PhotoCamera
                                fontSize="medium"
                                style={profileBlockStyles.profileIconButton}
                              />
                            }
                            data-testid="badge-content"
                          >
                            <IconButton
                              disableRipple={true}
                              aria-label="camera-edit_icon"
                              style={profileBlockStyles.avatarContainer}
                            >
                              {this.adminAvtar()}
                            </IconButton>
                          </StyledBadge>
                        </label>
                      </Box>
                      <Box className="profileName">
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Tooltip
                          title={getUserFullName(currentFarmerData.first_name,currentFarmerData.last_name)}
                        >
                          <Typography
                            noWrap
                            data-testid="profile-name"
                            style={
                              profileBlockStyles.avatarTypographyTitle
                            }
                          >{getUserFullName(currentFarmerData.first_name,currentFarmerData.last_name)}</Typography>
                        </Tooltip>
                        <span>{unVerifiedBadge(!currentFarmerData.verified)}</span>
                        </Box>                      
                        <Typography
                          noWrap
                          style={
                            profileBlockStyles.avatarTypographyContact
                          }
                        >
                          {this.adminPhoneNumber()}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} style={profileBlockStyles.gridContainer}>
                    <Typography
                      noWrap
                      style={profileBlockStyles.profileSubTitle}
                    >
                      Personal Details
                    </Typography>
                  </Grid>
                  
                  <Grid container xs={12} style={profileBlockStyles.gridContainer}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <ProfileDetail label={screenDefaults.profileAadhar} value={this.adminAdharCard()} />
                    </Grid>
                  </Grid>

                  <Grid container xs={12} style={{...profileBlockStyles.gridContainer, paddingBottom: "15px" }}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Box
                        style={profileBlockStyles.profileLabelContainer}
                      >
                        <ProfileDetail label={screenDefaults.profileGender} value={currentFarmerData.gender} />
                        <ProfileDetail label={screenDefaults.profileEducation} value={currentFarmerData.highest_education_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileMobileType} value={currentFarmerData.mobile_type_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileState} value={currentFarmerData.state_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileDistrict} value={currentFarmerData.district_id?.[0].name} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Box
                        style={profileBlockStyles.profileLabelContainer}
                      >
                        <ProfileDetail label={screenDefaults.profileTextDOB} value={currentFarmerData.date_of_birth} formatter={(val: any) => val ? moment(val).format("DD MMMM YYYY") : ''} />
                        <ProfileDetail label={screenDefaults.profileFamily} value={currentFarmerData.total_family_members} />
                        <ProfileDetail label={screenDefaults.profileBelongsTo} value={currentFarmerData.number_belongs_to_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileTaluka} value={currentFarmerData.taluka_id?.[0].name} />
                        <ProfileDetail label={screenDefaults.profileVillage} value={currentFarmerData.village_id?.[0].name} />
                      </Box>
                    </Grid>
                  </Grid>

                  
                    <Grid container xs={12}>                      
                      {/* Edit/Delete */}
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                      >
                        <Box
                          style={profileBlockStyles.functionButtonContainer}
                          data-test-id={"profile-container"}
                        >
                          <Button
                            component="span"
                            aria-label="edit-profile"
                            style={profileBlockStyles.buttonFunction}
                            data-testid={"edit-profile"}
                            onClick={() => {
                              this.handleFarmerEditClick({ attributes: this.state.currentFarmerData, meta: this.state.currentFarmerMeta });
                            }}
                          >
                            <Typography
                              style={profileBlockStyles.buttonFunctionTxt}
                            >
                              {screenDefaults.buttonText1}
                            </Typography>
                          </Button>

                          <Button
                            component="span"
                            aria-label="delete-profile"
                            style={{
                              ...profileBlockStyles.buttonFunction,
                              ...profileBlockStyles.buttonDeleteFunction,
                            }}
                            data-testid={"delete-profile"}
                            onClick={() => {
                              this.handleFarmerDelete();
                            }}
                          >
                            <Typography
                              style={profileBlockStyles.buttonFunctionTxt}
                            >
                              {screenDefaults.buttonText2}
                            </Typography>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid> 
                  <Grid container xs={12}>
                    {this.renderDocumentBox()}
                  </Grid>                 
                </Grid>    

              </Grid>
              {this.disaplyLeftBlock()}
            </Grid>

          </Container>
          {this.renderAddDocModal()}
          <Dialog
            aria-labelledby="customized-dialog-title"
            maxWidth={"md"}
            open={this.state.openExportModal}
            onClose={this.closeExportModal}
            data-testid="closeExportModal"
            PaperProps={{ style: { borderRadius: 12, width: "528px" } }}
          >
            <DialogContent dividers className="modalBodyWrapper">
              <Cf2enhancementsfromversion1
                id={""}
                navigation={undefined}
                fileName={this.state.csvName}
                title={"Export file!"}
              />
            </DialogContent>
          </Dialog>
          {this.renderAddDocument()}
          <Dialog
            aria-labelledby="customized-dialog-title"
            maxWidth={"md"}
            PaperProps={{ style: { borderRadius: 12, width: "528px" } }}
            open={this.state.openTemplateModal}
            onClose={this.closeTemplateModal}
            data-testid="closeTemplate"
          >
            <DialogContent dividers className="modalBodyWrapper">
              <Cf2enhancementsfromversion1
                id={""}
                navigation={undefined}
                fileName={this.state.csvName}
                title={"CSV template"}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            aria-labelledby="customized-dialog-title"
            maxWidth={"md"}
            open={this.state.backgroundProcess}
            onClose={this.closeBackgroundModal}
            PaperProps={{ style: { borderRadius: 12, width: "528px" } }}
            data-testid="closeBackground"
          >
            <DialogContent dividers className="modalBodyWrapper">
              <Cf2enhancementsfromversion1
                id={""}
                navigation={undefined}
                fileName={this.state.document?.name ?? ""}
                title={"Import file!"}
                modalName={"importing"}
              />
            </DialogContent>
          </Dialog>
          {/* ADD-EDIT MODAL START */}
          <Dialog
            open={this.state.userModal}
            aria-labelledby="customized-dialog-title"
            PaperProps={{
              style: { borderRadius: 12, width: "712px" },
            }}
            maxWidth={"md"}
          >
            <div
              style={{
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <DialogTitle style={{padding:"32px 32px 19px 32px"}}>
                  <Typography variant="subtitle1">
                    {this.getAddUserModalTitleTxt()}
                  </Typography>
                </DialogTitle>
              </div>
              <div style={{ marginRight: 20 }}>
                <IconButton
                  data-testid="modalCloseBtnTestid"
                  aria-label="close"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <DialogContent dividers className="modalBodyWrapper">
              <Box className="userFormBodyWrapper">
                <Grid
                  container
                  justifyContent="center"
                  direction="row"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formfieldWrapper"
                  >
                    <FormLabel className="customLabelField">
                      User Type
                    </FormLabel>
                    <FormControl fullWidth className="userTypeFormControl">
                      <Select
                        className="farmerFormDDClass"
                        value={this.state.seletedUserTypeAddUser}
                        data-testid="createUserModalDropdown"
                        color="secondary"
                        onChange={(e: any) =>
                          this.updateSeletedUserTypeAddUser(e.target.value)
                        }
                        displayEmpty
                        variant="outlined"
                        disabled={this.editModalStatus()}
                        inputProps={{ "aria-label": "Without label" }}
                        readOnly={this.editModalStatus()}
                      >
                        <MenuItem value={"Farmers"}>Farmers</MenuItem>
                        <MenuItem value={"FieldExecutive"}>
                          Field Executive
                        </MenuItem>
                        <MenuItem value={"Admin"}>Admin</MenuItem>
                        {!this.state.isLoggedUserAdmin ? <MenuItem value={"SA"}>Super Admin</MenuItem> : (<></>)}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {this.state.seletedUserTypeAddUser === "SA" ||
                  this.state.seletedUserTypeAddUser === "Admin" ? (
                  <AdminUserForm
                    validationSchemaAdminobj={SchemaAdminFormADD}
                    adminInitialValues={this.state.adminInitialValues}
                    handleSubmitAdmin={this.handleSubmitAdmin}
                    getErrorMessage={getErrorMessage}
                    getCustomErrorMessage={getCustomErrorMessage}
                    getPasswordType={this.getPasswordType}
                    getHelperText={getHelperText}
                    getEyeIconPassword={this.getEyeIconPassword}
                    getEyeIconConfirmPassword={this.getEyeIconConfirmPassword}
                    getConfirmPasswordType={this.getConfirmPasswordType}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    showEyeIconConfirm={this.showEyeIconConfirm}
                    getshowEyeIcon={this.getshowEyeIcon}
                    disableSubmitBtn={this.state.addAdminLoading || this.state.addSALoading}
                  />
                ) : (
                  <></>
                )}


                {this.state.seletedUserTypeAddUser === "FieldExecutive" ? (
                  <FieldExecutiveForm
                    feInitialValues={this.state.feInitialValues}
                    handleSubmitFEUser={this.handleSubmitFEUser}
                    validationSchemaFEobj={SchemaFieldExecutiveForm}
                    getErrorMessage={getErrorMessage}
                    getCustomErrorMessage={getCustomErrorMessage}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    getHelperText={getHelperText}
                    getMobileDeviceModelFEData={
                      this.state.getMobileDeviceModelFEData
                    }
                    showTableFilterModal={this.showVillageFilterModal}
                    anchorCurrentTarget={this.anchorCurrentTarget}
                    getLocationsListFEData={this.state.getLocationsListFEData}
                    selectedLocationArr={this.state.selectedLocationArr}
                    assignVillagePlaceholder={this.assignVillagePlaceholder}
                    villageNameAvatar={this.villageNameAvatar}
                    onFeLocationsChange={this.onFeLocationsChange}
                    handleVillageDelete={this.handleVillageDelete}
                    disableSubmitBtn={this.state.addFELoading}
                  />
                ) : (
                  <></>
                )}

                {this.state.seletedUserTypeAddUser === "Farmers" ? (
                  <FarmerUserForm
                    data-testid='addUserFarmerFormViewFarmer'                    
                    farmerInitialValues={this.state.farmerInitialValues}
                    handleSubmitFarmer={this.handleSubmitFarmer}
                    validationSchemaFarmerobj={SchemaFarmerForm}
                    getErrorMessage={getErrorMessage}
                    getCustomErrorMessage={getCustomErrorMessage}
                    getHelperText={getHelperText}
                    keyDownInt={keyDownInt}
                    getSubmitFormBtnTxt={this.getSubmitFormBtnTxt}
                    keyDown={keyDown}
                    getEducationFarmerData={this.state.getEducationFarmerData}
                    getNumberBelongsToData={this.state.getNumberBelongsToMenus}
                    getMobileDeviceModelFEData={this.state.getMobileDeviceModelFEData}
                    fetchDistrict={this.fetchDistrict}
                    disableStateFarmer={this.state.disableStateFarmer}
                    getStateData={this.state.getStateData}
                    getDistrictFarmerValue={this.getDistrictFarmerValue}
                    fetchTaluka={this.fetchTaluka}
                    getDistrictData={this.state.getDistrictData}
                    disableDistrictFarmerValue={this.disableDistrictFarmerValue}
                    getTalukaFarmerValue={this.getTalukaFarmerValue}
                    fetchVillage={this.fetchVillage}
                    disableTalukaFarmerValue={this.disableTalukaFarmerValue}
                    getTalukaData={this.state.getTalukaData}
                    disableVillageFarmerValue={this.disableVillageFarmerValue}
                    getVillageFarmerValue={this.getVillageFarmerValue}
                    getVillageData={this.state.getVillageData}
                    FElistData={this.state.FElistData}
                    selectedFEformData={this.state.selectedFEformData}
                    handleAssignFEchange={this.handleAssignFEchange}
                    assignFEPlaceholder={this.assignFEPlaceholder}
                    disableSubmitBtn={this.state.addFarmerLoading || this.state.updateFarmerLoading}
                  />
                ) : (
                  <></>
                )}
                {/* FILTER-ASSIGN VILLAGE STARTS */}
                <AssignVillageFilter
                  data-testid={'assignVillageFilterViewFE'}
                  anchorElVillageFilter={this.state.anchorElVillageFilter}
                  isVillageFilterOpen={this.state.openVillageFilter}
                  isAccordianError={this.state.isAccordianErrorVF}
                  isDisabled={this.state.isDisabledVF}
                  expanded={this.state.expandedVF}
                  handleVillageFilterClear={() => this.handleVillageFilterClearVF()}
                  closeVillageFilter={() => this.closeVillageFilterVF()}
                  handleSubmitAccordion={() => this.handleSubmitAccordionVF()}
                  handleChangePanelTaluka={this.handleChangePanelVF("panel3")}
                  handleChangePanelVillage={this.handleChangePanelVF("panel4")}
                  handleChangePanelState={this.handleChangePanelVF("panel1")}
                  handleChangePanelDistrict={this.handleChangePanelVF("panel2")}
                  opacityStateCalculator={this.opacityStateCalculatorViewForm}
                  handleChangeStateField={this.handleChangeStateFieldVF}
                  opacityDistrictCalculator={this.opacityDistrictCalculatorViewForm}
                  handleChangeDistrictField={this.handleChangeDistrictFieldVF}
                  opacityTalukaCalculator={this.opacityTalukaCalculatorViewForm}
                  handleChangeTalukaField={this.handleChangeTalukaFieldVF}
                  opacityVillageCalculator={this.opacityVillageCalculatorViewForm}
                  selectedState={this.state.selectedStateVF}
                  handleChangeVillageField={this.handleChangeVillageFieldVF}
                  handleStateSearch={this.handleStateSearchVF}
                  stateInputValue={this.state.stateInputValueVF}
                  selectedDistrict={this.state.selectedDistrictVF}
                  filteredStates={this.state.filteredStatesVF}
                  handleDistrictSearch={this.handleDistrictSearchVF}
                  districtInputValue={this.state.districtInputValueVF}
                  selectedTaluka={this.state.selectedTalukaVF}
                  talukaInputValue={this.state.talukaInputValueVF}
                  filteredDistricts={this.state.filteredDistrictsVF}
                  filteredTalukas={this.state.filteredTalukasVF}
                  handleTalukaSearch={this.handleTalukaSearchVF}
                  villageInputValue={this.state.villageInputValueVF}
                  selectedVillage={this.state.selectedVillageVF}
                  filteredVillages={this.state.filteredVillagesVF}
                  handleVillageSearch={this.handleVillageSearchVF}
                  getDistrictsVF={this.getDistrictsVF}
                  getTalukasVF={this.getTalukasVF}
                  getVillagesVF={this.getVillagesVF}
                />
                {/* FILTER-ASSIGN VILLAGE ENDS */}
              </Box>
            </DialogContent>
          </Dialog>
          {/* ADD EDIT MODAL END */}
          {/* CROP-MAPPING MODAL STARTS */}          
          {/* DELETE-ALERT-BOX FOR FE START */}
          <CustomModal
            open={this.state.openDeleteFarmerModal}
            title={"Delete User"}
            description={" Are you sure? You want to delete this User?"}
            confirmHandler={this.deleteFarmerUser}
            confirmTitle={this.state.deleteFarmerLoading ? "Deleting..." : "Delete"}
            onClose={this.handleClose}
            disableConfirmHandler={this.state.deleteFarmerLoading}
          />
          {/* DELETE ALERT BOX END */}
          {/* ERROR-MODAL STARTS */}
          <ErrorModal
            open={this.state.errorModalOpen}
            errorTitle="Error"
            errorSubTitle={this.state.errorModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleErrorModalClose}
          />
          {/* ERROR-MODAL ENDS */}
          {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}


          {/* SUCCESS-MODAL STARTS */}
          <ErrorModal
            open={this.state.successModalOpen}
            errorTitle="Success"
            errorSubTitle={this.state.successModalMsg}
            BtnTitle="Okay"
            handleBtnClick={this.handleSuccessModalClose}
          />
          {/* SUCCESS-MODAL ENDS */}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

export const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "11%",
    top: "80%",
    width: "2.5rem",
    height: "2.35rem",
    padding: ".8rem",
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    color: "#EB8F06",
  },
}));
export const AddDocumentDialog = styled(Dialog)(() => ({
  "& .modalBodyWrapper": {
    padding: "16px 35px 20px !important"
  }, 
  "& .custom-textbox": {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "16px !important"
    },
  },
  "& .documentPicker": {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    cursor: "pointer",
    padding: "15px",
    display: "flex",
    justifyContent: "space-between",
    "& .documentPickerText": {
      color: "#1C2324",
      opacity: "0.42"
    }
  }
}))
export const DocumentBox = styled(Box)(() => ({
  "& .titleWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", 
    textTransform: "uppercase", 
    color: "#006A77"
  },
  "& .title": {
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: 500,
    color: "#1C2324",
    "& svg": {
      verticalAlign: "sub", 
      marginRight: "5px"
    }
  },
  "& .addBtn": {
    textTransform: "uppercase",
    fontSize: "15px",
    fontWeight: 500,
    color: "#006A77"
  },
  "& .noDocWrapper": {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    padding: "60px 16px", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center", 
    margin: "10px 0px",
  },
  "& .loaderWrapper": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center", 
    height: "200px"
  },
  "& .downloadDocWrapper": {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    padding: "12px 16px", 
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", 
    margin: "10px 0px",
    "& .cardTitle": {
      color: "#1C2324",
      fontSize: "16px",
      fontWeight: 500, 
      whiteSpace: 'nowrap', width: '375px', overflow: 'hidden', textOverflow: 'ellipsis',
    }, 
    "& .cardDate": {
      color: "#1C2324",
      fontSize: "12px",
      fontWeight: 400
    },
    "& .download": {
      background: "#DF5200", 
      color: "#fff",
      fontSize: "11px",
      borderRadius: "24px",
      textTransform: "uppercase", 
      padding: "5px 10px",
      textDecoration: "none",
      fontFamily: "IBM Plex Sans",
      cursor: "pointer",
      minWidth: "100px",
      "& svg": {
        fontSize: "12px",
        marginLeft: "5px", 
        verticalAlign: "middle"
      }
    }
  },
}));

const CustomFormLabel = styled(FormLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#1C2324",
  opacity: 0.64,
  marginBottom: "8px",
});

const CustomFieldGrid = styled(Grid)({
  display: "flex !important",
  flexDirection: "column",
  minHeight: "93px",
  position: "relative",
  paddingBottom:"18px !important",
  paddingTop: "0px !important",
  "& .validation_errors": {
    position: "absolute",
    bottom: "0px", 
    color: "red",
    margin: "0px",
    fontSize: "12px"
  }, 
  "& .viewFile": {
    fontFamily: "IBM Plex Sans",
    color: "#DF5200", 
    fontSize: "12px", 
    marginLeft: "5px"
  }

});

// Customizable Area Start
const profileBlockStyles = {
  profileContainer: {
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    width: "100%",
    marginBottom: "12px",
  } as React.CSSProperties,

  gridContainer: {
    padding: "0 16px"
  } as React.CSSProperties,

  profileContainerSecond: {
    width: "100%",
    borderTop: "2px solid #F0EBE0",
    paddingTop: "20px",
  } as React.CSSProperties,

  profileSubTitle: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "uppercase",
    color: "#1C2324",
    opacity: 0.64,
    marginTop: "16px",
    marginBottom: "14px"
  } as React.CSSProperties,

  avatarContainer: {
    padding: "0.5rem",
    width: "10rem",
    objectFit: "cover",
    height: "9.5rem",
    marginBottom: "8px",
  } as React.CSSProperties,

  profileAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    fontSize: "72px",
    marginTop: "24px",
  } as React.CSSProperties,

  profileIconButton: {
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
  } as React.CSSProperties,

  profileAvatar: {
    width: "10rem",
    height: "10rem",
    backgroundColor: "#108B88",
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "72px",
    lineHeight: "86px",
    textTransform: "uppercase",
    color: "#FFFFFF",
  } as React.CSSProperties,

  avatarTypographyTitle: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    maxWidth: "100%",
    lineHeight: "36px",
    color: "#1C2324",
    wordWrap: "break-word",
    textAlign: "center",
  } as React.CSSProperties,

  avatarTypographyContact: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
  } as React.CSSProperties,

  profileLabelContainerAdhar: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    marginTop: "14px",
  } as React.CSSProperties,

  profileLabelContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    marginTop: "16px",
  } as React.CSSProperties,

  profileLabel: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,

  profileLabelRight: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,

  labelSpan: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  } as React.CSSProperties,

  profileLabelValue: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    textTransform: "capitalize",
  } as React.CSSProperties,

  profileLabelValueRight: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1C2324",
  } as React.CSSProperties,

  customFunctionContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  } as React.CSSProperties,

  functionButtonContainer: {
    display: "flex",
    gap: "1.5rem",
    marginTop: "15px",
    marginBottom: "18px",
    justifyContent: "center",
  } as React.CSSProperties,

  modalButtonContainer: {
    display: "flex",
    gap: "1.5rem",
    marginTop: "0px",
    marginBottom: "18px",
    justifyContent: "center",
  } as React.CSSProperties,

  buttonModal: {
    backgroundColor: "#DF5200",
    borderRadius: "1.85rem",
    paddingBlock: ".3rem",
    paddingInline: "2rem",
    padding: "5px 40px",
    height: "48px",
    width: "142px"
  } as React.CSSProperties,

  buttonFunction: {
    backgroundColor: "#DF5200",
    borderRadius: "1.85rem",
    paddingBlock: ".3rem",
    paddingInline: "2rem",
    padding: "8px 40px",
    height: "40px",
  } as React.CSSProperties,

  buttonFunctionTxt: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff",
  } as React.CSSProperties,

  buttonDeleteFunction: {
    backgroundColor: "#B3261E",
  } as React.CSSProperties,
};
// Customizable Area End

// Customizable Area Start
const MenuStyled = styled(Box)({
  "&.menuCustom": {
    borderRadius: "16px",
    width: "180px",
    border: "1px solid rgb(228, 221, 201)",
    position: "absolute", 
    top: "60px",
    padding: "5px 0", 
    background: "#fff", 
    textTransform: "uppercase", 
    zIndex: 10, 
    '@media (max-width: 600px)': {
      width: "165px"
    },
  },
  "&.menuCustom2": {
    borderRadius: "16px",
    width: "175px",
    border: "1px solid rgb(228, 221, 201)",
    position: "absolute", 
    background: "#DF5200",
    top: "58px",
    right: "0px", 
    color: "#fff", 
    padding: "5px 0",
    zIndex: 10,
    textTransform: "uppercase", 
  },
  "& .menuItem": {
    fontFamily: 'IBM Plex Sans',
    lineHeight: '22px',
    fontWeight: 500,
    opacity: 0.8,
    fontSize: '14px',
  },
  "& .MuiMenu-paper": {
    boxShadow: "none",
    marginTop: "55px",
  },
  "& .MuiMenu-list":{
    width: "163px",
    borderRadius: "16px",
    border: "1px solid rgb(228, 221, 201)",
  }
});
const TopBox = styled(Box)({
  position: "relative",
  display: "flex", 
  '@media (max-width: 600px)': {
    justifyContent: "space-between", 
    marginTop: "10px",
    width: '100%', 
  },
  "& .updateBtn":{
    border: "1px solid #E4DDC9",
    background: "#fff",
    marginRight: "20px", 
    borderRadius: "28px", 
    fontSize: "16px",
    fontWeight: 600, 
    '@media (max-width: 600px)': {
      fontSize: "14px",
      lineHeight: "20px",
      marginRight: "10px", 
    }
  }, 
  "& .addUsers": {
    borderRadius: "28px", 
    background: "#DF5200",
    color: "#fff", 
    borderBottomLeftRadius: '0px', 
    borderTopLeftRadius: '0px',
    padding: "15px 15px 15px 7px",
    minWidth: "0px",
    '@media (max-width: 600px)': {
      fontSize: "14px",
    },
    "& svg": {
      fontSize: "25px"
    }, 
    "& span": {
      marginRight: "0px",
      marginLeft: "0px",
    }
  }, 
  "& .addUserBtn": {
    '@media (max-width: 600px)': {
      fontSize: "16px !important",
    },
  }
});
const adminViewStyle = {
  maincard: {
    background: "#F8F6F1",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingRight: "0px",
    height: "fit-content"
  },
};

const webStyle = {
  addUserModalBtnStyle:{
    height:"56px",
    width: "130px",
    borderRadius: "28px",
    fontWeight: 700,
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    background: "#DF5200",
    fontSize: "18px",
    color: "#fff",
    padding: '10px 7px 10px 30px',
    boxShadow: 'none',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
  functionButtonContainer: {
    display: "flex",
    marginTop: "0px",
    gap: "1.5rem",
    justifyContent: "center",
    marginBottom: "20px",
  } as React.CSSProperties,
  buttonFunction: {
    backgroundColor: "#DF5200",
    paddingBlock: ".3rem",
    borderRadius: "1.85rem",
    padding: "8px 40px",
    paddingInline: "2rem",
    minWidth: "142px",
    height: "48px",
  } as React.CSSProperties,
  buttonFunctionTxt: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textAlign: "center",
    lineHeight: "24px",
    color: "#fff",
    textTransform: "uppercase",
  } as React.CSSProperties,
};
// Customizable Area End

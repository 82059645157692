import React from 'react'

import {
  // Customizable Area Start
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  Grid,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,

} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from "@material-ui/pickers";
import { Formik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { ChecokedRadioIconSVG, UnchecokedRadioIconSVG } from '../../blocks/AdminConsole/src/assets';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import { DropDownType, NoMenuDataFound, selectMenuItem } from './AdminConsoleHelper';

interface CustomFarmerUserForm {
  farmerInitialValues:any,
  validationSchemaFarmerobj: any,
  handleSubmitFarmer: (value:any) => void,
    getErrorMessage: (touched: any, errors: any, value: string) => boolean,
    getHelperText: (touched: any, errors: any, value: string) => boolean,
    getCustomErrorMessage: (touched: any, errors: any, value: string) => boolean,
    getSubmitFormBtnTxt:() => string,
    keyDown:(evt:any)=> void,
    keyDownInt: (evt: any)=> void,
    getEducationFarmerData: any[],
    disableStateFarmer: boolean,
    fetchDistrict:(value:any)=>void,
    getStateData:any[],
    getDistrictFarmerValue:(value:any) => void,
    fetchTaluka:(value:any)=>void,
    getDistrictData:any[],
    disableDistrictFarmerValue:()=>boolean,
    getTalukaFarmerValue:(value:any)=>void,
    fetchVillage:(value:any)=>void,
    disableTalukaFarmerValue:()=>boolean,
    getTalukaData:any[],
    getVillageFarmerValue:(value:any)=>void,
    disableVillageFarmerValue:()=>boolean,
    getVillageData:any[],
    FElistData:any,
    selectedFEformData:any,
    handleAssignFEchange:(event: any, value: any)=>void,
    assignFEPlaceholder:()=>void,
    disableSubmitBtn?:boolean,
    getNumberBelongsToData?:DropDownType[],
    getMobileDeviceModelFEData?:DropDownType[]
}

const FarmerUserForm = (props: CustomFarmerUserForm) => {
  const { farmerInitialValues, validationSchemaFarmerobj, handleSubmitFarmer, fetchDistrict, getDistrictFarmerValue, fetchTaluka,
    getErrorMessage, getHelperText, keyDown, keyDownInt, getCustomErrorMessage, disableStateFarmer,
    disableDistrictFarmerValue, getTalukaFarmerValue, fetchVillage, disableTalukaFarmerValue, getVillageFarmerValue, getSubmitFormBtnTxt,
    disableVillageFarmerValue, selectedFEformData, handleAssignFEchange, assignFEPlaceholder,
    getEducationFarmerData,
    getNumberBelongsToData,
    getMobileDeviceModelFEData,
    getStateData,
    getDistrictData,
    getTalukaData,
    getVillageData,
    FElistData,
    disableSubmitBtn = false,
  } = props

    return (
      <>
        <Formik
          data-testid="farmersFormikTestid"
          initialValues={farmerInitialValues}
          validationSchema={validationSchemaFarmerobj}
          onSubmit={(values) => {
            handleSubmitFarmer(values);
          }}
          validateOnChange
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"     
                className="customFormSpacing"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  style={{marginTop:"8px"}}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="firstnameFarmerId"
                      className="customLabelField"
                    >
                      First Name
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        data-testid="firstnameFarmerTestId"
                        style={webStyle.customInputField}
                        placeholder="Enter First Name"
                        type="text"
                        name="firstnameFarmer"
                        variant="outlined"
                        color="secondary"
                        value={values.firstnameFarmer}
                        onChange={handleChange}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "firstnameFarmer"
                        )}
                        helperText={getHelperText(
                          touched,
                          errors,
                          "firstnameFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="middlenameFarmerId"
                      className="customLabelField"
                    >
                      Middle Name
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        data-testid="middlenameFarmerTestId"
                        style={webStyle.customInputField}
                        placeholder="Enter Middle Name"
                        type="text"
                        name="middlenameFarmer"
                        variant="outlined"
                        color="secondary"
                        value={values.middlenameFarmer}
                        onChange={handleChange}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "middlenameFarmer"
                        )}
                        helperText={getHelperText(
                          touched,
                          errors,
                          "middlenameFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>                  
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="surnameFarmerId"
                      className="customLabelField"
                    >
                      Surname
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        data-testid="surnameFarmerTestId"
                        style={webStyle.customInputField}
                        placeholder="Enter Surname"
                        type="text"
                        name="surnameFarmer"
                        variant="outlined"
                        color="secondary"
                        value={values.surnameFarmer}
                        onChange={handleChange}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "surnameFarmer"
                        )}
                        helperText={getHelperText(
                          touched,
                          errors,
                          "surnameFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="mobileNumberFarmerId"
                      className="customLabelField"
                    >
                      Mobile Number
                    </FormLabel>
                    <FormControl fullWidth>
                      <Input
                        disableUnderline
                        className="custom-mobile-input"
                        style={{
                          marginTop: "8px",
                          padding: "8px 8px 8px 0",
                          background: "#FCFAF7",
                          borderRadius: "12px",
                          border: "1px solid #F0EBE0",
                          height:"56px"
                        }}
                        placeholder="Enter Mobile Number"
                        type="text"
                        name="mobileNumberFarmer"
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              style={{
                                padding: "0 16px",
                                borderRight: "1px solid #F0EBE0",
                                marginRight: "8px"
                              }}
                            >
                              <p>+91</p>
                            </Box>
                          </InputAdornment>
                        }
                        value={values.mobileNumberFarmer}
                        onChange={handleChange}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "mobileNumberFarmer"
                        )}
                      />
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "mobileNumberFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="formfieldWrapper">
                    <FormLabel
                      id="numberBelongsToFarmerId"
                      className="customLabelField"
                    >
                      Number belongs to
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        data-testid="numberBelongsToFarmerTestid"
                        value={values?.numberBelongsToFarmer || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="numberBelongsToFarmer"
                        color="secondary"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        error={getErrorMessage(
                          touched,
                          errors,
                          "numberBelongsToFarmer"
                        )}
                      >
                        {selectMenuItem("Select Option")}
                        {getNumberBelongsToData && getNumberBelongsToData.length ?
                          getNumberBelongsToData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`getNumberBeongsToDataTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "numberBelongsToFarmer"
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="formfieldWrapper">
                    <FormLabel
                      id="mobileTypeFarmerId"
                      className="customLabelField"
                    >
                      Mobile Type
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        data-testid="mobileTypeFarmerTestid"
                        value={values?.mobileTypeFarmer || ""}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="mobileTypeFarmer"
                        color="secondary"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        error={getErrorMessage(
                          touched,
                          errors,
                          "mobileTypeFarmer"
                        )}
                      >
                        {selectMenuItem("Select Option")}
                        {getMobileDeviceModelFEData && getMobileDeviceModelFEData.length ?
                          getMobileDeviceModelFEData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`getNumberBeongsToDataTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "mobileTypeFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="adharcardNumberFarmerId"
                      className="customLabelField"
                    >
                      Aadhar Card <span style={{fontStyle:"italic",fontSize:"12px"}}>- Optional</span>
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        style={webStyle.customInputField}
                        placeholder="Enter Aadhar Card Number"
                        type="number"
                        name="adharcardNumberFarmer"
                        variant="outlined"
                        color="secondary"
                        onKeyDown={keyDownInt}
                        value={values.adharcardNumberFarmer}
                        onChange={handleChange}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "adharcardNumberFarmer"
                        )}
                        helperText={getHelperText(
                          touched,
                          errors,
                          "adharcardNumberFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="dateOfBirthFarmerId"
                      className="customLabelField"
                    >
                      Date of Birth
                    </FormLabel>
                    <FormControl fullWidth style={{ marginTop: "8px" }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className="customDatePicker"
                          clearable
                          data-testid="dateOfBirthTestid"
                          value={values.dateOfBirthFarmer}
                          placeholder="DD/MM/YYYY"
                          onChange={(date) =>
                            setFieldValue("dateOfBirthFarmer", date)
                          }
                          maxDate={moment().subtract(18, "years").toDate()}
                          invalidDateMessage={`Invalid date`}
                          format="DD/MM/yyyy"
                          name="dateOfBirthFarmer"
                          inputVariant="outlined"
                          color="secondary"
                          error={getErrorMessage(
                            touched,
                            errors,
                            "dateOfBirthFarmer"
                          )}
                          helperText={getHelperText(
                            touched,
                            errors,
                            "dateOfBirthFarmer"
                          )}
                          keyboardIcon={
                            <DateRangeIcon style={webStyle.customDateIcon} />
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >                  
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="totalFamilyMemberFarmerId"
                      className="customLabelField"
                    >
                      Total Family Members
                    </FormLabel>
                    <FormControl fullWidth>
                      <TextField
                        style={webStyle.customInputField}
                        inputProps={{ inputMode: "numeric" }}
                        placeholder="Family Members"
                        type="number"
                        name="totalFamilyMemberFarmer"
                        variant="outlined"
                        onKeyDown={keyDownInt}
                        color="secondary"
                        value={values.totalFamilyMemberFarmer}
                        onChange={handleChange}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "totalFamilyMemberFarmer"
                        )}
                        helperText={getHelperText(
                          touched,
                          errors,
                          "totalFamilyMemberFarmer"
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="educationFarmerId"
                      className="customLabelField"
                    >
                      Education
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        data-testid="educationFarmerTestid"
                        value={values.educationFarmer}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="educationFarmer"
                        color="secondary"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        error={getErrorMessage(
                          touched,
                          errors,
                          "educationFarmer"
                        )}
                      >                        
                        {selectMenuItem("Select Education")}
                        {getEducationFarmerData.length ?
                          getEducationFarmerData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`educationFarmerMenusTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "educationFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Box style={{
                  margin: "8px 0 20px 0px"
                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "row",
                    alignItems: "center",                  
                  }}>
                    <Box>
                      <FormLabel style={{ marginRight: "24px" }} className="customLabelField">
                        Select Gender:
                      </FormLabel>
                    </Box>
                    <Box>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="userGenderId"
                          name="genderFarmer"
                          value={values.genderFarmer}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            style={{marginRight:"12px"}}
                            value="male"
                            control={
                              <Radio
                                icon={<img src={UnchecokedRadioIconSVG} />}
                                checkedIcon={
                                  <img src={ChecokedRadioIconSVG} />
                                }
                              />
                            }
                            label="Male"
                          />
                          <FormControlLabel
                            style={{marginRight:"12px"}}
                            value="female"
                            control={
                              <Radio
                                icon={<img src={UnchecokedRadioIconSVG} />}
                                checkedIcon={
                                  <img src={ChecokedRadioIconSVG} />
                                }
                              />
                            }
                            label="Female"
                          />
                          <FormControlLabel
                            style={{marginRight:"12px"}}
                            value="others"
                            control={
                              <Radio
                                icon={<img src={UnchecokedRadioIconSVG} />}
                                checkedIcon={
                                  <img src={ChecokedRadioIconSVG} />
                                }
                              />
                            }
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  {getCustomErrorMessage(
                    touched,
                    errors,
                    "genderFarmer"
                  )}
                </Box>
                
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel id="stateFarmerId" className="customLabelField">
                      State
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={values.stateFarmer}
                        className="mt-8 farmerFormDDClass"
                        data-testid="stateFarmerTestid"
                        variant="outlined"
                        disabled={disableStateFarmer}
                        name="stateFarmer"
                        color="secondary"
                        onChange={async (e: any) => {
                          setFieldValue("stateFarmer", e.target.value, true);
                          await setFieldTouched("stateFarmer", true);
                          setFieldValue("districtFarmer", "", true);
                          setFieldValue("talukaFarmer", "", true);
                          setFieldValue("villageFarmer", "", true);
                          fetchDistrict(e.target.value);
                        }}
                        displayEmpty
                        error={getErrorMessage(
                          touched,
                          errors,
                          "stateFarmer"
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >                        
                        {selectMenuItem("Select State")}                        
                        {getStateData.length > 0 ?
                          getStateData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`stateSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "stateFarmer"
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="districtFarmerId"
                      className="customLabelField"
                    >
                      District
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={getDistrictFarmerValue(values)}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="districtFarmer"
                        data-testid="districtFarmerTestid"
                        color="secondary"
                        onChange={async (e: any) => {
                          setFieldValue("districtFarmer", e.target.value, true);
                          await setFieldTouched("districtFarmer", true);
                          setFieldValue("talukaFarmer", "", true);
                          setFieldValue("villageFarmer", "", true);
                          fetchTaluka(e.target.value);
                        }}
                        error={getErrorMessage(
                          touched,
                          errors,
                          "districtFarmer"
                        )}
                        displayEmpty
                        disabled={disableDistrictFarmerValue()}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {selectMenuItem("Select District")}     
                        {getDistrictData.length > 0 ?
                          getDistrictData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`distSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "districtFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel id="talukaFarmerId" className="customLabelField">
                      Taluka
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={getTalukaFarmerValue(values)}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="talukaFarmer"
                        data-testid="talukaFarmerTestid"
                        color="secondary"
                        onChange={async (e: any) => {
                          setFieldValue("talukaFarmer", e.target.value, true);
                          await setFieldTouched("talukaFarmer", true);
                          setFieldValue("villageFarmer", "", true);
                          fetchVillage(e.target.value);
                        }}
                        displayEmpty
                        error={getErrorMessage(
                          touched,
                          errors,
                          "talukaFarmer"
                        )}
                        disabled={disableTalukaFarmerValue()}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {selectMenuItem("Select Taluka")}     
                        {getTalukaData.length > 0 ?
                          getTalukaData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`talukaSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "talukaFarmer"
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="formfieldWrapper"
                  >
                    <FormLabel
                      id="villageFarmerId"
                      className="customLabelField"
                    >
                      Village
                    </FormLabel>
                    <FormControl fullWidth>
                      <Select
                        value={getVillageFarmerValue(values)}
                        variant="outlined"
                        className="mt-8 farmerFormDDClass"
                        name="villageFarmer"
                        data-testid="villageFarmerTestid"
                        color="secondary"
                        onChange={async (e: any) => {
                          await setFieldValue("villageFarmer", e.target.value);
                          setFieldTouched("villageFarmer", true);
                        }}
                        displayEmpty
                        error={getErrorMessage(
                          touched,
                          errors,
                          "villageFarmer"
                        )}
                        disabled={disableVillageFarmerValue()}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {selectMenuItem("Select Village")}     
                        {getVillageData.length > 0 ?
                          getVillageData.map((ech: any) => (
                            <MenuItem
                              key={ech.id}
                              value={ech.id}
                              data-testid={`villageSubTestid-${ech.id}`}
                            >
                              {ech.name}
                            </MenuItem>
                          )) : NoMenuDataFound()}
                      </Select>
                      {getCustomErrorMessage(
                        touched,
                        errors,
                        "villageFarmer"
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Box id="assignFieldExecutiveId">
                  <Typography className="userModalBodySubHeading">
                    Assign Field Executive
                  </Typography>
                  <Box>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="formfieldWrapper"
                      >
                        <FormLabel
                          id="emailAddressAdminId"
                          className="customLabelField"
                        >
                          Assign Field Executive
                        </FormLabel>
                        <FormControl fullWidth>
                          <Autocomplete
                            data-testid="assigneFETestid"
                            className="customAutoComplete mt-8"
                            filterSelectedOptions // remove selected FE from the list
                            options={FElistData}
                            getOptionLabel={(option: any) =>
                              option.first_name + " " + option.last_name
                            }
                            value={selectedFEformData}
                            onChange={handleAssignFEchange}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                color="secondary"
                                placeholder={assignFEPlaceholder()}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className="modalSubmitButtonWrapper">
                  <Button
                    variant="contained"
                    data-testid="FEsubmitBtnTestid"
                    className="modalSubmitButton"
                    fullWidth
                    type="submit"
                    disabled={disableSubmitBtn}
                  >
                    {getSubmitFormBtnTxt()}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
        </>
    )
}


// Customizable Area Start
const webStyle = {
    center: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "row",
      alignItem: "center",
    },
    contentCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "30px",
      background: "#fff",
    },
    tableWrapper: {
      background: "#F8F6F1",
      border: "2px",
      borderRadius: "12px",
    },
    tableFilterWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      padding: "25px",
    },
    mainTable: {
      height: "583px",
    },
    maincard: {
      padding: "12px 15px",
      background: "#F8F6F1",
      border: "2px solid #F0EBE0",
      borderRadius: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imageIcons: {
      width: "107px",
      height: "96px",
    },
    font: {
      position: "relative",
      "&::after": {
        content: "' '",
        width: "2px",
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "-10px",
        background:
          "linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)",
      },
    },
    inputStyle: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
      width: "100%",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    buttonStyle: {
      width: "100%",
      height: "45px",
      marginTop: "40px",
      border: "none",
      backgroundColor: "rgb(98, 0, 238)",
    },
    customInputField: {
      margin: "8px 0 0 0",
    },
    customDateIcon: {
      color: "#1C2324",
      opacity: "0.48",
    },
    hide: {
      display: "none",
    },
    photoCamera: {
      width: 60,
      height: 60,
      color: "#1C2324",
      opacity: 0.5,
    },
    badgeCardRoot: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#FCFAF7",
      border: "1px solid #F0EBE0",
      borderRadius: "12px",
      minHeight: "150",
      padding: "0",
      marginTop: "8px",
      marginBottom: "20px",
    },
    badgeButton: {
      backgroundColor: "rgba(28, 35, 36, .56)",
      padding: "0.375rem",
    },
    addUserModalBtnStyle: {
      borderRadius: "28px",
      fontWeight: 700,
      lineHeight: "28px",
      display: "flex",
      alignItems: "center",
      background: "#DF5200",
      fontSize: "18px",
      color: "#fff",
      padding: "14px 32px",
      boxShadow: "none",
    }
  };
  // Customizable Area End

export default FarmerUserForm;
import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  InputAdornment,
  Radio,
  Tooltip,
  styled,
  Divider,
  Breadcrumbs
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon, DateRange, WatchLater, Share, KeyboardArrowDown } from "@material-ui/icons";

// Customizable Area Start
import {
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CustomModal from "../../../components/src/customModal.web";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

import { styles, theme } from "./SurveysStyle.web";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { linkSeparatorSVG } from "./assets";
import { checkCondition, returnTruthyString } from "../../../components/src/helper";


const ResponseBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px"
})
const ResponseTitle = styled(Typography)({
  fontWeight: 600,
  color: "#1C2324",
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "uppercase"
})
const ResponseCount = styled(Typography)({
  fontWeight: 600,
  color: "#006A77",
  fontSize: "20px",
  lineHeight: "30px",
  textTransform: "uppercase"
})
const CustomLine = styled(Divider)({
  height: "2px",
  background: "#F0EBE0"
})
const CustomLink = styled(Typography)({
  fontWeight: 500,
  fontStyle: "italic",
  color: "#006A77",
  fontSize: "16px",
  lineHeight: "24px",
})
const CustomBreadcrumbs = styled(Breadcrumbs)({
  marginTop: "8px",
  "& .MuiBreadcrumbs-separator": {
    margin: "0 4px"
  }
})
const CustomAccordionSummary = styled(AccordionSummary)({
  height: "48px !important",
  minHeight: "48px !important",
  padding: "0 16px !important",
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    height: "48px !important",
    minHeight: "48px !important",
  },
  "& .MuiButtonBase-root": {
    marginRight: "0px !important",
    padding: "0px !important",
  }
})
const CustomAccordionDetails = styled(AccordionDetails)({
  padding: "0 16px 12px 16px !important",
})
const CustomExpandIcon = styled(ExpandMoreIcon)({
  color: "#1C2324"
})
// Customizable Area End

import SurveysDetailsController, { Props, configJSON } from "./SurveysDetailsController";
import Loader from "../../../components/src/surveys/loader.web";
import LinkShare from "../../LinkShare/src/LinkShare.web";


export class SurveysDetails extends SurveysDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomModal = () => {
    const { openCustomPopup, titleCustomPopup, descCustomPopup } = this.state
    return (
      <>
        {openCustomPopup &&
          <ErrorModal
            open={openCustomPopup}
            errorTitle={titleCustomPopup}
            BtnTitle="Okay"
            errorSubTitle={descCustomPopup}
            handleBtnClick={this.closeCustomPopup}
          />}
      </>
    )
  }
  questionSection = (question: any, questionIdx: number) => {
    const { classes } = this.props

    let field = (<TextField
      id="outlined-multiline-static"
      multiline
      rows={4}
      defaultValue="User will write answer here...."
      variant="outlined"
      disabled
      className={classes.textArea}
    />)

    if (question?.attributes.question_type?.includes("Date")) {
      field = (
        <TextField
          defaultValue="DD/MM/YYYY"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><DateRange /></InputAdornment>,
          }}
          variant="outlined"
        />
      )
    }

    else if (question?.attributes.question_type?.includes("Time")) {
      field = (
        <TextField
          defaultValue="Time"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><WatchLater /></InputAdornment>,
          }}
          variant="outlined"
        />
      )
    }
    else if (question?.attributes.question_type?.includes("Dropdown")) {

      field = (
        <TextField
          defaultValue="Choose option"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="start"><KeyboardArrowDown /></InputAdornment>,
          }}
          fullWidth
          variant="outlined"
        />
      )
    } else if (question?.attributes.question_type?.includes("Check")) {
      field = <div className={classes.flexColumn}>{question?.attributes.question_options.data.map((data: any, index: number) => <FormControlLabel control={<Checkbox />} label={<span className={`${classes.betweenCardHeading} ${classes.option}`}>{data.attributes.value}</span>} key={`checkBox-${question.id}-${index}`} disabled />)}</div>
    }
    else if (question?.attributes.question_type?.includes("Radio")) {
      field = <div className={classes.flexColumn}>{question?.attributes.question_options.data.map((data: any, index: number) => <FormControlLabel value={data.attributes.value} control={<Radio />} label={<span className={`${classes.betweenCardHeading} ${classes.option}`}>{data.attributes.value}</span>} key={`radio-${question.id}-${index}`} disabled />)}</div>
    }



    return (
      <Box className={`${classes.boxWraperForServeyInput} ${classes.questionCard}`} key={`question-${question.id}-${questionIdx}`}>
        <div>
            <Tooltip title={question.attributes.question || ""}>
              <Typography className={classes.question} >
                {question.attributes.question}
              </Typography>
            </Tooltip>
          <CustomLine style={{ margin: "0px 0 14px 0" }} />
          {field}
        </div>
      </Box>
    )
  }

  renderLoader = () => {
    const { survey, surveyDetailsLoading } = this.state
    return (
      <>
        {surveyDetailsLoading && !survey ? <Loader /> : <></>}
      </>
    )
  }
  

  // Customizable Area End

  render() {
    const { navigation, id, classes } = this.props;
    const { survey, deleteId, responseUser, accordionActive, surveyDetailsLoading } = this.state


    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={theme}>
          <CustomModal
            open={!!deleteId}
            onClose={() => this.toggleDelete(null)}
            confirmHandler={() => this.deleteSurveyAPI()}
            title="Delete Survey"
            description={configJSON.DELETE_SURVEY_DESCRIPTION}
            confirmTitle="Delete"
          />
          {this.renderLoader()}
          {survey && !surveyDetailsLoading ? 
          <Box>
            <div className={classes.between}>
              <div>
                <Typography className={classes.surveyName}>
                  {survey.attributes.name} <span className={classes.dateText}>{moment(survey.attributes.created_at).format("DD/MM/YYYY")}</span>
                </Typography>
                  <CustomBreadcrumbs
                    separator={<img src={linkSeparatorSVG} alt={"separator"} />}
                    aria-label="breadcrumb"
                  >
                    <Box onClick={this.navigateToSurvey} style={{ cursor: "pointer" }} data-testid="survey-link">
                      <CustomLink style={{ opacity: 0.64 }}>Surveys</CustomLink>
                    </Box>
                    <CustomLink noWrap>
                      {returnTruthyString(survey.attributes.name)}
                    </CustomLink>
                  </CustomBreadcrumbs>
              </div>
              <div className={classes.rightHeader}>
                <FormControlLabel
                  value="start"
                  control={<Switch size="medium" checked={!survey.attributes.active}
                    id="switch-btn"
                    className={checkCondition(survey.attributes.active, classes.switchBtn, classes.disableSwitchBtn) as string}
                    onChange={() => this.changeSurveysActionState(survey.id, !survey.attributes.active)}
                  />}
                  className={classes.formControlLabel}
                  label={checkCondition(survey.attributes.active, "DISABLE",  "ENABLE") as string}
                  labelPlacement="start"
                />
                <Button variant="contained" id="deleteBtn" className={classes.deleteBtnLarge} onClick={() => this.toggleDelete(survey.id)}>
                  Delete
                </Button>
              </div>
            </div>
            <Grid container spacing={3} className={classes.mt12}>
              <Grid item xs={12} sm={6} md={7}>
                {
                  survey.attributes.questions.data.map((question, index) => this.questionSection(question, index))
                }
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <Box className={classes.responseCard}>
                  <ResponseBox>
                    <ResponseTitle className={classes.responseTitleKey} >
                      RESPONSES
                    </ResponseTitle>
                    <ResponseCount>
                      {checkCondition(Boolean(responseUser?.length),responseUser?.length, 0) as number}
                    </ResponseCount>
                  </ResponseBox>
                  <div id="scrollableDiv" style={{ overflowY: "auto", maxHeight: "300px", marginBottom:"3.75rem"}}  >
                  <InfiniteScroll
                        dataLength={checkCondition(Array.isArray(responseUser),responseUser?.length, 0) as number}
                        scrollableTarget="scrollableDiv"
                        next={() => this.responsesUser()}
                        hasMore={this.state.hasNext}
                        loader={<></>}
                    >
                  {Array.isArray(responseUser) && responseUser?.map((data, index) =>
                  <>
                  <CustomLine />
                    <Accordion className={classes.responseAccordion} key={`user-response-${data.id}-${index}`} expanded={accordionActive === index} onChange={() => this.setState(({accordionActive}) => ({accordionActive: accordionActive === index ? null : index}))} >
                      <CustomAccordionSummary
                        expandIcon={<CustomExpandIcon />}
                      >
                        <div className={`${classes.betweenCard} ${classes.w100}`}>
                          <Typography className={classes.headingAccordion}>{data.attributes.account_id}</Typography>
                            <span className={classes.dateText} style={{ marginRight: "6px" }}>{moment(data?.attributes.created_at).format("DD/MM/YYYY")}</span>
                        </div>
                      </CustomAccordionSummary>
                      <CustomAccordionDetails className={classes.detailSection}>
                        {Object.keys(data.attributes.response).map((item, index) => <div key={`question-${index}`} >
                          <Typography className={classes.questionAccordion}>
                            {item}
                          </Typography>
                          {typeof data.attributes.response[item] === "string" ? (
                            <Typography className={classes.answer}>
                              {data.attributes.response[item]}
                            </Typography>
                          ) : (
                            (data.attributes.response[item] as string[])?.map((items, index) => (
                              <Typography className={classes.answer} key={`checkbox-${index}`}>
                                {items}
                              </Typography>
                            ))
                          )}
                        </div>
                        )
                        }
                        <div className={classes.between} >
                          <Button className={classes.shareBtn} id="shareBtn" startIcon={<Share />} onClick={()=> this.handleShareLink(data)} > Share in Chat </Button>
                        </div>
                      </CustomAccordionDetails>
                    </Accordion>
                    </>)}
                    </InfiniteScroll>
                    </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
            : <></>}
          {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}
          {this.renderCustomModal()}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(SurveysDetails);
// Customizable Area End

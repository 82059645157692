import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start

import firebase from "firebase"
import { setStorageData } from "../../../framework/src/Utilities";
import { ITNavMember } from "../../../components/src/interface.web";
import { logoutUpdatedUser } from "../../../components/src/helper";
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  childrenStyle?:any
  lastMessageId?:any
  onChatCountFetch? : (count: any) => void;
  // Customizable Area End
}

interface S {
  error: string;
  loading: boolean;
  token: string | null;
  dashboard: string;
  // Customizable Area Start
  profileAvatar: string | undefined;
  profile_id: string | number;
  profile_firstname?: string;
  profile_lastname?: string;
  role: string;
  hover: Record<keyof ITNavMember, boolean>;
  drawerOpen: boolean;
  chat_count: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export type TNavMember = {
  home: boolean;
  survey: boolean;
  calendar: boolean;
  users: boolean;
  chat: boolean;
  materrapedia: boolean;
  notifications: boolean;
  events: boolean;
  dropdowns: boolean;
};

export type Path = {
  route: string;
  name: keyof TNavMember;
  icon: any;
  iconHover: any;
  iconExtra: any;
  title: string;
};
// Customizable Area End

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static instance: NavigationMenuController;
  adminProfileCallId: string = "";
  registerFcmToeknCallId: string = "";
  chatCountCallId: string = "";
  tokenizeMenu: ({
    token,
    otp_token
  }: {
    otp_token: string | null;
    token?: string;
  }) => null = ({
    token,
    otp_token
  }: {
    otp_token: string | null;
    token?: string;
  }) => {
    this.setState({ token: token ? token : otp_token, loading: true });
    return null;
  };

  getChatCount() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token:
        typeof window !== "undefined"
          ? window.localStorage.getItem("tokenn")
          : configJSON.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.chatCountCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "admin/bx_block_admin_chat/admin_chat/total_unread_meassage_count"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    NavigationMenuController.instance = this;
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      profile_id: "",
      profile_firstname: "",
      profile_lastname: "",
      role: "",
      token: "",
      loading: false,
      error: "",
      dashboard: "home",
      drawerOpen: false,
      profileAvatar: undefined,
      hover: {
        home: false,
        survey: false,
        calendar: false,
        users: false,
        chat: false,
        materrapedia: false,
        notifications: false,
        events: false,
        dropdowns: false,
        analytics: false,
      },
      chat_count: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getAdmin();
    this.getChatCount();
    const loc = window.location.pathname.split("/")[1];
    this.getPathName(loc);
    this.generateFcmToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getAdmin() {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token:
        typeof window !== "undefined"
          ? window.localStorage.getItem("tokenn")
          : configJSON.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adminProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adminGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPathName(path?: string): boolean {
    if (path) {
      const redirect: any = {
        "news-and-events": "events",
        home: "home",
        calendar: "calendar",
        "users-farm-diary": "users",
        "app-dropdowns": "dropdowns",
        "users-farm-surveys": "users",
        "view-fe-profile": "users",
        "view-admin-profile": "users",
        "view-super-admin-profile": "users",
        "view-farmer-profile": "users",
        materrapedia: "materrapedia",
        "surveys-section": "survey",
        "surveys-details": "survey",
        "notifications": "notifications",
        "analytics": "analytics",
      };

      this.setState(prev => ({
        ...prev,
        dashboard: redirect[path] ? redirect[path] : path
      }));
      return true;
    } else {
      this.setState(prev => ({ ...prev, dashboard: "home" }));
      return false;
    }
  }

  handleMouseOverEventNavigationSideBar = (target: keyof ITNavMember): void => {
    this.setState(prev => ({
      ...prev,
      hover: {
        ...prev.hover,
        [target]: true
      }
    }));
  };

  handleMouseLeaveEventNavigationSideBar = (target: keyof ITNavMember): void => {
    this.setState(prev => ({
      ...prev,
      hover: {
        ...prev.hover,
        [target]: false
      }
    }));
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    const otp_token =
      typeof window !== "undefined"
        ? window.localStorage.getItem("tokenn")
        : configJSON.token;
    this.tokenizeMenu({ token, otp_token });

    if (apiRequestCallId === this.adminProfileCallId) {
      if (successResponse.data) {
        this.updateProfileData(successResponse.data);
      } else {
        logoutUpdatedUser()
        this.setState({
          error: "Something went wrong",
          loading: false
        });
      }
    }

    if (apiRequestCallId === this.chatCountCallId) {
      if (successResponse) {
        this.updateChatCount({ successResponse, errorResponse });
      } else {
        this.setState({
          error: "Something went wrong",
          loading: false
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(this.props.lastMessageId &&  prevProps.lastMessageId !== this.props.lastMessageId){
      this.getChatCount();
    }
  }
  updateProfileData(successResponse: any) {
    this.setState(prev => {
      return {
        ...prev,
        profile_id: successResponse.id,
        role: successResponse.attributes.role,
        profile_firstname: successResponse.attributes.first_name,
        profile_lastname: successResponse.attributes.last_name,
        profileAvatar: successResponse.attributes.avatar
      };
    });
  }

  updateChatCount({ successResponse, errorResponse }: any) {
    if (successResponse) {
      this.setState(prev => {
        return {
          ...prev,
          chat_count: successResponse.total_unread_count
        };
      }, () => {
        if(this.props.onChatCountFetch) {
          this.props.onChatCountFetch(successResponse.total_unread_count);
        }
      });
    } else if (errorResponse) {
      this.setState({
        error: "Something went wrong",
        loading: false
      });
    }
  }

  generateFcmToken=()=>{
    let messaging:any=firebase.messaging()
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted");
      }
    });

    messaging
      .requestPermission()
      .then(async() => {
        return messaging.getToken();
      })
      .then((token:any) => {
        setStorageData("deviceId", token)
        this.registerFcmToken(token)
      })
      .catch((error:any) => {
        console.log("firebase error", error);
      });
  };

  registerFcmToken=(token:any)=>{
    if(!token) return
    console.log("registerFcmToken token",token)
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      token:
        typeof window !== "undefined"
          ? window.localStorage.getItem("tokenn")
          : configJSON.token
    };
    let body={
      data:{
        attributes:{
          platform:"web",
          token:token
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registerFcmToeknCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registerFcmTokenEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateTo = (path: string) => {
    this.props.navigation.navigate(path)
  }
  // Customizable Area End
}

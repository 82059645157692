import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { IAnalytic, IAnalyticForm, ICreatedAnalytic } from "../../../components/src/interface.web";
import { returnError, trimStart } from "../../../components/src/helper";

const initialForm = {
  url_value: "",
  embed_code: "",
  title: "",
  errors: {
    url_value: "",
    embed_code: "",
    title: "",
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string,
  authToken: string | null,
  selectedDashboard: IAnalytic | null,
  // MODALS
  openDashboardModal: boolean,
  openDeleteModal: boolean,
  titleDeleteModal: string,
  descDeleteModal: string,
  openAlertModal: boolean,
  titleAlertModal: string,
  descAlertModal: string,
  openErrorModal: boolean,
  titleErrorModal: string,
  descErrorModal: string,
  // FORM
  currentTab: number,
  form: IAnalyticForm,
  // API CALLS
  allAnalyticsDataLoading: boolean,
  allAnalyticsData: IAnalytic[],
  deleteAnalyticsLoading: boolean,
  addAnalyticsLoading: boolean,
  fetchAnalyticsLoading: boolean,
  analyticsData: ICreatedAnalytic | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllAnalyticsCallId: string = '';
  getAnalyticsCallId: string = '';
  deleteAnalyticsCallId: string = '';
  addAnalyticsCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      // NEW
      authToken: localStorage.getItem("tokenn"),
      selectedDashboard: null,
      // MODALS
      openDashboardModal: false,
      openDeleteModal: false,
      titleDeleteModal: "",
      descDeleteModal: "",
      openAlertModal: false,
      titleAlertModal: "",
      descAlertModal: "",
      openErrorModal: false,
      titleErrorModal: "",
      descErrorModal: "",
      // FORM
      currentTab: 1,
      form: initialForm, 
      // API CALLS
      allAnalyticsDataLoading: false,
      allAnalyticsData: [],
      deleteAnalyticsLoading: false,
      addAnalyticsLoading: false,
      fetchAnalyticsLoading: false,
      analyticsData: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorReponse) {
        this.openAlertModal(true, "Error", "Something went wrong.")
      }
      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.getAllAnalyticsCallId:
            this.handleGetAllAnalytics(responseJson)
            break;
          case this.deleteAnalyticsCallId:
            this.handleDeleteAnalytics(responseJson)
            break;
          case this.addAnalyticsCallId:
            this.handleAddAnalytics(responseJson)
            break;
          case this.getAnalyticsCallId:
            this.handleGetAnalytics(responseJson)
            break;
        }
      }
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start

  // HANDLE API CALLS
  handleGetAllAnalytics = (responseJson: { data: IAnalytic[] } | null | undefined) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({
        allAnalyticsData: responseJson.data,
        allAnalyticsDataLoading: false
      })
    } else {
      this.setState({
        allAnalyticsData: [],
        allAnalyticsDataLoading: false
      })
    }
  }

  handleDeleteAnalytics = (responseJson: { deleted: boolean,  errors?: string[] } | null | undefined) => {
    this.setState({ deleteAnalyticsLoading: false });
    if (responseJson && responseJson.deleted) {
      this.handleCloseDeleteModal()
      this.setState({
        selectedDashboard: null,
        analyticsData: null
      })
      this.openAlertModal(true, "Success", "Dashboard deleted Successfully.")
      this.fetchAllAnalytics()
    } else if (responseJson && responseJson.errors) {
      this.openAlertModal(true, "Error", responseJson.errors?.[0])
    } else {
      this.openAlertModal(true, "Error", "Something went wrong.")
    }
  }

  handleAddAnalytics = (responseJson: { data?: ICreatedAnalytic, errors?: string[] } | null | undefined) => {
    this.setState({ addAnalyticsLoading: false })
    if (responseJson && responseJson.data) {
      this.handleCloseAddModal()
      this.fetchAllAnalytics()
      this.openAlertModal(true, "Success", "Dashboard added Successfully.")
    }  else if (responseJson && responseJson.errors) {
      this.openAlertModal(true, "Error", responseJson.errors?.[0])
    } else {
      this.openAlertModal(true, "Error", "Something went wrong.")
    }
  }

  handleGetAnalytics = (responseJson: { data?: ICreatedAnalytic, errors?: string[] } | null | undefined) => {
    const successResponse = responseJson && responseJson.data ? responseJson.data : null
    const errorInResp = (!successResponse?.attributes.url) && (!successResponse?.attributes.embed_code)
    if (errorInResp) {
      this.errorModalOpen(true, configJSON.MSG.ERROR_TITLE, configJSON.MSG.ERROR_DESC)
    }
    if (successResponse) {
      this.setState({
        fetchAnalyticsLoading: false,
        analyticsData: successResponse ? successResponse : null
      })
    } else {
      this.setState({
        fetchAnalyticsLoading: false,
        analyticsData: null
      })
    }
  }
  async componentDidMount(): Promise<void> {
    // FETCH ALL ANALYTICS     
    this.fetchAllAnalytics()
  }

  // API CALLS
  fetchAllAnalytics = () => {
    this.setState({
      allAnalyticsDataLoading: true,
      allAnalyticsData: []
    });

    const header = { token: this.state.authToken };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllAnalyticsCallId = requestMsg.messageId;

    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.GET_ALL_ANALYTICS);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  fetchAnalyticsById = (itemId: number) => {
    this.setState({
      fetchAnalyticsLoading: true,
      analyticsData: null
    });

    const header = { token: this.state.authToken };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAnalyticsCallId = requestMsg.messageId;

    const endpoint = `${configJSON.ENDPOINTS.GET_ALL_ANALYTICS}/${itemId}`
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  deleteAnalytics = () => {
    this.setState({
      deleteAnalyticsLoading: true,
    });
    const itemId = this.state.selectedDashboard?.id

    const header = { token: this.state.authToken };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAnalyticsCallId = requestMsg.messageId;

    const endpoint = `${configJSON.ENDPOINTS.GET_ALL_ANALYTICS}/${itemId}`
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.DELETE_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  createAnalytics = () => {
    this.setState({
      addAnalyticsLoading: true,
    });

    const { title, url_value, embed_code } = this.state.form
    let Body;
    if (this.state.currentTab == 1) {
      Body = {
        "data": {
          "attributes": {
            "url": url_value,
            "title": title
          }
        }
      }
    } else {
      Body = {
        "data": {
          "attributes": {
            "title": title,
            "embed_code": embed_code
          }
        }
      }
    }
    
    const header = { token: this.state.authToken, "Content-Type": "application/json" };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addAnalyticsCallId = requestMsg.messageId;

    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
    requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(Body));
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.GET_ALL_ANALYTICS);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  handleChangeAnalytic = (item: IAnalytic) => {
    this.setState({
      selectedDashboard: item
    }, () => {
      if (item?.id) this.fetchAnalyticsById(Number(item?.id))
    })
  }

  // ADD MODAL HANDLE
  handleOpenAddModal = () => {
    this.setState({
      openDashboardModal: true
    })
  }
  handleCloseAddModal = () => {
    this.setState({
      openDashboardModal: false,
      // RESET 
      currentTab: 1,
      form: initialForm, 
    })
  }
  handleChangeTab = (event: React.ChangeEvent<{}>, TabValue: number) => {
    this.setState(prevState => ({
      currentTab: TabValue,
      form: {
        ...prevState.form,
        embed_code: "",
        url_value: "",
        errors: {
          ...prevState.form.errors,
          embed_code: "",
          url_value: "",
          title: ""
        }
      }
    }));
  }
  handleSubmitModal = () => {
    if(this.validateForm()) this.createAnalytics()    
  }
  validateForm = () => {
    let isValidate;
    const { title, url_value, embed_code } = this.state.form

    const errorTab1 = returnError(trimStart(String(title))) || returnError(trimStart(String(url_value)))
    const errorTab2 = returnError(trimStart(String(title))) || returnError(trimStart(String(embed_code)))
    if ((this.state.currentTab == 1) && errorTab1) {
      this.setValue("title", title)
      this.setValue("url_value", url_value)
      isValidate = false
    } else if ((this.state.currentTab == 2) && errorTab2) {
      this.setValue("embed_code", embed_code)
      this.setValue("title", title)
      isValidate = false
    } else {
      isValidate = true
    }
    return isValidate
  }

  setValue = (fieldName: string, fieldValue: string) => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [fieldName]: trimStart(String(fieldValue)),
        errors:{
          ...prevState.form.errors,
          [fieldName]: returnError(trimStart(String(fieldValue))),
        }
      }
    }));
  }

  // DELETE MODAL HANDLE
  handleOpenDeleteModal = () => {
    if (!this.state.selectedDashboard) return
    const title = this.state.selectedDashboard.attributes.title
    const description = `Are you sure? You want to delete ${title} Dashboard?`
    this.setState({
      openDeleteModal: true,
      titleDeleteModal: "Delete Dashboard",
      descDeleteModal: description,
    })
  }
  handleCloseDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      titleDeleteModal: "",
      descDeleteModal: "",
      deleteAnalyticsLoading: false,
    })
  }
  // ALERT MODAL
  openAlertModal = (open: boolean, title: string, description: string) => {
    this.setState({
      openAlertModal: open,
      titleAlertModal: title,
      descAlertModal: description,
    })
  }
  closeAlertModal = () => {
    this.setState({
      openAlertModal: false,
      titleAlertModal: "",
      descAlertModal: "",
    })
  }
  // CUSTOM ERROR MODAL
  errorModalOpen = (open: boolean, title: string, description: string) => {
    this.setState({
      openErrorModal: open,
      titleErrorModal: title,
      descErrorModal: description,
    })
  }
  errorModalClose = () => {
    this.setState({
      openErrorModal: false,
      titleErrorModal: "",
      descErrorModal: "",
    })
  }
  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, presowingImage, WeedManagementActivitySVG, pestManagementSvg, irrigationSvg, gapFilling2Svg, nutrientManagementSvg, harvestSvg, saleSvg, cropFailureSvg } from "./assets";
import { ICreateCropResponse, ICropDetailResponse, ICropDetails, ICropFormValues, ICropName, IDeleteCropResponse, IDropdownData, IFilteredActivityResponse, ILatitudeLongitude, ISelectFarm, ISelectFarmDataResponse, IUnitDropdown, IUnitDropdownResponse,  } from "../../../components/src/interface.web";
import moment from "moment";
import { getPolygonCentroid } from "../../../components/src/FarmMapping/FarmMapping.web";
import { converStringToDate, debounce, defaultQuanitityUnit } from "../../../components/src/helper";


interface LatLng {
  lat: number;
  lng: number;
}

interface Position {
  coords:{latitude: number;
    longitude: number;}
}

interface IEndCropResponse {
  data?:{message:string},
  errors?:string
}

const userLocation = localStorage.getItem('userLocationCropMapping');
const isValueFalsy = (value:unknown) => value === "" || value === null || value === undefined
const truthyValue = (value: string | number | null | undefined | "") => {
  if (value) {
    return String(value)
  } else {
    return ""
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userAccountId: string | number,
  currentCropYear:number,
  isAddCropModalOpen: boolean,
  handleCloseAddCropModal: () => void,
  successAddCropCallback: () => void,
  isEditCropModalOpen: boolean,
  handleOpenEditCropModal: (e: React.MouseEvent<SVGSVGElement>, id: string | number) => void,
  handleCloseEditCropModal: () => void,
  selectedCropId: string | number,
  isViewCropModalOpen: boolean,
  handleCloseViewCropModal: () => void,
  OpenAddFarmModalfn:() => void, // OPEN FARM
  OpenAddActivityModalfn:() => void, // OPEN ADD ACTIVITY 

  deleteActivity: (id: string, selectedActivityKey?:string) => void;
  handleEditActivity: (id: string,land_detail_id:string | undefined,crop_id:string | string [] | undefined, selectedActivityKey?:string) => void;
  errorPopup: string | null,
  isUserActive: boolean,
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  authToken: string | null;
  // CROP-MAPPING
  openFarmMapping: boolean
  currentLocation: LatLng | null
  latlngHash: string | null
  farmMappingURL: string | null;
  coordinates: LatLng[];
  markerDefault: LatLng | null;
  farmMappingFile: File | null
  tempCoordinates: LatLng[]
  // CROP FORMS
  initialCropFormValues: ICropFormValues,
  CropFormErrors: Record<string, {errorMsg: string}>,
  // ADD CROP DROPDOWNS
  farmNamesList:ISelectFarm[],
  cropNamesList: ICropName[],
  cropTypesList: IDropdownData[],
  cropVarietyList: IDropdownData[],
  seedSourcesList: IDropdownData[],
  BioAgentNamesList: IDropdownData[],
  unitOfCropAreaList: IUnitDropdown[],
  unitOfSeedQuantityList: IUnitDropdown[],
  unitOfBioAgentList: IUnitDropdown[],
  // CREATE CROP
  createCropLoading: boolean;
  // CROP DETAILS
  cropDetailsDataLoading:boolean;
  cropDetailsData:ICropDetails | null;
  // VIEW CROP
  isEndCropModalOpen:boolean,
  isRestartCropModalOpen:boolean,
  endCropLoading: boolean,
  restartCropLoading: boolean,
  // DELETE CROP
  isDeleteCropModalOpen: boolean,
  deleteCropLoading: boolean,
  // SUCCESS - ERROR POPUP MODAL
  modalTitle: string,
  isModalOpen: boolean,
  modalMessage: string,
  // MENU ITEM
  searchMenuItem: string,
  filteredCropNamesList: ICropName[],
  filteredBioAgentNamesList: IDropdownData[],
  // FILTER STATES
  anchorEl: any;
  openTableFilterUT: boolean;
  savedSortAscendingUT: boolean,
  savedSortDescendingUT: boolean,
  sortAscendingUT: boolean,
  sortDescendingUT: boolean,
  filterHasErrorUT: boolean
  selectedActivity: string[],
  sowingYear: number | null,
  filteredActivityData: IFilteredActivityResponse[],
  loader: boolean,
  hideBadgeUT: boolean,
  activeAccordionIndex: string | number ,
  openWarningModal: boolean,
  cropName: string,
  cropLastDate: string,
  btnEnabled: boolean,
  isDropdownAPIResponded: number,
  isShare: boolean,
  linkShare: string,
  messageHeading: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfmultiplecropsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFarmListDataApiCallId: string = "";
  fetchCropNameDataApiCallId: string = "";
  fetchCropTypeDataApiCallId: string = "";
  fetchCropVarietyDataApiCallId: string = "";
  fetchSeedSourceDataApiCallId: string = "";
  fetchBioAgentNameDataApiCallId: string = "";
  fetchCropAreaUnitDataApiCallId: string = "";
  fetchQuantityOfSeedUnitDataApiCallId: string = "";
  fetchBioAgentQuntityUnitDataApiCallId: string = "";
  fetchFilteredActivitiesApiCallId: string = "";
  // CROP CRUD
  createCropApiCallId: string = "";
  updateCropApiCallId: string = "";
  deleteCropApiCallId: string = "";
  fetchCropDetailsByIdApiCallId: string = "";
  endCropApiCallId: string = ""; // END CROP
  restartCropApiCallId: string = ""; // RESTART CROP

  initialErrorObject = {    
    errorMsg: ""
  }
  initialFormValues = {
    select_farm: "",
    sowing_date: new Date(),
    crop_name: "",
    crop_type: "",
    crop_area_value: "",
    crop_area_unit: "",
    crop_variety: [],
    number_of_packets: "",
    quantity_of_seed_value: "",
    quantity_of_seed_unit: "",
    seed_source: "",
    seed_cost: "",
    labor_cost: "",
    non_gmo_seed: "true",
    performed_seed_treatment: "true",
    bio_agent_name: "",
    bio_agent_cost: "",
    bio_agent_quntity: "",
    bio_agent_quntity_unit: ""
  }

  initialErrors = {
    select_farm: this.initialErrorObject,
    sowing_date: this.initialErrorObject,
    crop_name: this.initialErrorObject,
    crop_type: this.initialErrorObject,
    crop_area_value: this.initialErrorObject,
    crop_area_unit: this.initialErrorObject,    
    crop_variety: this.initialErrorObject,
    number_of_packets: this.initialErrorObject,
    quantity_of_seed_value: this.initialErrorObject,
    quantity_of_seed_unit: this.initialErrorObject,
    seed_source: this.initialErrorObject,
    seed_cost: this.initialErrorObject,
    labor_cost: this.initialErrorObject,
    non_gmo_seed: this.initialErrorObject,
    performed_seed_treatment: this.initialErrorObject,
    bio_agent_name: this.initialErrorObject,
    bio_agent_cost: this.initialErrorObject,
    bio_agent_quntity: this.initialErrorObject,
    bio_agent_quntity_unit: this.initialErrorObject,
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      authToken: localStorage.getItem("tokenn"),      
      // CROP-MAPPING
      openFarmMapping: false,      
      currentLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      tempCoordinates: [],
      // CROP FORM
      initialCropFormValues: this.initialFormValues,
      CropFormErrors: this.initialErrors,
      // ADD CROP DROPDOWNS
      farmNamesList: [],
      cropNamesList: [],
      cropTypesList: [],
      cropVarietyList: [],
      seedSourcesList: [],
      BioAgentNamesList: [],
      unitOfCropAreaList: [],
      unitOfSeedQuantityList: [],
      unitOfBioAgentList: [],
      // CREATE CROP
      createCropLoading: false,
      // CROP DETAILS
      cropDetailsDataLoading: false,
      cropDetailsData: null,
      // VIEW CROP
      isEndCropModalOpen: false,
      isRestartCropModalOpen: false,
      endCropLoading: false,
      restartCropLoading: false,
      // DELETE CROP
      isDeleteCropModalOpen: false,
      deleteCropLoading: false,
      // SUCCESS - ERROR POPUP MODAL
      modalTitle: "",
      isModalOpen: false,
      modalMessage: "",
      // MENU
      searchMenuItem: "",
      filteredCropNamesList: [],
      filteredBioAgentNamesList: [],
      // FILTER STATES
      anchorEl: null,
      openTableFilterUT: false,
      savedSortAscendingUT: false,
      savedSortDescendingUT: false,
      sortAscendingUT: false,
      sortDescendingUT: false,
      filterHasErrorUT: false,
      selectedActivity: [],
      sowingYear: null,
      filteredActivityData: [],
      loader: false,
      hideBadgeUT: true,
      activeAccordionIndex: "",
      openWarningModal: false,
      cropName: "",
      cropLastDate: "",
      btnEnabled: false,
      isDropdownAPIResponded: 0,
      isShare: false,
      linkShare: '',
      messageHeading: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.fetchFarmListDataApiCallId:
            this.handleFetchFarmListDataApiCallId(responseJson);
            break;
          case this.fetchCropNameDataApiCallId:
            this.handleFetchCropNameDataApiCallId(responseJson);
            break;
          case this.fetchCropTypeDataApiCallId:
            this.handlefetchCropTypeDataApiCallId(responseJson);
            break;
          case this.fetchCropVarietyDataApiCallId:
            this.handleFetchCropVarietyDataApiCallId(responseJson);
            break;
          case this.fetchSeedSourceDataApiCallId:
            this.handleFetchSeedSourceDataApiCallId(responseJson);
            break;
          case this.fetchBioAgentNameDataApiCallId:
            this.handleFetchBioAgentNameDataApiCallId(responseJson);
            break;
          case this.fetchCropAreaUnitDataApiCallId:
            this.handleFetchCropAreaUnitDataApiCallId(responseJson);
            break;
          case this.fetchQuantityOfSeedUnitDataApiCallId:
            this.handleFetchQuantityOfSeedUnitDataApiCallId(responseJson);
            break;
          case this.fetchBioAgentQuntityUnitDataApiCallId:
            this.handleFetchBioAgentQuntityUnitDataApiCallId(responseJson);
            break;
          // CREATE CROP
          case this.createCropApiCallId:
            this.handleCreateCropApiCallId(responseJson);
            break;
          // UPDATE CROP
          case this.updateCropApiCallId:
            this.handleUpdateCropApiCallId(responseJson);
            break;
          // DELETE CROP
          case this.deleteCropApiCallId:
            this.handleDeleteCropApiCallId(responseJson);
            break;
            
          // GET CROP DETAILS
          case this.fetchCropDetailsByIdApiCallId:
            this.handleFetchCropDetailsByIdApiCallId(responseJson)
            break;
            
          // END CROP
          case this.endCropApiCallId:
            this.handleEndCropApiCallId(responseJson)
            break;
          // RESTART CROP
          case this.restartCropApiCallId:
            this.handleRestartCropApiCallId(responseJson)
            break;
          case this.fetchFilteredActivitiesApiCallId: 
            this.handleFetchFilteredActivitiesApiResponse(responseJson)
            break;
        }
      }
    }
    // Customizable Area End
  }

  handleEditActivity = (id: string,land_detail_id:string | undefined,crop_id:string | string [] | undefined, selectedActivityKey:string) => {
    this.handleCloseViewCropModal()
    this.props.handleEditActivity(id,land_detail_id,crop_id, selectedActivityKey)
  }

  handleOpenEditCropModal = (event:React.MouseEvent<SVGSVGElement> , selectedCropId: string) => {
    this.setState({ cropDetailsDataLoading: false, cropDetailsData: null})
    this.props.handleOpenEditCropModal(event, selectedCropId)
  }

  deleteActivity = (id: string, selectedActivityKey:string) => {
    this.props.deleteActivity(id, selectedActivityKey)
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.fetchCurrentLocation();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    // Customizable Area Start
    if (prevProps.userAccountId !== this.props.userAccountId) {
      this.resetCropFormValue()
    }
    if (prevProps.errorPopup !== this.props.errorPopup && this.props.errorPopup === "Activity Deleted Successfully." && this.state.sowingYear) {
      this.filterActivitiesData(this.state.sowingYear)
    }
    if (((prevProps.isAddCropModalOpen !== this.props.isAddCropModalOpen) && this.props.isAddCropModalOpen)
      || ((prevProps.isEditCropModalOpen !== this.props.isEditCropModalOpen) && this.props.isEditCropModalOpen)
    ) {
      this.resetCropFormValue()

      // select_farm - TODO
      this.fetchFarmListData()
      // crop_name
      this.fetchCropNameData()
      // crop_type
      this.fetchCropTypeData()
      // crop_variety
      this.fetchCropVarietyData()
      // seed_source
      this.fetchSeedSourceData()
      // bio_agent_name
      this.fetchBioAgentNameData()
      // crop_area_unit
      this.fetchCropAreaUnitData()
      // quantity_of_seed_unit
      this.fetchQuantityOfSeedUnitData()
      // bio_agent_quntity_unit
      this.fetchBioAgentQuntityUnitData()
      if(!this.props.isAddCropModalOpen) this.setState({ cropDetailsDataLoading: true})
    }
    // WHEN EDIT MODAL OPENS
    if (this.props.isEditCropModalOpen && this.state.isDropdownAPIResponded === 3 && !this.state.cropDetailsData){
      // FETCH CROP DETAILS BY ID
      this.fetchCropDetailsById()

    }
    // WHEN VIEW MODAL OPENS
    if ((prevProps.isViewCropModalOpen !== this.props.isViewCropModalOpen) && this.props.isViewCropModalOpen) {
      // FETCH CROP DETAILS BY ID
      this.fetchCropDetailsById()
    }
    if (this.props.isViewCropModalOpen && ((prevState.sowingYear !== this.state.sowingYear) && this.state.sowingYear)) {
      this.filterActivitiesData(this.state.sowingYear)
    }

    // Customizable Area End
  }

  // Function's for Share in Chat 
  handleShareLinkButton =(id: string,land_detail_id:string | undefined,crop_id:string | string [] | undefined, selectedActivityKey:string) => {
    let selectedActivityKeyValue = `${selectedActivityKey}s`; 
    if(selectedActivityKey === "activity_pest_management"){
      selectedActivityKeyValue = "pest_management_activities";
    }
    const shareLink = `${configJSON.farmDairyShareLink}${selectedActivityKeyValue}/${id}`
    this.setState({
        isShare: true,
        linkShare: shareLink,
        messageHeading: selectedActivityKeyValue
    })
  
  }

  handleShareLink = () => {
    this.setState({
      isShare: !this.state.isShare
    })
  }

  // API CALLS HANDLE FUNCTION STARTS 
  handleFetchFarmListDataApiCallId = (response: ISelectFarmDataResponse) => {
    if (response && response.data && response.data.length) {
      this.setState({ farmNamesList: response.data })
    } else {
      this.setState({ farmNamesList: [] })
    }
  }
  handleFetchCropNameDataApiCallId = (response: ICropName[] | null | undefined) => {
    if (response && response.length) {
      this.setState({ cropNamesList: response, filteredCropNamesList: response })
    } else {
      this.setState({ cropNamesList: [], filteredCropNamesList: [] })
    }
  }
  handlefetchCropTypeDataApiCallId = (response: IDropdownData[] | null | undefined) => {
    if (response && response.length) {
      this.setState({ cropTypesList: response })
    } else {
      this.setState({ cropTypesList: [] })
    }
  }
  handleFetchCropVarietyDataApiCallId = (response: IDropdownData[] | null | undefined) => {
    if (response && response.length) {
      this.setState({ cropVarietyList: response })
    } else {
      this.setState({ cropVarietyList: [] })
    }
  }
  handleFetchSeedSourceDataApiCallId = (response: IDropdownData[] | null | undefined) => {
    if (response && response.length) {
      this.setState({ seedSourcesList: response })
    } else {
      this.setState({ seedSourcesList: [] })
    }
  }
  handleFetchBioAgentNameDataApiCallId = (response: IDropdownData[] | null | undefined) => {
    if (response && response.length) {
      this.setState({ BioAgentNamesList: response, filteredBioAgentNamesList: response })
    } else {
      this.setState({ BioAgentNamesList: [], filteredBioAgentNamesList: [] })
    }
    this.setState( (prevState) => ({ isDropdownAPIResponded: prevState.isDropdownAPIResponded + 1 }))
  }
  handleFetchCropAreaUnitDataApiCallId = (response: IUnitDropdownResponse | null | undefined) => {
    if (response && response.data.length > 0) {
      this.setState({
        unitOfCropAreaList: response.data,
        initialCropFormValues: {
          ...this.state.initialCropFormValues,
          crop_area_unit: defaultQuanitityUnit(response.data, "acre").unitId
        }
      })
    } else {
      this.setState({ unitOfCropAreaList: [] })
    }
    this.setState( (prevState) => ({ isDropdownAPIResponded: prevState.isDropdownAPIResponded + 1 }))
  }
  handleFetchQuantityOfSeedUnitDataApiCallId = (response: IUnitDropdownResponse | null | undefined) => {
    if (response && response.data.length > 0) {
      this.setState({ unitOfSeedQuantityList: response.data,
        initialCropFormValues: {
          ...this.state.initialCropFormValues,
          quantity_of_seed_unit: defaultQuanitityUnit(response.data, "KG").unitId
        }
      })
    } else {
      this.setState({ unitOfSeedQuantityList: [] })
    }
    this.setState( (prevState) => ({ isDropdownAPIResponded: prevState.isDropdownAPIResponded + 1 }))
  }
  handleFetchBioAgentQuntityUnitDataApiCallId = (response: IUnitDropdownResponse | null | undefined) => {
    if (response && response.data.length > 0) {
      this.setState({ unitOfBioAgentList: response.data,
        initialCropFormValues: {
          ...this.state.initialCropFormValues,
          bio_agent_quntity_unit: defaultQuanitityUnit(response.data, "KG").unitId
        }
      })      
    } else {
      this.setState({ unitOfBioAgentList: [] })
    }
  }

  handleCreateCropApiCallId = (response:ICreateCropResponse)=>{    
    if(response && response.data && !response.errors){
      this.setState({
        createCropLoading: false,
        modalTitle: "Success",
        isModalOpen: true,
        modalMessage: "Crop created successfully",
      })    
      this.closeAddEditCropModal()        
      this.props.successAddCropCallback()
    }else {
      if (response && response.errors && response.errors.length > 0){      
        this.setState({
          modalTitle: "Error",
          isModalOpen: true,
          modalMessage: typeof(response.errors[0]) === "string" ? response.errors[0] : "Something went wrong",
          createCropLoading:false
        })
      }
    }
  }
  handleUpdateCropApiCallId = (response:ICreateCropResponse)=>{    
    if(response && response.data && !response.errors){
      this.setState({
        createCropLoading:false,
        modalTitle: "Success",
        isModalOpen: true,
        modalMessage: "Crop updated successfully",
      })    
      this.closeAddEditCropModal()
      this.props.successAddCropCallback()
    }else {
      if (response && response.errors && response.errors.length > 0){      
        this.setState({
          modalTitle: "Error",
          isModalOpen: true,
          modalMessage: typeof(response.errors[0]) === "string" ? response.errors[0] : "Something went wrong",
          createCropLoading:false
        })
      }
    }
  }
  handleDeleteCropApiCallId = (response: IDeleteCropResponse) => {    
    if(response && response.data && response.data.message){
      this.setState({deleteCropLoading:false,
        modalTitle: "Success",
        isModalOpen: true,
        modalMessage: "Crop deleted successfully",
      })

      this.handleCloseDeleteCropModal()
      this.props.handleCloseViewCropModal()
      this.props.successAddCropCallback()
    }else{
      this.handleCloseDeleteCropModal()
      if (response && response.errors && response.errors.length > 0) {
        this.setState({
          modalTitle: "Error",
          isModalOpen: true,
          modalMessage: typeof(response.errors[0]) === "string" ? response.errors[0] : "Something went wrong",
          deleteCropLoading: false
        })
      }
    }
  }
  handleFetchCropDetailsByIdApiCallId = (response:ICropDetailResponse) =>{    
    if(response && response.data){
      let crop_info = response.data?.attributes?.crop_name_id
      let sowing_date = response.data.attributes.sowing_date
      this.calculateEnabledDate(converStringToDate(sowing_date), crop_info?.crop_days.toString(), crop_info.name, false)
      this.setState({
        cropDetailsDataLoading: false,
        cropDetailsData: response.data, 
        sowingYear: new Date(response.data?.attributes.sowing_date).getFullYear()
      })
      if (this.props.isEditCropModalOpen) this.setInitialCropValues(response.data)
      
    } else {
      this.setState({
        cropDetailsDataLoading: false,
        cropDetailsData: null
      })
    }          
  }
  handleEndCropApiCallId = (response: IEndCropResponse | null | undefined) => {
    if (response && response.data && response.data.message) {
      this.setState({
        endCropLoading: false,
        modalTitle: "Success",
        isModalOpen: true,
        modalMessage: response.data.message,
      })
      this.handleCloseEndCropModal()
      this.props.successAddCropCallback()
      this.fetchCropDetailsById()
      this.handleVillageFilterClearUT()
    } else if (response && response.errors) {
      this.setState({
        endCropLoading: false,
        modalTitle: "Error",
        isModalOpen: true,
        modalMessage: response.errors,
      })
      this.handleCloseEndCropModal()
    } else {
      this.setState({
        endCropLoading: false
      })
    }
  }
  handleRestartCropApiCallId = (response: IEndCropResponse | null | undefined) => {
    if (response && response.data && response.data.message) {
      this.setState({
        restartCropLoading: false,
        modalTitle: "Success",
        isModalOpen: true,
        modalMessage: response.data.message,
      })
      this.handleCloseRestartCropModal()
      this.props.successAddCropCallback()
      this.fetchCropDetailsById()
      this.handleVillageFilterClearUT()
    } else if (response && response.errors) {
      this.setState({
        restartCropLoading: false,
        modalTitle: "Error",
        isModalOpen: true,
        modalMessage: response.errors,
      })
      this.handleCloseEndCropModal()
    } else {
      this.setState({
        restartCropLoading: false
      })
    }
  }
  

  handleFetchFilteredActivitiesApiResponse = (response: IFilteredActivityResponse[] | {errors: string}) => {
    this.setState({ loader: false });
    if(response instanceof Array) {
      const sortedData = response?.filter((item: IFilteredActivityResponse) => item != null).slice().sort(this.compareByType);
      const sortOrder = this.state.savedSortAscendingUT ? sortedData
        : this.state.savedSortDescendingUT ? sortedData.reverse()
          : response.filter((item) => item != null);
      this.setState({ filteredActivityData: response.length ? sortOrder : [] });
    }else {
      this.setState({ filteredActivityData: [] });
    }
  }
    // API CALLS HANDLE FUNCTION ENDS

  getSelectedVarietiesArray = (variety_ids: IDropdownData[]) => {
    let result = []
    result = this.state.cropVarietyList.length > 0 ? this.state.cropVarietyList.filter(mainObj =>
      variety_ids.some(secondObj => secondObj.id === mainObj.id)
    ) : [];
    return result
  }

  setInitialCropValues = (response: ICropDetails) => {
    const {
      land_detail_id,
      sowing_date,
      crop_name_id, crop_type_id,
      total_crop_area,
      variety_ids,
      seed_source_id,
      seed_cost,
      labor_cost,
      seed_quantity,
      number_of_packets,
      non_gmo_seed,
      performed_seed_treatment,
      bio_agent_id,
      bio_agent_cost,
      bio_agent_quantity,
      unit_of_bio_agent_id,
      unit_of_area_id,
      unit_of_seed_quantity_id,
      crop_mapping,
      latitude_longitude
    } = response.attributes

    const sowingDateMoment = moment(sowing_date).isValid()
    const sowingDateValue = sowingDateMoment ? moment(sowing_date).toString() : null
    const seedCostValue = Number(seed_cost)
    const laborCostValue = Number(labor_cost)
    const seedQuantityValue = Number(seed_quantity)

    const nonGmoSeedValue = non_gmo_seed ? "true" : "false"
    const performedSeedTreatmentValue = performed_seed_treatment ? "true" : "false"

    const newInitialValues = {
      select_farm: truthyValue(land_detail_id && land_detail_id.id),
      sowing_date: sowingDateValue,
      crop_name: truthyValue(crop_name_id && crop_name_id.id),
      crop_type: truthyValue(crop_type_id && crop_type_id.id),
      crop_area_value: truthyValue(total_crop_area),
      crop_area_unit: this.state.unitOfCropAreaList.length ? truthyValue(unit_of_area_id && unit_of_area_id.id) : "",
      crop_variety: this.getSelectedVarietiesArray(variety_ids),
      number_of_packets: truthyValue(number_of_packets),
      quantity_of_seed_value: seedQuantityValue,
      quantity_of_seed_unit: this.state.unitOfSeedQuantityList.length ? truthyValue(unit_of_seed_quantity_id && unit_of_seed_quantity_id.id) : "",
      seed_source: truthyValue(seed_source_id && seed_source_id.id),
      seed_cost: seedCostValue,
      labor_cost: laborCostValue,
      non_gmo_seed: nonGmoSeedValue,
      performed_seed_treatment: performedSeedTreatmentValue,
      bio_agent_name: truthyValue(bio_agent_id && bio_agent_id.id),
      bio_agent_cost: truthyValue(bio_agent_cost),
      bio_agent_quntity: truthyValue(bio_agent_quantity),
      bio_agent_quntity_unit: unit_of_bio_agent_id && unit_of_bio_agent_id.id ? truthyValue(unit_of_bio_agent_id.id) : defaultQuanitityUnit(this.state.unitOfBioAgentList, "KG").unitId,
    }
    const lat_long :string= latitude_longitude as string        
    this.setState({ 
      initialCropFormValues: newInitialValues,
      // SET CROP MAPPING
      farmMappingURL: crop_mapping.url ?? null,
      coordinates: this.setCoordinates(lat_long),
      tempCoordinates: this.setCoordinates(lat_long),

    })
  }

  setCoordinates = (latitude_longitude: string) => {
    if (latitude_longitude && String(latitude_longitude).length > 0) {
      const latLongString = JSON.parse(latitude_longitude);
      // convert string to array of object
      let coordinatesArrayTemp: ILatitudeLongitude[] = [];
      for (let i = 0; i < Object.keys(latLongString as ILatitudeLongitude).length; i++) {
        coordinatesArrayTemp.push(latLongString[i]);
      }
      // re-change key names of array
      let CoordinatesArrFinal = [];
      if (coordinatesArrayTemp.length > 0) {
        // Loop through each object in the original array and modify the keys
        for (const coordinate of coordinatesArrayTemp) {
          const modifiedCoord = {
            "lat": coordinate.latitude,
            "lng": coordinate.longitude
          };
          CoordinatesArrFinal.push(modifiedCoord);
        }
      }
      if (CoordinatesArrFinal.length > 0) {
        const centroidTemp = getPolygonCentroid(CoordinatesArrFinal)
        this.setState({ markerDefault: centroidTemp })
      }

      return CoordinatesArrFinal;
    } else {
      return []
    }
  }

  resetCropFormValue() {    
    this.setState({
      initialCropFormValues: this.initialFormValues,
      CropFormErrors: this.initialErrors,
      // RESET CROP MAPPINGS
      openFarmMapping: false,
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      tempCoordinates: [],
    })
  }

  isCropNameCotton = () => {
    const { crop_name } = this.state.initialCropFormValues
    const cottonCrop = this.state.cropNamesList.find((each) => String(each.id) === String(crop_name))
    if (cottonCrop && cottonCrop.name === "Cotton") {
      return true
    } else {
      return false
    }
  }
  isPerformedSeedTreatment = () => {
    return this.state.initialCropFormValues.performed_seed_treatment === 'true' ? true : false
  }
  triggerCropModal = () => {
    return this.props.isAddCropModalOpen || this.props.isEditCropModalOpen
  }
  renderCropModalTitle = () => {
    let modalTitle = ""
    if (this.props.isAddCropModalOpen && !this.props.isEditCropModalOpen) {
      modalTitle = "Add Crop"
    } else if (this.props.isEditCropModalOpen && this.state.cropDetailsData) {
      modalTitle = this.state.cropDetailsData.attributes.crop_name_id.name ? this.state.cropDetailsData.attributes.crop_name_id.name: "Edit Crop"
    }
    return modalTitle
  }
  renderCropModalSubmitButtonText = () => {
    if (this.props.isAddCropModalOpen && !this.props.isEditCropModalOpen) {
      return "Add"
    } else {
      return "Update"
    }
  }

  returnCropVarietyPlaceholder = () => {
    if (this.state.initialCropFormValues.crop_variety.length > 0 ) {
      return ""
    } else {
      return "Enter Crop Variety"
    }
  }

  debouncedCropName = debounce((value: string) => this.filterCropNamesMenus(value), 500);
  debouncedBioAgentName = debounce((value: string) => this.filterBioAgentMenus(value), 500);
  resetSearchMenus = () => {
    this.setState({ searchMenuItem: "" }, () => {
      this.debouncedCropName("")
      this.debouncedBioAgentName("")
    })
  }

  filterCropNamesMenus = (searchQuery: string) => {
    this.setState({ searchMenuItem: searchQuery.trim() })
    const query = searchQuery?.toLowerCase() || "";
    const filteredArray = query ? this.state.cropNamesList.filter((value) => value.name.toLowerCase().includes(query)) : this.state.cropNamesList;
    this.setState({ filteredCropNamesList: filteredArray });
  }

  filterBioAgentMenus = (searchQuery: string) => {
    this.setState({ searchMenuItem: searchQuery.trim() })
    const query = searchQuery?.toLowerCase() || "";
    const filteredArray = query ? this.state.BioAgentNamesList.filter((value) => value.name.toLowerCase().includes(query)) : this.state.BioAgentNamesList;
    this.setState({ filteredBioAgentNamesList: filteredArray });
  }

  closeAddEditCropModal =()=>{
    this.props.handleCloseAddCropModal()
    this.props.handleCloseEditCropModal()
    this.resetCropFormValue()
    // reset 
    this.setState({
      cropDetailsData: null,
      cropDetailsDataLoading: false
    })
  }
  // OPEN ADD FARM MODAL
  openAddFarm = () => {
    this.props.handleCloseViewCropModal()
    this.props.OpenAddFarmModalfn()
  }
  // OPEN ADD ACTIVITY MODAL
  openAddActivity = () => {
    this.props.handleCloseViewCropModal()
    this.props.OpenAddActivityModalfn()
  }

  toggleFarmMappingModal=()=>{
    this.setState({openFarmMapping:false})
  }

  onSaveFarmMapping = (
    latlngHash: string,
    file: File,
    url: string,
    savedCoordinates: LatLng[]
  ) => {
    this.setState({
      latlngHash: latlngHash,
      openFarmMapping: false,
      farmMappingURL: url,
      farmMappingFile: file,
      tempCoordinates: savedCoordinates
    })
  };

  fetchCurrentLocation = () => {
    const successResult = (pos: Position) => {
      this.setState({currentLocation:{
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }}, () => {          
          localStorage.setItem('userLocationCropMapping', JSON.stringify(this.state.currentLocation))
        })
    };
    const errorResult = (error:GeolocationPositionError) => {
      this.setState({currentLocation:null})
    };
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successResult, errorResult);
    }
  };


  // API CALLS STARTS
  fetchFarmListData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      "type": "admin_user"
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmListDataApiCallId = reqMessage.messageId;

    const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.userAccountId}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropNameData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropNameDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchCropNameDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropTypeData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropTypeDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchCropTypeDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropVarietyData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropVarietyDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchCropVarietyDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchSeedSourceData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchSeedSourceDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchSeedSourceDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchBioAgentNameData = () => {    
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchBioAgentNameDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchBioAgentNameDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropAreaUnitData = () => {    
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropAreaUnitDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchCropAreaUnitDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchQuantityOfSeedUnitData= () => {    
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchQuantityOfSeedUnitDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchQuantityOfSeedUnitDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchBioAgentQuntityUnitData= () => {    
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchBioAgentQuntityUnitDataApiCallId = reqMessage.messageId;

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.fetchBioAgentQuntityUnitDataAPiEndPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  appendCropVariety = (existingFormData:FormData) =>{
    const formData = existingFormData 

    const {crop_variety} = this.state.initialCropFormValues
    if(this.isCropNameCotton()){    
      crop_variety.forEach((value, index) => {
      formData.append(`crops_varieties_attributes[${index}][variety_id]`, String(value.id));
    });

  }
    return formData;
  }

  createCrop = (addBodyData:FormData) => {
    this.setState({ createCropLoading: true })
    
    const headers = {
      token: this.state.authToken,
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createCropApiCallId = reqMessage.messageId;

    // ENDPOINT
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.cropsCRUDApiEndPoint);
    // HEADER
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    // BODY
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), addBodyData);
    // METHOD
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
    // RUN
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  updateCrop = (updateCropBody:FormData) =>{
    const cropId = this.props.selectedCropId 
    const accountId = this.props.userAccountId
    if (!cropId && !accountId) return

    this.setState({ createCropLoading: true })
    
    const headers = {
      token: this.state.authToken,
    };
    const endPoint = `${configJSON.cropsCRUDApiEndPoint}/${cropId}?account_id=${accountId}`
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // API CALL ID   
    this.updateCropApiCallId = reqMessage.messageId;
    // ENDPOINT
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    // HEADER
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    // BODY
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), updateCropBody);
    // METHOD
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH_METHOD_TYPE);
    // RUN
    runEngine.sendMessage(reqMessage.id, reqMessage);
  } 

  deleteCrop = () => {
    const cropId = this.props.selectedCropId 
    if (!cropId ) return

    this.setState({deleteCropLoading:true})
    const headers = {
      token: this.state.authToken,
    };
    
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.cropsCRUDApiEndPoint}/${cropId}`
    
    // API CALL ID   
    this.deleteCropApiCallId = reqMessage.messageId;
    // ENDPOINT
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    // HEADER
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    // METHOD
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.DELETE_METHOD_TYPE);
    // RUN
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropDetailsById = () => {
    const cropId = this.props.selectedCropId
    const currentCropYear = this.props.currentCropYear    
    if (!cropId && !currentCropYear) return

    this.setState({ cropDetailsDataLoading: true, cropDetailsData: null, isDropdownAPIResponded: 0 })
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropDetailsByIdApiCallId = reqMessage.messageId;
    const endPoint = `${configJSON.cropsCRUDApiEndPoint}/${cropId}`

    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
    
  }

  endCrop = () => {
    const cropId = this.props.selectedCropId
    const userAccountId = this.props.userAccountId

    this.setState({ endCropLoading: true })

    const headers = {
      token: this.state.authToken,
      type: "admin_user"
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.endCropApiCallId = reqMessage.messageId;

    // ENDPOINT
    const endpoint = `${configJSON.EndRestartCropApiEndPoint}/${cropId}/end_crop?user_id=${userAccountId}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    // HEADER
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    // METHOD
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
    // RUN
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  restartCrop = () => {
    const cropId = this.props.selectedCropId
    const userAccountId = this.props.userAccountId

    this.setState({ restartCropLoading: true })

    const headers = {
      token: this.state.authToken,
      type: "admin_user"
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.restartCropApiCallId = reqMessage.messageId;

    // ENDPOINT
    const endpoint = `${configJSON.EndRestartCropApiEndPoint}/${cropId}/restart_crop?user_id=${userAccountId}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    // HEADER
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    // METHOD
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
    // RUN
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  filterActivitiesData = (year: number | null, selectedActivitys?: string) => {
    if(!year) return
    let activitesToPass = selectedActivitys ??  (this.state.selectedActivity ? this.state.selectedActivity : "")
    this.setState({ loader: true })
    const cropId = this.props.selectedCropId
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      "type": "admin_user"
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFilteredActivitiesApiCallId = reqMessage.messageId;

    const endpoint = `mobile_and_web/farm_dairy/common_activities?crop_id=${cropId}&year=${year}&user_id=${this.props.userAccountId}&activities=${activitesToPass}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }
  // API CALLS ENDS

  handleErrorModalClose = () => {
    this.setState({
      modalTitle: "",
      isModalOpen: false,
      modalMessage: "",
    })
  }
  isCropAdd = () =>{
    return this.props.isAddCropModalOpen && !this.props.isEditCropModalOpen
  }
  isCropUpdate = () =>{
    return this.props.isEditCropModalOpen && !this.props.isAddCropModalOpen
  }

  checkAllValidations = () => {
    let allValueValidated = false

    const isErrorInSelectFarm = this.validateSelectFarmValue().isError
    const isErrorInSowingDate = this.validateSowingDateValue().isError 
    const isErrorInCropName = this.validateCropNameValue().isError
    const isErrorInCropType = this.validateCropTypeValue().isError
    const isErrorInCropArea = this.validateCropAreaValue().isError
    const isErrorInCropAreaUnitValue = this.validateCropAreaUnitValue().isError
    const isErrorInCropVarietyValue = this.validateCropVarietyValue().isError    
    const isErrorInNumberOfPackets = this.validateNumberOfPacketsValue().isError
    const isErrorInQuantityOfSeedValue = this.validateQuantityOfSeedValue().isError      
    const isErrorInSeedSource = this.validateSeedSourceValue().isError
    const isErrorInSeedCostValue = this.validateSeedCostValue().isError
    const isErrorInLaborCostValue = this.validateLaborCostValue().isError
    // RADIO BUTTONS
    const isErrorPerformedSeedTreatment = this.validatePerformedSeedTreatmentValue().isError
    const isErrorInNonGmoSeed = this.validateNonGmoSeedValue().isError
    // BIO AGENTS
    const isErrorInBioAgentName = this.validateBioAgentNameValue().isError
    const isErrorInBioAgentCost = this.validateBioAgentCostValue().isError  
    // const isErrorInBioAgentQuntityValue = this.validateBioAgentQuntityValue().isError  
    // const isErrorInBioAgentQuntityUnit = this.validateBioAgentQuntityUnitValue().isError  
    
    if (!isErrorInSelectFarm && !isErrorInCropName && !isErrorInCropArea && !isErrorInCropVarietyValue &&
      !isErrorInCropType && !isErrorInSowingDate &&
      !isErrorInSeedSource && !isErrorInBioAgentName &&
      !isErrorPerformedSeedTreatment && !isErrorInNonGmoSeed &&
      !isErrorInNumberOfPackets && !isErrorInSeedCostValue && !isErrorInLaborCostValue && !isErrorInBioAgentCost &&
      !isErrorInCropAreaUnitValue && !isErrorInQuantityOfSeedValue) {
      allValueValidated = true
    }

    return allValueValidated
  }

  // CROP FORM SUBMIT FUNCTION

  returnFormData = () =>{
    const { initialCropFormValues } = this.state
    const sowingDate = moment(initialCropFormValues.sowing_date).format("DD/MM/YYYY");    

    let reqFormdata = new FormData();
    reqFormdata.append("land_detail_id", initialCropFormValues.select_farm as string);
    reqFormdata.append("sowing_date", sowingDate);
    reqFormdata.append("crop_name_id", initialCropFormValues.crop_name);
    reqFormdata.append("crop_type_id", initialCropFormValues.crop_type);
    reqFormdata.append("total_crop_area", initialCropFormValues.crop_area_value as string);    
    reqFormdata.append("unit_of_area_id", initialCropFormValues.crop_area_unit);
    reqFormdata.append("seed_source_id", initialCropFormValues.seed_source);
    reqFormdata.append("seed_cost", initialCropFormValues.seed_cost as string);
    reqFormdata.append("labor_cost", initialCropFormValues.labor_cost as string);
    reqFormdata.append("performed_seed_treatment", initialCropFormValues.performed_seed_treatment as string);
    
    if (this.isCropNameCotton()) {
      reqFormdata.append("non_gmo_seed", initialCropFormValues.non_gmo_seed as string);
      reqFormdata.append("number_of_packets", initialCropFormValues.number_of_packets as string);      
    } else {      
      reqFormdata.append("seed_quantity", initialCropFormValues.quantity_of_seed_value as string);
      reqFormdata.append("unit_of_seed_quantity_id", initialCropFormValues.quantity_of_seed_unit);
    }
    if (this.isPerformedSeedTreatment()) {
      reqFormdata.append("bio_agent_id", initialCropFormValues.bio_agent_name);
      reqFormdata.append("bio_agent_cost", initialCropFormValues.bio_agent_cost as string);
      if(initialCropFormValues.bio_agent_quntity && initialCropFormValues.bio_agent_quntity_unit){
        reqFormdata.append("bio_agent_quantity", initialCropFormValues.bio_agent_quntity);
        reqFormdata.append("unit_of_bio_agent_id", initialCropFormValues.bio_agent_quntity_unit);
      }      
    }
    if (this.state.farmMappingFile) {
      reqFormdata.append("crop_mapping", this.state.farmMappingFile);
    }
    if (this.state.latlngHash) {
      reqFormdata.append("latitude_longitude", this.state.latlngHash);
    }
    const formBody = this.appendCropVariety(reqFormdata)
    return formBody
  }

  handleSubmitCropForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValidated = this.checkAllValidations()
    if (isFormValidated) {
      const formBody = this.returnFormData()
      if (this.isCropAdd()) {
        this.createCrop(formBody)
      } else if (this.isCropUpdate()) {
        this.updateCrop(formBody)
      }
    }else {
      this.setState({
        modalTitle: "Error",
        isModalOpen: true,
        modalMessage: "Form data is Incomplete. Please fill all the fields correctly."
      })
    }
  }

  // SET FIELD ERROR STATE
  setFieldError = (keyName:string,errorMsg:string) => {
    this.setState((prevState) => ({
      ...prevState,
      CropFormErrors: {
        ...prevState.CropFormErrors,
        [keyName]: {
          errorMsg
        }
      },
    }));
  }

  // SELECT FARM
  validateSelectFarmValue = () => {
    const { initialCropFormValues } = this.state    
    let errorMessage = ""

    if (isValueFalsy(initialCropFormValues.select_farm)) {
      errorMessage = "Please select Farm"
    }
    this.setFieldError('select_farm',errorMessage)
    return { errorMessage, isError: Boolean(errorMessage)}
  }
  handleSelectFarmValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateSelectFarmValue()
    }
    )
  }

  // CROP NAME
  validateCropNameValue = () => {
    const { initialCropFormValues } = this.state    
    let errorMessage = ""

    if (isValueFalsy(initialCropFormValues.crop_name)) {
      errorMessage = "Please select Crop"
    }
    this.setFieldError('crop_name',errorMessage)
    return { errorMessage, isError: Boolean(errorMessage)}
  }
  handleCropNameValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value,
        // reset some fields value 
        non_gmo_seed: "true",
        number_of_packets: "",
        quantity_of_seed_value: "",
        crop_variety: []
      },
      CropFormErrors: {
        ...prevState.CropFormErrors,
        // reset some fields errors
        non_gmo_seed: this.initialErrorObject,
        number_of_packets: this.initialErrorObject,
        quantity_of_seed_value: this.initialErrorObject,
        crop_variety: this.initialErrorObject,
      }
    }), () => {
      this.validateCropNameValue()
    }
    )
  }

  // SOWING DATE
  validateSowingDateValue = () => {
    const { sowing_date } = this.state.initialCropFormValues
    let errorMessage = ""
    const today = moment().endOf('day');
    const minDate = moment().subtract(361, 'days').startOf('day');

    if (isValueFalsy(sowing_date) || !moment(sowing_date).isValid()) {
      errorMessage = "Please enter valid Sowing Date"
    }
    
    if (moment(sowing_date).isBefore(minDate) ) {
      errorMessage = "Please enter minimum Sowing Date"
    }
    if(moment(sowing_date).isAfter(today)){
      errorMessage = "Date can not be more than Today's Date"

    }
    this.setFieldError('sowing_date', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleSowingDateValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateSowingDateValue()
    }
    )
  }

  // CROP TYPE
  validateCropTypeValue = () => {
    const { crop_type } = this.state.initialCropFormValues
    let errorMessage = ""

    if (isValueFalsy(crop_type)) {
      errorMessage = "Please select Crop Type"
    }
    this.setFieldError('crop_type', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleCropTypeValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateCropTypeValue()
    }
    )
  }
  // CROP AREA
  validateCropAreaValue = () => {
    const { crop_area_value } = this.state.initialCropFormValues
    let errorMessage = ""

    // Convert the value to a floating-point number
    const decimalValue = parseFloat(crop_area_value as string);

    if (isValueFalsy(crop_area_value)) {
      errorMessage = "Please enter Crop Area Value"
    }
    if (isNaN(decimalValue) && !isValueFalsy(crop_area_value)) {
      errorMessage = "Please enter valid Value"
    }
    // if (decimalValue < 0.5) {
    //   errorMessage = "Minimum allowed value is 0.5"
    // }
    // if (decimalValue > 50) {
    //   errorMessage = "Maximum allowed value is 50"
    // }

    this.setFieldError('crop_area_value', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleCropAreaValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateCropAreaValue()
    })
  }

  // CROP AREA - UNIT
  validateCropAreaUnitValue = () => {
    const { crop_area_unit } = this.state.initialCropFormValues
    let errorMessage = ""

    if (isValueFalsy(crop_area_unit)) {
      errorMessage = "Please select Crop Area Unit"
    }
    this.setFieldError('crop_area_unit', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleCropAreaUnitValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateCropAreaUnitValue()
    })
  }

  // CROP VARRIETY
  validateCropVarietyValue = () => {
    const { crop_variety } = this.state.initialCropFormValues
    let errorMessage = ""
    if (this.isCropNameCotton()) {
      if (crop_variety.length <= 0 ) {
        errorMessage = "Please select at least one Variety"
      }
    }
    this.setFieldError('crop_variety', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleCropVarietyValue = (newVal: IDropdownData[]) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        crop_variety: newVal
      },
    }), () => {
      this.validateCropVarietyValue()
    })
  }
  
  // NUMBER OF PACKETS
  validateNumberOfPacketsValue = () => {
    const { number_of_packets } = this.state.initialCropFormValues
    let errorMessage = ""

    // Convert the value to a floating-point number
    const decimalValue = parseFloat(number_of_packets as string);
    if (this.isCropNameCotton()) {
      if (isValueFalsy(number_of_packets)) {
        errorMessage = "Please enter Number Of Packets"
      }
      if (isNaN(decimalValue) && !isValueFalsy(number_of_packets)) {
        errorMessage = "Please enter valid Value"
      }
      if (decimalValue < 1) {
        errorMessage = "Minimum allowed value is 1"
      }
      if (decimalValue > 100) {
        errorMessage = "Maximum allowed value is 100"
      }
    }
    this.setFieldError('number_of_packets', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleNumberOfPacketsValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateNumberOfPacketsValue()
    })
  }

  // QUANTITY OF SEED
  validateQuantityOfSeedValue = () => {
    const { quantity_of_seed_value } = this.state.initialCropFormValues
    let errorMessage = ""

    // Convert the value to a floating-point number
    const decimalValue = parseFloat(quantity_of_seed_value as string);
    if (!this.isCropNameCotton()) {
      
      if (isValueFalsy(quantity_of_seed_value)) {
        errorMessage = "Please enter Quantity of Seed"
      }
      if (isNaN(decimalValue) && !isValueFalsy(quantity_of_seed_value)) {
        errorMessage = "Please enter valid Value"
      }
      // if (decimalValue < 0.1) {
      //   errorMessage = "Minimum allowed value is 0.1"
      // }
      // if (decimalValue > 100) {
      //   errorMessage = "Maximum allowed value is 100"
      // }
    }
    this.setFieldError('quantity_of_seed_value', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleQuantityOfSeedValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateQuantityOfSeedValue()
    })
  }

  // QUANTITY OF SEED - UNIT
  validateQuantityOfSeedUnit = () => {
    const { quantity_of_seed_unit } = this.state.initialCropFormValues
    let errorMessage = ""
    if (!this.isCropNameCotton()) {
      if (isValueFalsy(quantity_of_seed_unit)) {
        errorMessage = "Please select Unit"
      }
    }
    this.setFieldError('quantity_of_seed_unit', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleQuantityOfSeedUnit = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateQuantityOfSeedUnit()
    })
  }

  // SEED SOURCE
  validateSeedSourceValue = () => {
    const { seed_source } = this.state.initialCropFormValues
    let errorMessage = ""

    if (isValueFalsy(seed_source)) {
      errorMessage = "Please select Seed Source"
    }

    this.setFieldError('seed_source', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleSeedSourceValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateSeedSourceValue()
    })
  }

  // SEED COST
  validateSeedCostValue = () => {
    const { seed_cost } = this.state.initialCropFormValues
    let errorMessage = ""

    // Convert the value to an integer
    const floatValue = parseFloat(String(seed_cost));

    if (isValueFalsy(seed_cost)) {
      errorMessage = "Please enter Seed Cost"
    }
    if (isNaN(floatValue) && !isValueFalsy(seed_cost)) {
      errorMessage = "Please enter valid Value"
    }
    if (floatValue < 0) {
      errorMessage = "Minimum allowed value is 0"
    }
    if (floatValue > 50000) {
      errorMessage = "Maximum allowed value is 50000"
    }

    this.setFieldError('seed_cost', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleSeedCostValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateSeedCostValue()
    })
  }
  // LABOR COST
  validateLaborCostValue = () => {
    const { labor_cost } = this.state.initialCropFormValues
    let errorMessage = ""

    // Convert the value to an integer
    const floatValue = parseFloat(String(labor_cost));

    if (isValueFalsy(labor_cost)) {
      errorMessage = "Please enter Labor Cost"
    }
    if (isNaN(floatValue) && !isValueFalsy(labor_cost)) {
      errorMessage = "Please enter valid Value"
    }
    if (floatValue < 50) {
      errorMessage = "Minimum allowed value is 50"
    }
    if (floatValue > 50000) {
      errorMessage = "Maximum allowed value is 50000"
    }

    this.setFieldError('labor_cost', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleLaborCostValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateLaborCostValue()
    })
  }

  // PERFORMED SEED TREATMENT
  validatePerformedSeedTreatmentValue = () => {
    let errorMessage = ""
    this.setFieldError('performed_seed_treatment', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handlePerformedSeedTreatmentValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value,
        // reset some fields value and errors
        bio_agent_name: "",
        bio_agent_cost: "",
        bio_agent_quntity: "",
      },
      CropFormErrors: {
        ...prevState.CropFormErrors,
        // reset some fields errors        
        bio_agent_name: this.initialErrorObject,
        bio_agent_cost: this.initialErrorObject,
        bio_agent_quntity: this.initialErrorObject,
      }
    }), () => {
      this.validatePerformedSeedTreatmentValue()
    })
  }

  // NON GMO SEED
  validateNonGmoSeedValue = () => {
    const { non_gmo_seed } = this.state.initialCropFormValues
    let errorMessage = ""
    this.setFieldError('non_gmo_seed', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleNonGmoSeedValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value as string
      },
    }), () => {
      this.validateNonGmoSeedValue()
    })
  }

  // BIO AGENT NAME
  validateBioAgentNameValue = () => {
    const { bio_agent_name } = this.state.initialCropFormValues
    let errorMessage = ""
    if (this.isPerformedSeedTreatment()) {
      if (isValueFalsy(bio_agent_name)) {
        errorMessage = "Please select Bio Agent"
      }
    }
    this.setFieldError('bio_agent_name', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleBioAgentNameValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateBioAgentNameValue()
    })
  }

  // BIO AGENT COST
  validateBioAgentCostValue = () => {
    const { bio_agent_cost } = this.state.initialCropFormValues
    let errorMessage = ""

    // Convert the value to an integer
    const floatValue = parseFloat(String(bio_agent_cost));
    if (this.isPerformedSeedTreatment()) {

      if (isValueFalsy(bio_agent_cost)) {
        errorMessage = "Please enter Bio-agent Cost"
      }
      if (isNaN(floatValue) && !isValueFalsy(bio_agent_cost)) {
        errorMessage = "Please enter valid Value"
      }
      if (floatValue < 0) {
        errorMessage = "Minimum allowed value is 0"
      }
      if (floatValue > 100000) {
        errorMessage = "Maximum allowed value is 100000"
      }
    }
    this.setFieldError('bio_agent_cost', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleBioAgentCostValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateBioAgentCostValue()
    })
  }

  // BIO AGENT QUANTITY
  validateBioAgentQuntityValue = () => {      
    const { bio_agent_quntity_unit,bio_agent_quntity } = this.state.initialCropFormValues
    let errorMessage = ""
    // if (this.isPerformedSeedTreatment()) {
    //   if (isValueFalsy(bio_agent_quntity_unit) && !isValueFalsy(bio_agent_quntity)) {
    //     errorMessage = "Please enter Quantity Unit"
    //   } else if (!isValueFalsy(bio_agent_quntity_unit) && isValueFalsy(bio_agent_quntity)) {
    //     errorMessage = "Please enter Bio-agent Quantity"
    //   }
    // }
    this.setFieldError('bio_agent_quntity', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleBioAgentQuntityValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateBioAgentQuntityValue()
    })
  }

  // UNIT FOR BIO AGENT QUANTITY 
  validateBioAgentQuntityUnitValue = () => {    
    const { bio_agent_quntity,bio_agent_quntity_unit } = this.state.initialCropFormValues
    let errorMessage = ""
    // if (this.isPerformedSeedTreatment()) {
    //   if (isValueFalsy(bio_agent_quntity) && !isValueFalsy(bio_agent_quntity_unit)) {
    //     errorMessage = "Please enter Bio-agent Quantity"
    //   }
    // }
    this.setFieldError('bio_agent_quntity', errorMessage)
    return { errorMessage, isError: Boolean(errorMessage) }
  }
  handleBioAgentQuntityUnitValue = (keyName: string, value: unknown) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCropFormValues: {
        ...prevState.initialCropFormValues,
        [keyName]: value
      },
    }), () => {
      this.validateBioAgentQuntityUnitValue()
    })
  }

  // DELETE CROP MODAL
  handleOpenCropModal = () => {
    this.setState({
      isDeleteCropModalOpen: true
    })
  }

  handleCloseDeleteCropModal = () => {
    this.setState({
      isDeleteCropModalOpen: false,
      deleteCropLoading: false
    })
  }

  // END CROP MODAL-OPEN
  closeWarningModal = () => {
    this.setState({ openWarningModal: false})
  }
  // END CROP MODAL-CLOSE
  handleCloseEndCropModal = () => {
    this.setState({
      isEndCropModalOpen: false
    })
  }

  // RESTART CROP MODAL-OPEN
  handleOpenRestartCropModal = () => {
    this.setState({
      isRestartCropModalOpen: true
    })
  }
  // RESTART CROP MODAL-CLOSE
  handleCloseRestartCropModal = () => {
    this.setState({
      isRestartCropModalOpen: false
    })
  }

  // TABLE FILTER FUNCTIONS STARTS
  handleChangeAtoZUT = () => {
    this.setState({
      sortAscendingUT: !this.state.sortAscendingUT,
      sortDescendingUT: false
    });
  }
  handleChangeZtoAUT = () => {
    this.setState({
      sortDescendingUT: !this.state.sortDescendingUT,
      sortAscendingUT: false
    });
  }

  handleSubmitFilterUT = () => {
    const { selectedActivity, sortAscendingUT, sortDescendingUT, sowingYear} = this.state;
    const sortedData = [...this.state.filteredActivityData].sort(this.compareByType);
    this.setState({
      savedSortAscendingUT: this.state.sortAscendingUT,
      savedSortDescendingUT: this.state.sortDescendingUT,
      openTableFilterUT: false,
      
    });

    if (selectedActivity.length && !sortAscendingUT && !sortDescendingUT) {
      this.filterActivitiesData(sowingYear);
      this.setState({ hideBadgeUT: false})
    } else if (!selectedActivity.length && sortAscendingUT) {
      this.filterActivitiesData(sowingYear);
      this.setState({ filteredActivityData: sortedData, hideBadgeUT: false })
    } else if (selectedActivity.length && sortAscendingUT) {
      this.filterActivitiesData(sowingYear);
      this.setState({ filteredActivityData: sortedData, hideBadgeUT: false })
    } else if (!selectedActivity.length && sortDescendingUT) {
      this.filterActivitiesData(sowingYear);
      this.setState({ filteredActivityData: sortedData.reverse(), hideBadgeUT: false })
    } else if (selectedActivity.length && sortDescendingUT) {
      this.filterActivitiesData(sowingYear);
      this.setState({ filteredActivityData: sortedData.reverse(), hideBadgeUT: false })
    } else {
      this.filterActivitiesData(sowingYear);
      this.setState({ hideBadgeUT: true })
    }
  }

  compareByType = (activity1: IFilteredActivityResponse, activity2: IFilteredActivityResponse) => {
    const type1 = this.handleFilteredActivityName(activity1?.data.type)?.name;
    const type2 = this.handleFilteredActivityName(activity2?.data.type)?.name;

    if (!type1 || !type2) {
      return 0;
    }
    return (typeof type1 === 'string' && typeof type2 === 'string')
    ? type1.localeCompare(type2)
    : 0; 

  };

  handleCloseTableFilterPopupUT = () => {
    this.setState({
      openTableFilterUT: false,
      sortAscendingUT: this.state.savedSortAscendingUT,
      sortDescendingUT: this.state.savedSortDescendingUT,
    })
  }
  handleActivityChange = (event: any) => {
    const isChecked = event.target.checked;
    const activityName = event.target.name;

    if (isChecked) {
      this.setState({ selectedActivity: [...this.state.selectedActivity, activityName] });
    } else {
      this.setState({ selectedActivity: this.state.selectedActivity.filter(name => name !== activityName) });
    }
  }


  checkFilterErrorUT = () => {
    if (this.state.sortAscendingUT ||
      this.state.sortDescendingUT
    ) {
      this.setState({
        filterHasErrorUT: false
      })
      return false;
    } else {
      this.setState({
        filterHasErrorUT: true
      })
      return true;
    }
  }

  handleVillageFilterClearUT = () => {
    const { sowingYear } = this.state;
    this.setState({
      openTableFilterUT: false,
      anchorEl: null,
      sortDescendingUT: false,
      sortAscendingUT: false,
      savedSortAscendingUT: false,
      savedSortDescendingUT: false,
      selectedActivity: [],
      hideBadgeUT: true,
    })
    this.filterActivitiesData(sowingYear, "");
  };

  handleFilteredActivityName = (type: string) => {
    switch (type) {
      case "gap_filling":
        return {name:"Gap Filling", key: "GAP_FILLING"};
      case "weed_management":
        return {name:"Weed Management", key: "WEED_MANAGEMENT"};
      case "nutrient":
        return {name:"Nutrient Management", key: "NUTRIENT_MANAGEMENT"};
      case "harvest":
        return {name:"Harvest", key: "HARVEST"};
      case "sell":
        return {name:"Sell", key: "SALE"};
      case "irrigation":
        return {name:"Irrigation", key: "IRRIGATION"};
      case "crop_failure":
        return {name:"Crop Failure", key: "CROP_FAILURE"};
      case "activity_pest_management":
          return {name:"Pest Management", key: "PEST_MANAGEMENT"};
      default:
        return { name: "", key: "" }; 
    }
  }

  handleFilteredActivitySvg = (type: string) => {
    switch (type) {
      case "gap_filling":
        return gapFilling2Svg;
      case "weed_management":
        return WeedManagementActivitySVG;
      case "nutrient":
        return nutrientManagementSvg;
      case "harvest":
        return harvestSvg;
      case "sell":
        return saleSvg;
      case "activity_pest_management":
        return pestManagementSvg;
      case "irrigation":
        return irrigationSvg;
      case "crop_failure":
        return cropFailureSvg;
    }
  }

  calculateEnabledDate = (sowingDate: string, cropDays:string, cropName: string, endCropClick: boolean) => {
    const sowingMoment = moment(sowingDate, 'DD MMM YYYY');
    const enabledDate = sowingMoment.add(cropDays, 'days');
    
    const buttonEnabled = moment().isAfter(enabledDate);
    if(buttonEnabled && endCropClick){
      this.setState({ isEndCropModalOpen: true })
    } else if(!buttonEnabled && endCropClick) {
      this.setState({ openWarningModal: true})
    }
    this.setState({ cropLastDate: moment(enabledDate).format("DD/MM/YYYY"), cropName, btnEnabled: buttonEnabled  })
  }
 
  handleSelectActivity =
   (id: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
     this.setState({activeAccordionIndex:newExpanded?id:""})
   };
   handleCloseViewCropModal = () => {
    this.setState({ 
      anchorEl: null,
      openTableFilterUT: false,
      savedSortAscendingUT: false,
      savedSortDescendingUT: false,
      sortAscendingUT: false,
      sortDescendingUT: false,
      filterHasErrorUT: false,
      selectedActivity: [],
      sowingYear: null,
      filteredActivityData: [],
      loader: false,
      hideBadgeUT: true,
      activeAccordionIndex: "",
      openWarningModal: false,
      cropName: "",
      cropLastDate: "",
      btnEnabled: false,
      cropDetailsDataLoading: false, 
      cropDetailsData: null, 
      isDropdownAPIResponded: 0, 
    })
    this.props.handleCloseViewCropModal()
   }
  // TABLE FILTER FUNCTIONS ENDS
  // Customizable Area End
}

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const presowingImage=require("../assets/pre-sowing.svg");
export const shareIcons=require("../assets/share-icons.svg");
export const editIcon=require("../assets/edit-icon.svg");
export const deleteIcon=require("../assets/delete-icon.svg");
export const gapFillingSvg=require("../assets/gap-filling.svg");
export const gapFilling2Svg=require("../assets/gap-filling2.svg");
export const nutrientManagementSvg=require("../assets/nutrient-management.svg");
export const weedManagementSvg=require("../assets/weed-management.svg");
export const saleSvg=require("../assets/sale.svg");
export const irrigationSvg=require("../assets/irrigation.svg");
export const cropFailureSvg=require("../assets/crop-failure.svg");
export const pestManagementSvg=require("../assets/pest-management.svg");
export const harvestSvg=require("../assets/harvest.svg");
export const closeIconSvg=require("../assets/close-icon.svg");
export const BackArrowIconSVG =require("../assets/BackArrowIcon.svg")
export const mapPlusSVG =require("../assets/mapPlusIcon.svg")
export const rupeeIconSVG =require("../assets/rupeeIcon.svg")
export const rainFedErrorSVG = require("../assets/rainfedError.svg")








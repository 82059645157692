import React from "react";
import {
  AccordionDetails,
  Box,
  Table,
  TableBody,
  IconButton,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Tooltip
} from "@material-ui/core";
import { ActivityDataTypes } from "./interface.web";
import { dateDDMMYYYY, returnTruthyString } from "./helper";
interface GapFillingActivityDetailsPros {
  data: ActivityDataTypes[];
  handleDelete: (id: string) => void;
  handleEdit: (id: string,land_detail_id:string | undefined,crop_id:string | string [] | undefined) => void;
  handleShareLink: (id: string,land_detail_id:string | undefined,crop_id:string | string [] | undefined, message: string) => void;
}

const GapFillingActivityDetails = ({
  data = [],
  handleDelete,
  handleEdit,
  handleShareLink
}: GapFillingActivityDetailsPros) => {
  // RENDER TABLE ROW
  const rendertableRow = (label: string, value: string | Date) => {
    return (
      <TableRow>
        <TableCell
          style={{
            ...styleWeb.tableCell,
            ...styleWeb.opacity64,
          }}
        >
          {label}
        </TableCell>
        <TableCell style={styleWeb.tableCell}>{value}</TableCell>
      </TableRow>
    );
  };

  const renderTable = (data: ActivityDataTypes) => {
    const { id, attributes } = data ?? {};
    const { date, labor_cost, price_of_seed,land_detail_id,crop_id ,crop_info, freeze_record} = attributes ?? {};
    const disable_activity = freeze_record

    const farm_id = returnTruthyString(land_detail_id && land_detail_id[0]?.id)
    const farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)

    const crop_ids = returnTruthyString(crop_id && crop_id[0]?.id)
    const crop_name = returnTruthyString(crop_id && crop_id[0]?.name)

    return (
      <Box style={{ width: "100%" }} key={id}>
        <Table>
          <TableHead>
            <TableRow style={styleWeb.tableHead}>
              <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
              <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name ?? "-"}</span></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rendertableRow("Gap Filling Date", dateDDMMYYYY(date))}
            {rendertableRow("Labor Cost", labor_cost)}
            {rendertableRow("Price of Seed", price_of_seed)}
          </TableBody>
        </Table>
        <Box style={styleWeb.tableFooter}>
          <Box style={styleWeb.iconsbox} onClick={()=> handleShareLink(id,farm_id,crop_ids,"gap_fillings")}>
            <IconButton style={styleWeb.iconbutton} >
              <img
                src={require("./share-icons.svg")}
                alt="Share in chat"
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
            <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
          </Box>
          <Box style={styleWeb.iconsbox}>
          <Tooltip title={disable_activity ? "Activity Freezed" : ""}>
            <Box>
            <IconButton style={styleWeb.iconbutton} 
              onClick={() => handleEdit(id,farm_id,crop_ids)}
              disabled={disable_activity}
            >
              <img
                src={require("./edit-icon.svg")}
                alt="Edit activity"
                style={styleWeb.actionIcon}
              />
            </IconButton>
            </Box>
            </Tooltip>
            <Tooltip title={disable_activity ? "Activity Freezed" : ""}>
            <Box>
            <IconButton
              style={styleWeb.iconbutton}
              onClick={() => handleDelete(id)}
              disabled={disable_activity}
            >
              <img
                src={require("./delete-icon.svg")}
                alt="Delete activity"
                style={styleWeb.actionIcon}
              />
            </IconButton>
            </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
   <>
      {data?.length > 0 ? (
        data.map((item) => {
          return renderTable(item);
        })
      ) : (
        <Box style={{ width: "100%" }}>
          <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
            No data found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default GapFillingActivityDetails;

const styleWeb = {
  tableCell: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  } as React.CSSProperties,
  iconsbox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer"
  } as React.CSSProperties,
  actionIcon: {
    width: "20px",
    height: "20px",
  } as React.CSSProperties,
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  tableFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
  } as React.CSSProperties,
  opacity64: {
    opacity: "64%",
  } as React.CSSProperties,
  tableHead: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "100%",
  } as React.CSSProperties,
  highlightedText: {
    fontWeight: 500,
  } as React.CSSProperties,
  headInfo: {
    fontWeight: 400,
    padding: "8px 12px",
    fontSize: "12px",
    color: "#1C2324",
    border: "1px solid #F0EBE0",
  } as React.CSSProperties,
};

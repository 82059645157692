import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Tabs,
  Tab,
  styled,
  TextField,
  FormControl,
  IconButton,
  Select,
  MenuItem
} from "@material-ui/core";
import { AddSVG, RemoveSVG } from "../assets";
import { LoadingButton, NoMenuDataFound, renderErrorMessage, selectMenuItem } from "../../../../components/src/AdminConsoleHelper";
import LocationDropDownController, { ILanguageObject, PreviousUI, Props, configJSON, getLabelName } from "./LocationDropDownController.web";
import { KeyboardArrowDown } from "@material-ui/icons";

// Customizable Area End


export default class LocationDropDown extends LocationDropDownController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  handleMoreRender = (heading: string | undefined, initialForm?: any) => {
    switch (heading) {
      case "District":
        return (
          <Box style={{ width: "100%" }}>
            <CustomSelectDD
              IconComponent={CustomArrowIcon}
              data-testID="DistrictSelectState"
              variant="outlined"
              color="secondary"
              style={style.inputFieldStyle}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              value={this.state.stateId}
              onChange={(event) => { this.handleStateSelectChange(event.target.value) }}
            >
              {selectMenuItem("Select State")}
              {this.state.stateDropDownOptions.length > 0 ?
                this.state.stateDropDownOptions.map((each: any) => (
                  <MenuItem key={each.id} value={each.id}>
                    {each.name}
                  </MenuItem>
                ))
                : NoMenuDataFound()
              }
            </CustomSelectDD>
            {renderErrorMessage(initialForm?.stateError)}
          </Box>
        )

      case "Taluka":
        return (
          <Box style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: "4px" } as React.CSSProperties}>
            <Box>
              <CustomSelectDD
                IconComponent={CustomArrowIcon}
                data-testID="talukaSelectState"
                variant="outlined"
                color="secondary"
                style={style.inputFieldStyle}
                inputProps={{ "aria-label": "Without label" }}
                onChange={(event) => { this.handleSelectChange(event.target.value as string) }}
                displayEmpty
                value={this.state.stateId}
              >
                {selectMenuItem("Select State")}  
                { this.state.stateDropDownOptions.length > 0 ? 
                   this.state.stateDropDownOptions.map((each: any) => (
                      <MenuItem   value={each.id}  key={each.id}> 
                      {each.name}  

                     </MenuItem> 
                  ))    
                   :  NoMenuDataFound()
                }   
              </CustomSelectDD>   

              {renderErrorMessage(initialForm?.stateError)}   
             </Box>

               <Box>  
              <CustomSelectDD
                IconComponent={CustomArrowIcon}
                data-testID="talukaSelectDistrict"
                variant="outlined"
                color="secondary"
                style={style.inputFieldStyle}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                value={this.state.districtId}
                onChange={(event) => { this.handleDistrictSelectChange(event.target.value) }}
              >
                {selectMenuItem("Select District")}
                {this.state.districtDropDownOptions.length > 0 ?
                  this.state.districtDropDownOptions.map((each: any) => (
                    <MenuItem key={each.id} value={each.id}>
                      {each.name}
                    </MenuItem>
                  ))
                  : NoMenuDataFound()
                }
              </CustomSelectDD>
              {renderErrorMessage(initialForm?.districtError)}
            </Box>
          </Box>
        )

      case "Village":
        return (
          <Box style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: "4px" } as React.CSSProperties}>
            <Box>
              <CustomSelectDD
                IconComponent={CustomArrowIcon}
                variant="outlined"
                style={style.inputFieldStyle}
                color="secondary"
                data-testID="villageSelectState"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={this.state.stateId}
                onChange={(event) => { this.handleSelectChange(event.target.value as string) }}
              >
                {selectMenuItem("Select State")}    
                {this.state.stateDropDownOptions.length    > 0  ?  
                   this.state.stateDropDownOptions.map((each:  any)  =>  (
                     <MenuItem key={each.id} value={each.id}> 
                        {each.name} 
                    </MenuItem>  
                  )) 
                     : NoMenuDataFound()
                }   
              </CustomSelectDD>

              {renderErrorMessage(initialForm?.stateError)  }

               </Box>
            <Box>   
              <CustomSelectDD
                IconComponent={CustomArrowIcon}
                style={style.inputFieldStyle}
                variant="outlined"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                color="secondary"
                value={this.state.districtId}
                data-testID="villageSelectDistrict"
                onChange={(event) => { this.handleDistrictSelectChangeTal(event.target.value) }}
              >
                {selectMenuItem("Select District")}
                {this.state.districtDropDownOptions.length > 0 ?
                  this.state.districtDropDownOptions.map((each: any) => (
                    <MenuItem key={each.id} value={each.id}>
                      {each.name}
                    </MenuItem>
                  ))
                  : NoMenuDataFound()
                }
              </CustomSelectDD>
                
                     {renderErrorMessage(initialForm?.districtError)}
            </Box>
            <Box>
              <CustomSelectDD
                IconComponent={CustomArrowIcon}
                color="secondary"
                style={style.inputFieldStyle}
                variant="outlined"
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                value={this.state.talukaId}
                data-testID="villageSelectTaluka"
                onChange={(event) => { this.handleTalukaSelectChange(event.target.value) }}
              >
                {selectMenuItem("Select Taluka")}
                {this.state.talukaDropDownOptions.length > 0 ?
                  this.state.talukaDropDownOptions.map((each: any) => (
                    <MenuItem key={each.id} value={each.id}>
                       {each.name}
                    </MenuItem>
                  ))
                  : NoMenuDataFound()
                }
              </CustomSelectDD>
              {renderErrorMessage(initialForm?.talukaError)}
            </Box>
          </Box>

        )
      default:
        return <></>

    }
  }

  handleVillagePreUI = (form: PreviousUI, formIndex: number, existingForm: ILanguageObject) => {
    return (
      <>
        <Box style={{ width: "100%" }}>
          <CustomSelectDD disabled
            IconComponent={CustomArrowIcon}
            variant="outlined" color="secondary"
            style={style.inputFieldStyle} inputProps={{ "aria-label": "Without label" }}
            data-testid="select_stateVilage"
            displayEmpty
            value={form.state_id}
            renderValue={() => getLabelName(form.state_id, this.state.defaulStateList)}
            onChange={(event) => this.handleSelectChangePrevious(formIndex, "add_another_option", event.target.value as string)}
          >
          </CustomSelectDD>
          {renderErrorMessage(existingForm?.stateError ?? "")}
        </Box>

        <Box style={{ width: "100%" }}>
          <CustomSelectDD disabled
            IconComponent={CustomArrowIcon}
            color="secondary"
            variant="outlined"
            data-testid="select_district"
            value={form.district_id}
            inputProps={{ "aria-label": "Without label" }}
            style={style.inputFieldStyle}
            renderValue={() => getLabelName(form.district_id, this.state.defaulDistrictList)}
            displayEmpty
            onChange={(event) => { this.handleDistrictChangePrevious(formIndex, "add_another_option", event.target.value as string) }}
          >
          </CustomSelectDD>

                {renderErrorMessage(existingForm?.districtError ?? "")}
        </Box>

        <Box style={{ width: "100%" }}>
          <CustomSelectDD disabled
            IconComponent={CustomArrowIcon}
            data-testid="select_district"
            variant="outlined"
            color="secondary"
            style={style.inputFieldStyle}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            renderValue={() => getLabelName(form.taluka_id, this.state.defaulTalukaList)}
            value={form.taluka_id}
            onChange={(event) => this.handleSelectChangePrevious(formIndex, "add_another_option", event.target.value as string)}
          >
          </CustomSelectDD>

          {renderErrorMessage(existingForm?.stateError ?? "")}
        </Box>
      </>
    )
  }

  handleTalukaPreUI = (form: PreviousUI, formIndex: number, existingForm: ILanguageObject) => {
    return (
      <>
        <Box style={{ width: "100%" }}>
          <CustomSelectDD disabled
            IconComponent={CustomArrowIcon}
            color="secondary"
            onChange={(event) => this.handleSelectChangePrevious(formIndex, "add_another_option", event.target.value as string)}
            data-testid="talukaSelectPrev"
            variant="outlined"
            inputProps={{ "aria-label": "Without label" }}
            renderValue={() => getLabelName(form.state_id, this.state.defaulStateList)}
            displayEmpty
            style={style.inputFieldStyle}
            value={form.state_id}
          >
          </CustomSelectDD>
          {renderErrorMessage(existingForm?.stateError ?? "")}
        </Box>
        <Box style={{ width: "100%" }}>
          <CustomSelectDD disabled
            IconComponent={CustomArrowIcon}
            variant="outlined"
            style={style.inputFieldStyle}
            inputProps={{ "aria-label": "Without label" }}
            color="secondary"
            data-testid="select_district"
            renderValue={() => getLabelName(form.district_id, this.state.defaulDistrictList)}
            displayEmpty
            onChange={(event) => { this.handleDistrictChangePrevious(formIndex, "add_another_option", event.target.value as string) }}
            value={form.district_id}
          >
          </CustomSelectDD>
             {renderErrorMessage(existingForm?.districtError ?? "")}
        </Box>
      </>
    )
  }

  handlePreviousUi = (form: any, heading: string | undefined, existingForm: any, formIndex: number) => {
    if (heading == "Village" && form.state_id) {
      return this.handleVillagePreUI(form, formIndex, existingForm)
    }
    if (heading == "Taluka" && form.state_id) {
      return this.handleTalukaPreUI(form, formIndex, existingForm)
    }
    if (heading == "District" && form.state_id) {
      return (
        <Box style={{ width: "100%" }}>
          <CustomSelectDD disabled
            IconComponent={CustomArrowIcon}
            data-testid="select_state"
            variant="outlined"
            color="secondary"
            style={style.inputFieldStyle}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            renderValue={() => getLabelName(form.state_id, this.state.defaulStateList)}

            value={form.state_id}
            onChange={(event) => this.handleSelectChangePrevious(formIndex, "add_another_option", event.target.value as string)}
          >
          </CustomSelectDD>    
          {renderErrorMessage(existingForm?.stateError ?? "")}  
        </Box>
      )
    }
  }


  // Customizable Area End

  render() {
    // Customizable Area Start 

    const { selectedTab, defaultForm, formArray } = this.state;  
    const  initialForm =  defaultForm[selectedTab]   as ILanguageObject  
    // Customizable Area End
    return (
      // Customizable Area Start

      <> 
        <Box> 
           <PaperWrapper square style={{ background: "transparent" }}>
              <Tabs
               variant="fullWidth"
               className="tabs-wrapper"
               onChange={this.handleChangeTab}
               data-testid="tab-wrapper"    
               value={selectedTab}  
               > 
              <CustomTab  label="English"  value= {configJSON.TAB.ENGLISH}  data-testid="english-tab" />
              <CustomTab  label="Gujarati" value={configJSON.TAB.GUJARATI}   data-testid= "gujarati-tab"  />
              <CustomTab   value={configJSON.TAB.HINDI} label="Hindi" data-testid = "hindi-tab"  /> 
            </Tabs> 
          </PaperWrapper> 
           <Box style={style.listWrapper}>  
               {formArray?.length > 0 ? formArray?.map((form: any, formIndex: number) => {
              const existingForm =  form[selectedTab]  as ILanguageObject
               return (
                <React.Fragment key={formIndex}>
                  {!form?._destroy ? (
                    <>
                      <Box style={{ ...style.inputWrapper}}>
                        <FormControl fullWidth>
                          <CustomTextField
                            autoComplete="off"
                            type="text"
                            variant="outlined"
                            color="secondary"
                            placeholder="Add another option"
                            data-testid="text_input"
                            name="add_another_option"                            
                            value={existingForm?.value}
                            onChange={(event) => this.handleChangeInput(selectedTab, formIndex, "add_another_option", event.target.value)}
                          />
                          {renderErrorMessage(existingForm?.error)}
                        </FormControl>
                        <IconButton
                          data-testid={`remove-input-testid-${formIndex}`}
                          style={style.iconBtn}
                          onClick={() => this.handleRemoveInput(form, formIndex)}>
                          <img src={RemoveSVG} />
                        </IconButton>
                      </Box>

                      <Box style={style.locationWrapper}>
                        {this.handlePreviousUi(form, this.props.heading, existingForm, formIndex)}
                      </Box>

                    </>
                  )
                    :
                    <>
                    </>
                  }
                </React.Fragment>)
            }) : <></>}
            {/* DEFAULT FORM */}
            <Box style={style.inputWrapper}>
              <FormControl fullWidth>
                <CustomTextField
                  autoComplete="off"
                  type="text"
                  variant="outlined"
                  color="secondary"
                  placeholder="Add another option"
                  data-testid="text_input_default"
                  name="add_another_option"
                  value={initialForm?.value}
                  onChange={(event) => this.handleChangeInputDefault("add_another_option", event.target.value, selectedTab)}
                />
                {renderErrorMessage(initialForm?.error)}
              </FormControl>
              <IconButton
                data-testid="add-input-testid"
                style={style.iconBtn}
                onClick={this.handleAddAnotherInput.bind(this, this.handleMoreRender(this.props.heading), this.props.heading)}>
                <img src={AddSVG} />
              </IconButton>
            </Box>
            <Box style={{ display: "flex", width:"100%",padding:"0 40px 0 16px", marginTop:"8px" }}>
              {this.handleCall(this.handleMoreRender(this.props.heading, initialForm))}
            </Box>

            <LoadingButton
              data-testid="save-btn"
              btnStyle={style.saveBtn}
              loadingStyle={style.loadingStyle}
              title={"SAVE"}
              isLoading={this.props.submitLoading}
              onClick={this.handleSubmit}
            />
          </Box>
        </Box>
      </>
      // Customizable Area End

    )
  }
}
// Customizable Area Start

const CustomArrowIcon = styled(KeyboardArrowDown)({
  fontSize: "26px",
  color: "#1C2324",
  opacity: 0.40
});
const PaperWrapper = styled(Paper)({
  background: "transparent",
  "& .MuiTabs-root": {
    minHeight: "21px",
  }
})
const CustomSelectDD = styled(Select)({
  background: "#F8F6F1 !important",

  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent !important"
  },
  "& .MuiSelect-iconOutlined": {
    right: "10px !important"
  },
  "& .MuiSelect-root": {
    padding: "0 32px 0 14px !important"
  }

})
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "40px"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px !important",
    backgroundColor: "#F8F6F1 !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #F0EBE0 !important",

  }
})

const CustomTab = styled(Tab)({
  fontWeight: 500,
  fontSize: "14px",
  color: "#1C2324",
  opacity: "0.48 !important",
  borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
  padding: "0px",
  alignItems: "end",
  minHeight: "21px !important",
  minWidth: "0px !important",
  "&.Mui-selected": {
    color: "#DF5200 !important",
    opacity: "1 !important",
  }
})


const style = {
  saveBtn: {
    width: "100%",height: "40px",
    backgroundColor: "#DF5200",

    fontWeight: 600,
    color: "#FFFFFF",fontSize: "14px",
    lineHeight: "22px",textAlign: "center",
    borderRadius: "28px",marginTop: "16px"
  } as React.CSSProperties,
  listWrapper: {
    padding: "12px 16px 16px 16px"
  } as React.CSSProperties,
  locationWrapper: {
    display: "flex",
    width: "100%",
    padding: "0 40px 0 16px",
    marginTop: "8px",
    marginBottom: "12px",
    flexDirection: "column",
    gap: "4px"
  } as React.CSSProperties,
  inputWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  } as React.CSSProperties,
  select_formcontrol: {
    alignSelf: "center",
  },
  inputFieldStyle: {
    height: "32px",
    width: "100%",
  } as React.CSSProperties,
  opcity_48: {
    color: "#1C2324 !important",
  },
  iconBtn: {
    margin: "0 0 0 16px",
    padding: "0"
  } as React.CSSProperties,
  loadingStyle: {
    color: "#FFFFFF",
    height: "20px",
    width: "20px"
  } as React.CSSProperties,
}
// Customizable Area End
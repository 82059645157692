import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Cf2enhancementsfromversion1Controller, {
  Props,
  configJSON,
} from "./Cf2enhancementsfromversion1Controller";

export default class Cf2enhancementsfromversion1 extends Cf2enhancementsfromversion1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper>
        <Typography className="heading">{this.props.title}</Typography>
        {this.props.modalName === "importing" 
        ? <Typography className="description">Importing{" "}<span className="highlight">{this.props.fileName}</span>{" "} has started and we'll send you a notification when it's finished.</Typography>
        : <Typography className="description"><span className="highlight">{this.props.fileName}</span>{this.props.description}</Typography>
        }
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  textAlign: "center",
  padding: "30px",
  "& .heading": {
    fontSize: '24px', 
    color: "#1C2324", 
    fontWeight: 500, 
    lineHeight: '36px',
    fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
    marginBottom: "25px"
  }, 
  "& .highlight": {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
  },
  "& .description": {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'center',
    maxWidth: "400px",
    margin: "0 auto 10px",
    fontFamily: 'IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif',
  }, 
});
// Customizable Area End

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IDescription } from "../../../components/src/interface.web";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
interface S {
    authToken: string | null;
    PolicyDataLoading: boolean;
    PolicyData: IDescription | null;
}
interface SS { }
// Customizable Area End

export default class PrivacyPolicyController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPolicyDataId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            PolicyDataLoading: false,
            PolicyData: null
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start            
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.getPolicyDataId) {
                    this.handleGetPolicyDataId(responseJson)
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start
    handleGetPolicyDataId = (responseJson: IDescription[] | null) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({
                PolicyDataLoading: false,
                PolicyData: responseJson[0]
            })
        } else {
            this.setState({
                PolicyDataLoading: false,
                PolicyData: null
            })
        }
    }

    async componentDidMount(): Promise<void> {
        this.getPolicyData()
    }

    getPolicyData = () => {
        this.setState({
            PolicyDataLoading: true,
            PolicyData: null
        });

        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPolicyDataId = requestMsg.messageId;

        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.GET_PRIVACY_POLICY);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    // Customizable Area End
}
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { isLanguageKey, languageError, returnTruthyString, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface ILocations {
    id: number | string
    name: string
    name_english: string
    name_hindi: string
    name_gujrati: string
    active: boolean
    created_at: string
    updated_at: string
    state_id: number
    district_id: number
  }
export interface ILanguageObject {
    value: string;
    error: string;
    stateError?: string;
    districtError?: string;
    talukaError?: string;
}

interface FormValue{
    districtError?: string,
    stateError?: string,
    talukaError?: string,
    error?: string,
    value: string
}

export interface PreviousUI{
    district_id?:string,
    english: FormValue,
    gujarati: FormValue,
    hindi: FormValue,
    id: string,
    state_id?: string ,
    taluka_id?:string
}

interface IDefaultValue {
    [key: string]: ILanguageObject | string | number;
    id: string | number;
}

const defaultValue = {
    id: "",
    english: {
        value: "",
        stateError: "",
        error: "",
        talukaError: "",
        districtError: "",
    },
    gujarati: {
        stateError: "",
        error: "",
        districtError: "",
        value: "",
        talukaError: ""
    },
    hindi: {
        districtError: "",
        value: "",
        error: "",
        talukaError: "",
        stateError: "",

    },
}
export const getLabelName = (valueId: string | number | undefined, menuList: ILocations[]) => {
    let label = ""
    const obj = menuList.find((each) => each.id?.toString() == valueId?.toString())

    if (obj) {
      label = obj.name
    }
    return label
  }
export interface Props {
    navigation: any;
    id: string;
    dataArray: IDefaultValue[],
    onSubmit?: (data: any) => void,
    submitLoading: boolean,
    dropdownName?: string,
    heading?: string
}

interface StateResponse {
    active: boolean,
    created_at: string,
    id: number,
    name: string
    name_english: string
    name_gujrati: string,
    name_hindi: string,
    updated_at: string
}


interface S {
    selectedTab: string;
    authToken: string | null;
    defaultForm: IDefaultValue;
    formArray: IDefaultValue[];
    stateId: string;
    districtId: string;
    talukaId: string;
    stateDropDownOptions: StateResponse[];
    districtDropDownOptions: any;
    talukaDropDownOptions: any;
    defaulStateList: ILocations[];
    defaulDistrictList: ILocations[];
    defaulTalukaList: ILocations[];
}
interface SS { }
// Customizable Area End

export default class LocationDropDownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDistrictDetailsApiCallId: string = "";
    getTalukaDetailsApiCallId: string = "";
    getStateDetailsApiCallId: string = "";

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start 
            authToken: localStorage.getItem("tokenn"),
            selectedTab: configJSON.TAB.ENGLISH,
            defaultForm: { ...defaultValue },
            formArray: [],
            stateId: "",
            districtId: "",
            talukaId: "",
            stateDropDownOptions: [],
            districtDropDownOptions: [],
            talukaDropDownOptions: [],
            defaulStateList: [],
            defaulDistrictList: [],
            defaulTalukaList: [],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.getStateDetailsApiCallId) {
                    this.handleGetStateDetails(responseJson)
                }
                if (apiRequestCallId === this.getDistrictDetailsApiCallId) {
                    this.handleGetDistrictDetails(responseJson)
                }
                if (apiRequestCallId === this.getTalukaDetailsApiCallId) {
                    this.handleGetTalukaDetails(responseJson)
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    async componentDidMount() {
        this.getStateData();
        this.setState({ formArray: this.props.dataArray })
    }


    handleGetStateDetails = (responseJson: any) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({
                stateDropDownOptions: responseJson,
                defaulStateList: responseJson,
            })
        } else {
            this.setState({
                stateDropDownOptions: [],
                defaulStateList: responseJson,
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if (prevProps.dataArray !== this.props.dataArray) {
            this.setState({
                formArray: this.props.dataArray
            })
        }
    }

    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({
            selectedTab: newValue
        })
    }

    handleChangeInput = (language: string, fieldIndex: number, fieldName: string, fieldValue: string) => {
        this.setValue(language, fieldIndex, fieldName, fieldValue)
    }

    handleGetTalukaDetails = (responseJson: any) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({
                talukaDropDownOptions: responseJson,
                defaulTalukaList: responseJson
            })
        } else {
            this.setState({
                talukaDropDownOptions: [],
                defaulTalukaList: []
            })
        }
    }

    getStateData = () => {
        this.setState({
            // INITAL
            stateId:"",
            districtId:"",
            talukaId: "",
            // LIST
            stateDropDownOptions: [],
            districtDropDownOptions: [],
            talukaDropDownOptions: []
        })
        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getStateDetailsApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.DropdownDetailsEndPointDistrict}state_listing`
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    handleGetDistrictDetails = (responseJson: any) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({
                districtDropDownOptions: responseJson,
                defaulDistrictList: responseJson,
            })
        }else{
            this.setState({
                districtDropDownOptions: [],
                defaulDistrictList: [],
            })
        }
    }

    handleTalukaSelectChange = (value: any) => {
        this.setState({
            talukaId: value
        })
        this.setState(prevState => {
            const defaultForm = { ...prevState.defaultForm };
            if (defaultForm.english) {
                defaultForm.english = {
                    ...defaultForm.english as ILanguageObject,
                    talukaError: this.state.talukaDropDownOptions.length == 0 || value !== "" ? "" : "Taluka Field cannot be empty"
                };
            }
            if (defaultForm.gujarati) {
                defaultForm.gujarati = {
                    ...defaultForm.gujarati as ILanguageObject,
                    talukaError: this.state.talukaDropDownOptions.length == 0 || value !== "" ? "" : "Taluka Field cannot be empty"
                };
            }
            if (defaultForm.hindi) {
                defaultForm.hindi = {
                    ...defaultForm.hindi as ILanguageObject,
                    talukaError: this.state.talukaDropDownOptions.length == 0 || value !== "" ? "" : "Taluka Field cannot be empty"
                };
            }
            return { defaultForm };
        });

    }

    setValue = (language: string, fieldIndex: number, fieldName: string, fieldValue: string,) => {
         this.setState(prevState =>{ 
            const updatedFormArray =  [...prevState.formArray ];

            updatedFormArray[fieldIndex]  =  {
                ...updatedFormArray[fieldIndex],   
                [language]: {   
                    ...updatedFormArray[fieldIndex][language]  as  ILanguageObject,
                      value:  trimStart(String(fieldValue)),   
                       error:  returnTruthyString(String(fieldValue))  ? ""  : " Field cannot be empty"  
                }
            }; 

            return {  formArray:  updatedFormArray  };

        });
    }

    handleDistrictChangePrevious = (fieldIndex: number, fieldName: string, fieldValue: string) => {
        const updatedFormData = [...this.state.formArray]
        updatedFormData[fieldIndex].district_id = fieldValue;
        this.setState({
            formArray: updatedFormData
        })
    }

    handleRemoveInput = (form: IDefaultValue, index: number) => {
        let existingData = [...this.state.formArray]

        if (index >= 0 && index < existingData.length) {
            const obj = existingData[index];
            if (obj.id) {
                obj["_destroy"] = form.id;
            } else {
                existingData.splice(index, 1);
            }
        }
        this.setState({ formArray: existingData });
    };

    handleChangeInputDefault = (fieldName: string, fieldValue: string | number, language: string) => {
        this.setState(prevState => ({
            defaultForm: {
                ...prevState.defaultForm,
                [language]: {
                    ...prevState.defaultForm[language] as ILanguageObject,
                    value: trimStart(String(fieldValue)),
                    error: returnTruthyString(String(fieldValue)) ? "" : "Field cannot be empty",
                }
            }
        }));
    }

    setDefaultTab = (tabValue: string = configJSON.TAB.ENGLISH) => {
        this.setState({
            selectedTab: tabValue
        })
    }

    validateLanguage = (languageObject: ILanguageObject, languageTab: string) => {
        if (languageObject.error || languageObject.stateError || languageObject.districtError || languageObject.talukaError) {
            this.setDefaultTab(languageTab);
            return true;
        }
        return false;
    }

    handleStateValidation = (heading: string, currentLanguage: ILanguageObject) => {
        if (heading != "State" && this.state.stateId == '') {
             currentLanguage.stateError = "State Field cannot be empty";
        }
    }

    handleDistrictValidation = (heading: string, currentLanguage: ILanguageObject) => {
        if ((heading == "Taluka" || heading == "Village") && this.state.districtId == '') {
             currentLanguage.districtError = "District Field cannot be empty";
        }
    }

    handleTakulaValidation = (heading: string, currentLanguage: ILanguageObject) => {
        if (heading == "Village" && this.state.talukaId == '') {
             currentLanguage.talukaError = "Taluka Field cannot be empty";
        }
    }

    validateNewForm = (newForm: IDefaultValue, heading: any) => {
        let isErrorEnglish = false
        let isErrorGujarati = false
        let isErrorHindi = false

        for (const language in newForm) {
            if (isLanguageKey(language)) {
                const currentLanguage = newForm[language] as ILanguageObject;

                if (currentLanguage.value.trim() == '') {
                    currentLanguage.error = "Field cannot be empty";
                } 
                this.handleStateValidation(heading, currentLanguage)
                this.handleDistrictValidation(heading, currentLanguage)
                this.handleTakulaValidation(heading, currentLanguage)

            }
        }

        const englishLanguage = newForm[configJSON.TAB.ENGLISH] as ILanguageObject;
        const gujaratiLanguage = newForm[configJSON.TAB.GUJARATI] as ILanguageObject;
        const hindiLanguage = newForm[configJSON.TAB.HINDI] as ILanguageObject;
        if (this.validateLanguage(englishLanguage, configJSON.TAB.ENGLISH)) {
            isErrorEnglish = true
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if (this.validateLanguage(gujaratiLanguage, configJSON.TAB.GUJARATI)) {
            isErrorGujarati = true
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if (this.validateLanguage(hindiLanguage, configJSON.TAB.HINDI)) {
            isErrorHindi = true
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
        const isFormHasError = isErrorEnglish || isErrorGujarati || isErrorHindi

        return this.handleCondition(isFormHasError, false, true)
    }

    handleCondition = (condition: any, truePart: any, falsePart: any) => {
        return condition ? truePart : falsePart;
    };

    handleAddAnotherInput = (UiRendered: any, heading: string | undefined) => {
        let newForm = JSON.parse(JSON.stringify(this.state.defaultForm))
        if (this.validateNewForm(newForm, heading)) {
            if (heading !== "State" && heading !== "District" && heading !== "Taluka") {
                newForm = { ...newForm, state_id: this.state.stateId, district_id: this.state.districtId, taluka_id: this.state.talukaId }
            }
            else if (heading !== "State" && heading !== "District") {
                newForm = { ...newForm, state_id: this.state.stateId, district_id: this.state.districtId }
            } else if (heading !== "State") {
                newForm = { ...newForm, state_id: this.state.stateId }
            } else {
                newForm = { ...newForm }
            }
            let existingData = [...this.state.formArray]
            existingData.push(newForm);
            this.setState({ formArray: existingData, defaultForm: { ...defaultValue } });
            this.setDefaultTab()
            this.setState({ stateId: '', districtId: '', talukaId: '', districtDropDownOptions:[],talukaDropDownOptions:[] })
        } else {
            this.setState({ defaultForm: newForm });
        }
    }

    changeTab = (isErrorEnglish: boolean, isErrorGujarati: boolean, isErrorHindi: boolean) => {
        if (isErrorEnglish) {
            this.setDefaultTab(configJSON.TAB.ENGLISH)
        } else if (isErrorGujarati) {
            this.setDefaultTab(configJSON.TAB.GUJARATI)
        } else if (isErrorHindi) {
            this.setDefaultTab(configJSON.TAB.HINDI)
        }
        return isErrorEnglish || isErrorGujarati || isErrorHindi
    }

    handleCall = (UiRendered: any) => {
        return UiRendered
    }

    getDistrictData = (stateId: string | null) => {
        this.setState({
            // INITAL
            districtId:"",
            talukaId: "",
            // LIST
            districtDropDownOptions: [],
            talukaDropDownOptions: []
        })
        if (stateId) {
            const header = { token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDistrictDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPointDistrict}district_listing?state_id=${stateId}`
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    handleSelectChangePrevious = (fieldIndex: number, fieldName: string, fieldValue: string) => {
        const updatedFormData = [...this.state.formArray]
        updatedFormData[fieldIndex].state_id = fieldValue;
        if (this.props.heading === "Taluka") {
            this.getDistrictData(fieldValue);
            updatedFormData[fieldIndex].district_id = '';
        }

        this.setState({
            formArray: updatedFormData
        })
    }

    handleSelectChange = (state: string) => {
        this.setState({
            stateId: state
        })

        this.setState(prevState => {
            const defaultForm = { ...prevState.defaultForm };
            if (defaultForm.english) {
                defaultForm.english = {
                    ...defaultForm.english as ILanguageObject,
                    stateError: state !== "" ? "" : "State Field cannot be empty"
                };
            }
            if (defaultForm.gujarati) {
                defaultForm.gujarati = {
                    ...defaultForm.gujarati as ILanguageObject,
                    stateError: state !== "" ? "" : "State Field cannot be empty"
                };
            }
            if (defaultForm.hindi) {
                defaultForm.hindi = {
                    ...defaultForm.hindi as ILanguageObject,
                    stateError: state !== "" ? "" : "State Field cannot be empty"
                };
            }
            return { defaultForm };
        });
        this.getDistrictData(state);
    }

    handleDistrictSelectChange = (value: any) => {
        this.setState({
            districtId: value,
            talukaId: "",
        })
        this.setState(prevState => {

            const defaultForm = { ...prevState.defaultForm };  

            if ( defaultForm.gujarati ) {
                 defaultForm.gujarati  =  { 
                     ...defaultForm.gujarati as ILanguageObject,
                     districtError:  this.state.districtDropDownOptions.length ==  0 ||  value !== "" ? ""  :  "District Field cannot be empty"
                };
            }

            if  (defaultForm.english ) {
                defaultForm.english = { 
                    ...defaultForm.english as ILanguageObject,
                    districtError: this.state.districtDropDownOptions.length == 0 || value !== "" ? "" : "District Field cannot be empty"
                };
            }
            if (defaultForm.hindi )  { 
                defaultForm.hindi = { 
                     ...defaultForm.hindi as ILanguageObject,
                    districtError :  this.state.districtDropDownOptions.length == 0 ||  value !== "" ?  "" : " District Field cannot be empty"
                };
            }

            return { defaultForm };
        });

    }

    handleDistrictSelectChangeTal = (value: any) => {
        this.setState({
            districtId: value 
        })
        this.setState(prevState  =>  { 
            const defaultForm = { ...prevState.defaultForm }; 
             if (defaultForm.english) { 
                 defaultForm.english = { 
                    ...defaultForm.english as  ILanguageObject, 
                    districtError: this.state.districtDropDownOptions.length    == 0 || value !== "" ? "" : "District Field cannot be empty"
                };
            }
             if (defaultForm.gujarati) {
                defaultForm.gujarati = {
                    ...defaultForm.gujarati  as    ILanguageObject,
                    districtError: this.state.districtDropDownOptions.length == 0 ||  value !== "" ? "" :   "District Field cannot be empty"
                };
            }
            if (defaultForm.hindi) { 
                defaultForm.hindi = { 
                     ...defaultForm.hindi  as  ILanguageObject,
                     districtError:   this.state.districtDropDownOptions.length ==   0  || value !==  "" ?  "" :  "District Field cannot be empty"
                };   
            }

            return { defaultForm };     
        });   

        this.getTalukaData(value);

    }

    getTalukaData = (districtId: string | null) => {
        this.setState({
            // INITAL
            talukaId: "",
            // LIST
            talukaDropDownOptions: []
        })
        if (districtId) {

            const header = { token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTalukaDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPointDistrict}taluka_listing?district_id=${districtId}`
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    handleStateSelectChange = (value: any) => {
        this.setState({
            stateId: value,
            districtId: "",
            talukaId: "",
        })
        this.setState(prevState => {
            const defaultForm = { ...prevState.defaultForm };
            if (defaultForm.english) {
                defaultForm.english = {
                    ...defaultForm.english as ILanguageObject,
                    stateError: value !== "" ? "" : "State Field cannot be empty"
                };
            }
            if (defaultForm.gujarati) {
                defaultForm.gujarati = {
                    ...defaultForm.gujarati as ILanguageObject,
                    stateError: value !== "" ? "" : "State Field cannot be empty"
                };
            }
            if (defaultForm.hindi) {
                defaultForm.hindi = {
                    ...defaultForm.hindi as ILanguageObject,
                    stateError: value !== "" ? "" : "State Field cannot be empty"
                };
            }
            return { defaultForm };
        });

    }

    validateAllForms = () => {
        const allForms = [...this.state.formArray]
        let isFormValidated = true
        let isErrorEnglish = false;
        let isErrorGujarati = false;
        let isErrorHindi = false;
        allForms.forEach((newForm) => {
            for (const language in newForm) {
                if (isLanguageKey(language)) {
                    const currentLanguage = newForm[language] as ILanguageObject;
                    if (currentLanguage?.value?.trim() == '') {
                        currentLanguage.error = "Field cannot be empty";
                        isFormValidated = false;
                        ({ isErrorEnglish, isErrorGujarati, isErrorHindi } = languageError(language, isErrorEnglish, isErrorGujarati, isErrorHindi));
                    } else if (currentLanguage?.error) {
                        currentLanguage.error = "";
                    }
                }
            }
        })
        // SET THE NEW VALUE AND ERROR
        this.setState({
            formArray: allForms,
        })

        // CHANGE TAB BASED ON ERROR
        if (this.changeTab(isErrorEnglish, isErrorGujarati, isErrorHindi)) {
            isFormValidated = false
        } else {
            isFormValidated = true
        }

        return isFormValidated
    }

    handleSubmit = () => {
        this.setState({ defaultForm: { ...defaultValue } })
        if (this.validateAllForms()) {
            this.props?.onSubmit && this.props?.onSubmit(this.state.formArray)
        }
    }




    // Customizable Area End
}
import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Input,
    InputAdornment,
    styled,
    Grid
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import NotificationTypeController, { Props, configJSON } from "./NotificationTypeController.web";
import { DataNotFound, LoaderCenter } from "../../../../components/src/AdminConsoleHelper";
import { IDropdownDetails, IHeadings } from "../../../../components/src/interface.web";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleDropdown from "../Registration/SimpleDropdown.web";
import ErrorModal from "../../../../components/src/ErrorModal.web";

const formateSimpleDropdownData = (data: IDropdownDetails[]) => {
    let formattedData = []
    formattedData = data[0]?.data.map((obj) => {
        return {
            "id": obj.id,
            "english": {
                value: obj.name,
                error: ""
            },
            "gujarati": {
                value: obj.name_gujrati,
                error: ""
            },
            "hindi": {
                value: obj.name_hindi,
                error: ""
            }
        }
    })
    return formattedData
}
// Customizable Area End

export default class NotificationType extends NotificationTypeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderAccordianDetails = (item: IHeadings) => {
        return (
            <SimpleDropdown dropdownName={item.heading} id="" navigation={undefined} data-testid="simple-dropdown"
                dataArray={formateSimpleDropdownData(this.state.dropdownDetailsData)}
                onSubmit={this.handleSubmit}
                submitLoading={this.state.updateDropdownLoading}
            />
        )
    }
    renderHeader = () => {
        return (
            <HeaderBoxWrapper>
                <Typography style={style.headerTitle}>
                    {this.renderTitle()}
                    </Typography>
            </HeaderBoxWrapper>
        )
    }
    renderAccordians = (data: IHeadings[]) => {
        return (
            <>
                {data.map((item) => {
                    return (
                        <Box>
                            <CustomAccordian
                                expanded={this.state.currentExpanded === item.heading}
                                onChange={this.handleCurrentExpanded(item.heading)}
                                data-testid={`accordian`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ width: "28px", height: "28px", color: "#1C2324", opacity: 0.72 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className="sub-dropdown-title">{item.heading}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className="main-accordian-details-wrapper">
                                    {this.state.dropdownDetailsLoading ? <LoaderCenter loadingStyle={style.smallLoader} /> : <></>}
                                    {(!this.state.dropdownDetailsLoading && this.state.currentExpanded == item.heading) ? this.renderAccordianDetails(item) : <></>}
                                </AccordionDetails>
                            </CustomAccordian>
                        </Box>
                    )
                })}

            </>
        )
    }
    renderDropdowns = () => {
        const { headingsData:DropdownList } = this.state;

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(DropdownList)}
                    </Grid>
                    

                </Grid>
            </Box>
        )
    }

    renderMsgModal = () => {
        return (
            <>
                <ErrorModal
                    open={this.state.OpenCustomModal}
                    errorTitle={this.state.CustomModalTitle}
                    errorSubTitle={this.state.CustomModalMsg}
                    BtnTitle="Okay"
                    handleBtnClick={this.handleCloseCustomModal}
                />
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.mainWrapper}>
                    {this.renderHeader()}
                    {this.state.fetchHeadingsLoading ? <LoaderCenter /> : <></>}
                    {(!this.state.fetchHeadingsLoading && this.state.headingsData.length) ? this.renderDropdowns() : <></>}
                    {(!this.state.fetchHeadingsLoading && !this.state.headingsData.length) ? <DataNotFound fontStyle={{ ...style.noDataTxt, marginTop: "50px" }} /> : <></>}
                    {this.state.OpenCustomModal ? this.renderMsgModal() : <></>}
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start   


const HeaderBoxWrapper = styled(Box)({
    marginBottom: "24px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: "nowrap",
    rowGap: "8px",
    "@media (max-width: 695px)": {
        flexWrap: "wrap",
        width: "100%"
    }
})
const CustomAccordian = styled(Accordion)({
    backgroundColor: "#FCFAF7 !important",
    borderRadius: "12px !important",
    "& .MuiAccordionSummary-root": {
        height: "52px !important",
        minHeight: "52px !important",
        padding: "0 12px 0 16px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
        "padding": "12px !important"
    }
})

const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%"
    } as React.CSSProperties,
    dropdownGrid: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "16px",
        width: "100%"
    } as React.CSSProperties,
    // NO DATA FOUND
    noDataTxt: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#1C2324",
        textAlign: "center"
    } as React.CSSProperties,
    smallLoader: {
        height: "24px",
        width: "24px"
    } as React.CSSProperties,
}
// Customizable Area End

import React from "react";
import {
  AccordionDetails,
  Box,
  Table,
  TableBody,
  IconButton,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Tooltip
} from "@material-ui/core";
import { IFilteredActivityResponse, CropDetailIdEntityCommon } from "../interface.web";
import { dateDDMMYYYY, returnTruthyString } from "../helper";

interface CommonActivityDetailProps {
  data: IFilteredActivityResponse[];
  deleteActivity: (id: string) => void;
  handleEditActivity: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined) => void;
  handleShareLink: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined) => void;
  selectedActivity: string;
}

const CommonActivityDetails = ({
  data = [],
  deleteActivity,
  handleEditActivity,
  selectedActivity,
  handleShareLink
}: CommonActivityDetailProps) => {

  const getPreFixAndSuffix = (label: string, value: string | Date | number) => {
    if (label.toLowerCase().includes("cost")) {
      return "₹ " + value.toString() + "/-"
    } else {
      return value.toString()
    }
  }

  // RENDER TABLE ROW
  const rendertableRow = (label: string, value: string | Date | number | null | undefined, unit?: string) => {
    const formattedValue = (value !== "" && value !== null && value !== undefined) ? getPreFixAndSuffix(label, value) : "-";
    return (
      <TableRow>
        <TableCell
          style={{
            ...styleWeb.tableCell,
            ...styleWeb.opacity64,
          }}
        >
          {label}
        </TableCell>
        <TableCell style={styleWeb.tableCell}>{formattedValue}{" "}{unit}</TableCell>
      </TableRow>
    );
  };

  const getDateLabel = (pestType: string) => {
    if (pestType.toLowerCase().includes("trap")){
      return "Date of Installation"
    } else if(pestType.toLowerCase().includes("bio")) {
      return "Date of Release"
    } else {
      return "Date of Application"
    }
  }

  const hasValue = (value: string | Date | number | null | undefined) => {
    return value !== null && value !== undefined && value !== "";
  }

  const flatten:any = (arr: CropDetailIdEntityCommon[] | [CropDetailIdEntityCommon][]) => {
    const flatArr = [];
    for (const val of arr) {
      if (Array.isArray(val)) {
        flatArr.push(...flatten(val));
      } else {
        flatArr.push(val);
      }
    }
    return flatArr;
  }

  const renderTable = (dataItem: IFilteredActivityResponse) => {
    const { data } = dataItem ?? {}
    const { id, attributes } = data ?? {};
    const { land_detail_id, crop_id, freeze_record, sent_delete_request, crop_ids,
      // Cropfailure
      date_of_uprooting_crop, uprooting_reason_id, uprooted_crop_area, unit_of_uprooted_crop_area_id, farm_next_action_id,
      // Gapfilling
      date, labor_cost, price_of_seed,
      // Weed management
      weeding_date, machine_charges, cost_of_weedicide, quantity_of_weedicide, labor_cost_of_spraying, bullock_drawn_cost, fuel_consumption, weedicide_id, weeding_type_id, fuel_consumption_unit_id, weedicide_unit_id ,
      // Harvest
      date_of_picking, quantity_picked, unit_of_quantity_picked_id, total_picking_cost, harvest_expences_kg, storage_type_id, default_unit_of_quantity_picked_id,
      // Irrigation
      date_of_irrigation, hours_of_irrigation, source_irrigation_id, type_irrigation_id, spacing_of_dripper, drip_irrigated_area, row_to_row_spacing, number_of_nozels, irrigation_cost, horizontal_distance, motor_capacity_in_hp, depth_of_pump, dripper_discharge, unit_of_depth_of_pump, unit_of_motor_capacity_in_hp, unit_of_horizontal_distance, unit_of_dripper_discharge, unit_of_row_to_row_spacing,unit_of_drip_irrigated_area, unit_of_spacing_of_dripper,  
      // Nutrient
      nutrient_name_id, nutrient_managment_id, date_of_application, quantity_of_nutrient, fertilizer_cost,unit_of_measure_id,
      // Pest management
      machine_cost_of_spraying, pesticide_cost, cost_of_input, quantity, quantity_unit_id, quantity_pesticide, unit_of_quantity_pesticide_id, number_of_trap, bio_agent_id, pest_managment_id, type_of_trap_id, pesticide_id, cost_of_trap,
      // Sell
      date_of_sell, quantity_of_crop_sold, crop_price, total_amount, distance_from_farmer_location_to_buyer, buyer_name, transportation_cost, labor_cost_of_load_and_unload, vehical_type_id, unit_of_quantity_of_crop_sold_id, unit_of_distance_location_id, default_unit_crop_info
    } = attributes ?? {};

    let farm_id = "";let farm_name = "";let cropIds = "";let crop_name = "";
    let freezeRecord = false;

    if (Array.isArray(land_detail_id)) {
      farm_id = returnTruthyString(land_detail_id && land_detail_id[0]?.id)
      farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)
    } else {
      farm_id = returnTruthyString(land_detail_id && land_detail_id?.id)
      farm_name = returnTruthyString(land_detail_id && land_detail_id?.name)
    }

    if (selectedActivity === "PEST_MANAGEMENT") {
      let crop_id_name = flatten(crop_id)
      cropIds = crop_id_name?.map((crop: { name: string, id: string }) => String(crop.id))
      crop_name = crop_id_name?.map((crop: { name: string, id: string }) => String(crop?.name)).join(", ")
      freezeRecord = freeze_record ? freeze_record : false;
    } else if (selectedActivity === "IRRIGATION") {
      if (!Array.isArray(crop_ids) && crop_ids?.data) {
        let crop_id_name = flatten(crop_ids?.data)
        cropIds = crop_id_name?.map((crop: { id: string }) => String(crop.id))
        crop_name = crop_id_name?.map((crop: { attributes: { crop_name_id: { name: string } } }) => String(crop?.attributes.crop_name_id.name)).join(", ")
      }
      freezeRecord = freeze_record ? freeze_record : false;
    } else if (selectedActivity === "NUTRIENT_MANAGEMENT") {
      if (!Array.isArray(crop_ids) && crop_ids?.data) {
        let crop_id_name = flatten(crop_ids?.data)
        cropIds = crop_id_name?.map((crop: { id: string }) => String(crop.id))
        crop_name = crop_id_name?.map((crop: { attributes: { crop_name_id: { name: string } } }) => String(crop?.attributes.crop_name_id.name)).join(", ")
      }
      freezeRecord = freeze_record ? freeze_record : false;
    } else if (selectedActivity === "CROP_FAILURE") {
      let crop_id_name = flatten([crop_id])
      cropIds = returnTruthyString(crop_id_name && crop_id_name[0]?.id)
      crop_name = returnTruthyString(crop_id_name && crop_id_name[0]?.name)
      freezeRecord = crop_id_name[0].crop_ended || sent_delete_request || freeze_record;
    } else {
      let crop_id_name = flatten(crop_id)
      cropIds = returnTruthyString(crop_id_name && crop_id_name[0]?.id)
      crop_name = returnTruthyString(crop_id_name && crop_id_name[0]?.name)
      freezeRecord = freeze_record ? freeze_record : false;
    }

    const quantity_of_crop_sold_unit_value = returnTruthyString(unit_of_quantity_of_crop_sold_id && unit_of_quantity_of_crop_sold_id[0]?.name)
    const quantity_of_crop_sold_value = String(quantity_of_crop_sold) + " " + quantity_of_crop_sold_unit_value
    const crop_price_heading = "Crop Price" + "/" + returnTruthyString(default_unit_crop_info && default_unit_crop_info[0]?.name)
    const crop_price_value = "₹ " + String(crop_price)
    const distance_unit_value = returnTruthyString(unit_of_distance_location_id && unit_of_distance_location_id[0]?.name)
    const distance_value = String(distance_from_farmer_location_to_buyer) + " " + distance_unit_value

    const quantity_picked_unit_value = returnTruthyString(unit_of_quantity_picked_id && unit_of_quantity_picked_id[0]?.name)
    const quantity_picked_value = String(quantity_picked) + " " + quantity_picked_unit_value
    const harvest_headng = "Harvest expense" + "/" + returnTruthyString(default_unit_of_quantity_picked_id && default_unit_of_quantity_picked_id[0]?.name)
    const harvest_expense_kg_value = "₹ " + String(harvest_expences_kg)

    return (
      <Box style={{ width: "100%", borderTop: "1px solid #F0EBE0", }} key={id}>
        <Table>
          <TableHead>
            <TableRow style={styleWeb.tableHead}>
              <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
              <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name ?? "-"}</span></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedActivity === "CROP_FAILURE" && 
            <>
              {date_of_uprooting_crop && rendertableRow("Date of Uprooting Crop", dateDDMMYYYY(date_of_uprooting_crop))}
              {uprooting_reason_id && rendertableRow("Reason of Uprooting", uprooting_reason_id?.name)}
              {uprooted_crop_area !== null && uprooted_crop_area !== undefined && rendertableRow("Uprooted Crop Area", uprooted_crop_area, unit_of_uprooted_crop_area_id?.name )}
              {farm_next_action_id && rendertableRow("Next Action on Farm", farm_next_action_id?.name)}
            </>
            }
            {selectedActivity === "GAP_FILLING" &&
              <>
                {hasValue(date) && rendertableRow("Gap Filling Date", dateDDMMYYYY(date as string))}
                {hasValue(price_of_seed) && rendertableRow("Price of Seed", price_of_seed)}
                {hasValue(labor_cost) && rendertableRow("Labor Cost", labor_cost)}
              </>
            }
            {selectedActivity === "NUTRIENT_MANAGEMENT" &&
              <>
                {nutrient_name_id && rendertableRow("Nutrient Name", nutrient_name_id?.name)}
                {nutrient_managment_id && rendertableRow("Nutrient Management Type", nutrient_managment_id?.name)}
                {date_of_application && rendertableRow("Date of Application", dateDDMMYYYY(date_of_application))}
                {hasValue(quantity_of_nutrient) && rendertableRow("Nutrient Quantity", quantity_of_nutrient)}
                {hasValue(fertilizer_cost) && rendertableRow("Fertilizer Cost", fertilizer_cost)}
                {hasValue(labor_cost) && rendertableRow("Labor Cost", labor_cost)}
              </>
            }
            {selectedActivity === "IRRIGATION" &&
              <>
                {source_irrigation_id && rendertableRow("Source of Irrigation", source_irrigation_id[0].name)}
                {type_irrigation_id && rendertableRow("Type of Irrigation", type_irrigation_id[0].name)}
                {date_of_irrigation && rendertableRow("Date of Irrigation", dateDDMMYYYY(date_of_irrigation))}
                {hasValue(hours_of_irrigation) && rendertableRow("Hours of Irrigation", hours_of_irrigation)}
                {hasValue(irrigation_cost) && rendertableRow("Irrigation Cost", irrigation_cost)}
                {hasValue(labor_cost) && rendertableRow("Labor Cost", labor_cost)}
                {hasValue(number_of_nozels) && rendertableRow("Number of Nozzels", number_of_nozels)}
                {hasValue(spacing_of_dripper) && rendertableRow("Spacing of Drippers", spacing_of_dripper, unit_of_spacing_of_dripper?.name ?? "")}
                {hasValue(drip_irrigated_area) && rendertableRow("Drip Irrigated Area", drip_irrigated_area, unit_of_drip_irrigated_area?.name ?? "")}
                {hasValue(row_to_row_spacing) && rendertableRow("Row to row spacing", row_to_row_spacing, unit_of_row_to_row_spacing?.name ?? "")}
                {hasValue(dripper_discharge) && rendertableRow("Dripper Discharge", dripper_discharge, unit_of_dripper_discharge?.name ?? "")}
                {hasValue(horizontal_distance) && rendertableRow("Horizontal Distance", horizontal_distance, unit_of_horizontal_distance?.name ?? "")}
                {hasValue(motor_capacity_in_hp) && rendertableRow("Motor in HP", motor_capacity_in_hp, unit_of_motor_capacity_in_hp?.name ?? "")}
                {hasValue(depth_of_pump) && rendertableRow("Depth of Pump", depth_of_pump, unit_of_depth_of_pump?.name ?? "")}
              </>
            }
            {selectedActivity === "SALE" &&
              <>
                {date_of_sell && rendertableRow("Date of Sell", dateDDMMYYYY(date_of_sell))}
                {unit_of_quantity_of_crop_sold_id && rendertableRow("Quantity of Crop Sold", quantity_of_crop_sold_value)}
                {default_unit_crop_info && rendertableRow(crop_price_heading, crop_price_value)}
                {hasValue(total_amount) && rendertableRow("Total Cost", total_amount)}
                {hasValue(distance_from_farmer_location_to_buyer) && rendertableRow("Distance from Farmer location to Buyer", distance_from_farmer_location_to_buyer, distance_unit_value)}
                {hasValue(buyer_name) && rendertableRow("Buyer Name", buyer_name)}
                {hasValue(transportation_cost) && rendertableRow("Transportation Cost", transportation_cost)}
                {vehical_type_id && rendertableRow("Vehicle Type", vehical_type_id[0]?.name)}
                {hasValue(labor_cost_of_load_and_unload) && rendertableRow("Labor cost of load & unload", labor_cost_of_load_and_unload)}
              </>
            }
            {selectedActivity === "WEED_MANAGEMENT" &&
              <>
                {weeding_date && rendertableRow("Weeding Date", dateDDMMYYYY(weeding_date))}
                {weeding_type_id && rendertableRow("Weeding Type", weeding_type_id[0].name)}
                {weedicide_id && rendertableRow("Weedicide Name", weedicide_id[0].name)}
                {hasValue(quantity_of_weedicide) && rendertableRow("Quantity of Weedicide", quantity_of_weedicide, weedicide_unit_id ? weedicide_unit_id[0].name : "")}
                {hasValue(cost_of_weedicide) && rendertableRow("Cost of Weedicide", cost_of_weedicide)}
                {hasValue(labor_cost) && rendertableRow("Labor Cost", labor_cost)}
                {hasValue(fuel_consumption) && rendertableRow("Fuel Consumption", fuel_consumption, fuel_consumption_unit_id ? fuel_consumption_unit_id[0]?.name : "")}
                {hasValue(machine_charges) && rendertableRow("Machine Cost", machine_charges)}
                {hasValue(labor_cost_of_spraying) && rendertableRow("Cost of Spraying", labor_cost_of_spraying)}
                {hasValue(bullock_drawn_cost) && rendertableRow("Bullock Drawn Cost", bullock_drawn_cost)}
              </>
            }
            {selectedActivity === "PEST_MANAGEMENT" &&
              <>
                {pest_managment_id && rendertableRow("Pest Management", pest_managment_id[0].name)}
                {pesticide_id && rendertableRow("Pesticide Name", pesticide_id[0].name)}
                {pest_managment_id && rendertableRow(getDateLabel(pest_managment_id[0].name), dateDDMMYYYY(date_of_application as string))}
                {hasValue(machine_cost_of_spraying) && machine_cost_of_spraying !== undefined && rendertableRow("Machine cost for Spraying", machine_cost_of_spraying)}
                {hasValue(pesticide_cost) && pesticide_cost !== undefined && rendertableRow("Pesticide Cost", pesticide_cost)}
                {hasValue(quantity_pesticide) && quantity_pesticide !== undefined && rendertableRow("Quantity of pesticide", quantity_pesticide, unit_of_quantity_pesticide_id ? unit_of_quantity_pesticide_id[0].name : "")}
                {hasValue(labor_cost) && rendertableRow("Labor Cost", labor_cost)}
                {type_of_trap_id && rendertableRow("Type of Trap", type_of_trap_id[0]?.name)}
                {hasValue(number_of_trap) && rendertableRow("Number of Trap", number_of_trap)}
                {hasValue(cost_of_trap) && rendertableRow("Cost of Trap", cost_of_trap)}
                {bio_agent_id && rendertableRow("Bio-agent Name", bio_agent_id[0]?.name)}
                {hasValue(quantity) && rendertableRow("Quantity", quantity, quantity_unit_id ? quantity_unit_id[0]?.name : "")}
                {hasValue(cost_of_input) && rendertableRow("Cost of Input", cost_of_input)}
              </>
            }
            {selectedActivity === "HARVEST" &&
              <>
                {hasValue(date_of_picking) && rendertableRow("Date of Picking", dateDDMMYYYY(date_of_picking as string))}
                {unit_of_quantity_picked_id && rendertableRow("Quantity Picked", returnTruthyString(quantity_picked_value))}
                {hasValue(total_picking_cost) && rendertableRow("Total Picking Cost", total_picking_cost)}
                {default_unit_of_quantity_picked_id && rendertableRow(harvest_headng, harvest_expense_kg_value)}
                {storage_type_id ? rendertableRow("Storage type", storage_type_id[0]?.name) : <></>}
              </>
            }
          </TableBody>
        </Table>
        <Box style={styleWeb.tableFooter}>
          <Box style={styleWeb.iconsbox} onClick={() => handleShareLink(id, farm_id.toString(), (selectedActivity === "PEST_MANAGEMENT" || selectedActivity === "IRRIGATION" || selectedActivity === "NUTRIENT_MANAGEMENT") ? cropIds : cropIds.toString())}>
            <IconButton style={styleWeb.iconbutton}>
              <img
                src={require("../share-icons.svg")}
                alt="Share in chat"
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
            <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
          </Box>
          <Box style={styleWeb.iconsbox}>
            <Tooltip title={freezeRecord ? "Activity Freezed" : ""}>
              <Box>
                <IconButton style={styleWeb.iconbutton}
                  onClick={() => handleEditActivity(id, farm_id.toString(), (selectedActivity === "PEST_MANAGEMENT" || selectedActivity === "IRRIGATION" || selectedActivity === "NUTRIENT_MANAGEMENT") ? cropIds : cropIds.toString())}
                  disabled={freezeRecord}
                >
                  <img
                    src={require("../edit-icon.svg")}
                    alt="Edit activity"
                    style={styleWeb.actionIcon}
                  />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title={freezeRecord ? "Activity Freezed" : ""}>
              <Box>
                <IconButton
                  style={styleWeb.iconbutton}
                  onClick={() => deleteActivity(id)}
                  disabled={freezeRecord}
                >
                  <img
                    src={require("../delete-icon.svg")}
                    alt="Delete activity"
                    style={styleWeb.actionIcon}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <AccordionDetails
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxHeight: "350px",
        overflow: "auto",
        padding: "0px"
      }}
    >
      {data?.length > 0 ? (
        data.map((item) => {
          return renderTable(item);
        })
      ) : (
        <Box style={{ width: "100%" }}>
          <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
            No data found
          </Typography>
        </Box>
      )}
    </AccordionDetails>
  );
};

export default CommonActivityDetails;

const styleWeb = {
  tableCell: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "50%",
  } as React.CSSProperties,
  tableHead: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "100%",
  } as React.CSSProperties,
  highlightedText: {
    fontWeight: 500,
  } as React.CSSProperties,
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  tableFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
  } as React.CSSProperties,
  opacity64: {
    opacity: "64%",
  } as React.CSSProperties,
  headInfo: {
    fontWeight: 400,
    padding: "8px 12px",
    fontSize: "12px",
    color: "#1C2324",
    border: "1px solid #F0EBE0",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  } as React.CSSProperties,
  iconsbox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer"
  } as React.CSSProperties,
  actionIcon: {
    width: "20px",
    height: "20px",
  } as React.CSSProperties,
};

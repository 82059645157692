Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
const {
  gapFillingSvg,
  nutrientManagementSvg,
  weedManagementSvg,
  saleSvg,
  irrigationSvg,
  cropFailureSvg,
  pestManagementSvg,
  harvestSvg,
  presowingImage,
  gapFilling2Svg,
} = require("./assets");

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcropactivities";
exports.labelBodyText = "cfcropactivities Body";
exports.GET_METHOD_TYPE="GET";
exports.POST_METHOD_TYPE="POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.PATCH_METHOD_TYPE = "PATCH";
exports.DELETE_METHOD_TYPE = "DELETE";

exports.unitDropdownAPIEndPoint = "admin/bx_block_dropdown/unit_dropdowns" // UNIT DROPDOWN

exports.fetchFarmListDataAPiEndPoint = "mobile_and_web/farm_dairy/selected_farm"; // FARM LIST
exports.fetchCropListDataApiEndPoint= "mobile_and_web/farm_dairy/selected_crop"
exports.gapFillingActivityEndPoint="admin/bx_block_farm_dairy/gap_fillings"


exports.leftSideActivities = [
  {
    id: "1",
    name: "Pre-Sowing",
    icon: presowingImage,
    order:1,
    endPoint:"",
    key:"PRE_SOWING"
  },
  {
    id: "2",
    name: "Gap filling",
    icon: gapFilling2Svg,
    order:2,
    key:"GAP_FILLING",
    endPoint:"admin/bx_block_farm_dairy/gap_fillings"
  },
  {
    id: "3",
    name: "Nutrient Management",
    icon: nutrientManagementSvg,
    order:4,
    key:"NUTRIENT_MANAGEMENT",
    endPoint:""
  },
  {
    id: "4",
    name: "Irrigation",
    icon: irrigationSvg,
    order:6,
    key:"IRRIGATION",
    endPoint:""
  },
  {
    id: "5",
    name: "Sell",
    icon: saleSvg,
    key:"SALE",
    order:8,
    endPoint:""
  
  },
];

exports.rightSideActivities = [
  {
    id: "6",
    name: "Crop failure",
    icon: cropFailureSvg,
    order:9,
    key:"CROP_FAILURE",
    endPoint:""

  },
  {
    id: "7",
    name: "Weed Management",
    icon: weedManagementSvg,
    order:3,
    key:"WEED_MANAGEMENT",
    endPoint:""
  },
  {
    id: "8",
    name: "Pest Management",
    icon: pestManagementSvg,
    order:5,
    key:"PEST_MANAGEMENT",
    endPoint:""
  },

  {
    id: "9",
    name: "Harvest",
    icon: harvestSvg,
    order:7,
    key:"HARVEST",
    endPoint:""
  },
];

exports.btnExampleTitle = "CLICK ME";

//API End Point
exports.unitDropdownendPoint = "admin/bx_block_dropdown/unit_dropdowns?activity=pre_sowing&dropdown=unit_of_fuel_consumption_pre_sowing";
exports.seasonListendPoint = "admin/bx_block_farm_dairy/crop_seasons";
exports.preSowingActivityendPoint = "admin/bx_block_farm_dairy/pre_sowing_activities";
exports.farmlistendPoint = "admin/bx_block_profile_bio/land_details?account_id"


// NUTRIENT MANAGEMENT ACTIVITY STARTS
exports.NutrientTypeAPIEndPoint = "admin/bx_block_farm_dairy/nutrient_managments" // NUTRIENT MANAGEMENT TYPE
exports.nutrientManagmentsAPIEndPoint = "admin/bx_block_farm_dairy/nutrient_names" // NUTRIENT NAMES
exports.CreateNutrientAPIEndPoint = "admin/bx_block_farm_dairy/nutrients" // CREATE NUTRINET
// NUTRIENT MANAGEMENT ACTIVITY ENDS


// HARVEST ACTIVITY START
exports.storageTypesAPIEndPoint = "admin/bx_block_farm_dairy/storage_types"
exports.monoCropModalTitle = "Are you sure?"
exports.monoCropModalDesc = "Once you submit the Harvest detail, all other entries will freeze after 30 days. So please complete pending entries within 30 days."
// HARVEST ACTIVITY END


// SELL ACTIVITY START
exports.vehicleTypesAPIEndPoint = "admin/bx_block_farm_dairy/vehical_types"
// SELL ACTIVITY END


// GAP FILLING ACTIVITY START
exports.gapFillingApiEndPonit="admin/bx_block_farm_dairy/gap_fillings";
// GAP FILLING ACTIVITY ENDS

exports.createPreSowingFormEndPoint="admin/bx_block_farm_dairy/pre_sowings?account_id="

// Customizable Area End
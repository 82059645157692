export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const RegistrationSVG = require("../assets/RegistrationDD.svg");
export const FarmDiarySVG = require("../assets/FarmDiaryDD.svg");
export const MaterrapediaSVG = require("../assets/MaterrapediaDD.svg");
export const NotificationSVG = require("../assets/NotificationDD.svg");
export const UnitSVG = require("../assets/UnitsDD.svg");
export const RightArrowSVG = require("../assets/RightArrowDD.svg");

export const AddSVG = require("../assets/AddCircle.svg")
export const RemoveSVG = require("../assets/RemoveCircle.svg")

export const CheckedSVG = require("../assets/CheckedSmall.svg") // CHECK BOX
export const UnCheckedSVG = require("../assets/UnCheckedSmall.svg") // CHECK BOX

export const CheckedRadioSVG = require("../assets/checkedRadio.svg") // RADIO
export const UnCheckedRadioSVG = require("../assets/unCheckedRadio.svg") // RADIO

// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetails, IHeadings, ISimpleDropdownFormData } from "../../../../components/src/interface.web";
import { toLowerSnakeCase } from "../../../../components/src/helper";

export const configJSON = require("../config");
interface IRequestBody {
    id?: string
    name: string
    name_english: string
    name_hindi: string
    name_gujrati: string
    active: boolean
}
export interface Props {
    navigation: any;
    id: string;
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    dropdownDetailsLoading: boolean,
    dropdownDetailsData: IDropdownDetails[],
    updateDropdownLoading: boolean,
    // CUSTOM MODAL
    OpenCustomModal: boolean,
    CustomModalTitle: string,
    CustomModalMsg: string,
}
interface SS { }
// Customizable Area End

export default class NotificationTypeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    updateDetailsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    getHeadingsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            currentExpanded: null,
            // API CALLS
            fetchHeadingsLoading: false,
            headingsData: [],
            dropdownDetailsLoading: false,
            dropdownDetailsData: [],
            updateDropdownLoading: false,
            // CUSTOM MODAL
            CustomModalTitle: "",
            CustomModalMsg: "",
            OpenCustomModal: false,
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiReqCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiReqCallId) {
                if (apiReqCallId === this.getHeadingsApiCallId) {
                    this.GetHeadingsApiCallIdResp(responseJson)
                }
                if (apiReqCallId === this.getDetailsApiCallId) {
                    this.GetDetailsApiCallIdResp(responseJson)
                }
                if (apiReqCallId === this.updateDetailsApiCallId) {
                    this.UpdateDetailsApiCallIdResp(responseJson)
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    // HANDLE API CALLS
    UpdateDetailsApiCallIdResp = (response: IDropdownDetails[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                updateDropdownLoading: false
            })
            this.setCustomModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.getDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.setState({
                updateDropdownLoading: false
            })
            this.setCustomModal(true, "Error", "Something went wrong!")
        }
    }
    GetDetailsApiCallIdResp = (response: IDropdownDetails[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetailsData: response
            })
        } else {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetailsData: []
            })
        }
    }
    GetHeadingsApiCallIdResp = (response: IHeadings[] | null | undefined) => {
        if (response && response.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: response
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }

    async componentDidMount(): Promise<void> {
        const searchParams = new URLSearchParams(window.location.search);
        const dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        // FETCH HEADING
        if (dropdown && subDropdown) {
            this.getDropdownHeadings(dropdown, subDropdown)
        }
    }

    // API CALLS
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBody)[]) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        const title = this.state.currentExpanded
        if (Dropdown && subDropdown && title) {
            this.setState({
                updateDropdownLoading: true
            })
            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDetailsApiCallId = requestApiMessage.messageId;

            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
        } else {
            return
        }
    }

    getDropdownDetails = (title: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown && title) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetailsData: []
            })

            const header = { token: this.state.authToken };
            const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiCallId = requestApiMessage.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
        } else {
            return
        }
    }

    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestApiMessage.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}`
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
    }

    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const requestBody = this.formateBodyData(formData)
        this.updateDropdownDetails(requestBody)
    }

    formateBodyData = (formData: ISimpleDropdownFormData[]) => {
        let bodyData = []
        bodyData = formData.map((formObj) => {
            if (formObj?._destroy) {
                return { _destroy: formObj.id };
            }

            return {
                ...(formObj.id &&
                {
                    "id": formObj.id
                }),
                name: formObj.english.value,
                name_english: formObj.english.value,
                name_gujrati: formObj.gujarati.value,
                name_hindi: formObj.hindi.value,
                active: true
            }
        })
        return bodyData
    }

    renderTitle = () => {
        let titleName = ""
        const searchParams = new URLSearchParams(window.location.search);
        const subDropdown = searchParams.get('sub-dropdown');
        if (subDropdown) {
            titleName = subDropdown
        }

        return titleName
    }
    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetailsData: []
            }, () => {
                this.getDropdownDetails(heading)

            })
        }
    };
    handleCloseCustomModal = () => {
        this.setState({
            CustomModalMsg: "",
            OpenCustomModal: false,
            CustomModalTitle: "",
        })
    }
    setCustomModal = (open: boolean, title: string, message: string) => {
        this.setState({
            CustomModalMsg: message,
            OpenCustomModal: open,
            CustomModalTitle: title,
        })
    }
    // Customizable Area End
}
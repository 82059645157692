import React from 'react'

import {
    Box,
    Input,
    Button,
    InputAdornment,
    IconButton,
    Grid,
    FormLabel,
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateRangeIcon from "@material-ui/icons/DateRange";

interface CustomAdminUserForm {
    adminInitialValues:any,
    validationSchemaAdminobj: any,
    handleSubmitAdmin: (value:any) => void,
    getErrorMessage: (touched: any, errors: any, value: string) => boolean,
    getHelperText: (touched: any, errors: any, value: string) => boolean,
    getCustomErrorMessage: (touched: any, errors: any, value: string) => void,
    getPasswordType:() => string,
    getEyeIconPassword: () => any,
    getEyeIconConfirmPassword: () => any,
    getConfirmPasswordType: () => string,
    getSubmitFormBtnTxt:() => string,
    getshowEyeIcon:() => void,
    showEyeIconConfirm:() => void,
    disableSubmitBtn?:boolean

}

const AdminUserForm = (props: CustomAdminUserForm) => {
    const { adminInitialValues, validationSchemaAdminobj, handleSubmitAdmin, getErrorMessage, getCustomErrorMessage, getHelperText,
        getPasswordType, getEyeIconPassword, getEyeIconConfirmPassword, getConfirmPasswordType, getSubmitFormBtnTxt, getshowEyeIcon, showEyeIconConfirm,
        disableSubmitBtn=false } = props

    return (
        <>
            <Formik
                data-testid="adminFormikTestid"
                initialValues={adminInitialValues}
                validationSchema={validationSchemaAdminobj}
                onSubmit={(values) => {
                    handleSubmitAdmin(values);
                }}
                enableReinitialize={true}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                        className="customFormSpacing"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel id="firstnameAdminId" className="customLabelField">
                                    First Name
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        style={webStyle.customInputField}
                                        placeholder="Enter First Name"
                                        type="text"
                                        name="firstnameAdmin"
                                        variant="outlined"
                                        color="secondary"
                                        value={values.firstnameAdmin}
                                        onChange={handleChange}
                                        error={getErrorMessage(
                                            touched,
                                            errors,
                                            "firstnameAdmin"
                                        )}
                                        helperText={getHelperText(
                                            touched,
                                            errors,
                                            "firstnameAdmin"
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel id="lastnameAdminId" className="customLabelField">
                                    Last Name
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        style={webStyle.customInputField}
                                        placeholder="Enter Last Name"
                                        type="text"
                                        name="lastnameAdmin"
                                        variant="outlined"
                                        color="secondary"
                                        value={values.lastnameAdmin}
                                        onChange={handleChange}
                                        error={getErrorMessage(
                                            touched,
                                            errors,
                                            "lastnameAdmin"
                                        )}
                                        helperText={getHelperText(
                                            touched,
                                            errors,
                                            "lastnameAdmin"
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel
                                    id="mobileNumberAdminId"
                                    className="customLabelField"
                                >
                                    Mobile Number
                                </FormLabel>
                                <FormControl fullWidth>
                                    <Input
                                        disableUnderline
                                        fullWidth
                                        style={{
                                            marginTop: "8px",
                                            padding: "8px 8px 8px 0",
                                            background: "#FCFAF7",
                                            borderRadius: "12px",
                                            border: "1px solid #F0EBE0",
                                            height:"56px"
                                        }}
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        className="custom-mobile-input"
                                        name="mobileNumberAdmin"
                                        value={values.mobileNumberAdmin}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Box
                                                    style={{
                                                        padding: "0 16px",
                                                        borderRight: "1px solid #F0EBE0",
                                                        marginRight: "8px"
                                                    }}
                                                >
                                                    <p>+91</p>
                                                </Box>
                                            </InputAdornment>
                                        }
                                        onChange={handleChange}
                                        error={getErrorMessage(
                                            touched,
                                            errors,
                                            "mobileNumberAdmin"
                                        )}
                                    />
                                    {getCustomErrorMessage(
                                        touched,
                                        errors,
                                        "mobileNumberAdmin"
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel
                                    id="dateOfBirthAdminId"
                                    className="customLabelField"
                                >
                                    Date of Birth
                                </FormLabel>
                                <FormControl fullWidth style={{ marginTop: "8px" }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            className="customDatePicker"
                                            clearable
                                            data-testid="dateOfBirthAdminTestid"
                                            value={values.dateOfBirthAdmin}
                                            maxDate={moment().subtract(18, "years").toDate()}
                                            invalidDateMessage={`Invalid date`}
                                            placeholder="DD/MM/YYYY"
                                            onChange={(date) =>
                                                setFieldValue("dateOfBirthAdmin", date)
                                            }
                                            format="DD/MM/yyyy"
                                            inputVariant="outlined"
                                            color="secondary"
                                            name="dateOfBirthAdmin"
                                            error={getErrorMessage(
                                                touched,
                                                errors,
                                                "dateOfBirthAdmin"
                                            )}
                                            helperText={getHelperText(
                                                touched,
                                                errors,
                                                "dateOfBirthAdmin"
                                            )}
                                            keyboardIcon={
                                                <DateRangeIcon style={webStyle.customDateIcon} />
                                            }
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel
                                    id="emailAddressAdminId"
                                    className="customLabelField"
                                >
                                    Email Address
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        style={webStyle.customInputField}
                                        placeholder="Enter Email Id"
                                        type="email"
                                        name="emailAddressAdmin"
                                        variant="outlined"
                                        color="secondary"
                                        value={values.emailAddressAdmin}
                                        onChange={handleChange}
                                        error={getErrorMessage(
                                            touched,
                                            errors,
                                            "emailAddressAdmin"
                                        )}
                                        helperText={getHelperText(
                                            touched,
                                            errors,
                                            "emailAddressAdmin"
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel id="usernameAdminId" className="customLabelField">
                                    Username
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        style={webStyle.customInputField}
                                        placeholder="Enter Username"
                                        type="text"
                                        name="usernameAdmin"
                                        variant="outlined"
                                        color="secondary"
                                        value={values.usernameAdmin}
                                        onChange={handleChange}
                                        error={getErrorMessage(
                                            touched,
                                            errors,
                                            "usernameAdmin"
                                        )}
                                        helperText={getHelperText(
                                            touched,
                                            errors,
                                            "usernameAdmin"
                                        )}
                                        InputProps={{
                                            autoComplete: "new-username",
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel id="passwordAdminId" className="customLabelField">
                                    Password
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        style={webStyle.customInputField}
                                        placeholder="Enter Password"
                                        type={getPasswordType()}
                                        name="passwordAdmin"
                                        variant="outlined"
                                        color="secondary"
                                        value={values.passwordAdmin}
                                        onChange={handleChange}
                                        error={getErrorMessage(
                                            touched,
                                            errors,
                                            "passwordAdmin"
                                        )}
                                        helperText={getHelperText(
                                            touched,
                                            errors,
                                            "passwordAdmin"
                                        )}
                                        data-testid="showPasswordTestid"
                                        InputProps={{
                                            autoComplete: "new-password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            getshowEyeIcon()
                                                        }}
                                                        edge="end"
                                                    >
                                                        {getEyeIconPassword()}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="formfieldWrapper"
                            >
                                <FormLabel
                                    id="confirmPasswordAdminId"
                                    className="customLabelField"
                                >
                                    Confirm Password
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        style={webStyle.customInputField}
                                        placeholder="Enter Confirm Password"
                                        type={getConfirmPasswordType()}
                                        name="confirmPasswordAdmin"
                                        variant="outlined"
                                        color="secondary"
                                        value={values.confirmPasswordAdmin}
                                        onChange={handleChange}
                                        error={
                                            touched?.confirmPasswordAdmin &&
                                            Boolean(errors?.confirmPasswordAdmin)
                                        }
                                        helperText={
                                            touched.confirmPasswordAdmin &&
                                            errors.confirmPasswordAdmin
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        data-testId="showConfirmPasswordTestid"
                                                        onClick={() => {
                                                            showEyeIconConfirm()                                                          
                                                        }}
                                                        edge="end"
                                                    >
                                                        {getEyeIconConfirmPassword()}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Box className="modalSubmitButtonWrapper">
                            <Button
                                variant="contained"
                                data-testid="adminFormSubmitBtnTestid"
                                className="modalSubmitButton"
                                fullWidth
                                type="submit"
                                disabled={disableSubmitBtn}
                            >
                                {getSubmitFormBtnTxt()}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    )
}


// Customizable Area Start
const webStyle = {
    center: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "row",
      alignItem: "center",
    },
    contentCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "30px",
      background: "#fff",
    },
    tableWrapper: {
      background: "#F8F6F1",
      border: "2px",
      borderRadius: "12px",
    },
    tableFilterWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      padding: "25px",
    },
    mainTable: {
      height: "583px",
    },
    maincard: {
      padding: "12px 15px",
      background: "#F8F6F1",
      border: "2px solid #F0EBE0",
      borderRadius: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imageIcons: {
      width: "107px",
      height: "96px",
    },
    font: {
      position: "relative",
      "&::after": {
        content: "' '",
        width: "2px",
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "-10px",
        background:
          "linear-gradient(180deg, rgba(235, 230, 215, 0.29) 0%, #EBE6D7 49.79%, rgba(235, 230, 215, 0.31) 100%)",
      },
    },
    inputStyle: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
      width: "100%",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    buttonStyle: {
      width: "100%",
      height: "45px",
      marginTop: "40px",
      border: "none",
      backgroundColor: "rgb(98, 0, 238)",
    },
    customInputField: {
      margin: "8px 0 0 0",
    },
    customDateIcon: {
      color: "#1C2324",
      opacity: "0.48",
    },
    hide: {
      display: "none",
    },
    photoCamera: {
      width: 60,
      height: 60,
      color: "#1C2324",
      opacity: 0.5,
    },
    badgeCardRoot: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#FCFAF7",
      border: "1px solid #F0EBE0",
      borderRadius: "12px",
      minHeight: "150",
      padding: "0",
      marginTop: "8px",
      marginBottom: "20px",
    },
    badgeButton: {
      backgroundColor: "rgba(28, 35, 36, .56)",
      padding: "0.375rem",
    },
    addUserModalBtnStyle: {
      borderRadius: "28px",
      fontWeight: 700,
      lineHeight: "28px",
      display: "flex",
      alignItems: "center",
      background: "#DF5200",
      fontSize: "18px",
      color: "#fff",
      padding: "14px 32px",
      boxShadow: "none",
    }
  };
  // Customizable Area End

export default AdminUserForm;
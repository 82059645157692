import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

type FarmIdTypes=string | undefined
type CropIdTypes=string | string[] | undefined

export interface IListActivityItem {
  id: string | number,
  name: string
  icon: string,
  order: string | number,
  key: string,
  endPoint: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isAddActivity: boolean;
  handleAddActivityModal: () => void;
  accountId: string | number;
  handleAddCropModalCallback:()=>void;
  // Edit activity
  activitySelected:string
  selectActivityId:string
  isEditActivity:boolean
  selectedActivityFarmId:FarmIdTypes
  selectedActivityCropId:CropIdTypes
  selectedyear:number
  handleOpenFarmModalCallback:()=>void;
  handleEditFarm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string | number) => void;
  onCreateOrUpdate: (status: string) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  authToken: string | null;
  selectedActivity: string;
  selectActivity: string;
  addActivity: boolean;
  childState: string;
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class CfcropactivitiesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      authToken: localStorage.getItem("tokenn"),
      selectedActivity: "",
      selectActivity: "",
      addActivity: false,
      childState: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(){
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {

    if(this.props.isEditActivity !== prevProps.isEditActivity && this.props.activitySelected){
      this.setState({selectedActivity:this.props.activitySelected})
    }
  }

  handleCloseAddActivityModal = () => {
    this.setState({
      selectedActivity: "",
      selectActivity: "",
      addActivity: false
    });
    this.props.handleAddActivityModal()
  };

  handleSelectActivity=()=>{
    this.setState({
      selectedActivity: this.state.selectActivity,
      selectActivity:"",
      addActivity: true
    });
    this.props.handleAddActivityModal()
  }

  onChildCreateOrUpdate = (newState: string) => {
    this.props.onCreateOrUpdate(newState)
    this.setState({ childState: newState });
  }
  // Customizable Area End
}

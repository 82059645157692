// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICropDetails, ILatitudeLongitude, IUnitDropdown, ISelectFarmDataResponse, ISelectFarm, IUnitDropdownResponse,ICropFailureResponse, ICropFailureAttributes } from "../../../components/src/interface.web";
export const configJSON = require("./config");
import moment from "moment";
import { getSingleCropSowingDate, getBase64 } from "../../../components/src/helper";
import { getPolygonCentroid } from "../../../components/src/FarmMapping/FarmMapping.web";

type FarmIdTypes = string | undefined;
type CropIdTypes = string | string[] | undefined;
export interface Props {
  id: string;
  accountId: string | number;
  isCropFailureModal: boolean;
  isEditActivity: boolean;
  selectedActivityFarmId: FarmIdTypes;
  selectedActivityCropId: CropIdTypes;
  selectedyear: number;
  handleClose: () => void;
  handleAddCropModalCallback: () => void;
  handleOpenFarmModalCallback: () => void;
  onCreateOrUpdate: (status: string) => void;
}

export interface DropdownOptions {
  id: number;
  name: string;
  name_english: string;
  name_hindi: string;
  name_gujrati: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

interface LatLng {
  lat: number;
  lng: number;
}

interface Position {
  coords:{latitude: number;
    longitude: number;}
}
const userLocation = localStorage.getItem('userLocationCropMapping');

export interface InitialFormValues {
  id?: string | number;
  uprooting_reason: string | number;
  next_action: string | number;
  date: Date;
  uprooted_area: string;
  unit_of_uprooted_area: string;
  errors: InitialFormErrorValues;
}
interface InitialFormErrorValues {
  uprooting_reason: string;
  next_action: string;
  date: string;
  uprooted_area: string;
  unit_of_uprooted_area: string;
  [key: string]: string;
}

export interface DropdownOptions {
  id: number;
  name: string;
  name_english: string;
  name_hindi: string;
  name_gujrati: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}


const intialFormValues = {
  uprooting_reason: "",
  next_action: "",
  date: new Date(),
  uprooted_area: "",
  unit_of_uprooted_area: "",
  errors: {
    uprooting_reason: "",
    next_action: "",
    date: "",
    uprooted_area: "",
    unit_of_uprooted_area: "",
  },
};

interface S {
  // FORM STATES
  minimumDate: Date;
  formLandDetailId: string | unknown;
  formCropId: string | unknown;
  formLandDetailIdError: string;
  formCropIdError: string;
  cropFailureFormValues: InitialFormValues[];
  customMessage: string;
  customMessageTitle: string;
  authToken: string | null;
  cropNameList: ICropDetails[];
  loader: boolean;
  selectDropdownValue: string;
  selectedFarmId: string | number,
  // DROPDOWN STATES
  farmNamesList: ISelectFarm[],
  cropNamesList: any[],
  nextActionOptions: DropdownOptions[],
  unitOfFuelConsumptionList: IUnitDropdown[],
  uprootingReasonOptions: DropdownOptions[],
  // CROP-MAPPING
  openFarmMapping: boolean
  userLocation: LatLng | null
  latlngHash: string | null
  farmMappingURL: string | null;
  coordinates: LatLng[];
  markerDefault: LatLng | null;
  farmMappingFile: File | null
  tempCoordinates: LatLng[],
  openEditUserModal: boolean;
  farmMappingURLError: string;
}

interface SS {
  id: string;
}

// Customizable Area End

export default class CropFailureController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchFarmNamesDataApiCallId: string = "";
  fetchCropNameDataApiCallId: string = "";
  fetchFuelConsumptionUnitDataApiCallId: string = "";
  fetchUprootingReasonOptionsApiCallId: string = "";
  fetchNextActionOptionsApiCallId: string = "";
  createWeedManagementApiCallId: string = "";
  fetchActivityDetailsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      minimumDate: moment().subtract(360, 'days').toDate(),
      formLandDetailId: "",
      formLandDetailIdError: "",
      formCropId: "",
      formCropIdError: "",
      cropFailureFormValues: [intialFormValues],
      customMessage: "",
      customMessageTitle: "",
      authToken: localStorage.getItem("tokenn"),
      cropNameList: [],
      loader: false,
      selectDropdownValue: "",
      selectedFarmId: "",
      // DROPDOWN STATES
      farmNamesList: [],
      cropNamesList: [],
      nextActionOptions: [],
      unitOfFuelConsumptionList: [],
      uprootingReasonOptions: [],
      // CROP-MAPPING
      openFarmMapping: false,      
      userLocation: userLocation ? JSON.parse(userLocation) : { lat: 22.7, lng: 75.8 },
      latlngHash: null,
      farmMappingURL: null,
      coordinates: [],
      markerDefault: null,
      farmMappingFile: null,
      tempCoordinates: [],
      openEditUserModal: false,
      farmMappingURLError: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.fetchFarmNamesDataApiCallId:
            this.handleFetchFarmNamesDataApiCallId(responseJson);
            break;
          case this.fetchCropNameDataApiCallId:
            this.handleFetchCropListResponse(responseJson);
            break;
          case this.fetchUprootingReasonOptionsApiCallId:
            this.handleFetchUprootingReasonOptionsResponse(responseJson);
            break;
          case this.fetchNextActionOptionsApiCallId: 
            this.fetchNextActionOptionsResponse(responseJson);
            break;
          case this.fetchFuelConsumptionUnitDataApiCallId:
            this.handleFetchFuelConsumptionResponse(responseJson)
            break;
          case this.createWeedManagementApiCallId:
            this.handleWeedManagementCreationResponse(responseJson);
            break;
          case this.fetchActivityDetailsApiCallId:
            this.handleFetchActivityDetailsApiCallId(responseJson);
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {   
    this.resetErrorObject(intialFormValues.errors)
    if(this.props.isEditActivity) {
      this.fetchFarmData(this.props.accountId);
      this.fetchCropNameData(this.props.accountId);
      this.fetchFuelConsumptionUnitData()
      this.fetchUprootingReasonOptions()
      this.fetchNextActionOptions()
    }
    if(!this.props.isEditActivity){
      this.fetchFarmData(this.props.accountId);
      this.fetchFuelConsumptionUnitData()
      this.fetchUprootingReasonOptions()
      this.fetchNextActionOptions()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevState.formLandDetailId !== this.state.formLandDetailId) {
      this.fetchCropNameData(this.props.accountId);
    }
  }


  // API CALLS STARTS
  fetchFarmData = (userAccountId: string | number | null) => {
    if (!userAccountId) return
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFarmNamesDataApiCallId = reqMessage.messageId;

    const endpoint = `${configJSON.fetchFarmListDataAPiEndPoint}?user_id=${this.props.accountId}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  fetchCropNameData = (accountID: string | number) => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user",
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchCropNameDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.fetchCropListDataApiEndPoint}?farm_id=${this.state.formLandDetailId ?? this.props.selectedActivityFarmId}&user_id=${accountID}`;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchUprootingReasonOptions = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchUprootingReasonOptionsApiCallId = reqMessage.messageId;
    const endpoint = `/mobile_and_web/farm_dairy/uprooting_reasons`;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchNextActionOptions = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
      type: "admin_user"
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchNextActionOptionsApiCallId = reqMessage.messageId;
    const endpoint = `/mobile_and_web/farm_dairy/farm_next_actions`;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchFuelConsumptionUnitData = () => {
    const headers = {
      token: this.state.authToken,
      "Content-Type": configJSON.validationApiContentType,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchFuelConsumptionUnitDataApiCallId = reqMessage.messageId;
    const endpoint = `${configJSON.unitDropdownAPIEndPoint}?activity=${'crop_failure'}&dropdown=${'unit_of_area_crop_failure'}`
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  createCropFailure = async() => {
    this.setState({ loader: true})
    let url = ""
    if(this.state.farmMappingFile){
    url = await getBase64(this.state.farmMappingFile) as string
    }
    let formValues = [...this.state.cropFailureFormValues];
    const headers = {
      token: this.state.authToken,
      type: "admin_user",
      "Content-Type": configJSON.validationApiContentType
    };

    let formData = formValues.map((item) => {
      return {
        ...(item.id ? { id: item.id } : {}),
        date_of_uprooting_crop: moment(item.date).format("YYYY-MM-DD"),
        uprooting_reason_id: item.uprooting_reason,
        uprooted_crop_area: item.uprooted_area,
        unit_of_uprooted_crop_area_id: item.unit_of_uprooted_area,
        farm_next_action_id: item.next_action,
        account_id: this.props.accountId,
        crop_id: this.state.formCropId,
        land_detail_id: this.state.formLandDetailId,
        latitude_longitude: this.state.latlngHash,
        ...(url ? {uprooted_crop_mapping: url} : {})
      };
    });

    let httpBody = { farm_dairy: { crop_failure: formData }};

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createWeedManagementApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/mobile_and_web/farm_dairy/activity_crop_failures?user_id=${this.props.accountId}`);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchActivityDetails = () => {
    this.setState({ loader: true })
    const farmId = this.props.selectedActivityFarmId
    const cropIds = this.props.selectedActivityCropId
    if (farmId && cropIds && this.props.accountId) {
      const headers = {
        token: this.state.authToken,
        type: "admin_user"
      };

      const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.fetchActivityDetailsApiCallId = reqMessage.messageId;
      const endpoint = `/mobile_and_web/farm_dairy/activity_crop_failures/crop_failure_history?user_id=${this.props.accountId}land_detail_ids=${this.props.selectedActivityFarmId}&crop_ids=${this.props.selectedActivityCropId}&year=${this.props.selectedyear}`

      reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

      runEngine.sendMessage(reqMessage.id, reqMessage);
    } else {
      this.setState({ loader: false })
    }
  }

  // API CALLS ENDS

  // HANDLE API CALLS FUNCTIONS STARTS
  handleFetchFarmNamesDataApiCallId = (response: ISelectFarmDataResponse) => {
    if (response?.data?.length) {
      this.setState({ farmNamesList: response.data })
    } else {
      this.setState({ farmNamesList: [] })
    }
  }

  handleFetchCropListResponse = (response: { data: ICropDetails[] }) => {
    if (response?.data) {
      this.setState({ cropNameList: response.data } ,() => {
        if(this.props.isEditActivity){
          this.fetchActivityDetails();
        }
      });
    } else {
      this.setState({ cropNameList: [] });
    }
  };

  handleFetchUprootingReasonOptionsResponse = (response: DropdownOptions[] | null | undefined) => {
    if (response && response.length > 0) {
      this.setState({
        uprootingReasonOptions: response,
      })
    } else {
      this.setState({ uprootingReasonOptions: [] })
    }
  }

  fetchNextActionOptionsResponse = (response: DropdownOptions[] | null | undefined) => {
    if (response && response.length > 0) {
      this.setState({
        nextActionOptions: response,
      })
    } else {
      this.setState({ nextActionOptions: [] })
    }
  }

  handleFetchFuelConsumptionResponse = (response: IUnitDropdownResponse) => {
    let updatedFormValues = [...this.state.cropFailureFormValues];
    if (response && response?.data.length > 1) {
      this.setState({ unitOfFuelConsumptionList: response.data });
    } else if (response?.data.length === 1) {
      const unitId = response.data[0]?.id;
      updatedFormValues = updatedFormValues.map((item) => ({
        ...item,
        unit_of_uprooted_area: unitId ?? null,
      }));
      this.setState({ unitOfFuelConsumptionList: response.data, cropFailureFormValues: updatedFormValues });
    } else {
      this.setState({ unitOfFuelConsumptionList: [] });
    }
  }

  handleWeedManagementCreationResponse = (response: any) => {
    if (response?.errors?.length) {
      this.setState({
        loader: false,
        customMessageTitle: "Error",
        customMessage: response.errors[0] || "Something went wrong!",
      })
    } else if (response[0].data) {
      this.setState({
        loader: false,
        customMessageTitle: "Success",
        customMessage: this.props.isEditActivity ? "Crop failure updated successfully.":  "Crop failure added successfully.",
      })
      this.props.onCreateOrUpdate("Success")
    }
  }

  handleFetchActivityDetailsApiCallId = (response:{ data: ICropFailureResponse[] } ) => {
    if (response?.data?.length > 0) {
      this.formateData(response.data)
    } else {
      this.setState({ loader: false })
    }
  }
  // HANDLE API CALLS FUNCTIONS ENDS

  formateData = (dataArray: ICropFailureResponse[]) => {
    let updateInitalValue = []
    updateInitalValue = dataArray.map((item: ICropFailureResponse) => {
      const momentObject = moment(item.attributes.date_of_uprooting_crop);
      const dateObject = momentObject.toDate();

      return {
        id: item.id,
        date: dateObject,
        uprooting_reason: item.attributes.uprooting_reason_id ? item.attributes.uprooting_reason_id?.id.toString() : "",
        next_action:  item.attributes.farm_next_action_id ? item.attributes.farm_next_action_id?.id.toString() : "",
        uprooted_area: item.attributes.uprooted_crop_area?.toString() ?? "",
        unit_of_uprooted_area:  item.attributes.unit_of_uprooted_crop_area_id ? item.attributes.unit_of_uprooted_crop_area_id?.id.toString() : "",
        errors: {
          date: this.validateMinDate(moment(dateObject).toDate(), this.props.selectedActivityCropId as string),
          uprooting_reason: "",
          next_action: "",
          uprooted_area: "",
          unit_of_uprooted_area: "",
        }
      }
    })
    this.setState({
      openEditUserModal: true,
      formLandDetailId: this.props.selectedActivityFarmId ? this.props.selectedActivityFarmId : "",
      formCropId: this.props.selectedActivityCropId ? this.props.selectedActivityCropId : "",
      cropFailureFormValues: updateInitalValue,
      loader: false, 
      farmMappingURL: dataArray[0].attributes.uprooted_crop_mapping.url ? `${ dataArray[0].attributes.uprooted_crop_mapping.url}` : null,
      latlngHash: dataArray[0].attributes.latitude_longitude,
      minimumDate: getSingleCropSowingDate(this.props.selectedActivityCropId as string, this.state.cropNameList)
      ?? moment().subtract(360, 'days').toDate(),
      coordinates: this.setCoordinates(dataArray[0].attributes.latitude_longitude),
      tempCoordinates: this.setCoordinates(dataArray[0].attributes.latitude_longitude),
    })
  }

  resetErrorObject(data: InitialFormErrorValues) {
    Object.keys(data).forEach((key) => {
      if (key !== "date") {
        data[key as keyof InitialFormErrorValues] = "";
      }
    });
    return data;
  }
  setCoordinates = (latitude_longitude: string) => {
    if (latitude_longitude && String(latitude_longitude).length > 0) {
      const latLongString = JSON.parse(latitude_longitude);
      // convert string to array of object
      let coordinatesArrayTemp: ILatitudeLongitude[] = [];
      for (let i = 0; i < Object.keys(latLongString as ILatitudeLongitude).length; i++) {
        coordinatesArrayTemp.push(latLongString[i]);
      }
      // re-change key names of array
      let CoordinatesArrFinal = [];
      if (coordinatesArrayTemp.length > 0) {
        // Loop through each object in the original array and modify the keys
        for (const coordinate of coordinatesArrayTemp) {
          const modifiedCoord = {
            "lat": coordinate.latitude,
            "lng": coordinate.longitude
          };
          CoordinatesArrFinal.push(modifiedCoord);
        }
      }
      if (CoordinatesArrFinal.length > 0) {
        const centroidTemp = getPolygonCentroid(CoordinatesArrFinal)
        this.setState({ markerDefault: centroidTemp })
      }

      return CoordinatesArrFinal;
    } else {
      return []
    }
  }
  getLabelFromId = (valueId: string | number, areaUnitList: IUnitDropdown[]) => {
    let label = ""
    const resultObject = areaUnitList.find((each) => each.id.toString() === valueId.toString())

    if (resultObject) {
      label = resultObject.attributes.name
    } else {
      label = areaUnitList[0]?.attributes.name || ""
    }
    return label
  }

  toggleFarmMappingModal=()=>{
    this.setState({openFarmMapping:false})
  }

  onSaveFarmMapping = (latlngHash: any, file: any, url: string, savedCoordinates: any[]) => {
    this.setState({ latlngHash: latlngHash, openFarmMapping: false, farmMappingURL: url, farmMappingFile: file, tempCoordinates: savedCoordinates, farmMappingURLError: "" })
  }

  fetchCurrentLocation = () => {
    const successResult = (pos: Position) => {
      this.setState({userLocation:{
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }}, () => {          
          localStorage.setItem('userLocationCropMapping', JSON.stringify(this.state.userLocation))
        })
    };
    const errorResult = (error:GeolocationPositionError) => {
      this.setState({userLocation:null})
    };
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(successResult, errorResult);
    }
  };

  handleInputChange = (
    e: { target: { name: string; value: number | string | Date } }
  ) => {
    const { name, value } = e.target;
    const updatedFormValues = [...this.state.cropFailureFormValues];
    if (name in updatedFormValues[0]) {
      const { error, newError } = this.handleErrorText(value, name, 0);
      updatedFormValues[0] = {
        ...updatedFormValues[0],
        [name]: value ?? null,
        errors: {
          ...updatedFormValues[0].errors,
          [name]: error,
          ...(newError || {})
        }
      };
    }

    this.setState({ cropFailureFormValues: updatedFormValues });
  };

  handleErrorText = (value: number | Date | string, fieldName: string, index: number) => {
    let error = "";
    let newError: { [key: string]: string } | null = null;
   
    if (fieldName == "date") {
      error = value ? this.validateMinDate(value as Date, this.state.formCropId as string) : "Please enter Date";
    }

    if (fieldName == "uprooted_area") {
      let fuelValueErrors = this.handleQuantityOrUnit("uprooted_area", "unit_of_uprooted_area", value as string, index, false)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    if (fieldName == "unit_of_uprooted_area") {
      let fuelValueErrors = this.handleQuantityOrUnit("unit_of_uprooted_area", "uprooted_area", value as string, index, false)
      error = fuelValueErrors.error;
      newError = fuelValueErrors.newError
    }
    return { error, newError };
  };

  handleQuantityOrUnit = (errorKey: keyof InitialFormValues, secondaryFieldKey: keyof InitialFormValues, value: string, index: number, isOptional?: boolean) => {
    const { cropFailureFormValues } = this.state;
    let error = value ? "" : `Please enter ${errorKey.replace(/_/g, " ").replace("unit", "")}`;
    let newError = {
      [secondaryFieldKey]: cropFailureFormValues[index][secondaryFieldKey] ? "" : `Please enter ${errorKey.replace(/_/g, " ").replace("unit", "")}`
    };

    if (!value && !cropFailureFormValues[index][secondaryFieldKey] && isOptional) {
      error = "";
      newError = { [secondaryFieldKey]: "" };
    }

    if (!value && !cropFailureFormValues[index][errorKey] && isOptional) {
      error = ""; newError = { [secondaryFieldKey]: "" }
    }
    if (!value && isOptional && cropFailureFormValues[index][errorKey] && this.state.uprootingReasonOptions.length === 1) {
      error = "";
    }
    return { error, newError };
  };

  handleCloseCustomModal = () => {
    if (this.state.customMessageTitle.toLocaleLowerCase() == "success") {
      this.props.handleClose();
    }
    this.setState({ customMessage: "", customMessageTitle: "" });
  };

  checkFormValidation = (entry: InitialFormValues) => {
    entry.errors.uprooting_reason = entry.uprooting_reason ? "" : "Please select reason of uprooting";
    entry.errors.date = this.validateMinDate(entry.date, this.state.formCropId as string) ? "Please enter date" : "";
    entry.errors.next_action = !entry.next_action ? "Please select next action" : "";
    entry.errors.uprooted_area = !(entry.uprooted_area && entry.unit_of_uprooted_area) ? "Please enter uprooted area" : "";
    this.setState({ farmMappingURLError : !this.state.farmMappingURL ? "Please select uprooted crop mapping" : ""})
   
    return !this.state.farmMappingURLError && !entry.errors.uprooting_reason &&  !entry.errors.date && !entry.errors.next_action && !entry.errors.uprooted_area && !entry.errors.unit_of_uprooted_area;
  }

  isDataComplete = () => {
    let valid = true;
    const updatedFormValues = [...this.state.cropFailureFormValues];
    
    for (const entry of updatedFormValues) {
      valid = valid && this.checkFormValidation(entry);
    }

    if (!valid) {
      this.setState({ cropFailureFormValues: updatedFormValues });
    }
    return valid;
  };

  isFormComplete = () => {
    let valid = true;
    if (!this.state.formLandDetailId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        formLandDetailIdError: "Please select Farm",
      }));
    }
    if (!this.state.formCropId) {
      valid = false;
      this.setState((prev) => ({
        ...prev,
        formCropIdError: "Please select Crop",
      }));
    }
    if (!this.isDataComplete()) {
      valid = false;
    }
    return valid;
  };

  handleFormSubmit = () => {
    if (!this.isFormComplete()) {
      this.setState({
        customMessage:
          "Form data is Incomplete. Please fill all the fields correctly.",
      });
      return;
    }
    this.createCropFailure();
  };

  handleAddCrop = () => {
    this.props.handleClose();
    this.props.handleAddCropModalCallback();
  };
  handleAddFarm = ()  => {
    this.props.handleClose();
    this.props.handleOpenFarmModalCallback();
  };

  validateMinDate = (selectedDate: Date, cropValue?: string) => {
    const minimumDate = (cropValue && getSingleCropSowingDate(cropValue, this.state.cropNameList)) || moment().subtract(361, 'days').toDate();
    return (selectedDate >= minimumDate && selectedDate <= new Date()) ? "" : "Please enter valid date";
  };

  handleFarmSelect = (value: string) => {
    const updatedFormValues = this.state.cropFailureFormValues.map((item) => ({
      ...item,
      errors: { ...item.errors, date: this.validateMinDate(item.date, "") },
    }));

    this.setState({
      formLandDetailId: value as string,
      formCropId: "",
      formLandDetailIdError: "",
      minimumDate: moment().subtract(360, 'days').toDate(),
      cropFailureFormValues: updatedFormValues,
    }, () => {
      this.fetchCropNameData(this.props.accountId);
    });
  };

  handleCropSelection = (value: string) => {
    const minimumDate = getSingleCropSowingDate(value, this.state.cropNameList) || moment().subtract(360, 'days').toDate();
    this.setState({ formCropId: value, formCropIdError: "", minimumDate })
    const updatedFormValues = [...this.state.cropFailureFormValues];
    this.state.cropFailureFormValues.map((item, index) => {
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        errors: {
          ...updatedFormValues[index].errors,
          date: this.validateMinDate(item.date, value),
        }
      };
    });
    this.setState({ cropFailureFormValues: updatedFormValues });
  }
  // Customizable Area End
}
import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Grid,
} from "@material-ui/core";
import LibraryChapterController, { Props, configJSON } from "./LibraryChapterController.web";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataNotFound,  } from "../../../../components/src/AdminConsoleHelper";
import { DropdownKey, InputAndLabelFAQ, InputAndLabelLibrary, formateLibraryFAQData } from "../../../../components/src/helper";
import { LoaderCenter } from "../../../../components/src/AdminConsoleHelper";
import { IHeadings } from "../../../../components/src/interface.web";
import LibraryFAQ from "./LibraryFAQ.web";
import ErrorModal from "../../../../components/src/ErrorModal.web";
// Customizable Area End

export default class LibraryChapter extends LibraryChapterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeader = () => {
        const { dropdownName } = DropdownKey()
        return (
            <HeaderBox>
                <Typography style={style.headerTitle}>{dropdownName}</Typography>
            </HeaderBox>
        )
    }
    renderAccordianDetails = (item: IHeadings) => {
        const InputAndLabel = item.heading == configJSON.MATERRAPEDIA.LIBRARY_CHAPTER ? InputAndLabelLibrary : InputAndLabelFAQ
        return (
            <LibraryFAQ
                dataArray={formateLibraryFAQData(this.state.dropdownDetailsData)}
                InputAndLabel={InputAndLabel}
                onSubmit={this.handleSubmit}
                saveLoading={this.state.saveLoading}
                data-testid='library-faq-component'
            />
        )
    }
    renderAccordians = (data: IHeadings[]) => {
        return (
            <>
                {data.map((item) => {
                    return (
                        <Box>
                            <CustomAccordian data-testid='dropdown-accordian'
                                expanded={this.state.currentExpanded === item.heading}
                                onChange={this.handleCurrentExpanded(item.heading)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ width: "28px", height: "28px", color: "#1C2324", opacity: 0.72 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className="sub-dropdown-title">{item.heading}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className="main-accordian-details-wrapper">
                                    {this.state.dropdownDetailsDataLoading ? <LoaderCenter loadingStyle={{ height: "24px", width: "24px" }} /> : <></>}
                                    {(!this.state.dropdownDetailsDataLoading && this.state.currentExpanded == item.heading) ? this.renderAccordianDetails(item) : <></>}
                                </AccordionDetails>
                            </CustomAccordian>
                        </Box>
                    )
                })}

            </>
        )
    }
    renderDropdowns = () => {
        const { headingsData } = this.state;

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item lg={12} sm={12} md={12} style={style.dropdownGrid}>
                        {this.renderAccordians(headingsData)}
                    </Grid>
                </Grid>
            </Box>
        )
    }
    NoDataFound = () => {
        return (
            <DataNotFound fontStyle={style.noDataTxt} />
        )
    }
    renderAlertModal = () => {
        return (
            <>
                <ErrorModal
                    data-testid="alert-modal"
                    open={this.state.openAlertModal}
                    errorTitle={this.state.titleAlertModal}
                    errorSubTitle={this.state.descAlertModal}
                    BtnTitle="Okay"
                    handleBtnClick={this.closeAlert}
                />
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start   
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.mainWrapper}>
                    {this.renderHeader()}
                    {this.state.headingsDataLoading ? <LoaderCenter /> : <></>}
                    {(!this.state.headingsDataLoading && this.state.headingsData.length) ? this.renderDropdowns() : <></>}
                    {(!this.state.headingsDataLoading && !this.state.headingsData.length) ? this.NoDataFound() : <></>}                    
                    {this.state.openAlertModal ? this.renderAlertModal() : <></>}
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start   
const HeaderBox = styled(Box)({
    marginBottom: "24px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: "nowrap",
    rowGap: "8px",
    "@media (max-width: 695px)": {
        flexWrap: "wrap",
        width: "100%"
    }
})
const SearchBoxWrapper = styled(Box)({
    minWidth: "300px",
    height: "40px",
    background: "#FCFAF7",
    borderRadius: "28px",
    border: "1px solid #F0EBE0",
    width: "100%",
    '& .custom_search_input>.MuiInputAdornment-positionStart': {
        margin: "0 4px 0 16px !important"
    },
    '& .custom_search_input>.MuiInputBase-input': {
        margin: "2px"
    },
    "@media (min-width: 959px) and (max-width: 1130px)": {
        minWidth: "200px",
    },
    "@media (max-width: 695px)": {
        minWidth: "200px",
        width: "100%"
    }
})

const CustomAccordian = styled(Accordion)({
    backgroundColor: "#FCFAF7 !important",
    borderRadius: "12px !important",
    "& .MuiAccordionSummary-root": {
        height: "52px !important",
        minHeight: "52px !important",
        padding: "0 12px 0 16px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
        "padding": "12px !important"
    }
})

const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    loadingStyle: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%"
    } as React.CSSProperties,
    noDataTxt: {
        color: "#1C2324",
        textAlign: "center",
        marginTop: "50px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
    } as React.CSSProperties,
    dropdownGrid: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "16px",
        width: "100%"
    } as React.CSSProperties,
}
// Customizable Area End

import React from "react";

import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  Grid,
  Tooltip
  // Customizable Area Start
    ,Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import moment from "moment";
import { configJSON } from "../../blocks/AdminConsole2/src/UserFarmDiary.web";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { dateDDMMYYYY, returnTruthyString } from "./helper";
import { CropDetailIdEntityCommon, ICropIds } from "./interface.web";

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop: '10px'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "10px",
      textTransform: 'uppercase',
      fontStyle: "normal"
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      padding: "5px 16px",
      textTransform: 'capitalize'
    },
    body1: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop: '5px',
      paddingBottom: '10px'
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});

const StyledTypography = withStyles({
  root: {},
  label: {},
  body1: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1c2324",
    marginBottom: "4px",
  },
  body2: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    marginBottom: "4px",
  },
  subtitle1: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
})(Typography);

interface FarmDiaryContent {
  handleShareLinkPopup: (linkType?: string, linkId?: any, accountId?: any, type?: any) => void;
  isLinkPopup: boolean;
  farmObj: any,
  farmType: string
}

const FarmDiaryChatContent = (props: FarmDiaryContent) => {
  const useStyles = makeStyles((theme) => ({
    modalContent: {
      width: '480px',
      "@media (max-width: 550px)": {
        width: "calc(100% - 44px)",
      },
    },
  }))
  
  const classes = useStyles();
  const { handleShareLinkPopup, isLinkPopup, farmObj, farmType } = props

  const changeElemType = (farmType: any) => {
    if(farmType){
      if (farmType.includes('pest_management')) {
        return 'pest_management'
      }
      return farmType
    }   
  }
const farmDiaryType = changeElemType(farmType);

const getPreFixAndSuffix = (label: string, value: string | Date | number) => {
  if (label.toLowerCase().includes("cost")) {
    return "₹ " + value.toString() + "/-"
  } else {
    return value.toString()
  }
}

const rendertableRow = (label: string, value: string | Date, unit: string="") => {
  return (
    <TableRow>
      <TableCell
        style={{
          ...styleWeb.tableCell,
          ...styleWeb.opacity64,
        }}
      >
        {label}
      </TableCell>
      <TableCell style={styleWeb.tableCell}>{`${value} ${unit}`}</TableCell>
    </TableRow>
  );
};

const rendertableRowCrop = (label: string, value: string | Date | number | null | undefined, unit?: string) => {
  const formattedValue = (value !== "" && value !== null && value !== undefined) ? getPreFixAndSuffix(label, value) : "-";
  return (
    <TableRow>
      <TableCell
        style={{
          ...styleWeb.tableCell,
          ...styleWeb.opacity64,
        }}
      >
        {label}
      </TableCell>
      <TableCell style={styleWeb.tableCell}>{formattedValue}{" "}{unit}</TableCell>
    </TableRow>
  );
};


const handleIrrigation = () => {
  const { date_of_irrigation, hours_of_irrigation, labor_cost, source_irrigation_id,
    type_irrigation_id, spacing_of_dripper, drip_irrigated_area, row_to_row_spacing, number_of_nozels,
    irrigation_cost, horizontal_distance, motor_capacity_in_hp, depth_of_pump, dripper_discharge,
    unit_of_depth_of_pump, unit_of_motor_capacity_in_hp, unit_of_horizontal_distance,
    unit_of_dripper_discharge, unit_of_row_to_row_spacing,unit_of_drip_irrigated_area,
    unit_of_spacing_of_dripper, crop_ids, land_detail_id } = farmObj ?? {};

const farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)
const crop_id_name = Array.isArray(crop_ids) && flatten(crop_ids)
const crop_name = Array.isArray(crop_id_name) ?  crop_id_name?.map((crop: { name: string, id: string }) => String(crop?.name)).join(", ") : []
return (
  <Table>
  <TableHead>
    <TableRow style={styleWeb.tableHead}>
      <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
      <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
    {source_irrigation_id && rendertableRow("Source of Irrigation", source_irrigation_id[0].name)}
    {type_irrigation_id && rendertableRow("Type of Irrigation", type_irrigation_id[0].name)}
    {date_of_irrigation && rendertableRow("Date of Irrigation", dateDDMMYYYY(date_of_irrigation))}
    
    {hours_of_irrigation !== null && hours_of_irrigation !== undefined && rendertableRow("Hours of Irrigation",hours_of_irrigation)}
    {irrigation_cost !== null && irrigation_cost !== undefined && rendertableRow("Irrigation Cost", irrigation_cost)}
    {labor_cost !== null && labor_cost !== undefined && rendertableRow("Labor Cost", labor_cost)}
    {number_of_nozels !== null && number_of_nozels !== undefined && rendertableRow("Number of Nozzels", number_of_nozels)}
    
    {spacing_of_dripper !== null && spacing_of_dripper !== undefined && rendertableRow("Spacing of Drippers", spacing_of_dripper,  unit_of_spacing_of_dripper?.name ?? "")}
    {drip_irrigated_area !== null && drip_irrigated_area !== undefined && rendertableRow("Drip Irrigated Area", drip_irrigated_area,  unit_of_drip_irrigated_area?.name ?? "")}
    {row_to_row_spacing !== null && row_to_row_spacing !== undefined && rendertableRow("Row to row spacing", row_to_row_spacing,  unit_of_row_to_row_spacing?.name ?? "")}
    {dripper_discharge !== null && dripper_discharge !== undefined && rendertableRow("Dripper Discharge", dripper_discharge,  unit_of_dripper_discharge?.name ?? "")}

    {horizontal_distance !== null && horizontal_distance !== undefined && rendertableRow("Horizontal Distance", horizontal_distance,  unit_of_horizontal_distance?.name ?? "")}
    {motor_capacity_in_hp !== null && motor_capacity_in_hp !== undefined && rendertableRow("Motor in HP", motor_capacity_in_hp,  unit_of_motor_capacity_in_hp?.name ?? "")}
    {depth_of_pump !== null && depth_of_pump !== undefined && rendertableRow("Depth of Pump", depth_of_pump,  unit_of_depth_of_pump?.name ?? "")}
  </TableBody>
</Table>
)
}

const handleWeedManagement = () => {
  const { weeding_date, machine_charges, labor_cost, cost_of_weedicide, quantity_of_weedicide,
    labor_cost_of_spraying, bullock_drawn_cost, fuel_consumption, weedicide_id, weeding_type_id, land_detail_id, crop_id,
    fuel_consumption_unit_id, weedicide_unit_id } = farmObj;
    return (
      <Table>
          <TableHead>
            <TableRow style={styleWeb.tableHead}>
              {land_detail_id?.length > 0 && (
                <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{land_detail_id[0]?.name ?? "-"}</span> </TableCell>
              )}
            {crop_id?.length > 0 && (
                <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_id?.[0]?.name ?? "-"}</span></TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {rendertableRow("Weeding Date", dateDDMMYYYY(weeding_date))}
            {weeding_type_id && rendertableRow("Weeding Type", weeding_type_id[0].name)}
            {weedicide_id && rendertableRow("Weedicide Name", weedicide_id[0].name)}
            {quantity_of_weedicide !== null && quantity_of_weedicide !== undefined && rendertableRow("Quantity of Weedicide", quantity_of_weedicide, weedicide_unit_id ? weedicide_unit_id[0].name : "")}
            {cost_of_weedicide !== null && cost_of_weedicide !== undefined && rendertableRow("Cost of Weedicide", cost_of_weedicide)}
            {labor_cost !== null && labor_cost !== undefined && rendertableRow("Labor Cost", labor_cost)}
            {fuel_consumption !== null && fuel_consumption !== undefined && rendertableRow("Fuel Consumption", fuel_consumption, fuel_consumption_unit_id ? fuel_consumption_unit_id[0]?.name : "")}
            {machine_charges !== null && machine_charges !== undefined && rendertableRow("Machine Cost", machine_charges)}
            {labor_cost_of_spraying !== null && labor_cost_of_spraying !== undefined && rendertableRow("Cost of Spraying", labor_cost_of_spraying)}
            {bullock_drawn_cost !== null && bullock_drawn_cost !== undefined && rendertableRow("Bullock Drawn Cost", bullock_drawn_cost)}
          </TableBody>
        </Table>
    )
}

const handlePestManagement = () => {
  const { date_of_application, machine_cost_of_spraying, pesticide_cost, cost_of_input,
    quantity, quantity_unit_id, quantity_pesticide, unit_of_quantity_pesticide_id, number_of_trap,
    crop_id, labor_cost, bio_agent_id, land_detail_id, pest_managment_id, type_of_trap_id,
    pesticide_id, cost_of_trap} = farmObj ?? {};

  let crop_id_name = Array.isArray(crop_id) && flatten(crop_id)
  let crop_name = Array.isArray(crop_id_name) ?  crop_id_name?.map((crop: { name: string, id: string }) => String(crop?.name)).join(", ") : []
  const farm_name = String(land_detail_id && land_detail_id[0]?.name)
  return(
    <Table>
    <TableHead>
      <TableRow style={styleWeb.tableHead}>
        <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
        <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {pest_managment_id && rendertableRow("Pest Management", pest_managment_id[0].name)}
      {pesticide_id && rendertableRow("Pesticide Name", pesticide_id[0].name)}
      {pest_managment_id && rendertableRow(getDateLabel(pest_managment_id[0].name), dateDDMMYYYY(date_of_application))}
      {machine_cost_of_spraying !== null && machine_cost_of_spraying !== undefined && rendertableRow("Machine cost for Spraying", machine_cost_of_spraying)}
      {pesticide_cost !== null && pesticide_cost !== undefined && rendertableRow("Pesticide Cost", pesticide_cost)}
      {quantity_pesticide !== null && quantity_pesticide !== undefined && rendertableRow("Quantity of pesticide", quantity_pesticide, unit_of_quantity_pesticide_id ? unit_of_quantity_pesticide_id[0].name : "")}
      {labor_cost !== null && labor_cost !== undefined && rendertableRow("Labor Cost", labor_cost)}
      {type_of_trap_id && rendertableRow("Type of Trap", type_of_trap_id[0]?.name)}
      {number_of_trap !== null && number_of_trap !== undefined && rendertableRow("Number of Trap", number_of_trap)}
      {cost_of_trap !== null && cost_of_trap !== undefined && rendertableRow("Cost of Trap", cost_of_trap)}
      {bio_agent_id && rendertableRow("Bio-agent Name", bio_agent_id[0]?.name)}
      {quantity !== null && quantity !== undefined && rendertableRow("Quantity", quantity, quantity_unit_id ? quantity_unit_id[0]?.name : "")}
      {cost_of_input !== null && cost_of_input !== undefined && rendertableRow("Cost of Input", cost_of_input)}
    </TableBody>
  </Table>
  )
}

const handleNutrient = () => {
  const { nutrient_name_id, nutrient_managment_id, date_of_application, quantity_of_nutrient, fertilizer_cost, labor_cost, land_detail_id, crop_ids,unit_of_measure_id } = farmObj ?? {};
        const nutrient_name_value = returnTruthyString(nutrient_name_id?.name)
        const nutrient_managment_value = returnTruthyString(nutrient_managment_id?.name)
        const date_of_application_value = dateDDMMYYYY(date_of_application)
        const unit_name = returnTruthyString(unit_of_measure_id?.name)
        const quantity_of_nutrient_value = String(quantity_of_nutrient) + " " + unit_name
        const crop_names_array = crop_ids?.data?.map((crop:ICropIds) => String(crop?.attributes?.crop_name_id?.name)).join(", ")
        
        
        const fertilizer_cost_value = "₹ " + String(fertilizer_cost) + "/-"
        const labor_cost_value = "₹ " + String(labor_cost) + "/-"
        
        const farm_name = land_detail_id?.length > 0 && String(land_detail_id[0]?.name)
        const crop_name = crop_names_array


  return (
              <Table>
                  <TableHead>
                      <TableRow style={styleWeb.tableHead}>
                          <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
                          <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name ?? "-"}</span></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {rendertableRow("Nutrient Name", nutrient_name_value)}
                      {rendertableRow("Nutrient Management Type", nutrient_managment_value)}
                      {rendertableRow("Date of Application", date_of_application_value)}
                      {rendertableRow("Nutrient Quantity", quantity_of_nutrient_value)}
                      {rendertableRow("Fertilizer Cost", fertilizer_cost_value)}
                      {rendertableRow("Labor Cost", labor_cost_value)}
                  </TableBody>
              </Table>
      )
}

const handleGapFillingForm =()=> {
  const { date, labor_cost, price_of_seed,land_detail_id,crop_id} = farmObj ?? {};

  const farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)
  const crop_name = returnTruthyString(crop_id && crop_id[0]?.name)

  return (
  <Table>
  <TableHead>
    <TableRow style={styleWeb.tableHead}>
      <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name ?? "-"}</span> </TableCell>
      <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name ?? "-"}</span></TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {rendertableRow("Gap Filling Date", dateDDMMYYYY(date))}
    {rendertableRow("Labor Cost", labor_cost)}
    {rendertableRow("Price of Seed", price_of_seed)}
  </TableBody>
</Table>
  )
}

const handleCropFailure = () => {
  const { land_detail_id, crop_id,  date_of_uprooting_crop, uprooting_reason_id, uprooted_crop_area, unit_of_uprooted_crop_area_id, farm_next_action_id, 
  } = farmObj ?? {};
  const cropName = Array.isArray(crop_id) && crop_id.length ? crop_id[0].name : ""
  return (
    <Table>
          <TableHead>
            <TableRow style={styleWeb.tableHead}>
              {
                land_detail_id?.length > 0  && (
                  <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{ land_detail_id[0]?.name ?? "-"}</span> </TableCell>
                )
              }
              <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{cropName}</span></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {date_of_uprooting_crop && rendertableRowCrop("Date of Uprooting Crop", dateDDMMYYYY(date_of_uprooting_crop))}
            {uprooting_reason_id && rendertableRowCrop("Reason of Uprooting", uprooting_reason_id?.name)}
            {uprooted_crop_area !== null && uprooted_crop_area !== undefined && rendertableRowCrop("Uprooted Crop Area", uprooted_crop_area, unit_of_uprooted_crop_area_id?.name )}
            {farm_next_action_id && rendertableRowCrop("Next Action on Farm", farm_next_action_id?.name)}
            </TableBody>
        </Table>
  )
}

const handleHarvest = () => {
        const { id: itemId, land_detail_id, crop_id, date_of_picking, quantity_picked, unit_of_quantity_picked_id, total_picking_cost, harvest_expences_kg, storage_type_id, default_unit_of_quantity_picked_id } = farmObj ?? {}

        const farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)
        const crop_name = returnTruthyString(crop_id && crop_id?.[0]?.name)

        const date_of_picking_value = dateDDMMYYYY(date_of_picking)

        const quantity_picked_unit_value = returnTruthyString(unit_of_quantity_picked_id && unit_of_quantity_picked_id?.[0]?.name)
        const quantity_picked_value = String(quantity_picked) + " " + quantity_picked_unit_value

        const total_picking_cost_value = "₹ " + String(total_picking_cost) + "/-"
        const harvest_headng = "Harvest expense" + "/" + returnTruthyString(default_unit_of_quantity_picked_id?.[0]?.name)
        const harvest_expense_kg_value = "₹ " + String(harvest_expences_kg)
        const storage_type_value = returnTruthyString(storage_type_id && storage_type_id?.[0]?.name)
  return (
<Table>
                    <TableHead>
                        <TableRow style={styleWeb.tableHead}>
                            <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name}</span> </TableCell>
                            <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rendertableRow("Date of Picking", date_of_picking_value)}
                        {rendertableRow("Quantity Picked", quantity_picked_value)}
                        {rendertableRow("Total Picking Cost", total_picking_cost_value)}
                        {rendertableRow(harvest_headng, harvest_expense_kg_value)}
                        {storage_type_value ? rendertableRow("Storage type", storage_type_value) : <></>}
                    </TableBody>
                </Table>
  )
}

const handlePreSowing = () => {
  const { land_detail_id, pre_sowing_activity_id,
    date, crop_season_id, labor_cost, cost, fuel_consumption,
    unit_of_fuel_consumption_id } = farmObj;

const farm_name = returnTruthyString(land_detail_id?.name)
const crop_season = returnTruthyString(crop_season_id && crop_season_id[0]?.name)
const pre_sowing_activity = returnTruthyString(pre_sowing_activity_id && pre_sowing_activity_id[0]?.name)
const date_value = dateDDMMYYYY(date)
const labor_cost_value = "₹ " + String(labor_cost) + "/-"
const machine_cost_value = "₹ " + String(cost) + "/-"
const fuel_consumption_unit = returnTruthyString(unit_of_fuel_consumption_id?.name)
const fuel_consumption_value = String(fuel_consumption) + " " + fuel_consumption_unit // NEED TO UPDATE
return (
  <Table>
                    <TableHead>
                        <TableRow style={styleWeb.tableHead}>
                            <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name}</span> </TableCell>
                            <TableCell style={styleWeb.headInfo}>Season: <span style={styleWeb.highlightedText}>{" "}{crop_season}</span></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rendertableRow("Pre-Sowing Activity", pre_sowing_activity)}
                        {rendertableRow("Date", date_value)}
                        {rendertableRow("Labor Cost", labor_cost_value)}
                        {rendertableRow("Machine Cost", machine_cost_value)}
                        { fuel_consumption ? rendertableRow("Fuel Consumption", fuel_consumption_value) : <></>}
                    </TableBody>
                </Table>
)
}

const flatten:any = (arr: [CropDetailIdEntityCommon][]) => {
  const flatArr = [];
  for (const val of arr) {
    if (Array.isArray(val)) {
      flatArr.push(...flatten(val));
    } else {
      flatArr.push(val);
    }
  }
  return flatArr;
}

const getDateLabel = (pestType: string) => {
  if (pestType.toLowerCase().includes("trap")){
    return "Date of Installation"
  } else if(pestType.toLowerCase().includes("bio")) {
    return "Date of Release"
  } else {
    return "Date of Application"
  }
}


const handleSell = () => {
  const { land_detail_id, crop_id,
    date_of_sell,
    quantity_of_crop_sold, crop_price, total_amount,
    distance_from_farmer_location_to_buyer,
    buyer_name,
    transportation_cost,
    labor_cost_of_load_and_unload,
    vehical_type_id,
    unit_of_quantity_of_crop_sold_id,
    unit_of_distance_location_id,
    default_unit_crop_info
} = farmObj ?? {}

const farm_name = returnTruthyString(land_detail_id && land_detail_id[0]?.name)
const crop_name = returnTruthyString(crop_id && crop_id?.[0]?.name)

const date_of_sell_value = dateDDMMYYYY(date_of_sell)

const quantity_of_crop_sold_unit_value = returnTruthyString(unit_of_quantity_of_crop_sold_id && unit_of_quantity_of_crop_sold_id[0]?.name)
const quantity_of_crop_sold_value = String(quantity_of_crop_sold) + " " + quantity_of_crop_sold_unit_value

const crop_price_heading = "Crop Price" + "/" + returnTruthyString(default_unit_crop_info && default_unit_crop_info[0]?.name)
const crop_price_value = "₹ " + String(crop_price)
const total_amount_value = "₹ " + String(total_amount) + "/-"

const distance_unit_value = returnTruthyString(unit_of_distance_location_id && unit_of_distance_location_id[0]?.name)
const distance_value = String(distance_from_farmer_location_to_buyer) + " " + distance_unit_value

const buyer_name_value = returnTruthyString(buyer_name)
const transportation_cost_value = "₹ " + String(transportation_cost) + "/-"
const vehical_type_value = returnTruthyString(vehical_type_id && vehical_type_id[0]?.name)
const labor_cost_value = "₹ " + String(labor_cost_of_load_and_unload) + "/-"

return (
<Table>
                    <TableHead>
                        <TableRow style={styleWeb.tableHead}>
                            <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{farm_name}</span> </TableCell>
                            <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_name}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rendertableRow("Date of Sell", date_of_sell_value)}
                        {rendertableRow("Quantity of Crop Sold", quantity_of_crop_sold_value)}
                        {rendertableRow(crop_price_heading, crop_price_value)}
                        {rendertableRow("Total Amount", total_amount_value)}
                        {rendertableRow("Distance from Farmer location to Buyer", distance_value)}
                        {rendertableRow("Buyer Name", buyer_name_value)}
                        {rendertableRow("Transportation Cost", transportation_cost_value)}
                        {rendertableRow("Vehicle Type", vehical_type_value)}
                        {rendertableRow("Labor cost of load & unload", labor_cost_value)}
                    </TableBody>
                </Table>
)
}

const  renderActivityDetailComponent = (key: string) => {
    switch (key) {
      case "gap_fillings":
        return handleGapFillingForm();

      case "nutrients":
        return handleNutrient();

      case "crop_failures":
        return handleCropFailure(); 

      case "weed_managements":
        return handleWeedManagement();

      case "irrigations":
        return handleIrrigation();

      case "pest_management_activities":
        return handlePestManagement(); 
     
      case "harvests":
        return handleHarvest();

      case "sells":
        return handleSell();

      case "pre_sowings":
        return handlePreSowing();
        
      default:
        return(         
          <Box style={{width:"100%"}}>
            <Typography style={{textAlign:"center",marginBottom:"10px"}}>No data found</Typography>
          </Box>        
      )
    }
}

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "528px" },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <DialogTitle className="modalDialogTitle">
              <Typography variant="subtitle1">
                {"Farm Diary"}
              </Typography>
            </DialogTitle>
          </div>
          <div data-testid="modalCloseBtnTestid"  style={styleWeb.closeButton}
          onClick={(e) => {
            const linkType = 'farmdairy'
            handleShareLinkPopup(linkType)
          }}>
              <CloseIcon />
          </div>
        </div>
        <DialogContent dividers className="modalBodyWrapper">
          <Box className={classes.modalContent} style={{ borderRadius: '12px', boxSizing: 'border-box', marginLeft: '22px', marginBottom: '15px',background:'#F0EBE0' }}>
          <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
           <Typography variant="subtitle2"> 
            {farmDiaryType && configJSON.farmDiaryScreenPopup[farmDiaryType]}
            </Typography>
           </div>  
            <Box style={{ paddingBottom: '10px', marginLeft: '10px', marginRight: '10px' }}>
               {farmType !== "" && renderActivityDetailComponent(farmType)}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start
const styleWeb = {
  tableCell: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
  } as React.CSSProperties,
  opacity64: {
    opacity: "64%",
  } as React.CSSProperties,
  tableHead: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "100%",
  } as React.CSSProperties,
  headInfo: {
    fontWeight: 400,
    padding: "8px 12px",
    fontSize: "12px",
    color: "#1C2324",
    border: "1px solid #F0EBE0",
  } as React.CSSProperties,
  highlightedText: {
    fontWeight: 500,
  } as React.CSSProperties,
  closeButton: { 
    marginRight: 26, 
    color: '#1C2324', 
    cursor: "pointer" 
  }
}
// Customizable Area End

export default FarmDiaryChatContent;
import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Paper,
    Tabs,
    Tab,
    TextField,
    FormControl,
    FormLabel,
    Badge,
    Card,
    CardMedia,
    IconButton,
} from "@material-ui/core";
import LibraryFAQController, { Props, configJSON } from "./LibraryFAQController.web";
import { Edit, AddAPhoto, } from "@material-ui/icons";
import { LoadingButton, renderErrorMessage } from "../../../../components/src/AdminConsoleHelper";
import { AddSVG, RemoveSVG } from "../assets";
import { returnImgUrl } from "../../../../components/src/helper";
import { ISubValueLibraryFAQ } from "../../../../components/src/interface.web";
// Customizable Area End

export default class LibraryFAQ extends LibraryFAQController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ImageFragmentDefault = ({ attachment }: { attachment: string | null | undefined }) => {
        const { image_placeholder } = this.props.InputAndLabel

        return attachment ? (
            <>
                <StyledBadge overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                accept="image/*"
                                className="image-contained-class"
                                style={style.hideImageInput}
                                onChange={(event) => {
                                    const editedFile = event.target.files && event.target.files[0];
                                    if (editedFile) {
                                        this.setImageDefault(editedFile)
                                    }
                                }}
                                data-testid={"edit_input_img_default"}
                                id="edit-img-input-default"
                            />
                            <label htmlFor="edit-img-input-default">
                                <IconButton component="span"
                                    aria-label="edit upload picture"
                                    disableRipple={true}
                                    style={style.badgeButton}
                                    data-testid={"edit_btn_img_default"}
                                >
                                    <Edit fontSize="small" htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card variant="outlined" style={style.badgeCardRoot} >
                        <CardMedia component="img" alt="image"
                            image={attachment}
                        />
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={style.formImageContainer}>
                    <input
                        accept="image/*"
                        type="file"
                        className="image-empty-container-class"
                        style={style.hideImageInput}
                        onChange={(event) => {
                            const newFile = event.target.files && event.target.files[0];
                            if (newFile) {
                                this.setImageDefault(newFile)
                            }
                        }}
                        data-testid={"add_input_img_default"}
                        id="add-img-input-default"
                    />
                    <Typography className="uploadTxt" style={{ textAlign: 'left' }}>
                        {image_placeholder}
                    </Typography>
                    <label htmlFor="add-img-input-default" style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <IconButton aria-label="add upload image" component={"span"} style={{ padding: 0, marginTop: "18px" }}>
                            <AddAPhoto style={style.AddAPhoto} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );
    };
    ImageFragmentList = ({ attachment, formIndex }: { attachment: string | null | undefined, formIndex: number }) => {
        const { image_placeholder } = this.props.InputAndLabel

        return attachment ? (
            <>
                <StyledBadge
                    overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                style={style.hideImageInput}
                                id={`edit-img-input-form-${formIndex}`}
                                className="image-contained-class"
                                onChange={(event) => {
                                    const newFormFileEdit = event.target.files && event.target.files[0];
                                    if (newFormFileEdit) {
                                        this.setImageForm(formIndex, newFormFileEdit)
                                    }
                                }}
                                data-testid={`edit_input_img_form-${formIndex}`}
                                accept="image/*"
                            />
                            <label htmlFor={`edit-img-input-form-${formIndex}`}>
                                <IconButton
                                    component="span"
                                    aria-label="edit upload picture"
                                    disableRipple={true}
                                    style={style.badgeButton}
                                    data-testid={`edit_btn_img_form-${formIndex}`}
                                >
                                    <Edit fontSize="small"
                                        htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card variant="outlined"
                        style={style.badgeCardRoot} >
                        <CardMedia
                            component="img"
                            image={attachment}
                            alt="cover-images"
                        />
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={style.formImageContainer}>
                    <input
                        id={`add-img-input-form-${formIndex}`}
                        accept="image/*"
                        type="file"
                        className="image-empty-container-class"
                        style={style.hideImageInput}                        
                        onChange={(event) => {
                            const newFormFile = event.target.files && event.target.files[0];
                            if (newFormFile) {
                                this.setImageForm(formIndex, newFormFile)
                            }
                        }}
                        data-testid={`add-img-input-form-${formIndex}`}
                    />
                    <Typography className="uploadTxt" style={{ textAlign: 'left' }}>{image_placeholder}</Typography>
                    <label htmlFor={`add-img-input-form-${formIndex}`} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <IconButton aria-label="upload image" component={"span"} style={{ padding: 0, marginTop: "18px" }}>
                            <AddAPhoto style={style.AddAPhoto} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );
    };
    // Customizable Area End

    render() {

        // Customizable Area Start      
        const { image_label, input_label, input_placeholder } = this.props.InputAndLabel
        const { formArray, defualtForm, selectedTab } = this.state
        const defaultFormLanguage = defualtForm[selectedTab] as ISubValueLibraryFAQ
        const image_url_default = returnImgUrl(String(defualtForm?.image))

        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.dropdownWrapper}>
                    <PaperWrapper square>
                        <Tabs
                            className="tabs-wrapper"
                            variant="fullWidth"
                            value={this.state.selectedTab}
                            onChange={this.handleChangeTab}
                            data-testid="tab-wrapper"
                        >
                            <CustomTab label="English" value={configJSON.TAB.ENGLISH} />
                            <CustomTab label="Gujarati" value={configJSON.TAB.GUJARATI} />
                            <CustomTab label="Hindi" value={configJSON.TAB.HINDI} />
                        </Tabs>
                    </PaperWrapper>

                    <Box style={{ padding: "0 16px 0px 16px", maxHeight: "400px", overflowY: "auto" }}>
                        {formArray.length ? formArray.map((form, formIndex) => {
                            const formArrayLanguage = form[selectedTab] as ISubValueLibraryFAQ
                            const image_url = returnImgUrl(String(form?.image))

                            return (
                                <>
                                    {!form?._destroy ?
                                        <Box style={style.inputWrapper} key={formIndex}>
                                            <InputBoxWrapper>
                                                <Box style={{ width: "100%" }}>
                                                    <CustomFormLabel>{input_label}</CustomFormLabel>
                                                    <FormControl fullWidth style={{ marginTop: "4px" }}>
                                                        <CustomTextField
                                                            type="text"
                                                            variant="outlined"
                                                            color="secondary"
                                                            placeholder={input_placeholder}
                                                            name="chpater_name_form"
                                                            value={formArrayLanguage?.title}
                                                            onChange={(event) => this.setValueForm(formIndex, event.target.value, selectedTab)}
                                                            data-testid="chpater_name_form"
                                                        />
                                                        {renderErrorMessage(formArrayLanguage?.title_error)}
                                                    </FormControl>
                                                </Box>
                                                <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                    <CustomFormLabel>{image_label}</CustomFormLabel>
                                                    {this.ImageFragmentList({ attachment: form?.image, formIndex })}
                                                    {renderErrorMessage(form?.image_error)}
                                                </Box>
                                            </InputBoxWrapper>
                                            <Box style={{ padding: "8px 0 0 8px", display: "flex", alignItems: "flex-start" }}>
                                                <IconButton style={style.iconBtn}
                                                    onClick={() => this.handleRemoveInput(form, formIndex)}
                                                    data-testid={`remove-input-testid-${formIndex}`}>
                                                    <img src={RemoveSVG} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        :
                                        <></>}
                                </>
                            )
                        }) : <></>}

                    </Box>
                    <Box style={{ padding: "0 16px" }}>
                        <Box style={style.inputWrapper}>
                            <InputBoxWrapper>
                                <Box style={{ width: "100%" }}>
                                    <CustomFormLabel>{input_label}</CustomFormLabel>
                                    <FormControl fullWidth style={{ marginTop: "4px" }}>
                                        <CustomTextField
                                            type="text"
                                            variant="outlined"
                                            color="secondary"
                                            placeholder={input_placeholder}
                                            name="chapter_name_default"
                                            value={defaultFormLanguage?.title}
                                            onChange={(event) => this.setValueDefault(event.target.value, selectedTab)}
                                            data-testid="chapter_name_default"
                                        />
                                        {renderErrorMessage(defaultFormLanguage?.title_error)}
                                    </FormControl>
                                </Box>
                                <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <CustomFormLabel>{image_label}</CustomFormLabel>
                                    {this.ImageFragmentDefault({ attachment: image_url_default })}
                                    {renderErrorMessage(defualtForm?.image_error)}
                                </Box>
                            </InputBoxWrapper>
                            <Box style={{ padding: "8px 0 0 8px", display: "flex", alignItems: "flex-start" }}>
                                <IconButton
                                    data-testid="add-input-testid"
                                    style={style.iconBtn}
                                    onClick={this.handleAddInput}>
                                    <img src={AddSVG} />
                                </IconButton>
                            </Box>
                        </Box>
                        <LoadingButton
                            data-testid="save-btn"
                            btnStyle={style.saveBtn}
                            loadingStyle={style.loadingStyle}
                            title={"SAVE"}
                            isLoading={this.props.saveLoading}
                            onClick={this.handleSubmit}
                        />
                    </Box>
                </Box>
            </>
            // Customizable Area End

        )
    }
}

// Customizable Area Start
const PaperWrapper = styled(Paper)({
    background: "transparent",
    "& .MuiTabs-root": {
        minHeight: "21px",
    }
})

const CustomTab = styled(Tab)({
    fontWeight: 500,
    fontSize: "14px",
    color: "#1C2324",
    opacity: "0.48 !important",
    borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
    padding: "0px",
    alignItems: "end",
    minHeight: "21px !important",
    minWidth: "0px !important",
    "&.Mui-selected": {
        color: "#DF5200 !important",
        opacity: "1 !important",
    }
})
const CustomFormLabel = styled(FormLabel)({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1C2324",
    opacity: 0.64,
    marginBottom: "4px"
})
const InputBoxWrapper = styled(Box)({
    border: "1px solid #F0EBE0",
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    width: "100%",
    "@media (min-width: 959px) and (max-width: 1220px)": {
        flexWrap: "wrap"
    },
    "@media (max-width: 610px)": {
        flexWrap: "wrap"
    }
})
const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "40px"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
        backgroundColor: "#FCFAF7 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0EBE0 !important",
    }
})

const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
        right: "20px",
        padding: "4px",
        top: "20px",
        width: "25px",
        height: "20px",
    },
}));

const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%"
    } as React.CSSProperties,
    dropdownWrapper: {
        backgroundColor: "#FCFAF7",
        minHeight: "200px",
        borderRadius: "12px",
    } as React.CSSProperties,
    saveBtn: {
        textAlign: "center",
        fontWeight: 600,
        borderRadius: "28px",
        width: "100%",
        height: "40px",
        backgroundColor: "#DF5200",
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "22px",
        marginTop: "16px"
    } as React.CSSProperties,
    loadingStyle: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
    inputWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: "16px"
    } as React.CSSProperties,

    hideImageInput: {
        display: "none"
    } as React.CSSProperties,
    badgeCardRoot: {
        width: "100%",
        height: "126px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "12px",
        minHeight: "126",
        padding: "0",
    } as React.CSSProperties,
    badgeButton: {
        backgroundColor: "rgba(28, 35, 36, .56)",
        padding: "0.375rem",
    } as React.CSSProperties,
    photoCamera: {
        width: 60,
        height: 60,
        color: "#1C2324",
        opacity: .5
    } as React.CSSProperties,
    formImageContainer: {
        backgroundColor: 'rgb(252, 250, 247)',
        border: '1px solid rgb(240, 235, 224)',
        borderRadius: '0.75rem',
        width: '100%',
        height: '126px',
        padding: '12px',
        minWidth: "233px",
        marginTop: "4px"
    } as React.CSSProperties,
    iconBtn: {
        padding: "0"
    } as React.CSSProperties,
    AddAPhoto: {
        width: '40px',
        height: '30px',
        color: '#1C2324',
        opacity: 0.32
    } as React.CSSProperties,
    dropdownGrid: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "16px",
        width: "100%"
    } as React.CSSProperties,
    // NO DATA FOUND
    noDataTxt: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "28px",
        color: "#1C2324",
        textAlign: "center",
        marginTop: "50px"
    } as React.CSSProperties,
}
// Customizable Area End
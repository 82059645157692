// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IDescription } from "../../../components/src/interface.web";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
interface S {
    authToken: string | null;
    TermsConditionsLoading: boolean,
    TermsConditionsData: IDescription | null;
}
interface SS { }
// Customizable Area End

export default class TermsAndConditionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    termsConditionsId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            TermsConditionsLoading: false,
            TermsConditionsData: null
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start            
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                if (apiRequestCallId === this.termsConditionsId) {
                    this.handleResponse(responseJson)
                }
            }
            if (errorReponse) {
                this.setState({
                    TermsConditionsLoading: false,
                    TermsConditionsData: null
                });
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start
    handleResponse = (responseJson: IDescription[] | null) => {
        if (responseJson && responseJson.length > 0) {
            this.setState({
                TermsConditionsLoading: false,
                TermsConditionsData: responseJson[0]
            })
        } else {
            this.setState({
                TermsConditionsLoading: false,
                TermsConditionsData: null
            });
        }
    }
    async componentDidMount(): Promise<void> {
        this.getTermsConditions()
    }

    getTermsConditions = () => {
        this.setState({
            TermsConditionsLoading: true,
            TermsConditionsData: null
        });

        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.termsConditionsId = requestMsg.messageId;

        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.GET_TERMS_CONDITIONS);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    // Customizable Area End
}
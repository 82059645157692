// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetails, IHeadings, IResponseDropdowns, ISimpleDropdownFormData } from "../../../../components/src/interface.web";
import { DropdownKey, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

interface IRequestBody {
    id?: string
    name: string
    name_english: string
    name_hindi: string
    name_gujrati: string
    active: boolean
}


export interface Props {
    navigation: any;
    id: string;
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    dropdownDetailsLoading: boolean,
    dropdownDetailsData: IDropdownDetails[],
    updateDropdownLoading: boolean,
    // CUSTOM MODAL
    OpenCustomModal: boolean,
    CustomModalTitle: string,
    CustomModalMsg: string,
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class PersonalDetailsDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsApiCallId: string = "";
    getDetailsApiCallId: string = "";
    updateDetailsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            fetchHeadingsLoading: false,
            headingsData: [],
            currentExpanded: null,
            dropdownDetailsLoading: false,
            dropdownDetailsData: [],
            updateDropdownLoading: false,
            // CUSTOM MODAL
            OpenCustomModal: false,
            CustomModalTitle: "",
            CustomModalMsg: "",
            searchValue: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequeCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequeCallId) {
                if (apiRequeCallId === this.getHeadingsApiCallId) {
                    this.GetHeadingsApiCallIdResp(responseJson)
                }
                if (apiRequeCallId === this.getDetailsApiCallId) {
                    this.GetDetailsApiCallIdResp(responseJson)
                }
                if (apiRequeCallId === this.updateDetailsApiCallId) {
                    this.UpdateDetailsApiCallIdResp(responseJson)
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsApiCallIdResp = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    GetDetailsApiCallIdResp = (responseJson: IDropdownDetails[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetailsData: responseJson
            })
        } else {
            this.setState({
                dropdownDetailsLoading: false,
                dropdownDetailsData: []
            })
        }
    }
    UpdateDetailsApiCallIdResp = (responseJson: IResponseDropdowns[] | null | undefined) => {

        this.setState({ updateDropdownLoading: false })
        if (!Array.isArray(responseJson) || responseJson.length === 0) {
            this.setCustomModal(true, "Error", "Something went wrong")
            return
        }
        const hasMessageKey = responseJson.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseJson);
            this.setCustomModal(true, title, message)
            if (this.state.currentExpanded) {
                this.getDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(responseJson);
        }
    }
    successUpdate = (responseJson: IResponseDropdowns[]) => {
        if (responseJson && responseJson.length) {
            this.setCustomModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.getDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.setCustomModal(true, "Error", "Something went wrong!")
        }
    }

    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }

    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = requestMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    getDropdownDetails = (title: string) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        if (dropdownName && subDropdownName && title) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetailsData: []
            })

            const header = { token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    handleSubmit = (formData: ISimpleDropdownFormData[]) => {

        const requestBody = this.formateBodyData(formData)
        this.updateDropdownDetails(requestBody)
    }
    formateBodyData = (formData: ISimpleDropdownFormData[]) => {
        let bodyData = []
        bodyData = formData.map((form) => {
            if (form?._destroy) {
                return { _destroy: form.id };
            }

            return {
                ...(form.id &&
                {
                    "id": form.id
                }),
                name: form.english.value,
                name_english: form.english.value,
                name_gujrati: form.gujarati.value,
                name_hindi: form.hindi.value,
                active: true
            }
        })
        return bodyData
    }


    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBody)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()
        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                updateDropdownLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDetailsApiCallId = requestMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestMsg.id, requestMsg);
        } else {
            return
        }
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDetailsLoading: true,
                dropdownDetailsData: []
            }, () => {
                this.getDropdownDetails(heading)

            })
        }
    };
    setCustomModal = (open: boolean, title: string, message: string) => {
        this.setState({
            OpenCustomModal: open,
            CustomModalTitle: title,
            CustomModalMsg: message,
        })
    }
    handleCloseCustomModal = () => {
        this.setState({
            OpenCustomModal: false,
            CustomModalTitle: "",
            CustomModalMsg: "",
        })
    }
    setSearchValue = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchValue: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }
    // Customizable Area End
}
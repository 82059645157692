import React from "react";
// Customizable Area Start
import {
  Avatar,
  Box,
  Grid,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  Hidden,
  IconButton,
  styled
} from "@material-ui/core";
import "./Responsive.web.css";
import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";
import MenuIcon from "@material-ui/icons/Menu";
import {
  imgfarmLogo as Logo,
  // NEW
  DHome,
  DUsers,
  DSurvey,
  DChat,
  DMaterrapedia,
  DNotification,
  DNewsEvents,
  DAppDropdown,
  DAnalytics,
  // HOVER
  HHome,
  HUsers,
  HSurvey,
  HChat,
  HMaterrapedia,
  HNotification,
  HNewsEvents,
  HAppDropdown,
  HAnalytics,
  // SELECTED
  SHome,
  SUsers,
  SSurvey,
  SChat,
  SMaterrapedia,
  SNotification,
  SNewsEvents,
  SAppDropdown,
  SAnalytics,
  BottomLogo
} from "./assets";
import { Link } from "react-router-dom";
import { ITNavMember, IPath } from "../../../components/src/interface.web";
// Customizable Area End

const { navigationScreenDefaults: screenDefaults } = configJSON;

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderHoverCSS = (path: keyof ITNavMember) => {
    return this.state.dashboard === path
      ? {
          ...styles.navigationListItem,
          backgroundColor: this.state.hover[path]
            ? "#DF5200"
            : "rgba(223, 82, 0, 0.16)",
          borderInlineStart: "4px solid #DF5200",
          color: this.state.hover[path] ? "#fff" : "#DF5200"
        }
      : {
          ...styles.navigationListItem,
          backgroundColor: this.state.hover[path] ? "#DF5200" : "transparent",
          borderInlineStart: "4px solid transparent",
          color: this.state.hover[path] ? "#fff" : "#000"
        };
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const user_profile: string =
      this.state.profile_firstname && this.state.profile_lastname
        ? `${this.state.profile_firstname[0].toUpperCase()} ${this.state.profile_lastname[0].toUpperCase()}`
        : "";

    const renderLinks = () => {
      const paths: IPath[] = [
        {
          route: "/home",
          name: "home",
          title: "Home",
          icon: DHome,
          iconExtra: SHome,
          iconHover: HHome
        },
        {
          route: "/users",
          name: "users",
          title: "Users",
          icon: DUsers,
          iconExtra: SUsers,
          iconHover: HUsers
        },
        {
          route: "/surveys-section",
          name: "survey",
          title: "Surveys",
          icon: DSurvey,
          iconExtra: SSurvey,
          iconHover: HSurvey
        },
        {
          route: "/chat",
          name: "chat",
          title: "Chat",
          icon: DChat,
          iconExtra: SChat,
          iconHover: HChat
        },
        {
          route: "/materrapedia",          
          name: "materrapedia",
          title: "Materrapedia",
          icon: DMaterrapedia,
          iconExtra: SMaterrapedia,
          iconHover: HMaterrapedia
        },
        {
          route: "/notifications",
          name: "notifications",
          title: "Notifications",
          icon: DNotification,
          iconExtra: SNotification,
          iconHover: HNotification
        },
        {
          route: "/news-and-events",
          name: "events",
          title: "News & Events",
          icon: DNewsEvents,
          iconExtra: SNewsEvents,
          iconHover: HNewsEvents
        },
        {
          route: "/app-dropdowns",
          name: "dropdowns",
          title: "App Dropdowns",
          icon: DAppDropdown,
          iconExtra: SAppDropdown,
          iconHover: HAppDropdown
        },
        {
          route: "/analytics",
          name: "analytics",
          title: "Analytics",
          icon: DAnalytics,
          iconExtra: SAnalytics,
          iconHover: HAnalytics
        }
      ];

      return paths.map((path: IPath, int) => {
        return (
          <React.Fragment key={int}>
            <Link
              to={path.route}
              style={styles.navigationLink}
              data-testid={"nav_menu_link"}
            >
              <ListItem
                button
                key={path.name}
                id={path.name}
                data-testid={"root_div:hover"}
                style={this.renderHoverCSS(path.name)}
                onMouseEnter={() =>
                  this.handleMouseOverEventNavigationSideBar(path.name)
                }
                onMouseLeave={() =>
                  this.handleMouseLeaveEventNavigationSideBar(path.name)
                }
              >
                <ListItemIcon style={styles.iconRoot}>
                  {!this.state.hover[path.name] ? (
                    <img
                      src={
                        this.state.dashboard !== path.name
                          ? path.icon
                          : path.iconHover
                      }
                      id={`icon-${path.name}`}
                    />
                  ) : (
                    <img src={path.iconExtra} id={`icon-${path.name}`} />
                  )}
                </ListItemIcon>
                <ListItemText data-testid={"nav_add_item"}>
                  <Typography style={styles.navigationTxt}>
                    {path.title}
                  </Typography>
                </ListItemText>
                {path.name === "chat" && (
                  <Avatar style={styles.badgeIcon}>
                    {this.state.chat_count}
                  </Avatar>
                )}
              </ListItem>
            </Link>
          </React.Fragment>
        );
      });
    };
    const variableLinks = renderLinks();

    const drawer = (
      <Box style={styles.drawerContainer}>
        <List style={styles.drawerList} data-testid={"nav_link_container"}>
          <ListItem
            key={"Logo"}
            id={"Logo"}
            style={styles.navigationLogoContainer}
          >
            <ListItemIcon>
              <img src={Logo} style={styles.navigationLogo} />
            </ListItemIcon>
          </ListItem>
          {variableLinks}
        </List>
        <BottomWrapper>
          <img src={BottomLogo} alt="logo" />
          <CustomBox>
            <LinkWrapper onClick={() => this.navigateTo("TermsAndCondition")} data-testid="terms-and-conditions">
              Terms & Conditions
            </LinkWrapper>
            <VerticalLine>|</VerticalLine>
            <LinkWrapper onClick={() => this.navigateTo("PrivacyPolicy")} data-testid="privacy-policy">
              Privacy Policy
            </LinkWrapper>
          </CustomBox>
        </BottomWrapper>
      </Box>
    );
    // Customizable Area End
    return (
      // Customizable Area Start
      <Grid
        container
        justifyContent="space-between"
        style={styles.rootNavigationGrid}
      >
        {/* <Grid item> */}
        <Box className="gridBox">
          <Hidden smDown>
            <Drawer
              variant="permanent"
              anchor={"left"}
              open
              PaperProps={{
                style: { width: "22%", overflow: "hidden" }
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"left"}
              open={this.state.drawerOpen}
              onClose={() => this.setState({ drawerOpen: false })}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Box style={styles.navBox}>
            <Box style={styles.appBar} position="relative">
              <Toolbar className="appBarToolbar">
                <Hidden mdUp>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => this.setState({ drawerOpen: true })}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                <Box style={styles.toolbarWrapper}>
                  {/* <Box style={styles.searchAppBar}>
                    <Box style={styles.searchIconContainer}>
                      <Search style={styles.searchIcon} fontSize="medium" />
                    </Box>
                    <InputBase
                      placeholder={screenDefaults.placeholderText}
                      inputProps={{ "aria-label": "search" }}
                      style={styles.searchInputNative}
                      classes={{ input: "searchInput" }}
                    />
                  </Box> */}

                  <Box style={styles.appBarAdminContainer}>
                    <Box style={styles.appBarAvatarContainer}>
                      <Link
                        to="/profile"
                        style={styles.navigationLink}
                      >
                        <Avatar
                          style={styles.appBarAvatar}
                          src={this.state.profileAvatar}
                        >
                          {this.state.profileAvatar && (
                            <Typography style={styles.appBarAvatarTypography}>
                              {user_profile}
                            </Typography>
                          )}
                        </Avatar>
                      </Link>
                    </Box>
                    <Box>
                      <Typography
                        style={styles.appBarAdminTextHeader}
                        noWrap
                        variant="h5"
                        className="searchInputUsername"
                      >
                        {`${this.state.profile_firstname} ${
                          this.state.profile_lastname
                        }`}
                      </Typography>
                      <Typography style={styles.appBarAdminTextTitle}>
                        {this.state.role}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Toolbar>
            </Box>
            <Box
              style={{ ...styles.rootChildren, ...this.props.childrenStyle }}
            >
              {this.props.children}
            </Box>
          </Box>
        </Box>
      </Grid>
      // </Grid >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles: any = {
  badgeIcon: {
    // backgroundColor: "#F0EBE0",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    fontSize: "0.5625rem",
    lineHeight: ".75rem",
    color: "white",
    backgroundColor: "#DF5200",
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500
  },
  iconRoot: {
    minWidth: "0",
    marginRight:"12px"
  } as React.CSSProperties,
  rootNavigationGrid: {
    flexGrow: 1,
    width: "100%"
  },
  navBox: { width: "100%", display: "flex", flexDirection: "column" },
  drawerContainer: {
    backgroundColor: "#F0EBE0",
    fontFamily: screenDefaults.fontFamily,
    overflow: "auto",
    minHeight: "100%",
    display: "flex",
    flexDirection:"column",
    justifyContent:"space-between"
  } as React.CSSProperties,

  drawerList: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  } as React.CSSProperties,

  navigationLogoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    objectFit: "cover",
    width: "100%",
    paddingBlock: "1rem",
    paddingBlockEnd: "2rem",
    paddingInline:"1.5rem"
  } as React.CSSProperties,

  navigationLogo: {
    width: "100%",
    height: "100%"
  },

  navigationLogoTxt: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: ".65rem",
    lineHeight: "1rem",
    color: "#1C2324"
  },

  navigationListItem: {
    overflow: "hidden",
    padding:"9px 20px"
  } as React.CSSProperties,

  navigationDrawer: {
    flexShrink: 0
  },

  rootChildren: {
    paddingInline: "1.5rem",
    paddingBlock: "1.5rem"
  },

  navigationTxt: {
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 400,
    fontSize: "1.25rem"
  },

  appBar: {
    display: "flex",
    paddingInline: "1.5rem",
    paddingBlock: "1.5rem",
    backgroundColor: "white",
    borderBlockEnd: "1px solid #F0EBE0",
    justifyContent: "end"
  },

  toolbarWrapper: {
    display: "flex",
    width: "100%",
    gap: "2rem",
    justifyContent: 'end'
  },

  searchAppBar: {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    fontSize: "1rem",
    borderRadius: "28px",
    display: "flex",
    width: "100%",
    alignItems: "center"
  },

  searchIconContainer: {
    padding: "1rem",
    paddingInlineEnd: "0.5rem",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  searchIcon: {
    color: "#1C2324",
    opacity: 0.48
  },

  searchInputNative: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    padding: 0,
    lineHeight: "1.5rem",
    width: "100%"
  },
  appBarAdminContainer: {
    display: "flex",
    gap: ".7rem"
  },

  appBarAvatarContainer: {} as React.CSSProperties,

  appBarAvatar: {
    width: "3rem",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#108B88"
  },

  appBarAvatarTypography: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "1.25rem",
    letterSpacing: -1.89,
    lineHeight: "1.875rem",
    color: "#FFFFFF"
  },

  appBarAdminTextHeader: {
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 500,
    lineHeight: "1.875rem",
    fontSize: "1.25rem",
    fontStyle: "normal",
    color: "#1C2324"
  },

  appBarAdminTextTitle: {
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 400,
    lineHeight: "1.37rem",
    fontSize: "0.8rem",
    fontStyle: "normal",
    color: "#1C2324",
    opacity: 0.64
  },

  navigationLink: {
    textDecoration: "none",
    color: "#000"
  }
};

const BottomWrapper = styled(Box)({
  padding: "24px"
})

const LinkWrapper = styled(Box)({
  color: "#108B88",
  fontSize: "12px",
  fontStyle: "italic",
  fontWeight: 400,
  lineHeight: "18px",
  cursor: "pointer",
  textDecoration: "underline"
})
const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "8px",
  marginTop: "12px"
})
const VerticalLine = styled(Typography)({
  color: "#108B88",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
})
// Customizable Area End

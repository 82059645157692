import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start

  CardMedia,
  CircularProgress,
  Badge,
  FormControl,
  Tabs,
  Tab,
  Grid,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  TextareaAutosize
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import { createTheme, styled } from "@material-ui/core/styles";
import moment from "moment"
import {
  Add
  , Close, Edit, PhotoCamera, PlayArrow
} from "@material-ui/icons";
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from "@material-ui/icons/Search";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import InfiniteScroll from "react-infinite-scroll-component";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import {
  FaqCategoryResp,
  FaqResponse,
  FaqssResponse,
  FormDataInitial,
  Library,
} from "./VideoManagementController";
import ViewCropModal from "../../../components/src/cropModals/ViewCrop";


// Customizable Area End

import VideoManagementController, {
  Props,
  configJSON,
} from "./VideoManagementController";
import LinkShare from "../../LinkShare/src/LinkShare.web";
import { IILibraryTopic, ILibraryChapter } from "../../../components/src/interface.web";
import { converStringToDate, getURL, returnTruthyString } from "../../../components/src/helper";
import { VideoPlayIconSVG } from "./assets";
import Cf2enhancementsfromversion1 from "../../cf2enhancementsfromversion1/src/Cf2enhancementsfromversion1";
import ErrorModal from "../../../components/src/ErrorModal.web";
export default class VideoManagement extends VideoManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTabs = () => {
    return ["English", "Gujarati", "Hindi"].map((tabs: string) => {
      return (
        <Tab key={tabs} value={tabs.toLowerCase()} label={tabs} style={{ ...styles.languageTabs, color: this.state.languageTab.toLowerCase() == tabs.toLowerCase() ? "#DF5200" : "#1C2324", }} />
      );
    })
  }
  renderFaqTopics = () => {
    return (
      <>
        {this.state.faqQuestions.length > 0 ?
          <Box style={{ padding: "16px 24px" }}>
            {this.state.faqQuestions.map((eachFaq: FaqResponse, faqIndex: number) => {
              const expandFaq = this.state.selectedFAQIdTopic === eachFaq.id
              const { image, question, updated_at, description, image_or_video, libraries_faq_id } = eachFaq.attributes
              const topicName = returnTruthyString(question)
              const lastUpdatedTopicDate = returnTruthyString(updated_at)
              const topicDescription = returnTruthyString(description)
              const isTopicContentVideo = returnTruthyString(image_or_video) == "Video"
              const topicThumbnailImageUrl = getURL(image?.thumb?.url, image?.url)
              const eachTopicImageUrl = returnTruthyString(image.url)
              const updatedEachTopicImageUrl = this.defaultImageUrl(topicThumbnailImageUrl);

              return (
                <>
                  {this.handleConditions(faqIndex > 0, <Divider style={{ backgroundColor: "#E4DDC9", height: "2px", margin: '8px 0px' }} />, <></>)}

                  <SubAccordianBoxLibrary
                    expanded={expandFaq}
                    style={{ borderRadius: "12px", backgroundColor: "transparent" }}
                    key={faqIndex}
                    data-testid="library-sub-accordion"
                    onChange={this.handleSelectFaqTopic(eachFaq.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ fontSize: 24 }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box style={this.handleConditions(expandFaq, { ...styles.boxSubLibraryChpaterImg, display: "none" }, styles.boxSubLibraryChpaterImg)}>
                        {this.handleConditions(isTopicContentVideo,
                          <Box style={{ position: 'relative' }}>
                            <img src={updatedEachTopicImageUrl} alt="Background" style={{
                              position: 'relative',
                              height: "48px",
                              width: "48px"
                            } as React.CSSProperties} />
                            <img src={VideoPlayIconSVG} alt="Overlay" style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)'
                            } as React.CSSProperties} />
                          </Box>, <img src={updatedEachTopicImageUrl} style={styles.subLibraryChpaterImg} />
                        )}
                      </Box>
                      <Box style={expandFaq ? styles.boxChapterHeadingExpanded : styles.boxChapterHeading}>
                        <Tooltip title={topicName}>
                          <Typography style={{ ...styles.libraryChapterTopicheading, maxWidth: "250px" }} noWrap>
                            {topicName}
                          </Typography>
                        </Tooltip>
                        <Box>
                          <Typography variant="inherit" style={styles.libraryLastUpdate}>Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>{converStringToDate(lastUpdatedTopicDate)}</Typography></Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <Box sx={{ margin: "0px 0 12px 0",display: "flex", justifyContent:"center" }}>
                      {this.renderImageVideo(eachTopicImageUrl, true, "150px")}
                    </Box>
                    <Typography variant="inherit" style={{ ...styles.paragraph, whiteSpace: 'pre-wrap' }}>{topicDescription}</Typography>

                    <Box
                      style={{
                        display: "flex",
                        marginTop: "13px",
                        marginBottom: "12px",
                      }}
                    >
                      <Box style={{ flex: 1, display: "flex", cursor: "pointer" }}
                        data-testId="handleShareLink"
                        onClick={() => this.handleShareLink(eachFaq)}
                      >
                        <ShareIcon
                          style={{
                            marginTop: "-1px",
                            cursor: 'pointer',
                            color: "#286b77",
                            height: "24px",
                            width: "24px",
                          }}
                        />
                        <Typography
                          variant="h6"
                          style={{
                            color: "#286b77",
                            fontWeight: 500,
                            fontSize: "14px",
                            marginLeft: "6px",
                            lineHeight: "22px",
                            fontFamily: configJSON.fontFamily
                          }}
                        >
                          Share in Chat
                        </Typography>
                      </Box>
                      <Box style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                        {this.handleConditions(libraries_faq_id?.id, <EditIcon htmlColor="#EB8F06" style={styles.icon} data-testid="edit_faq_button" onClick={() => this.handleClickFaqEditIcon(libraries_faq_id?.id)} />, null)}
                      </Box>
                      <Box>
                        {this.handleConditions(libraries_faq_id?.id, <DeleteIcon htmlColor="#B3261E" style={styles.icon} data-testid="delete_faq_button" onClick={() => this.handleClickFaqDeleteIcon(libraries_faq_id?.id)} />, null)}

                      </Box>
                    </Box>
                  </SubAccordianBoxLibrary>
                </>
              )
            })}
          </Box>
          :
          <></>
        }
      </>
    )
  }

  ImageFragment = ({ attachment }: { attachment: any }) => {
    let mediaType = this.checkMediaType(attachment?.src)
    let image = attachment.src
    if (image) {
      return <StyledBadge
        style={{ marginBottom: "16px", position: "relative" }}
        overlap="rectangular"
        data-testid="styledBadge"
        badgeContent={
          <Box sx={{ width: "100%" }}>
            <input
              type="file"
              style={styles.hide}
            ref={this.state.inputRef}
              id="edit-icon-badge-file"
              onChange={(event) => this.handleImageSelect(event)}
              data-testid={`modal_image_handle_select1`}
              accept="image/*,video/*"
            />
            <Box style={{display: "flex", alignItems: "center", gap: "17px", position: "absolute", top: "22px", right: "22px" }}>
              <label htmlFor="edit-icon-bad0ge-file">
                <IconButton
                  data-testid={"modal_image_fragment_select"}
                  component="span" 
                  aria-label="edit upload picture"
                  disableRipple={true}   
                  style={styles.badgeButton}
                  onClick={()=> this.handleClick()}
                >
                  <Edit fontSize="small" htmlColor="#fff" style={{ fontSize: "1rem" }} />
                </IconButton>
              </label>
              {(this.state.faqAddModelOpen || this.state.faqEditModel) ?(
              <label htmlFor="delete-icon-badge-file">
                <IconButton
                  data-testid={"modal_image_fragment_select"}
                  component="span"
                  aria-label="delete upload picture"
                  disableRipple={true}
                  style={styles.badgeButton}
                  onClick={this.handleFaqDeleteImage}
                >
                  <DeleteIcon fontSize="small" htmlColor="#fff" style={{ fontSize: "1rem" }} />
                </IconButton>
              </label>
                ):""
              }
            </Box>
          </ Box>
        }
      >
        {mediaType === "img" && (
          <Card variant="outlined" style={styles.badgeCardRoot}>
            <CardMedia
              component={mediaType}
              src={attachment.src}
              alt="cover-images"
            />
          </Card>
        )}
        {mediaType === "video" && (

          <CustomVideo url={attachment.src} isDetailedView={true} height="200px" width="100%" />
        )}
      </StyledBadge>
    } else {
      return <>
        <Box
          style={{
            ...styles.formImageContainer,
            border:
              this.state.alertOpen && !attachment?.src && (this.state.libraryEditModal || this.state.isLibraryModalOpen)
                ? "1px solid #f44336"
                : "1px solid #F0EBE0",
          }}
        >  <input
            accept="image/*,video/*"
            type="file"
            style={styles.hide}
            ref={this.state.inputRef}
            id="icon-button-file"
            onChange={(event) => this.handleImageSelect(event)}
            data-test-id={"modal_image_handle_select2"}
          />
          <label
            htmlFor="icon-button-file"
            style={{ display: "flex", alignItems: "center" }}
          >
            <IconButton
              data-testid={"modal_image_fragment_select"}
              aria-label="upload image"
              component={"span"}
            >
              <PhotoCamera style={styles.photoCamera} />
            </IconButton>
          </label>
        </Box>
        {this.state.alertOpen && !attachment?.src && (this.state.libraryEditModal || this.state.isLibraryModalOpen) && (
          <Typography variant="body1" style={styles.modalCardFieldError}>
            Image/Video is required
          </Typography>
        )}
      </>
    }
  };

  renderLibraryTopics = () => {
    return (
      <>
        {this.state.libraryTopicsData.length > 0 ?
          <Box style={{ padding: "16px 24px" }}>
            {this.state.libraryTopicsData.map((eachTopic: IILibraryTopic, topicIndex: number) => {
              const expandTopic = this.state.selectedSubLibraryTopicId === eachTopic.id
              const { image, topic, updated_at, description, image_or_video } = eachTopic.attributes
              const topicName = returnTruthyString(topic)
              const lastUpdatedTopicDate = returnTruthyString(updated_at)
              const topicDescription = returnTruthyString(description)
              const isTopicContentVideo = returnTruthyString(image_or_video) == "Video"
              const topicThumbnailImageUrl = getURL(image?.thumb?.url, image?.url)
              const eachTopicImageUrl = returnTruthyString(image.url)
              return (
                <>
                  {topicIndex > 0 ? <Divider style={{ backgroundColor: "#E4DDC9", height: "2px", margin: '8px 0px' }} /> : <></>}

                  <SubAccordianBoxLibrary
                    expanded={expandTopic}
                    style={{ borderRadius: "12px", backgroundColor: "transparent" }}
                    key={topicIndex}
                    data-testid="library-sub-accordion"
                    onChange={this.handleChangeSubLibrarySelect(eachTopic.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ fontSize: 24 }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box style={expandTopic ? { ...styles.boxSubLibraryChpaterImg, display: "none" } : styles.boxSubLibraryChpaterImg}>
                        {isTopicContentVideo ? <Box style={{ position: 'relative' }}><img src={topicThumbnailImageUrl} alt="Background" style={{
                          width: "48px",
                          height: "48px",
                          position: 'relative',
                        } as React.CSSProperties} />
                          <img src={VideoPlayIconSVG} alt="Overlay" style={{
                            position: 'absolute', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            left: '50%',
                          } as React.CSSProperties} />
                        </Box> : <img src={topicThumbnailImageUrl} style={styles.subLibraryChpaterImg} />}
                      </Box>
                      <Box style={expandTopic ? styles.boxChapterHeadingExpanded : styles.boxChapterHeading}>
                        <Tooltip title={topicName}>
                          <Typography style={{
                            ...styles.libraryChapterTopicheading,
                            maxWidth: "250px"
                          }} noWrap>{topicName}</Typography>
                        </Tooltip>
                        <Box><Typography variant="inherit" style={styles.libraryLastUpdate}>Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>{converStringToDate(lastUpdatedTopicDate)}</Typography></Typography></Box>
                      </Box>
                    </AccordionSummary>
                    <Box sx={{ margin: "0px 0 12px 0" }}> {this.renderImageVideo(eachTopicImageUrl, true, "150px")}</Box>
                    <Typography variant="inherit" style={{ ...styles.paragraph, whiteSpace: 'pre-wrap' }}>
                      {topicDescription}
                    </Typography>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "12px 0 8px 0" }}>
                      <Box onClick={() => this.handleShareLink(eachTopic)} data-testId = "shareLinkContainer">
                        <LinkShareButton><ShareIcon htmlColor="#006A77" style={styles.topicIcon} /> <span style={{ marginLeft: '4px' }}>Share in Chat</span>
                      </LinkShareButton>
                      </Box><Box style={styles.boxTopicsAction}>
                        <Tooltip title={"Edit"}><EditIcon htmlColor="#EB8F06"
                          data-testid="edit_library_topic_button" style={styles.topicIcon}
                          onClick={() => {
                            this.setState({
                              libraryEditModal: true,
                            });
                            document.body.style.overflow = 'hidden';
                            this.getEditFormData(Number(eachTopic.attributes.libraries_faq_id))
                          }} /></Tooltip>
                        <Tooltip title={"Delete"}><DeleteIcon htmlColor="#B3261E" data-testid="delete_library_topic_button"
                          style={styles.topicIcon} onClick={() => {
                            this.setState({
                              deleteLiraryModal: true,
                              selectedDeleteLibrary: eachTopic.attributes.libraries_faq_id
                            })
                          }} />
                        </Tooltip>
                      </Box>
                    </Box>
                  </SubAccordianBoxLibrary>
                </>
              )
            })}
          </Box>
          :
          <></>
        }
      </>
    )
  }
 
  renderImageVideo = (image: any, isDetailedView: boolean, height: string) => {
    if (!image) {
      return <StyledImageBox src={"https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} alt="video" style={isDetailedView ? styles.videoDetaildImg : {}} />
    }
    let mediaType = this.checkMediaType(image)
    if (mediaType == "img" && isDetailedView) {
      return <img src={image} alt="Image" style={styles.videoDetaildImg} />
    }
    if (mediaType == "img" && !isDetailedView) {
      return <StyledImageBox src={image} alt="Image" style={{ height: height }} />
    }
    if (mediaType == "video") {
      return <CustomVideo url={image} isDetailedView={isDetailedView} width="100%" height={height} />

    }
  }

  faqCard = (faqss: any, index: number) => {
    const { image, name, updated_at } = faqss.attributes
    const faq_topic_url = getURL(image?.resized?.url, image?.url, image) 
    return <AccordionBox expanded={faqss.id == this.state.selectedFAQId} style={{ borderRadius: "12px" }} key={index} data-testid="faq-accordion-select" onChange={this.handleSelectFaq(faqss.id)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box style={{ padding: "0px 12px 0px 0px" }}>
          <img src={faq_topic_url} style={styles.faqimg} />
        </Box>
        <Box>
          <Typography style={styles.faqheading}>
            {name}
          </Typography>
          <Typography variant="inherit" style={styles.libraryLastUpdate}>Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>{updated_at && moment(updated_at).format("DD MMM YYYY")}</Typography></Typography>
        </Box>

      </AccordionSummary>
      <Divider style={{ backgroundColor: "#E4DDC9", height: "2px", marginBottom: '5px' }} />
      {faqss.id == this.state.selectedFAQId && this.state.faqQuestions.length > 0 && <AccordionDetails
        id="scrollfaq"
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "400px",
          overflowY: "auto"
        }}
      >
        <InfiniteScroll
          dataLength={this.state.faqQuestions.length}
          next={() => {
            this.getFaqQuestions(this.state.selectedFAQId)
          }}
          scrollableTarget="scrollfaq"
          hasMore={true}
          loader={<></>}
        >
          {this.renderFaqTopics()}

        </InfiniteScroll>
      </AccordionDetails>}
      {this.state.isFetchingQuestions &&
        <AccordionDetails style={{ display: "flex", paddingBlock: ".5rem", justifyContent: "center" }}>
          <CircularProgress size={20} style={{ color: "#DF5200" }} />
        </AccordionDetails>
      }
      {this.state.faqQuestions.length === 0 && !this.state.isFetchingQuestions &&
        <AccordionDetails style={{ display: "flex", justifyContent: "center", paddingBlock: ".5rem", opacity: 0.64 }}>
          <Typography>No result found</Typography>
        </AccordionDetails>
      }
    </AccordionBox >

  }

  renderDropDown = (data: ILibraryChapter) => {
    switch (this.state.languageTab) {
      case "hindi":
        return data?.attributes.name_hindi;

      case "gujarati":
        return data?.attributes.name_gujarati;

      default:
        return data?.attributes.name
    }
  }

  renderBigFileErrorModal = () => {
    return <Grid item>
      <Modal
        open={this.state.isSelectedFileBig}
        onClose={this.handleAlertCancel}
        style={styles.secondaryModalBox}
        aria-describedby="modal-calendar-alert"
        aria-labelledby="modal-calendar-alert-validation"
        data-testId="closeModal"
        disableScrollLock={true}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card
          variant="outlined"
          style={styles.modalCardBoxSuccess}
        >
          <CardContent style={styles.secondaryModalCardsContent}>
            <Container
              style={styles.secondaryModalContentsContainer}
            >
              <Typography align="center" style={{
                fontSize: "24px",
                fontFamily: configJSON.fontFamily,fontStyle: "normal",
                fontWeight: 500,
                color: "#1C2324",
                lineHeight: "36px",
              }}>
                Error
              </Typography>

              <Box style={styles.modalimageBox}>
                <Typography align="center" style={{
                  fontStyle: "normal",
                  fontFamily: configJSON.fontFamily,
                  lineHeight: "28px",  color: "#1C2324",
                  fontWeight: 400, fontSize: "18px",
                  textAlign: "center"
                }}>
                  Select file is bigger than expected, Please select the media size less then 250MB
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={styles.modalButtonGoBack}
                  onClick={this.handleAlertCancel}
                  data-testid={"modal_alert_cancel_button"}
                >
                  <strong>GO BACK</strong>
                </Button>
                <Button
                  fullWidth
                  style={styles.modalButtonCardCancel}
                  onClick={this.handleAlertCancel}
                  data-testid={"modal_alert_cancel_buttonCancel"}
                >
                  <strong>CANCEL</strong>
                </Button>
              </Box>
            </Container>
          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }

  renderfaqForm = (item: any, index: number) => {
    if (
      this.state.languageTab.toLowerCase() == item.language.toLowerCase()
    ) {
      return (
        <FormControl key={index} fullWidth style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box>
            <Typography variant="subtitle2" style={styles.modalCardContentEditModalTypography}>Topic of FAQ*</Typography>
            <FormControl fullWidth
              style={{
                fontFamily: configJSON.fontFamily,
                borderRadius: "0.75rem",
                border: this.renderBorder(this.state.alertOpen, this.state.selectedFaqCategory)
              }}
            >
              <Select
                data-testid="tableSelectionMenuTestid"
                className="table-select-menuitem"
                value={this.state.selectedFaqCategory}
                onChange={this.handleEventChange}
                label="Select topic of FAQ"
                variant="outlined"
                IconComponent={(props) => (
                  <ExpandMoreIcon
                    {...props}
                    sx={{ marginRight: "15px" }}
                  />
                )}
                disabled={this.state.faqEditModel}
              >
                <MenuItem value={0} disabled>
                  <span className="opcity-48" style={{ fontFamily: configJSON.fontFamily }}>Select topic of FAQ</span>
                </MenuItem>
                {[...this.state.faqCategories].map((data: any, index: number) => {
                  return (
                    <MenuItem key={data.id} value={data.id} style={{ opacity: 0.72 }} >{this.renderSelectOption(data)}</MenuItem>
                  );
                })}

              </Select>
            </FormControl>
            {
              this.state.alertOpen && !this.state.selectedFaqCategory && (
                <Typography
                  variant="body1"
                  style={styles.modalCardFieldError}
                >
                  Please select topic of FAQ
                </Typography>
              )
            }
          </Box >

          <Box>
            <Typography
              variant="subtitle2"
              style={styles.modalCardContentEditModalTypography}
            >
              Question*
            </Typography>
            <input
              style={{
                ...styles.modalTextField,
                border: this.renderBorder(this.state.alertOpen, item.question.trim())
              }}
              placeholder="Enter Question of FAQ"
              value={item.question}
              name="topic_name"
              onChange={(event) => this.updatedFaqQuestionName(event, index)}
              data-testid={"modal_input_topic_name"}
            />
            {this.state.alertOpen && !item.question && (
              <Typography variant="body1"
                style={styles.modalCardFieldError}>
                Question of FAQ is required
              </Typography>
            )}
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              style={styles.modalCardContentEditModalTypography}
            >
              Upload Image/Video
            </Typography>
            {this.ImageFragment({ attachment: this.state.libraryFormImage })}

          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              style={styles.modalCardContentEditModalTypography}
            >
              Description*
            </Typography>
            <TextArea
              style={{
                ...styles.modalTextField,
                border: this.renderBorder(this.state.alertOpen, item.description.trim())
              }}
              rows={12}
              placeholder="Enter description here...."
              value={item.description}
              name="description"
              onChange={(event) => this.updatedFaqDescription(event, index)}
              data-testid={"modal_input_topic_description"}
            />
            {this.state.alertOpen && !item.description && (
              <Typography variant="body1" style={styles.modalCardFieldError}>
                Description is required
              </Typography>
            )}
          </Box>

        </FormControl >
      );
    }
  };

  renderLibraryChapters = (libraryChapter: ILibraryChapter, index: number) => {
    const { name, updated_at, topic_count, image } = libraryChapter.attributes
    const chapterName = returnTruthyString(name)
    const chapterLastUpdatedDate = returnTruthyString(updated_at)
    const image_url = getURL(image?.resized?.url, image?.url) 

    return (
      <AccordionBox
        expanded={this.state.selectedLibraryChapterId === libraryChapter.id}
        style={{ borderRadius: "12px" }}
        key={index}
        data-testid="library-chapter-accordion"
        onChange={this.handleSelectLibraryChapter(libraryChapter.id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ fontSize: 32 }} />}
          aria-controls="panel1a-content"
          id="library-chapter-summary"
        >
          <Box style={{ padding: "0px 12px 0px 0px" }}>
          <img src={image_url} style={styles.faqimg} />
        </Box>
          <Box style={styles.boxChapterHeading}>
            <Tooltip title={chapterName}>
              <Typography style={{ ...styles.libraryChapterTopicheading, maxWidth: "250px" }} >
                {chapterName}
              </Typography>
            </Tooltip>
            <Box sx={styles.LibrarySummaryBox}>
              <Box>
              <Typography variant="inherit" style={styles.libraryTopicsFont}>{topic_count} Topics</Typography>
              <Typography variant="inherit" style={{ ...styles.libraryTopicsFont, padding: "0 8px" }}>|</Typography>
              </Box>
              <Box>
              <Typography variant="inherit" style={styles.libraryLastUpdate}>
                Last update: <Typography variant="inherit" style={{ fontWeight: 500, display: "inline-block" }}>
                  {converStringToDate(chapterLastUpdatedDate)}
                </Typography>
              </Typography>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <Divider style={{ height: "2px", marginBottom: '0px', backgroundColor: "#E4DDC9" }} />
        {libraryChapter.id == this.state.selectedLibraryChapterId && this.state.libraryTopicsData.length > 0 &&
        <div id={`scrollableTopicDiv-${index}`} style={{ maxHeight: "480px", overflowY: "auto" }}>
          <InfiniteScroll
            dataLength={this.state.libraryTopicsData.length}
            scrollableTarget={`scrollableTopicDiv-${index}`}
            next={this.fetchNextTopics}
            hasMore={true}
            loader={<></>}
          >
            {this.renderLibraryTopics()}
          </InfiniteScroll>
        </div>}
        {this.state.libraryTopicsLoading &&
          <>
            <AccordionDetails style={{ display: "flex", paddingBlock: ".5rem", justifyContent: "center" }}>
              <CircularProgress size={20} style={{ color: "#DF5200" }} />
            </AccordionDetails>
          </>
        }
        {this.state.libraryTopicsData.length === 0 && !this.state.libraryTopicsLoading &&
          <>
            <AccordionDetails style={{ display: "flex", justifyContent: "center", paddingBlock: ".5rem", opacity: 0.64 }}>
              <Typography>No result found</Typography>
            </AccordionDetails>
          </>
        }
      </AccordionBox>
    )
  }

  handleTopicNameError = (topic: string) => {
    if(this.state.alertOpen && !topic){
      return(
          <Typography
            variant="body1"  
            style={styles.modalCardFieldError
            }
          >
            Topic name is required
          </Typography>
      )
    }
  }

  renderForm = (data: any, index: number) => {
    if (this.state.languageTab.toLowerCase() == data.language.toLowerCase()) {
      return <FormControl key={index} fullWidth style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={styles.formfieldWrapper}
          >
            <FormLabel style={styles.customLabelField}>
              Select chapter*
            </FormLabel>
            <FormControl fullWidth style={styles.userTypeFormControl}>
              <Select
                value={this.state.selectedDropDownValue}
                variant="outlined"
                className="farmerFormDDClass"
                color="secondary"
                data-testid={`createUserModalDropdown${index}`}
                name="library_chapter_id"
                style={{border: (this.state.alertOpen && this.state.selectedDropDownValue == "Select Chapter") ? "1px solid #f44336" : ""}}
                onChange={(event) => this.updatedlessonID(event, index)}
                inputProps={{ "aria-label": "Without label" }}
                disabled={this.state.allLibraryChaptersLoading || this.state.libraryEditModal}
              >
                <MenuItem value={"Select Chapter"} disabled>
                  <span >Select Chapter</span>
                </MenuItem>
                {
                  this.state.allLibraryChapters.length > 0 ? (
                    this.state.allLibraryChapters.map((item, index) => {
                      return (
                        <MenuItem value={item?.attributes.name} key={index}>{this.renderDropDown(item)}</MenuItem>
                      )
                    })
                  ) : (
                    <></>
                  )
                }
              </Select>
            </FormControl>
            {this.handleConditions(this.state.alertOpen && this.state.selectedDropDownValue == "Select Chapter", <Typography
            variant="body1"  
            style={styles.modalCardFieldError
            }
          >
            Please select chapter of Library
          </Typography>, "")}
          </Grid>
        </Grid>
        <Box>
          <Typography
            variant="subtitle2"
            style={
              styles.modalCardContentEditModalTypography
            }
          >
            Topic Name*
          </Typography>
          <input
            style={{ ...styles.modalTextField, border: (this.state.alertOpen && !data.topic.trim()) ? "1px solid #f44336" : "1px solid #F0EBE0", }}
            placeholder="Enter name of topic"
            value={data.topic}
            name="topic_name"
            onChange={(event) => this.updatedTopicName(event, index)}
            data-testid={"modal_input_topic_name"}
          />
          {this.handleTopicNameError(data.topic)}
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            style={
              styles.modalCardContentEditModalTypography
            }
          >
            Upload Image/Video*
          </Typography>
          { this.ImageFragment({attachment: this.state.libraryFormImage  })  }  
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            style={
              styles.modalCardContentEditModalTypography
            }
          >
            Description*
          </Typography>
          <TextArea
            style={{ ...styles.modalTextField, border: (this.state.alertOpen && !data.description.trim()) ? "1px solid #f44336" : "1px solid #F0EBE0", }}
            rows={12}
            placeholder="Enter description here...."
            id="description"
            name="description"
            onChange={(event) => this.updatedDescription(event, index)}
            value={data.description}
            data-testid={"modal_input_topic_description"}
            />
          {this.handleFormDescription(data.description)}
        </Box>
      </FormControl >
    }
  }


  renderBorder = (alertOpen: boolean, field: number | string) => {
    if (alertOpen && !field) {
      return "1px solid #f44336"
    }
    return "1px solid #F0EBE0"
  }
 
 
  renderLibrarySection = () => {
    return <Grid item xs={12} lg={6} >
      <StyledBoxSection >
        <Grid item container xs={12} alignItems="center" 
    style={{ padding: "1.375rem 1.5rem" }}>
          <Grid item xs={6}>
            <Typography variant='h4' style={styles.sectionHeadingText}>{configJSON.librarySectionHeading}</Typography>
          </Grid>
          <Grid item xs={6}>
          <Box
              style={{
                padding: "7px",
                background: "#FCFAF7",
                borderRadius: "28px",
                border: "2px solid #F0EBE0",
                width: "100%",
              }}
            >
              <Input
                id="searchInputId"
                type="search"
                name="searchInput"
                disableUnderline
                fullWidth
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                autoComplete="off"
                className="tableSearchInputfilter searchByTopic"
                data-testid="tableInputSearchTestid"
                placeholder="Search by topic"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "#1C2324", opacity: 0.48 }}
                    />
                  </InputAdornment>
                }
                value={this.state.librarySearchTopic}
                onChange={this.handleSearch}
              />
            </Box>
          </Grid>
        </Grid>
        <div id="scrollableLibraryDiv" style={{ overflowY: "auto", maxHeight: "815px", padding: "0 1.5rem" }}>
          <InfiniteScroll
            dataLength={this.state.libraryChaptersData.length}
            scrollableTarget="scrollableLibraryDiv"
            next={this.fetchNextLibraryChapters}
            hasMore={true}
            loader={<></>}
          >
            <Box sx={styles.libraryCards}>
              {this.state.libraryChaptersData.length > 0 && this.state.libraryChaptersData.map((library: ILibraryChapter, index: number) => {
                return this.renderLibraryChapters(library, index)
              })}
              <Box sx={{ paddingBottom: "1rem" }}>
                {this.state.libraryChaptersData.length === 0 && !this.state.libraryChaptersLoading && <Typography style={{ textAlign: "center" }} className="ps-16">No result found</Typography>}
              </Box>
            </Box>
          </InfiniteScroll>
        </div>
        {this.state.libraryChaptersLoading && <Box sx={{ display: 'flex', justifyContent: "center", paddingBottom: "1rem" }}>
          <CircularProgress size={30} />
        </Box>}
      </StyledBoxSection>
    </Grid>
  }

  renderSelectOption = (data: FaqCategoryResp) => {

    if (this.state.languageTab.toLowerCase() == "hindi") {
      return data.name_hindi
    }
    if (this.state.languageTab.toLowerCase() == "gujarati") {
      return data.name_gujarati
    }
    return data.name
  }

    handleFormDescription = (data:string) => {
      if(this.state.alertOpen && (!data || this.state.selectedEditFormDataValue?.description)){
     return(
      <Typography
       variant="body1"
       style={
         styles.modalCardFieldError
       }
     >
       Description is required
     </Typography>
     )   
      }
  }

  renderLibraryDeleteModal = () => {
    return <Grid item>
      <CustomModal
        open={this.state.deleteLiraryModal}
        onClose={this.handleButtonCancel}
        aria-describedby="modal-calendar-alert"
        aria-labelledby="modal-calendar-alert-validation"
        style={styles.secondaryModalBox}
        disableScrollLock={true}
        data-testid="close_modal_button"
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card
          variant="outlined"
          className="modalCardBoxSuccess"
        >
          <CardContent style={styles.secondaryModalCardsContent}>
            <Container style={styles.secondaryModalContentsContainer}>
              <Typography align="center" style={{
                fontFamily: configJSON.fontFamily,fontStyle: "normal",
                lineHeight: "36px", 
                fontWeight: 500 ,fontSize: "24px",
                color: "#1C2324",  
              }}>
                Delete Library
              </Typography>

              <Box style={styles.modalimageBox}>
                <Typography align="center" style={{
                  fontFamily: configJSON.fontFamily,
                  fontStyle: "normal",   fontWeight: 400,
                  color: "#1C2324",
                  fontSize: "18px",   lineHeight: "28px",
                  textAlign: "center" 
                }}>
                  Are you sure you want to delete? All the content related to this topic will be deleted
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={styles.modalButtonGoBack}
                  data-testid={"modal_delete_button"}
                  onClick={this.deleteTopic}
                  disabled={this.state.isDeleting}
                >
                  {
                    !this.state.isDeleting ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />
                  }

                </Button>
                <Button
                  fullWidth
                  style={styles.modalButtonCardCancel}
                  onClick={this.handleButtonCancel}
                  data-testid="close_modal_button"

                >
                  <strong>CANCEL</strong>
                </Button>
              </Box>
              {this.state.isDeleted && <Typography style={{
                lineHeight: "28px",
                fontWeight: 500, fontFamily: configJSON.fontFamily,
                fontSize: "18px", color: "#75bd68", 
                textAlign: "center",
              }}>
                Library deleted successfully
              </Typography>}
              {this.state.actionApiError && <Typography style={{
                fontFamily: configJSON.fontFamily, fontWeight: 500,
                textAlign: "center",
                fontSize: "18px", lineHeight: "28px",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Container>
          </CardContent>
        </Card>
      </CustomModal>
    </Grid>
  }
  
  renderDeleteFaqModal = () => {
    return <Grid item>
      <Modal 
        open={this.state.deleteFaqModal}
        onClose={this.handleButtonCancel}  
        aria-describedby="modal-calendar-alert"
        data-testid="close_modal_button"
        aria-labelledby="modal-calendar-alert-validation"
        style={styles.secondaryModalBox} 
        disableScrollLock={true}
        BackdropProps={{ 
          style: styles.modalBackdropProps,
        }} 
      >
        <Card
          variant="outlined" 
          style={styles.modalCardBoxSuccess}
        > 
          <CardContent style={styles.secondaryModalCardsContent}> 
            <Container
              style={styles.secondaryModalContentsContainer}
            >
              <Typography align="center" style={{
                fontWeight: 500,
                 fontFamily: configJSON.fontFamily,fontStyle: "normal",
                lineHeight: "36px",  color: "#1C2324",
                fontSize: "24px",
              }}> 
                Delete FAQ
              </Typography>
 
              <Box style={styles.modalimageBox}>
                <Typography align="center" style={{  
                  fontFamily: configJSON.fontFamily, fontStyle: "normal",
                  color: "#1C2324", textAlign: "center",  
                  lineHeight: "28px",fontWeight: 400,  fontSize: "18px",
                }}> 
                  Are you sure you want to delete? All the content related to this topic will be deleted
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}> 
                <Button
                  fullWidth 
                  style={styles.modalButtonGoBack}
                  onClick={this.deleteFaq}
                  data-testid={"modal_delete_faq_button"}
                  disabled={this.state.isDeletingFaq} 
                >
                  {
                    !this.state.isDeletingFaq ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />
                  }
 
                </Button>
                <Button 
                  fullWidth 
                  style={styles.modalButtonCardCancel}
                  onClick={this.handleButtonCancel} 
                  data-testid="close_modal_button"
                > 
                  <strong>CANCEL</strong>
                </Button>  
              </Box>
              {this.state.isDeletedFaq && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500, 
                fontSize: "18px", 
                lineHeight: "28px", 
                textAlign: "center",
                color: "#75bd68", 
              }}> 
                FAQ deleted successfully 
              </Typography>}
              {this.state.actionApiError && <Typography style={{
                 fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                 fontSize: "18px", 
                lineHeight: "28px",
                  textAlign: "center",  
                 color: "red", 
              }}>
                Something went wrong 
               </Typography>}
            </Container>  
           </CardContent> 
        </Card> 
       </Modal>
    </Grid> 
  }

  renderSuccessFaqModal = () => {
    return <Grid item>
      <Modal   
        open={this.state.successFaq} 
        style={styles.secondaryModalBox} 
        aria-describedby="modal-calendar-alert"
        onClose={this.handleButtonCancel} 
        disableScrollLock={true}  
        aria-labelledby="modal-calendar-alert-validation" 
        data-testid="close_modal_button"
        BackdropProps={{ 
          style: styles.modalBackdropProps, 
        }}  
      >
        <Card  
          variant= "outlined"  
          style={styles.modalCardBoxSuccess} 
        >  
          <CardContent style={styles.secondaryModalCardsContent}> 
            <Container  
               style= {styles.secondaryModalContentsContainer} 
            >   
                  <Typography align="center" style={{ 
                fontWeight: 500, fontFamily: configJSON.fontFamily,
                fontStyle:  "normal", lineHeight: "36px",   color:  "#1C2324",
                fontSize: "24px",   
              }}>  
                   {this.state.actionApiError? "Error":  "Success"} 
                </Typography>    
              <Box style={styles.modalimageBox}>    
                <Typography align="center" style={{     

                    fontFamily: configJSON.fontFamily, fontStyle: "normal",
                    color: "#1C2324",  
                    textAlign: "center",  
                  lineHeight: "28px",fontWeight: 400,  
                   fontSize: "18px",  
                 }}> 
                  {  
                       this.state.successFaq &&  !this.state.actionApiError &&   `Faq ${this.state.addFaq  ?  "Added": "Edited" } Successfully`
                   }  
 
{this.state.actionApiError &&    <Typography style={{ lineHeight: "28px",textAlign: "center", 
                fontFamily: configJSON.fontFamily, fontWeight: 500,fontSize: "18px",
                color: "red", 
              }}>   
                 Something went wrong   
              </Typography>} 
                 </Typography>  
              </Box> 
              <Box style={styles.modalTextSucessContainer}> 
                <Button
                  fullWidth  
                  style={styles.modalButtonGoBack}
                  onClick={this.handleButtonCancel}
                > 
                  OKAY
                </Button>
              </Box> 
            </Container>
          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }

  renderFaqSection = () => { 
    return <Grid item xs={12} lg={6} > 
      <StyledBoxSection >
        <Grid item container xs={12} alignItems="center" style={{ padding: "1.375rem 1.5rem" }}>
          <Grid item xs={6}>
            <Typography variant='h4'  style={styles.sectionHeadingText}>{configJSON.faqSectionHeading}</Typography>
          </Grid>
          <Grid item xs={6}> 
            <Box 
              style={{
                padding: "7px",  
                background: "#FCFAF7",   
                borderRadius: "28px",   
                border: "2px solid #F0EBE0",  
                width: "100%", 
              }} 
            > 
              <Input
                id="searchInputId" 
                type="search" 
                name="searchInput" 
                style={{ whiteSpace:  "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}  
                disableUnderline
                fullWidth 
                autoComplete="off" 
                 className="tableSearchInputfilter searchByTopic" 
                data-testid="tableInputSearchFaqTestid"
                 placeholder="Search by topic"
                startAdornment={  
                  <InputAdornment position="start">
                     <SearchIcon
                      style={{ color: "#1C2324", opacity: 0.48 }}
                     />
                  </InputAdornment>
                 }
                onChange={this.handleFaqSearch}
              />
            </Box>
          </Grid>
         </Grid>
         <div id="scrollableFaqDiv" style={{ overflowY: "auto", maxHeight: "815px", padding: "0 1.5rem" }}>
          <InfiniteScroll
            dataLength={this.state.faqsData?.length}
             scrollableTarget="scrollableFaqDiv"
            next={() => {
               this.getAllFaqs()
            }}
             hasMore={true}
             loader={<></>}
          >
            <Box sx={styles.faqCards} >
               {this.state.faqsData?.length > 0 && this.state.faqsData.map((faqss: FaqssResponse, index: number) => {
                 return this.faqCard(faqss, index)
              })}
               <Box sx={{ paddingBottom: "1rem" }}>
                 {this.state.faqsData?.length === 0 && !this.state.isFecthingFaqData && <Typography style={{ textAlign: "center" }} className="ps-16">No result found</Typography>}
               </Box>
              </Box>
           </InfiniteScroll> 
        </div> 

        {this.state.isFecthingFaqData  && <Box  sx={{ display: 'flex',  justifyContent: "center",  paddingBottom: "1rem" }}>
           <CircularProgress size={30} />
        </Box>} 
      </StyledBoxSection>
    </Grid> 
  }

  renderSuccessLibraryModal = () => { 
    return <Grid item>
      <Modal
        open={this.state.successLibrary} 
        aria-describedby="modal-calendar-alert"
        onClose={this.handleButtonCancel}   
        style={styles.secondaryModalBox} 
        aria-labelledby="modal-calendar-alert-validation"
        data-testid="close_modal_button" 
        BackdropProps={{
          style: styles.modalBackdropProps, 
        }}
        disableScrollLock={true}
      >   
        <Card
          variant="outlined"  style={styles.modalCardBoxSuccess}
        > 
          <CardContent style={styles.secondaryModalCardsContent}>  
            <Container style={styles.secondaryModalContentsContainer}
            > 
              <Typography  align="center" style={{
                fontWeight: 500,  
                fontFamily: configJSON.fontFamily,fontStyle: "normal",  
                lineHeight: "36px",  color: "#1C2324",  
                fontSize: "24px", 
              }}>
                {this.state.actionApiError ? "Error":"Success"}
              </Typography> 

              <Box  style={styles.modalimageBox}>   
                <Typography align="center"  style={{ 
                  fontFamily: configJSON.fontFamily, fontStyle: "normal",
                  color: "#1C2324", textAlign: "center",  
                  lineHeight: "28px", fontWeight: 400,  fontSize: "18px",
                }}>
                  { 
                    this.state.successLibrary && !this.state.actionApiError && `Library ${this.state.addLibrary ? "Added": "Edited"} Successfully` 
                  }
                  
                  {this.state.actionApiError && <Typography style={{
                fontFamily: configJSON.fontFamily,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "28px",
                textAlign: "center",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
                </Typography>
              </Box>
              <Box style={styles.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={styles.modalButtonGoBack}
                  onClick={this.handleButtonCancel}
                >OKAY</Button>
              </Box>  
            </Container>  
           </CardContent>    
         </Card>
       </Modal>
     </Grid>
  }

  renderButtons = () => {
    return (
      <Box display={(this.state.libraryEditModal || this.state.faqEditModel) && "none"}>
        <Button
          style={styles.exportBtnStyle}
          variant="contained"
          data-testid="exportBtn"
          onClick={this.exportFile}
        >
          Export
        </Button>
        <Button
          style={styles.exportBtnStyle}
          variant="contained"
          data-testid="importBtn"
          onClick={this.openImportModal}
        >
          Import
        </Button>
        <Button
          style={styles.exportBtnStyle}
          variant="contained"
          data-testid="templateBtn"
          onClick={this.downloadTemplate}
        >
          Template
        </Button>
      </Box>
    );
  }

  renderErrorDialog = () => {
    return(
      <ErrorModal
        open={this.state.openModal === "error"}
        errorTitle="Error"
        BtnTitle="Okay"
        errorSubTitle={this.state.errorMessage}
        handleBtnClick={this.closeModal}
        styles={{ backdropFilter: "blur(5px)" }}
      />
    );
  }

  getTitle = () => {
    if(this.state.openModal === "importing"){
      return "Import file!";
    } else if (this.state.openModal === "export") {
      return "Export file!";
    } else if (this.state.openModal === "template") {
      return "CSV template";
    }
  }

  renderSuccessDialog = () => {
    return (
      <>
        {this.state.openModal !== "" &&
          <Dialog
            data-testid="success-modal"
            maxWidth="md"
           className="blur-dialog"
            aria-labelledby="dialog-title"
            open={["importing", "export", "template"].includes(this.state.openModal)}
            PaperProps={{ style: { borderRadius: 12, width: "528px" } }}
            onClose={this.onModalClose}
          >
            <DialogContent dividers className="modalBodyWrapper">
              <Cf2enhancementsfromversion1
                id=""
                navigation={undefined}
                title={this.getTitle() ?? ""}
                fileName={this.state.fileName}
                modalName={this.state.openModal}
              />
            </DialogContent>
          </Dialog>
        }
      </>
    );
  }

  renderImportDialog = () => {
    return (
      <AddDocumentDialog
        maxWidth="md"
        aria-labelledby="customized-dialog-title"
        open={this.state.openModal === "import"}
        PaperProps={{ style: { borderRadius: 12, width: "528px" } }}
      >
        <Box position="relative">
          <DialogTitle className="dialog-title">
            <Typography variant="subtitle1">Import file!</Typography>
          </DialogTitle>
          <Box position="absolute" right="20px" top="20px">
            <IconButton
              aria-label="close"
              data-testid="modalCloseBtnTestid"
              onClick={this.closeModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <DialogContent dividers className="modalBodyWrapper">
          <Formik
            data-testid="formikForm"
            initialValues={{ document: null }}
            validationSchema={
              Yup.object().shape({
                document: Yup.mixed()
                  .required("Please select a CSV file to upload.")
                  .test("fileSize", "File is too large. Maximum allowed size is 250 MB.",
                    (file) => file?.size <= 250 * 1024 * 1024
                  )
                  .test("fileType", "Invalid file type. Only CSV files allowed.",
                    (file) => file && /\.(csv)$/.test(file.name)
                  ),
              })
            }
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => { this.importFile(values) }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid className="label-parent" container spacing={3}>
                  <CustomFieldGrid item xs={12} lg={12}>
                    <CustomFormLabel>Choose file</CustomFormLabel>
                    <FormControl>
                      <label htmlFor="document" className="documentPicker" style={{ border: (props.touched.document && props.errors.document) ? "1px solid red" : "1px solid #F0EBE0" }}>
                        <input
                          type="file"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleChange(event, props);
                          }}
                          hidden
                          accept=".csv"
                          data-testid="filePicker"
                          id="document"
                          style={{ display: "none" }}
                        />
                        <Typography className="documentPickerText">
                          {this.state.document ? this.state.document.name: "Choose file to import"}
                        </Typography>
                        <PublishIcon className="documentPickerText" />
                      </label>
                    </FormControl>
                    {props.touched.document && props.errors.document && (
                      <p className="validation_errors"> {props.errors.document}</p>
                    )}
                  </CustomFieldGrid>
                  <Grid item xs={12} alignItems="center">
                    <Box className="functionButtonContainer" data-test-id="profile-container">
                      <Button
                        data-testid="importDoc"
                        type="submit"
                        className="buttonFunction"
                      >
                        {this.state.loading
                          ? <CircularProgress size={23} style={{ color: "#fff" }} />
                          : <Typography className="buttonFunctionTxt import-btn">IMPORT</Typography>
                        }
                      </Button>
                     <Button
                        data-testid="cancelBtn"
                        className="buttonFunction cancel-btn"
                        component="span"
                        onClick={this.closeModal}
                      >
                        <Typography className="buttonFunctionTxt">
                          CANCEL
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </AddDocumentDialog>
    );
  }

  renderLibraryForm = () => {
    return <Grid item>
      <CustomModal
        open={this.state.isLibraryModalOpen || this.state.libraryEditModal}
        onClose={this.handleButtonCancel}
        data-testid="close_modal_button"
        style={styles.modalRoot}
        disableScrollLock={true}
        BackdropProps={{
          style: {
            ...styles.modalBackdropProps,
            overflowY: 'hidden', 
          },
        }}
      >
        <Card variant="outlined" className="modalCardRoot">
          <CardHeader
            style={styles.modalCardHeader}
            disableTypography={true}
            data-testid="card-header"
            action={
              <IconButton
                aria-label="close"
                data-testid="close_modal_button"

                onClick={this.handleButtonCancel}
              >
                <Close />
              </IconButton>
            }
            title={
              <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between">
                <Typography style={styles.modalHeaderTypography}>
                  {`${this.state.libraryEditModal ? "Edit" : "Add New in"}`}  Library
                </Typography>
               {this.renderButtons()}
             </Box>
            }
          />
          <CardContent style={{ paddingInline: 0, paddingBlockStart: '.5rem', paddingBottom: 0 }}>
            <Box style={{ paddingInline: "2rem" }} >
              <StyledTabs
                value={this.state.languageTab.toLowerCase()}
                onChange={this.handleTabChange}
                variant="fullWidth"
                data-testid={"modal_assign_language_tab"}
              >
                {this.renderTabs()}
              </StyledTabs>
            </Box>
            <Box sx={{ padding: "2rem" }}>
              {this.state.formData.map(
                (data: FormDataInitial, index: number) => {
                  return this.renderForm(data, index);
                }
              )}
              <Button
                aria-label="add-library"
                component="span"
                style={{ ...styles.buttonComponent, ...styles.buttonComponentAddNewPhase, marginTop: "32px" }}
                data-testid={"modal_add_library_button"} disableRipple={true}
                onClick={() => { this.state.libraryEditModal ? this.editTopic() : this.addNewTOpic() }}
                disabled={this.state.isAddingLibrary}
              >
                {
                  !this.state.isAddingLibrary ? <Typography style={{ ...styles.buttonTxt, ...styles.addButtonTxt }}>
                    {`${this.state.libraryEditModal ? "Edit" : " Add New"}`}  Library
                  </Typography> : <CircularProgress size={28} style={{ color: "#fff" }} />
                }

              </Button>
              {this.state.actionApiError && <Typography style={{
                fontWeight: 500,fontFamily: configJSON.fontFamily,
                textAlign: "center",
                fontSize: "18px",lineHeight: "28px",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Box>

          </CardContent>
        </Card>
      </CustomModal>
    </Grid>
  }


 

  renderFaqForm = () => {
    return <Grid item>
      <CustomModal
        open={this.state.faqAddModelOpen || this.state.faqEditModel}
        data-testid="close_faq_modal_button"
        onClose={this.handleFaqButtonCancel}
        disableScrollLock={true}
        style={styles.modalRoot}
        BackdropProps={{
          style: styles.modalBackdropProps,
        }}
      >
        <Card variant="outlined" className="modalCardRoot">
          <CardHeader
            style={styles.modalCardHeader}
            disableTypography={true}
            action={
              <IconButton
                aria-label="close"
                data-testid="close_modal_button"
                onClick={this.handleFaqButtonCancel}
              >
                <Close />
              </IconButton>
            }
            title={
              <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between">
              <Typography style={styles.modalHeaderTypography}>
                {`${this.state.faqEditModel ? "Edit" : "Add New in"}`}  FAQ
              </Typography>
              {this.renderButtons()}
              </Box>
            }
          />
          <CardContent style={{ paddingInline: 0, paddingBlockStart: '.5rem', paddingBottom: 0 }}>
            <Box style={{ paddingInline: "2rem" }} >
              <StyledTabs
                value={this.state.languageTab.toLowerCase()}
                variant="fullWidth"
                onChange={this.handleTabChange}
                data-testid={"modal_assign_language_tab_faq"}
              >
                {this.renderTabs()}
              </StyledTabs>
            </Box>
            <Box sx={{ padding: "2rem" }}>
              {this.state.faqFormData.map((item: any, index: number) => {
                return this.renderfaqForm(item, index)
              })}
              <Button
                component="span"
                data-testid={"modal_add_faq_button"}
                aria-label="add-faq"
                disableRipple={true}
                style={{
                  ...styles.buttonComponent,
                  ...styles.buttonComponentAddNewPhase,
                  marginTop: "32px",
                }}
                onClick={()=>
                  this.state.faqEditModel ? this.editFaq() : this.addFaqTopic()
                }
                disabled={this.state.isAddingFaq}
              >
                {!this.state.isAddingFaq ? (
                  <Typography
                    style={{
                      ...styles.buttonTxt,
                      ...styles.addButtonTxt,
                    }}
                  >
                    {`${this.state.faqEditModel ? "Edit" : " Add New"}`} FAQ
                  </Typography>
                ) : (
                  <CircularProgress size={28} style={{ color: "#fff" }} />
                )}
              </Button>
              {this.state.actionApiError  &&  <Typography style={{
                fontWeight: 500,fontFamily: configJSON.fontFamily,
                lineHeight: "28px",textAlign: "center",
                fontSize: "18px",
                color: "red",
              }}>
                Something went wrong
              </Typography>}
            </Box>

          </CardContent>
        </Card>
      </CustomModal>
    </Grid>
  }



  renderCustomModal = () => {
    return (
      <>
        {this.state.customModalOpen &&
          <ErrorModal
            open={this.state.customModalOpen}
            errorTitle={this.state.customModalTitle}
            BtnTitle="Okay"
            errorSubTitle={this.state.customModalDesc}
            handleBtnClick={this.closeCustomModal}
          />}
      </>
    )
  }

  renderDownloadProcessDialog = () => {
    return (
      <>
       <Dialog
          maxWidth={"md"}
          aria-labelledby="customized-dialog-title"
          open={this.state.downloadProcess}
          PaperProps={{ style: { borderRadius: 12, width: "528px" }}}
        >
          <DialogContent dividers className="modalBodyWrapper">
            <Cf2enhancementsfromversion1 
            navigation={undefined} 
            id={""}
            title={"Export file!"}
            fileName={""}
            description={" File is being downloaded on your computer. Please wait until it's finished!"}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={this.props.id}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={styles.rootGrid}>
            <Box sx={styles.rootGridContainer}>
              <Typography variant='h4' style={styles.heading}>{configJSON.heading1}</Typography>
              <Box sx={styles.buttonContainer}>
                <StyeledButton
                  aria-label="add-library"
                  disableRipple={true}
                  onClick={this.handleAddLibrary}
                  data-testid={'add_library_modal_button'}
                >
                  <Add />
                  <Typography style={styles.buttonTxt}>
                    {configJSON.addLibraryButtonText}
                  </Typography>
                </StyeledButton>
                <StyeledButton
                  aria-label="edit-profile"
                  disableRipple={true}
                  onClick={this.handleAddFaqForm}
                  data-testid={'add_faq_modal_button'}
                >
                  <Add />
                  <Typography style={styles.buttonTxt}>
                    {configJSON.addFaqButtonText}
                  </Typography>
                </StyeledButton>
              </Box>
            </Box>
          </Grid>
          {this.renderLibrarySection()} 
            {this.renderFaqSection()}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              {this.renderLibraryForm()}  
              {this.renderLibraryDeleteModal()} 
               {this.renderBigFileErrorModal()}
                {this.renderFaqForm()} 

              {this.renderDeleteFaqModal()} 
              {this.renderSuccessFaqModal()}
              {this.renderSuccessLibraryModal()}
              {this.renderImportDialog()}
              {this.renderSuccessDialog()}
              {this.renderErrorDialog()}
              {this.renderDownloadProcessDialog()}
            </Grid>
          </Grid>
        </Grid>
        {this.state.isShare && <LinkShare isShare={this.state.isShare}
          handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
          messageHeading={this.state.messageHeading} />}
          {this.renderCustomModal()}
      </NavigationMenu >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
interface CustomVideoProps {
  url: string
  isDetailedView?: boolean
  height?: string
  width?: string
}
interface CustomVideoState {
  isPlaying: boolean,
}
export class CustomVideo extends React.Component<CustomVideoProps, CustomVideoState>{
  state: CustomVideoState = {
    isPlaying: false,

  };
  videoRef: any = React.createRef();

  renderVideoView = () => {
    if (this.props.isDetailedView) {
      return <video ref={this.videoRef} style={{ objectFit: "cover", width: this.props.width, height: this.props.height, borderRadius: "8px" }} controls={this.state.isPlaying} >
        <source src={this.props.url} />
      </video>
	      }
    return <StyledVideoBox ref={this.videoRef} controls={this.state.isPlaying} >
      <source src={this.props.url} />
    </StyledVideoBox>
  }

  playVideo = () => {
    if (this.state.isPlaying) {
      this.videoRef.current?.pause();
      this.setState({ isPlaying: false });
    } else {
      this.videoRef.current?.play();
      this.setState({ isPlaying: true });
    }
  }


  render() {
    return <CustomVideoBox sx={{ width: this.props.isDetailedView ? "100%" : "", maxHeight: this.props.height }} data-testid={"play-video-button"} onClick={() => this.playVideo()}>
      {this.renderVideoView()}
      {!this.state.isPlaying && <PlayArrow onClick={(event) => {
        event.stopPropagation()
        this.playVideo()
      }} style={{
        background: "rgb(28 35 36 / 56%)",
        textAlign: "center",
        top: "50%",
        position: "absolute",left: "50%",
        margin: 0,
        color: "white",
        height: "32px",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
        width: "32px",
      }} />}
    </CustomVideoBox>
  }
}

const CustomModal = styled(Modal)({
  "& .modalCardRoot": {
    position: 'absolute',
    transform: "translate(-50%, -50%)",
    fontFamily: configJSON.fontFamily,
    fontSize: "1rem",
    height: "90%",
    maxWidth: "712px",
    top: "50%",
    left: "50%",
    width: "100%",
    overflowY: "scroll",
    '@media (max-width:720px)': {
      width: '93%'
    },
  },
  "& .modalCardBoxSuccess": {
    position: "absolute",
    height: 360, left: "50%", maxWidth: 528,
    justifyContent: "space-between", fontSize: "16px", display: "flex",
    borderRadius: "12px", transform: "translate(-50%, -50%)",
    top: "50%", "flexDirection": "column",
  },
});

const AddDocumentDialog = styled(Dialog)({
  "& .MuiDialog-container": {
    backdropFilter: "blur(5px)"
  },
  "& p, label, h6": {
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  },
  "& .dialog-title": {
    padding: "50px 32px 10px",
    textAlign: "center",
    "& h6": {
      fontSize: "24px",
      fontWeight: "500",
      lineHeight: "36px",
    }
  },
  "& .modalBodyWrapper": {
    padding: "20px !important"
  },
  "& .custom-textbox": {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "16px !important"
    },
  },
  "& .documentPicker": {
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    cursor: "pointer",
    justifyContent: "space-between",
    display: "flex",
    background: "#FCFAF7",
    padding: "16px",
    "& .documentPickerText": {
      opacity: "0.42",
      color: "#1C2324",
    }
  },
  "& .functionButtonContainer": {
    display: "flex",
    gap: "1.5rem",
    marginTop: "0px",
    marginBottom: "20px",
    justifyContent: "center",
  },
  "& .buttonFunction": {
    backgroundColor: "#DF5200",
    borderRadius: "1.85rem",
    paddingBlock: ".3rem",
    paddingInline: "2rem",
    padding: "8px 40px",
    height: "48px",
    minWidth: "126px"
  },
  "& .cancel-btn": {
    opacity: "0.72",
    backgroundColor: "#E4DDC9"
  },
  "& .buttonFunctionTxt": {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#1C2324",
  },
  "& .import-btn": {
    color: "#fff"
  }
});

const CustomFormLabel = styled(FormLabel)({
  fontWeight: 400,
  fontStyle: "normal",
  lineHeight: "22px",
  fontSize: "14px",
  color: "#1C2324",
  marginBottom: "8px",
  opacity: 0.64,
});

const CustomFieldGrid = styled(Grid)({
  minHeight: "93px",
  display: "flex !important",
  flexDirection: "column",
  position: "relative",
  paddingTop: "0px !important",
  paddingBottom:"18px !important",
  "& .validation_errors": {
    color: "red",
    position: "absolute",
    bottom: "0px", 
    margin: "0px",
    fontSize: "12px"
  }, 
  "& .viewFile": {
    marginLeft: "5px",
    color: "#DF5200", 
    fontFamily: "IBM Plex Sans",
    fontSize: "12px", 
  }
});
const DividerBox = styled(Divider)({
  height: "2px",
  backgroundColor: "#E4DDC9",
});


const StyledTabs = styled(Tabs)(() => ({
  color: "#1C2324",
  "& .MuiTabs-indicator": {
    height: "2px", backgroundColor: '#DF5200',
    width: "100%",
  }
}));

const StyledImageBox = styled('img')({
  minWidth: '130px',
  objectFit: "cover", maxWidth: "130px",
  height: '130px',
  '@media (max-width:600px)': {
    minWidth: "100%",
    width: '100%',
  },
})

const LineClampTypography = styled(Typography)({
   '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 2, fontWeight: 400, 
  wordBreak: "break-all", opacity: "0.72",
  lineHeight: "22px",  marginTop: "4px",
  overflow: 'hidden', fontSize: "14px", 
  display: '-webkit-box', fontFamily: configJSON.fontFamily,
  height: "46px",  color: "#1C2324",
});

const StyledVideoBox = styled('video')({
  maxWidth: "130px", objectFit: "cover", 
  height: "130px", 
  borderRadius: "8px 0 0 8px", minWidth: "130px",
  '@media (max-width:600px)': {
    width: '100%', minWidth: "100%",
  },
});

const SubAccordianBoxLibrary = styled(Accordion)({
  backgroundColor: "#F0EBE0",
  borderRadius: "12px",  
  boxShadow: "none",  

  '& .MuiAccordionSummary-root': {  
    padding: '0px !important', 
  },
  '& .MuiAccordionSummary-root.Mui-expanded': { 

    minHeight: "38px" 
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {  
    margin: '0px !important', 
  },
})




const LinkShareButton = styled(Button)({
  color: "#006A77",
  border: "none", 
  borderRadius: "none", 
  fontWeight: 500,
  fontSize: '14px !important',
  padding: "0",
  textTransform: "none", 
  lineHeight: "22px",
  '@media (max-width:600px)': { 
    '& .MuiTypography-root': {
      fontSize: '12px !important', 
    }
  },
  "&:hover": {
    background: "none !iimportant", 
  },
})

const AccordionBox = styled(Accordion)({
  backgroundColor: "#F0EBE0", minHeight: "80px", borderRadius: "12px",
  boxShadow: "none",
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0!important'
  },
  '& .MuiAccordion-root.Mui-expanded:before': {
    height: '2px',
    background: 'rgba(0, 0, 0, 0.06)',
    opacity: 1,
  },
  '&.MuiAccordion-root:before': {
    height: '0!important',
  },
  '& .MuiAccordion-root:before': {
    margin: '0 20px',
    background: 'rgba(0, 0, 0, 0.06)',
    height: '2px',
  },
  '& .MuiAccordionSummary-root': {
    padding: '0px 16px!important',
  },
  '& .MuiAccordionSummary-content': {
    margin: '16px 0px!important',
  },
  '& .MuiAccordionDetails-root': {
    padding: 0,
    '& .MuiPaper-rounded': {
      padding: '0px 24px!important',
    },
    '& .MuiPaper-rounded .MuiAccordionSummary-root': {
      padding: '0px!important',
    },
    '& MuiPaper-rounded .MuiAccordionSummary-root': {
      alignItems: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {
      margin: '12px 0px!important',
    },
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0
  }

});

const AccordionBoxLibrary = styled(Accordion)({
  borderRadius: "12px",
  minHeight: "80px",
  boxShadow: "none",
  backgroundColor: "#F0EBE0",
  '&.MuiAccordion-root:before': { 
    height: '0!important',
  },
  '& .MuiAccordion-root:before': {
    height: '2px',
    margin: '0 20px',
    background: 'rgba(0, 0, 0, 0.06)'
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0!important'
  }, 
  '& .MuiAccordion-root.Mui-expanded:before': {
    height: '2px', 
    opacity: 1, 
    background: 'rgba(0, 0, 0, 0.06)'
  }, 
  '& .MuiAccordionDetails-root': { 
    padding: 0,
    '& .MuiPaper-rounded': {
      padding: '0px 24px!important', 
    },
    '& .MuiPaper-rounded .MuiAccordionSummary-root': {
      padding: '0px!important', 
    },
    '& MuiPaper-rounded .MuiAccordionSummary-root': {
      alignItems: 'flex-start', 
    },
    '& .MuiAccordionSummary-content': {  
      margin: '12px 0px!important',
    }, 
  },
  '& .MuiAccordionSummary-root': {
    padding: '0px 16px 0 8px !important', 
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0px!important',
  },  
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0 
  }, 
  '& .MuiAccordionSummary-expandIcon': {
    padding: 0
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: 0
  }

});

const TextArea = styled(TextareaAutosize)({
  borderRadius: "0.75rem", 
  paddingBlock: "1rem",
  fontSize: "1rem",
  resize: "none",  
  paddingInline: "1.13rem",  
  fontFamily:  configJSON.fontFamily,
  width: "100%",
  border: "1px  solid #F0EBE0",
  backgroundColor: '#FCFAF7',
  height: "300px !important",
  overflow: "auto !important",
  lineHeight: "24px",
  fontWeight: 400,
})
const CustomVideoBox = styled(Box)({
  position: "relative",
  '@media (max-width:600px)': {
    width: '100%', 
  },
})

const StyeledButton = styled(Button)({
  padding: ".875rem 2rem", 
  borderRadius: "1.85rem",
   color: "#FFF", 
  backgroundColor: "#DF5200",  border: "1px solid #F0EBE0",
  "&:hover": {
    background: "#DF5200",
  },

  '@media (max-width:600px)': {
    padding: "0.675rem 1rem", 
    '& .MuiTypography-root': {
      fontSize: '14px !important',
    }
  },
});

const StyledBadge = styled(Badge)({
  width: '100%' 
});

const StyledBoxSection = styled(Box)({
  backgroundColor: "#F8F6F1",

  borderRadius: "12px",
  '@media (max-width:600px)': {
    padding: "0.675rem 1rem",
  },
});

const styles = {
  exportBtnStyle: {
    marginBottom: "10px",
    border: "1px solid #E4DDC9",
    background: "#FCFAF7", 
    borderRadius: "28px",
    fontWeight: 600,
    color: "#1C2324", 
    opacity: "0.8",
    textTransform: "uppercase", 
    boxShadow: "none",
    marginRight: "10px", 
    fontSize: "16px", 
    cursor: "pointer"
  } as React.CSSProperties,
  rootGrid: { 
    flexGrow: 1, 
    width: "100%",
  } as React.CSSProperties,

  languageTabs: { 
    fontFamily: configJSON.fontFamily,
    fontWeight: 500,     
    fontSize: "1.2rem",   
    borderBlockEnd: "2px solid rgba(28, 35, 36, .12)",
    lineHeight: "28px",
  },  

  libraryCardContent: {
    width: "100%", padding: "10px", 
  }, faqheading: {
    fontWeight: 500,
    fontSize: "18px", lineHeight: "28px",
    marginBottom: '2px',
     textTransform: 'capitalize',
    color: "#1C2324",

    fontFamily: configJSON.fontFamily,  
  } as React.CSSProperties,
  
  heading: {
    fontFamily: configJSON.fontFamily, lineHeight: "48px",
    fontSize: "32px", fontWeight: 600, color: "#1C2324",
  } as React.CSSProperties,
  addButtonContainer: {
    paddingBlock: "1.5rem",alignSelf: "center", 
    display: "flex",
     paddingInline: "2rem",
 
    flexDirection: "column",
    gap: "2rem"
  } as React.CSSProperties,
  inputStyle: { 
    height: "100px", width: "100%",
    justifyContent: "space-between",
    display: "flex", flexDirection: "column",

    borderBottom: "1px solid rgba(0, 0, 0, 0.6)", 
  },
  buttonStyle: {
    
    backgroundColor: "rgb(98, 0, 238)",
    border: "none", marginTop: "40px",

    width: "100%", height: "45px",

  },
  buttonComponentAddNewPhase: {
    backgroundColor: "#DF5200"
  } as React.CSSProperties, 
  addButtonTxt: { 
    color: "#fff",
    opacity: 1, 
  } as React.CSSProperties, 
 
  faqimg: {
    width: "48px", height: "48px",
  } as React.CSSProperties,

  buttonContainer: {
    alignItems: "center", display: "flex",
    gap: "10px",
    alignSelf: "center",
  } as React.CSSProperties,

  buttonTxt: {
    lineHeight: "28px", 
    fontWeight: 600,  
    fontFamily: configJSON.fontFamily,  
    textTransform: "uppercase", 
    textAlign: "center",   
    color: "#FFF",  
    fontSize: "18px",  
    marginLeft: ".25rem",  
    whiteSpace: "nowrap", 
  } as React.CSSProperties,   
  paragraph: {
    color: "#1C2324",
    opacity: "0.72",  
    fontSize: "14px",
    fontFamily: configJSON.fontFamily,
    fontWeight: 400, 
    lineHeight: "22px",
    marginTop: "4px", 
  } as React.CSSProperties,
   
  videoDetaildImg: { 
    height: '150px', width: '100%', 
    borderRadius: "12px", 
  
    objectFit: "cover",
  } as React.CSSProperties, 
  faqCards: {
    flexDirection: "column", 
    border: "none",

    display: "flex", gap: "16px",
  },
  libraryCard: { 
    borderRadius: "12px", backgroundColor: "#F0EBE0",
    overflow: "hidden" 
  },
  sectionHeadingText: {
    fontFamily: configJSON.fontFamily,
    fontSize: "24px", fontWeight: 600,
    lineHeight: "36px",
  } as React.CSSProperties,
  libraryLastUpdate: {
    fontFamily: configJSON.fontFamily, fontSize: "12px",
    color: "#1C2324",
    lineHeight: "18px", 
    fontWeight: 400,
     fontStyle: "italic", 
    opacity: 0.56, whiteSpace: "nowrap"
  } as React.CSSProperties, 
  containerHead: {
    justifyContent: "space-between", display: "flex",
    alignItems: "center"
  } as React.CSSProperties,
  videoImg: {
    objectFit: "cover", borderRadius: "12px 0 0 12px",
    maxWidth: '130px', height: '130px'
  } as React.CSSProperties,
  
  modalHeaderTypography: {
    lineHeight: "36px", 
    fontFamily: configJSON.fontFamily, fontStyle: "normal", 
    color: "#1C2324", 
    fontWeight: 500, fontSize: "24px"
  },
  modalCardFieldError: { 
    fontFamily: configJSON.fontFamily,
    fontSize: ".75rem", 
    fontWeight: 400,
    color: "#f44336", 
  },
  customLabelField: { 
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#1c2324",
    opacity: "0.64",
    fontSize: "14px",
  },
  
  photoCamera: {
    width: 60, 
    color: "#1C2324",
    height: 60, 
    opacity: .5,
  },

  userTypeFormControl: {
    marginTop: "8px", 
  },

  modalCardHeader: {
    paddingBlockEnd: 0,
    paddingInline: "2rem",
  },
  
  secondaryModalContentsContainer: {
    gap: "2rem",flexDirection: "column", 
    height: "100%", 
    fontSize: "16px",alignItems: "center",
    width: "100%",display: "flex",
  } as React.CSSProperties, 
  rootGridContainer: {
    display: "flex", flexWrap: "wrap", justifyContent: "space-between",
  },
  readMore: { 
    color: "#DF5200",
    fontSize: "14px",
    fontWeight: 500, 
    lineHeight: "22px",
    fontFamily: configJSON.fontFamily,
    textTransform: "uppercase",
    cursor: "pointer"
  } as React.CSSProperties,  
  modalimageBox: {
    alignItems: "center",
    justifyContent: "center", 
    display: "flex",
    paddingInline: "3rem", 
  } as React.CSSProperties,
  hide: {
    display: "none"
  },
  
  cardAction: { 
    alignItems: "center",
    display: "flex",
    gap: "5px",
    justifyContent: "flex-end"
  },

  formfieldWrapper: { 
    paddingBottom: "0px",
    paddingTop: "0px"
  },
  icon: {
    marginLeft: "8px",
    width: "24px",
    cursor: "pointer",
    height: "24px",
  } as React.CSSProperties,

  
  modalRoot: {
    gap: 1,
    display: "flex",
    fontSize: "16px",
    borderBlockStart: "1px solid #ccc",
    paddingBlock: "2rem",
    justifyContent: "center", backdropFilter: "blur(5px)",
    top: 0, bottom: 0,
    left: 0, right: 0, alignItems: "center",
  } as React.CSSProperties,
  
  librarySubHeading: {
    lineHeight: "24px",
    fontWeight: 500,
    fontSize: "16px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontFamily: configJSON.fontFamily,
    textOverflow: "ellipsis"
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  } as React.CSSProperties,

  libraryCards: {
    display: "flex", flexDirection: "column",
    gap: "16px",
  },
  horiline: {
    height: "2px",
    color: "#E4DDC9",
  } as React.CSSProperties,
  modalCardContentEditModalTypography: {
    fontWeight: 400,
    fontFamily: configJSON.fontFamily,
    paddingBlockEnd: 1,
    fontSize: ".875rem",
    marginBottom: "8px",
    color: "#1C2324",
    opacity: 0.64,
  } as React.CSSProperties,
  modalCardBoxSuccess: {
    position: "absolute",
    height: 360, left: "50%", width: 528,
    top: "50%", "flexDirection": "column",
    justifyContent: "space-between", fontSize: "16px", display: "flex",
    borderRadius: "12px", transform: "translate(-50%, -50%)",
  } as React.CSSProperties,
  secondaryModalCardsContent: {
    padding: 0,
    height: "100%",
    paddingBlockStart: "4.5rem",
  },
  modalTextField: {
    borderRadius: "0.75rem", paddingBlock: "1rem",
    fontSize: "1rem",resize: "none",  
    paddingInline: "1.13rem",  
    fontFamily:  configJSON.fontFamily,
    width: "100%",
    border: "1px  solid #F0EBE0",backgroundColor: '#FCFAF7',
    '::placeholder': {
      fontWeight: 300,
      color: "red",
    },
  } as React.CSSProperties,
  secondaryModalBox: {
    borderBlockStart: "1px solid #ccc",
    backdropFilter: "blur(5px)", top: 0,
    display: "flex",  gap: 1,
    fontSize: "16px",   justifyContent: "center",
    bottom: 0, left: 0, right: 0,
    alignItems: "center",
    height: "100%",  
  } as React.CSSProperties,
  modalTextSucessContainer: {
    display: "flex",
    gap: "1rem",
    fontSize: "16px"
  } as React.CSSProperties,
  
  badgeButton: {
    padding: "4px",
    backgroundColor: "rgba(28, 35, 36, .56)",
  },
  formImageContainer: {
    display: "flex", justifyContent: "center",
    backgroundColor: "#FCFAF7", border: "1px solid #F0EBE0",
    borderRadius: "0.75rem",
    marginTop: "0.5rem",   
    height: "200px",
    width: "100%",
  },
  badgeCardRoot: {
    display: "flex", width: "100%",
    borderRadius: "0.75rem", backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    justifyContent: "center", height: '189px',
  },
  lable: {
     color: '#1C2324',
    fontSize:  '14px',
     lineHeight: '1.5vw',
    opacity: 0.64, 
  } as React.CSSProperties,
  modalButtonGoBack: {
    backgroundColor: "#B3261E",
    fontWeight: 500, paddingBlock: "0.875rem",
    "textTransform": "none",
    color: "#fff", width: "142px",
    borderRadius: "28px",
  } as React.CSSProperties,

  modalButtonCardCancel: {
    borderRadius: "28px",fontWeight: 500,
    "textTransform": "none",
    width: "142px",backgroundColor: "#E4DDC9",
  } as React.CSSProperties,
  buttonComponent: {
    backgroundColor: "#FCFAF7", borderRadius: "1.85rem",
     border: "1px solid #F0EBE0", 
    width: "100%",  
     paddingBlock:  ".875rem",
  } as React.CSSProperties,
  libraryTopicsFont: {
    color: "#1C2324",
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: configJSON.fontFamily,
    opacity: 0.56,
    whiteSpace: "nowrap",
    lineHeight: "18px",
  } as React.CSSProperties,
  libraryChapterTopicheading: {
    fontSize: "18px",lineHeight: "28px",
    fontWeight: 500,
    textTransform: 'capitalize',color: "#1C2324",
    fontFamily: configJSON.fontFamily,
  } as React.CSSProperties,

  // NEW CSS FOR LIBRARY CHAPTERS
  boxLibraryChpaterImg: {
    padding: "0px 12px 0px 0px",
    display: "flex",
  } as React.CSSProperties,
  boxChapterHeading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "2px"
  } as React.CSSProperties,
  ExpandedBoxSubLibraryChpaterImg: {

  } as React.CSSProperties,
  subLibraryChpaterImg: {
    height: "48px",
    width: "48px",
    borderRadius: "8px"
  } as React.CSSProperties,
 

  boxTopicsAction: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  } as React.CSSProperties,
  libraryChpaterImg: {
    height: "64px",
    width: "64px",
    borderRadius: "8px"
  } as React.CSSProperties,
  LibrarySummaryBox:{
    display: {
      xs: "",
      sm: "flex"
    }
  }, 
  // SUB LIBRARY CSS
  boxSubLibraryChpaterImg: {
    display: "flex",
    padding: "0px 16px 0px 0px",
  } as React.CSSProperties,
  topicIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  } as React.CSSProperties,
  boxChapterHeadingExpanded: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "16px"
  } as React.CSSProperties,
};

// Customizable Area End

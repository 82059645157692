import React from "react";

// Customizable Area Start
import moment from "moment";
import {
  Box,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  CircularProgress,
  ListItem,
  ListItemIcon,
  Avatar,
  Modal,
  Card,
  CardContent,
  Container,
  styled
} from "@material-ui/core";
import { Send, Search, AttachFile, SentimentSatisfiedAlt, Mic, Close,KeyboardArrowDown, KeyboardArrowUp, ArrowBack} from '@material-ui/icons';
import "./ChatView.web.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AuioWaveFrame, deleteSVG, micSVG, playSVG, pauseSVG, audioSVG, whiteCameraSVG, documentSVG, videoSVG, closeSVG, gallerySVG } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchField } from "./components/SearchField";
import CustomModal from "../../../components/src/customModal.web";
import ImageCropEditor from "../../../components/src/ImageCropper";
import Webcam from "react-webcam";
import { getNameChar, returnTruthyString } from "../../../components/src/helper";
// Customizable Area End

import ChatViewController, { TMessageList, Props, TMessageElement } from "./ChatViewController";

import { ParticipantComponent } from "./components/ParticipantComponent";
import MessageComponent from "./components/MessageComponent";

import Picker from 'emoji-picker-react'
import NewsAndEventsChatContent from "../../../components/src/NewsAndEventsChatContent";
import MaterrapediaLibraryChatContent from "../../../components/src/MaterrapediaLibraryChatContent";
import MaterrapediaFaqsChatContent from "../../../components/src/MaterrapediaFaqsChatContent";
import ErrorModal from "../../../components/src/ErrorModal.web";
import SurveyDetailsChatContent from "../../../components/src/SurveyDetailsChatContent";
import SurveyChatContent from "../../../components/src/SurveyChatContent";
import FarmDiaryChatContent from "../../../components/src/FarmDiaryChatContent";
import NotificationChatContent from "../../../components/src/NotificationChatContent";
import CropCalendarChatContent from "../../../components/src/CropCalenderChatContent";

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: "IBM Plex Sans",
  },
});

const style={
  dialogHeader:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  } as  React.CSSProperties,
  dialogTitle:{
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "36px",
    color:"#1C2324"
  },
  selectbtn: {
    display: 'flex',
    paddingInline:"12px",
    lineHeight: '28px',
    fontSize: '12px',
    color: '#ffff',
    background: "#DF5200",
    borderRadius: "25px",
    height: "28px",
  },
  removebtn:{
    display: 'flex',
    paddingInline:"12px",
    lineHeight: '28px',
    fontSize: '12px',
    color: '#1C2324',
    background: "#E4DDC9",
    borderRadius: "25px",
    height: "28px",
  },
  addbtn:{
    display: 'flex',
    width:"100%",
    paddingBlock:"14px",
    fontSize: '18px',
    fontFamily:"IBM Plex Sans",
    color: '#fff',
    background: "#DF5200",
    borderRadius: "28px",
    marginTop:"16px"
  },
  alreadyAdded:{
    color:  "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px" ,
    opacity:0.72,
  },
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    backdropFilter: "blur(5px)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%"
  } as React.CSSProperties,
  secondaryModalCardsContent: {
    padding: 0,
    paddingBlockStart: "4.5rem",
    height: "100%"
  },

  secondaryModalContentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    fontSize: "16px",
    alignItems: "center",
    height: "100%",
    width: "100%"
  } as React.CSSProperties,

  modalimageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: "3rem",
  } as React.CSSProperties,
  
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .7)",
    backdropFilter: "blur(10px)",
  } as React.CSSProperties,

  modalCardBoxSuccess: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 528,
    height: 360,
    fontSize: "16px",
    display: "flex",
    "flexDirection": "column",
    justifyContent: "space-between",
    borderRadius: "12px",
  } as React.CSSProperties,
 

  modalTextSucessContainer: {
    display: "flex",
    gap: "1rem",
    fontSize: "16px"
  } as React.CSSProperties,
  modalButtonGoBack: {
    width: "142px",
    backgroundColor: "#B3261E",
    color: "#fff",
    borderRadius: "28px",
    "textTransform": "none",
    fontWeight: 500,
    paddingBlock: "0.875rem"
  } as React.CSSProperties,

  modalButtonCardCancel: {
    width: "142px",
    backgroundColor: "#E4DDC9",
    borderRadius: "28px",
    "textTransform": "none",
    fontWeight: 500,
  } as React.CSSProperties,

  groupAvatarStyle: {
    height: "64px",
    width: "64px",
    background: "#108b88",
    fontSize: "24px",
    fontWeight: 500
  } as React.CSSProperties,

}
const CustomHeadText = styled('h4')({
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',  
  overflow: 'hidden',
  width: '100%',
  maxWidth:"300px"
})
// Customizable Area End

export default class ChatView extends ChatViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start

  highLightMessage = ({ message, search }: { message: string, search: string }) => {
    const start = message.indexOf(search);
    const end = start + search.length;

    const messageBody = message.split("").map((char, index) => {
      const active = index === start || index > start && index <= (end - 1);

      const char1 = char == " " ? <span style={{ backgroundColor: "#F4C0BD" }} key={index}>&#160;</span> : <span style={{ backgroundColor: "#F4C0BD" }} key={index}>{char}</span>

      const char2 = char == " " ? <span key={index}>&#160;</span> : <span key={index}> {char}</span>;

      return active ? char1 : char2;
    }).concat();

    return (
      <span id={"message_identifier_active"} style={{ display: "flex", flexWrap: 'wrap' }}>
        {messageBody}
      </span >
    )
  };

  messageList = ({ data, search }: { data: TMessageElement[], search: string }) => {
    return data.map((ele: TMessageElement, int: number) => {
      return (
        <Box key={ele?.date}>
          <Box className="day-label">
            <span>{ele?.date == moment().format('YYYY-MM-DD') ? 'Today' : moment(ele?.date).format("DD/MM/YY")}</span>
          </Box>
          <Box id={`userchat-ul${ele?.date}`} className="userchat-ul">
            <ul id="day_label" style={{ display:"flex",flexDirection:"column-reverse"}}>
            {ele?.messagelist?.map((item: TMessageList, int) => {
                return <MessageComponent
                  key={item.id}
                  item={item} search={search}
                  scrollService={this.scrollOptionService}
                  highLightMessage={this.highLightMessage}
                  int={int} user={this.state.user}
                  handleShareLinkPopup={this.handleShareLinkPopup}  
                  handleProgress={this.handleProgress}
                  is_mark_delivered={item?.attributes?.is_mark_delivered}  
                  is_mark_read={item?.attributes?.is_mark_read}                
                />
              })}
            </ul>
          </Box>
        </Box>
      );
    })
  };
  Logo = (image: string | undefined, name: string | undefined) => {
    return (
      <Avatar style={style.groupAvatarStyle} src={returnTruthyString(image)}>{getNameChar(name)}</Avatar>
    );
  }

  ChatViewSearchRender = () => {
    return (
    this.state.isVisibleSearchModel ?
      <Box className="search-profile">
        <TextField
          name='meaasge-search-input'
          data-testid="message_search_input"
          onChange={this.searchMessageInputValue}
          InputProps={{
            startAdornment: (
              <Button data-testid='close_search_input' onClick={this.hideSearchModal}>
                <Close />
              </Button>
            ),
            endAdornment: (
              <>
                <Button onClick={() => this.searchScroll(1)}>
                  <KeyboardArrowDown />
                </Button>
                <Button onClick={() => this.searchScroll(0)}>
                  <KeyboardArrowUp />
                </Button>
              </>
            ),
            disableUnderline: true
          }}
        />
      </Box>      
        :      
      <Grid className="search-usserstart">
        <Box className="user-symbol" data-testid="user-symbol" onClick={this.showModal}>
          {this.backBtn()}
          <div className="profile-logo heading">
            {this.Logo(this.state.chatData?.attributes?.group_profile_image, this.state.chatData?.attributes?.name)}
          </div>
          <div className="group-name">
            <CustomHeadText title={this.state.chatData?.attributes.name}>{this.state.chatData?.attributes.name}</CustomHeadText>
            <p>{this.state.chatData?.attributes.participants_count} members in the group</p>
          </div>
        </Box>
        <div className="group-search" data-testid="search_modal_symbol" onClick={this.showSearchModal}><Search /></div>
      </Grid>
    );
 }



backBtn = () =>  (
  this.props.isMobile && 
   <IconButton  id="bacKBtn" onClick={() => this.props.goBack()}>
      <ArrowBack />
    </IconButton>
)

chatAttachmentPopup = (mediaType: string) => {
  let renderMedia: any;
  switch (mediaType) {
    case 'image':
      renderMedia = <div className="chat-attachment">
                      <img src={this.state.imageUrl} alt='' className="chat-image" />
                    </div>;
      break;
    case 'video':
      renderMedia = <div className="chat-attachment">
                      <video controls width="300" src={this.state.videoURL} />
                    </div>
      break;
    case 'application':
      renderMedia = <div className="chat-attachment">
                        <img className="doc-icon" src={documentSVG} alt="" />
                  </div>
      break;
    case 'audio':
      renderMedia = <div className="chat-attachment">
                      <audio controls src={this.state.videoURL} style={{width: '100%'}}/>
                    </div>
      break;
    default:
      renderMedia = null;
      break;
  }

  return renderMedia;
}

ChatActionForm = () => {
  return <Box className={this.addChatActionClass()}>
   {
    this.ifAnyMediaAttached() ? <Button onClick={this.removeMediaAttached} style={{ float: "right" }} data-testid="close-button">
          <span>
            <img className="icon" src={closeSVG} alt="" />
          </span>
      </Button> : <></>
    }
   {this.chatAttachmentPopup(this.state.attachment_file_type)}
   <CustomModal 
        open={this.state.videoFileMaxSize} 
        onClose={this.closeMaxVideoSizeModal}
        title="Error"
        description="Uploaded video size of 250 MB limit exceeded"
    />

   {
    this.state.openWebcam && <Box className="webcam-container chat-attachment">
   {
    this.state.cameraDenied ? ( <span style={{ margin: "0 28px", padding: "24px 0" }}> Please allow camera to capture the photo </span> )
   :
   ( <> <Box className="webcam-img">
        {this.state.webcamUrl === '' ? (
            <Webcam
                audio={false}
                height={200}
                ref={this.webcamRef}
                screenshotFormat="image/jpeg"
                width={220}
                onUserMediaError={this.handleCameraDenied}
                videoConstraints={{
                    width: 220,
                    height: 200,
                    facingMode: "user"
                  }}
            />
        ) : (
            <img width="100%" src={this.state.webcamUrl} alt="Captured" className="chat-image" />
        )}
    </Box>
    <Box>
        {this.state.webcamUrl !== '' ? (
            <Button 
              data-testid="retake-image"
              onClick={this.retakeImage}
              className="webcam-btn"
            >
                Retake Image
            </Button>
        ) : (
            <Button 
              data-testid="capture-image"
              onClick={this.capture} 
              className="webcam-btn"
            >
                Capture
            </Button>
        )}
    </Box>
    </> )}
  </Box>
   }

    <Grid className="chat-textarea-wrapper">
        {this.state.showPicker && (
          <Picker
            pickerStyle={{
            width: "50%",
            position: "absolute",
            left: "24px",
            bottom:"90%",
            zIndex:"9"
          }}
            onEmojiClick={this.onEmojiClick}
          />
        )}
      <Box style={{ display: "flex", gap: ".5rem" }} >
        <Box className={this.showPicker(this.state.showPicker)}>
          <div className="emoji" style={{ display: "flex"}} onClick={this.showEmojiPicker} data-testid="emoji_picker_icon">
            <SentimentSatisfiedAlt />
          </div>
          <TextField
            data-testid="send-message-input"
            placeholder="Message"
            autoComplete='off'
            value={this.state.message}
            onChange={this.handleMessageChange}
            onKeyPress={this.handleKeyPress}
            InputProps={{
              disableUnderline: true
            }}
            inputRef={this.messageInputRef}
            fullWidth
          />
        <Box>  
    <Button
        data-testid="attachment-button"
        variant="text"
        className="attach"
        component="label"
        onClick={this.toggleAttachmentOptions}
      >
    <i className="fas fa-paperclip"></i>  <AttachFile />{/* You can use a suitable icon library here */}
  </Button>

  {this.state.showAttachmentOptions && (
  <div className="attachment-container" ref={this.attachmentOptionsRef}>
      <Button onClick={this.toggleAttachmentOptions} style={{ float: "right" }} data-testid="close-button">
          <span>
            <img className="icon" src={closeSVG} alt="" />
          </span>
      </Button>
    <Box className="attachment-options">
    <Box className="icon-container">
        <Button component="label">
          <input 
          data-testid="send-doc-file-input" 
          type="file" 
          hidden
          accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, application/pdf, application/msword, text/plain, application/vnd.ms-powerpoint, text/csv"
          onChange={(input) => {
            this.handleDocumentUpload(input);
            this.toggleAttachmentOptions();
          }}
          />
          <span className="icon-document-background">
            <img className="icon" src={documentSVG} alt="" />
          </span>
        </Button>
        <Box className="icon-label">Document</Box>
      </Box> 
      <Box className="icon-container">
        <Button 
          component="label" 
          data-testid="webcam-input" 
          onClick={() => {
              this.openWebCamera();
              this.toggleAttachmentOptions();
            }}
        >
          <span className="icon-camera-background">
            <img className="icon" src={whiteCameraSVG} alt="" />
          </span>
        </Button>
        <Box className="icon-label">Camera</Box>
      </Box> 
      <Box className="icon-container">
          <Button component="label">
            <input 
            data-testid="send-image-file-input" 
            type="file" 
            hidden
            onChange={(input) => {
              this.handleFileUpload(input);
              this.toggleAttachmentOptions();
            }}
            />
            <span className="icon-image-background">
              <img className="icon" src={gallerySVG} alt="" />
            </span>
          </Button>
        <Box className="icon-label">Gallery</Box>
      </Box> 
      <Box className="icon-container">
        <Button component="label">
          <input 
              data-testid="send-video-file-input" 
              type="file" 
              accept="video/*"
              onChange={(input) => {
                this.handleVideoUpload(input, 'video');
                this.toggleAttachmentOptions();
              }}
              hidden 
              />
          <span className="icon-video-background">
            <img className="icon" src={videoSVG} alt="" />
          </span>
        </Button>
        <Box className="icon-label">Video</Box>
      </Box>
      <Box className="icon-container">
        <Button component="label">
          <input 
          data-testid="send-audio-file-input" 
          type="file" 
          hidden
          accept="audio/mpeg, audio/wav, audio/mp3, audio/ogg, audio/wav, audio/aac, audio/m4a, audio/flac, aduio/wma, audio/alac, audio/aiff, audio/opus"
          onChange={(input) => {
            this.handleVideoUpload(input, 'audio');
            this.toggleAttachmentOptions();
          }}
          />
          <span className="icon-audio-background">
            <img className="icon" src={audioSVG} alt="" />
          </span>
        </Button>
        <Box className="icon-label">Audio</Box>
      </Box> 
    </Box>
  </div>
  )}
</Box>
        </Box>
        <Box className="number">
          {
            this.ifAnyMediaUrl() ?
              <Box style={{width: '28px', height: '28px', marginBottom: '5px', cursor: 'pointer'}}  
                  data-testid='send-message-button' 
                  onClick={this.handleSendMessage}
                >
                <Send />
              </Box>
              :
              <Box style={{ cursor: 'pointer', display: 'flex'}} 
                    onClick={this.openVoiceRecord}
                    data-testid="mic-button"
                  >
                <Mic />
              </Box>
          }
        </Box>
      </Box>
    </Grid>
  </Box>
}


  ChatMediaScreenRender() {
    
    const ChatAudioRecorder = (
      <Box className="voice_record_container">
        <Box>
        <Box>
          {this.state.isRecording && (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  marginRight: '5px',
                  animation: 'blink 1s infinite',
                }}
              ></Box>
              <span>Recording: {this.state.recordingDuration}s</span>
            </Box>
          )}
        </Box>

        {(this.state.audioBlob && !this.state.disable_send_button) && (
          <Box>
            <audio controls preload="metadata" style={{ width: "100%" }}>
              <source src={URL.createObjectURL(this.state.video)} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}
      </Box>
        <Box className="voice_record_buttons">
          <span onClick={this.deleteRecording} data-testid="delete-voice-recording">
            <img src={deleteSVG} />
          </span>
          {
            this.state.showRecordingMic && <>
            {!this.state.isRecording && this.state.disable_send_button ? (
                <span onClick={this.startAudioRecording} data-testid="start-voice-recording">
                  <img src={micSVG} />
                </span>
              ) : (
                <span onClick={this.stopAudioRecording} data-testid="stop-voice-recording">
                  <img src={pauseSVG} height={40} width={40} />
                </span>
            )}
            </>
          }
          <span><Box className="number">
                  <Box style={{ width: '28px', 
                                height: '28px', 
                                marginLeft: '13px',
                                marginTop: '8px',
                                cursor: 'pointer',
                                opacity: this.state.disable_send_button ? '0.5' : '1'
                              }}  
                        data-testid='send-message-button' 
                        onClick={() => {
                          if(!this.state.disable_send_button)
                            return this.handleSendMessage();
                          }}
                      >
                    <Send />
                  </Box>
                </Box>
          </span>
        </Box>
      </Box>
    )

    if (this.state.isVisibleModal) {
      return (
        <ParticipantComponent
          hideModal={this.hideModal}
          selectGroupImage={this.handleSelectGroupImage}
          removeUser={this.removeUser}
          participant_count={this.state.groupMembers.length}
          image={this.state.chatData?.attributes?.group_profile_image}
          name={this.state.chatData?.attributes.name}
          media_data={this.state.chatMediadata}
          chat_data={this.state.chatData || false}
          media_length={this.state.chatMediadata.length}
          group_members={this.state.groupMembers}
          logoRender={this.Logo}
          isProfileImgUploading={this.state.isProfileImgUploading}
          openAddParticipantModal={this.openAddParticipantModal}
          openRemoveUserModal={this.openRemoveUserModal}
          handleEditGroupName={this.handleEditGroupName}
          isEditGroupName={this.state.isEditGroupName}
          handleChangeChatname={this.handleChangeChatname}
          chatName={this.state.chatName}
          uploadGroupName={this.uploadGroupName}
          participantLoading={this.state.groupMembersLoading} 
        />
      )
    } 
    else {
      return (<Box
          className="chat-history chat-start"
          style={{
            marginInlineStart: "1.5rem",
            borderRadius: "12px 12px 0 0",
            minHeight: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {this.ChatViewSearchRender()}
          <Box id="message-add" style={{ overflow: "auto" ,display: 'flex',flexDirection: 'column-reverse'}}>
            <Box className="userchat">
            <InfiniteScroll
                dataLength={this.state.messagesData?.length+(new Date().getTime() % 18) + 1}
                style={{                
                  boxShadow: "none",
                  width: "100%",
                  display:"flex",
                  flexDirection:"column-reverse"
                }}
                inverse={true}
                scrollableTarget="message-add"
                next={this.getChatDetailWithPages}
                hasMore={true}
                loader={<></>}>
                {this.messageList({ data: this.state.messagesData, search: this.state.searchInputValue })}
              </InfiniteScroll>
        
                </Box>
          </Box>
          {!this.state.record_voice ? this.ChatActionForm() : ChatAudioRecorder}
        </Box >
    
    )}
  }

  WelcomeRender() {
    return (
      <Box
        // className="chat-history"
        style={{
          backgroundColor: "#F8F6F1",
          borderRadius: "12px 12px 0 0",
          paddingBlockStart: "10rem",
          paddingBlockEnd: "1.5rem",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        className="chat-welcome-screen"
      >
        <div className="chat-newopen">
          <div className="user-symbol">
            <div className="username">{this.state.user.initials}</div>
          </div>
          <div className="new-chat">
            <h5 className="header-main">Welcome!</h5>
            <h3 className="header-message">{`${this.state.user.first_name} ${this.state.user.last_name}`}</h3>
          </div>
        </div>
        <div className="signin_user_detail">
          <p className="signin_text">You are signed in as
            <span className="signin_user"> {`${this.state.user.email}`}</span>
          </p>
        </div>
      </Box>
    );
  }

  renderLoader=()=>{
    if (this.state.isLoading){
      return <Box sx={{ display: 'flex', justifyContent: "center" }}>
      <CircularProgress
        style={{ color: "#df5200" }}
      />
    </Box>
    }
  }

   handleSelectParticipant=(ele:any)=>{
    let filteredData:any;
    let isItemExist=this.state.selectedParticipant.find((item:any)=>item.id==ele.id);
    filteredData=isItemExist ?this.state.selectedParticipant.filter((item:any)=>item.id != ele.id) : [...this.state.selectedParticipant,ele]
    this.handleParticipant(filteredData)
  }

   renderSelectButton=(ele:any)=>{
    let button=<Button style={style.selectbtn} data-testid="select-user-btn-id" onClick={(e)=>{   
      this.handleSelectParticipant(ele)
    }} >
      Select
    </Button>;


    let userExist= this.state.groupMembers.find((item:any)=>item.id == ele.id)
      if(userExist)(
        button=<Typography variant="subtitle1" style={style.alreadyAdded} >Already added</Typography>
      )

    if(this.state.selectedParticipant.find((item:any)=>item.id==ele.id)){
     button = <Button style={style.removebtn} data-testid="remove-user-btn-id" onClick={(e)=>{             
      this.handleSelectParticipant(ele)
    }} >
      Remove
    </Button>
    }
    return button
  }

  renderChatList = () => {  
    return this.state.chatList?.map((element: any, i: number) => {
      const name = element.name?.split(" ")
      let avatarTitle = ""
      if (name?.length) {
        avatarTitle = name[0].charAt(0).toUpperCase()
        if (name.length >= 2) {
          avatarTitle += name[1].charAt(0).toUpperCase()
        }
      }
      return (
        <ListItem
          button
          key={element.id}
          className='user-box'
          style={{width:"99%"}}>
          <ListItemIcon
            data-testid={`user-image`}
            className="user-image"
          >
            {
              element.logo
                ?
                <Avatar src={element.logo} alt="" id="user_profile_image" />
                :
                <Avatar
                  data-testid='user-symbol'
                  className="user-symbol"
                  style={{height:'40px',width:'40px', fontSize:'14px'}}            
                >
                  {avatarTitle}
                </Avatar>
            }
          </ListItemIcon>
  
          <div className="chatside-user">
            <div className="name">
              <h5>{element.name}</h5>          
            </div>   
            <div>
            {this.renderSelectButton(element)}
              </div>      
          </div>
        </ListItem >
      );
    })
  }

  renderAddParticipant=()=>{
    return <Dialog
    aria-labelledby="customized-dialog-title"
    open={this.state.addParticipantModal}
    maxWidth={"md"}
    PaperProps={{
      style: { borderRadius: 12, width: "620px" },
    }}
  >
    <div
      style={style.dialogHeader}
    >
        <DialogTitle>
          <Typography variant="subtitle1" style={style.dialogTitle}>
            Add Participant
          </Typography>
        </DialogTitle>
      <div style={{ marginRight: 20, color: '#1C2324' }}>
        <IconButton
          aria-label="close"
          onClick={this.closeAddParticipantModal}
        >
          <Close />
        </IconButton>
      </div>
    </div>
    <DialogContent dividers style={{padding:"1.5rem"}}>
      <div className="add-search-chat">
      <SearchField
          chatList={this.state.chatList}
          searchText={this.state.searchText}
          filterList={this.filterList}
          isAddGroup={true}
          handleParticipant={this.handleParticipant}
          selectedParticipant={this.state.selectedParticipant}
          onInputChange={this.onInputChange}
          disableOptions={this.state.groupMembers?.map(((item:any)=>item.id))}
        />
      </div>          
      <div id="addparticipantScrollableDiv" style={{ overflowY: "auto", maxHeight: 336,marginBottom:'20px' }}>
            {this.renderLoader()}
              <InfiniteScroll
                dataLength={this.state.chatList?.length}
                className="sidebar-chat"
                style={{                
                  boxShadow: "none",
                  width: "100%",
                }}
                scrollableTarget="addparticipantScrollableDiv"
                next={this.userChatListRenderer}
                hasMore={true}
                loader={<></>}>
               { this.renderChatList()}
              </InfiniteScroll>
            </div>
            {!this.state.isLoading &&  <Button style={style.addbtn} disabled={this.state.selectedParticipant.length > 0 ? false:true} data-testid="add-user-btn-id" onClick={this.addUsers} >
                {this.state.isAddingUser ? <CircularProgress size={28} style={{ color: "#fff" }} /> :"Add"} 
              </Button>  }
    </DialogContent>
  </Dialog>
  }

  renderRemoveUserModal = () => {
    return <Grid item>
      <Modal
        open={this.state.removeUserModal}
        onClose={this.closeRemoveUserModal}
        aria-labelledby="modal-calendar-alert-validation"
        aria-describedby="modal-calendar-alert"
        data-testid="close_modal_button"
        style={style.secondaryModalBox}
        disableScrollLock={true}
        BackdropProps={{
          style: style.modalBackdropProps,
        }}
      >
        <Card
          variant="outlined"
          style={style.modalCardBoxSuccess}
        >
          <CardContent style={style.secondaryModalCardsContent}>
            <Container
              style={style.secondaryModalContentsContainer}
            >
              <Typography align="center" style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "36px",
                color: "#1C2324",
              }}>
                Remove user
              </Typography>

              <Box style={style.modalimageBox}>
                <Typography align="center" style={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#1C2324",
                  textAlign: "center"
                }}>
                  Are you sure you want to delete?
                </Typography>
              </Box>
              <Box style={style.modalTextSucessContainer}>
                <Button
                  fullWidth
                  style={style.modalButtonGoBack}
                  onClick={this.removeUser}
                  data-testid={"modal_delete_button"}
                  disabled={this.state.isUserRemoving}
                >
                  {
                    !this.state.isUserRemoving ? <strong>DELETE</strong> : <CircularProgress size={28} style={{ color: "#fff" }} />
                  }

                </Button>
                <Button
                  fullWidth
                  style={style.modalButtonCardCancel}
                  onClick={this.closeRemoveUserModal}
                  data-testid="close_remove_modal_button"
                >
                  <strong>CANCEL</strong>
                </Button>
              </Box>
            </Container>
          </CardContent>
        </Card>
      </Modal>
    </Grid>
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          {
            this.props.id.length
              ? this.ChatMediaScreenRender()
              : this.WelcomeRender()
          }
         {this.renderAddParticipant()}
         {this.renderRemoveUserModal()}
         {this.state.groupProfileImageUrl && <ImageCropEditor isUploading={this.state.isProfileImgUploading} open={this.state.cropImageModal} src={this.state.groupProfileImageUrl} onClose={this.handleCloseCropModal} title="Edit group profile" buttonText="Upload" handleUploadImage={this.uploadGroupProfileImg}/>}
         <CustomModal 
          open={!!this.state.apiError} 
          onClose={this.closeErrorModal}
          title="Error"
          description={this.state.apiError}
          />
        </ThemeProvider>  
        <NewsAndEventsChatContent 
        handleShareLinkPopup={this.handleShareLinkPopup}
        isLinkPopup={this.state.isNewsLink} 
        newsAndEventsObj={this.state.showLinkObj}/>   
        <CropCalendarChatContent 
          handleExpandDetails={this.handleExpandDetails}
          handleShareLinkPopup={this.handleShareLinkPopup}
          expandCropCalendarInputDetails={this.state.expandCropCalendarInputDetails}
          isLinkPopup={this.state.isCropCalendar} 
          cropCalendarObj={this.state.showLinkObj}
        />  
        <MaterrapediaLibraryChatContent 
        handleShareLinkPopup={this.handleShareLinkPopup}
        isLinkPopup={this.state.isLibrariesLink} 
        librariesObj={this.state.showLinkObj}
        />   
         <MaterrapediaFaqsChatContent
        handleShareLinkPopup={this.handleShareLinkPopup}
        isLinkPopup={this.state.isFaqLInk} 
        faqsObj={this.state.showLinkObj}
        /> 
         <SurveyChatContent
          handleShareLinkPopup={this.handleShareLinkPopup}
          isLinkPopup={this.state.isSurveyLInk}
          surveyObj={this.state.showLinkObj}
        />  
        <SurveyDetailsChatContent
          handleShareLinkPopup={this.handleShareLinkPopup}
          isLinkPopup={this.state.isSurveyDeatilsLInk}
          surveyObj={this.state.showLinkObj}
        />  
        <FarmDiaryChatContent
          handleShareLinkPopup={this.handleShareLinkPopup}
          isLinkPopup={this.state.isFarmLInk}
          farmObj={this.state.showLinkObj}
          farmType = {this.state.farmType}
        /> 
        <NotificationChatContent
          handleShareLinkPopup={this.handleShareLinkPopup}
          isLinkPopup={this.state.isNotifyLInk}
          notificationObj={this.state.showLinkObj}
          notiFyType = {this.state.notiFyType}
        />  
        {/* ERROR-MODAL STARTS */}
        <ErrorModal
          open={this.state.errorModalOpen}
          errorTitle="Error"
          errorSubTitle={this.state.errorModalMsg}
          BtnTitle="Okay"
          handleBtnClick={this.handleErrorModalClose}
        />
        {/* ERROR-MODAL ENDS */}

      </React.Fragment>
    );
    // Customizable Area End
  }
}

import React from "react";
import {
  AccordionDetails,
  Box,
  Table,
  TableBody,
  IconButton,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Tooltip
} from "@material-ui/core";
import { IWeedManagementDataTypes } from "../interface.web";
import { dateDDMMYYYY } from "../helper";

interface WeedManagementActivityDetailsPros {
  data: IWeedManagementDataTypes[];
  handleDelete: (id: string) => void;
  handleEdit: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined) => void;
  handleShareLink: (id: string, land_detail_id: string | undefined, crop_id: string | string[] | undefined, message: string) => void;
}

const WeedManagementActivityDetails = ({
  data = [],
  handleDelete,
  handleEdit,
  handleShareLink
}: WeedManagementActivityDetailsPros) => {
  const getPreFixAndSuffix = (label: string, value: string | Date | number) => {
    if (label.toLowerCase().includes("cost")) {
      return "₹ " + value.toString() + "/-"
    } else {
      return value.toString()
    }
  }

  // RENDER TABLE ROW
  const rendertableRow = (label: string, value: string | Date | number | null | undefined, unit?: string) => {
    const formattedValue = (value !== "" && value !== null && value !== undefined) ? getPreFixAndSuffix(label, value) : "-";
    return (
      <TableRow>
        <TableCell
          style={{
            ...styleWeb.tableCell,
            ...styleWeb.opacity64,
          }}
        >
          {label}
        </TableCell>
        <TableCell style={styleWeb.tableCell}>{formattedValue}{" "}{unit}</TableCell>
      </TableRow>
    );
  };

  const renderTable = (data: IWeedManagementDataTypes) => {
    const { id, attributes } = data ?? {};
    const { weeding_date, machine_charges, labor_cost, cost_of_weedicide, quantity_of_weedicide,
      labor_cost_of_spraying, bullock_drawn_cost, fuel_consumption, weedicide_id, weeding_type_id, land_detail_id, crop_id,
      fuel_consumption_unit_id, weedicide_unit_id, freeze_record } = attributes ?? {};

    return (
      <Box style={{ width: "100%", borderTop: "1px solid #F0EBE0", }} key={id}>
        <Table>
          <TableHead>
            <TableRow style={styleWeb.tableHead}>
              <TableCell style={styleWeb.headInfo}>Farm: <span style={styleWeb.highlightedText}>{" "}{land_detail_id[0]?.name ?? "-"}</span> </TableCell>
              <TableCell style={styleWeb.headInfo}>Crop: <span style={styleWeb.highlightedText}>{" "}{crop_id?.[0]?.name ?? "-"}</span></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rendertableRow("Weeding Date", dateDDMMYYYY(weeding_date))}
            {weeding_type_id && rendertableRow("Weeding Type", weeding_type_id[0].name)}
            {weedicide_id && rendertableRow("Weedicide Name", weedicide_id[0].name)}
            {quantity_of_weedicide !== null && quantity_of_weedicide !== undefined && rendertableRow("Quantity of Weedicide", quantity_of_weedicide, weedicide_unit_id ? weedicide_unit_id[0].name : "")}
            {cost_of_weedicide !== null && cost_of_weedicide !== undefined && rendertableRow("Cost of Weedicide", cost_of_weedicide)}
            {labor_cost !== null && labor_cost !== undefined && rendertableRow("Labor Cost", labor_cost)}
            {fuel_consumption !== null && fuel_consumption !== undefined && rendertableRow("Fuel Consumption", fuel_consumption, fuel_consumption_unit_id ? fuel_consumption_unit_id[0]?.name : "")}
            {machine_charges !== null && machine_charges !== undefined && rendertableRow("Machine Cost", machine_charges)}
            {labor_cost_of_spraying !== null && labor_cost_of_spraying !== undefined && rendertableRow("Cost of Spraying", labor_cost_of_spraying)}
            {bullock_drawn_cost !== null && bullock_drawn_cost !== undefined && rendertableRow("Bullock Drawn Cost", bullock_drawn_cost)}
          </TableBody>
        </Table>
        <Box style={styleWeb.tableFooter}>
          <Box style={styleWeb.iconsbox} onClick={()=> handleShareLink(id, land_detail_id[0].id, crop_id?.[0]?.id, "weed_managements")}>
            <IconButton style={styleWeb.iconbutton}>
              <img
                src={require("../share-icons.svg")}
                alt="Share in chat"
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
            <Typography style={styleWeb.shareInChat}>Share in Chat</Typography>
          </Box>
          <Box style={styleWeb.iconsbox}>
          <Tooltip title={freeze_record ? "Activity Freezed" : ""}>
              <Box>
                <IconButton style={styleWeb.iconbutton}
                  onClick={() => handleEdit(id, land_detail_id[0].id, crop_id?.[0]?.id)}
                  disabled={freeze_record}
                >
                  <img
                    src={require("../edit-icon.svg")}
                    alt="Edit activity"
                    style={styleWeb.actionIcon}
                  />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title={freeze_record ? "Activity Freezed" : ""}>
              <Box>
                <IconButton
                  style={styleWeb.iconbutton}
                  onClick={() => handleDelete(id)}
                  disabled={freeze_record}
                >
                  <img
                    src={require("../delete-icon.svg")}
                    alt="Delete activity"
                    style={styleWeb.actionIcon}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <AccordionDetails
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxHeight: "350px",
        overflow: "auto",
      }}
    >
      {data?.length > 0 ? (
        data.map((item) => {
          return renderTable(item);
        })
      ) : (
        <Box style={{ width: "100%" }}>
          <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
            No data found
          </Typography>
        </Box>
      )}
    </AccordionDetails>
  );
};

export default WeedManagementActivityDetails;

const styleWeb = {
  tableCell: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "50%",
  } as React.CSSProperties,
  tableHead: {
    border: "1px solid #F0EBE0",
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#1C2324",
    padding: "8px 12px",
    width: "100%",
  } as React.CSSProperties,
  highlightedText: {
    fontWeight: 500,
  } as React.CSSProperties,
  headInfo: {
    fontWeight: 400,
    padding: "8px 12px",
    fontSize: "12px",
    color: "#1C2324",
    border: "1px solid #F0EBE0",
  } as React.CSSProperties,
  shareInChat: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#006A77",
    lineHeight: "18px",
  } as React.CSSProperties,
  iconsbox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer"
  } as React.CSSProperties,
  actionIcon: {
    width: "20px",
    height: "20px",
  } as React.CSSProperties,
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  tableFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
  } as React.CSSProperties,
  opacity64: {
    opacity: "64%",
  } as React.CSSProperties,
};

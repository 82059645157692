import React from 'react'
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Popover,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { BlankCheckbox, FilterCloseIconSVG, checkedCheckBox } from '../../blocks/AdminConsole/src/assets';

interface AssignVillageFilterProps {
  openTableFilter: boolean;
  anchorEl: any;
  handleCloseFilter: () => void;
  handleSubmitFilter: () => void;
  sortAscending: boolean;
  sortDescending: boolean;
  handleChangeAtoZ: () => void;
  handleChangeZtoA: () => void;
  handleActivityChange: (event: any) => void;
  selectedActivity: string[],
  handleFilterClearUT: () => void;
}

function ActivityFilterPopup(props: AssignVillageFilterProps) {
  const {
    openTableFilter,
    anchorEl,
    sortAscending,
    sortDescending,
    handleChangeAtoZ,
    handleChangeZtoA,
    handleSubmitFilter,
    handleCloseFilter,
    handleActivityChange,
    selectedActivity,
    handleFilterClearUT
  } = props;

  return (
    <>
      <Popover
        className="filterPopover filterPopover-farm-diaries"
        open={openTableFilter}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleCloseFilter}
      >
        <Box
          style={{
            padding: "16px",
            width: "262px",
            overflow: "inherit !important",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                color: "#1C2324",
                fontWeight: 500,
              }}
            >
              Filter
            </Typography>
            <IconButton
              aria-label="close"
              data-testid="VillagefilterModalCloseBtnTestid"
              className="close_btn"
              style={{ padding: "0px" }}
              onClick={handleCloseFilter}
            >
              <img src={FilterCloseIconSVG} alt='close' />
            </IconButton>
          </Box>
          <Box style={{ marginTop: "7px", }}>
            <Box
              className="checkbox-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                className="temp-checkbox"
                control={
                  <Checkbox
                    color="secondary"
                    disableRipple
                    className="azcheckbox"
                    icon={<img src={BlankCheckbox} />}
                    checkedIcon={
                      <img src={checkedCheckBox} className="checkboxImgCustom" />
                    }
                    onChange={handleChangeAtoZ}
                    checked={sortAscending}
                  />
                }
                label={
                  <Typography style={{
                    fontWeight: 400, fontSize: "14px",
                    lineHeight: "22px"
                  }} component="span">
                    Sort by <span style={{ fontWeight: 500 }}>A-Z</span>
                  </Typography>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                className="temp-checkbox"
                style={{ transform: " translateX(2px) !important" }}
                control={
                  <Checkbox
                    color="secondary"
                    disableRipple
                    className="zacheckbox"
                    icon={<img src={BlankCheckbox} />}
                    checkedIcon={
                      <img src={checkedCheckBox} className="checkboxImgCustom" />
                    }
                    onChange={handleChangeZtoA}
                    checked={sortDescending}
                  />
                }
                label={
                  <Typography style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "22px"
                  }}
                    component="span">
                    Sort by <span style={{ fontWeight: 500 }}>Z-A</span>
                  </Typography>
                }
                labelPlacement="end"
              />
            </Box>
            <Box style={{ marginTop: "9px", padding: "12px", borderTop: "1px solid #F0EBE0" }}>
              <Grid container style={{ display: "grid", gridGap: "12px" }}>

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span"> Crop Failure</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="crop_failures"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("crop_failures")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span"> Gap filling</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="gap_fillings"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("gap_fillings")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span"> Weed management</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="weed_managements"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("weed_managements")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span">Nutrient management</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="nutrients"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("nutrients")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span"> Pest management</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="pest_management_activities"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("pest_management_activities")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span"> Irrigation</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="irrigations"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("irrigations")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span">Harvest</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="harvests"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("harvests")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />

                <FormControlLabel
                  className="temp-checkbox"
                  labelPlacement="end"
                  label={<Typography style={filterStyle.checkBoxLabel} component="span">Sell</Typography>}
                  control={
                    <Checkbox
                      color="secondary"
                      disableRipple
                      className="azcheckbox"
                      icon={<img src={BlankCheckbox} />}
                      name="sells"
                      onChange={(event) => handleActivityChange(event)}
                      checked={selectedActivity.includes("sells")}
                      checkedIcon={<img src={checkedCheckBox} className="checkboxImgCustom" />}
                      style={filterStyle.tempCheckBox}
                    />
                  }
                />
              </Grid>
               {/* SAVE AND CLEAR BUTTOS */}
               <Grid
                  item
                  container
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexFlow: "row",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    style={filterStyle.saveBtn}
                    className="submit_accordion_btn"
                    variant="contained"
                    onClick={handleSubmitFilter}
                    data-testid="VillageFilterSubmitBtnTestid"
                  >
                    Save
                  </Button>
                  <Button
                    style={filterStyle.clearBtn}
                    variant="outlined"
                    data-testid="VillageFilterClearBtnTestid"
                    onClick={handleFilterClearUT}
                  >
                    Clear
                  </Button>
                </Grid>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default ActivityFilterPopup

export const getErrorMsg = (isError: boolean, Message: string, HideFEFilter: boolean): JSX.Element => {
  return (
    <>
      {isError ? (
        <Typography
          className="text-danger"
          style={{
            fontSize: "0.75rem",
            position: "absolute",
            zIndex: HideFEFilter ? 1 : 0,
          }}
        >
          {Message}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};
const filterStyle = {
  saveBtn: {
    width: "111px",
    height: "32px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "uppercase",
    background: "#DF5200",
    padding: "5px 38px",
    borderRadius: "16px",
    color: "#FFFFFF",
  } as React.CSSProperties,
  checkBoxLabel: {
    fontWeight: 400, fontSize: "14px", lineHeight: "22px"
  } as React.CSSProperties,
  tempCheckBox: {
    padding: "0px 9px",
  } as React.CSSProperties,
  clearBtn: {
    width: "111px",
    height: "32px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "uppercase",
    border: "1px solid #DF5200",
    borderRadius: "16px",
    background: "#FFFFF",
    padding: "5px 34px",
    marginLeft: "8px",
    color: "#1C2324",
  } as React.CSSProperties
}
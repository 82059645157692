import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    FormControlLabel,
    Typography,
    Input,
    InputAdornment,
    Button,
    Checkbox
} from "@material-ui/core";
import UnitComponentController, { Props, configJSON } from "./UnitComponentController.web";
import SearchIcon from "@material-ui/icons/Search";
import { CheckedSVG, UnCheckedSVG } from "../assets";
import { Add } from "@material-ui/icons";
import { LoadingButton } from "../../../../components/src/AdminConsoleHelper";

// Customizable Area End

export default class UnitComponent extends UnitComponentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <>
                    <Box style={{ padding: "0 16px 16px 16px " }}>
                        <SmallSearchBox>
                            <Input
                                fullWidth
                                disableUnderline
                                className="custom_search_input"
                                data-testid="search_dropdown_testid"
                                type="text"
                                placeholder="Search here..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon
                                            style={{ color: "#1C2324", opacity: 0.48, width: 20, height: 20 }}
                                        />
                                    </InputAdornment>
                                }
                                name="search_dropdown"
                                value={this.state.searchValue}
                                onChange={(event) => this.handleSearchValue(event.target.value)}
                            />
                        </SmallSearchBox>
                        <Box style={{ display: "flex", flexDirection: "column", rowGap: "12px" }}>
                            {this.state.filteredArray.map((item) => (
                                <CheckboxLabel
                                    key={item?.id}
                                    data-testid={`checkbox-${item.id}`}
                                    control={
                                        <Checkbox
                                            checked={item?.checked}
                                            checkedIcon={<img src={CheckedSVG} />}
                                            icon={<img src={UnCheckedSVG} />}
                                            onChange={(event) => { this.handleCheckboxChange(event, item.id) }}
                                        />
                                    }
                                    label={<Typography style={style.unitName}>{item.name}</Typography>}
                                />
                            ))}

                            <Button style={style.addOptionBtn} onClick={this.props.openUnitsDrodpowns}>
                                <Typography style={style.addOptionTxt}>
                                    <Add style={style.addIcon} /> Add option
                                </Typography>
                            </Button>

                        </Box>

                        <LoadingButton
                            data-testid="save-btn"
                            btnStyle={style.saveBtn}
                            loadingStyle={style.loadingStyle}
                            title={"SAVE"}
                            isLoading={this.props.submitLoading}
                            onClick={this.handleSubmit}
                        />

                    </Box>
                </>
            </>
            // Customizable Area End

        )
    }
}

// Customizable Area Start      
const SmallSearchBox = styled(Box)({
    display: "flex",
    height: "30px",
    margin: "6px 0px 12px 0",
    background: "#FCFAF7",
    borderRadius: "28px",
    border: "1px solid #F0EBE0",
    '& .custom_search_input>.MuiInputAdornment-positionStart': {
        margin: "0 4px 0 16px !important"
    },
})
const CheckboxLabel = styled(FormControlLabel)({
    margin: "0px !important",
    "& .MuiButtonBase-root": {
        padding: "0px !important",
        marginRight: "12px"
    }
})

const style = {
    unitName: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2324",
        userSelect: "none"
    } as React.CSSProperties,
    addOptionBtn: {
        height: "20px",
        backgroundColor: "transparent",
        color: "#006A77",
        display: "flex",
        justifyContent: "start",
        padding: "0px"
    } as React.CSSProperties,
    addOptionTxt: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 500,
    } as React.CSSProperties,
    addIcon: {
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
    // BUTTON
    saveBtn: {
        width: "100%",
        height: "40px",
        backgroundColor: "#DF5200",
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center",
        fontWeight: 600,
        borderRadius: "28px",
        marginTop: "16px"
    } as React.CSSProperties,
    loadingStyle: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
}
// Customizable Area End
import React from "react";

import {
  // Customizable Area Start
  Grid,
  Modal,
  Card,
  Box,
  Typography,
  IconButton,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Divider,
  TextField,
  Button,
  styled,
  FormControlLabel,
  InputAdornment,
  FormHelperText,
  Checkbox,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MomentUtils from "@date-io/moment";
import CustomModal from "../../../components/src/customModal.web";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { renderErrorMessage } from "../../../components/src/AdminConsoleHelper";
import { keyDown } from "../../../components/src/helper";
import { ISelectFarm, IIntialValueIrrigation, UnitFarmAreaId as IIrrigationType } from "../../../components/src/interface.web";
import IrrigationActivityController, { Props } from "./IrrigationActivityController.web";
import { DateRange, Add, KeyboardArrowDown } from "@material-ui/icons";
import { closeIconSvg, deleteIcon, rupeeIconSVG, irrigationSvg, rainFedErrorSVG } from "./assets";

const inputprop = {
  startAdornment: (
    <InputAdornment className="startIconCustom" position="start">
      <img src={rupeeIconSVG} alt="rupees_image" />
    </InputAdornment>
  ),
  inputProps: {
    min: 0,
    max: 50000,
    step: 1, // Allow Integer values
  },
};
// Customizable Area End

export default class IrrigationActivity extends IrrigationActivityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSourceOfIrrigation = (form: IIntialValueIrrigation, formIndex: number) => {
    const { irrigationSourceList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={12}>
        <CustomFormLabel>Source of Irrigation</CustomFormLabel>
        <FormControl fullWidth>
          <Select
            style={webStyle.inputFieldStyle}
            className="selectDDCustom"
            data-testid="source_of_irrigation_testid"
            variant="outlined"
            color="secondary"
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            name="source_of_irrigation"
            value={form.source_of_irrigation}
            error={!!form.errors.source_of_irrigation}
            MenuProps={{ style: { maxWidth: "400px" } }}
            onChange={(event: React.ChangeEvent<{ value: unknown | string; }>
            ) => {
              this.handleInputChange({
                target: {
                  name: "source_of_irrigation",
                  value: event.target.value as string,
                },
              }, formIndex)
            }}
            renderValue={(value) => {
              if (form.source_of_irrigation) {
                return irrigationSourceList.find((item) => item.id.toString() == form.source_of_irrigation)?.label_english
              } else {
                return <span className="opcity-48">Source of Irrigation</span>
              }
            }}
          >
            <MenuItem value={""} disabled>
              <span className="opcity-48">Source of Irrigation</span>
            </MenuItem>
            {irrigationSourceList.map((item: IIrrigationType) => {
              return <MenuItem value={item.id} key={item.id}>{item.label_english}</MenuItem>
            })}
          </Select>
          {form.errors.source_of_irrigation && (
            <FormHelperText style={webStyle.errorStyle}>{form.errors.source_of_irrigation}</FormHelperText>
          )}
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderTypeOfIrrigation = (form: IIntialValueIrrigation, formIndex: number) => {
    const { irrigationTypeList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={12}>
        <CustomFormLabel>Type of Irrigation</CustomFormLabel>
        <FormControl fullWidth>
          <Select
            disabled={!!form?.id}
            style={webStyle.inputFieldStyle}
            className="selectDDCustom"
            data-testid="type_of_irrigation_testid"
            variant="outlined"
            color="secondary"
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            name="type_of_irrigation"
            value={form.type_of_irrigation}
            error={!!form.errors.type_of_irrigation}
            MenuProps={{ style: { maxWidth: "400px" } }}
            onChange={(event: React.ChangeEvent<{ value: unknown | string; }>
            ) => {
              this.handleInputChange({
                target: {
                  name: "type_of_irrigation",
                  value: event.target.value as string,
                },
              }, formIndex, false, false, true)
            }}
            renderValue={(value) => {
              if (form.type_of_irrigation) {
                return irrigationTypeList.find((item) => item.id.toString() == form.type_of_irrigation)?.label_english
              } else {
                return <span className="opcity-48">Select Type of Irrigation</span>
              }
            }}
          >
            <MenuItem value={""} disabled>
              <span className="opcity-48">Select Type of Irrigation</span>
            </MenuItem>
            {irrigationTypeList.map((item: IIrrigationType) => {
              return <MenuItem value={item.id} key={item.id}>{item.label_english}</MenuItem>
            })}
          </Select>
          {form.errors.type_of_irrigation && (
            <FormHelperText style={webStyle.errorStyle}>{form.errors.type_of_irrigation}</FormHelperText>
          )}
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderDateOfApplication = (
    form: IIntialValueIrrigation,
    formIndex: number,
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Date of Irrigation</CustomFormLabel>
        <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required
              clearable
              data-testid="date_of_picking_testid"
              className="customDatePicker custom-textbox"
              placeholder="DD/MM/YYYY"
              format="DD/MM/yyyy"
              inputVariant="outlined"
              color="secondary"
              keyboardIcon={<DateRange style={webStyle.customDateIcon} />}
              minDate={this.state.minimumDate}
              minDateMessage={'Please select a valid date'}
              maxDate={moment()}
              maxDateMessage={'Selected date cannot be a future date'}
              name="date"
              value={form.date}
              onChange={(newDate) =>
                this.handleInputChange(
                  {
                    target: {
                      name: "date",
                      value: newDate,
                    },
                  },
                  formIndex
                )
              }
              error={!!form.errors.date}
              helperText={form.errors.date}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderHoursOfIrrigation = (form: IIntialValueIrrigation, formIndex: number) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Hours of Irrigation</CustomFormLabel>
        <FormControl>
          <TextField
            className="input-left-padding custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Enter Hours"
            onKeyDown={keyDown}
            data-testid="hours_of_irrigation_testid"
            name="hours_of_irrigation"
            value={form.hours_of_irrigation}
            onChange={(event) =>
              this.handleInputChange(event, formIndex)
            }
            error={Boolean(form.errors.hours_of_irrigation)}
            helperText={form.errors.hours_of_irrigation}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderLaborCost = (
    form: IIntialValueIrrigation,
    formIndex: number,
    isMinMax?: boolean
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Labor Cost</CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="labor_cost_testid"
            InputProps={inputprop}
            onKeyDown={keyDown}
            name="labor_cost"
            value={form.labor_cost}
            onChange={(event) =>
              this.handleInputChange(event, formIndex, false, isMinMax)
            }
            error={!!form.errors.labor_cost}
            helperText={form.errors.labor_cost}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderHorizontalDistance = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfHorizontalDistanceList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Horizontal Distance</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="horizontal_distance_testid"
            fullWidth
            placeholder="from Pump to Farm"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="horizontal_distance"
            value={form.horizontal_distance}
            error={!!form.errors.horizontal_distance || !!form.errors.unit_horizontal_distance}
            helperText={form.errors.horizontal_distance != "" ? form.errors.horizontal_distance : form.errors.unit_horizontal_distance}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "horizontal_distance",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfHorizontalDistanceList.length === 1 || !unitOfHorizontalDistanceList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.horizontal_distance, unitOfHorizontalDistanceList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_horizontal_distance}
                  value={form.unit_horizontal_distance}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_horizontal_distance",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfHorizontalDistanceList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderMotorInHP = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfMotorList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Motor in HP</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="motor_in_hp_testid"
            fullWidth
            placeholder="Enter Motor"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="motor_in_hp"
            value={form.motor_in_hp}
            error={!!form.errors.motor_in_hp || !!form.errors.unit_motor_in_hp}
            helperText={form.errors.motor_in_hp != "" ? form.errors.motor_in_hp : form.errors.unit_motor_in_hp}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "motor_in_hp",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfMotorList.length === 1 || !unitOfMotorList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.motor_in_hp, unitOfMotorList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_motor_in_hp}
                  value={form.unit_motor_in_hp}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_motor_in_hp",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfMotorList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderDepthOfPump = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfDepthPumpList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Depth of Pump</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="depth_of_pump_testid"
            fullWidth
            placeholder="Enter Depth"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="depth_of_pump"
            value={form.depth_of_pump}
            error={!!form.errors.depth_of_pump || !!form.errors.unit_depth_of_pump}
            helperText={form.errors.depth_of_pump != "" ? form.errors.depth_of_pump : form.errors.unit_depth_of_pump}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "depth_of_pump",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfDepthPumpList.length === 1 || !unitOfDepthPumpList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.depth_of_pump, unitOfDepthPumpList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_depth_of_pump}
                  value={form.unit_depth_of_pump}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_depth_of_pump",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfDepthPumpList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderIrrigationCost = (
    form: IIntialValueIrrigation,
    formIndex: number,
    isMinMax?: boolean
  ) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Irrigation Cost</CustomFormLabel>
        <FormControl>
          <TextField
            className="inputIconTextFieldCustom custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Total Cost"
            data-testid="irrigation_cost_testid"
            InputProps={inputprop}
            onKeyDown={keyDown}
            name="irrigation_cost"
            value={form.irrigation_cost}
            onChange={(event) =>
              this.handleInputChange(event, formIndex, false, isMinMax)
            }
            error={!!form.errors.irrigation_cost}
            helperText={form.errors.irrigation_cost}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderNumberOfNozzels = (form: IIntialValueIrrigation, formIndex: number) => {
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Number of Nozzels</CustomFormLabel>
        <FormControl>
          <TextField
            className="input-left-padding custom-textbox"
            type="number"
            variant="outlined"
            color="secondary"
            placeholder="Enter Number"
            onKeyDown={keyDown}
            data-testid="number_of_nozzles_testid"
            name="number_of_nozzles"
            value={form.number_of_nozzles}
            onChange={(event) =>
              this.handleInputChange(event, formIndex)
            }
            error={Boolean(form.errors.number_of_nozzles)}
            helperText={form.errors.number_of_nozzles}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderSpacingOfDrippers = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfSpacingDrippersList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Spacing of Drippers</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="spacing_of_drippers_testid"
            fullWidth
            placeholder="Spacing"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="spacing_of_drippers"
            value={form.spacing_of_drippers}
            error={!!form.errors.spacing_of_drippers || !!form.errors.unit_spacing_of_drippers}
            helperText={form.errors.spacing_of_drippers != "" ? form.errors.spacing_of_drippers : form.errors.unit_spacing_of_drippers}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "spacing_of_drippers",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfSpacingDrippersList.length === 1 || !unitOfSpacingDrippersList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.spacing_of_drippers, unitOfSpacingDrippersList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_spacing_of_drippers}
                  value={form.unit_spacing_of_drippers}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_spacing_of_drippers",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfSpacingDrippersList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderDripArea = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfDripAreaList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Drip Irrigated Area</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="drip_irrigated_area_testid"
            fullWidth
            placeholder="Drip Area"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="drip_irrigated_area"
            value={form.drip_irrigated_area}
            error={!!form.errors.drip_irrigated_area || !!form.errors.unit_drip_irrigated_area}
            helperText={form.errors.drip_irrigated_area != "" ? form.errors.drip_irrigated_area : form.errors.unit_drip_irrigated_area}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "drip_irrigated_area",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfDripAreaList.length === 1 || !unitOfDripAreaList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.drip_irrigated_area, unitOfDripAreaList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_drip_irrigated_area}
                  value={form.unit_drip_irrigated_area}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_drip_irrigated_area",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfDripAreaList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderRowSpacing = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfRowSpacingList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Row to row spacing</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="row_spacing_testid"
            fullWidth
            placeholder="Spacing"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="row_spacing"
            value={form.row_spacing}
            error={!!form.errors.row_spacing || !!form.errors.unit_row_spacing}
            helperText={form.errors.row_spacing != "" ? form.errors.row_spacing : form.errors.unit_row_spacing}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "row_spacing",
                    value: event.target.value as string,
                  },
                }, formIndex)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfRowSpacingList.length === 1 || !unitOfRowSpacingList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.row_spacing, unitOfRowSpacingList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_row_spacing}
                  value={form.unit_row_spacing}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_row_spacing",
                          value: event.target.value as string,
                        },
                      }, formIndex)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfRowSpacingList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderDripperDischarge = (
    form: IIntialValueIrrigation,
    formIndex: number
  ) => {
    const { unitOfDripperDischargeList } = this.state;
    return (
      <CustomFieldGrid item xs={12} lg={6}>
        <CustomFormLabel>Dripper Discharge</CustomFormLabel>
        <FormControl fullWidth>
          <TextField
            className="textfield-with-select"
            data-testid="dripper_discharge_testid"
            fullWidth
            placeholder="Drip Area"
            type="number"
            variant="outlined"
            color="secondary"
            onKeyDown={keyDown}
            name="dripper_discharge"
            value={form.dripper_discharge}
            error={!!form.errors.dripper_discharge || !!form.errors.unit_dripper_discharge}
            helperText={form.errors.dripper_discharge != "" ? form.errors.dripper_discharge : form.errors.unit_dripper_discharge}
            onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
              this.handleInputChange(
                {
                  target: {
                    name: "dripper_discharge",
                    value: event.target.value as string,
                  },
                }, formIndex, true)
            }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: unitOfDripperDischargeList.length === 1 || !unitOfDripperDischargeList.length ? (
                <CustomUnitLabel>
                  <Typography noWrap>
                    {this.getLabelFromId(form.dripper_discharge, unitOfDripperDischargeList)}
                  </Typography>
                </CustomUnitLabel>
              ) : (
                <SelectWithInput
                  className="select-with-input"
                  error={!!form.errors.unit_dripper_discharge}
                  value={form.unit_dripper_discharge}
                  IconComponent={KeyboardArrowDown}
                  data-testid="nutrient_quantity_unit_testid"
                  onChange={(event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
                    this.handleInputChange(
                      {
                        target: {
                          name: "unit_dripper_discharge",
                          value: event.target.value as string,
                        },
                      }, formIndex, true)
                  }}
                >
                  <MenuItem value={""} disabled>
                    <span className="opcity-48"><em>None</em></span>
                  </MenuItem>
                  {unitOfDripperDischargeList.map(
                    (unit) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.attributes.unit_name}
                        </MenuItem>
                      );
                    }
                  )}
                </SelectWithInput>
              ),
            }}
          />
        </FormControl>
      </CustomFieldGrid>
    );
  };

  renderFieldsBasedOnPestManagement = (irrigationType: string, form: IIntialValueIrrigation, formIndex: number) => {
    const { irrigationTypeList } = this.state;
    const objectWithFloodName: IIrrigationType | undefined = irrigationTypeList.find((objItem: IIrrigationType) => objItem.label.toLowerCase().includes("flood"));
    const objectWithSprinklerName: IIrrigationType | undefined = irrigationTypeList.find((objItem: IIrrigationType) => objItem.label.toLowerCase().includes("sprinkler"));
    const objectWithDripName: IIrrigationType | undefined = irrigationTypeList.find((objItem: IIrrigationType) => objItem.label.toLowerCase().includes("drip"));
    switch (irrigationType.toString()) {
      case (objectWithFloodName?.id.toString()):
        return (
          <>
            {this.renderDateOfApplication(form, formIndex)}
            {this.renderHoursOfIrrigation(form, formIndex)}
            {this.renderLaborCost(form, formIndex)}
            {this.renderHorizontalDistance(form, formIndex)}
            {this.renderMotorInHP(form, formIndex)}
            {this.renderDepthOfPump(form, formIndex)}
          </>
        )
      case (objectWithSprinklerName?.id.toString()):
        return (
          <>
            {this.renderDateOfApplication(form, formIndex)}
            {this.renderHoursOfIrrigation(form, formIndex)}
            {this.renderIrrigationCost(form, formIndex)}
            {this.renderLaborCost(form, formIndex)}
            {this.renderNumberOfNozzels(form, formIndex)}
            {this.renderHorizontalDistance(form, formIndex)}
            {this.renderMotorInHP(form, formIndex)}
            {this.renderDepthOfPump(form, formIndex)}
          </>
        )
      case (objectWithDripName?.id.toString()):
        return (
          <>
            {this.renderDateOfApplication(form, formIndex)}
            {this.renderHoursOfIrrigation(form, formIndex)}
            {this.renderIrrigationCost(form, formIndex)}
            {this.renderLaborCost(form, formIndex)}
            {this.renderSpacingOfDrippers(form, formIndex)}
            {this.renderDripArea(form, formIndex)}
            {this.renderRowSpacing(form, formIndex)}
            {this.renderDripperDischarge(form, formIndex)}
            {this.renderHorizontalDistance(form, formIndex)}
            {this.renderMotorInHP(form, formIndex)}
            {this.renderDepthOfPump(form, formIndex)}
          </>
        )
      default:
        return (
          <>
            {this.renderDateOfApplication(form, formIndex)}
            {this.renderHoursOfIrrigation(form, formIndex)}
            {this.renderLaborCost(form, formIndex)}
            {this.renderHorizontalDistance(form, formIndex)}
            {this.renderMotorInHP(form, formIndex)}
            {this.renderDepthOfPump(form, formIndex)}
          </>
        )
    }
  }

  renderFarmError = () => {
    return <ErrorBox>
      <img src={rainFedErrorSVG} alt="error" className="svgImg" />
      <div className="errorTitle">No irrigation data needed</div>
      <div className="errorMessage">You have selected Rainfed in farm type in farm details. If you want to change please change it from farm details. </div>
      <Button onClick={(event) => this.handleOpenRainFedModel(event)} className="buttonClass">CHANGE</Button>
    </ErrorBox>
  }

  renderPestManagmentForm = () => {
    return <Grid item>
      <Modal
        open={this.props.isIrrigationModal}
        data-testid="close_modal_button"
        style={webStyle.secondaryModalBox}
        BackdropProps={{
          style: webStyle.modalBackdropProps,
        }}
        className="addCropModal"
      >
        <Card variant="outlined" style={{ ...webStyle.AddCardBoxSuccess, maxWidth: this.state.rainFedFarm ? "450px" : "712px" }} >
          <Box style={webStyle.boxContainer}>
            <CustomAddCropBox>
              <Box style={webStyle.cropHeader}>
                <Box style={webStyle.headerLeft}>
                  <img
                    src={irrigationSvg}
                    alt="Crop image"
                    style={webStyle.headerImage}
                  />
                  <Typography style={webStyle.headerTitle}>
                    Irrigation
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={this.props.handleCloseAddActivityModal}
                  >
                    <img
                      src={closeIconSvg}
                      style={{ width: "24px", height: "24px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </CustomAddCropBox>
            {this.state.rainFedFarm
              ? this.renderFarmError()
              :
              <form
                noValidate
                autoComplete="off"
                data-testid="pestmanagment-form-submit-testid"
                onSubmit={(event) => event.preventDefault()}
              >
                <CustomBoxHeader>
                  <Grid container spacing={2}>
                    <CustomFieldGrid item xs={12} lg={6}>
                      <CustomFormLabel>Select Farm</CustomFormLabel>
                      <CustomSelectDD
                        IconComponent={CustomArrowIcon}
                        style={webStyle.inputFieldStyle}
                        data-testid="select_farm_testid"
                        variant="outlined"
                        color="secondary"
                        disabled={this.props.isEditActivity}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        name="select_farm_id"
                        renderValue={(value) => {
                          if (this.state.selectedFarmId) {
                            return this.state.farmNamesList.find((item) => item.attributes.id == this.state.selectedFarmId)?.attributes?.name
                          } else if (!this.state.selectedFarmId) {
                            return (
                              <span className="opcity-48">Select Farm</span>
                            );
                          }
                        }}
                        error={Boolean(this.state.selectedFarmIdError)}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleFarmChange(event.target.value as string)}
                      >
                        <MenuItem value={""} disabled>
                          <span className="opcity-48">Select Farm</span>
                        </MenuItem>
                        {this.state.farmNamesList.map((item: ISelectFarm) => {
                          return (
                            <MenuItem
                              style={{ paddingBlock: "0px" }}
                              value={item.id}
                              key={item.id}
                            >
                              <RadioGroup
                                value={this.state.selectedFarmId}
                                style={{
                                  width: "100%",
                                  borderBottom: "1px solid #F0EBE0",
                                }}
                              >
                                <CustomFormControlLabel
                                  value={item.id}
                                  control={<Radio />}
                                  style={{ width: "100%" }}
                                  label={
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={webStyle.cropDropDownLabel}
                                        >
                                          {item.attributes.name}
                                        </Typography>
                                      </Box>
                                      <Typography style={webStyle.cropUnitText}>
                                        {item.attributes.farm_area}{" "}
                                        {item.attributes?.unit_farm_area_id?.name}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </RadioGroup>
                            </MenuItem>
                          );
                        })}
                        <CustomButton
                          onClick={this.handleAddFarm}
                          startIcon={
                            <Add style={{ width: "18px", height: "18px" }} />
                          }
                        >
                          Add Farm
                        </CustomButton>
                      </CustomSelectDD>
                      {renderErrorMessage(this.state.selectedFarmIdError)}
                    </CustomFieldGrid>
                    <CustomFieldGrid item xs={12} lg={6}>
                      <CustomFormLabel>Select Crop</CustomFormLabel>
                      <CustomSelectDD
                        style={webStyle.inputFieldStyle}
                        IconComponent={CustomArrowIcon}
                        data-testid="select_crop_testid"
                        variant="outlined"
                        color="secondary"
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        name="select_crop"
                        multiple
                        disabled={this.props.isEditActivity || !this.state.selectedFarmId}
                        error={Boolean(this.state.selectedCropIdError)}
                        value={this.state.selectedCropIdArr}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          this.handleChangeSelectCrop(event.target.value as number[])
                        }}
                        renderValue={(selected: any) => {
                          if (!this.state.selectedCropIdArr.length) {
                            return <span className="opcity-48">Select Crop</span>;
                          } else {
                            let selectedCropsLabel: string[] = [];
                            selected.forEach((value: string | number) => {
                              const result = this.state.cropNamesList.find(
                                (option) =>
                                  String(option.attributes.id) == String(value)
                              );
                              if (result) {
                                selectedCropsLabel.push(
                                  result.attributes.crop_name_id.name
                                );
                              }
                            });
                            const labels = selectedCropsLabel.join(", ");
                            return <Typography noWrap>{labels}</Typography>;
                          }
                        }}
                      >
                        <MenuItem value={""} disabled>
                          <span className="opcity-48">Select Crop</span>
                        </MenuItem>
                        {this.state.cropNamesList.map((option) => (
                          <MenuItem
                            key={option.attributes.id}
                            value={option.attributes.id}
                            style={{ paddingBottom: 0, flexDirection: "column" }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                marginLeft: "-22px",
                              }}
                            >
                              <Checkbox
                                checked={
                                  this.state.selectedCropIdArr.indexOf(
                                    option.attributes.id
                                  ) != -1
                                }
                              />
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography style={webStyle.cropDropDownLable}>
                                    {option.attributes.crop_name_id.name}
                                    {" | "}
                                    <span style={{ color: "#108B88" }}>
                                      {option.attributes?.crop_type_id?.name}
                                    </span>
                                  </Typography>
                                </Box>
                                <Typography style={webStyle.cropUnitText}>
                                  {option.attributes.total_crop_area}{" "}
                                  {option.attributes?.unit_of_area_id?.name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box style={{ padding: "0", width: "100%" }}>
                              <Divider style={{ background: "#F0EBE0" }} />
                            </Box>
                          </MenuItem>
                        ))}
                        <CustomButton
                          onClick={this.handleAddCrop}
                          startIcon={
                            <Add style={{ width: "18px", height: "18px" }} />
                          }
                        >
                          Add Crop
                        </CustomButton>
                      </CustomSelectDD>
                      {renderErrorMessage(this.state.selectedCropIdError)}
                    </CustomFieldGrid>
                  </Grid>
                </CustomBoxHeader>
                <CustomHR />

                {/* MAP FORMS */}
                {this.state.initialIrrigationValues.map(
                  (form: IIntialValueIrrigation, formIndex: number) => {
                    return (
                      <>
                        <CustomBox key={formIndex}>
                          <Grid
                            container
                            spacing={2}
                            style={{ position: "relative" }}
                          >
                            {this.renderSourceOfIrrigation(form, formIndex)}
                            {this.renderTypeOfIrrigation(form, formIndex)}
                            {this.renderFieldsBasedOnPestManagement(form.type_of_irrigation, form, formIndex)}
                            {this.state.initialIrrigationValues.length > 1 && (
                              <Box
                                style={{
                                  visibility: !!form?.id ? "hidden" : "visible",
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <IconButton
                                  style={webStyle.iconbutton}
                                  disabled={!!form?.id}
                                  onClick={() =>
                                    this.handleRemoveActivity(formIndex)
                                  }
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="Delete activity"
                                    style={{ width: "24px", height: "24px" }}
                                  />
                                </IconButton>
                              </Box>
                            )}
                          </Grid>
                        </CustomBox>
                        <CustomHR />
                      </>
                    );
                  }
                )}
                {/* BUTTONS */}
                <CustomButtonWrapper container>
                  <Button
                    type="submit"
                    style={{ ...webStyle.commonButton, background: "#F0EBE0" }}
                    data-testid={`add_activity_button`}
                    onClick={this.handleAddAnotherEntry}
                  >
                    <Add style={{ marginRight: "8px", opacity: "64%" }} />
                    <Typography
                      style={{
                        ...webStyle.commonButtonText,
                        color: "#1C2324",
                        opacity: "64%",
                      }}
                    >
                      Add another Entry
                    </Typography>
                  </Button>
                  <Button
                    type="submit"
                    style={{ ...webStyle.commonButton, background: "#DF5200" }}
                    data-testid="submit_button_testid"
                    onClick={this.onSubmitIrrigationForm}
                    disabled={this.state.loader}
                  >
                    <Typography style={webStyle.commonButtonText}>
                      {this.state.loader ? (
                        <CircularProgress
                          size={28}
                          style={{ color: "#fff" }}
                        />
                      ) : (
                        this.props.isEditActivity ? "Update" : "Save"
                      )}
                    </Typography>
                  </Button>
                </CustomButtonWrapper>
              </form>
            }
          </Box>
        </Card>
      </Modal>
    </Grid>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.props.isIrrigationModal && this.renderPestManagmentForm()}
        {this.state.isErrorModalOpen && (
          <CustomModal
            open={!!this.state.isErrorModalOpen}
            onClose={this.handleCloseErrorModal}
            title={this.state.ErrorModalTitle}
            description={this.state.ErrorModalMessage}
          />
        )}  
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomUnitLabel = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "83px",
  height: "28px",
  padding: "0px 12px",
  boxSizing: "border-box",
  lineHeight: "2px",
  textAlign: "center",
  fontSize: "14px",
  borderRadius: "8px",
  background: "#F0EBE0",
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
});

const CustomButton = styled(Button)({
  color: "#006A77",
  margin: "6px 0 0 12px",
  fontSize: "12px",
  fontWeight: 500,
  "& .MuiButton-startIcon": {
    marginRight: "2px ",
  },
});

const CustomBoxHeader = styled(Box)({
  padding: "0px 32px 24px 32px",
  "@media (max-width:600px)": {
    padding: "16px 24px",
  },
});
const CustomHR = styled(Divider)({
  background: "#F0EBE0",
  width: "100%",
  height: "2px",
});

const CustomFieldGrid = styled(Grid)({
  display: "flex !important",
  flexDirection: "column",
  minHeight: "93px",
});

const CustomSelectDD = styled(Select)({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent !important",
  },
  "& .MuiSelect-iconOutlined": {
    right: "10px !important",
  },
});

const CustomButtonWrapper = styled(Grid)({
  padding: "24px 32px 32px",
  flexDirection: "row",
  rowGap: "32px",
  "@media (max-width:600px)": {
    padding: "24px 24px 32px",
  },
})

const CustomAddCropBox = styled(Box)({});
const SelectWithInput = styled(Select)({
  "&.select-with-input": {
    height: "28px !important",
  },
  "& .MuiInputBase-formControl": {
    height: "auto",
  },
  '&.select-with-input .MuiSelect-iconOutlined': {
    right: "0 !important"
  },
  "&.select-with-input .MuiSelect-root": {
    width: "83px",
    height: "28px",
    padding: "12px !important",
    boxSizing: "border-box",
    lineHeight: "2px",
    textAlign: "left",
    fontSize: "14px",
    borderRadius: "8px",
    background: "#F0EBE0",
  },
});
const webStyle = {
  iconbutton: {
    padding: 0,
  } as React.CSSProperties,
  tableFooter: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 12px",
  } as React.CSSProperties,
  secondaryModalBox: {
    display: "flex",
    gap: 1,
    fontSize: "16px",
    justifyContent: "center",
    borderBlockStart: "1px solid #ccc",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    height: "100%",
  } as React.CSSProperties,
  modalBackdropProps: {
    backgroundColor: "rgba(0, 0, 0, .5)",
  } as React.CSSProperties,
  modalBoxStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 528,
    width: "95%",
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "12px",
    background: "#fff",
    maxHeight: "95%",
    overflow: "auto",
  } as React.CSSProperties,
  activityLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
    marginTop: "4px",
    color: "#1C2324",
  } as React.CSSProperties,
  AddCardBoxSuccess: {
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    maxWidth: "712px",
    flexDirection: "column",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: "95%",
    overflow: "hidden",
  } as React.CSSProperties,
  boxContainer: {
    fontSize: "16px",
    height: "100%",
    overflowY: "auto",
    overflowX: 'hidden'
  } as React.CSSProperties,
  cropHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '32px 32px 10px 32px'
  } as React.CSSProperties,
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties,
  headerTitle: {
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    fontSize: "20px",
    fontWeight: 500,
  } as React.CSSProperties,
  headerImage: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
  } as React.CSSProperties,
  inputFieldStyle: {
    height: "56px",
  } as React.CSSProperties,
  closeIcon: {
    width: "24px",
    height: "24px",
  } as React.CSSProperties,
  cropDropDownLable: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  cropUnitText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    fontStyle: "italic",
  } as React.CSSProperties,
  cropDropDownLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
  } as React.CSSProperties,
  commonButton: {
    borderRadius: "28px",
    width: "100%",
    paddingBlock: "14px",
  } as React.CSSProperties,
  commonButtonText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
  } as React.CSSProperties,
  errorStyle: {
    fontSize: "0.75rem",
    color: "#f44336",
  } as React.CSSProperties,
  customDateIcon: {
    color: "#1C2324",
    opacity: "0.48",
  } as React.CSSProperties,
};
const CustomFormLabel = styled(FormLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#1C2324",
  opacity: 0.64,
  marginBottom: "8px",
});

const CustomBox = styled(Box)({
  padding: "24px 32px",
  "@media (max-width:600px)": {
    padding: "16px 24px",
  },
});

const ErrorBox = styled(Box)({
  padding: "24px 32px 40px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .svgImg": {
    width: "200px",
    height: "200px"
  },
  "& .errorTitle": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "##1C2324",
    fontFamily: "IBM Plex Sans",
    marginBottom: "10px",
  },
  "& .errorMessage": {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    fontFamily: "IBM Plex Sans",
    marginBottom: "30px",
    opacity: "0.72",
    textAlign: "center"
  },
  "& .buttonClass": {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "40px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: 8,
    background: "#DF5200",
    borderRadius: "28px",
    width: "100%"
  },

});

const CustomArrowIcon = styled(KeyboardArrowDown)({
  fontSize: "26px",
  color: "#1C2324",
  opacity: 0.40
});
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfunitconversions";
exports.labelBodyText = "cfunitconversions Body";

exports.btnExampleTitle = "CLICK ME";

// GENERAL
exports.GET_METHOD_TYPE = "GET";
exports.POST_METHOD_TYPE = "POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.PATCH_METHOD_TYPE = "PATCH";
exports.DELETE_METHOD_TYPE = "DELETE";

// INDEX
exports.fetchDropdownIndexEndPoint = 'admin/bx_block_dropdown/dropdown_count'

// NEW DROPDOWN
exports.subDropdownHeadingsEndPoint = 'admin/bx_block_dropdown/dropdown_activity'
exports.DropdownDetailsEndPoint = 'admin/bx_block_dropdown/dropdown_index'
exports.DropdownDetailsEndPointDistrict = 'admin/'
exports.updateDropdownEndpoint = 'admin/bx_block_dropdown/dropdown_edit_options'

// OTHER DROPDOWNS
exports.CropSeasonsEndpoint = 'admin/bx_block_farm_dairy/crop_seasons'
exports.DefaultDropdownsEndpoint = "admin/bx_block_dropdown/default_unit_dropdowns"

exports.TAB = {
  ENGLISH: "english",
  GUJARATI: "gujarati",
  HINDI: "hindi"
}

exports.REGISTRATION = {
  TITLE: "Registration",
  PERSONAL_DETAILS: "Personal Details",
  LOCATIONS: "Locations",
}

exports.FARM_DIARY = {
  TITLE: "Farm Diary",
  FARMS: "Farms",
  CROPS: "Crops",
  PRE_SOWING: "Pre-Sowing",
  CROP_FAILURE: "Crop failure",
  WEED_MANAGEMENT: "Weed Management",
  NUTRIENT_MANAGEMENT: "Nutrient Management",
  PEST_MANAGEMENT: "Pest Management",
  IRRIGATION: "Irrigation",
  HARVEST: "Harvest",
  SELL: "Sell",
}

exports.MATERRAPEDIA = {
  TITLE: "Materrapedia",
  LIBRARY_CHAPTER: "Library chapter",
  FAQ_CATEGORY: "FAQ category"
}

exports.NOTIFICATION = {
  TITLE: "Notification",
  NOTIFICATION_TYPE: "Notification Type"
}

exports.UNITS = {
  TITLE: "Units",
  UNITS: "Units"
}

exports.PERSONAL_DETAILS = {
  NUMBER_BELONGS_TO: "Number belongs to",
  EDUCATION: "Education",
  MOBILE_TYPE: "Mobile Type"
}

exports.LOCATIONS = {
  STATE: "State",
  DISTRICT: "District",
  TALUKA: "Taluka",
  VILLAGE: "Village"
}

exports.FARMS = {
  UNIT_OF_AREA: "Unit of area",
  FARM_IRRIGATION_TYPE: "Farm Irrigation Type",
  OWNERSHIP_OF_FARM: "Ownership of Farm",
  SOIL_TEXTURE: "Soil Texture"
}

exports.PRE_SOWING = {
  PRE_SOWING_ACTIVITY: "Pre-sowing activity",
  UNIT_OF_FUEL_CONSUMPTION: "Unit of fuel consumption",
  SEASON: "Season",
}

exports.CROPS = {
  CROP_NAME: "Crop Name",
  UNIT_OF_AREA: "Unit of area",
  VARIETY: "Variety",
  UNIT_OF_SEED_QUANTITY: "Unit of seed quantity",
  UNIT_OF_BIO_AGENT_QUANTITY: "Unit of bio-agent quantity",
  CROP_TYPE: "Crop type",
  SEED_SOURCE: "Seed source",
  BIO_AGENT: "Bio agent",
}

exports.CROP_FAILURE = {
  REASON_OF_UPROOTING: "Reason of uprooting",
  NEXT_ACTION_OF_FARM: "Next Action of farm",
  UNIT_OF_AREA: "Unit of area",
}

exports.WEED_MANAGEMENT = {
  WEEDING_TYPE: "Weeding type",
  WEEDICIDE_NAME: "Weedicide name",
  UNIT_OF_FUEL_CONSUMPTION: "Unit of fuel consumption",
  UNIT_OF_WEEDICIDE_QUANTITY: "Unit of weedicide quantity",
}

exports.NUTRIENT_MANAGEMENT = {
  NUTRIENT_NAME: "Nutrient name",
  UNIT_OF_NUTRIENT_QUANTITY: "Unit of nutrient quantity",
  NUTRIENT_MANAGEMENT_TYPE: "Nutrient management type"
}

exports.PEST_MANAGEMENT = {
  PEST_MANAGEMENT: "Pest management",
  PESTICIDE_NAME: "Pesticide name",
  UNIT_OF_PESTICIDE_QUANTITY: "Unit of pesticide quantity",
  TYPE_OF_TRAP: "Type of trap",
  BIO_AGENT_NAME: "Bio-agent name",
  UNIT_OF_BIO_AGENT_QUANTITY: "Unit of bio-agent quantity",
}

exports.IRRIGATION = {
  SOURCE_OF_IRRIGATION: "Source of Irrigation",
  UNIT_OF_HORIZANTAL_DISTANCE: "Unit of horizontal distance",
  UNIT_OF_DEPTH_OF_PUMP: "Unit of depth of pump",
  UNIT_OF_MOTOR: "Unit of motor",
  TYPE_OF_IRRIGATION: "Type of Irrigation",
  UNIT_OF_IRRIGATED_AREA: "Unit of irrigated area",
  UNIT_OF_SPACING_OF_DRIPPERS: "Unit of spacing of drippers",
  UNIT_OF_DRIPPER_DISCHARGE: "Unit of dripper discharge",
  UNIT_OF_ROW_TO_ROW_SPACING: "Unit of row to row spacing",
}

exports.HARVEST = {
  UNIT_OF_QUANTITY_PICKED: "Unit of quantity picked",
  STORAGE_TYPE: "Storage type",
}

exports.SELL = {
  UNIT_OF_QUANTITY_OF_COTTON_SOLD: "Unit of quantity of cotton sold",
  UNIT_OF_DISTANCE: "Unit of distance",
  VEHICAL_TYPE: "Vehical type"
}

// Customizable Area End
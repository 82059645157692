import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Input,
    InputAdornment,
    Paper,
    Tabs,
    Tab,
    Button,
    FormLabel,
    FormControl,
    TextField,
    IconButton,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    Grid,
    Divider,
    MenuItem
} from "@material-ui/core";
import UnitsController, { IValue, Props, configJSON } from "./UnitsController.web";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { keyDown } from "../../../../components/src/helper";
import { AddSVG, RemoveSVG } from "../assets";
import { LoaderCenter, LoadingButton, renderErrorMessage } from "../../../../components/src/AdminConsoleHelper";
import ErrorModal from "../../../../components/src/ErrorModal.web";

// Customizable Area End

export default class Units extends UnitsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeader = () => {
        return (
            <HeaderBox>
                <Typography style={style.headerTitle}>{this.renderTitle()}</Typography>
        
            </HeaderBox>
        )
    }

    renderForms = () => {
        const { formArray, selectedTab, defaultUnitsData } = this.state
        return (
            <>
                {formArray.map((form, index) => {
                    const formValue = form[selectedTab] as IValue
                    return (
                        <>
                            {!form?._destroy ?
                                <Box key={index}>
                                    <Grid container spacing={1} style={{ display: "flex" }}>
                                        <Grid lg={4} md={12} sm={12} item style={{ width: "100%" }}>
                                            <CustomFormLabel>Unit Name</CustomFormLabel>
                                            <FormControl fullWidth>
                                                <CustomTextField
                                                    type="text"
                                                    variant="outlined"
                                                    color="secondary"
                                                    placeholder="Enter Unit"
                                                    data-testid="unit_name_testid_form"
                                                    name="unit_name"
                                                    value={formValue?.unit_name?.value}
                                                    onChange={(event) => this.handleChangeInput(selectedTab, index, "unit_name", event.target.value)}
                                                />
                                            </FormControl>
                                            {renderErrorMessage(formValue?.unit_name?.error)}
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} item style={{ width: "100%" }}>
                                            <CustomFormLabel>Formula</CustomFormLabel>
                                            <CustomFieldBox>
                                                <FormControl fullWidth>
                                                    <CustomTextField
                                                        type="number"
                                                        variant="outlined"
                                                        color="secondary"
                                                        placeholder="Number"
                                                        data-testid="formula_testid_form"
                                                        onKeyDown={keyDown}
                                                        name="formula"
                                                        value={formValue?.formula?.value}
                                                        onChange={(event) => this.handleChangeInput(selectedTab, index, "formula", event.target.value)}
                                                    />
                                                </FormControl>
                                                <Typography style={style.formulaX}>X</Typography>
                                            </CustomFieldBox>
                                            {renderErrorMessage(formValue?.formula?.error)}
                                        </Grid>
                                        <Grid lg={4} md={6} sm={6} item style={{ width: "100%" }}>
                                            <CustomFormLabel>Default Unit</CustomFormLabel>
                                            <Box style={{ display: "flex",alignItems:"center" }}>
                                                <Box style={{ width: "100%", position: "relative", height: "42px" }}>
                                                    <AccordionActivityBox
                                                        expanded={this.state.selectedAccordianIndex === index}
                                                        onChange={this.handleSelectAccordian(index)}
                                                        style={{ position: "absolute", zIndex: this.state.selectedAccordianIndex === index ? 2000 : 0 }}
                                                        data-testid={`accordian-list-${index}_form`}
                                                    >
                                                        <AccordionSummary
                                                            id="panel1a-header"
                                                            aria-controls="panel1a-content"
                                                            expandIcon={<ExpandMoreIcon />}
                                                            style={{ height: "40px", minHeight: "40px", }}
                                                        >
                                                            {formValue?.area_name?.value ?
                                                                <Typography style={{ ...style.accordianTitle, opacity: 1 }}>{defaultUnitsData.find((item) => item.id == formValue?.area_name?.value)?.unit}</Typography>
                                                                :
                                                                <Typography style={{ ...style.accordianTitle, opacity: 0.48 }}>Select Unit</Typography>}
                                                        </AccordionSummary>
                                                        <AccordionDetails
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "0"
                                                            }}
                                                        >
                                                            <Box style={{ width: "100%", padding: "2px 0 12px 0px", maxHeight: "150px", overflowY: "auto" }}>
                                                                <Divider style={{ color: "#D9D9D9" }} />
                                                                {defaultUnitsData.length ? (<>
                                                                    {defaultUnitsData.map((item, menuIndex) => {
                                                                        return (
                                                                            <CustomMenuItem value={item.id} key={item.id}
                                                                                data-testid={`form-menuitem-${menuIndex}`}
                                                                                style={{ opacity: item.id == formValue?.area_name?.value ? 1 : 0.56 }}
                                                                                onClick={() => {
                                                                                    this.handleChangeInput(selectedTab, index, "area_name", String(item.id))
                                                                                    this.closeAccordians()
                                                                                }}
                                                                            >{item.unit}</CustomMenuItem>
                                                                        )
                                                                    })}
                                                                </>) : <></>}
                                                            </Box>

                                                        </AccordionDetails>
                                                    </AccordionActivityBox>
                                                </Box>
                                                <IconButton
                                                    data-testid={`remove-btn-${index}`}
                                                    style={style.iconBtn}
                                                    onClick={() => this.handleRemoveInput(form, index)}>
                                                    <img src={RemoveSVG} />
                                                </IconButton>
                                            </Box>
                                            {renderErrorMessage(formValue?.area_name?.error)}
                                        </Grid>
                                    </Grid>
                                </Box>
                                : <></>}
                        </>
                    )
                })}
            </>
        )
    }

    renderDefaultForm = () => {
        const { defaultForm, selectedTab } = this.state
        const defaultFormValue = defaultForm[selectedTab] as IValue

        return (
            <>
                <Box style={{padding:"0 24px"}}>
                    <Grid container spacing={1} style={{ display: "flex" }}>
                        <Grid lg={4} md={12} sm={12} item style={{ width: "100%" }}>
                            <CustomFormLabel>Unit Name</CustomFormLabel>
                            <FormControl fullWidth>
                                <CustomTextField
                                    type="text"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder="Enter Unit"
                                    data-testid="unit_name_testid"
                                    name="unit_name"
                                    value={defaultFormValue?.unit_name?.value}
                                    onChange={(event) => this.handleChangeInputDefault("unit_name", event.target.value, selectedTab)}
                                />
                            </FormControl>
                            {renderErrorMessage(defaultFormValue?.unit_name?.error)}
                        </Grid>
                        <Grid lg={4} md={6} sm={6} item style={{ width: "100%" }}>
                            <CustomFormLabel>Formula</CustomFormLabel>
                            <CustomFieldBox>
                                <FormControl fullWidth>
                                    <CustomTextField
                                        type="number"
                                        variant="outlined"
                                        color="secondary"
                                        placeholder="Number"
                                        data-testid="formula_testid"
                                        onKeyDown={keyDown}
                                        name="formula"
                                        value={defaultFormValue?.formula?.value}
                                        onChange={(event) => this.handleChangeInputDefault("formula", event.target.value, selectedTab)}
                                    />
                                </FormControl>
                                <Typography style={style.formulaX}>X</Typography>
                            </CustomFieldBox>
                            {renderErrorMessage(defaultFormValue?.formula?.error)}
                        </Grid>

                        <Grid lg={4} md={6} sm={6} item style={{ width: "100%" }}>
                            <CustomFormLabel>Default Unit</CustomFormLabel>
                            <Box style={{ display: "flex",alignItems:"center" }}>
                                <Box style={{ width: "100%", position: "relative", height: "42px" }}>
                                    <AccordionActivityBox
                                        data-testid="default-accordian-test-id"
                                        expanded={this.state.defaultAccordian === "OPEN"}
                                        onChange={this.handleDefaultAccordian("OPEN")}
                                        style={{ position: "absolute", zIndex: this.state.defaultAccordian === "OPEN" ? 2000 : 0 }}
                                    >
                                        <AccordionSummary
                                            id="panel1a-header"
                                            aria-controls="panel1a-content"
                                            expandIcon={<ExpandMoreIcon />}
                                            style={{ height: "40px", minHeight: "40px", }}
                                        >
                                            {defaultFormValue?.area_name?.value ?
                                                <Typography style={{ ...style.accordianTitle, opacity: 1 }}>{this.state.defaultUnitsData.find((item) => item.id == defaultFormValue?.area_name?.value)?.unit}</Typography>
                                                :
                                                <Typography style={{ ...style.accordianTitle, opacity: 0.48 }}>Select Unit</Typography>}
                                        </AccordionSummary>
                                        <AccordionDetails
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "0"
                                            }}
                                        >
                                            <Box style={{ width: "100%", padding: "2px 0 12px 0px", maxHeight: "150px", overflowY: "auto" }}>
                                                <Divider style={{ color: "#D9D9D9" }} />
                                                {this.state.defaultUnitsData.length ? (<>
                                                    {this.state.defaultUnitsData.map((item, menuIndex) => {
                                                        return (
                                                            <CustomMenuItem value={item.id} key={item.id}
                                                                data-testid={`default-menuitem-${menuIndex}`}
                                                                style={{ opacity: item.id == defaultFormValue?.area_name?.value ? 1 : 0.56 }}
                                                                onClick={() => {
                                                                    this.handleChangeInputDefault("area_name", item.id, selectedTab)
                                                                    this.closeAccordians()
                                                                }}
                                                            >{item.unit}</CustomMenuItem>
                                                        )
                                                    })}
                                                </>) : <></>}
                                            </Box>

                                        </AccordionDetails>
                                    </AccordionActivityBox>

                                </Box>
                                <IconButton
                                    data-testid="add-input-testid"
                                    style={style.iconBtn}
                                    onClick={this.handleAddInput}>
                                    <img src={AddSVG} />
                                </IconButton>
                            </Box>
                            {renderErrorMessage(defaultFormValue?.area_name?.error)}
                        </Grid>
                    </Grid>

                

                {/* SAVE BUTTON */}
                <LoadingButton
                    data-testid="save-btn"
                    btnStyle={style.saveBtn}
                    loadingStyle={style.loadingStyle}
                    title={"SAVE"}
                    isLoading={this.state.updateDropdownLoading}
                    onClick={this.handleSubmit}
                />
                </Box>
            </>)
    }

    renderUnits = () => {
        return (
            <>
                <Box style={style.dropdownWrapper}>
                    <PaperWrapper square>
                        <Tabs
                            className="tabs-wrapper"
                            variant="fullWidth"
                            value={this.state.selectedTab}
                            onChange={this.handleChangeTab}
                            data-testid="tab-wrapper"
                        >
                            <CustomTab label="English" value={configJSON.TAB.ENGLISH} data-testid="english-tab" />
                            <CustomTab label="Gujarati" value={configJSON.TAB.GUJARATI} data-testid="gujarati-tab" />
                            <CustomTab label="Hindi" value={configJSON.TAB.HINDI} data-testid="hindi-tab" />
                        </Tabs>
                    </PaperWrapper>

                    <Box style={style.optionWrapper}>
                        <Box style={{padding:"0 24px",height:"324px",overflowY:"auto"}}>
                        {this.state.formArray.length ? this.renderForms() : <></>}
                        </Box>
                        {this.renderDefaultForm()}
                    </Box>
                </Box>
            </>
        )
    }

    renderMsgModal = () => {
        return (
            <>
                <ErrorModal
                    open={this.state.OpenCustomModal}
                    errorTitle={this.state.CustomModalTitle}
                    errorSubTitle={this.state.CustomModalMsg}
                    BtnTitle="Okay"
                    handleBtnClick={this.handleCloseCustomModal}
                />
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box style={style.mainWrapper}>
                    {this.renderHeader()}
                    {this.state.dropdownDetailsLoading ? <LoaderCenter /> : this.renderUnits()}
                    {this.state.OpenCustomModal ? this.renderMsgModal() : <></>}
                </Box>
            </>
            // Customizable Area End

        )
    }
}

// Customizable Area Start
const HeaderBox = styled(Box)({
    marginBottom: "24px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: "nowrap",
    rowGap: "8px",
    "@media (max-width: 695px)": {
        flexWrap: "wrap",
        width: "100%"
    }
})

const PaperWrapper = styled(Paper)({
    background: "transparent",
    "& .MuiTabs-root": {
        minHeight: "21px",
    }
})
const CustomTab = styled(Tab)({
    fontWeight: 500,
    fontSize: "14px",
    color: "#1C2324",
    opacity: "0.48 !important",
    borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
    padding: "0px",
    alignItems: "end",
    minHeight: "21px !important",
    minWidth: "0px !important",
    "&.Mui-selected": {
        color: "#DF5200 !important",
        opacity: "1 !important",
    }
})

const CustomFieldBox = styled(Box)({
    display: "flex !important",
    flexDirection: "row",
    alignItems: "center"
})
const CustomFormLabel = styled(FormLabel)({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1C2324",
    opacity: 0.64,
    marginBottom: "4px"
})

const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "40px"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
        backgroundColor: "#F8F6F1 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0EBE0 !important",

    }
})

const CustomMenuItem = styled(MenuItem)({
    padding: "6px 16px 0 16px !important",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    opacity: 0.56,
    color: "#1C2324",
    '& .MuiListItem-root.Mui-selected': {
        backgroundColor: "none !important",
        opacity: 1
    }
})
const AccordionActivityBox = styled(Accordion)({
    width: "100%",
    backgroundColor: "#F8F6F1 !important",
    boxShadow: "none !important",
    border: "1px solid #F0EBE0 !important",
    borderRadius: "12px !important",
    "& .MuiAccordionSummary-root": {
        padding: "8px 8px 8px 16px !important"
    } as React.CSSProperties,
    "& .MuiAccordionSummary-content": {
        margin: "0",
    } as React.CSSProperties,
    "& .MuiButtonBase-root": {
        padding: 0,
        marginRight: 0
    }
})

const style = {
    mainWrapper: {
        borderRadius: "12px",
        padding: "24px"
    } as React.CSSProperties,
    headerTitle: {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "36px",
        color: "#1C2324",
        width: "100%"
    } as React.CSSProperties,
    dropdownWrapper: {
        backgroundColor: "#FCFAF7",
        borderRadius: "12px",
        paddingTop: "16px"
    } as React.CSSProperties,
    // TABS
    customTab: {
        minWidth: 50,
        width: "33.3% !important",
        borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
        padding: "6px 12px 2px 12px",
        minHeight: 0
    } as React.CSSProperties,
    // OPTIONS
    optionWrapper: {
        padding: "24px 0px",
        display: "flex",
        flexDirection: "column",
        rowGap: "12px"
    } as React.CSSProperties,
    formulaX: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        margin: "0 0 0 6px",
        opacity: 0.72,
        color: "#1C2324"
    } as React.CSSProperties,
    iconBtn: {
        margin: "0px 0 0 16px",
        padding: "0"
    } as React.CSSProperties,
    accordianTitle: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
        color: "#1C2324"
    } as React.CSSProperties,
    // BUTTON
    saveBtn: {
        width: "100%",
        height: "40px",
        backgroundColor: "#DF5200",
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center",
        fontWeight: 600,
        borderRadius: "28px",
        marginTop: "24px"
    } as React.CSSProperties,
    loadingStyle: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
}
// Customizable Area End
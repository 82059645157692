Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Chat9";
exports.labelBodyText = "Chat9 Body";
exports.notificationType = "admin_notification_web";

exports.btnExampleTitle = "CLICK ME";
exports.apiContentType = 'application/json';
exports.acceptAll = '*/*';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.getChatListApiEndPoint = 'admin/bx_block_admin_chat/admin_chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'admin/bx_block_admin_chat/admin_chat/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'admin/bx_block_admin_chat/chat_messages';
exports.updateReadMessageApiEndPoint = 'admin/bx_block_admin_chat/chat_messages/mark_read';
exports.updateDeliverMessageApiEndPoint = 'admin/bx_block_admin_chat/chat_messages/mark_delivered';
exports.updateChatApiEndPoint = 'chat/chats';
exports.updateProfileImage = 'admin/bx_block_admin_chat/admin_chat/update_chat_logo';
exports.getStateList = 'admin/state_listing';
exports.getDistrictList = 'admin/district_listing';
exports.getTalukaList = 'admin/taluka_listing';
exports.getVillageList = 'admin/village_listing';
exports.getFarmerList = "admin/farmer_listing";
exports.getFieldExecutiveList = "admin/fe_listing";
exports.getSupeAdminList = "admin/super_admin_listing"
exports.getAdminList = "admin/admin_listing"
exports.createNewGroup = '/admin/bx_block_admin_chat/admin_chats'
exports.getMyChatsApiEndpoint = 'chat/chats/mychats';
exports.getChatMediaHistory = 'admin/bx_block_admin_chat/admin_chat/media_history';
exports.removeChatUser = '/admin/bx_block_admin_chat/admin_chat/remove_chat_user'
exports.newGroupApi = "admin/account_users"
exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.adminGetUrl = "admin/show_current_admin";
exports.getnewsAndEventApiEndPoint = 'admin/bx_block_news_and_events/events_news';
exports.getlibraryApiEndPoint = 'admin/bx_block_materrapedia/libraries';
exports.getFaqsApiEndPoint = 'bx_block_materrapedia/show_faq?id=';
exports.getSurveyApiEndPoint = 'admin/bx_block_admin_farm_dairy/surveys/';
exports.getSurveyDeatilsApiEndPoint = 'admin/bx_block_admin_farm_dairy/surveys/survey_user_response';
exports.getFarmDiaryEntriesApiEndPoint = 'admin/bx_block_admin_farm_dairy/admin_farm_dairies/show_farm_dairy';
exports.getFarmDiaryShareChatApiEndPoint = 'mobile_and_web/farm_dairy/share_chat';
exports.addUserApiEndPoint="admin/bx_block_admin_chat/admin_chat/add_chat_user"
exports.perPageUserList = 10;
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTksImV4cCI6MTY4NjQ4MjYxMiwidG9rZW5fdHlwZSI6ImFkbWluX2xvZ2luIn0.bp74gMvDCZcMogDfRBGfa2a7zTTAAZEEJPPpSh8pdlkPVryevAYEoOmCBKdFuEApmH7p4hZvzbCQYikvCil_pQ";
exports.updateChatName="admin/bx_block_admin_chat/admin_chat/update_chat_name";
exports.getAllUsers="admin/all_users_listing";
exports.getNotificationPushApiEndPoint = 'admin/bx_block_admin_chat/push_notifications/';
exports.getNotificationAdminApiEndPoint = 'admin/bx_block_admin_notification/admin_notifications/';
exports.getCropCalendarChatDetailsEndPoint = 'admin/bx_block_phase/sub_activity_progress';

exports.ENDPOINT = {
  GET_PARTICIPANTS: "admin/bx_block_admin_chat/admin_chat/chat_participants"
}
// Customizable Area End
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple2, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, IrrigationReqKey, formateSimpleBodyType2, formateUnitBodyData, getTitleMessage, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    currentExpanded: string | null,
    searchValue: string,
    // API CALLS
    fetchHeadingsLoading: boolean,
    dropdownDataLoading: boolean,
    submitLoading: boolean
    headingsData: IHeadings[],
    dropdownData: IDropdownDetailsData[],
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
}
interface SS { }
// Customizable Area End

export default class IrrigationDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getHeadingsCallId: string = "";
    getDetailsCallId: string = "";
    updateDropdownsCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            currentExpanded: null,
            searchValue: "",
            titleMsgModal: "",
            openMsgModal: false,
            descMsgModal: "",
            // API CALLS
            fetchHeadingsLoading: false,
            dropdownDataLoading: false,
            submitLoading: false,
            dropdownData: [],
            headingsData: [],
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getHeadingsCallId:
                        this.GetHeadingsApiCallIdHandle(responseJson)
                        break;
                    case this.getDetailsCallId:
                        this.GetDetailsHandle(responseJson)
                        break;
                    case this.updateDropdownsCallId:
                        this.UpdateDropdownHandle(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const { dropdownName, subDropdownName } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }
    // HANDLE API CALLS
    GetHeadingsApiCallIdHandle = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    GetDetailsHandle = (responseJson: IDropdownDetailsData[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: responseJson
            })
        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }
    UpdateDropdownHandle = (response: IResponseDropdowns[] | null | undefined) => {

        this.setState({ submitLoading: false })
        if (!Array.isArray(response) || response.length === 0) {
            this.openMsgModal(true, "Error", "Something went wrong!")
            return;
        }

        const hasMessageKey = response.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(response);
            this.openMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.successUpdateResp(response);
        }
    }

    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const requestApiMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsCallId = requestApiMSG.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        requestApiMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        requestApiMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestApiMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(requestApiMSG.id, requestApiMSG);
    }
    openMsgModal = (
        open: boolean,
        title: string,
        message: string) => {
        this.setState({
            openMsgModal: open, titleMsgModal: title, descMsgModal: message,
        })
    }
    successUpdateResp = (response: IResponseDropdowns[]) => {
        if (response && response.length) {
            this.openMsgModal(
                true, 
                "Success", 
                "Dropdown Saved Successfully."
                )
            if (this.state.currentExpanded) { this.fetchDropdownDetails(this.state.currentExpanded)
            }
        } else {
            this.openMsgModal(true, "Error", "Something went wrong!")
        }
    }
    fetchDropdownDetails = (title: string) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        if (dropdownName && subDropdownName && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            })

            const header = { token: this.state.authToken };
            const requestApiMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDetailsCallId = requestApiMSG.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(requestApiMSG.id, requestApiMSG);
        } else {
            return
        }
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple2 | IRequestBodyUnit)[]) => {
        const { dropdownName, subDropdownName } = DropdownKey()

        const title = this.state.currentExpanded
        if (dropdownName && subDropdownName && title) {
            this.setState({
                submitLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const requestApiMSG = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsCallId = requestApiMSG.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${dropdownName}&sub_activity=${subDropdownName}&title=${title}`
            const dropdown_type = IrrigationReqKey(title, configJSON.IRRIGATION.SOURCE_OF_IRRIGATION, configJSON.IRRIGATION.TYPE_OF_IRRIGATION)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            requestApiMSG.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestApiMSG.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(requestApiMSG.id, requestApiMSG);
        } else {
            return
        }
    }

    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }

    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType2(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple2)[])
    }

    closeMsgModal = () => {
        this.setState({
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
        })
    }
    
    setSearchValue = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            currentExpanded: null,
            searchValue: query
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            }, () => {
                this.fetchDropdownDetails(heading)

            })
        }
    };
 
    // Customizable Area End
}
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ICropDetailsProfile, ICropsResponseProfile, IUserFarmFarmerProfile, IChartDataProfile, IDataToPassInChart } from "../../../components/src/interface.web";
const initialChartValues = [
  {
    name: "Labor",
    pv: 0,
  },
  {
    name: "Machine",
    pv: 0,
  },
  {
    name: "Weed",
    pv: 0,
  },
  {
    name: "Irrigation",
    pv: 0,
  },
  {
    name: "Seed",
    pv: 0,
  },
  {
    name: "Nutrient",
    pv: 0,
  },
  {
    name: "Pest",
    pv: 0,
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentSelectedYear:number;
  userFarmsData: IUserFarmFarmerProfile[];
  userFarmDiary: any;
  // FARM STATES
  selectedFarmIds:string[],
  selectedFarmId:string | number,
  userFarmsLoading:boolean,
  // USER CROPS    
  userCropsDataLoading: boolean,
  userCropsData: ICropDetailsProfile[],
  selectedCropsId: string[],
  //CHART STATES
  chartData: IDataToPassInChart[], 
  profit: boolean,
  totalIncome: string,
  totalExpense: string,
  netQuantity: number,
  netProfit: string,
  netLoss: string,
  farmerId: string,
  numOfCrops: number,
  farmArea: number,
  cropArea: number,
  sowingDate: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfmultiplecropspart2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserFarmsApiCallId:string = "";
  getUserCropsApiCallId: string = "";
  getUserFarmSummaryApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentSelectedYear:new Date().getFullYear(),
      userFarmsData:[],
      userFarmDiary: undefined,
      // FARM STATES
      selectedFarmIds: [""],
      selectedFarmId: "",
      userFarmsLoading: false,
      // USER CROPS
      userCropsDataLoading:false,
      userCropsData:[],
      selectedCropsId:[""],
      // CHART STATES
      chartData: initialChartValues, 
      profit: false,
      totalIncome: "0",
      totalExpense: "0",
      netQuantity: 0,
      netProfit: "0",
      netLoss: "0",
      farmerId: "", 
      numOfCrops: 0,
      farmArea: 0,
      cropArea: 0,
      sowingDate: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getUserFarmsApiCallId:
            this.getUserFarmsApiRes(responseJson);
            break;
          case this.getUserCropsApiCallId:
            this.handleGetUserCropsApiCallId(responseJson);
            break;
          case this.getUserFarmSummaryApiCallId: 
            this.getUserFarmSummaryApiResponse(responseJson);
            break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const url = window.location.pathname;
    const parts = url.split('/');
    const id = parts[parts.length - 1];
    this.setState({ farmerId: id }, () => {
      this.fetchUserFarms()
      this.fetchCrops()
      this.fetchFarmSummary()
    })
  }

  // API CALL STARTS
  fetchUserFarms = () => {
    const { farmerId } = this.state;
    this.setState({ userFarmsLoading: true, userFarmsData: [] })
    const header = { token: localStorage.getItem("tokenn"), "Content-Type": configJSON.validationApiContentType, type: "admin_user" };
    let endpoint = `admin/bx_block_profile_bio/land_details/?account_id=${farmerId}`
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserFarmsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchCrops = () => {
    const { farmerId, selectedFarmIds } = this.state;
    this.setState({ userCropsDataLoading: true, userCropsData: [], selectedCropsId: [""] })
    const header = { token: localStorage.getItem("tokenn"), "Content-Type": configJSON.validationApiContentType, type: "admin_user" };
    let endpoint = `admin/bx_block_profile_bio/crops?account_id=${farmerId}&sowing_year=${this.state.currentSelectedYear}`
    if (selectedFarmIds.length > 0 && !selectedFarmIds.includes("")) {
      endpoint = endpoint + `&land_detail_id=${selectedFarmIds}`
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserCropsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchFarmSummary = () => {
    const { currentSelectedYear, farmerId, selectedFarmIds, selectedCropsId } = this.state;
    const header = { token: localStorage.getItem("tokenn"), "Content-Type": configJSON.validationApiContentType, type: "admin_user" };
    let endpoint = `mobile_and_web/farm_dairy/farm_summaries?year=${currentSelectedYear}&user_id=${farmerId}`
    if (!selectedFarmIds.includes("")) {
      endpoint = endpoint + `&land_detail_ids=${selectedFarmIds}`
    }
    if (!selectedCropsId.includes("")) {
      endpoint = endpoint + `&crop_ids=${selectedCropsId}`
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserFarmSummaryApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // API CALL ENDS

  // API RESPONSE HANDLE STARTS
  getUserFarmsApiRes = (res: { data: IUserFarmFarmerProfile[] }) => {
    let newData: IUserFarmFarmerProfile = {
      "id": "",
      "type": "land_detail",
      "attributes": {
        "name": "All",
        "farm_image": null,
      }
    };

    if (res?.data) {
      let farmData = [newData, ...res.data]
      this.setState({ userFarmsData: farmData, userFarmsLoading: false })
    }
  }

  handleGetUserCropsApiCallId = (response: ICropsResponseProfile | undefined) => {
    let newData: ICropDetailsProfile = {
      "id": "",
      "type": "crop",
      "attributes": {
        "crop_name_id": {
          "id": 0,
          "name": "All",
          "name_english": "All",
          "name_hindi": "All",
          "name_gujrati": "All",
          "active": true,
          "crop_photo": {
            "url": "",
            "thumb": {
              "url": ""
            }
          },
        },
      }
    }
    if (response && response.data) {
      let cropData = [newData, ...response.data]
      this.setState({
        userCropsData: cropData,
        userCropsDataLoading: false
      })
    } else {
      this.setState({
        userCropsData: [],
        userCropsDataLoading: false
      })
    }
  }

  getUserFarmSummaryApiResponse = (response: IChartDataProfile) => {
    if (response?.data) {
      let updatedData = this.state.chartData.map((item) => {
        switch (item.name) {
          case "Labor":
            return { ...item, pv: +response.data[0].total_labor_cost }
          case "Machine":
            return { ...item, pv: +response.data[0].total_machine_cost }
          case "Weed":
            return { ...item, pv: +response.data[0].total_weed_cost }
          case "Irrigation":
            return { ...item, pv: +response.data[0].total_irrigation_cost }
          case "Seed":
            return { ...item, pv: +response.data[0].total_seed_cost }
          case "Nutrient":
            return { ...item, pv: +response.data[0].total_nutrient_cost }
          case "Pest":
            return { ...item, pv: +response.data[0].total_pest_cost }
          default:
            return { ...item }
        }
      });
      this.setState({
        chartData: updatedData,
        userFarmsLoading: false,
        profit: response.data[0].net_profit ? true : false,
        netQuantity: response.data[0].net_quantity,
        totalIncome: response.data[0].total_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        totalExpense: response.data[0].total_expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        netProfit: response.data[0].net_profit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        netLoss: response.data[0].net_loss?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), 
        numOfCrops: response.data[0].total_number_of_crops,
        farmArea: response.data[0].total_farm_area, 
        cropArea: response.data[0].total_active_crop_area,
        sowingDate: response.data[0]?.last_cotton_sowing_date ? moment(response.data[0]?.last_cotton_sowing_date.toString()).format("DD MMMM YYYY") : ""
      })
    }
  }
  // API RESPONSE HANDLE ENDS

  handleSelectFarms = (farmId: string) => {
    const { selectedFarmIds } = this.state;
    let existingSelectedFarmsIdArray = selectedFarmIds.filter(id => id !== "");
    if(farmId === ""){
      existingSelectedFarmsIdArray = [""]
    } else {
      if (existingSelectedFarmsIdArray.includes(farmId)) {
        if(existingSelectedFarmsIdArray.length === 1){
          existingSelectedFarmsIdArray = [""]
        } else {
          existingSelectedFarmsIdArray = existingSelectedFarmsIdArray.filter(id => id !== farmId)
        }
      } else {
        existingSelectedFarmsIdArray.push(farmId);
      }
    }
    this.setState({
      selectedFarmIds: existingSelectedFarmsIdArray,
      selectedCropsId: [""]
    }, () => {
      this.fetchCrops()
      this.fetchFarmSummary()
    })
  }

  handleSelectCrops = (cropId: string) => {
    const { selectedCropsId } = this.state;
    let existingSelectedCropsIdArray = selectedCropsId.filter(id => id !== "");
    if (cropId === "") {
      existingSelectedCropsIdArray = [""]
    } else {
      if (existingSelectedCropsIdArray.includes(cropId)) {
        if(existingSelectedCropsIdArray.length === 1){
          existingSelectedCropsIdArray = [""]
        } else {
          existingSelectedCropsIdArray = existingSelectedCropsIdArray.filter(id => id !== cropId)
        }
      } else {
        existingSelectedCropsIdArray.push(cropId);
      }
    }
    this.setState({
      selectedCropsId: existingSelectedCropsIdArray,
    }, () => {
      this.fetchFarmSummary()
    })
  }

  handleChangeCurrentYear = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      currentSelectedYear: newValue,
      selectedFarmIds: [""],
      selectedCropsId: [""]
    }, () => {
      this.fetchCrops(), 
      this.fetchFarmSummary()
    })
  }

  handleRedirection = () => {
    const pathSegments = window.location.pathname.split('/');
    const hasFarmerKey = pathSegments.length > 1;
    if(hasFarmerKey){
      this.props.navigation.navigate("AdminConsoleUserFarmDiary", { }, {farmer:pathSegments[2]})
    }
  }
  // Customizable Area End
}

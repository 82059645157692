// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IDropdownDetailsData, IHeadings, IRequestBodySimple1, IRequestBodyUnit, IResponseDropdowns, ISimpleDropdownFormData, IUnitMenu } from "../../../../components/src/interface.web";
import { DropdownKey, formateSimpleBodyType1, formateUnitBodyData, getTitleMessage, toLowerSnakeCase, trimStart } from "../../../../components/src/helper";

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    openUnitsDrodpowns: () => void
}
interface S {
    authToken: string | null,
    fetchHeadingsLoading: boolean,
    headingsData: IHeadings[],
    currentExpanded: string | null,
    updateLoading: boolean
    dropdownDataLoading: boolean,
    dropdownData: IDropdownDetailsData[],
    // MSG MODAL
    openMsgModal: boolean,
    titleMsgModal: string,
    descMsgModal: string,
    searchValue: string,
}
interface SS { }
// Customizable Area End

export default class HarvestDropdownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    updateDropdownsApiCallId: string = "";
    getHeadingsApiCallId: string = "";
    getDropdownsDataApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            authToken: localStorage.getItem("tokenn"),
            fetchHeadingsLoading: false,
            currentExpanded: null,
            updateLoading: false,
            dropdownData: [],
            openMsgModal: false,
            titleMsgModal: "",
            headingsData: [],
            descMsgModal: "",
            dropdownDataLoading: false,
            searchValue: "",
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start        
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.updateDropdownsApiCallId:
                        this.UpdateDropdownsResponse(responseJson)
                        break;
                    case this.getDropdownsDataApiCallId:
                        this.GetDropdownsDataResponse(responseJson)
                        break;
                    case this.getHeadingsApiCallId:
                        this.GetHeadingsApiResponse(responseJson)
                        break;
                }
            }
        }
        // Customizable Area End

    }

    // Customizable Area Start

    // HANDLE API CALLS
    GetHeadingsApiResponse = (responseJson: IHeadings[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: responseJson
            })
        } else {
            this.setState({
                fetchHeadingsLoading: false,
                headingsData: []
            })
        }
    }
    UpdateDropdownsResponse = (responseJsonValue: IResponseDropdowns[] | null | undefined) => {

        this.setState({ updateLoading: false })
        if (!Array.isArray(responseJsonValue) || responseJsonValue.length === 0) {
            this.handleOpenMsgModal(true, "Error", "Something went wrong")
            return
        }
        const hasMessageKey = responseJsonValue.some(
            (item) => 'errors' in item && typeof item.errors === 'string' && item.errors.trim() !== '');

        if (hasMessageKey) {
            const { title, message } = getTitleMessage(responseJsonValue);
            this.handleOpenMsgModal(true, title, message)
            if (this.state.currentExpanded) {
                this.getDropdownsData(this.state.currentExpanded)
            }
        } else {
            this.successUpdate(responseJsonValue);
        }
    }
    GetDropdownsDataResponse = (responseJson: IDropdownDetailsData[] | null | undefined) => {
        if (responseJson && responseJson.length) {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: responseJson
            })
        } else {
            this.setState({
                dropdownDataLoading: false,
                dropdownData: []
            })
        }
    }


    successUpdate = (responseJson: IResponseDropdowns[]) => {
        if (responseJson && responseJson.length) {
            this.handleOpenMsgModal(true, "Success", "Dropdown Saved Successfully.")
            if (this.state.currentExpanded) {
                this.getDropdownsData(this.state.currentExpanded)
            }
        } else {
            this.handleOpenMsgModal(true, "Error", "Something went wrong!")
        }
    }

    async componentDidMount(): Promise<void> {
        const {
            dropdownName,
            subDropdownName
        } = DropdownKey()
        // FETCH HEADING
        if (dropdownName && subDropdownName) {
            this.getDropdownHeadings(dropdownName, subDropdownName)
        }
    }

    // API CALLS
    getDropdownHeadings = (dropdown: string, subDropdown: string) => {
        this.setState({
            fetchHeadingsLoading: true,
            headingsData: []
        });

        const header = { token: this.state.authToken };
        const reqapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHeadingsApiCallId = reqapiMsg.messageId;

        const endpoint = `${configJSON.subDropdownHeadingsEndPoint}?activity=${dropdown}&sub_activity=${subDropdown}&title=${this.state.searchValue}`
        reqapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
        reqapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

        runEngine.sendMessage(reqapiMsg.id, reqapiMsg);
    }

    getDropdownsData = (title: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        if (Dropdown && subDropdown && title) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            })

            const header = { token: this.state.authToken };
            const reqapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDropdownsDataApiCallId = reqapiMsg.messageId;

            const endpoint = `${configJSON.DropdownDetailsEndPoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            reqapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            reqapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.GET_METHOD_TYPE);

            runEngine.sendMessage(reqapiMsg.id, reqapiMsg);
        } else {
            return
        }
    }
    updateDropdownDetails = (requestBody: ({ _destroy: string } | IRequestBodySimple1 | IRequestBodyUnit)[]) => {
        const searchParams = new URLSearchParams(window.location.search);
        const Dropdown = searchParams.get('dropdown');
        const subDropdown = searchParams.get('sub-dropdown');
        const title = this.state.currentExpanded
        if (Dropdown && subDropdown && title) {
            this.setState({
                updateLoading: true
            })
            const header = { "Content-Type": configJSON.validationApiContentType, token: this.state.authToken };
            const reqapiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateDropdownsApiCallId = reqapiMsg.messageId;

            const endpoint = `${configJSON.updateDropdownEndpoint}?activity=${Dropdown}&sub_activity=${subDropdown}&title=${title}`
            const dropdown_type = toLowerSnakeCase(title)
            const body = {
                "drop_down": {
                    [dropdown_type]: requestBody
                }
            }
            reqapiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header))
            reqapiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqapiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            reqapiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.POST_METHOD_TYPE);

            runEngine.sendMessage(reqapiMsg.id, reqapiMsg);
        } else {
            return
        }
    }

    handleSubmit = (formData: ISimpleDropdownFormData[]) => {
        const bodyData = formateSimpleBodyType1(formData)
        this.updateDropdownDetails(bodyData as ({ _destroy: string } | IRequestBodySimple1)[])
    }

    handleSubmitUnit = (formData: IUnitMenu[]) => {
        const bodyData = formateUnitBodyData(formData)
        this.updateDropdownDetails(bodyData as IRequestBodyUnit[])
    }

    handleCurrentExpanded = (heading: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            currentExpanded: isExpanded ? heading : null
        })
        if (isExpanded) {
            this.setState({
                dropdownDataLoading: true,
                dropdownData: []
            }, () => {
                this.getDropdownsData(heading)
            })
        }
    };
    handleCloseMsgModal = () => {
        this.setState({
            openMsgModal: false,
            titleMsgModal: "",
            descMsgModal: "",
        })
    }
    handleOpenMsgModal = (open: boolean, title: string, message: string) => {
        this.setState({
            openMsgModal: open,
            titleMsgModal: title,
            descMsgModal: message,
        })
    }
    setSearchValue = (value: string) => {
        const query = trimStart(String(value))
        this.setState({
            searchValue: query,
            currentExpanded: null
        }, () => {
            const { dropdownName, subDropdownName } = DropdownKey()
            if (dropdownName && subDropdownName) this.getDropdownHeadings(dropdownName, subDropdownName)
        })

    }
    // Customizable Area End
}
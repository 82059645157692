Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cf2enhancementsfromversion1";
exports.labelBodyText = "cf2enhancementsfromversion1 Body";

exports.btnExampleTitle = "CLICK ME";

// HTTP METHODS
exports.GET_METHOD_TYPE = "GET";
exports.POST_METHOD_TYPE = "POST";
exports.PUT_METHOD_TYPE = "PUT";
exports.PATCH_METHOD_TYPE = "PATCH";
exports.DELETE_METHOD_TYPE = "DELETE";

exports.TITLE = {
  TERMS_CONDITIONS: "Terms & Conditions",
  PRIVACY_POLICY: "Privacy Policy"
}
exports.ENDPOINTS = {
  GET_TERMS_CONDITIONS: "bx_block_settings/term_and_conditions",
  GET_PRIVACY_POLICY: "bx_block_settings/privacy_policies"
}

// Customizable Area End
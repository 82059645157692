import React from "react";

// Customizable Area Start
import { 
  Box, 
  Button, 
  Typography, 
  styled,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Tabs,
  Tab,
  FormControl,
  TextField,
  FormLabel,
  TextareaAutosize,
  Modal,
  Tooltip
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { Add } from "@material-ui/icons";
import CustomModal from "../../../components/src/customModal.web";
import { IAnalytic } from "../../../components/src/interface.web";
import { ErrorIconSVG, GraphIconSVG } from "./assets";
import { DataNotFound, LoadingButton, renderErrorMessage, themeUser } from "../../../components/src/AdminConsoleHelper";
import CloseIcon from "@material-ui/icons/Close";
import ErrorModal from "../../../components/src/ErrorModal.web";
import { isValidURL, returnTruthyString } from "../../../components/src/helper";

const DashboardWrapper: React.FC<{content: React.ReactNode}> = ({ content }) => {
  return (
    <Box style={webStyle.chart}>
      <ContentWrapper style={webStyle.contentWraper}>
        {content}
      </ContentWrapper>
    </Box>
  );
}
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () =>{
    const Title = this.state.selectedDashboard ? this.state.selectedDashboard.attributes.title : "Dashboard"
    return (
      <Box style={webStyle.headerWrapper}>
        <Typography style={webStyle.headerTitle}>{Title}</Typography>
        <Box style={webStyle.headerBtnWrapper}>
          <Button style={{ ...webStyle.modalButtons, backgroundColor: "#DF5200"}}
            onClick={this.handleOpenAddModal}
            data-testid="add-dashboard-btn"
          >
            <AddIcon /> Add Dashbaord
          </Button>
          <Tooltip title={!this.state.selectedDashboard ? "Please select Dashboard" : ""}>
            <span>
              <Button style={{ ...webStyle.modalButtons, backgroundColor: "#B3261E" }}
                onClick={this.handleOpenDeleteModal}
                data-testid="delete-btn"
                disabled={!this.state.selectedDashboard}
              >
                Delete
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    )
  }
  renderAddModal = () => {
    const { form, currentTab } = this.state
    return (
      <>
        <Dialog
          aria-labelledby="add-dashboard-modal"
          open={this.state.openDashboardModal}
          maxWidth={"md"}
          PaperProps={{
            style: { borderRadius: 12, width: "500px", top: "-5%", overflow: "hidden" },
          }}
        >
          <Box style={{ ...webStyle.headerWrapper, padding: "24px 24px 16px 24px" }}>
            <Typography style={webStyle.modalTitle}>Add New Dashboard</Typography>
            <IconButton
              style={webStyle.closeIconBtn}
              data-testid="close-btn"
              onClick={this.handleCloseAddModal}
            >
              <CloseIcon style={{ color: "#1c2324" }} />
            </IconButton>
          </Box>

          <PaperWrapper square style={{ background: "transparent" }}>
            <Tabs
              className="tabs-wrapper"
              variant="fullWidth"
              value={currentTab}
              onChange={this.handleChangeTab}
              data-testid="tab-wrapper"
            >
              <CustomTab label="By URL" value={1} />
              <CustomTab label="Embed code" value={2} />
            </Tabs>
          </PaperWrapper>
          <Box style={{ padding: "16px 24px 24px 24px", overflow: "auto" }}>
            <form>
              <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                {currentTab == 1 ?
                  <Box>
                    <CustomFormLabel>Enter/Paste URL</CustomFormLabel>
                    <FormControl fullWidth>
                      <CustomTextField
                        autoComplete="off"
                        type="text"
                        variant="outlined"
                        color="secondary"
                        placeholder="Paste URL here....."
                        data-testid="url_value_testid"
                        name="url_value"
                        value={form.url_value}
                        onChange={(event: React.ChangeEvent<{ value: string }>) => { this.setValue("url_value", event.target.value) }}
                      />
                      {renderErrorMessage(form.errors.url_value)}
                    </FormControl>
                  </Box>
                  :
                  <Box>
                    <CustomFormLabel>Embed code</CustomFormLabel>
                    <FormControl fullWidth>
                      <TextArea
                        aria-label="text-area-custom"
                        minRows={3}
                        data-testid="embed_code_testid"
                        placeholder="Paste code here....."
                        value={form.embed_code}
                        onChange={(event: React.ChangeEvent<{ value: string }>) => { this.setValue("embed_code", event.target.value) }}
                      />
                      {renderErrorMessage(form.errors.embed_code)}
                    </FormControl>
                  </Box>
                }
                <Box>
                  <CustomFormLabel>Title of dashboard</CustomFormLabel>
                  <FormControl fullWidth>
                    <CustomTextField
                      autoComplete="off"
                      type="text"
                      variant="outlined"
                      color="secondary"
                      placeholder="Enter title of dashboard"
                      data-testid="title_testid"
                      name="title"
                      value={form.title}
                      onChange={(event: React.ChangeEvent<{ value: string }>) => { this.setValue("title", event.target.value) }}
                    />
                    {renderErrorMessage(form.errors.title)}
                  </FormControl>
                </Box>
              </Box>
              <LoadingButton
                data-testid="add-btn"
                btnStyle={webStyle.addBtn}
                loadingStyle={webStyle.btnLoader}
                title={"Add New Dashboard"}
                isLoading={this.state.addAnalyticsLoading}
                onClick={this.handleSubmitModal}
              />
            </form>
          </Box>
        </Dialog>

      </>
    )
  }
  renderDeletModal = () =>{
    const { openDeleteModal, titleDeleteModal, descDeleteModal, deleteAnalyticsLoading } = this.state
    return (
      <CustomModal
        data-testid="delete-modal"
        open={openDeleteModal}
        title={titleDeleteModal}
        description={descDeleteModal}
        confirmHandler={this.deleteAnalytics}
        disableConfirmHandler={deleteAnalyticsLoading}
        confirmTitle={"Delete"}
        onClose={this.handleCloseDeleteModal}
        dynamicDescription={
          <>
            Are you sure? You want to delete <span style={{ fontWeight: 500 }}>{this.state.selectedDashboard?.attributes.title}</span> Dashboard?
          </>}
      />
    )
  }
  renderMainContent = () => {
    const url_value = isValidURL(this.state.analyticsData?.attributes.url) ? this.state.analyticsData?.attributes.url : null
    const embedCode = this.state.analyticsData?.attributes.embed_code
    if (url_value) {
      return (
        <DashboardWrapper content={
          <iframe
            style={{ pointerEvents: "auto" } as React.CSSProperties}
            title="Power BI Report"
            width="100%"
            height="100%"
            src={url_value}
            frameBorder="0"
            allowFullScreen
          />
        } />
      )
    } else if (embedCode) {
      return (
        <DashboardWrapper content={
          <div dangerouslySetInnerHTML={{ __html: embedCode }}/>
        } />
      )
    } else {
      return (
        <DashboardWrapper content={<Typography style={webStyle.contentTxt}>Dashboard</Typography>} />
      )
    }
  }

  renderDashboard = () =>{
    return(
      <>
        {this.state.fetchAnalyticsLoading ?
          <DashboardWrapper content={<CircularProgress style={webStyle.loaderStyle} size={32} />} /> : <></>}
        {!this.state.fetchAnalyticsLoading && this.state.analyticsData ? this.renderMainContent() : <></>}
        {!this.state.fetchAnalyticsLoading && !this.state.analyticsData ?
          <DashboardWrapper content={<Typography style={webStyle.contentTxt}>Dashboard</Typography>} /> : <></>}
      </>
    )
  }

  renderCard = (item: IAnalytic, itemIndex: number) => {
    const borderColor = this.state.selectedDashboard?.attributes.title == item.attributes.title ? "2px solid #DF5200" : "2px solid #F0EBE0"
    return (
      <>
        <Box style={{ ...webStyle.cardWrapper, border: borderColor }}
          key={itemIndex}
          data-testid={`analytics-item`}
          onClick={() => this.handleChangeAnalytic(item)}
        >
          <Tooltip title={returnTruthyString(item.attributes.title)}>
            <CustomTitle>{returnTruthyString(item.attributes.title)}</CustomTitle>
          </Tooltip>
          <img src={GraphIconSVG} style={webStyle.imageAtBottomRight} alt="Image" />
        </Box>
      </>
    )
  }
  renderAnalyticsList = () => {
    const { allAnalyticsData } = this.state
    return (
      <>
      <ListWrapper>
        {allAnalyticsData.map((item, itemIndex) => (
          this.renderCard(item, itemIndex)
        ))}
        </ListWrapper>
      </>
    )
  }

  analyticsLoader = () => {
    return(
      <Box style={{ padding: "32px 0", textAlign: "center" }}>
        <CircularProgress style={webStyle.loaderStyle} size={32} />
      </Box>
    )
  }

  alertModal = () => {
    return (
      <>
        <ErrorModal
          open={this.state.openAlertModal}
          errorTitle={this.state.titleAlertModal}
          errorSubTitle={this.state.descAlertModal}
          BtnTitle="Okay"
          handleBtnClick={this.closeAlertModal}
          data-testid="alert-modal"
        />
      </>
    )
  }
  customErrorModal = () => {
    const { titleErrorModal, descErrorModal } = this.state
    return (
      <>
        <Modal
          open={this.state.openErrorModal}
          onClose={this.errorModalClose}>
          <ErrorWrapper>
            <BoxWrapper>
              <img src={ErrorIconSVG} alt="error" />
              <Typography style={webStyle.errorTitle}>{titleErrorModal}</Typography>
              <Typography style={webStyle.errorDesc}>{descErrorModal}</Typography>
              <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "32px" }}>
                <Button style={{ ...webStyle.errorModalBtns, color: "#FFFFFF", backgroundColor: "#DF5200" }}
                  onClick={this.errorModalClose}
                  data-testid="go-back-btn"
                >Go Back</Button>
                <Button style={{ ...webStyle.errorModalBtns, color: "rgba(28, 35, 36, 0.72)", backgroundColor: "#E4DDC9" }}
                  onClick={this.errorModalClose}
                >Cancel</Button>
              </Box>
            </BoxWrapper>
          </ErrorWrapper>
        </Modal>
      </>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={this.props.id}>
        <ThemeProvider theme={themeUser}>
          {this.renderHeader()}
          {this.renderDashboard()}
          {this.state.allAnalyticsDataLoading ? this.analyticsLoader() : <></>}
          {(!this.state.allAnalyticsDataLoading && this.state.allAnalyticsData.length) ?
            this.renderAnalyticsList() : <></>
          }
          {(!this.state.allAnalyticsDataLoading && !this.state.allAnalyticsData.length) ?
            <DataNotFound fontStyle={webStyle.noDataTxt} /> : <></>
          }
          {this.state.openDashboardModal ? this.renderAddModal() : <></>}
          {this.state.openDeleteModal ? this.renderDeletModal() : <></>}
          {this.state.openAlertModal ? this.alertModal() : <></>}
          {this.state.openErrorModal ? this.customErrorModal() : <></>}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AddIcon = styled(Add)({
  width: "24px",
  height: "24px",
  marginRight: "4px"
})

const ListWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  overflowX: "auto",
  padding: "16px 0px"
})
const PaperWrapper = styled(Paper)({
  background: "transparent",
  "& .MuiTabs-root": {
      minHeight: "21px",
  }
})
const CustomTab = styled(Tab)({
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "28px",
  color: "#1C2324",
  opacity: "0.48 !important",
  borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
  padding: "0px",
  alignItems: "end",
  minHeight: "21px !important",
  minWidth: "0px !important",
  "&.Mui-selected": {
      color: "#DF5200 !important",
      opacity: "1 !important",
      
  },
  "& .MuiTab-wrapper":{
    marginBottom:"4px"
  }
})
const CustomTextField = styled(TextField)({
  marginTop: "4px",
  "& .MuiInputBase-root": {
    height: "56px"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px !important",
    backgroundColor: "#FCFAF7 !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #F0EBE0 !important",
  },
  "& .Mui-disabled": {
    opacity: 1,
    color: "rgba(0, 0, 0, 0.87)"
  }
})
const CustomFormLabel = styled(FormLabel)({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#1C2324",
  opacity: 0.64,
});

const TextArea = styled(TextareaAutosize)({
  border: "1px solid #F0EBE0 !important",
  backgroundColor: "#FCFAF7",
  borderRadius: "12px",
  minWidth: "447px !important",
  width: "447px !important",
  height: "200px !important",
  padding: "16px !important",
  overflow: "auto !important",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 400,
  marginTop:"4px",
  "&:focus": {
    border: "1px solid #F0EBE0 !important",
    outline: "none !important",
  },
  "@media (max-width: 600px)": {
    minWidth: "300px !important",
    width: "100% !important",
  }
})

const ErrorWrapper = styled(Box)({
  textAlign: "center",
  width: "528px",
  padding: "48px 5px",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: " translate(-50%, -50%)",
  boxShadow: " 2px 8px 32px 4px rgba(0, 0, 0, 0.06)",
  borderRadius: "12px",
  "@media (max-width: 528px)": {
    width: "428px",
  },
  "@media (max-width: 428px)": {
    width: "328px",
  }
})
const BoxWrapper = styled(Box)({
  padding:"0 135px",
  "@media (max-width: 528px)": {
    padding:"0 80px",
  },
  "@media (max-width: 428px)": {
    padding:"0 20px",
  }
})
const ContentWrapper = styled(Box)({
  backgroundColor: "#F8F6F1",
  borderRadius: "12px 12px 0 0",
  alignItems: "center",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  '& iframe': {
    pointerEvents: "auto"
  }
})

const CustomTitle = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#1C2324',
  userSelect: "none",
  maxHeight: "70px",
  overflow: "hidden"
})

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  // HEADER
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    rowGap: "8px",
  } as React.CSSProperties,
  headerBtnWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: "8px",
    columnGap: "16px"
  } as React.CSSProperties,
  headerTitle: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#1C2324"
  } as React.CSSProperties,
  modalButtons: {
    padding: "8px 24px",
    boxShadow: "none !important",
    height: "40px !important",
    color: "#FFFFFF",
    textTransform: "uppercase",
    borderRadius: "24px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600
  } as React.CSSProperties,
  cardWrapper: {
    position: 'relative',
    minWidth: "200px",
    width: "100%",
    height: '92px',
    backgroundColor: '#F0EBE0',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingInline: '1rem 0.7rem',
    paddingBlock: '0.4rem',
    cursor: 'pointer',
  } as React.CSSProperties,
  cardTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1C2324',
    userSelect: "none"
  } as React.CSSProperties,
  imageAtBottomRight: {
    position: 'absolute',
    height: '60px',
    bottom: '0',
    right: '0',
    userSelect: "none"
  } as React.CSSProperties,
  noDataTxt: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#1C2324",
    textAlign: "center",
    padding: "32px 0"
  } as React.CSSProperties,
  loaderStyle: {
    color: "#DF5200",
  } as React.CSSProperties,
  // ADD MODAL
  modalTitle: {
    color: "#1C2324",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "28px",
    textTransform: "uppercase"
  } as React.CSSProperties,
  closeIconBtn:{
    padding:0
  } as React.CSSProperties,
  addBtn:{
    width: "100%",
    height: "56px",
    backgroundColor: "#DF5200",
    color: "#FFFFFF",
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    fontWeight: 600,
    borderRadius: "28px",
    marginTop: "24px",
    textTransform:"uppercase"
  } as React.CSSProperties,
  btnLoader:{
    color: "#FFFFFF",
    height: "20px",
    width: "20px"
  } as React.CSSProperties,
  // DASHBOARD
  chart: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  } as React.CSSProperties,
  contentWraper: {
   marginTop:"12px"
  } as React.CSSProperties,
  contentTxt: {
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#1C2324',
    userSelect: "none"
  } as React.CSSProperties,
  errorModalBtns: {
    padding: "12px 24px",
    height: "48px",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    fontWeight: 600,
    borderRadius: "28px",
    textTransform: "uppercase"
  } as React.CSSProperties,
  errorTitle: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#1C2324',
    userSelect: "none",
    marginTop: "8px"
  } as React.CSSProperties,
  errorDesc: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#1C2324',
    userSelect: "none",
    marginTop: "8px"
  } as React.CSSProperties,
};
// Customizable Area End

import React from "react";
// Customizable Area Start
import {
    Box,
    Paper,
    Tabs,
    Tab,
    styled,
    TextField,
    FormControl,
    IconButton,
    Select,
    MenuItem
} from "@material-ui/core";
import TalukaDropdownController, { Props, configJSON } from "./TalukaDropdownController.web";
import { LoadingButton, selectMenuItem, NoMenuDataFound, renderErrorMessage } from "../../../components/src/AdminConsoleHelper";
import { AddSVG, RemoveSVG } from "./assets";
import { KeyboardArrowDown } from "@material-ui/icons";
import { returnTruthyString } from "../../../components/src/helper";
import { ILanguageObjectTaluka } from "../../../components/src/interface.web";

// Customizable Area End

export default class TalukaDropdown extends TalukaDropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTab = () => {
        const { selectedTab } = this.state
        return (
            <PaperWrapper square style={{ background: "transparent" }}>
                <Tabs
                    variant="fullWidth"
                    className="tabs-wrapper"
                    data-testid="tab-wrapper"
                    value={selectedTab}
                    onChange={this.handleChangeTab}
                >
                    <CustomTab label="English" value={configJSON.TAB.ENGLISH} data-testid="english-tab" />
                    <CustomTab label="Gujarati" value={configJSON.TAB.GUJARATI} data-testid="gujarati-tab" />
                    <CustomTab value={configJSON.TAB.HINDI} label="Hindi" data-testid="hindi-tab" />
                </Tabs>
            </PaperWrapper>
        )
    }

    renderDefaultForm = () => {
        const { selectedTab, defaultForm } = this.state
        const initialForm = defaultForm[selectedTab] as ILanguageObjectTaluka
        return (
            <>
                <Box style={style.inputWrapper}>
                    <FormControl fullWidth>
                        <CustomTextField
                            autoComplete="off"
                            type="text"
                            variant="outlined"
                            color="secondary"
                            placeholder="Add another option"
                            data-testid="text_input_default"
                            name="add_another_option"
                            value={initialForm?.value}
                            onChange={(event) => this.handleChangeInputDefault(event.target.value, selectedTab)}
                        />
                        {renderErrorMessage(initialForm?.error)}
                    </FormControl>
                    <IconButton
                        data-testid="add-input-testid"
                        style={style.iconBtn}
                        onClick={this.handleAddAnotherInput}
                    >
                        <img src={AddSVG} />
                    </IconButton>

                </Box>
                <Box style={{ display: "flex", width: "100%", padding: "0 40px 0 16px", marginTop: "8px" }}>
                    <Box style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: "4px" } as React.CSSProperties}>
                        <Box>
                            <CustomSelectDD
                                IconComponent={CustomArrowIcon}
                                data-testid="state-dropdown"
                                variant="outlined"
                                color="secondary"
                                style={style.inputFieldStyle}
                                inputProps={{ "aria-label": "Without label" }}
                                displayEmpty
                                value={defaultForm.stateId}
                                onChange={(event) => { this.handleChangeState(event.target.value as string) }}
                                disabled={this.state.stateListLoading}
                            >
                                {selectMenuItem("Select State")}
                                {this.state.stateList.length > 0 ?
                                    this.state.stateList.map((each) => (
                                        <MenuItem value={each.id} key={each.id}>
                                            {each.name}
                                        </MenuItem>
                                    ))
                                    : NoMenuDataFound()
                                }
                            </CustomSelectDD>

                            {renderErrorMessage(defaultForm.stateError)}
                        </Box>

                        <Box>
                            <CustomSelectDD
                                IconComponent={CustomArrowIcon}
                                data-testid="district-dropdown"
                                variant="outlined"
                                color="secondary"
                                style={style.inputFieldStyle}
                                inputProps={{ "aria-label": "Without label" }}
                                displayEmpty
                                value={defaultForm.districtId}
                                onChange={(event) => { this.handleChangeDropdowns("districtId", "districtError", event.target.value as string) }}
                                disabled={this.state.districtListLoading || !returnTruthyString(defaultForm.stateId)}
                            >
                                {selectMenuItem("Select District")}
                                {this.state.districtList.length > 0 ?
                                    this.state.districtList.map((each) => (
                                        <MenuItem key={each.id} value={each.id}>
                                            {each.name}
                                        </MenuItem>
                                    ))
                                    : NoMenuDataFound()
                                }
                            </CustomSelectDD>
                            {renderErrorMessage(defaultForm.districtError)}
                        </Box>
                        <Box>
                            <CustomSelectDD
                                IconComponent={CustomArrowIcon}
                                data-testid="mandi-dropdown"
                                variant="outlined"
                                color="secondary"
                                style={style.inputFieldStyle}
                                inputProps={{ "aria-label": "Without label" }}
                                displayEmpty
                                value={defaultForm.mandiName}
                                onChange={(event) => { this.handleChangeDropdowns("mandiName", "mandiError", event.target.value as string) }}
                                disabled={this.state.mandiListLoading}
                            >
                                {selectMenuItem("Select Mandi")}
                                {this.state.mandiList.length > 0 ?
                                    this.state.mandiList.map((each, index) => (
                                        <MenuItem key={index} value={each}>
                                            {each}
                                        </MenuItem>
                                    ))
                                    : NoMenuDataFound()
                                }
                            </CustomSelectDD>
                            {renderErrorMessage(defaultForm.mandiError)}
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }

    renderFormArray = () => {
        const { formArray, selectedTab } = this.state
        return (
            <>
                {formArray.length > 0 ? formArray.map((form, formIndex) => {
                    const existingForm = form[selectedTab] as ILanguageObjectTaluka
                    return (
                        <React.Fragment key={formIndex}>
                            {!form?._destroy ? (
                                <Box style={{ marginBottom: "8px" }}>
                                    <Box style={{ ...style.inputWrapper }}>
                                        <FormControl fullWidth>
                                            <CustomTextField
                                                autoComplete="off"
                                                type="text"
                                                variant="outlined"
                                                color="secondary"
                                                placeholder="Add another option"
                                                data-testid="text_input_form"
                                                name="add_another_option"
                                                value={existingForm?.value}
                                                onChange={(event) => this.handleChangeForm(formIndex, "value", "error", event.target.value, selectedTab)}
                                            />
                                            {renderErrorMessage(existingForm?.error)}
                                        </FormControl>
                                        <IconButton
                                            data-testid="remove-input-testid"
                                            style={style.iconBtn}
                                            onClick={() => this.handleRemoveInput(form, formIndex)}
                                        >
                                            <img src={RemoveSVG} />
                                        </IconButton>

                                    </Box>
                                    <Box style={{ display: "flex", width: "100%", padding: "0 40px 0 16px", marginTop: "8px" }}>
                                        <Box style={{ width: "100%", display: "flex", flexDirection: "column", rowGap: "4px" } as React.CSSProperties}>
                                            <Box>
                                                <CustomSelectDD
                                                    IconComponent={CustomArrowIcon}
                                                    data-testid={`mandi-dropdown-form`}
                                                    variant="outlined"
                                                    color="secondary"
                                                    style={style.inputFieldStyle}
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    displayEmpty
                                                    value={this.state.mandiList.length > 0 ? form.mandiName : ""}
                                                    onChange={(event) => this.handleChangeForm(formIndex, "mandiName", "mandiError", event.target.value as string)}
                                                    renderValue={() => form.mandiName}
                                                    disabled={this.state.mandiListLoading}
                                                >
                                                    {selectMenuItem("Select Mandi")}
                                                    {this.state.mandiList.length > 0 ?
                                                        this.state.mandiList.map((each, index) => (
                                                            <MenuItem key={index} value={each}>
                                                                {each}
                                                            </MenuItem>
                                                        ))
                                                        : NoMenuDataFound()
                                                    }
                                                </CustomSelectDD>
                                                {renderErrorMessage(form.mandiError)}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>)
                                :
                                <></>}
                        </React.Fragment>
                    )
                })
                    : <></>
                }
            </>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box>
                    {this.renderTab()}
                    <Box style={style.mainWrapper}>
                        {this.renderFormArray()}
                        {this.renderDefaultForm()}
                        <LoadingButton
                            data-testid="save-btn"
                            btnStyle={style.submitBtn}
                            loadingStyle={style.btnLoading}
                            title={"SAVE"}
                            isLoading={this.props.submitLoading}
                            onClick={this.handleSubmit}
                        />
                    </Box>
                </Box>
            </>
            // Customizable Area End

        )
    }
}
// Customizable Area Start        
const PaperWrapper = styled(Paper)({
    background: "transparent",
    "& .MuiTabs-root": {
        minHeight: "21px",
    }
})
const CustomTab = styled(Tab)({
    fontWeight: 500,
    fontSize: "14px",
    color: "#1C2324",
    opacity: "0.48 !important",
    borderBottom: "2px solid rgba(28, 35, 36, 0.32)",
    padding: "0px",
    alignItems: "end",
    minHeight: "21px !important",
    minWidth: "0px !important",
    "&.Mui-selected": {
        color: "#DF5200 !important",
        opacity: "1 !important",
    }
})
const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        height: "40px"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
        backgroundColor: "#F8F6F1 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #F0EBE0 !important",

    }
})
const CustomSelectDD = styled(Select)({
    background: "#F8F6F1 !important",

    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent !important"
    },
    "& .MuiSelect-iconOutlined": {
        right: "10px !important"
    },
    "& .MuiSelect-root": {
        padding: "0 32px 0 14px !important"
    }

})
const CustomArrowIcon = styled(KeyboardArrowDown)({
    fontSize: "26px",
    color: "#1C2324",
    opacity: 0.40
});
const style = {
    submitBtn: {
        width: "100%",
        height: "40px",
        lineHeight: "22px",
        textAlign: "center",
        borderRadius: "28px",
        marginTop: "16px",
        backgroundColor: "#DF5200",
        fontWeight: 600,
        color: "#FFFFFF",
        fontSize: "14px",
    } as React.CSSProperties,
    btnLoading: {
        color: "#FFFFFF",
        height: "20px",
        width: "20px"
    } as React.CSSProperties,
    inputWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    } as React.CSSProperties,
    iconBtn: {
        margin: "0 0 0 16px",
        padding: "0"
    } as React.CSSProperties,
    mainWrapper: {
        padding: "12px 16px 16px 16px"
    } as React.CSSProperties,
    inputFieldStyle: {
        height: "32px",
        width: "100%",
    } as React.CSSProperties,
}
// Customizable Area End

import React from 'react'

import {
  // Customizable Area Start
  Typography,
  Modal,
  Button,
  CircularProgress 
  // Customizable Area End
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

interface CustomModalPropsType { 
  open: boolean,
  onClose: () => void,
  confirmHandler?: () => void;
  title: string;
  description: string | null;
  confirmTitle?: string;
  disableConfirmHandler?:boolean;
  dynamicDescription?:React.ReactNode
}

const CustomModal = (props: CustomModalPropsType) => {
  const { open, onClose, confirmHandler, title, description, confirmTitle, disableConfirmHandler =false, dynamicDescription=null } = props

  const classes = useStyles();

  return (
    <Modal
      onClose={onClose}
      open={open}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <Typography variant='h2' id="server-modal-title" className={classes.modalHeading}> {title} </Typography>

        {dynamicDescription ? <Typography id="server-modal-description" className={classes.modalPara}>{dynamicDescription}</Typography> :
          <Typography id="server-modal-description" className={classes.modalPara}>{description}</Typography> }
          
          <div className={`${classes.modalBtn} ${!confirmHandler ? classes.modalBtnSingle: ''}`}>
          { confirmHandler && <Button variant="contained" className={classes.DeleteBtn} onClick={() => confirmHandler()} disabled={disableConfirmHandler}>
          {disableConfirmHandler ? <CircularProgress style={{width:"32px",height:"32px",color:"#B3261E"}}/> : confirmTitle}
          </Button>}
          
          <Button variant="contained" className={confirmTitle ?  classes.CancelBtn : classes.DeleteBtn} onClick={() => onClose()}>
              {confirmTitle ? "Cancel" :  "Okay" }
          </Button>
            </div>
        </div>
        
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: "center",
    width: "528px",
    padding: "70px 5px",
    backgroundColor: "#fff",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    boxShadow:' 2px 8px 32px 4px rgba(0, 0, 0, 0.06)',
    borderRadius: '12px',
    "@media (max-width: 528px)": {
      width: "428px",
    },
    "@media (max-width: 428px)": {
      width: "328px",
    }
  },
  modalHeading: {
    color: ' #1C2324',
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize:' 24px',
    paddingBottom:'16px',
  },
  modalPara: {
    maxWidth: '400px',
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight:' 28px',
    textAlign: 'center',
    color: ' #1C2324',
    margin: 'auto',
    paddingBottom:'24px',
  },
  modalBtn: {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px'
  },
  modalBtnSingle: {
    justifyContent: ' center',
  },
  DeleteBtn:{
    background: '#B3261E',
    borderRadius:' 28px',
    textTransform:"uppercase",
    color: '#FFFFFF',
    width: '130px',
    height: '56px',
    fontWeight: 700,
    fontSize: '18px',
    boxShadow: 'none',
    '&:hover': {
      background: '#B3261E',
      boxShadow: 'none'
    },
    "@media (max-width: 528px)": {
      margin: "0px",
    },
  },
  CancelBtn:{
    background: '#E4DDC9',
    borderRadius: ' 28px',
    color: '#1C2324',
    width: '130px',
    height: '56px',
    fontWeight: 700,
    fontSize:'18px',
    boxShadow: "none",
    '&:hover': {
      background: '#E4DDC9',
      boxShadow: 'none'
    }
  }
}))

// CustomModal.defaultProps = {
//   disableConfirmHandler:false
// }
export default CustomModal;